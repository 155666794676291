import Bacon from 'baconjs';

export default () => {
    const initialStream$ =
        window.pageYOffset === 0 ? Bacon.never() : Bacon.once();

    return Bacon.fromEvent(window, 'scroll')
        .merge(initialStream$)
        .throttle(300)
        .map(() => (window.pageYOffset <= 10 ? 'show' : 'hide'))
        .skipDuplicates();
};
