import React from 'react';
import intl from 'react-intl-universal';
import {
    getCardIcon,
    getCardType,
    getCardNumber,
} from '../../../services/paymentProviders';

const base = 'profile-payment-method';

export default ({ paymentMethod, onDeleteClicked }) => {
    return (
        <div
            className={`${base}__item display--flex flex-align-items-center fs--small-medium`}
        >
            <div className={getCardIcon(paymentMethod) + ' flex-none'} />
            <div className={`${base}__item__card-type`}>
                {getCardType(paymentMethod)}
            </div>
            <div className={`${base}__item__card-info mlm flex-1`}>
                {getCardNumber(paymentMethod)}
            </div>
            {paymentMethod['card_type'] !== 'Wallet' ? (
                <button
                    type="button"
                    className={`${base}__item__delete-btn`}
                    onClick={onDeleteClicked.bind(null, paymentMethod)}
                >
                    {intl.get('profile.payment.delete')}
                </button>
            ) : null}
        </div>
    );
};
