import React, { Component } from 'react';
import intl from 'react-intl-universal';

const base = 'login-popup';

export default class OrSeparator extends Component {
    render() {
        const { customMargin } = this.props;
        const margin = customMargin ? customMargin : 20;

        return (
            <div
                className={`${base}__or-separator`}
                style={{ margin: `${margin}px 0` }}
            >
                <div className={`${base}__or-separator-line-container`}>
                    <div className={`${base}__or-separator-line`} />
                </div>
                <span className={`${base}__or-separator-text`}>
                    {intl.get('loginPopup.usernameView.or')}
                </span>
                <div className={`${base}__or-separator-line-container`}>
                    <div className={`${base}__or-separator-line`} />
                </div>
            </div>
        );
    }
}
