import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import Modal from '../../core/Modal';
import DateAndTimeSelectorUI from '../menuPage/DateAndTimeSelectorUI';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import ChangeButton from './ChangeButton';

const base = 'shopping-cart-timeslot';

@inject('cartStore', 'userStore', 'menuListStore')
@observer
export default class extends React.Component {
    state = {
        showTimeslotModal: false,
    };

    openModal = () => {
        this.setState({ showTimeslotModal: true });
    };

    closeModal = () => {
        this.setState({ showTimeslotModal: false });
    };

    isActiveTimeslot = (timeslot) => {
        const { cartStore, date } = this.props;
        const activeTimeslotId = cartStore.cart.find(
            (delivery) => delivery.date === date
        ).time_slot_id;

        return timeslot.id === activeTimeslotId;
    };

    handleSetActiveTimeslot = (activeTimeslotObj) => {
        const { cartStore, menuListStore, date } = this.props;

        // update activeTimeSlotObj
        if (menuListStore.activeDateObj.date === date) {
            menuListStore.storeActiveTimeslotObj(activeTimeslotObj);
        }

        cartStore.updateDateTimeslotInCart(date, activeTimeslotObj);
        menuListStore.getMenuForEachDelivery({
            startDateString: date,
            endDateString: date,
            customTimeslotId: activeTimeslotObj.time_slot_id,
        });
        this.closeModal();
    };

    renderList() {
        const { userStore, menuListStore, date } = this.props;
        const { showTimeslotModal } = this.state;

        const contentClasses = `address-selector-popup fixed`;
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        const validDate = menuListStore.validDates.find(
            (validDate) => validDate.date === date
        );
        const timeslots = menuListStore.getValidTimeslotsForOneDate(validDate);

        return (
            <div className={`${base}__list-container display--flex`}>
                <Modal
                    showModal={showTimeslotModal}
                    contentClasses={`${contentClasses} ${
                        !isMobile && 'modal__content--golden-ratio'
                    }`}
                    closeButtonClasses="fill--white"
                    closeButtonMargin="modal__extra-margin"
                    onBackdropClicked={this.closeModal}
                    onBackClicked={this.handleNavigateBack}
                    onCloseClicked={this.closeModal}
                    showCloseIcon={true}
                >
                    <DateAndTimeSelectorUI
                        subHeading="Delivery time"
                        validTimeslotsForActiveDate={timeslots}
                        isActiveTimeslot={this.isActiveTimeslot}
                        setActiveTimeslot={this.handleSetActiveTimeslot}
                        sendFullTimeslot={true}
                        hideSubmit={true}
                        autoScroll={true}
                    />
                </Modal>
            </div>
        );
    }

    render() {
        const { cartStore, menuListStore, date } = this.props;

        const validDate = menuListStore.validDates.find(
            (validDate) => validDate.date === date
        );
        const timeslots = menuListStore.getValidTimeslotsForOneDate(validDate);

        const activeTimeslotId = cartStore.cart.find(
            (delivery) => delivery.date === date
        ).time_slot_id;
        const activeTimeslotObj = timeslots.find(
            (slot) => slot.id === activeTimeslotId
        );

        return (
            <div>
                <div className="shopping-cart__section-title-container">
                    <p className="shopping-cart__section-title text--bolder">
                        {intl.get('timeframeSelection.deliveryTime')}
                    </p>
                </div>
                <div className="shopping-cart__timeframe-container display--flex">
                    <div className="display--flex">
                        <p className="shopping-cart__timeframe">
                            {activeTimeslotObj &&
                                activeTimeslotObj.display_text}
                        </p>
                    </div>
                    <div className="shopping-cart__change-btn-container flex-none">
                        <ChangeButton onClick={this.openModal} />
                    </div>
                </div>
                {this.renderList()}
            </div>
        );
    }
}
