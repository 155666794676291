export const storeActiveDateObj = (dateObj) => {
    sessionStorage.setItem('active_date', JSON.stringify(dateObj));
};

export const removeActiveDateObj = () => {
    sessionStorage.removeItem('active_date');
};

export const getActiveDateObj = () =>
    JSON.parse(sessionStorage.getItem('active_date'));

export const storeActiveTimeslotObj = (timeslotObj) => {
    sessionStorage.setItem('active_timeslot', JSON.stringify(timeslotObj));
};

export const removeActiveTimeslotObj = () => {
    sessionStorage.removeItem('active_timeslot');
};

export const getActiveTimeslotObj = () =>
    JSON.parse(sessionStorage.getItem('active_timeslot'));
