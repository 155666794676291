import React from 'react';
import Collapsible from '../../core/Collapsible';
import { inject, observer } from 'mobx-react';
import { getDateDisplay, parseDate } from '../../../services/dateTime';
import Price from '../../core/Price';
import OrderSummary from './OrderSummary';

const base = 'product-list';
const DateRow = ({ dateString, items }) => {
    const dateDisplay = getDateDisplay(parseDate(dateString));
    const numberOfItems = items.reduce((acc, cur) => {
        return acc + cur.quantity;
    }, 0);
    const itemsQuantifier =
        numberOfItems === 1 ? '1 item' : `${numberOfItems} items`;

    const title = (
        <span
            className={`${base}__date-title display--flex flex-align-items-center`}
        >
            <span className={`${base}__date text--bold text--uppercase mrs`}>
                {dateDisplay}
            </span>
            <span className="text--thin text--bold float-right mrs">
                {itemsQuantifier}
            </span>
        </span>
    );

    return (
        <div className={`${base}__date-row`}>
            <Collapsible
                title={title}
                initialCollapse={false}
                titleClasses={`${base}__date-title-wrapper`}
                titleCollapsedClasses={`${base}__date-title-wrapper--no-border`}
            >
                <ul className="mtm">
                    {items.map((item, index) => {
                        return <ItemRow key={index} item={item} />;
                    })}
                </ul>
            </Collapsible>
        </div>
    );
};

class ItemRow extends React.Component {
    render() {
        const { item } = this.props;

        const priceToDisplay = item.special_offer_price
            ? item.special_offer_price * item.quantity
            : item.price * item.quantity;

        return (
            <li className={`${base}__list-item display--flex mbm`}>
                <span className="lh--125">{item.title_bold || item.name}</span>
                <div className="text--right flex-1-only">
                    <span>x{item.quantity}</span>
                    <span className={`${base}__list-item-price`}>
                        <span>{parseFloat(priceToDisplay).toFixed(2)}</span>
                    </span>
                </div>
            </li>
        );
    }
}

@inject('cartStore')
@observer
export default class extends React.Component {
    render() {
        const { cartStore } = this.props;
        const cart = cartStore.cart;

        return (
            <div className={`${base} well`}>
                <h3 className="text--bolder mbm">Products</h3>
                {cart.map((dateOrder) => {
                    return (
                        <DateRow
                            key={dateOrder.date}
                            dateString={dateOrder.date}
                            items={dateOrder.menu_item}
                        />
                    );
                })}
                <div
                    className={`${base}__summary-section show--if-large hide--if-small`}
                >
                    <hr />
                    <OrderSummary />
                </div>
            </div>
        );
    }
}
