import React from 'react';

import config from '../../configs/parameters';

export const BaseGTMScript = `
    window.dataLayer = window.dataLayer || [];
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${config.GTMid}');
`;

export const getGTMScript = ({ pageTitle, pageType, lpType, lpVersion }) => `
    ${BaseGTMScript}

    window.dataLayer.push({
        "event": "viewPage",
        "page_title": "${pageTitle}",
        "page_type": "${pageType}",
        "lp_type": "${lpType}",
        "lp_version": "${lpVersion}"
    });
`;

const googleAnalyticsKey =
    window.location.hostname === config.hostnameTh
        ? 'UA-55484570-5'
        : 'UA-55484570-1';

const GTMNoScriptTag = `
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NGWB36" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
`;

const ScriptEmbed = (script) => (
    <div
        dangerouslySetInnerHTML={{ __html: script }}
        style={{ dispay: 'none' }}
    />
);
const GoogleTagManager = (variety) => {
    return getGTMScript({
        pageTitle: variety.pageTitle,
        pageType: variety.mixPanelPage,
        lpType: variety.mixPanelProperty['LP Type'] || '',
        lpVersion: variety.mixPanelProperty['LP Version'] || '',
    });
};
const GoogleTagManagerNoScript = ScriptEmbed(GTMNoScriptTag);
const WalletGoogleAnalytics = `
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function()
    { (i[r].q=i[r].q||[]).push(arguments)}
    ,i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    ga('create', '${googleAnalyticsKey}', 'auto');
    ga('send', 'pageview');
`;

const facebookAppId = config.facebookAppId;

const fbSdk = `
  window.fbAsyncInit = function() {
    FB.init({
      appId      : '${facebookAppId}',
      cookie     : true,
      xfbml      : true,
      version    : 'v2.10'
    });

    FB.AppEvents.logPageView();

  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));
`;

//if there are additional brand scripts
//const AdditionalEmbeddedBrandScripts = ScriptEmbed(AdditionalBrandScripts)

export {
    GoogleTagManager,
    GoogleTagManagerNoScript,
    WalletGoogleAnalytics,
    fbSdk,
};
