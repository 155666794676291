export const setReferral = (referralInfo) => {
    localStorage.setItem('referral', JSON.stringify(referralInfo));
};

export const getReferral = () => JSON.parse(localStorage.getItem('referral'));

export const clearReferralItemsFromLocalStorage = () => {
    localStorage.removeItem('referral');
    localStorage.removeItem('referral_variant');
};

export const setShowReferralSuccessBanner = () => {
    localStorage.setItem('show_referral_success_banner', true);
};

export const removeShowReferralSuccessBanner = () => {
    localStorage.removeItem('show_referral_success_banner');
};

export const willShowReferralSuccessBanner = () => {
    return localStorage.getItem('show_referral_success_banner');
};

export const setShowExsitingUserErrorBanner = () => {
    localStorage.setItem('shown_existing_user', true);
};

export const willShowExistingUserErrorBanner = () => {
    return localStorage.getItem('shown_existing_user');
};

export const removeShowExistingUserErrorBanner = () => {
    localStorage.removeItem('shown_existing_user');
};

export const setShowExistingCreditsErrorBanner = () => {
    localStorage.setItem('shown_existing_credits', true);
};

export const willShowExistingCreditsErrorBanner = () => {
    return localStorage.getItem('shown_existing_credits');
};

export const removeShowExistingCreditsErrorBanner = () => {
    localStorage.removeItem('shown_existing_credits');
};

export const referralMultiplier = 4;
