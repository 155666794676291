import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import AddToCartButton from './AddToCartButton';
import StarRatings from 'react-star-ratings';
import NewCounter from './NewCounter';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import { currency, truncateDecimal } from '../../../services';
import { GetLanguage } from '../../../services/translation';

const base = 'menu-item';

export default class extends React.Component {
    renderItemCount = () => {
        const { itemCount } = this.props;

        return itemCount > 0 && <NewCounter itemCount={itemCount} />;
    };

    renderTag = (tag, marginRight) => {
        const style = {
            backgroundColor: '#' + tag.color,
            marginRight: marginRight ? '3px' : '0',
        };

        return (
            <div>
                <p className={`${base}__tag text--bolder`} style={style}>
                    {tag.name}
                </p>
            </div>
        );
    };

    renderAddToCartButton = (customClass) => {
        const { price, onAddToCart, discountedPrice, showSkeleton } =
            this.props;
        const isLangMalay = GetLanguage() === 'my';

        return (
            <div
                className={`${base}__button-container ${customClass} ${
                    discountedPrice ? 'discount' : ''
                }`}
            >
                <AddToCartButton
                    onClick={onAddToCart}
                    price={price}
                    text={showSkeleton ? '' : intl.get('menuPage.menuItem.cta')}
                    customClass={`${showSkeleton ? 'skeleton' : ''} ${
                        isLangMalay ? 'small-font' : ''
                    }`}
                />
                {this.renderItemCount()}
            </div>
        );
    };

    renderPrice = () => {
        const { price, discountedPrice, showSkeleton } = this.props;
        const showDot = window.matchMedia(SMALL_SCREEN_MEDIA).matches
            ? !discountedPrice
            : true;
        const displayPrice =
            discountedPrice != null
                ? truncateDecimal(price, 2, false)
                : currency.localCurrency(price, false);

        return (
            <div className={`${base}__price-container`}>
                {discountedPrice != null ? (
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <s>
                            <span className={`${base}__price`}>
                                {displayPrice}
                            </span>
                        </s>
                        <span
                            className={`${base}__discounted-price text--bolder`}
                        >
                            {' '}
                            {truncateDecimal(discountedPrice, 2, false)}
                        </span>
                    </span>
                ) : (
                    <span
                        className={`${base}__price ${
                            showSkeleton ? 'skeleton' : ''
                        }`}
                    >
                        {displayPrice}
                    </span>
                )}
            </div>
        );
    };

    renderGradientImage = (item) => {
        if (item) {
            return `url(${item.image})`;
        }
    };

    renderRating() {
        const { ratingText, showThumbForRatingText, showSkeleton } = this.props;

        if (showSkeleton) {
            return (
                <div className={`${base}__rating-container skeleton`}>
                    <span className={` ${base}__rating skeleton`} />
                </div>
            );
        } else {
            return (
                <div className={`${base}__rating-container`}>
                    {showThumbForRatingText && (
                        <span className={` ${base}__rating`}>
                            <svg className={`${base}-icon iconmoon-icon`}>
                                <use xlinkHref="#icon-thumbs-up-menu-item" />
                            </svg>
                        </span>
                    )}
                    <span className={` ${base}__rating`}>{ratingText}</span>
                </div>
            );
        }
    }

    render() {
        const {
            item,
            redirectURL,
            availableAt,
            tag,
            ratingText,
            addOn,
            showSkeleton,
        } = this.props;
        const isSmallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches;
        const redirectPayload = {
            pathname: redirectURL,
        };

        const imageStyles = {
            backgroundImage: this.renderGradientImage(item),
        };

        return (
            <div className={base}>
                <div className={`${base}__main-container cursor-pointer`}>
                    <Link to={redirectPayload}>
                        <div
                            className={`${base}__image ${
                                addOn ? 'add-on' : ''
                            } ${showSkeleton ? 'skeleton' : ''}`}
                            style={imageStyles}
                        >
                            {availableAt && (
                                <div className={`${base}__tint`}>
                                    <p
                                        className={`sold-out-text text--sofiapro`}
                                    >
                                        Available from {availableAt}
                                    </p>
                                </div>
                            )}
                            {tag && (
                                <div
                                    className={`${base}__tag-container display--flex`}
                                >
                                    {this.renderTag(tag, isSmallScreen)}
                                </div>
                            )}
                        </div>
                    </Link>
                    <Link
                        className={`${base}__link link--no-underline ${
                            addOn ? 'add-on' : ''
                        } ${showSkeleton ? 'skeleton' : ''}`}
                        to={redirectPayload}
                    >
                        {((ratingText && !isSmallScreen) || showSkeleton) &&
                            this.renderRating()}
                        <div
                            className={`${base}__name-container ${
                                showSkeleton ? 'skeleton' : ''
                            }`}
                        >
                            <span
                                className={`${base}__name link--no-underline text--bolder`}
                            >
                                {item.title_bold || item.name}
                            </span>
                            <span
                                className={`${base}__subheading link--no-underline hide--if-small`}
                            >
                                {' '}
                                {item.title}
                            </span>
                        </div>
                    </Link>
                    {this.renderPrice()}
                    {this.renderAddToCartButton()}
                </div>
            </div>
        );
    }
}
