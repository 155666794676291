import React from 'react';
import { inject, observer } from 'mobx-react';

const base = 'order-info';

@inject('completeSignUpStore')
@inject('store')
@inject('deliveryAddressStore')
@observer
export default class extends React.Component {
    renderAddress(address) {
        if (
            address.location_type === 'Condo' ||
            address.location_type === 'Office'
        ) {
            if (address.building_name && address.district) {
                return (
                    <span>
                        {address.building_name}, {address.district}
                    </span>
                );
            } else if (address.building_name && address.city) {
                return (
                    <span>
                        {address.building_name}, {address.city}
                    </span>
                );
            } else if (address.building_name && address.block) {
                return (
                    <span>
                        {address.building_name}, {address.block}
                    </span>
                );
            }
        }
        return <span>{address.display_address}</span>;
    }

    render() {
        const { completeSignUpStore, store, deliveryAddressStore } = this.props;
        if (completeSignUpStore.willShowForm()) {
            return <div />;
        }

        if (!store.user) {
            return (
                <div className={`${base} well`}>
                    <div>Loading ...</div>
                </div>
            );
        }

        return (
            <div className={`${base} well`}>
                <div className="mbm">{store.user.alias}</div>
                <div className="lh--125">
                    {this.renderAddress(deliveryAddressStore.selectedAddress)}
                </div>
            </div>
        );
    }
}
