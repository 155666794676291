import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { cities, GetCity } from '../../../services/city';
import config from '../../../configs/parameters';
import template from 'lodash/template';

const base = 'about__section-faq';

export default class SectionFAQ extends Component {
    state = {
        content: [
            {
                question: intl.get('about.section-faq.content.one.question'),
                answer: template(
                    intl.get('about.section-faq.content.one.answer')
                )({
                    brandName: config.brandName,
                    area:
                        GetCity() === cities.kl
                            ? 'the Klang Valley'
                            : 'Bangkok',
                }),
                expanded: false,
            },
            {
                question: template(
                    intl.get('about.section-faq.content.two.question')
                )({ brandName: config.brandNameShort }),
                answer: template(
                    intl.get('about.section-faq.content.two.answer')
                )({ brandName: config.brandNameShort }),
                expanded: false,
            },
            {
                question: template(
                    intl.get('about.section-faq.content.three.question')
                )({ brandName: config.brandNameShort }),
                answer: template(
                    intl.get('about.section-faq.content.three.answer')
                )({ brandName: config.brandNameShort }),
                expanded: false,
            },
        ],
    };

    toggleExpand = (index) => {
        const c = this.state.content;
        c[index].expanded = !c[index].expanded;
        this.setState({
            content: c,
        });
    };

    render() {
        const content =
            GetCity() === cities.kl
                ? this.state.content
                : this.state.content.slice(0, 2);
        return (
            <div className={`${base} container`}>
                <div className={`${base}__header-container`}>
                    <h3 className={`${base}__header text--bolder`}>
                        {intl.get('about.section-faq.header')}
                    </h3>
                    <p className={`${base}__sub-header`}>
                        {template(intl.get('about.section-faq.sub-header'))({
                            brandName: config.brandName,
                        })}
                    </p>
                </div>
                <div className={`${base}__faq-container`}>
                    {content.map((c, i) => (
                        <div className={`${base}__faq-row`} key={i}>
                            <div
                                className={`${base}__faq-question-container cursor-pointer`}
                                onClick={() => this.toggleExpand(i)}
                            >
                                <p
                                    className={`${base}__faq-question text--bolder`}
                                >
                                    {c.question}
                                </p>
                                <svg
                                    className={`${base}__arrow-icon iconmoon-icon`}
                                >
                                    {c.expanded ? (
                                        <use
                                            xlinkHref={`#icon-chevron-thin-up`}
                                        />
                                    ) : (
                                        <use
                                            xlinkHref={`#icon-chevron-thin-down`}
                                        />
                                    )}
                                </svg>
                            </div>
                            <p
                                className={`${base}__faq-answer ${
                                    c.expanded ? 'expanded' : 'hidden'
                                }`}
                            >
                                {c.answer}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
