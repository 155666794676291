import React from 'react';
import template from 'lodash/template';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import PopupExplainedUI from '../../shoppingCartPage/deliveryAddress/PopupExplainedUI';

@inject('deliveryAddressStore')
@observer
export default class extends React.Component {
    render() {
        const store = this.props.deliveryAddressStore;

        const options = [
            {
                title: template(store.PICKUP_AT_FLOOR)({
                    floor: store.newAddress.block,
                }),
                description: intl.get(
                    'deliveryAddress.popupExplain.pickUpFromFloorReceptionDetail'
                ),
                preferred: true,
            },
            {
                title: template(store.MEET_DRIVER_AT_FLOOR)({
                    floor: store.newAddress.block,
                }),
                description: intl.get(
                    'deliveryAddress.popupExplain.meetTheDriverAtMyFloorDetail'
                ),
                preferred: false,
            },
            {
                title: store.MEET_DRIVER_AT_LOBBY,
                description: intl.get(
                    'deliveryAddress.popupExplain.meetTheDriverInLobbyDetail'
                ),
                preferred: false,
            },
        ];

        return <PopupExplainedUI options={options} />;
    }
}
