import config from '../../configs/parameters';

export default {
    menu: `Menu | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    about: `About | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    chef: `Our Chefs | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    deliveryArea: `Delivery Area | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    orderHistory: `Order History | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    profile: `My Profile | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    shoppingCart: `Shopping Cart | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    terms: `Terms & Conditions | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    welcome: `Healthy Food Delivery for Kuala Lumpur | Order now | ${config.brandName}`,
    //onboarding: onboarding title for ${config.brandName} is in variety.js
    business: `Business | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    walletBillPlzProcess: `Wallet Checkout | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    packageCheckout: `Wallet Checkout | ${config.brandName}`,
    wallet: `Wallet by ${config.brandName} | Food Delivery in Kuala Lumpur`,
    rewards: `My Rewards | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    ride: `Ride Now | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    careers: `Join Us | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    terminalAddress: `Add Address | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    terminalLearnMore: `Learn More | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    terminalLogin: `Login | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    terminal: `Menu | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    resetPassword: `Reset Password | ${config.brandName}`,
    offlineSignup: `Welcome to ${config.brandName}. Signup here to receive your 1 for 1`,
    promotion: `Promotions | ${config.brandName} - Food Delivery in Kuala Lumpur`,
    referral: `Get Your Referral Credits | ${config.brandName} - Food Delivery in Kuala Lumpur`,
};
