import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import BasePage from './basePage';
import ReferralRoot from '../components/ReferralRoot';
import { getGTMScript } from '../components/core/ScriptTag';
import { inject, observer, Provider } from 'mobx-react';
import { menuTags } from '../services/metatags';
import UtmStore from '../store/utmStore';
import { services } from '../services/index';
import qs from 'query-string';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('menu');
const GTM = getGTMScript({
    pageTitle: pageTitle,
    pageType: 'Referral',
    lpType: '',
    lpVersion: '',
});

const utmStore = new UtmStore();

@inject('userStore')
@observer
export default class extends Component {
    componentDidMount() {
        const { location } = this.props;
        const params = qs.parse(location.search);
        utmStore.params = params;
        utmStore.handleSendParams(params);

        // checks for auto applied discount campaigns
        document.body.classList.add('referral');
    }

    render() {
        return (
            <Fragment>
                <Helmet meta={menuTags}>
                    {localizationTags('referral').map((tag) => tag)}
                    {getCanonicalTag('referral')}
                    <title>{pageTitle}</title>
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <Provider utmStore={utmStore}>
                    <ReferralRoot />
                </Provider>
            </Fragment>
        );
    }
}
