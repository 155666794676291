import React, { Fragment } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { when } from 'mobx';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import CalendarStore from '../store/calendarStore';
import menuHeaderStore from '../store/menuHeaderStore';
import cartStore from '../store/cartStore';
import menuListStore from '../store/menuListStore';
import setUpStoreInteractions from '../store/interactions/menuInteractions';
import BaseLayout from './core/baseLayout/Master_BaseLayout';
import MenuHeader from './core/headers/MenuHeader';
import MenuFooter from './core/MenuFooter';
import Entry from './page/menuPage/Entry';
import { SMALL_SCREEN_MEDIA } from '../services/constants';
import { services } from '../services/index';
import { isEmpty } from 'lodash';
import LoginPopup from './core/LoginPopup';
import MagicLoginPopup from './core/MagicLoginPopup';

import {
    clearReferralItemsFromLocalStorage,
    willShowReferralSuccessBanner,
    removeShowReferralSuccessBanner,
    willShowExistingUserErrorBanner,
    removeShowExistingUserErrorBanner,
    willShowExistingCreditsErrorBanner,
    removeShowExistingCreditsErrorBanner,
} from '../services/referrals';
import {
    clearRewardsToApplyBanner,
    showRewardsToApplyBanner,
} from '../services/rewards';
import { GetDishURL } from '../services/url';

const { clevertap } = window;

const calendarStore = new CalendarStore();

const headerStores = {
    menuHeaderStore,
};
const stores = {
    menuHeaderStore,
    calendarStore,
};

setUpStoreInteractions(
    calendarStore,
    menuListStore,
    cartStore,
    menuHeaderStore
);

const Header = () => (
    <Provider>
        <MenuHeader />
    </Provider>
);

@inject('loginPopupStore', 'rewardsStore', 'menuCheckoutStore')
@observer
class MenuRoot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddressSelector: false,
            showDateTimeSelector: false,
        };
    }

    componentDidMount() {
        //refresh user authorization token
        services.api.AccessTokenStorage.refreshToken();

        clevertap.event.push('View Page', {
            'Page Title': document.title,
            'Page Type': 'Menu',
            'Page URL': window.location.href,
        });

        setTimeout(() => {
            this.showRewardsBanner();
        }, 100);
    }

    showRewardsBanner = () => {
        const { rewardsStore } = this.props;

        if (willShowReferralSuccessBanner() || showRewardsToApplyBanner()) {
            rewardsStore.shownBanner = true;
            services.bannerEvents.showSuccess(
                'Credits successfully applied! Order now'
            );
            clearRewardsToApplyBanner();
            removeShowReferralSuccessBanner();
        }

        if (willShowExistingUserErrorBanner()) {
            rewardsStore.shownBanner = true;
            services.bannerEvents.showError(
                'Sorry! Referral rewards are only available for new users.'
            );
            clearReferralItemsFromLocalStorage();
            removeShowExistingUserErrorBanner();
        }

        if (willShowExistingCreditsErrorBanner()) {
            rewardsStore.shownBanner = true;
            services.bannerEvents.showSuccess(
                'You already have credits in your account! Order now'
            );
            removeShowExistingCreditsErrorBanner();
        }
    };

    render() {
        const { loginPopupStore } = this.props;
        const stickyFooter = window.matchMedia(SMALL_SCREEN_MEDIA).matches
            ? false
            : true;

        return (
            <Provider {...stores}>
                <BaseLayout.Page HeaderComponent={() => Header()}>
                    <Fragment>
                        <main className="p0--if-small">
                            {loginPopupStore.isShowing && (
                                <LoginPopup
                                    dismissable={true}
                                    showCloseIcon={true}
                                />
                            )}
                            {loginPopupStore.magicLinkAccessToken && (
                                <MagicLoginPopup
                                    dismissable={true}
                                    showCloseIcon={true}
                                />
                            )}
                            <Entry />
                        </main>
                        <MenuFooter sticky={stickyFooter} />
                    </Fragment>
                </BaseLayout.Page>
            </Provider>
        );
    }
}

export default withRouter(MenuRoot);
