import daggy from 'daggy';

const empty = function () {
    return {};
};

export const RESOURCE = daggy.taggedSum('RESOURCE', {
    Initial: [],
    Loading: [],
    Success: ['data'],
    Failure: ['message', 'data'],
});

RESOURCE.prototype.getOrEmpty = function (mapFn) {
    return this.cata({
        Success: function (data) {
            return mapFn(data);
        },
        Initial: empty,
        Loading: empty,
        Failure: empty,
    });
};

export const MAYBE = daggy.taggedSum('MAYBE', {
    Some: ['data'],
    None: [],
});

MAYBE.prototype.getOrEmpty = function () {
    return this.cata({
        Some: function (data) {
            return data;
        },
        None: empty,
    });
};

MAYBE.prototype.getOrElse = function (orElse) {
    return this.cata({
        Some: function (data) {
            return data;
        },
        None: function () {
            return orElse;
        },
    });
};

export const Link = daggy.tagged('Link', ['href', 'text']);

export const RESULT = daggy.taggedSum('RESULT', {
    Failure: ['message', 'info'],
    Success: ['data'],
});

export const BANNER_LEVEL = daggy.taggedSum('BANNER_LEVEL', {
    ERROR: [],
    WARNING: [],
    INFO: [],
    SUCCESS: [],
});

export const VERIFICATION_STATUS = daggy.taggedSum('VERIFICATION_STATUS', {
    Initial: [],
    CodeRequesting: [],
    CodeRequestSuccess: ['data'],
    CodeRequestFailure: ['data'],
    Verifying: ['data'],
    VerifySuccess: ['data'],
    VerifyFailure: ['data'],
});

export const DELIVERY_STATUS = daggy.taggedSum('DELIVERY_STATUS', {
    Empty: [],
    Pending: ['data'],
    Delivering: ['data'],
    Arrived: ['data'],
    Delivered: ['data'],
    Delayed: ['data'],
});
