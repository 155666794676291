import React, { Component } from 'react';
import Modal from '../Modal';
import { observer, inject } from 'mobx-react';
import { when } from 'mobx';
import SelectDetailsPage from './SelectDetailsPage';
import SelectPaymentPage from './SelectPaymentPage';
import SelectThankYouPage from './SelectThankYouPage';

const base = 'select-popup';

@inject('selectPopupStore', 'loginPopupStore', 'userStore')
@observer
export default class SelectPopup extends Component {
    componentDidMount() {
        const { selectPopupStore } = this.props;

        selectPopupStore.handleGetSelectOptions();

        when(
            () => selectPopupStore.plans && selectPopupStore.plans.length > 0,
            () => {
                const mostPopular = selectPopupStore.plans.find(
                    (plan) => plan.most_popular
                );
                selectPopupStore.selected = mostPopular;
            }
        );
    }

    render() {
        const { selectPopupStore } = this.props;
        const showThankYouPage =
            selectPopupStore.view === selectPopupStore.PAGE_THANK_YOU;
        return (
            <Modal
                showModal={selectPopupStore.show}
                showCloseIcon={
                    selectPopupStore.view === selectPopupStore.PAGE_ONE
                }
                contentClasses={`${
                    showThankYouPage ? 'select-thank-you-popup' : `${base}`
                }`}
                onBackdropClicked={selectPopupStore.handleHidePopup}
                onCloseClicked={selectPopupStore.handleHidePopup}
                showBackIcon={
                    selectPopupStore.view === selectPopupStore.PAGE_TWO
                }
                backButtonMargin={`${base}__back-margin`}
                backButtonText="Back"
                onBackClicked={() =>
                    selectPopupStore.handleChangeView(selectPopupStore.PAGE_ONE)
                }
                showBackdropOnMobile={
                    selectPopupStore.view === selectPopupStore.PAGE_THANK_YOU
                }
            >
                {selectPopupStore.view === selectPopupStore.PAGE_ONE && (
                    <SelectDetailsPage />
                )}
                {selectPopupStore.view === selectPopupStore.PAGE_TWO && (
                    <SelectPaymentPage />
                )}
                {showThankYouPage && <SelectThankYouPage />}
            </Modal>
        );
    }
}
