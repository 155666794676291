import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import Dropdown from '../../core/Dropdown';

import {
    getCompanyFromLocalStorage,
    setCompanyToLocalStorage,
} from '../../../services/company';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import { services } from '../../../services';

const base = 'account-switcher';

@inject('userStore')
@observer
export default class AccountSwitcher extends Component {
    state = {
        loading: true,
        currentSelection: '',
    };

    componentDidMount() {
        const { userStore } = this.props;
        when(
            () => userStore.user.data,
            () => {
                this.setState({ loading: false });

                if (getCompanyFromLocalStorage()) {
                    this.setState({ currentSelection: 'business' });
                } else {
                    this.setState({ currentSelection: 'personal' });
                }
            }
        );
    }

    handleSelectAccountType = (choice) => {
        setCompanyToLocalStorage(choice);
        services.api.UserCart.clear();
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    renderAccountType() {
        const user = this.props.userStore.user;
        const personalDisplay = window.matchMedia(SMALL_SCREEN_MEDIA)
            .matches ? (
            <span className="margin--left">Personal</span>
        ) : (
            <span>
                Order as <strong>Personal</strong>
            </span>
        );

        const businessDisplay = window.matchMedia(SMALL_SCREEN_MEDIA)
            .matches ? (
            <span className="margin--left">Business</span>
        ) : (
            <span>
                Order as{' '}
                <strong>Business - {user.data.company.company_name}</strong>
            </span>
        );

        return getCompanyFromLocalStorage() ? businessDisplay : personalDisplay;
    }

    renderCheckMark = () => {
        return (
            <svg className={`${base}__check iconmoon-icon`}>
                <use xlinkHref="#icon-checkmark-noborder-account-switcher" />
            </svg>
        );
    };

    render() {
        const { loading, currentSelection } = this.state;
        const { dropdownStore } = this.props;
        const user = this.props.userStore.user;
        const dropDownWidth = window.matchMedia(SMALL_SCREEN_MEDIA).matches
            ? '80vw'
            : '300px';

        return loading ? (
            <div />
        ) : user.data.company ? (
            <div className={`${base}`}>
                <div
                    className={`${base}__content-container cursor-pointer z-index-higher-than-backdrop`}
                    onClick={() =>
                        dropdownStore.handleShowDropdown(
                            dropdownStore.DROPDOWN_ACCOUNT_SWITCHER
                        )
                    }
                    onMouseEnter={() =>
                        dropdownStore.handleShowDropdown(
                            dropdownStore.DROPDOWN_ACCOUNT_SWITCHER
                        )
                    }
                >
                    {window.matchMedia(SMALL_SCREEN_MEDIA).matches && (
                        <span>Order as</span>
                    )}
                    {this.renderAccountType()}
                    <svg
                        className={`${base}__dropdown iconmoon-icon cursor-pointer`}
                    >
                        <use xlinkHref="#icon-chevron-thin-down" />
                    </svg>
                </div>

                <Dropdown
                    width={dropDownWidth}
                    arrowSize={10}
                    isShowing={
                        dropdownStore.showingDropdown ===
                        dropdownStore.DROPDOWN_ACCOUNT_SWITCHER
                    }
                    onClickOutside={() =>
                        dropdownStore.handleHideDropdownImmediately()
                    }
                >
                    <div className={`${base}__list-container`}>
                        <ul
                            className={`${base}__list`}
                            onMouseLeave={() =>
                                dropdownStore.handleHideDropdown()
                            }
                        >
                            <li
                                className={`${base}__list-item ${
                                    currentSelection === 'personal' &&
                                    'text--bolder'
                                } cursor-pointer display--flex`}
                                onClick={() =>
                                    this.handleSelectAccountType(false)
                                }
                            >
                                <span className={`${base}__list-item-name`}>
                                    Personal
                                </span>
                                {currentSelection === 'personal' && (
                                    <span className="margin--left">
                                        {this.renderCheckMark()}
                                    </span>
                                )}
                            </li>
                            <li
                                className={`${base}__list-item cursor-pointer`}
                                onClick={() =>
                                    this.handleSelectAccountType(true)
                                }
                            >
                                <p
                                    className={`${
                                        currentSelection === 'business' &&
                                        'text--bolder'
                                    } display--flex`}
                                >
                                    <span className={`${base}__list-item-name`}>
                                        Business -{' '}
                                        {user.data.company.company_name}
                                    </span>
                                    {currentSelection === 'business' && (
                                        <span className="margin--left">
                                            {this.renderCheckMark()}
                                        </span>
                                    )}
                                </p>
                                {user.data.company.company_text ? (
                                    <p className="company-details">
                                        {user.data.company.company_text}
                                    </p>
                                ) : (
                                    <div />
                                )}
                            </li>
                        </ul>
                    </div>
                </Dropdown>
            </div>
        ) : (
            <div></div>
        );
    }
}
