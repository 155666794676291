import React from 'react';

export default ({ question, answer, extras }) => {
    const base = 'faq-item';

    return (
        <div className={base}>
            <p className={`${base}__question`}>{question}</p>
            <div
                className="lh--150"
                dangerouslySetInnerHTML={{ __html: answer }}
            />
            <div className="mtm">{extras}</div>
        </div>
    );
};
