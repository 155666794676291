import React from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import intl from 'react-intl-universal';

import { RESOURCE } from '../../../services/types';

const base = 'delivery-area__popup-email';

@inject('deliveryAreaStore')
@observer
export default class extends React.Component {
    render() {
        const store = this.props.deliveryAreaStore;
        const { validateEmailResult, voter } = store;
        const buttonClasses = cx(
            'button button--success button--bigger-on-mobile width--100',
            {
                'button--busy': voter === RESOURCE.Loading,
            }
        );

        return (
            <div className={base}>
                <h1 className={`${base}__heading text--bold text--sofiapro`}>
                    {intl.get('deliveryArea.popupEmailHeading')}
                </h1>
                <div className="separator separator--green mbl mtl" />
                <p className={`${base}__subheading lh--125 mbl`}>
                    {intl.get('deliveryArea.popupEmailSubheading')}
                </p>
                <form onSubmit={::store.submitVoteForNewUser}>
                    <input
                        type="text"
                        autoFocus="true"
                        className="input width--100 mbm"
                        placeholder="Email"
                        name="email"
                        value={store.email}
                        onChange={::store.updateModel}
                    />
                    <p className="text--red">
                        {validateEmailResult.getFirstError()}
                    </p>
                    <button className={buttonClasses}>
                        {intl.get('deliveryArea.voteCTA')}
                    </button>
                </form>
            </div>
        );
    }
}
