import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { inject } from '../../../node_modules/mobx-react';
import { services } from '../../services/index';
import { GetCity, cities } from '../../services/city';
import { clearReferralItemsFromLocalStorage } from '../../services/referrals';
import { UserAddressSettings } from '../../services/api/userAddressServices';
import { removeActiveTimeslotObj } from '../../services/timeslot';
import { CitySelectedCallback } from '../../services/url';
import { saveLangToStorage } from '../../services/translation';

class CountrySelector extends Component {
    state = {
        expand: false,
        cities: [],
    };

    componentDidMount() {
        services.api
            .GetCities()
            .then((res) => {
                this.setState({
                    cities: res.filter((city) => city.is_published),
                });
            })
            .catch();
    }

    handleSelector = () => {
        this.setState({ expand: !this.state.expand });
    };

    handleClickOutside = () => {
        this.setState({ expand: false });
    };

    checkMark = (base) => {
        return (
            <svg className={`${base}__check iconmoon-icon`}>
                <use xlinkHref="#icon-checkmark-noborder-account-switcher" />
            </svg>
        );
    };

    handleSetCity = (city) => {
        const lang = 'en'; //setting language as en, as for KL, we've en and don't have any other city as of now.
        clearReferralItemsFromLocalStorage();
        UserAddressSettings.clear();
        removeActiveTimeslotObj();
        CitySelectedCallback(city);

        saveLangToStorage(lang);
        services.api.SetLanguage(lang);

        this.props.menuHeaderStore.logOut(true);
    };

    handleGetCity = () => {
        return 'Kuala Lumpur';
    };

    render() {
        const { base } = this.props;
        const { expand } = this.state;

        const city = 'kuala-lumpur';

        return (
            <div>
                {expand && (
                    <div className={`${base}__selector-container`}>
                        {this.state.cities.map((cityToShow) => (
                            <a
                                className={
                                    `${base}__selector link--no-underline text--black fs--small-medium ms ` +
                                    (city === cities.kl ? '' : 'cursor-pointer')
                                }
                                onClick={() => this.handleSetCity(cities.kl)}
                                id={`select-country-${cityToShow.name}`}
                            >
                                <div className="display--flex">
                                    <span>{cityToShow.name}</span>
                                    {city === cities.kl
                                        ? this.checkMark(base)
                                        : null}
                                </div>
                            </a>
                        ))}
                    </div>
                )}
                <div onClick={this.handleSelector} id="toggle-city">
                    <a
                        className={`${base}__selector-picker link--no-underline text--black fs--small-medium ms cursor-pointer`}
                    >
                        {this.handleGetCity()}
                    </a>
                    <button type="button" className={`${base}__toggle`}>
                        <svg className={`${base}__collapse iconmoon-icon`}>
                            <use
                                xlinkHref={
                                    expand
                                        ? '#icon-chevron-thin-up'
                                        : '#icon-chevron-thin-down'
                                }
                            />
                        </svg>
                    </button>
                </div>
            </div>
        );
    }
}

export default inject('menuHeaderStore')(onClickOutside(CountrySelector));
