import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';

import BaseLayout from './core/baseLayout/Master_BaseLayout';
import WalletFooter from './page/walletPage/WalletFooter';
import WalletHeader from './core/headers/WalletHeader';
import SectionHero from './page/walletPage/SectionHero';
import SectionPackages from './page/walletPage/SectionPackages';
import SectionFAQ from './page/walletPage/SectionFAQ';
import HeaderStore from '../store/walletHeaderStore';
import { RESOURCE } from '../services/types';
import { CircleLoader } from './core/Loading';
import WalletStatusBanner from './page/walletPage/WalletStatusBanner';

const { clevertap } = window;

@inject('walletStore', 'walletStatusStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);

        this.headerStore = new HeaderStore({
            backLink: props.backLink ? props.backLink : '/menu',
        });
    }

    renderHeader = () => {
        const { walletStatusStore } = this.props;
        const hasWallet = walletStatusStore.walletStatus.data?.has_wallet;

        return (
            <WalletHeader
                headerStore={this.headerStore}
                hasWallet={hasWallet}
                customClass="border"
            />
        );
    };

    componentDidMount() {
        clevertap.event.push('View Wallet Page');
    }

    render() {
        const { walletStore, walletStatusStore } = this.props;

        return (
            <BaseLayout.Page HeaderComponent={this.renderHeader}>
                {RESOURCE.Success.is(walletStatusStore.walletStatus) && (
                    <Fragment>
                        <main>
                            {walletStatusStore.walletStatus.data?.has_wallet ? (
                                <WalletStatusBanner />
                            ) : (
                                <SectionHero backLink={this.props.backLink} />
                            )}
                            {walletStore.walletOptions === RESOURCE.Initial ||
                            walletStore.walletOptions === RESOURCE.Loading ? (
                                <div className="display--inline-block text--center width--100 mtxxl mbxxl">
                                    <CircleLoader className="m0a" />
                                </div>
                            ) : (
                                <SectionPackages
                                    packages={walletStore.enabledWalletPackages}
                                />
                            )}
                            <SectionFAQ />
                        </main>
                        <WalletFooter />
                    </Fragment>
                )}
            </BaseLayout.Page>
        );
    }
}
