import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import WalletPackagesRoot from '../components/WalletPackagesRoot';
import { CircleLoader } from '../components/core/Loading';
import { services } from '../services/index';
import { Helmet } from 'react-helmet';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';
import { RESOURCE } from '../services/types';

@inject('walletStore')
@observer
export default class extends Component {
    componentDidMount() {
        const { walletStore } = this.props;
        walletStore.getWalletOptions();
        document.body.classList.add('wallet');
    }

    render() {
        const { location, walletStore } = this.props;
        const redirected = location.state && location.state.redirected;
        const from = location.state && location.state.from;

        return (
            <Fragment>
                <Helmet>
                    <title>{services.getTitle('wallet')}</title>
                    {localizationTags(`wallet-packages`).map((tag) => tag)}
                    {getCanonicalTag(`wallet-packages`)}
                </Helmet>
                {walletStore.walletOptions.data && (
                    <WalletPackagesRoot
                        backLink={from}
                        redirected={redirected}
                    />
                )}
            </Fragment>
        );
    }
}
