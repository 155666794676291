import { createAtom } from 'mobx';
import { services } from '../../services/index';

class AddressAtom {
    atom;
    addresses = [];

    constructor() {
        this.atom = createAtom('AddressAtom', () => {
            services.api.GetAddresses.run().then((addresses) => {
                this.addresses = addresses;
                this.atom.reportChanged(); // tells the world that "this.addresses" has changed
            });
        });
    }

    getAddresses() {
        if (this.atom.reportObserved()) {
            return this.addresses;
        }

        return this.addresses;
    }

    reload() {
        services.api.GetAddresses.runFresh().then((addresses) => {
            this.addresses = addresses;
            this.atom.reportChanged();
        });
    }
}

const store = new AddressAtom();
export default store;
