/* eslint-disable no-undef */
import { httpPost, httpGet } from '../http';

export const GetOrderSummary = (
    requestBody = {
        customer_id,
        card_id,
        payment_method,
        discount_code,
        orders,
    }
) => {
    return httpPost(`/v5/orders/prices`, requestBody, { authType: 'Bearer' });
};

export const PlaceOrder = (
    requestBody = {
        customer_id,
        card_id,
        payment_method,
        discount_code,
        orders,
    },
    device_data
) => {
    return httpPost('/v5/orders/package', requestBody, {
        authType: 'Bearer',
        additionalHeaders: { 'X-Braintree-Device-Data': device_data },
    });
};

export const checkWalletID = (walletID) =>
    httpGet(`/v5/orders/package/${walletID}`, {
        authType: 'Bearer',
    });

export const SendPaymentIntentID = (
    requestBody = {
        payment_intent_id,
    },
    device_data
) => {
    return httpPost('/v5/payment/stripe/complete', requestBody, {
        authType: 'Bearer',
        additionalHeaders: { 'X-Braintree-Device-Data': device_data },
    });
};

export const PlaceMealOrder = (
    requestBody = {
        customer_id,
        card_id,
        payment_method,
        discount_code,
        orders,
    },
    device_data
) => {
    return httpPost(`/v5/orders`, requestBody, {
        authType: 'Bearer',
        additionalHeaders: { 'X-Braintree-Device-Data': device_data },
    });
};

export const CheckPurchaseStatus = (menuOrderId) =>
    httpGet(`/v3/orders/meal/${menuOrderId}`, {
        authType: 'Bearer',
    });
