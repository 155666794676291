/*global google*/

import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import { parseLocation } from '../../../services/geoparser';
import { CircleLoader } from '../../core/Loading';
import AddressAutocomplete from '../../core/AddressAutocomplete';

const base = 'terminal-address-input';

@inject('terminalAddressStore', 'addressAutocompleteStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowingLabel: true,
            isOnClientSide: false,
        };
    }

    componentDidMount() {
        const KL = { lat: 3.139003, lng: 101.686855 };

        this.map = new google.maps.Map(this.mapNode, {
            center: KL,
            zoom: 16,
            scrollwheel: false,
        });

        this.marker = new google.maps.Marker({
            position: KL,
            map: this.map,
        });

        this.setState({
            isOnClientSide: true,
        });
    }

    componentWillUnmount() {
        google.maps.event.clearInstanceListeners(this.map);
        this.map = null;
    }

    handleHideMap() {
        this.setState({ isShowingLabel: true });
        this.mapNode.classList.add(`${base}__map--hidden`);
        google.maps.event.trigger(this.map, 'resize');
    }

    handleAddressSelected(prediction) {
        this.setState({ isShowingLabel: false });
        this.mapNode.classList.remove(`${base}__map--hidden`);
        google.maps.event.trigger(this.map, 'resize');

        const placeService = new google.maps.places.PlacesService(
            this.placeNode
        );
        placeService.getDetails(
            { placeId: prediction.place_id },
            (placeDetail, status) => {
                if (status === 'OK') {
                    const newPosition = new google.maps.LatLng(
                        placeDetail.geometry.location.lat(),
                        placeDetail.geometry.location.lng()
                    );

                    this.map.setCenter(newPosition);
                    this.marker.setPosition(newPosition);
                }
            }
        );
    }

    handleAddressConfirmed(place) {
        if (place) {
            const { terminalAddressStore } = this.props;

            terminalAddressStore.setPlace(place);
            terminalAddressStore.setNewAddress(parseLocation(place));
            terminalAddressStore.setStep(
                terminalAddressStore.STEP_ADJUST_ADDRESS
            );
        }
    }

    render() {
        const { addressAutocompleteStore } = this.props;
        return (
            <div className={base}>
                <div
                    ref={(node) => {
                        this.placeNode = node;
                    }}
                />
                <div
                    className={`${base}__map ${base}__map--hidden`}
                    ref={(node) => {
                        this.mapNode = node;
                    }}
                />
                <center>
                    {this.state.isShowingLabel ? (
                        <div className="text--bolder mtxl">
                            {intl.get('terminal.address.search')}
                        </div>
                    ) : null}

                    <div className={`${base}__input-container mtm`}>
                        {this.state.isOnClientSide === true ? (
                            <AddressAutocomplete
                                store={addressAutocompleteStore}
                                onClear={::this.handleHideMap}
                                onAddressSelected={::this.handleAddressSelected}
                                onAddressConfirmed={
                                    ::this.handleAddressConfirmed
                                }
                            />
                        ) : (
                            <CircleLoader />
                        )}
                    </div>
                </center>
            </div>
        );
    }
}
