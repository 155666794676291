import React, { Fragment } from 'react';
import { Provider } from 'mobx-react';

import BaseLayout from './core/baseLayout/Master_BaseLayout';
import MenuHeader from './core/headers/MenuHeader';
import MenuFooter from './core/MenuFooter';
import Entry from './page/businessPage/Entry';

import menuHeaderStore from '../store/menuHeaderStore';
import BusinessFormStore from '../store/businessFormStore';
import DatePickerStore from '../store/datePickerStore';
import PromotionBar from './core/PromotionBar';

const { clevertap } = window;

const businessFormStore = new BusinessFormStore();
const datePickerStore = new DatePickerStore();

const Header = () => (
    <Provider menuHeaderStore={menuHeaderStore}>
        <Fragment>
            <MenuHeader />
            <PromotionBar />
        </Fragment>
    </Provider>
);

const stores = {
    businessFormStore,
    datePickerStore,
};

export default class extends React.Component {
    componentDidMount() {
        clevertap.event.push('View Page', {
            'Page Title': document.title,
            Type: 'About',
        });
    }

    render() {
        return (
            <BaseLayout.Page HeaderComponent={Header}>
                <main>
                    <Provider {...stores}>
                        <Entry />
                    </Provider>
                </main>
                <Provider menuHeaderStore={menuHeaderStore}>
                    <MenuFooter />
                </Provider>
            </BaseLayout.Page>
        );
    }
}
