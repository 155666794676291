import Validator from '../../services/validator';

export const validator = new Validator()
    .add(
        'oldPassword',
        (model) => Boolean(model.oldPassword),
        'Please enter your current password'
    )
    .add(
        'newPassword',
        (model) => Boolean(model.newPassword),
        'Please enter a new password'
    )
    .add(
        'newPassword',
        (model) => model.newPassword.length > 5,
        'New Password should be at least 6 characters long'
    );

export const socialAccountValidator = new Validator()
    .add(
        'newPassword',
        (model) => Boolean(model.newPassword),
        'Please enter a new password'
    )
    .add(
        'newPassword',
        (model) => model.newPassword.length > 5,
        'New Password should be at least 6 characters long'
    );
