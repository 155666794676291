import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import Modal from '../../../core/Modal';
import { services } from '../../../../services/';
import PopmealsIcon from './PopmealsIcon';

const base = 'terminal-mobile-order-confirmation-screen';
const { clevertap } = window;

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    state = {
        showRatingModal: false,
    };

    componentDidMount() {
        const {
            terminalMenuStore: { terminalInfo },
        } = this.props;
        setTimeout(() => {
            if (terminalInfo.data.google_review_link) {
                this.setState({ showRatingModal: true });
            }
        }, 30 * 1000); //30seconds
    }

    handleClosePopup = () => {
        this.setState({ showRatingModal: false });
    };

    sendCleverTapEvent = () => {
        clevertap.event.push(
            'Tapped On Download App On Kiosk-On-Phone Order Confirmation'
        );
    };

    render() {
        const {
            terminalMenuStore: { customerName, terminalInfo },
            terminalCartStore,
        } = this.props;
        const { showRatingModal } = this.state;

        return (
            <Fragment>
                <div className={base}>
                    <div className={`${base}__content_container`}>
                        <img
                            className={`${base}__thank_you`}
                            src={
                                'https://image.popcontent.link/thank_you_text.svg'
                            }
                            alt="thank you"
                        />
                        <p className={`${base}__pay_text`}>
                            Please proceed to pay at the counter
                        </p>
                        <div className={`${base}__cash_price_container`}>
                            <div className={`${base}__cash_machine`}>
                                <img
                                    className={`${base}__cash_machine_img`}
                                    src={
                                        'https://image.popcontent.link/cash_machine_icon.svg'
                                    }
                                    alt="cash machine icon"
                                />
                            </div>

                            <div className={`${base}__price_container`}>
                                <p className="rm_text">RM </p>
                                <p className="price_text">
                                    {terminalCartStore.totalPrice}
                                </p>
                            </div>
                            <div className="circle circle1"></div>
                            <div className="circle circle2"></div>
                        </div>

                        <div className={`${base}__name_app_container`}>
                            <p className="name_text">Customer Name</p>
                            <p className="name">{customerName}</p>

                            <div className={`${base}__app_btns_container`}>
                                <div className={`${base}__pop_app_btns`}>
                                    <PopmealsIcon
                                        width={'68px'}
                                        height={'68px'}
                                    />

                                    <div
                                        className={`${base}__app_download_btns`}
                                    >
                                        <a
                                            href={
                                                'https://app.popmeals.com.my/kiosk-phone-app-download'
                                            }
                                            onClick={this.sendCleverTapEvent}
                                        >
                                            <img
                                                className={`${base}__app-button ${base}__app-store`}
                                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/app-store-black.png"
                                                alt={`Download the ${services.getParam(
                                                    'brandName'
                                                )} iOS app`}
                                            />
                                        </a>
                                        <a
                                            href={
                                                'https://app.popmeals.com.my/kiosk-phone-app-download'
                                            }
                                            onClick={this.sendCleverTapEvent}
                                        >
                                            <img
                                                className={`${base}__app-button ${base}__google-play`}
                                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/google-play-black.png"
                                                alt={`Download the ${services.getParam(
                                                    'brandName'
                                                )} Android app`}
                                            />
                                        </a>
                                    </div>
                                </div>
                                <a
                                    className={`${base}__rating_href`}
                                    href={
                                        'https://app.popmeals.com.my/kiosk-phone-app-download'
                                    }
                                    onClick={this.sendCleverTapEvent}
                                >
                                    <button
                                        className={`${base}__referral_cta_btn`}
                                    >
                                        {'DOWNLOAD THE APP &'}
                                        <br />
                                        {' GET RM5 OFF YOUR NEXT ORDER'}
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    showModal={showRatingModal}
                    showCloseIcon={false}
                    onBackdropClicked={this.handleClosePopup}
                    disableScrollingOnMobile={false}
                    showBackdropOnMobile={true}
                    contentClasses={'rounded'}
                    manualStyles={{ width: '295px' }}
                >
                    <div className={`${base}__rating_modal`}>
                        <p className={`${base}__rating_modal_title`}>
                            Support Pop Meals and give 5 stars!
                        </p>
                        <a
                            className={`${base}__rating_href`}
                            href={terminalInfo.data.google_review_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                                this.setState({ showRatingModal: false });
                            }}
                        >
                            <button className={`${base}__modal_cta_btn`}>
                                Leave a review
                            </button>
                        </a>
                        <button
                            onClick={this.handleClosePopup}
                            className={`${base}__modal_later_btn`}
                        >
                            Maybe later
                        </button>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}
