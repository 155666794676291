import React from 'react';
import { inject, observer } from 'mobx-react';

import PopupStarRating from './PopupStarRating';
import PopupNPSRating from './PopupNPSRating';
import PopupFeedbackComment from './PopupFeedbackComment';
import PopupThankYou from './PopupThankYou';

@inject('orderRatingStore')
@observer
export default class extends React.Component {
    render() {
        const { orderRatingStore } = this.props;

        if (
            orderRatingStore.showingPopup === orderRatingStore.POPUP_STAR_RATING
        ) {
            return <PopupStarRating />;
        }

        if (
            orderRatingStore.showingPopup === orderRatingStore.POPUP_NPS_RATING
        ) {
            return <PopupNPSRating />;
        }

        if (
            orderRatingStore.showingPopup ===
            orderRatingStore.POPUP_FEEDBACK_COMMENT
        ) {
            return <PopupFeedbackComment />;
        }

        if (
            orderRatingStore.showingPopup === orderRatingStore.POPUP_THANK_YOU
        ) {
            return <PopupThankYou />;
        }

        return null;
    }
}
