import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import PaymentMethodType from '../../core/PaymentMethodType';
import { services } from '../../../services/index';
import { cities, GetCity } from '../../../services/city';

const base = 'add-payment-method';

@inject('paymentStore', 'paypalStore', 'store')
@observer
export default class extends React.Component {
    chooseOnlineBanking = () => {
        const { paymentStore, store } = this.props;

        let stubOnlineBanking;
        if (services.getParam('paymentGateway') === 'omise') {
            // show the selector
            store.showBankSelector = true;
            stubOnlineBanking = {
                id: 'billplz-temp',
                card_type: 'Online Banking',
                payment_method_type: 'Omise_Bank',
                description:
                    'You will be redirected to complete checkout with FPX payment.',
            };
        } else {
            stubOnlineBanking = {
                id: 'billplz-temp',
                card_type: 'BillPlz',
                payment_method_type: 'BillPlz',
                description:
                    'You will be redirected to complete checkout with FPX payment.',
            };
        }

        paymentStore.addPaymentMethod(stubOnlineBanking);
        store.setPaymentMethod(stubOnlineBanking);
        paymentStore.goToView(paymentStore.SHOW_VIEW)();
    };

    chooseTruemoney = () => {
        const { paymentStore, store } = this.props;

        const stubOnlineBanking = {
            id: 'truemoney',
            card_type: 'TrueMoney',
            payment_method_type: 'Omise_Bank',
            description:
                'You will be redirected to complete checkout with FPX payment.',
            fpx_gateway: 'truemoney',
        };

        paymentStore.addPaymentMethod(stubOnlineBanking);
        store.setPaymentMethod(stubOnlineBanking);
        paymentStore.goToView(paymentStore.SHOW_VIEW)();
    };

    chooseBoost = () => {
        const { paymentStore, store } = this.props;

        const stubBoost = {
            id: 'boost',
            card_type: 'Boost',
            payment_method_type: 'Boost',
            description:
                'You will be redirected to the Boost app to complete payment',
        };

        paymentStore.addPaymentMethod(stubBoost);
        store.setPaymentMethod(stubBoost);
        paymentStore.goToView(paymentStore.SHOW_VIEW)();
    };

    chooseCOD = () => {
        const { paymentStore, store } = this.props;
        const stubCOD = {
            id: 'cod-temp',
            card_type: 'Cash',
            payment_method_type: 'Cash',
            description: 'Pay directly to the driver.',
        };

        paymentStore.addPaymentMethod(stubCOD);
        store.setPaymentMethod(stubCOD);
        paymentStore.goToView(paymentStore.SHOW_VIEW)();
    };

    choosePaypal = () => {
        this.props.paypalStore.doAddPaypal();
    };

    componentDidMount() {
        this.props.paypalStore.initPaypal();
    }

    componentWillUnmount() {
        this.props.paypalStore.reset();
    }

    renderPaypalButton() {
        const { paypalStore } = this.props;

        if (paypalStore.isBusy) {
            return (
                <li className="mts mbm">
                    <button className={`button button--busy ${base}__button`}>
                        <span className="pts pbs">
                            {intl.get('packageCheckout.payment.paypalInit')}
                        </span>
                    </button>
                </li>
            );
        }

        return (
            <PaymentMethodType
                onClick={this.choosePaypal}
                text={intl.get('packageCheckout.payment.add.paypal')}
                paymentProviderIcon="payment-provider payment-provider--paypal"
            />
        );
    }

    render() {
        const { paymentStore, paypalStore } = this.props;
        const isKL = GetCity() === cities.kl;
        const isBKK = GetCity() === cities.bangkok;

        if (paypalStore.isAddingPaypal) {
            return (
                <div className={base}>
                    {intl.get('packageCheckout.payment.paypalLoading')}
                </div>
            );
        }

        return (
            <div className={base}>
                <div className="display--flex flex-align-items-center">
                    <div className="flex-1">
                        <p className="text--bold">
                            {intl.get('packageCheckout.payment.add.title')}
                        </p>
                    </div>
                </div>
                <ul className={`${base}__list`}>
                    <Fragment>
                        <PaymentMethodType
                            onClick={this.chooseOnlineBanking}
                            text={intl.get(
                                `packageCheckout.payment.add.onlineBanking_${services.getParam(
                                    'city'
                                )}`
                            )}
                            paymentProviderIcon="payment-provider payment-provider--onlinebanking"
                        />
                        {!window.location.pathname.includes('/wallet') && (
                            <PaymentMethodType
                                onClick={this.chooseCOD}
                                text="Cash on Delivery"
                                paymentProviderIcon="payment-provider payment-provider--cash"
                            />
                        )}
                        <PaymentMethodType
                            onClick={paymentStore.goToView(
                                paymentStore.ADD_CREDIT_CARD
                            )}
                            text={intl.get(
                                'packageCheckout.payment.add.creditcard'
                            )}
                            paymentProviderIcon="payment-provider payment-provider--unknown"
                        />
                        {isKL && (
                            <PaymentMethodType
                                onClick={this.chooseBoost}
                                text={intl.get(
                                    'packageCheckout.payment.add.boost'
                                )}
                                paymentProviderIcon="payment-provider payment-provider--boost"
                            />
                        )}
                        {isBKK && (
                            <PaymentMethodType
                                onClick={this.chooseTruemoney}
                                text={intl.get(
                                    'packageCheckout.payment.add.truemoney'
                                )}
                                paymentProviderIcon="payment-provider payment-provider--truemoney"
                            />
                        )}
                        {this.renderPaypalButton()}
                    </Fragment>
                </ul>
            </div>
        );
    }
}
