import { observable, action } from 'mobx';
import Cookies from 'js-cookie';

import { services } from '../services/index';

class terminalAuthStore {
    @observable authed = null;

    @action
    checkAuth = () => {
        const city = services.getParam('city');
        if (!Cookies.get(`${city}_Authorization`)) {
            this.authed = false;
        } else {
            this.authed = true;
        }
    };
}

const store = new terminalAuthStore();
export default store;
