import React, { Fragment } from 'react';
import { Provider } from 'mobx-react';

import { Link } from '../services/types';
import BaseLayout from './core/baseLayout/Master_BaseLayout';
import MenuHeader from './core/headers/MenuHeader';
import MenuFooter from './core/MenuFooter';
import Entry from './page/rewardsPage/Entry';

import menuHeaderStore from '../store/menuHeaderStore';
import loginPopupStore from '../store/loginPopupStore';
import PromotionBar from './core/PromotionBar';

const { clevertap } = window;

loginPopupStore.setBackLink(new Link('/menu', 'BACK TO MENU'));

const Header = () => (
    <Provider menuHeaderStore={menuHeaderStore}>
        <Fragment>
            <MenuHeader />
            <PromotionBar />
        </Fragment>
    </Provider>
);

export default class extends React.Component {
    componentDidMount() {
        clevertap.event.push('View Page', {
            'Page Title': document.title,
            'Page Type': 'Rewards',
            'Page URL': window.location.href,
        });
    }

    render() {
        return (
            <BaseLayout.Page HeaderComponent={Header}>
                <main>
                    <Entry />
                </main>
                <Provider menuHeaderStore={menuHeaderStore}>
                    <MenuFooter />
                </Provider>
            </BaseLayout.Page>
        );
    }
}
