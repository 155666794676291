import { observable, action } from 'mobx';

import { ValidationResult } from '../services/validator';
import {
    validator,
    socialAccountValidator,
} from './validators/profilePasswordValidator';
import { RESOURCE } from '../services/types';
import UserServices from './services/userServices';
import { services } from '../services/index';

export default class {
    @observable willShowOldPassword = false;
    @observable oldPassword = '';
    @observable newPassword = '';
    @observable.ref validationResult = new ValidationResult();
    @observable.ref passwordUpdater = RESOURCE.Initial;
    @observable userType;

    @action updateModel(e) {
        this[e.currentTarget.name] = e.currentTarget.value;
    }

    @action fetchUserInfo() {
        this.userFetcher = RESOURCE.Loading;

        services.api.GetUserWithMemoizer.run()
            .then((user) => {
                this.userType = UserServices.from(user).getUserType(true);

                if (this.userType === 'Normal') {
                    this.willShowOldPassword = true;
                }

                this.userFetcher = RESOURCE.Success(user);
            })
            .catch((response) => {
                this.userFetcher = RESOURCE.Failure(response.message, response);
                services.bannerEvents.showError(response.message);
            });
    }

    @action savePassword() {
        services.api
            .UpdateUser({
                password: this.newPassword,
            })
            .then(() => {
                this.newPassword = '';
                this.oldPassword = '';
                this.passwordUpdater = RESOURCE.Success({});
                services.bannerEvents.showSuccess('Updated successfully');
                this.userType = 'Normal';
                this.willShowOldPassword = true;
            })
            .catch((response) => {
                this.passwordUpdater = RESOURCE.Failure(
                    response.message,
                    response
                );
                services.bannerEvents.showError(response.message);
                this.newPassword = '';
                this.oldPassword = '';
            });
    }

    @action updatePassword(e) {
        e.preventDefault();

        const model = {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
        };

        if (this.userType !== 'Normal') {
            this.validationResult = socialAccountValidator
                .validate(model)
                .toSingleErrorMode();
            if (this.validationResult.pass()) {
                this.passwordUpdater = RESOURCE.Loading;
                this.savePassword();
            }
        } else {
            this.validationResult = validator
                .validate(model)
                .toSingleErrorMode();
            if (this.validationResult.pass()) {
                this.passwordUpdater = RESOURCE.Loading;

                services.api
                    .UserLogin(this.userFetcher.data.username, this.oldPassword)
                    .then((credentials) => {
                        this.savePassword();
                    })
                    .catch((responseBody) => {
                        this.passwordUpdater = RESOURCE.Failure(
                            responseBody.message,
                            responseBody
                        );
                        services.bannerEvents.showError(responseBody.message);
                        this.newPassword = '';
                        this.oldPassword = '';
                    });
            }
        }
    }
}
