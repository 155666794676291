import React from 'react';
import cx from 'classnames';

export default class extends React.Component {
    static defaultProps = {
        initialCollapse: true,
        titleClasses: '',
        contentClasses: '',
        toggleClasses: '',
    };

    constructor(props) {
        super(props);

        this.state = { isCollapsing: this.props.initialCollapse };
        this.toggleCollapsing = ::this.toggleCollapsing;
    }

    toggleCollapsing() {
        this.setState({ isCollapsing: !this.state.isCollapsing });
    }

    render() {
        const base = 'collapsible';
        const collapseIcon = this.state.isCollapsing
            ? '#icon-chevron-thin-down'
            : '#icon-chevron-thin-up';
        const toggleClasses = cx(
            `${base}__collapse iconmoon-icon`,
            this.props.toggleClasses
        );
        const contentClasses = cx(
            `${base}__content`,
            this.props.contentClasses,
            {
                [`${base}__content--collapsed`]: this.state.isCollapsing,
            }
        );
        const titleClasses = cx('display--flex', this.props.titleClasses);
        const finalTitleClass = cx(titleClasses, {
            [this.props.titleCollapsedClasses]: this.state.isCollapsing,
        });

        return (
            <div className={base}>
                <div className={finalTitleClass}>
                    <p className="text--bolder flex-1 lh--150">
                        {this.props.title}
                    </p>
                    <button
                        type="button"
                        className={`${base}__toggle`}
                        onClick={this.toggleCollapsing}
                    >
                        <svg className={toggleClasses}>
                            <use xlinkHref={collapseIcon} />
                        </svg>
                    </button>
                </div>

                <div className={contentClasses}>{this.props.children}</div>
            </div>
        );
    }
}
