import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import WalletProgress from '../page/walletPage/WalletProgress';
import { RESOURCE } from '../../services/types';
import { currency } from '../../services';
import { trackLink } from './ClevertapHelpers';
import intl from 'react-intl-universal';
import { services } from '../../services/index';

const { clevertap } = window;
const base = 'wallet-status-dropdown';

@inject('walletStatusStore')
@observer
class WalletStatusInDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = { topupLoading: false };
    }

    sendTracking(event) {
        clevertap.event.push(event);
    }

    storeSelectedWalletToStorage = (storedWallet) => {
        //checking if wallet package has all data, falling back to packages page in case not
        let goToPackages = false;
        for (var property in storedWallet) {
            if (
                storedWallet[property] === null ||
                storedWallet[property] === '' ||
                typeof storedWallet[property] === 'undefined'
            ) {
                goToPackages = true;
            }
        }
        if (goToPackages) {
            this.props.history.push('/wallet-packages');
        } else {
            services.api.SelectingWalletPackage.set(
                JSON.stringify(storedWallet)
            );
            this.setState({ topupLoading: true });

            window.dataLayer.push({
                currency: services.getParam('currencyCode'),
                value: currency.to2digits(storedWallet.price),
                event: 'AddToCartFbPixel',
                content_name: storedWallet.title,
                content_type: 'Meal_Package',
            });

            trackLink('Clicked Top Up in Wallet Dropdown', {
                Package: storedWallet.title,
            })
                .then(() => {
                    window.location.href = '/wallet/checkout';
                })
                .catch(() => {
                    window.location.href = '/wallet/checkout';
                });
        }
    };

    handleTopUpClicked(enable) {
        const { walletStatusStore } = this.props;

        walletStatusStore.changeAutoTopup(enable);
        enable
            ? clevertap.event.push('Attempted to enable Easy Reload')
            : clevertap.event.push('Attempted to disable Easy Reload');
    }

    render() {
        const { walletStatusStore } = this.props;
        const walletStatus = walletStatusStore.walletStatus.data;

        const id = walletStatus.auto_top_up_package_id;
        const title = walletStatus.auto_top_up_title;
        const size = walletStatus.auto_top_up_package_size;
        const price = walletStatus.auto_top_up_package_price;
        const dataToStore = {
            id,
            title,
            size,
            price,
        };

        if (walletStatus.has_wallet) {
            return (
                <div className={base}>
                    <div className={`${base}__row display--flex`}>
                        <div
                            className={`${base}__col-label fs--small-medium lh--125  width--33 text--bold`}
                        >
                            {intl.get('walletStatusInDropDown.meals.title')}
                        </div>
                        <div className="width--33">
                            <WalletProgress balance={walletStatus.balance} />
                        </div>
                        <div className="width--33">
                            <div
                                onClick={() =>
                                    this.storeSelectedWalletToStorage(
                                        dataToStore
                                    )
                                }
                                className={`${base}__cta text--center text--bolder `}
                            >
                                {this.state.topupLoading
                                    ? '...'
                                    : intl.get(
                                          'walletStatusInDropDown.meals.cta'
                                      )}
                            </div>
                        </div>
                    </div>

                    <div className={`${base}__row display--flex`}>
                        <div
                            className={`${base}__col-label fs--small-medium lh--125  width--33 text--bold`}
                            data-tip={intl.get(
                                'menu.header.smartReloadTooltip'
                            )}
                        >
                            {intl.get(
                                'walletStatusInDropDown.smartReload.title'
                            )}
                        </div>
                        <div className="fs--medium-large text--bold width--33">
                            {walletStatus['auto_top_up_enabled'] ? 'On' : 'Off'}
                        </div>
                        <div className="width--33">
                            <div
                                onClick={() =>
                                    this.handleTopUpClicked(
                                        walletStatus['auto_top_up_enabled']
                                            ? false
                                            : true
                                    )
                                }
                                className={`${base}__cta text--center text--bolder `}
                            >
                                {walletStatus['auto_top_up_enabled']
                                    ? intl.get(
                                          'walletStatusInDropDown.smartReload.cta2'
                                      )
                                    : intl.get(
                                          'walletStatusInDropDown.smartReload.cta1'
                                      )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(WalletStatusInDropdown);
