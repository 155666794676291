import Validator from '../../services/validator';

export default new Validator()
    .add(
        'countryCode',
        (model) => Boolean(model.countryCode),
        'Country Code is required.'
    )
    .add(
        'number',
        (model) => String(model.number || '').length >= 8,
        'Phone Number must be greater than 8 digits.'
    );
