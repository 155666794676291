import { observable, computed, action, observe, when } from 'mobx';

import CartServices from '../store/services/cartServices';
import { UserCart } from '../services/api/userCartServices';
import { RESOURCE } from '../services/types';
import Notifiable from './services/notifiable';
import { currency } from '../services';
import { services } from '../services/index';

const { clevertap } = window;

const INTERVAl = 10 * 1000;

export default class extends Notifiable {
    PENDING = 'Pending';
    COMPLETE = 'Complete';
    CANCELLED = 'Cancelled';
    FAILED = 'Failed';

    @observable.ref status = RESOURCE.Initial;
    @observable.ref addresses = RESOURCE.Initial;
    @observable.ref user = RESOURCE.Initial;
    @observable.ref cart = UserCart.getCart() ? UserCart.getCart() : [];
    @observable message = '';
    @observable reviewPromptModalShowing = false;

    @computed get itemsInCart() {
        let itemsInCart = [];
        this.cart.forEach((dateOrder) => {
            itemsInCart = itemsInCart.concat.apply([], dateOrder.menu_item);
        });

        return itemsInCart;
    }

    @computed get cartServices() {
        return new CartServices(this.itemsInCart);
    }

    menuOrderId;

    constructor(menuOrderId) {
        super();

        this.menuOrderId = menuOrderId;
        this.setUpNotification({ showCloseIcon: false });
    }

    loadData() {
        this.startPolling();
    }

    @action loadUser() {
        this.user = RESOURCE.Loading;

        services.api
            .GetUser()
            .then((user) => {
                this.user = RESOURCE.Success(user);
            })
            .catch((responseData) => {
                this.user = RESOURCE.Failure(
                    responseData.message,
                    responseData
                );
                this.message = responseData.message;
            });
    }

    @action loadAddresses() {
        this.addresses = RESOURCE.Loading;

        services.api.GetAddresses.runFresh()
            .then((addresses) => {
                this.addresses = RESOURCE.Success(addresses);
            })
            .catch((responseData) => {
                this.addresses = RESOURCE.Failure(
                    responseData.message,
                    responseData
                );
                this.message = responseData.message;
            });
    }

    @action loadStatus() {
        this.status = RESOURCE.Loading;

        services.api
            .CheckPurchaseStatus(this.menuOrderId)
            .then((responseData) => {
                if (responseData['transaction_status'] === this.COMPLETE) {
                    this.status = RESOURCE.Success(responseData);
                    if (
                        services.getParam('showGoogleMapsReviewPrompt') &&
                        responseData.first_order
                    ) {
                        this.reviewPromptModalShowing = true;
                    }
                    this.sendTrackingEvents(responseData);
                } else if (
                    responseData['transaction_status'] === this.PENDING
                ) {
                    this.status = RESOURCE.Loading;
                } else {
                    throw responseData;
                }
            })
            .catch((responseData) => {
                if (responseData.status === 404) {
                    this.status = RESOURCE.Failure(
                        `Order number ${this.walletOrderId} is invalid.`,
                        responseData
                    );
                } else {
                    this.status = RESOURCE.Failure(
                        responseData.message,
                        responseData
                    );
                }

                this.message = this.status.message;
                window.clearInterval(this.interval);
            })
            .then(() => {
                const isSuccessAlready = RESOURCE.Success.is(this.status);
                const isNotPending =
                    isSuccessAlready &&
                    this.status.data['transaction_status'] !== 'Pending';

                if (isNotPending) {
                    window.clearInterval(this.interval);
                }
            });
    }

    sendTrackingEvents(orderSummary) {
        when(
            () => RESOURCE.Success.is(this.user),
            () => {
                clevertap.profile.push({
                    Site: {
                        'Last Order Number': '#' + orderSummary['group_id'],
                    },
                });
            }
        );

        window.dataLayer.push({
            event: 'transactionComplete',
            ecommerce: {
                purchase: {
                    actionField: {
                        id: orderSummary['group_id'],
                        revenue: orderSummary['gross_total'],
                    },
                    products: this.cartServices.getGTMProductList(),
                },
            },
        });

        window.dataLayer.push({
            event: 'Purchase',
            value: currency.to2digits(orderSummary.gross_total),
            currency: services.getParam('currencyCode'),
            content_name: this.cartServices.getGTMProductList(),
            content_type: 'product',
            content_ids: this.cartServices
                .getGTMProductList()
                .map((item) => item.id),
            contents: this.cartServices
                .getGTMProductList()
                .map((item) => ({
                    id: item.id,
                    quantity: item.quantity,
                    item_price: item.price,
                })),
        });

        window.dataLayer.push({
            event: orderSummary['first_order'] ? 'first_order' : 'reorder',
            valueToSum: orderSummary['gross_total'],
        });

        //criteo data
        window.dataLayer.push({
            PageType: 'TransactionPage',
            TransactionID: orderSummary.group_id,
        });
        var product_list = [];
        this.cartServices.getGTMProductList().forEach((item) => {
            product_list.push({
                id: item.menu_id,
                price: item.price,
                quantity: '1',
            });
        });
        window.dataLayer.push({
            ProductTransactionProducts: product_list,
        });
    }

    startPolling() {
        this.loadStatus();

        this.interval = window.setInterval(::this.loadStatus, INTERVAl);
    }

    hidePopup(clickedYes) {
        this.reviewPromptModalShowing = false;

        clevertap.event.push('Prompted for Google Maps Review', {
            accepted: clickedYes,
        });
    }

    clearCart() {
        services.api.UserCart.clear();
    }
}
