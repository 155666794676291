import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';

import PopupHeader from './PopupHeader';
import { RESOURCE } from '../../../../services/types';
import { SMALL_SCREEN_MEDIA } from '../../../../services/constants';
import FloatingCta from '../../../core/FloatingCta';

const base = 'shopping-cart-address';

@inject('deliveryAddressStore', 'menuCheckoutStore')
@observer
export default class extends Component {
    handleTypeClicked(type) {
        const store = this.props.deliveryAddressStore;

        store.newAddress.deliveryInstruction = type;
    }

    handleCreateAddress(e) {
        const { menuCheckoutStore, deliveryAddressStore, date } = this.props;
        e.preventDefault();

        deliveryAddressStore.saveAddress({ date }).then(() => {
            if (menuCheckoutStore.clickedGoToCheckout) {
                deliveryAddressStore.selectedAddress.address_incomplete = false;
                menuCheckoutStore.handleGoToCheckout();
            }
        });
    }

    render() {
        const store = this.props.deliveryAddressStore;
        const { deliveryPreferencesFetcher, deliveryInstructionValRes } = store;
        const place = store.getPlace()
            ? store.getPlace()
            : store.editableAddress;
        const explainingPopup = store.getExplainingPopup();
        const buttonClasses = store.isSavingAddress
            ? `${base}__confirm button button--success button--bigger-on-mobile button--busy`
            : `${base}__confirm button button--success button--bigger-on-mobile`;
        const formattedAddress =
            place.suggested_address || place.formatted_address || place.name;

        return (
            <form onSubmit={::this.handleCreateAddress}>
                <PopupHeader
                    text={intl.get('shoppingCart.deliveryAddress.popup.title')}
                    customClasses="shopping-cart-address__popup-header__extra-margin"
                />
                <div className={`${base}__popup--note fs--small-medium`}>
                    <hr className={`${base}__separator show--if-large`} />
                    <div
                        className={`${base}__address-preview display--flex flex-align-items-center ptm pbm`}
                    >
                        <svg
                            className={`${base}__pin-icon iconmoon-icon flex-none text--primary`}
                        >
                            <use xlinkHref="#icon-location" />
                        </svg>
                        <p className="flex-1-only ellipsis fs--small-medium">
                            {formattedAddress}
                        </p>
                    </div>
                    <hr className={`${base}__separator show--if-large`} />
                    <p
                        className={`${base}__title mtm text--bolder fs--medium-medium-large`}
                    >
                        {intl.get(
                            'shoppingCart.deliveryAddress.popupDelivery.header'
                        )}
                    </p>
                    {deliveryPreferencesFetcher !== RESOURCE.Loading ? (
                        <DeliveryPreferences deliveryAddressStore={store} />
                    ) : (
                        <div style={{ marginBottom: '20px' }}>Loading...</div>
                    )}

                    <p
                        id="inputError"
                        className={`${base}__error-message display--none-if-empty text--center text--red ptm fs--small-medium`}
                    >
                        {deliveryInstructionValRes.pass()
                            ? ''
                            : deliveryInstructionValRes.getFirstError()}
                    </p>
                    <div className="delivery-preference__most-convenient-icon-container display--flex flex-align-items-center">
                        <div className="delivery-preference__most-convenient-icon mrxs" />
                        <div className="fs--small">
                            {intl.get(
                                'shoppingCart.deliveryAddress.popupDelivery.convenient'
                            )}
                        </div>
                        <a
                            className="delivery-preference__learn-more text--secondary cursor-pointer fs--small"
                            onClick={() => store.showPopup(explainingPopup)}
                        >
                            {intl.get(
                                'shoppingCart.deliveryAddress.popupDelivery.learnMore'
                            )}
                        </a>
                    </div>
                    <div
                        className={`${base}__title text--bolder fs--medium-medium-large`}
                    >
                        {intl.get(
                            'shoppingCart.deliveryAddress.popupDelivery.instruction'
                        )}
                    </div>
                    <textarea
                        id="inputFurtherInstruction"
                        className={`${base}__delivery-note text--thin`}
                        name="additionalInfo"
                        placeholder={intl.get(
                            'deliveryAddress.popupNote.placeholder'
                        )}
                        value={store.newAddress.additionalInfo}
                        onChange={::store.updateNewAddress}
                    />
                </div>
                <div className={`${base}__popup-footer`}>
                    {window.matchMedia(SMALL_SCREEN_MEDIA).matches === true ? (
                        <FloatingCta
                            centerText={intl.get(
                                'shoppingCart.deliveryAddress.popupDelivery.cta'
                            )}
                        />
                    ) : (
                        <button className={buttonClasses}>
                            {intl.get(
                                'shoppingCart.deliveryAddress.popupDelivery.cta'
                            )}
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

class DeliveryPreferences extends Component {
    state = {
        options: null,
    };

    componentDidMount() {
        const store = this.props.deliveryAddressStore;

        const options = this.getOptions();
        this.setState({
            options: options,
            deliveryPreference: options[0],
        });
        store.setDeliveryInstruction(options[0].value);
    }

    getOptions = () => {
        const store = this.props.deliveryAddressStore;
        const { deliveryPreferencesFetcher } = store;
        const preferences = deliveryPreferencesFetcher.data.preferences;

        return preferences.map((preference) => ({
            value: preference.key,
            label: (
                <div className="delivery-preference__label">
                    {preference.text}{' '}
                    {preference.thumbs_up ? (
                        <div className="delivery-preference__thumbs"></div>
                    ) : (
                        <div></div>
                    )}
                </div>
            ),
            style: { padding: '12px', margin: '12px' },
        }));
    };

    handleSetDeliveryPreference(preference) {
        const store = this.props.deliveryAddressStore;

        store.setDeliveryInstruction(preference);
    }

    handleSelect = (deliveryPreference) => {
        this.setState({ deliveryPreference });
        this.handleSetDeliveryPreference(deliveryPreference.value);
    };

    render() {
        const { options } = this.state;
        return (
            options && (
                <Select
                    className="delivery-preference__select-container"
                    classNamePrefix="delivery-preference__select"
                    value={this.state.deliveryPreference}
                    onChange={this.handleSelect}
                    defaultValue={options[0]}
                    options={options}
                    isSearchable={false}
                />
            )
        );
    }
}
