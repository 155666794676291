import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import template from 'lodash/template';
import intl from 'react-intl-universal';
import { uniqBy } from 'lodash';

import { RESOURCE, MAYBE } from '../../../services/types';
import { CircleLoader } from '../../core/Loading';
import LoginPopup from '../../core/LoginPopup';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import PopupDeliveryDetail from '../../core/orderStatus/PopupDeliveryDetail';
import PopupCancel from '../../core/changeOrder/PopupCancel';
import Modal from '../../core/Modal';
import PopupRouter from '../../core/ratings/PopupRouter';
import { services } from '../../../services/index';
import HalalPopup from '../../core/HalalPopup';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

const base = 'order-history';

@inject(
    'loginPopupStore',
    'orderHistoryStore',
    'orderStatusStore',
    'orderRatingStore',
    'userStore'
)
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.loginPopupStore.checkUser();
        this.props.orderHistoryStore.loadOrderHistory();
    }

    renderList() {
        const { orderHistoryStore } = this.props;
        const { orderHistory } = orderHistoryStore;
        const { upcomingOrders } = orderHistoryStore;

        if (
            orderHistory === RESOURCE.Loading ||
            upcomingOrders === RESOURCE.Loading
        ) {
            return (
                <div className={`${base}__loading`}>
                    <div>
                        <CircleLoader />
                    </div>
                    {intl.get('orderHistory.loading')}
                </div>
            );
        }

        if (
            RESOURCE.Success.is(orderHistory) &&
            RESOURCE.Success.is(upcomingOrders)
        ) {
            const { deliveries } = orderHistory.data;
            const upcoming = upcomingOrders.data.deliveries;

            let combinedDeliveries = upcoming.concat(deliveries);
            const lastRow = combinedDeliveries
                ? combinedDeliveries.length - 1
                : null;
            combinedDeliveries = uniqBy(combinedDeliveries, 'id');
            return combinedDeliveries.map((order, index) => {
                return (
                    <TableRow
                        key={order.id}
                        order={order}
                        index={index}
                        lastRow={lastRow}
                    />
                );
            });
        }

        return null;
    }

    handleCloseRating = () => {
        const { orderStatusStore, orderRatingStore } = this.props;
        orderRatingStore.setOrder(null);
        orderStatusStore.deliveryInfoFetcher = RESOURCE.Initial;
    };

    render() {
        const {
            orderHistoryStore,
            orderStatusStore,
            orderRatingStore,
            userStore,
        } = this.props;
        const isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
        const { showHalalPopup, setHideHalalPopup } = userStore;
        let content = null;

        if (orderHistoryStore.isEmpty) {
            content = (
                <div className={`${base}__content text--center`}>
                    <h3 className={`${base}__empty-title`}>
                        {intl.get('orderHistory.title')}
                    </h3>
                    <p className={`${base}__empty-subheading`}>
                        {intl.get('orderHistory.subheading')}
                    </p>
                    <Link to="/menu">
                        <button
                            className={`${base}__empty-cta button button--success`}
                        >
                            {intl.get('orderHistory.cta')}
                        </button>
                    </Link>
                </div>
            );
        } else {
            content = (
                <div className={`${base}__content`}>
                    <div className={`${base}__table-container border--rounded`}>
                        <TableHeader />
                        {this.renderList()}
                    </div>
                </div>
            );
        }

        return (
            <div>
                <LoginPopup />
                <h1 className={`${base}__header`}>
                    {intl.get('orderHistory.title')}
                </h1>
                {services.getParam('showChallenge') ? (
                    <div className={`${base}__content-card`}>
                        {orderHistoryStore.ordersThisWeek !== null ? (
                            <div
                                className={`${base}__content-card-row row lh--150`}
                            >
                                <p className="text--sofiapro">
                                    {intl.get('orderHistory.challenge.header')}
                                </p>
                                <p>
                                    {template(
                                        intl.get(
                                            'orderHistory.challenge.ordersPlaced'
                                        )
                                    )({
                                        orders: orderHistoryStore.ordersThisWeek,
                                    })}
                                </p>
                                {orderHistoryStore.ordersThisWeek > 2 ? (
                                    <p>
                                        {intl.get(
                                            'orderHistory.challenge.eligible'
                                        )}
                                    </p>
                                ) : (
                                    <p>
                                        {template(
                                            intl.get(
                                                'orderHistory.challenge.uneligible'
                                            )
                                        )({
                                            ordersLeft:
                                                3 -
                                                orderHistoryStore.ordersThisWeek,
                                        })}
                                    </p>
                                )}
                                <Link to="/menu">
                                    <button
                                        className={`${base}__content-card-cta button button--success mbm mtm`}
                                    >
                                        {intl.get('orderHistory.challenge.cta')}
                                    </button>
                                </Link>
                            </div>
                        ) : (
                            <div className={`${base}__content-card-row row`}>
                                <p>Loading ...</p>
                            </div>
                        )}
                    </div>
                ) : null}
                {content}
                {showHalalPopup && isSmallScreen && (
                    <HalalPopup
                        showModal={showHalalPopup}
                        handleClose={setHideHalalPopup}
                    />
                )}
                <Modal
                    showModal={orderStatusStore.isShowingDetail}
                    showCloseIcon={true}
                    contentClasses="modal__content--full-mobile"
                    contentId="order-status-popup"
                    onBackdropClicked={::orderStatusStore.setShowingDetail(
                        false
                    )}
                    doNotHideBanner={true}
                >
                    <PopupDeliveryDetail />
                </Modal>
                <Modal
                    showModal={MAYBE.Some.is(orderRatingStore.order)}
                    showCloseIcon={true}
                    contentClasses="modal__content--full-mobile"
                    closeButtonClasses="rating-popup__close"
                    onBackdropClicked={this.handleCloseRating}
                >
                    <PopupRouter />
                </Modal>
                <Modal
                    showBackdropOnMobile={true}
                    showModal={orderStatusStore.isShowingPopup}
                    contentClasses="order-status-popup__cancel-popup"
                    closeButtonClasses="fill--white"
                    onBackdropClicked={orderStatusStore.setShowPopup(false)}
                    onCloseClicked={orderStatusStore.setShowPopup(false)}
                    showCloseIcon={true}
                >
                    <PopupCancel />
                </Modal>
            </div>
        );
    }
}
