import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import AddressTypePicker from './AddressTypePicker';
import PopupHeader from './PopupHeader';
import {
    OFFICE_ADDRESS,
    HOME_ADDRESS,
    SMALL_SCREEN_MEDIA,
} from '../../../../services/constants';
import FloatingCta from '../../../core/FloatingCta';

const base = 'shopping-cart-address';
const { clevertap } = window;

@inject('deliveryAddressStore', 'userStore')
@observer
export default class extends React.Component {
    state = {};
    handleFormSubmit(e) {
        e.preventDefault();

        const store = this.props.deliveryAddressStore;
        const result = store.validate();

        if (result.pass()) {
            store.showPopup(store.POPUP_NOTE);
            store.fetchAddressPreference();
        } else {
            document.getElementById('inputError') &&
                document.getElementById('inputError').scrollIntoViewIfNeeded();
        }

        clevertap.event.push('Confirmed Address details', {
            'Building Type': store.newAddress.addressType,
        });
    }

    renderBuildings() {
        const store = this.props.deliveryAddressStore;
        const { newAddress, adjustAddressValRes } = store;
        const updateNewAddress = ::store.updateNewAddress;

        if (newAddress.addressType === HOME_ADDRESS) {
            return null;
        }

        return (
            <div className={`${base}__input-area`}>
                <div className={`${base}__input-container building-name`}>
                    <label
                        className={`${base}__address-label`}
                        htmlFor="inputBuildingName"
                    >
                        {intl.get(
                            'shoppingCart.deliveryAddress.popup.buildingName'
                        )}
                    </label>
                    <br />
                    <input
                        id="inputBuildingName"
                        name="buildingName"
                        className={`${base}__input input width--100 ${adjustAddressValRes.getInputErrorClassOf(
                            'buildingName'
                        )}`}
                        title="Building Name, or Company name"
                        value={newAddress.buildingName}
                        onChange={updateNewAddress}
                    />
                </div>
                <div className={`${base}__input-container`}>
                    <label
                        className={`${base}__address-label`}
                        htmlFor="inputBlock"
                    >
                        {intl.get('shoppingCart.deliveryAddress.popup.block')}
                    </label>
                    <br />
                    <input
                        id="inputBlock"
                        name="block"
                        className={`${base}__input input width--100`}
                        title="Block"
                        value={newAddress.block}
                        onChange={updateNewAddress}
                    />
                </div>
            </div>
        );
    }

    renderHouse() {
        const store = this.props.deliveryAddressStore;
        const { newAddress, adjustAddressValRes } = store;
        const updateNewAddress = ::store.updateNewAddress;
        return (
            <div className={`${base}__input-area`}>
                <div className={`${base}__input-container width-100`}>
                    <label
                        className={`${base}__address-label width--100`}
                        htmlFor="inputHomeNumber"
                    >
                        {intl.get('shoppingCart.deliveryAddress.popup.houseNo')}
                    </label>
                    <br />
                    <input
                        id="inputHomeNumber"
                        name="homeNumber"
                        className={`${base}__input input width--100 ${adjustAddressValRes.getInputErrorClassOf(
                            'homeNumber'
                        )}`}
                        title="House Number"
                        value={newAddress.homeNumber}
                        onChange={updateNewAddress}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { deliveryAddressStore } = this.props;
        const {
            adjustAddressValRes,
            editing,
            editableAddress,
            getPlace,
            newAddress,
        } = deliveryAddressStore;
        const place = editing ? editableAddress : getPlace();
        const formattedAddress =
            place.suggested_address || place.formatted_address || place.name;

        return (
            <form onSubmit={::this.handleFormSubmit}>
                <PopupHeader
                    text={intl.get('shoppingCart.deliveryAddress.popup.title')}
                    customClasses="shopping-cart-address__popup-header__extra-margin"
                />
                <div
                    className={`${base}__popup--adjust text--left fs--small-medium`}
                >
                    <hr className={`${base}__separator show--if-large`} />
                    <div
                        className={`${base}__address-preview display--flex flex-align-items-center ptm pbm`}
                    >
                        <svg
                            className={`${base}__pin-icon iconmoon-icon flex-none text--primary`}
                        >
                            <use xlinkHref="#icon-location" />
                        </svg>
                        <p className="flex-1-only fs--small-medium ellipsis">
                            {formattedAddress}
                        </p>
                    </div>
                    <hr className={`${base}__separator show--if-large`} />

                    <p
                        className={`${base}__title mtm text--bolder fs--medium-medium-large`}
                    >
                        {intl.get('shoppingCart.deliveryAddress.popup.header')}
                    </p>
                    <AddressTypePicker />

                    <div className={`${base}__title-container`}>
                        {newAddress.addressType && (
                            <p
                                className={`${base}__title mtm text--bolder fs--medium-medium-large`}
                            >
                                {intl.get(
                                    'shoppingCart.deliveryAddress.popup.confirmAddressDetails'
                                )}
                            </p>
                        )}
                        {newAddress.addressType === HOME_ADDRESS
                            ? this.renderHouse()
                            : newAddress.addressType === OFFICE_ADDRESS
                            ? this.renderBuildings()
                            : null}
                    </div>
                    {!adjustAddressValRes.pass() && (
                        <p
                            id="inputError"
                            className={`${base}__error-message text--left text--red ptm pbm fs--small-medium`}
                        >
                            {adjustAddressValRes.pass()
                                ? ''
                                : adjustAddressValRes.getFirstError()}
                        </p>
                    )}
                </div>
                {newAddress.addressType && (
                    <div className={`${base}__popup-footer`}>
                        {window.matchMedia(SMALL_SCREEN_MEDIA).matches ===
                        true ? (
                            <FloatingCta
                                centerText={intl.get(
                                    'shoppingCart.deliveryAddress.popup.cta'
                                )}
                            />
                        ) : (
                            <button
                                className={`${base}__confirm button button--bigger-on-mobile button--bigger-on-mobile button--success`}
                            >
                                {intl.get(
                                    'shoppingCart.deliveryAddress.popup.cta'
                                )}
                            </button>
                        )}
                    </div>
                )}
            </form>
        );
    }
}
