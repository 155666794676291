export default class UserService {
    constructor(user) {
        if (!user) {
            throw new Error('User Service can not be instantiated');
        }

        this.user = user;
    }

    static from(user) {
        return new UserService(user);
    }

    getUserType(onProfilePage) {
        // need to check if the user on the Profile Page, because here it is important if the user has ever set a password
        if (onProfilePage) {
            if (this.user.password_length) {
                return 'Normal';
            }
        }
        if (this.user['facebook_id']) {
            return 'Facebook';
        } else if (this.user['google_id']) {
            return 'Google';
        }

        return 'Normal';
    }

    hasFinishedRegistration() {
        return Boolean(this.user.registration_complete);
    }

    hasPhoneVerified() {
        return Boolean(this.user.mobileno);
    }

    hasEmailVerified() {
        return Boolean(this.user.email);
    }
}
