import React from 'react';
import template from 'lodash/template';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import HelpPopup from '../../core/HelpPopup';
import Checkbox from '../../core/Checkbox';
import OrderSummary from './OrderSummary';
import { currency, getParam } from '../../../services';

@inject('store')
@observer
export default class extends React.Component {
    renderProduct(walletPackage) {
        if (walletPackage) {
            return (
                <div>
                    <span className="float-left text--bolder">
                        {walletPackage.title}
                    </span>
                    <span className="float-right">
                        {currency.localCurrency(walletPackage.price, false)}
                    </span>
                </div>
            );
        }

        return <div />;
    }

    render() {
        const base = 'cart';
        const { store } = this.props;
        const { walletPackage } = store;
        const tooltip = template(intl.get('wallet.smartReloadTooltip'))({
            packageName: walletPackage.title,
            brandName: getParam('brandName'),
        });

        return (
            <div className={`${base} well`}>
                <strong>{intl.get('packageCheckout.cart.title')}</strong>
                <hr className="mbm mtm" />
                <div className="clearfix mtm mbm">
                    {this.renderProduct(walletPackage)}
                </div>
                <div
                    className={`${base}__gray clearfix mts mbs pm border--rounded`}
                >
                    <span className="float-left">
                        <HelpPopup
                            text={tooltip}
                            showModalIfMatch="(max-width: 768px)"
                            contentClass={`${base}__smart-reload-tooltip`}
                            triggerClass={`${base}__help`}
                        />
                        {intl.get('wallet.smartReload')}
                    </span>
                    <span className="float-right">
                        <Checkbox
                            checked={store.autoTopUpEnabled}
                            onChange={::store.setAutoTopup}
                        />
                    </span>
                </div>
                <div
                    className={`${base}__smart-reload-desc mtm mbm fs--small-medium text--gray`}
                >
                    {intl.get('packageCheckout.cart.smartReloadDescription')}
                </div>
                <hr className="mtm mbm hide--if-small" />
                <div className="hide--if-small">
                    <OrderSummary />
                </div>
            </div>
        );
    }
}
