import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { parseDate, getDateDisplay } from '../../../services/dateTime';
import ItemRow from './ItemRow';
import DeliveryAddress from './DeliveryAddress';
import TimeframeSelection from './TimeframeSelection';
import AddressPopup from './deliveryAddress/AddressPopup';
import AddonUpsell from '../../core/addonUpsell/AddonUpsell';
import SelectUpsell from '../../core/SelectUpsell';
import CutlerySwitcher from './CutlerySwitcher';

const base = 'shopping-cart-meal';

@inject(
    'store',
    'addressAtom',
    'cartStore',
    'selectPopupStore',
    'menuCheckoutStore',
    'menuListStore'
)
@observer
export default class extends Component {
    render() {
        const {
            id,
            date,
            cartStore,
            items,
            isLastRow,
            lastRow,
            menuListStore,
        } = this.props;
        const xdate = parseDate(date);
        const numberOfItems = cartStore.itemsInCart.length;
        const quantifier =
            numberOfItems === 1 ? '1 item' : `${numberOfItems} items`;

        return menuListStore.isLoading ? (
            <div />
        ) : (
            <div className={base}>
                <div className={`${base}__header`}>
                    <span
                        className={`${base}__header-date text--uppercase mrm`}
                    >
                        {getDateDisplay(xdate)}
                    </span>
                </div>
                <hr className={`${base}__separator`} />
                <div className="row">
                    <div
                        className={`${base}__timeslot-addresses col-lg-7 col-md-7`}
                    >
                        <div>
                            <DeliveryAddress date={date} />
                            <hr className={`${base}__separator`} />
                            <TimeframeSelection date={date} />
                            <div className="mbl" />
                            <AddressPopup date={date} />
                        </div>
                    </div>
                    <div className={`${base}__products col-lg-5 col-md-5`}>
                        <hr className="hide--if-large show--if-small mtm mbl" />
                        <div className="clearfix">
                            <p className="float-left text--bolder">
                                Your order
                            </p>
                            <p className="float-right">{quantifier}</p>
                        </div>
                        {items.map((item) => (
                            <ItemRow key={item.id} item={item} date={date} />
                        ))}
                        <hr className={`${base}__quantity-separator`} />
                        {cartStore.itemsInCart.some(
                            (item) => !item.addon_category
                        ) && (
                            <CutlerySwitcher
                                id={id}
                                date={date}
                                isLastRow={isLastRow}
                            />
                        )}
                        {lastRow}
                        {isLastRow &&
                            cartStore.preliminaryOrderSummary &&
                            cartStore.showSelectUpsell && <SelectUpsell />}
                    </div>
                </div>
                <div className="mbl" />
                {isLastRow && <hr className={`${base}__quantity-separator`} />}
                <div>
                    <AddonUpsell date={date} />
                </div>
            </div>
        );
    }
}
