import { observable, action, computed } from 'mobx';
import { RESOURCE } from '../services/types';
import { services } from '../services/index';

class WalletStore {
    @observable walletOptions = RESOURCE.Initial;
    @observable dataToStore = {};

    @computed get entryWalletPackage() {
        if (this.walletOptions.data) {
            return this.walletOptions.data.find(
                (walletOption) => walletOption.size === 218
            );
        }
    }

    @computed get enabledWalletPackages() {
        if (this.walletOptions.data) {
            return this.walletOptions.data.filter(
                (walletOption) => walletOption.published
            );
        }
    }

    @action getWalletOptions() {
        this.walletOptions = RESOURCE.Loading;

        services.api
            .GetWalletOptions()
            .then((response) => {
                const walletOptions = response.list;
                this.walletOptions = RESOURCE.Success(
                    walletOptions.map(this.mapWalletOption)
                );
            })
            .catch((response) => {
                this.walletOptions = RESOURCE.Failure(
                    response.message,
                    response
                );
            });
    }

    mapWalletOption = (walletOption) => ({
        id: walletOption.id,
        title: walletOption.title,
        price: walletOption.price,
        size: walletOption.size,
        information: walletOption.information,
        percentSaved: walletOption.percent_saved,
        packageValue: walletOption.package_value,
        published: walletOption.published,
    });
}

const store = new WalletStore();
export default store;
