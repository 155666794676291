import React from 'react';

const { clevertap } = window;

export const handleSentTrackLinkEvent = (href, eventName) => {
    return new Promise((resolve) => {
        clevertap.event.push(eventName);
        resolve();
    }).finally(() => {
        if (href) window.location.href = href;
    });
};

export const ClevertapLink = ({
    href,
    eventName,
    eventProperty,
    children,
    ...rest
}) => {
    return (
        <a
            onClick={() =>
                handleSentTrackLinkEvent(href, eventName, eventProperty)
            }
            {...rest}
        >
            {children}
        </a>
    );
};

export const trackLink = (eventName, eventProperty) => {
    return new Promise((resolve) => {
        clevertap.event.push(eventName, eventProperty);
        setTimeout(resolve, 1000);
    });
};
