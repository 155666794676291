import React, { Component, Fragment } from 'react';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import intl from 'react-intl-universal';
import { inject, observer } from 'mobx-react';
import AddressAutocomplete from '../../core/AddressAutocomplete';
import { parseLocation } from '../../../services/geoparser';
import { services } from '../../../services';
import { cities, GetCity } from '../../../services/city';
import { RESOURCE } from '../../../services/types';

import { when } from 'mobx';

const base = 'section-hero';
const isBKK = GetCity() === cities.bangkok;

@inject(
    'loginPopupStore',
    'addressAutocompleteStore',
    'deliveryAddressStore',
    'menuHeaderStore'
)
@observer
export default class SectionHero extends Component {
    state = {
        height: 0,
        isBusy: false,
        coords: null,
        inputValue: '',
    };

    componentDidMount() {
        this.isSmallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches;
        if (this.isSmallScreen) {
            this.setState({ height: window.innerHeight });
        } else {
            this.setState({ height: window.innerHeight * 0.8 });
        }
    }

    handleAddressSelected = (prediction, cb) => {
        const { addressAutocompleteStore, deliveryAddressStore } = this.props;
        addressAutocompleteStore.handleAddressSelected(prediction, cb);

        when(
            () => deliveryAddressStore.place,
            () => {
                deliveryAddressStore.place.geometry = {};
                deliveryAddressStore.place.geometry.location =
                    addressAutocompleteStore.setAddressCoordinate(
                        deliveryAddressStore.place
                    );
            }
        );
    };

    handleAddressConfirmed = (place) => {
        const { deliveryAddressStore } = this.props;
        if (place) {
            deliveryAddressStore.setPlace(place);
        }

        this.handleSubmitAddress(place);
    };

    handleSubmitAddress = (place) => {
        const { deliveryAddressStore } = this.props;
        deliveryAddressStore.setNewAddress(parseLocation(place), true);
        when(
            () => deliveryAddressStore.newAddress,
            () => {
                deliveryAddressStore.saveAddress({ refreshMenu: true });
            }
        );
    };

    handleInputValue = (value) => {
        this.setState({ inputValue: value });
    };

    redirectToMenu = () => {
        window.location.href = '/menu'; //menu has to be loaded fresh as sometimes menu from landing has not finished loading before opening menu page, causing older dates to be placed at the top of the menu
    };

    render() {
        const {
            variety,
            deliveryAddressStore,
            loginPopupStore,
            menuHeaderStore,
            addressAutocompleteStore,
        } = this.props;
        const { height, inputValue } = this.state;
        const isWrongAddress =
            RESOURCE.Failure.is(addressAutocompleteStore.addressChecker) ||
            (addressAutocompleteStore.predictions.length === 0 &&
                inputValue.length > 3 &&
                !RESOURCE.Success.is(addressAutocompleteStore.addressChecker));
        const bgImage = `bg-pop ${isBKK ? 'bg-pop-th' : ''}`;
        const bgMobileImage = 'bg-mobile-pop';
        const margin =
            menuHeaderStore.showAppDownloadBanner && !loginPopupStore.isShowing
                ? 'extra-margin'
                : '';
        const appDownloadBannerHeight = menuHeaderStore.branchBannerHeight;
        const headerHeight = menuHeaderStore.headerHeight;
        const showHalal = menuHeaderStore.showHalal;

        return (
            <div
                className={`${base}`}
                id="sectionHero"
                style={{ height: height + 'px' }}
            >
                {/*background images*/}
                {!this.isSmallScreen && (
                    <Fragment>
                        <div
                            className={`${bgImage} show`}
                            style={{ height: height + 'px' }}
                        >
                            {showHalal && (
                                <img
                                    className={`${bgImage}__halal-img`}
                                    src={
                                        'https://image.popcontent.link/dijamin_halal.svg'
                                    }
                                    alt="Halal logo"
                                />
                            )}
                        </div>
                    </Fragment>
                )}
                <div
                    className={`${base}__container ${
                        this.isSmallScreen ? '' : 'container'
                    }`}
                    id="sectionHeroContainer"
                    style={{
                        height: this.isSmallScreen
                            ? `${
                                  height -
                                  appDownloadBannerHeight -
                                  headerHeight
                              }px`
                            : '100%',
                    }}
                >
                    {this.isSmallScreen && (
                        <div className={`${bgMobileImage}`}>
                            <img
                                className={`${bgMobileImage}__hero-img`}
                                src={
                                    'https://d3tjqo2jh935k9.cloudfront.net/images/static/hero-pop-meal-chart.png'
                                }
                                alt="Pop meals hero section image"
                            />
                            {showHalal && (
                                <img
                                    className={`${bgMobileImage}__halal-img`}
                                    src={
                                        'https://image.popcontent.link/dijamin_halal.svg'
                                    }
                                    alt="Halal logo"
                                />
                            )}
                        </div>
                    )}
                    <div
                        className={`${base}__intro ${margin} ${
                            this.isSmallScreen ? 'container' : ''
                        }`}
                    >
                        <div className={`${base}__intro-text`}>
                            <h1
                                className={`${base}__header text--bolder text--secondary`}
                            >
                                {this.isSmallScreen ? (
                                    variety.mobileTitleLine1
                                ) : (
                                    <Fragment>
                                        {variety.titleLine1} <br />
                                        {variety.titleLine2}
                                    </Fragment>
                                )}
                            </h1>
                        </div>
                        <p
                            className={`${base}__auto-complete-header text--bolder text--black`}
                        >
                            {this.isSmallScreen
                                ? variety.mobileSubtitle
                                : intl.get(
                                      'shoppingCart.deliveryAddress.header'
                                  )}
                        </p>
                        <div
                            className={`display--flex ${
                                this.isSmallScreen ? 'flex-column' : ''
                            }`}
                        >
                            <div className={`${base}__auto-complete-container`}>
                                <AddressAutocomplete
                                    showBorder={true}
                                    inputContainerClass={`${base}__input-container`}
                                    marginLeft={15}
                                    onAddressSelected={
                                        this.handleAddressSelected
                                    }
                                    onAddressConfirmed={
                                        this.handleAddressConfirmed
                                    }
                                    absolutePosition={true}
                                    selectedAddress={
                                        deliveryAddressStore.selectedAddress &&
                                        (deliveryAddressStore.selectedAddress
                                            .address_local ||
                                            deliveryAddressStore.selectedAddress
                                                .address)
                                    }
                                    displayErrorInline={true}
                                    displayBlack={true}
                                    large={!this.isSmallScreen}
                                    liftInputValueCallback={
                                        this.handleInputValue
                                    }
                                />
                            </div>
                            <button
                                className={`${base}__cta-button button`}
                                onClick={this.redirectToMenu}
                                disabled={isWrongAddress}
                            >
                                {intl.get(`onboarding.sectionHero.cta`)}
                            </button>
                        </div>
                        <div
                            className={`${base}__subtitle hide--if-small text--secondary`}
                        >
                            {variety.subtitle}
                        </div>
                        <div className={` ${base}__apps hide--if-small`}>
                            <a href={services.getParam('appStorePath')}>
                                <img
                                    className={`${base}__app-button ${base}__app-store`}
                                    src="https://d3tjqo2jh935k9.cloudfront.net/images/static/app-store-black.png"
                                    alt={`Download the ${services.getParam(
                                        'brandName'
                                    )} iOS app`}
                                />
                            </a>
                            <a href={services.getParam('googlePlayPath')}>
                                <img
                                    className={`${base}__app-button ${base}__google-play`}
                                    src="https://d3tjqo2jh935k9.cloudfront.net/images/static/google-play-black.png"
                                    alt={`Download the ${services.getParam(
                                        'brandName'
                                    )} Android app`}
                                />
                            </a>
                            <a href={services.getParam('huaweiAppGalleryPath')}>
                                <img
                                    className={`${base}__app-button ${base}__huawei-app-gallery`}
                                    src="https://d3tjqo2jh935k9.cloudfront.net/images/static/huawei-app-gallery-black.png"
                                    alt={`Download the ${services.getParam(
                                        'brandName'
                                    )} Huawei app`}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
