import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import BasePage from './basePage';
import MenuRoot from '../components/MenuRoot';
import { getGTMScript } from '../components/core/ScriptTag';
import { inject, observer, Provider } from 'mobx-react';
import { menuTags } from '../services/metatags';
import UtmStore from '../store/utmStore';
import { services } from '../services/index';
import qs from 'query-string';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';
import { storeAutoAssignDiscountCampaign } from '../services/autoAssignDiscount';
import { fromTimestamp } from '../services/dateTime';
import { when } from 'mobx';
import { MAGICLINK } from '../services/constants';
import XDate from 'xdate';

const pageTitle = services.getTitle('menu');

const utmStore = new UtmStore();

@inject('userStore', 'loginPopupStore')
@observer
export default class extends Component {
    componentDidMount() {
        const { loginPopupStore, userStore } = this.props;
        const params = qs.parse(location.search, { decoded: true });
        utmStore.params = params;
        utmStore.handleSendParams(params);

        if (params.one_time_token) {
            const { one_time_token, timestamp } = params;
            const timestampXDate = fromTimestamp(parseInt(timestamp));
            const today = new XDate();
            const isTokenValid = timestampXDate.diffHours(today) < 24;

            if (one_time_token && isTokenValid) {
                if (userStore.isLoggedIn) {
                    loginPopupStore.setMagicLinkAccessToken(one_time_token);
                } else {
                    loginPopupStore.doLogin(MAGICLINK, one_time_token);
                }
            }
        }

        // checks for auto applied discount campaigns
        when(
            () => userStore.isNotLoggedIn,
            () => {
                if (params.benefit) {
                    storeAutoAssignDiscountCampaign(params);
                }
            }
        );
        document.body.classList.add('menu');
    }

    render() {
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: 'Menu',
            lpType: '',
            lpVersion: '',
        });

        return (
            <Fragment>
                <Helmet meta={menuTags}>
                    {localizationTags('menu').map((tag) => tag)}
                    {getCanonicalTag(`menu`)}
                    <title>{pageTitle}</title>
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <Provider utmStore={utmStore}>
                    <MenuRoot />
                </Provider>
            </Fragment>
        );
    }
}
