import { observable, action } from 'mobx';

import { RESOURCE } from '../services/types';
import { ValidationResult } from '../services/validator';
import { services } from '../services/index';
import {
    postCodeValidator,
    emailValidator,
} from '../store/validators/voteAddressValidator';

const { clevertap } = window;

export default class {
    @observable postCode = '';
    @observable email = '';
    @observable showPopupEmail = false;
    @observable showPopupSuccess = false;
    @observable.ref polygons = RESOURCE.Initial;
    @observable.ref voter = RESOURCE.Initial;
    @observable.ref validatePostCodeResult = new ValidationResult();
    @observable.ref validateEmailResult = new ValidationResult();

    @action updateModel(e) {
        this[e.currentTarget.name] = e.currentTarget.value;
    }

    @action fetchDeliveryAreaPolygons() {
        this.polygons = RESOURCE.Loading;

        const city = services.getParam('defaultCity');
        services.api
            .GetDeliveryAreaPolygon({ city })
            .then((response) => {
                const poly = response[0];
                if (!poly || !poly.locations || poly.locations.length === 0) {
                    throw new Error('There is no polygon');
                }

                const googlePolygons = poly.locations.map(poly => poly.location.map((vertex) => ({
                    lat: vertex.latitude,
                    lng: vertex.longitude,
                })));

                this.polygons = RESOURCE.Success(googlePolygons);
            })
            .catch((response) => {
                this.polygons = RESOURCE.Failure(response.message, response);
            });
    }

    @action submitVoteAddress(e) {
        e.preventDefault();
        this.validatePostCodeResult = postCodeValidator.validate({
            postCode: this.postCode,
        });

        if (this.validatePostCodeResult.pass()) {
            this.voter = RESOURCE.Loading;

            services.api
                .GetUser()
                .then((user) => {
                    services.api
                        .VoteAddress({
                            address: this.postCode,
                            username: user.username,
                        })
                        .then(() => {
                            this.voter = RESOURCE.Success({});
                            this.showPopupSuccess = true;
                        })
                        .catch((response) => {
                            this.voter = RESOURCE.Failure(
                                response.message,
                                response
                            );
                        });
                })
                .catch(() => {
                    this.voter = RESOURCE.Initial;
                    this.showPopupEmail = true;
                });
        }
    }

    @action hidePopup() {
        this.voter = RESOURCE.Initial;
        this.showPopupEmail = false;
        this.showPopupSuccess = false;
    }

    @action submitVoteForNewUser(e) {
        e.preventDefault();
        this.validateEmailResult = emailValidator.validate({
            email: this.email,
        });

        if (this.validateEmailResult.pass()) {
            this.voter = RESOURCE.Loading;

            services.api
                .VoteAddress({
                    address: this.postCode,
                    username: this.email,
                })
                .then((credentials) => {
                    if (credentials) {
                        this.sendMixPanelEvent();

                        // Log user in
                        const accessToken = credentials['access_token'];
                        services.api.AccessTokenStorage.setToken(accessToken);
                    }
                })
                .then(() => {
                    this.voter = RESOURCE.Success({});
                    this.showPopupEmail = false;
                    this.showPopupSuccess = true;
                })
                .catch((response) => {
                    this.voter = RESOURCE.Failure(response.message, response);
                });
        }
    }

    sendMixPanelEvent() {
        return new Promise((resolve) => {
            clevertap.profile.push({
                Site: {
                    Type: 'Email',
                    Email: this.email,
                },
            });
            clevertap.event.push('Signup', { Type: 'Email' });
        });
    }
}
