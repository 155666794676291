import React from 'react';
import { inject, observer } from 'mobx-react';

import { parseDate, getDateDisplay } from '../../../services/dateTime';
import ItemRow from './ItemRowCartPreview';

const base = 'cart-preview';

@inject('cartStore')
@observer
export default class extends React.Component {
    render() {
        const { date, items } = this.props;
        const xdate = parseDate(date);

        return (
            <div className="mbm">
                <div className={`${base}__header`}>
                    <span
                        className={`${base}__header-date text--bold text--uppercase mrm`}
                    >
                        {getDateDisplay(xdate)}
                    </span>
                </div>
                <div className={`${base}__products`}>
                    <hr className="hide--if-large show--if-small mtm mbl" />
                    {items.map((item) => (
                        <ItemRow key={item.id} item={item} date={date} />
                    ))}
                    <hr className={`${base}__quantity-separator`} />
                </div>
            </div>
        );
    }
}
