import React, { Fragment } from 'react';
import template from 'lodash/template';
import intl from 'react-intl-universal';
import { currency } from '../../../services';
import { trackLink } from '../../core/ClevertapHelpers';
import { services } from '../../../services/index';

const base = 'wallet-benefit';

const storeSelectedWalletToStorage = (storedWallet, e) => {
    services.api.SelectingWalletPackage.set(JSON.stringify(storedWallet));
    e.currentTarget.classList.add('button--busy');

    window.dataLayer.push({
        currency: services.getParam('currencyCode'),
        value: currency.to2digits(storedWallet.price),
        event: 'AddToCartFbPixel',
        content_name: storedWallet.title,
        content_type: 'Meal_Package',
    });

    trackLink('Wallet package selected', {
        Package: storedWallet.title,
    })
        .then(() => {
            window.location.href = '/wallet/checkout';
        })
        .catch(() => {
            window.location.href = '/wallet/checkout';
        });
};

export default ({
    id,
    title,
    price,
    size,
    information,
    percentSaved,
    packageValue,
    isProminent,
    isTextOnly,
    mostPopular,
}) => {
    const dataToStore = { id, title, size, price };
    const classNames = isProminent
        ? `${base} text--center ${base}--prominent`
        : `${base} text--center`;

    return (
        <div className={`${classNames}`}>
            <div className={`${base}__info text--left`}>
                <span className={`${base}__title text--black`}>
                    <span>{title}</span>
                    {mostPopular && (
                        <span
                            className={`${base}__popular-tag ${
                                mostPopular ? 'popular  mlm' : ''
                            }`}
                        >
                            <Fragment>
                                <svg
                                    className={`${base}__popular-icon iconmoon-icon`}
                                >
                                    <use xlinkHref="#icon-star-filled" />
                                </svg>
                                <span>MOST POPULAR</span>
                            </Fragment>
                        </span>
                    )}
                </span>
                <p className={`${base}__descriptions lh--125 mts`}>
                    {information}
                </p>
            </div>
            <button
                className={`${base}__button`}
                onClick={storeSelectedWalletToStorage.bind(null, dataToStore)}
            >
                <span>TOP UP</span>
            </button>
        </div>
    );
};
