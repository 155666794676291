import React, { Component } from 'react';

const base = 'terminal-addon-item-combo';

export default class AddonItemCombo extends Component {
    render() {
        const { item, addAddonItemToCart, cheapestAddonItemPrice } = this.props;
        const roundedValue = (
            item.value_meal_price - cheapestAddonItemPrice
        ).toFixed(1);
        const addionalPriceText =
            roundedValue === '0.0'
                ? null
                : roundedValue > 0
                ? `(+${roundedValue.replace(`.0`, '')})`
                : `(${roundedValue.replace(`.0`, '')})`;
        return (
            <div className={`${base}`}>
                <img
                    onClick={() => addAddonItemToCart(item)}
                    src={item.image}
                    className="image cursor-pointer"
                    alt="drink item image"
                />
                <div className="name">
                    {item.title_bold && (
                        <p className="name">{item.title_bold}</p>
                    )}
                    {addionalPriceText && (
                        <p className="price-logic">{addionalPriceText}</p>
                    )}
                </div>
            </div>
        );
    }
}
