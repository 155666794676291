import React, { Component, Fragment } from 'react';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';

const base = 'section-stats';

@inject('calendarStore')
@observer
export default class SectionStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            heroWidth: 0,
            activeStat: 0,
        };
    }

    componentDidMount() {
        const { calendarStore, showReferralOnboarding } = this.props;

        if (showReferralOnboarding) {
            calendarStore.loadTimeSettings();
        }

        if (document.getElementById('sectionHeroContainer')) {
            this.setState({
                heroWidth: document.getElementById('sectionHeroContainer')
                    .offsetWidth,
            });
        }
        let index = 0;

        when(
            () => calendarStore.conversionContent,
            () => {
                setInterval(() => {
                    this.setState({ fading: true });
                    if (index === calendarStore.conversionContent.length - 1) {
                        index = 0;
                    } else {
                        index++;
                    }

                    setTimeout(() => {
                        this.setState({ fading: false });
                        this.setState({ activeStat: index });
                    }, 500);
                }, 2500);
            }
        );
    }

    setIcon = (text) => {
        if (text) {
            const stat = text.toLowerCase();

            if (stat.includes('instagram')) {
                return 'instagram-followers-icon';
            } else if (stat.includes('meals')) {
                return 'served-meals-icon';
            } else if (stat.includes('customer')) {
                return 'happy-customers-icon';
            } else if (stat.includes('certified')) {
                return 'halal-icon';
            } else {
                return 'google-rating-icon';
            }
        } else {
            return 'google-rating-icon';
        }
    };

    render() {
        const { heroWidth, fading } = this.state;
        const { calendarStore, showReferralOnboarding } = this.props;
        const smallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        if (calendarStore && calendarStore.conversionContent.length > 0) {
            if (smallScreen && !showReferralOnboarding) {
                const activeStat =
                    calendarStore.conversionContent[this.state.activeStat];
                return (
                    <Fragment>
                        <div
                            className={`${base}__mobile-container display--flex ${
                                fading ? 'faded' : ''
                            }`}
                            style={{ marginTop: '35px' }}
                        >
                            <div
                                className={`${base}__icon`}
                                style={{
                                    background: `url(${activeStat.symbol_url})`,
                                }}
                            />
                            <div className="text--white center--vertical">
                                <div
                                    className={`${base}__stat-header text--bolder`}
                                >
                                    {activeStat.first_line}
                                </div>
                                <div
                                    className={`${base}__stat-subheader text--thin`}
                                >
                                    {activeStat.second_line}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
            } else {
                return (
                    <div
                        className={`${base} ${
                            showReferralOnboarding ? 'referral' : ''
                        }`}
                        style={{
                            maxWidth:
                                (smallScreen
                                    ? heroWidth + 120
                                    : heroWidth - 113) + 'px',
                        }}
                    >
                        {showReferralOnboarding && (
                            <div className={`${base}__referral-green-box`} />
                        )}
                        <div className={`height--100`}>
                            <div
                                className={`${base}__container ${
                                    showReferralOnboarding ? 'referral' : ''
                                }`}
                            >
                                {calendarStore.conversionContent.map(
                                    (stat, i) => (
                                        <div
                                            className={`${base}__stat-container ${
                                                showReferralOnboarding
                                                    ? 'referral'
                                                    : ''
                                            }`}
                                            key={i}
                                        >
                                            <div
                                                className={`${base}__icon ${this.setIcon(
                                                    stat.second_line
                                                )} ${
                                                    showReferralOnboarding
                                                        ? 'referral'
                                                        : ''
                                                }`}
                                            />
                                            <div
                                                style={{
                                                    width: showReferralOnboarding
                                                        ? '100%'
                                                        : '',
                                                }}
                                            >
                                                <div
                                                    className={`${base}__stat-header text--bolder`}
                                                >
                                                    {stat.first_line}
                                                </div>
                                                <div
                                                    className={`${base}__stat-subheader text--thin ${
                                                        showReferralOnboarding
                                                            ? 'referral'
                                                            : ''
                                                    }`}
                                                >
                                                    {stat.second_line}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return <div />;
        }
    }
}
