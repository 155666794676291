import React from 'react';
import { GetQueryParamFromUrl } from '../../../services/url';

import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

const base = 'terminal-reset-kiosk';
@inject('terminalCartStore')
@inject('terminalMenuStore')
@observer
class PopupResetKiosk extends React.Component {
    state = {
        time: 10,
        timeInterval: null,
    };
    componentDidMount() {
        this.countdownTimer();
    }

    componentWillUnmount = () => {
        clearInterval(this.state.timeInterval);
    };

    resetKiosk = () => {
        const { terminalMenuStore, history } = this.props;
        history.push('/terminal');
        let orderType = GetQueryParamFromUrl('orderType');
        terminalMenuStore.showSplashScreenActions(orderType);
        terminalMenuStore.setOrderTypeIfExistsAndReloadMenu(orderType);
    };

    countdownTimer = () => {
        var x = setInterval(() => {
            let time = this.state.time;
            time -= 1;

            this.setState({ time: time, timeInterval: x });
            if (time == 0) {
                clearInterval(x);
                this.resetKiosk();
            }
        }, 1000);
    };
    resetScreen = () => {
        const { onClosePopup } = this.props;
        onClosePopup();
    };
    render() {
        return (
            <div className={`${base}`}>
                <div className={`${base}__title`}>Are you still there?</div>

                <div className={`${base}__inner-text`}>
                    Due to inactivity, the kiosk will reset in{' '}
                    <span className={`${base}__timer-text`}>{this.state.time}</span> seconds
                </div>

                <div
                    onClick={(e) => {
                        this.resetScreen();
                    }}
                    className={`${base}__CTA-btn`}
                >
                    I’m still here!
                </div>
            </div>
        );
    }
}
export default withRouter(PopupResetKiosk);
