import React, { Component } from 'react';
import ReferralHero from '../landingPage/ReferralHero';
import SectionStats from '../landingPage/SectionStats';
import SectionHowItWorks from '../landingPage/SectionHowItWorks';
import GoogleReviews from '../../core/GoogleReviews';

export default class Referral extends Component {
    state = {
        heroWidth: 0,
    };

    componentDidMount() {
        if (document.getElementById('sectionHeroContainer')) {
            this.setState({
                heroWidth: document.getElementById('sectionHeroContainer')
                    .offsetWidth,
            });
        }
    }

    render() {
        const { heroWidth } = this.state;

        return (
            <div>
                <ReferralHero />
                <SectionStats showReferralOnboarding={true} />
                <SectionHowItWorks heroWidth={heroWidth} />
                <GoogleReviews />
            </div>
        );
    }
}
