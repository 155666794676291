import React from 'react';
import { questions } from './WalletQuestions';
import FAQItem from './FAQItem';
import intl from 'react-intl-universal';

export default () => {
    const allQuestions = questions(intl);
    const base = 'wallet';
    return (
        <div className={`${base}__section ${base}__section-faq`}>
            <h1 className={`${base}__section-title text--center`}>
                {intl.get('wallet.faq.title')}
            </h1>
            <div className="container">
                <div className={`${base}__faq-wrapper`}>
                    {allQuestions.map((question, questionIndex) => (
                        <div
                            key={questionIndex}
                            className={`${base}__faq-item-wrapper border--rounded`}
                        >
                            <FAQItem {...question} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
