import React from 'react';
import { Provider } from 'mobx-react';

import TerminalAddressStore from '../store/terminalAddressStore';
import BaseLayout from './core/baseLayout/Master_BaseLayout';
import Entry from './page/terminalAddressPage/Entry';
import TerminalAddressHeader from './core/headers/TerminalAddressHeader';

const terminalAddressStore = new TerminalAddressStore();

const stores = {
    terminalAddressStore,
};

const Header = () => (
    <Provider {...stores}>
        <TerminalAddressHeader />
    </Provider>
);

export default class extends React.Component {
    render() {
        return (
            <BaseLayout.Page HeaderComponent={Header}>
                <main>
                    <Provider {...stores}>
                        <Entry />
                    </Provider>
                </main>
            </BaseLayout.Page>
        );
    }
}
