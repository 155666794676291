import React from 'react';
import cx from 'classnames';

export default ({ name, selected, onSelect }) => {
    const base = 'rating-tag';
    const classes = cx(base, {
        [`${base}--selected`]: selected,
    });

    return (
        <div className={classes} onClick={onSelect.bind(null, name)}>
            {name}
        </div>
    );
};
