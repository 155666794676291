import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import config from '../../configs/parameters';

export default class extends React.Component {
    render() {
        const base = 'wallet-header';
        const {
            backLink,
            nextLink,
            showBackLink,
            showNextLink,
            showPopLogo,
            fromURL,
            logoURL,
            logoWidth,
            customClass,
            customArrowClass,
            showWalletLogo,
        } = this.props;

        return (
            <div className={`${base} ${customClass}`}>
                <div className="container position--relative">
                    {showBackLink ? (
                        <Link
                            className={`${base}__back text--white link--no-underline`}
                            to={{
                                pathname: backLink,
                                state: { from: fromURL },
                            }}
                        >
                            <svg
                                className={`${base}__back-icon iconmoon-icon ${customArrowClass}`}
                            >
                                <use xlinkHref="#icon-arrow-left" />
                            </svg>
                            <span className={`${base}__back-text`}>
                                {intl.get('wallet.header.back')}
                            </span>
                        </Link>
                    ) : null}
                    <div className="text--center">
                        {showPopLogo ? (
                            <img
                                className={`${base}__pop-logo`}
                                src={logoURL}
                                alt={`${config.brandName} logo`}
                                style={{ width: logoWidth + 'px' }}
                            />
                        ) : (
                            <p
                                className={`${base}__header text--bold fs--medium-medium-large`}
                            >
                                {showWalletLogo && (
                                    <Fragment>
                                        <span>
                                            {config.brandNameShort}&nbsp;
                                        </span>
                                        <span
                                            className={`${base}__header-alt text--bolder text--primary`}
                                        >
                                            Wallet
                                        </span>
                                    </Fragment>
                                )}
                            </p>
                        )}
                    </div>
                    {showNextLink ? (
                        <a
                            className={`${base}__next text--white link--no-underline`}
                            href={nextLink}
                        >
                            <span className={`${base}__next-text`}>
                                {intl.get('wallet.header.menu')}
                            </span>
                            <svg className={`${base}__next-icon iconmoon-icon`}>
                                <use xlinkHref="#icon-arrow-right" />
                            </svg>
                        </a>
                    ) : null}
                </div>
            </div>
        );
    }
}
