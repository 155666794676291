import { Helmet } from 'react-helmet';
import React, { Component, Fragment } from 'react';
import intl from 'react-intl-universal';
import LanguageSelector from './LanguageSelector';
import CountrySelector from './CountrySelector';
import { services } from '../../services/index';
import FooterButton from './FooterButton';
import { cities, GetCity } from '../../services/city';

export default class extends Component {
    state = {
        displayFooter: false,
    };

    toggleFooter = () => {
        this.setState({ displayFooter: !this.state.displayFooter });
    };

    renderLineButton() {
        return (
            services.getParam('city') === 'Bangkok' && (
                <div style={{ display: 'inline-block' }}>
                    <div
                        className="line-it-button"
                        data-lang="en"
                        data-type="friend"
                        data-lineid="@Polpa"
                        data-count="true"
                        style={{ display: 'none' }}
                    ></div>
                </div>
            )
        );
    }

    loadLineScript = () =>
        services.getParam('city') === 'Bangkok' && (
            <Helmet>
                <script
                    src="https://d.line-scdn.net/r/web/social-plugin/js/thirdparty/loader.min.js"
                    async="async"
                    defer="defer"
                ></script>
            </Helmet>
        );

    render() {
        const { displayFooter } = this.state;
        const { sticky } = this.props;
        const base = 'menu-footer';
        const facebookLink = services.getParam('facebookLink');
        const instagramLink = services.getParam('instagramLink');
        const twitterLink = services.getParam('twitterLink');

        return (
            <Fragment>
                {sticky && (
                    <div onClick={this.toggleFooter} id="toggle-menu-footer">
                        <FooterButton displayFooter={displayFooter} />
                    </div>
                )}
                {this.loadLineScript()}
                <div
                    className={
                        !sticky
                            ? 'menu-footer-show'
                            : sticky && displayFooter
                            ? 'menu-footer-show sticky'
                            : 'menu-footer-hide sticky'
                    }
                >
                    <div>
                        <footer className={base}>
                            <div
                                className={`container ${
                                    displayFooter || !sticky
                                        ? ''
                                        : 'visible-none'
                                }`}
                            >
                                <div
                                    className={`${base}__container border-bottom display--flex flex-align-items-center flex-1-only`}
                                >
                                    <div
                                        className={`${base}__links flex-none display--flex text--center`}
                                    >
                                        <a
                                            href="/careers"
                                            className="link--no-underline text--black fs--small-medium ms"
                                        >
                                            {intl.get('menuFooter.joinUs')}
                                        </a>
                                        <a
                                            href="/ride"
                                            className="link--no-underline text--black fs--small-medium ms hide--if-small"
                                        >
                                            {intl.get('menuFooter.rideWithUs')}
                                        </a>
                                        {services.getParam('city') ===
                                            'Kuala_Lumpur' && (
                                            <a
                                                href={services.getParam(
                                                    'freshDeskHc'
                                                )}
                                                className="link--no-underline text--black fs--small-medium ms"
                                            >
                                                {intl.get('menuFooter.faq')}
                                            </a>
                                        )}
                                        <a
                                            href="/terms"
                                            className="link--no-underline text--black fs--small-medium ms"
                                        >
                                            {intl.get('menuFooter.tnc')}
                                        </a>
                                    </div>
                                    <div
                                        className={`${base}__locale flex-1-only text--right`}
                                    >
                                        <div className="country">
                                            <CountrySelector base={base} />
                                        </div>
                                        <div
                                            className={`${base}__language language`}
                                        >
                                            <LanguageSelector base={base} />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${base}__container display--flex flex-align-items-center flex-1-only`}
                                >
                                    <div
                                        className={`${base}__bottom text--center`}
                                    >
                                        <div className={` ${base}__apps `}>
                                            <a
                                                href={services.getParam(
                                                    'appStorePath'
                                                )}
                                            >
                                                <img
                                                    className={`${base}__app-button ${base}__app-store`}
                                                    src="https://d3tjqo2jh935k9.cloudfront.net/images/static/app-store-black.png"
                                                    alt={`Download the ${services.getParam(
                                                        'brandName'
                                                    )} iOS app`}
                                                />
                                            </a>
                                            <a
                                                href={services.getParam(
                                                    'googlePlayPath'
                                                )}
                                            >
                                                <img
                                                    className={`${base}__app-button ${base}__google-play`}
                                                    src="https://d3tjqo2jh935k9.cloudfront.net/images/static/google-play-black.png"
                                                    alt={`Download the ${services.getParam(
                                                        'brandName'
                                                    )} Android app`}
                                                />
                                            </a>
                                            <a
                                                href={services.getParam(
                                                    'huaweiAppGalleryPath'
                                                )}
                                            >
                                                <img
                                                    className={`${base}__app-button ${base}__huawei-app-gallery`}
                                                    src="https://d3tjqo2jh935k9.cloudfront.net/images/static/huawei-app-gallery-black.png"
                                                    alt={`Download the ${services.getParam(
                                                        'brandName'
                                                    )} Huawei app`}
                                                />
                                            </a>
                                        </div>
                                        <div className={`${base}__social`}>
                                            <a
                                                href={facebookLink}
                                                className={`${base}__facebook-icon`}
                                            >
                                                <svg
                                                    className={`${base}__social-icon iconmoon-icon text--black`}
                                                >
                                                    <use xlinkHref="#icon-facebook-square" />
                                                </svg>
                                            </a>
                                            <a
                                                href={twitterLink}
                                                className={`${base}__twitter-icon`}
                                            >
                                                <svg
                                                    className={`${base}__social-icon iconmoon-icon text--black`}
                                                >
                                                    <use xlinkHref="#icon-twitter" />
                                                </svg>
                                            </a>
                                            <a
                                                href={instagramLink}
                                                className={`${base}__instagram-icon`}
                                            >
                                                <svg
                                                    className={`${base}__social-icon iconmoon-icon text--black`}
                                                >
                                                    <use xlinkHref="#icon-instagram" />
                                                </svg>
                                            </a>
                                            {this.renderLineButton()}
                                        </div>
                                        <div
                                            className={`${base}__payment-methods`}
                                        >
                                            <div
                                                className={`${base}__visa-logo`}
                                            />
                                            <div
                                                className={`${base}__mastercard-logo`}
                                            />
                                            <div
                                                className={`${base}__paypal-logo`}
                                            />
                                            {GetCity() === cities.kl && (
                                                <Fragment>
                                                    <div
                                                        className={`${base}__boost-logo`}
                                                    />
                                                    <div
                                                        className={`${base}__fpx-logo`}
                                                    />
                                                </Fragment>
                                            )}

                                            <div
                                                className={`${base}__cod-logo`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </Fragment>
        );
    }
}
