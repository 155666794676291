import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import 'es5-shim';
import 'es6-promise';
import 'es6-shim';
import isBot from 'isbot';
import Fingerprint2 from 'fingerprintjs2';
import intl from 'react-intl-universal';
import config from './configs/parameters';
import { GetLanguage, LOCALES } from './services/translation';
import { GetUserLocation } from './services/api/locationServices';
import { GetCity, cities } from './services/city';
import {
    GetUrl,
    CitySelectedCallback,
    HandleRedirect,
    GetQueryParamFromUrl,
} from './services/url';
import { androidDevice, iOSDevice } from './services/domUtils';
import {
    setPartnerIntegrationData,
    getPartnerIntegrationData,
} from './services/storage';

import './resources/scss/main.scss';
import './resources/loader-css/loader.css';

import { CircleLoader } from './components/core/Loading';

import {
    OfflineDetect,
    OnlineDetect,
} from './components/core/NetworkDetectorComponents';
import WalletPage from './pages/walletPage';
import WalletPackagesPage from './pages/walletPackagesPage';
import OnboardingPage from './pages/onboardingPage';
import AboutPage from './pages/aboutPage';
import NotFoundPage from './pages/notFoundPage';
import RidePage from './pages/ridePage';
import MenuPage from './pages/menuPage';
import DishPage from './pages/dishPage';
import CareersPage from './pages/careersPage';
import ComboItemsPage from './pages/comboItemsPage';

import ShoppingCartPage from './pages/shoppingCartPage';
import BusinessPage from './pages/businessPage';
import DeliveryAreaPage from './pages/deliveryAreaPage';
import TermsPage from './pages/termsPage';
import ProfilePage from './pages/profilePage';
import OrderHistoryPage from './pages/orderHistoryPage';
import ResetPasswordPage from './pages/resetPasswordPage';
import WalletBillPlzProcessPage from './pages/walletBillPlzProcessPage';
import WalletCheckoutPage from './pages/walletCheckoutPage';
import RewardsPage from './pages/rewardsPage';
import MenuBillPlzProcessPage from './pages/menuBillPlzProcessPage';
import OfflineSignupPage from './pages/offlineSignupPage';
import ChefPage from './pages/chefPage';
import ReferralPage from './pages/referralPage';

import terminalAuthStore from './store/terminalAuthStore';
import TerminalPage from './pages/terminalPage';
import DealSelectionPage from './pages/dealSelectionPage';
import MealSelectionPage from './pages/mealSelectionPage';
import TerminalLearnMorePage from './pages/terminalLearnMorePage';
import TerminalLoginPage from './pages/terminalLoginPage';
import TerminalAddressPage from './pages/terminalAddressPage';
import { when, reaction } from 'mobx';
import qs from 'query-string';
import Cookies from 'js-cookie';
import TerminalUserPopup from './components/core/TerminalUserPopup';
import AppDownloadBanner from './components/core/AppDownloadBanner';
import ScrollToTop from './components/core/ScrollToTop';
import { OfflinePage } from './components/core/OfflinePage';
import PromotionPage from './pages/promotionPage';
import { runDataMigrations } from './services/storage';

const { clevertap } = window;

const kl = cities.kl;
const bangkok = cities.bangkok;

const isTerminal = window.location.pathname.includes('/terminal');
const isReferral = window.location.pathname.includes('/referred');

isBot.extend(['prerender']);

@inject('userStore', 'loginPopupStore', 'rewardsStore', 'menuHeaderStore')
@observer
class App extends Component {
    constructor(props) {
        super(props);

        runDataMigrations();

        this.state = {
            initLang: false,
            initCity: false,
            resolvingCity: false,
            city: GetCity(),
            lang: GetLanguage(),
        };

        // TODO: TEMP: REMOVE THIS CODE AFTER A WHILE
        if (isBot(window.navigator.userAgent)) {
            try {
                localStorage.removeItem('lang');
                // localStorage.removeItem("city");
            } catch (e) {}
        }

        // TODO: temp: temporary hard redirect to KL terminal because
        // some tablets might have bangkok in localstorage
        if (window.location.pathname.includes('/bangkok/terminal')) {
            window.location.href = '/kuala-lumpur/terminal';
        }
    }

    componentDidMount() {
        const { loginPopupStore, rewardsStore, userStore } = this.props;
        // this.getWindowInnerHeight();
        this.handleGetDeviceFingerPrint();

        this.loadLocales();
        this.handleGetCity();
        terminalAuthStore.checkAuth();
        if (!isTerminal) {
            userStore.getUserData();
            rewardsStore.handleGetAllRewards();
        }
        const params = qs.parse(window.location.search);

        // shopback partner integration
        this.handleUpdatePartnerIntegration();

        if (params.platform === 'boost' || Cookies.get('boost_user')) {
            this.handleTrackBoostUser();
            Cookies.set('boost_user', true);
        }

        if (!isTerminal) {
            this.props.rewardsStore.getRewards();
        }
        // check for mixpanel cookie
        // if present, set distinct_id on profile and delete cookie
        if (Cookies.get('mp_' + config.mixpanelKey + '_mixpanel')) {
            const mixpanel_distinct_id = JSON.parse(
                Cookies.get('mp_' + config.mixpanelKey + '_mixpanel')
            ).distinct_id;
            // send the distinct id to clevertap as identity
            clevertap.profile.push({
                Site: {
                    Identity: mixpanel_distinct_id,
                },
            });
            Cookies.remove('mp_' + config.mixpanelKey + '_mixpanel');
        }
    }

    handleUpdatePartnerIntegration = () => {
        const { userStore } = this.props;
        const params = qs.parse(window.location.search);

        if (params.partner === 'shopback') {
            const partnerIntegration = {
                partner: 'shopback',
                attributions: {
                    partner_parameter: params.partner_parameter,
                    last_click: +new Date(),
                },
            };
            setPartnerIntegrationData(partnerIntegration);
        }
    };

    handleTrackBoostUser = () => {
        clevertap.event.push('Opened App In External Platform', {
            Platform: 'Boost',
        });
    };

    handleTrackActiveCityAndLang = () => {
        const city = GetCity() === 'kuala-lumpur' ? 'Kuala_Lumpur' : 'Bangkok';
        const { lang } = this.state;
        when(
            () => this.props.userStore.user.data,
            () => {
                clevertap.profile.push({
                    Site: {
                        'Active City': city,
                        Language: lang,
                        'Customer ID': this.props.userStore.user.data.id,
                    },
                });
            }
        );

        if (lang === 'th') {
            document.documentElement.style.setProperty(
                '--primary-font',
                'PromptLight'
            );
            document.documentElement.style.setProperty(
                '--bolder-font',
                'Prompt'
            );
            document.documentElement.style.setProperty(
                '--boldest-font',
                'PromptSemiBold'
            );
        }
    };

    loadLocales() {
        const currentLocale = GetLanguage();
        intl.init({
            currentLocale,
            locales: {
                [currentLocale]: LOCALES[currentLocale]['json'],
            },
            commonLocaleDataUrls: {
                en: 'https://g.alicdn.com/react-intl-universal/locale-data/1.0.0/en.js',
                ms: 'https://g.alicdn.com/react-intl-universal/locale-data/1.0.0/ms.js',
                th: 'https://g.alicdn.com/react-intl-universal/locale-data/1.0.0/th.js',
            },
        })
            .then(() => {
                // After loading CLDR locale data, start to render
                this.setState({ initLang: true });
            })
            .catch((_e) => {
                this.setState({ initLang: true });
            });
    }

    handleGetCity = () => {
        if (GetCity()) {
            this.setState({ initCity: true });
            this.handleTrackActiveCityAndLang();
        } else {
            this.setState({ resolvingCity: true });
            GetUserLocation()
                .then((res) => {
                    if (res.countryCode === 'TH') {
                        CitySelectedCallback(bangkok);
                    } else {
                        CitySelectedCallback(kl);
                    }
                })
                .catch(() => {
                    CitySelectedCallback(kl);
                });
        }
    };

    handleGetDeviceFingerPrint = () => {
        if (!localStorage.getItem('device_id')) {
            if (window.requestIdleCallback) {
                requestIdleCallback(function () {
                    Fingerprint2.get(function (components) {
                        const values = components.map(function (component) {
                            return component.value;
                        });
                        const murmur = Fingerprint2.x64hash128(
                            values.join(''),
                            31
                        );
                        if (murmur) {
                            localStorage.setItem('device_id', murmur);
                        }
                    });
                });
            } else {
                setTimeout(function () {
                    Fingerprint2.get(function (components) {
                        const values = components.map(function (component) {
                            return component.value;
                        });
                        const murmur = Fingerprint2.x64hash128(
                            values.join(''),
                            31
                        );
                        if (murmur) {
                            localStorage.setItem('device_id', murmur);
                        }
                    });
                }, 500);
            }
        }
    };

    render() {
        const { initCity, initLang, resolvingCity } = this.state;
        const { loginPopupStore, menuHeaderStore, userStore } = this.props;
        const bearerToken = GetQueryParamFromUrl('bearerToken');

        return initCity && initLang ? (
            <Router basename={GetUrl()}>
                <ScrollToTop>
                    <div className="App" id="App">
                        {HandleMagicLogin()}
                        {HandleRedirect()}
                        {!isTerminal && userStore.showTerminalUserPopup && (
                            <TerminalUserPopup />
                        )}
                        {!isTerminal &&
                            !isReferral &&
                            (androidDevice || iOSDevice) && (
                                <AppDownloadBanner />
                            )}
                        <Switch>
                            <Route exact path={`/`} component={MenuPage} />
                            <Route
                                path={`/food-delivery/:id?`}
                                component={MenuPage}
                            />
                            <Route
                                path={`/referred`}
                                component={ReferralPage}
                            />
                            {/* <Route path={`/about`} component={AboutPage} /> */}
                            <Route
                                path="/about"
                                component={() => {
                                    window.location.href =
                                        'https://popmeals.com.my/about';
                                    return null;
                                }}
                            />
                            <Route path={`/menu`} component={MenuPage} />
                            <Route path="/menu-item/:id" component={DishPage} />
                            <Route path="/our-chefs" component={ChefPage} />
                            <Route path="/cart" component={ShoppingCartPage} />
                            <Route
                                path="/offline-signup"
                                component={OfflineSignupPage}
                            />
                            <Route
                                path="/purchase/:id"
                                component={MenuBillPlzProcessPage}
                            />
                            <Route path="/rewards" component={RewardsPage} />
                            <Route
                                path="/wallet/purchase/:id"
                                component={WalletBillPlzProcessPage}
                            />
                            <Route
                                path="/history"
                                component={OrderHistoryPage}
                            />
                            <Route path="/me" component={ProfilePage} />
                            <Route path="/terms" component={TermsPage} />
                            {/* <Route
                                path="/delivery-area"
                                component={DeliveryAreaPage}
                            /> */}
                            <Route
                                path="/delivery-area"
                                component={() => {
                                    window.location.href =
                                        'https://popmeals.com.my/outlet-delivery-area';
                                    return null;
                                }}
                            />
                            <Route path="/business" component={BusinessPage} />
                            <Route
                                exact
                                path="/wallet"
                                component={WalletPage}
                            />
                            <Route
                                path="/wallet-packages"
                                component={WalletPackagesPage}
                            />
                            <Route path="/ride" component={RidePage} />
                            {/* <Route path="/careers" component={CareersPage} /> */}
                            <Route
                                path="/careers"
                                component={() => {
                                    window.location.href =
                                        'https://popmeals.com.my/careers';
                                    return null;
                                }}
                            />
                            <Route
                                path="/promotions"
                                component={PromotionPage}
                            />
                            <Route
                                path="/wallet/checkout"
                                component={WalletCheckoutPage}
                            />
                            <Route
                                path="/reset-password"
                                component={ResetPasswordPage}
                            />
                            <TerminalRoute
                                path="/terminal"
                                component={TerminalPage}
                                authed={terminalAuthStore.authed}
                                bearerToken={bearerToken}
                            />
                            <TerminalRoute
                                path="/terminal-dealSelection"
                                component={DealSelectionPage}
                                authed={terminalAuthStore.authed}
                                bearerToken={bearerToken}
                            />
                            <TerminalRoute
                                path="/terminal-mealSelection"
                                component={MealSelectionPage}
                                authed={terminalAuthStore.authed}
                                bearerToken={bearerToken}
                            />
                            <TerminalRoute
                                path="/terminal-combo-items"
                                component={ComboItemsPage}
                                authed={terminalAuthStore.authed}
                                bearerToken={bearerToken}
                            />
                            <TerminalRoute
                                path="/terminal-learnmore"
                                component={TerminalLearnMorePage}
                                authed={terminalAuthStore.authed}
                                bearerToken={bearerToken}
                            />
                            <TerminalRoute
                                path="/terminal-address"
                                component={TerminalAddressPage}
                                authed={terminalAuthStore.authed}
                                bearerToken={bearerToken}
                            />
                            <TerminalLoginRoute
                                path="/terminal-login"
                                component={TerminalLoginPage}
                            />
                            <Route component={NotFoundPage} />
                        </Switch>
                    </div>
                </ScrollToTop>
            </Router>
        ) : resolvingCity ? (
            <Loader />
        ) : (
            <div />
        );
    }
}

const HandleMagicLogin = () => {
    const params = qs.parse(window.location.search, { decoded: true });
    if (params.one_time_token || params.destination === 'menu') {
        return (
            <Redirect
                to={{ pathname: '/menu', search: window.location.search }}
            />
        );
    }
};

const TerminalRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            rest.authed === true || rest.bearerToken ? (
                <Fragment>
                    <OnlineDetect>
                        <Component {...props} />
                    </OnlineDetect>
                    <OfflineDetect>
                        <OfflinePage />
                    </OfflineDetect>
                </Fragment>
            ) : rest.authed === false ? (
                <Redirect
                    to={{
                        pathname: '/terminal-login',
                        state: { from: props.location },
                    }}
                />
            ) : (
                <div>Loading...</div>
            )
        }
    />
);

const TerminalLoginRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            <Fragment>
                <OnlineDetect>
                    <Component {...props} />
                </OnlineDetect>
                <OfflineDetect>
                    <OfflinePage />
                </OfflineDetect>
            </Fragment>
        )}
    />
);

const Loader = () => {
    return (
        <div
            style={{
                minHeight: 'calc(100vh - 71px)',
                paddingTop: '20px',
                textAlign: 'center',
            }}
        >
            <div>
                <CircleLoader
                    className={'loading--circle--neutral'}
                    style={{ margin: '0 auto' }}
                />
                <p style={{ marginTop: '16px' }}>{intl.get('loading')}</p>
            </div>
        </div>
    );
};

const RedirectComponent = ({ location }) => {
    const params = qs.parse(location.search, { decoded: true });
    if (params.one_time_token) {
        return <Redirect to={{ ...location, pathname: '/menu' }} />;
    }
    return <Redirect to={{ ...location, pathname: '/food-delivery' }} />;
};

export default App;
