import React, { Component } from 'react';

const base = 'new-input';

export default class InputUI extends Component {
    state = {
        focus: false,
    };

    handleFocus = () => {
        this.setState({ focus: true });
    };

    handleFocusOut = () => {
        this.setState({ focus: false });
    };

    render() {
        const {
            inputClass,
            containerClass,
            id,
            autoFocus,
            placeholder,
            type,
            pattern,
            required,
            value,
            onChange,
            onInvalid,
            mobileNumberInput,
            errorMessage,
            countryCode,
            name,
            autoComplete,
            customMessage,
            focusColor,
        } = this.props;
        const { focus } = this.state;

        return (
            <div className="display--flex flex-column">
                <div className={containerClass}>
                    <div
                        className={`${base} ${focus && 'focus'} display--flex`}
                        style={{
                            border:
                                focus && focusColor
                                    ? `2px solid ${focusColor}`
                                    : '',
                        }}
                    >
                        {mobileNumberInput && (
                            <div className={`${base}__country-code-container`}>
                                <input
                                    className={`${base}__country-code input`}
                                    autoComplete="tel-country-code"
                                    type="tel"
                                    value={countryCode}
                                    onChange={onChange}
                                    name="countryCode"
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleFocusOut}
                                />
                                <div className={`${base}__input-separator`} />
                            </div>
                        )}
                        <input
                            name={name}
                            id={id}
                            className={`${base}__box input ${inputClass}`}
                            autoFocus={autoFocus}
                            placeholder={placeholder}
                            type={type}
                            pattern={pattern}
                            required={required}
                            value={value}
                            onChange={onChange}
                            onInvalid={onInvalid}
                            onFocus={this.handleFocus}
                            onBlur={this.handleFocusOut}
                            autoComplete={autoComplete}
                        />
                    </div>
                </div>
                {errorMessage && (
                    <div
                        className={`${base}__error-message-container ${containerClass}__error margin--right`}
                    >
                        <p className="text--red fs--small-medium">
                            {errorMessage}
                        </p>
                    </div>
                )}
                {customMessage && (
                    <div className={`${base}__custom-message-container`}>
                        {customMessage}
                    </div>
                )}
            </div>
        );
    }
}
