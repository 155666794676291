import React from 'react';
import { inject, observer } from 'mobx-react';

import ShowAddress from './deliveryAddress/ShowAddress';
import ListAddress from './deliveryAddress/ListAddress';

@inject('deliveryAddressStore', 'cartStore', 'menuCheckoutStore')
@observer
export default class extends React.Component {
    render() {
        const { deliveryAddressStore, date } = this.props;

        if (deliveryAddressStore.isAddressLoading) {
            return <div>Loading addresses</div>;
        }

        if (deliveryAddressStore.view === deliveryAddressStore.SHOW_VIEW) {
            return <ShowAddress date={date} />;
        } else if (
            deliveryAddressStore.view === deliveryAddressStore.LIST_VIEW
        ) {
            return <ListAddress date={date} />;
        }

        return <div />;
    }
}
