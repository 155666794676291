import React from 'react';
import { services } from '../../../services';
import qs from 'query-string';

const base = 'terms';

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: 'https://admin.popmeals.com/terms',
        };

        const params = qs.parse(window.location.search);
        if (params.showPrivacyPolicy) {
            this.state.url =
                'https://admin.popmeals.com/terms/index.html?showPrivacyPolicy';
        }
    }

    render() {
        return (
            <div className={`${base}__container`}>
                <iframe
                    className={`${base}__frame`}
                    title={`${services.getParam(
                        'brandName'
                    )} Terms & Conditions`}
                    src={this.state.url}
                />
            </div>
        );
    }
}
