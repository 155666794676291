import React, { Fragment } from 'react';
import basePage from './basePage';
import TermRoot from '../components/TermsRoot';
import { services } from '../services/index';
import { Helmet } from 'react-helmet';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('terms');

export default class extends basePage {
    componentDidMount() {
        document.body.classList.add('terms');
    }
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta name="nosnippet" />
                    <title>{pageTitle}</title>
                    {localizationTags(`terms`).map((tag) => tag)}
                    {getCanonicalTag(`terms`)}
                </Helmet>
                <TermRoot />
            </Fragment>
        );
    }
}
