import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './registerServiceWorker';
import { Provider } from 'mobx-react';
import userStore from './store/userStore';
import PhoneVerificationStore from './store/phoneVerificationStore';
import rewardsStore from './store/rewardsStore';
import loginPopupStore from './store/loginPopupStore';
import branchBannerStore from './store/branchBannerStore';
import terminalLoginStore from './store/terminalLoginStore';
import dishStore from './store/dishStore';
import menuListStore from './store/menuListStore';
import addressAtom from './store/atoms/addressAtom';
import menuCheckoutStore from './store/menuCheckoutStore';
import addressAutocompleteStore from './store/addressAutocompleteStore';
import cartStore from './store/cartStore';
import terminalCartStore from './store/terminalCartStore';
import terminalDishStore from './store/terminalDishStore';
import orderStatusStore from './store/orderStatusStore';
import orderHistoryStore from './store/orderHistoryStore';
import selectPopupStore from './store/selectPopupStore';
import walletStore from './store/walletStore';
import cartViewStore from './store/cartViewStore';
import rewardsPopupStore from './store/rewardsPopupStore';
import deliveryAddressStore from './store/deliveryAddressStore';
import dropdownStore from './store/dropdownStore';
import menuHeaderStore from './store/menuHeaderStore';
import walletStatusStore from './store/walletStatusStore';
/* Import basic support for another locale if needed
   ('en' is included by default) */
// let elLocaleData = require('react-intl/locale-data/el');
// addLocaleData(elLocaleData);

/* Define your translations */
// let i18nConfig = {
//   locale: "en",
//   messages: enJson
// };
const phoneVerificationStore = new PhoneVerificationStore();

ReactDOM.render(
    <Provider
        userStore={userStore}
        phoneVerificationStore={phoneVerificationStore}
        rewardsStore={rewardsStore}
        loginPopupStore={loginPopupStore}
        branchBannerStore={branchBannerStore}
        terminalLoginStore={terminalLoginStore}
        dishStore={dishStore}
        menuListStore={menuListStore}
        addressAtom={addressAtom}
        menuCheckoutStore={menuCheckoutStore}
        addressAutocompleteStore={addressAutocompleteStore}
        cartStore={cartStore}
        terminalCartStore={terminalCartStore}
        terminalDishStore={terminalDishStore}
        orderStatusStore={orderStatusStore}
        orderHistoryStore={orderHistoryStore}
        selectPopupStore={selectPopupStore}
        walletStore={walletStore}
        cartViewStore={cartViewStore}
        rewardsPopupStore={rewardsPopupStore}
        deliveryAddressStore={deliveryAddressStore}
        dropdownStore={dropdownStore}
        menuHeaderStore={menuHeaderStore}
        walletStatusStore={walletStatusStore}
    >
        <App />
    </Provider>,
    document.getElementById('root')
);
serviceWorkerRegistration.register();
