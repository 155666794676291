import React, { Component } from 'react';
import BasePage from './basePage';
import { Helmet } from 'react-helmet';
import BusinessRoot from '../components/BusinessRoot';
import { getGTMScript } from '../components/core/ScriptTag';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('business');

export default class extends Component {
    componentDidMount() {
        document.body.classList.add('business');
        window.scrollTo(0, 0);
    }
    render() {
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: '',
            lpType: '',
            lpVersion: '',
        });

        return (
            <div>
                <Helmet>
                    <title>{pageTitle}</title>
                    {localizationTags(`business`).map((tag) => tag)}
                    {getCanonicalTag('business')}
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <BusinessRoot />
            </div>
        );
    }
}
