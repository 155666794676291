import React, { Component } from 'react';
import ContentUI from './ContentUI';
import intl from 'react-intl-universal';
import { template } from 'lodash';
import config from '../../../configs/parameters';

export default class Why extends Component {
    render() {
        const brandName = config.brandName;

        return (
            <ContentUI
                heading={template(intl.get('careers.why.heading'))({
                    brandName,
                })}
                content={intl.get('careers.why.content')}
                readMore={intl.get('careers.why.cta')}
                link="/about"
            />
        );
    }
}
