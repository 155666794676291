import React from 'react';

import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

const base = 'terminal-payment-option';
@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        
        setTimeout(() => {
            this.returnToPaymentSelectScreen();
        }, 10000); //10 seconds
    }
    render() {
        return (
            <div className={base}>
                <div style={{ marginTop: '50px' }}>
                    <img src="/cross.png" />
                </div>

                <div
                    style={{
                        fontSize: '64px',
                        fontWeight: 600,
                        maxWidth: '384px',
                        margin: '25px auto',
                    }}
                >
                    {intl.get('terminal.qrPopup.payment_failed')}
                </div>
                <div>
                    <button className={`${base}__button`} onClick={this.returnToPaymentSelectScreen}>{intl.get('terminal.qrPopup.try_again')}</button>
                </div>
            </div>
        );
    }

    returnToPaymentSelectScreen = () => {
        const { terminalMenuStore } = this.props;
        //terminalMenuStore.cancelReaderAction();
        terminalMenuStore.backBtn = true;
        terminalMenuStore.showingPopup = terminalMenuStore.POPUP_PAYMENT_OPTION;
    }
}
