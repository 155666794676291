import { observable } from 'mobx';
import Bacon from 'baconjs';
import Notifiable from './services/notifiable';
import { services } from '../services/index';

const { clevertap } = window;

export default class extends Notifiable {
    @observable message = '';
    @observable isBusy = true;
    @observable isAddingPaypal = false;

    newPaypal = null;
    paypalInstance = null;
    onPaypalAdded$ = new Bacon.Bus();

    constructor() {
        super();

        this.setUpNotification();
    }

    initPaypal() {
        this.getClientToken()
            .then(::this.createClientInstance)
            .then(::this.createPaypalInstance)
            .then(::this.setPaypalInstance)
            .then(() => {
                this.isBusy = false;
            })
            .catch((error) => {
                this.message = 'Paypal: ' + error.message;
            });
    }

    getClientToken() {
        return services.api.GetClientToken().then((responseData) => {
            return responseData.token;
        });
    }

    createClientInstance(token) {
        return new Promise((resolve, reject) => {
            window.braintree.client.create(
                {
                    authorization: token,
                },
                (error, clientInstance) => {
                    if (error) {
                        return reject(error);
                    }

                    return resolve(clientInstance);
                }
            );
        });
    }

    createPaypalInstance(clientInstance) {
        return new Promise((resolve, reject) => {
            window.braintree.paypal.create(
                {
                    client: clientInstance,
                },
                (paypalErr, paypalInstance) => {
                    if (paypalErr) {
                        return reject(paypalErr);
                    }
                    return resolve(paypalInstance);
                }
            );
        });
    }

    getPaypalNonceKey(paypalInstance) {
        return new Promise((resolve, reject) => {
            paypalInstance.tokenize(
                { flow: 'vault' },
                (tokenizeError, tokenizeInfo) => {
                    if (tokenizeError) {
                        return reject(tokenizeError);
                    }

                    if (tokenizeInfo) {
                        return resolve(tokenizeInfo);
                    }

                    return reject(tokenizeInfo);
                }
            );
        });
    }

    getPaypalRequestPayload(paypalInstance) {
        return this.getPaypalNonceKey(paypalInstance).then((tokenizeInfo) => {
            return {
                card_type: 'Paypal',
                nonce_key: tokenizeInfo.nonce,
                email: tokenizeInfo.details.email,
            };
        });
    }

    doAddPaypal() {
        this.isAddingPaypal = true;

        return this.getPaypalRequestPayload(this.paypalInstance)
            .then(services.api.CreateCreditCard)
            .then(::this.setNewPaypal)
            .then(::this.sendTrackingEvents)
            .then(() => {
                this.onPaypalAdded$.push(this.newPaypal);
            })
            .catch((error) => {
                this.message = error.message;
            })
            .then(() => {
                this.isAddingPaypal = false;
            });
    }

    sendTrackingEvents() {
        clevertap.event.push('Payment method added', {
            'payment method': 'Paypal',
        });

        window.dataLayer.push({ event: 'AddPaymentInfo' });
    }

    setPaypalInstance(instance) {
        this.paypalInstance = instance;
    }
    setNewPaypal(newPaypal) {
        this.newPaypal = newPaypal;
    }
    reset() {
        this.paypalInstance = null;
        this.newPaypal = null;
        this.isBusy = true;
        this.message = '';
    }
}
