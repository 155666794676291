import React from 'react';
import BusinessForm from './BusinessForm';
import intl from 'react-intl-universal';
import { cities, GetCity } from '../../../services/city';
import config from '../../../configs/parameters';
import { template } from 'lodash';

const city = GetCity();
const base = 'business';

export default class extends React.Component {
    handleScrollToForm() {
        const scrollToElement = require('scroll-to-element');
        const form = document.getElementById('business-form');

        scrollToElement(form, { duration: 500 });
    }

    render() {
        const brandName = config.brandName;

        return (
            <div className={`${base}__hero-section`}>
                <div className={`${base}__hero-section-overlay`} />
                <div className="container">
                    <div className="row">
                        <div
                            className={`${base}__intro-container col-lg-6 col-md-5 col-sm-5 mtxl ptl`}
                        >
                            <h1 className="text--bold text--white mbm">
                                {template(
                                    intl.get(`business.${city}.heroTitle`)
                                )({ brandName })}
                            </h1>
                            <h6
                                className={`${base}__hero-section-subheading text--white`}
                            >
                                {intl.get(`business.${city}.heroSubheading`)}
                            </h6>
                            <div className="separator separator--green mtm mbm" />
                            <p
                                className={`${base}__hero-text text--white lh--150 fs--small-medium`}
                                dangerouslySetInnerHTML={{
                                    __html: template(
                                        intl.get(`business.${city}.heroText`)
                                    )({ brandName }),
                                }}
                            />
                            <div className="hide--if-large show--if-small">
                                <button
                                    className="button button--success button--bigger-on-mobile width--100 mtl"
                                    onClick={::this.handleScrollToForm}
                                >
                                    {intl.get(`business.${city}.heroCTA`)}
                                </button>
                            </div>
                        </div>
                        <div
                            className={`${base}__form-container mtl col-lg-6 col-md-7 col-sm-7`}
                        >
                            <BusinessForm />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
