export default (creditCardStore, paypalStore, profilePaymentMethodStore) => {
    /*
     * Every time a card is added:
     * ----- Add that card to the list
     * ----- Set the card as the current one
     * ----- Go back to root view
     * ----- Reset the add credit card form
     */
    creditCardStore.onCreditCardAdded$.onValue(() => {
        profilePaymentMethodStore.fetchPaymentMethods();
        profilePaymentMethodStore.setView(
            profilePaymentMethodStore.SHOW_VIEW
        )();
        creditCardStore.reset();
    });

    /*
     * Every time Paypal card is added:
     * ----- Add that card to the list
     * ----- Set the card as the current one
     * ----- Go back to root view
     * ----- Reset the Paypal button
     */
    paypalStore.onPaypalAdded$.onValue(() => {
        profilePaymentMethodStore.fetchPaymentMethods();
        profilePaymentMethodStore.setView(
            profilePaymentMethodStore.SHOW_VIEW
        )();
        creditCardStore.reset();
    });
};
