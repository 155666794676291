import React from 'react';
import brandLogo from '../../core/brandLogo/MasterBrandLogo';
import Cookies from 'js-cookie';
import intl from 'react-intl-universal';

const base = 'rideNavbar';

export default class extends React.Component {
    handleClick(e) {
        e.preventDefault();
        Cookies.set('ridepage', 'english');
        window.location.reload();
    }

    handleClickBahasa(e) {
        e.preventDefault();
        Cookies.remove('ridepage');
        window.location.reload();
    }

    sendGTM = (param) => (e) => {
        window.dataLayer.push({ event: 'rider application click ' + param });
    };

    render() {
        return (
            <div className={`${base}`}>
                <div
                    className={`${base}__container display--flex flex-align-items-center container p0--if-small`}
                >
                    <div className={`${base}__left-nav flex-1-only`}>
                        <a href="/">
                            <brandLogo.PurpleLogo />
                        </a>
                    </div>
                    <div className={`${base}__language flex-none ma`}>
                        <span
                            className="cursor-pointer dmk-link--primary-hover"
                            onClick={this.handleClick}
                        >
                            English
                        </span>{' '}
                        |{' '}
                        <span
                            className="cursor-pointer dmk-link--primary-hover"
                            onClick={this.handleClickBahasa}
                        >
                            Bahasa
                        </span>
                    </div>
                    <div
                        className={`${base}__right-nav  ride__center-horizontal flex-1-only display--flex text--right hide--if-small`}
                    >
                        <div
                            className={`${base}__right-nav text--right flex-1-only hide--if-small`}
                        >
                            <a
                                onClick={this.sendGTM('google form')}
                                href="http://bit.ly/dmWebCTA"
                            >
                                <button
                                    className={`${base}__apply button button--medium button--success`}
                                >
                                    {intl.get('ride.applyNow')}
                                </button>
                            </a>
                        </div>
                        <div
                            className={`${base}__right-nav flex-1-only text--right ${base}__whatsapp hide--if-small`}
                        >
                            <a
                                onClick={this.sendGTM('whatsapp')}
                                className="link--no-underline"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://chat.whatsapp.com/DkcEBrjHs1DFFLXutjg7KX"
                            >
                                <p className="dmk-link--primary-hover cursor-pointer display--inline-block">
                                    {intl.get('ride.navbar.orWhatsapp')}
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
