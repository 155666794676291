import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { CardCvcElement, CardExpiryElement, CardNumberElement, ElementsConsumer } from '@stripe/react-stripe-js';

const { clevertap } = window;

const base = 'stripe-credit-card';

const options = {
    style: {
        base: {
            color: '#000000',
            letterSpacing: '0.025em',
            padding: '8px 10px',
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

@inject('creditCardStore')
@observer
class CreditCardForm extends Component {
    handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        const { stripe, elements, creditCardStore } = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const clientSecret = await creditCardStore.confirmSetupIntent();
        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
            },
        });

        if (result) clevertap.event.push('3D-secure Verification Started');

        if (result.error) {
            // Display result.error.message in your UI.
            creditCardStore.message = result.error.message;
        } else {
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to your server to save the
            // card to a Customer
            creditCardStore.handleStoreStripeToken({
                id: result.setupIntent.payment_method,
            });
        }
    };

    render() {
        const { creditCardStore } = this.props;
        const formClasses = creditCardStore.isBusy
            ? `add-credit-card__form col-lg-7 add-credit-card__form--busy`
            : `add-credit-card__form col-lg-7`;
        const buttonClasses = creditCardStore.isAddingCreditCard
            ? 'button button--medium button--success width--100 button--busy'
            : 'button button--medium button--success width--100';

        return (
            <Fragment>
                <div className={formClasses}>
                    <div className={base}>
                        <div>
                            <CardNumberElement
                                className={`${base}__input card`}
                                options={options}
                                placeholder={intl.get('packageCheckout.addCreditCard.creditcardNumber')}
                            />
                        </div>
                        <div className="display--flex">
                            <div className={`${base}__payment-expiry-container`}>
                                <CardExpiryElement options={options} />
                            </div>
                            <div className={`${base}__payment-cvv-container`}>
                                <CardCvcElement options={options} />
                            </div>
                        </div>
                        <button onClick={this.handleSubmit} className={buttonClasses}>
                            {intl.get('packageCheckout.addCreditCard.cta')}
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default function InjectedCardSetupForm() {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => <CreditCardForm stripe={stripe} elements={elements} />}
        </ElementsConsumer>
    );
}
