import Cookies from 'js-cookie';

export const storeAutoAssignDiscountCampaign = (params) => {
    Cookies.set('promotion', params.promotion, { expires: 1 });
    Cookies.set('utm_source', params.utm_source, { expires: 1 });
    Cookies.set('benefit', params.benefit, { expires: 1 });
    Cookies.set('benefit_type', params.benefit_type, { expires: 1 });
};

// removes all items related to a discount campaign
export const clearAutoAssignDiscountCampaign = () => {
    Cookies.remove('promotion');
    Cookies.remove('benefit');
    Cookies.remove('benefit_type');
    Cookies.remove('utm_source');
    localStorage.removeItem('has_seen_auto_assigned_code_response_banner');
    localStorage.removeItem(
        'has_seen_non-user_auto_assigned_code_response_banner'
    );
};

export const autoAssignedCode = Cookies.get('promotion');
export const autoAssignedCodeAmount = Cookies.get('benefit');
export const autoAssignedCodeType = Cookies.get('benefit_type');
export const setSeenAutoAssignedCodeResponseBanner = () =>
    localStorage.setItem('has_seen_auto_assigned_code_response_banner', 'true');
export const setSeenNonUserAutoAssignedCodeResponseBanner = () =>
    localStorage.setItem(
        'has_seen_non-user_auto_assigned_code_response_banner',
        'true'
    );
export const seenNonUserAutoAssignedCodeResponseBanner = localStorage.getItem(
    'has_seen_non-user_auto_assigned_code_response_banner'
);
export const seenAutoAssignedCodeResponseBanner = localStorage.getItem(
    'has_seen_auto_assigned_code_response_banner'
);
