import React from 'react';
import { Provider } from 'mobx-react';

import TerminalMenuStore from '../store/terminalMenuStore';
import DishHeaderStore from '../store/dishHeaderStore';
import menuHeaderStore from '../store/menuHeaderStore';

import BaseLayout from './core/baseLayout/Master_BaseLayout';
import ComboMenuItemUI from './page/terminalPage/ComboMenuItemUI';
import TerminalHeader from './core/headers/TerminalHeader';
import DealComboHeader from './core/headers/DealComboHeader';

const dishHeaderStore = new DishHeaderStore();

const stores = {
    dishHeaderStore,
    menuHeaderStore,
    terminalMenuStore: TerminalMenuStore,
};
const Header = () => {
    return (
        <Provider {...stores}>
            <DealComboHeader type={'comboitems'} />
        </Provider>
    );
};
export default class extends React.Component {
    render() {
        return (
            <BaseLayout.Page HeaderComponent={Header}>
                <main>
                    <Provider {...stores}>
                        <ComboMenuItemUI />
                    </Provider>
                </main>
            </BaseLayout.Page>
        );
    }
}
