import React from 'react';
import { inject, observer } from 'mobx-react';

import { RESOURCE, VERIFICATION_STATUS } from '../../../services/types';

@inject('completeSignUpStore')
@observer
export default class extends React.Component {
    render() {
        const { completeSignUpStore, showEmail } = this.props;
        const { userUpdater, verifier } = completeSignUpStore;
        const isBusy =
            userUpdater === RESOURCE.Loading ||
            VERIFICATION_STATUS.Verifying.is(verifier);
        const notRequestVerifyCodeYet = showEmail
            ? !completeSignUpStore.email
            : !VERIFICATION_STATUS.CodeRequestSuccess.is(verifier);
        let submitButtonClasses = 'button button--success width--100';
        submitButtonClasses = isBusy
            ? (submitButtonClasses += ' button--busy')
            : submitButtonClasses;
        submitButtonClasses = notRequestVerifyCodeYet
            ? (submitButtonClasses += ' button--disabled')
            : submitButtonClasses;

        return (
            <button
                type={notRequestVerifyCodeYet ? 'button' : 'submit'}
                className={submitButtonClasses}
            >
                {this.props.text}
            </button>
        );
    }
}
