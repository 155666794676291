/* eslint-disable no-undef */
import { httpPost, httpGet, httpPut } from '../http';

export const EditAddress = (
    requestBody = {
        street,
        postal_code_new,
        city,
        latitude,
        longitude,
        district,
        address,
        name, //eslint-disable-line
        building_name,
        delivery_instruction,
        address_type,
        state,
        block,
        customer_id,
        place_id,
    },
    userId
) => {
    return httpPut(`/v3/users/address/${userId}`, requestBody, {
        authType: 'Bearer',
    });
};

export const CreateAddress = (
    requestBody = {
        street,
        postal_code_new,
        city,
        latitude,
        longitude,
        district,
        address,
        name, //eslint-disable-line
        building_name,
        delivery_instruction,
        address_type,
        state,
        block,
        customer_id,
        place_id,
        id,
    }
) => {
    return httpPost('/v3/users/address/', requestBody, { authType: 'Bearer' });
};

export const ValidateAddressCoordinate = ({ city, latitude, longitude }) => {
    return httpGet(
        `/v4/deliveries/is-coordinate-valid/${city}?latitude=${latitude}&longitude=${longitude}`
    );
};

export const GetDeliveryAreaPolygon = ({ city }) => {
    return httpGet(`/v3/orders/setting/${city}`);
};

export const VoteAddress = (requestBody = { address, username }) => {
    return httpPost('/v4/deliveries/vote', requestBody);
};

export const ExtractPlaceDetails = (requestBody) => {
    return httpPost('/v4/address/extract-place-details', requestBody, {
        authType: 'Bearer',
    });
};

export const AddressAutocomplete = (input) => {
    return httpGet(`/v4/address/autocomplete?input=${input}`, {
        authType: 'Bearer',
    });
};

export const GetAddressDetails = (requestBody) => {
    return httpPost('/v4/address/geocode', requestBody, { authType: 'Bearer' });
};
