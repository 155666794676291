import React from 'react';

export default function ArrowSide({ width, height }) {
    return (
        <img
            src={
                'https://dahmakan-images.s3-ap-southeast-1.amazonaws.com/side_arrow.svg'
            }
            style={{ width: `${width}px`, height: `${height}px` }}
            alt="side arrow"
        />
    );
}
