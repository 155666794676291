import React, { Fragment } from 'react';
import { Provider } from 'mobx-react';

import BaseLayout from './core/baseLayout/Master_BaseLayout';
import MenuHeader from './core/headers/MenuHeader';
import MenuFooter from './core/MenuFooter';
import Entry from './page/termsPage/Entry';

import menuHeaderStore from '../store/menuHeaderStore';
import PromotionBar from './core/PromotionBar';

const Header = () => (
    <Provider menuHeaderStore={menuHeaderStore}>
        <Fragment>
            <MenuHeader />
            <PromotionBar />
        </Fragment>
    </Provider>
);

const stores = {
    menuHeaderStore,
};

export default class extends React.Component {
    render() {
        return (
            <Provider {...stores}>
                <BaseLayout.Page HeaderComponent={Header}>
                    <main>
                        <Entry />
                    </main>
                    <div className="terms__footer-wrapper">
                        <MenuFooter />
                    </div>
                </BaseLayout.Page>
            </Provider>
        );
    }
}
