import React, { Fragment, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { CircleLoader } from '../../core/Loading';
import { services } from '../../../services';
import PopupPinEntry from './PopupPinEntry';
import { sortDate } from '../../../services/dateTime';
const base = 'terminal-phone-popup';

@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    state = {
        isIpad: false,
        focus: false,
        headerHeight: 0,
        showNameError: false,
        eventHandler: null,
        numClick: -1,
        btnDisable: true,
        otpTime: 60,
        timer: '',
    };

    componentWillMount() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        const { terminalMenuStore } = this.props;

        const eventHandler = this.handleKeyPress;
        this.setState({ eventHandler: eventHandler });
        document.addEventListener('keydown', eventHandler);
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        const { eventHandler } = this.state;
        if (eventHandler) {
            document.removeEventListener('keydown', eventHandler);
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleClickCta();
        }
    };

    handleClickCta = () => {
        const { terminalMenuStore, showOnMobile, onClosePopup } = this.props;
        const { loadingPhoneNoExist, askForName, customerName, orderType, ORDER_TYPES, checkAccountExistInfo } =
            terminalMenuStore;
        const customerNumber = terminalMenuStore.phoneNumber.number;
        const showNameInput = !loadingPhoneNoExist && askForName;

        let areInputsValid;
        if (!showNameInput) {
            //will be true only when the length of phone number is > 0 and phone number popup is open
            areInputsValid = customerNumber.length > 0;
        } else {
            //will be true only when the length of customer name is > 0 and customer name popup is open
            areInputsValid = terminalMenuStore.customerName.length > 0;
        }
        if (
            areInputsValid &&
            (terminalMenuStore.couponValidation == null || terminalMenuStore.couponValidation == true)
        ) {
            let outletName = localStorage.getItem('terminalUserName') || null;
            let deviceId = terminalMenuStore.KIOSK_RANDOM_ID || null;
            //only run when areInputsValid is true

            if (askForName) {
                if (customerName.length > 0) {
                    const isNewUser = true;
                    const eligibleForLuckyWheel = true && !terminalMenuStore.isSkipClicked;
                    terminalMenuStore.eligibleForLuckyWheelAfterTableNum = eligibleForLuckyWheel;

                    // if (terminalMenuStore.orderType === terminalMenuStore.ORDER_TYPES.DINE_IN) {
                    //     //show table number popup

                    //     terminalMenuStore.setShowPopup(terminalMenuStore.POPUP_TABLE_NUMBER);
                    // } else
                    if (
                        terminalMenuStore.orderType === terminalMenuStore.ORDER_TYPES.TAKE_AWAY &&
                        !showOnMobile &&
                        terminalMenuStore.walletStatus
                    ) {
                        terminalMenuStore.setShowPopup(terminalMenuStore.POPUP_PAYMENT_OPTION);
                    } else {
                        if (
                            !showOnMobile &&
                            terminalMenuStore.walletStatus
                        ) {
                            terminalMenuStore.setShowPopup(terminalMenuStore.POPUP_PAYMENT_OPTION);
                        } else {
                            try {
                                terminalMenuStore.confirmOrder(
                                    showOnMobile,
                                    isNewUser,
                                    eligibleForLuckyWheel,
                                    outletName,
                                    deviceId
                                );
                            } catch (e) {
                                services.api.SendLogToBackend({
                                    log: `outlet=${outletName} device_id=${deviceId} tableNumber=${tableNumber} exception in confirming order exception=${e}`,
                                });
                            }
                        }
                    }
                } else {
                    this.setState({ showNameError: true });
                }
            } else {
                if (terminalMenuStore.couponDisable) {
                    terminalMenuStore.validateCoupon();
                } else {
                    checkAccountExistInfo(showOnMobile);
                }
            }
        } else {
            onClosePopup();
        }
    };

    handleClickSkip = () => {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.askForName = true;
        terminalMenuStore.loadingPhoneNoExist = false;
        terminalMenuStore.isSkipClicked = true;
    };

    handleNumClick = (num) => {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.phoneNumber.number += num;
        terminalMenuStore.rawPhoneNumber += num;
        terminalMenuStore.phoneValidationMessage = '';
        this.setState({ numClick: num });
        setTimeout(() => {
            this.setState({ numClick: -1 });
        }, 100);
    };

    handleBackspaceClick = () => {
        const { terminalMenuStore } = this.props;
        if (terminalMenuStore.phoneNumber.number && terminalMenuStore.phoneNumber.number.length > 0) {
            terminalMenuStore.phoneNumber.number = terminalMenuStore.phoneNumber.number.slice(0, -1);
        }
        if (terminalMenuStore.rawPhoneNumber && terminalMenuStore.rawPhoneNumber.length > 0) {
            terminalMenuStore.rawPhoneNumber = terminalMenuStore.rawPhoneNumber.slice(0, -1);
            terminalMenuStore.phoneValidationMessage = '';
            if (terminalMenuStore.rawPhoneNumber.length == 0 && terminalMenuStore.isInternationalPhoneHubCode()) {
                terminalMenuStore.rawPhoneNumber = '+';
            }
        }
    };

    inputFocus = () => {
        this.setState({ focus: true });
    };
    inputBlur = () => {
        this.setState({ focus: false });
    };

    otpCountdown = () => {
        var count = 60,
            timer = setInterval(() => {
                count -= 1;

                this.setState({ otpTime: count });
                if (count == 0) {
                    clearInterval(timer);
                    this.setState({ btnDisable: false });
                }
            }, 1000);

        this.setState({ timer: timer });
    };

    render() {
        const { terminalMenuStore, onFocus, onBlur, showOnMobile } = this.props;
        const { loadingPhoneNoExist, askForName } = terminalMenuStore;
        const { isIpad, focus, showNameError, numClick } = this.state;
        const showNameInput = !loadingPhoneNoExist && askForName;

        let showPinInput = terminalMenuStore.pinValidation;
        let customerNumber = '';
        const couponCheck = terminalMenuStore.couponDisable;

        const phoneValidationMessage = terminalMenuStore.phoneValidationMessage;
        if (showOnMobile) {
            customerNumber = terminalMenuStore.phoneNumber.number;
        } else {
            if (terminalMenuStore.isInternationalPhoneHubCode()) {
                if (!terminalMenuStore.rawPhoneNumber) {
                    terminalMenuStore.rawPhoneNumber = '+60';
                }
                customerNumber = terminalMenuStore.rawPhoneNumber;
            } else {
                customerNumber = terminalMenuStore.phoneNumber.number;
            }
        }
        const isInternationalPhoneHubCode = terminalMenuStore.isInternationalPhoneHubCode();
        const countryCode = terminalMenuStore.phoneNumber.countryCode;
        const numbersToDisplay = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
        const couponValidation = terminalMenuStore.couponValidation;

        const trProps = {};

        if (customerNumber.length > 0 && phoneValidationMessage) {
            trProps.className = 'input-number-error-box';
        } else {
            trProps.className = 'input-number-box';
        }

        return (
            <Fragment>
                <div
                    className={`${base} ${
                        showNameInput || couponValidation == false
                            ? 'name-popup'
                            : showPinInput
                            ? 'pin-popup'
                            : 'number-popup'
                    }`}
                >
                    <div className={`${base}__title`}>
                        <p>{`${
                            showNameInput && !showPinInput
                                ? intl.get('terminal.phonePopup.titleName')
                                : couponValidation == false
                                ? 'Sorry!'
                                : showPinInput
                                ? `${intl.get('terminal.phonePopup.enterPin')} ${
                                      terminalMenuStore.isInternationalPhoneHubCode
                                          ? terminalMenuStore.phoneNumber.number
                                          : '+60' + terminalMenuStore.phoneNumber.number
                                  }`
                                : intl.get('terminal.phonePopup.titleNumber')
                        }`}</p>
                    </div>
                    <div className={`${base}__container`}>
                        {terminalMenuStore.checkIn && (
                            <div>
                                <h3 className={`${base}__title text--sofiapro text--bolder mbm`}>
                                    Enter your phone number to check in
                                </h3>
                                <p className={`${base}__sub-heading`}>Remember to check in daily for your rewards!</p>
                            </div>
                        )}
                        <div className={`${base}__input-wrapper`}>
                            {showNameInput ? (
                                <input
                                    type="text"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    className={`${base}__input-name ${
                                        terminalMenuStore.customerName.length === 0 ? `${base}__placeholder` : ''
                                    }`}
                                    name="name"
                                    placeholder={intl.get('terminal.phonePopup.placeholderTextName')}
                                    value={terminalMenuStore.customerName}
                                    onChange={::terminalMenuStore.updateName}
                                    onFocus={onFocus ? onFocus : this.inputFocus}
                                    onBlur={onBlur ? onBlur : this.inputBlur}
                                />
                            ) : couponValidation == false ? (
                                <div>
                                    <p className={`${base}__coupon-error-sub`}>
                                        {terminalMenuStore.couponValidationMsg}
                                    </p>
                                </div>
                            ) : showOnMobile ? (
                                <div>
                                    <div className={`${base}__input_number_container`}>
                                        <div
                                            className={`${base}__input-country-code`}
                                            style={{
                                                paddingLeft: '25px',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            +
                                            <input
                                                type="number"
                                                name="countryCode"
                                                className={`${base}__input-country-code`}
                                                style={{ borderLeft: 'none' }}
                                                value={countryCode}
                                                disabled={isInternationalPhoneHubCode ? '' : 'disabled'}
                                                onChange={::terminalMenuStore.updateCountryCode}
                                                onFocus={onFocus ? onFocus : this.inputFocus}
                                                onBlur={onBlur ? onBlur : this.inputBlur}
                                            />
                                        </div>
                                        <input
                                            type="number"
                                            className={`${
                                                !focus && customerNumber.length > 0 && phoneValidationMessage
                                                    ? `${base}__input-number-error`
                                                    : `${base}__input-number`
                                            } ${customerNumber.length === 0 ? `${base}__placeholder` : ''}`}
                                            name="number"
                                            placeholder={'012-345 6789'}
                                            value={customerNumber}
                                            onChange={::terminalMenuStore.updatePhoneNumber}
                                            onFocus={onFocus ? onFocus : this.inputFocus}
                                            onBlur={onBlur ? onBlur : this.inputBlur}
                                        />
                                    </div>
                                    <div>
                                        {!focus && customerNumber.length > 0 && phoneValidationMessage && (
                                            <p className={`${base}__error_message`}>{phoneValidationMessage}</p>
                                        )}
                                    </div>
                                </div>
                            ) : showPinInput ? (
                                <PopupPinEntry triggerCountdown={this.otpCountdown} timer={this.state.timer} />
                            ) : (
                                <div className={`${base}__num_inp_box`}>
                                    <div {...trProps}>
                                        <div
                                            className={`${base}__number ${
                                                customerNumber.length === 0 ? `${base}__placeholder` : ''
                                            }`}
                                        >
                                            {customerNumber ? customerNumber : '012-345 6789'}
                                        </div>
                                        <div 
                                            onClick={this.handleBackspaceClick}
                                            className={`${base}__backspace_icon_container`}
                                            >
                                        <img                                             
                                            className={`${base}__backspace_icon ${customerNumber ? 'show' : 'hide'}`}
                                            src={'https://image.popcontent.link/backbutton.svg'}
                                            alt="back button"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        {!focus && customerNumber.length > 0 && phoneValidationMessage && (
                                            <p className={`${base}__error_message`}>{phoneValidationMessage}</p>
                                        )}
                                        {!focus && showNameError && (
                                            <p className={`${base}__error_message`}>
                                                Please enter your name to proceed
                                            </p>
                                        )}
                                    </div>
                                    <div
                                        style={{ marginTop: phoneValidationMessage ? '0px' : '30px' }}
                                        className={`${base}__numpad`}
                                    >
                                        {numbersToDisplay.map((num) => (
                                            <div
                                                onClick={() => this.handleNumClick(num)}
                                                key={num}
                                                className={`${base}__numpad_key ${
                                                    numClick === num ? 'numpad_clicked_key' : ''
                                                }`}
                                            >
                                                {num}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {loadingPhoneNoExist && (
                                <center className="mtm mbm">
                                    <CircleLoader />
                                </center>
                            )}
                        </div>
                    </div>
                    <div className={`${base}__CTA-container`}>
                        {showPinInput ? (
                            <div>
                                <div
                                    onClick={(e) => {
                                        this.setState({
                                            btnDisable: true,
                                            otpTime: 60,
                                        });
                                        terminalMenuStore.resendOTP();
                                        this.otpCountdown();
                                    }}
                                    className={`${base}__CTA cursor-pointer ${isIpad && focus ? 'ipad' : ''} ${
                                        this.state.btnDisable ? 'disabled' : null
                                    }`}
                                >
                                    {intl.get('terminal.phonePopup.resendCode')}{' '}
                                    {this.state.btnDisable ? `(0:${this.state.otpTime})` : null}
                                </div>
                                <div
                                    style={{
                                        color: '#7056B5',
                                        textAlign: 'center',
                                        marginTop: '32px',
                                        fontSize: '18px',
                                    }}
                                    onClick={(e) => {
                                        const timer = this.state.timer;
                                        terminalMenuStore.pinValidation = false;
                                        clearInterval(timer);
                                        this.setState({
                                            otpTime: 60,
                                            btnDisable: true,
                                        });
                                    }}
                                >
                                    {intl.get('terminal.phonePopup.reenterPhone')}
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`${base}__CTA cursor-pointer ${isIpad && focus ? 'ipad' : ''}
                            ${
                                !showNameInput
                                    ? customerNumber.length > 0
                                        ? ''
                                        : 'disabled'
                                    : terminalMenuStore.customerName.length > 0
                                    ? ''
                                    : 'disabled'
                            }`}
                                onClick={this.handleClickCta}
                            >
                                {terminalMenuStore.couponValidation == false
                                    ? intl.get('terminal.phonePopup.close')
                                    : intl.get('terminal.phonePopup.cta')}
                            </div>
                        )}

                        {!showNameInput && isInternationalPhoneHubCode && !couponCheck && (
                            <div>
                                <div
                                    className={`${base}__skip-button cursor-pointer text--center`}
                                    onClick={this.handleClickSkip}
                                >
                                    {intl.get('terminal.phonePopup.skip')}
                                </div>
                                <div className="text--center" style={{ color: '#7056B5' }}>
                                    ({intl.get('terminal.phonePopup.skipInfo')})
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}
