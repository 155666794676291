import config from '../../configs/parameters';

export default {
    menu: `Menu | ${config.brandName} - Bangkok's best food, delivered`,
    about: `About | ${config.brandName} - Bangkok's best food, delivered`,
    deliveryArea: `Delivery Area | ${config.brandName} - Bangkok's best food, delivered`,
    orderHistory: `Order History | ${config.brandName} - Bangkok's best food, delivered`,
    profile: `My Profile | ${config.brandName} - Bangkok's best food, delivered`,
    shoppingCart: `Shopping Cart | ${config.brandName} - Bangkok's best food, delivered`,
    terms: `Terms & Conditions | ${config.brandName} - Bangkok's best food, delivered`,
    welcome: `Healthy Food Delivery for Bangkok | Order now | ${config.brandName}`,
    //onboarding: onboarding title for ${config.brandName} is in variety.js
    business: `Business | ${config.brandName} - Bangkok's best food, delivered`,
    walletBillPlzProcess: `Wallet Checkout | ${config.brandName} - Bangkok's best food, delivered`,
    packageCheckout: `Wallet Checkout | ${config.brandName}`,
    wallet: `Wallet by ${config.brandName} | Healthy Food Delivery in Bangkok`,
    rewards: `My Rewards | ${config.brandName} - Bangkok's best food, delivered`,
    ride: `Ride Now | ${config.brandName} - Bangkok's best food, delivered`,
    careers: `Join Us | ${config.brandName} - Bangkok's best food, delivered`,
    terminalAddress: `Add Address | ${config.brandName} - Bangkok's best food, delivered`,
    terminalLearnMore: `Learn More | ${config.brandName} - Bangkok's best food, delivered`,
    terminalLogin: `Login | ${config.brandName} - Bangkok's best food, delivered`,
    terminal: `Menu | ${config.brandName} - Bangkok's best food, delivered`,
    resetPassword: `Reset Password | ${config.brandName}`,
    offlineSignup: `Welcome to ${config.brandName}. Signup here to receive your 1 for 1`,
    promotion: `Promotions | ${config.brandName} - Bangkok\'s best food, delivered`,
};
