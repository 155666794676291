import config from '../../configs/parameters';
import { services } from '../index';
import request from 'superagent';

export const InitGoogle = (buttonSelector, onGoogleButtonClicked) => {
    const waitForGoogleInit = setInterval(() => {
        if (typeof window.google !== 'undefined' && typeof window.google.accounts !== 'undefined') {
            clearInterval(waitForGoogleInit);
            window.google.accounts.id.initialize({
                client_id: config.googleClientId,
                callback: onGoogleButtonClicked
            });

            if (document.getElementById(buttonSelector)) {
                const buttonDOM = document.getElementById(buttonSelector);
                window.google.accounts.id.renderButton(
                    buttonDOM,
                    { width: "325", locale: "en" }
                );

            }
        }
    }, 100);
};

export const GetGoogleReviews = () => {
    return request.get(
        `https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJf54KvvFIzDERaDcy3h_q7vE&fields=user_ratings_total,rating&key=${services.getParam(
            'googleMapKey'
        )}`
    );
};

export const decodeJwtToken = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}
