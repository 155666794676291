import React, { Component, Fragment } from 'react';
import { getGTMScript } from '../components/core/ScriptTag';
import { services } from '../services';
import { Helmet } from 'react-helmet';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';
import BasePage from './basePage';
import PromotionRoot from '../components/PromotionRoot';

const pageTitle = services.getTitle('promotion');

export default class PromotionPage extends Component {
    componentDidMount() {
        document.body.classList.add('promotion');
        window.scrollTo(0, 0);
    }

    render() {
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: '',
            lpType: '',
            lpVersion: '',
        });

        return (
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                    {localizationTags(`promotion`).map((tag) => tag)}
                    {getCanonicalTag(`promotion`)}
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <PromotionRoot />
            </Fragment>
        );
    }
}
