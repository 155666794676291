import config from '../configs/parameters';
import { GetCity, cities } from './city';

let ogurl = 'https://popmeals.com.my';
if (window.location.hostname.includes('com.th')) {
    ogurl = 'https://popmeals.co.th';
}

export const menuTags =
    GetCity() === cities.kl
        ? [
              {
                  name: 'description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Klang Valley. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  itemprop: 'name',
                  content: `Menu | ${config.brandName} | Popular meals at even more popular prices`,
              },
              {
                  itemprop: 'description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Klang Valley. Expect exciting, locally trending meals at an affordable price! `,
              },
              {
                  itemprop: 'image',
                  content:
                      'https://image.popcontent.link/metatag-images/dahmakanMalaysianMealDelivery.jpg',
              },
              { property: 'og:url', content: ogurl + '/kuala-lumpur/menu' },
              { property: 'og:type', content: 'website' },
              {
                  property: 'og:description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Klang Valley. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  property: 'og:image',
                  content:
                      'https://image.popcontent.link/metatag-images/dahmakanMalaysianMealDelivery.jpg',
              },
              { property: 'fb:app_id', content: config.facebookAppId },
              {
                  property: 'og:title',
                  content: `Menu | ${config.brandName} | Popular meals at even more popular prices`,
              },
              { name: 'twitter:card', content: 'summary_large_image' },
              {
                  name: 'twitter:title',
                  content: `Menu | ${config.brandName} | Popular meals at even more popular prices`,
              },
              {
                  name: 'twitter:description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Klang Valley. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  name: 'twitter:image',
                  content:
                      'https://image.popcontent.link/metatag-images/dahmakanMalaysianMealDelivery.jpg',
              },
          ]
        : [
              {
                  name: 'description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Bangkok. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  itemprop: 'name',
                  content: `Menu | ${config.brandName} | Popular meals at even more popular prices`,
              },
              {
                  itemprop: 'description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Bangkok. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  itemprop: 'image',
                  content:
                      'https://image.popcontent.link/metatag-images/polpa-og-image.jpg',
              },
              { property: 'og:url', content: ogurl + '/bangkok/menu' },
              { property: 'og:type', content: 'website' },
              {
                  property: 'og:description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Bangkok. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  property: 'og:image',
                  content:
                      'https://image.popcontent.link/metatag-images/polpa-og-image.jpg',
              },
              { property: 'fb:app_id', content: config.facebookAppId },
              {
                  property: 'og:title',
                  content: `Menu | ${config.brandName} | Popular meals at even more popular prices`,
              },
              { name: 'twitter:card', content: 'summary_large_image' },
              {
                  name: 'twitter:title',
                  content: `Menu | ${config.brandName} | Popular meals at even more popular prices`,
              },
              {
                  name: 'twitter:description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Bangkok. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  name: 'twitter:image',
                  content:
                      'https://image.popcontent.link/metatag-images/polpa-og-image.jpg',
              },
          ];

export const onboardingTags =
    GetCity() === cities.kl
        ? [
              {
                  name: 'description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Klang Valley. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  itemprop: 'name',
                  content: `Popular meals at even more popular prices | ${config.brandName}`,
              },
              {
                  itemprop: 'description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Klang Valley. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  itemprop: 'image',
                  content:
                      'https://image.popcontent.link/metatag-images/dahmakanMalaysianMealDelivery.jpg',
              },
              { property: 'og:url', content: ogurl },
              { property: 'og:type', content: 'website' },
              {
                  property: 'og:description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Klang Valley. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  property: 'og:image',
                  content:
                      'https://image.popcontent.link/metatag-images/dahmakanMalaysianMealDelivery.jpg',
              },
              { property: 'fb:app_id', content: config.facebookAppId },
              {
                  property: 'og:title',
                  content: `Popular meals at even more popular prices | ${config.brandName}`,
              },
              { name: 'twitter:card', content: 'summary_large_image' },
              {
                  name: 'twitter:title',
                  content: `Popular meals at even more popular prices | ${config.brandName}`,
              },
              {
                  name: 'twitter:description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Klang Valley. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  name: 'twitter:image',
                  content:
                      'https://image.popcontent.link/metatag-images/dahmakanMalaysianMealDelivery.jpg',
              },
          ]
        : [
              {
                  name: 'description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Bangkok. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  itemprop: 'name',
                  content: `Popular meals at even more popular prices | ${config.brandName}`,
              },
              {
                  itemprop: 'description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Bangkok. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  itemprop: 'image',
                  content:
                      'https://image.popcontent.link/metatag-images/polpa-og-image.jpg',
              },
              { property: 'og:url', content: ogurl + '/bangkok' },
              { property: 'og:type', content: 'website' },
              {
                  property: 'og:description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Bangkok. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  property: 'og:image',
                  content:
                      'https://image.popcontent.link/metatag-images/polpa-og-image.jpg',
              },
              { property: 'fb:app_id', content: config.facebookAppId },
              {
                  property: 'og:title',
                  content: `Popular meals at even more popular prices | ${config.brandName}`,
              },
              { name: 'twitter:card', content: 'summary_large_image' },
              {
                  name: 'twitter:title',
                  content: `Popular meals at even more popular prices | ${config.brandName}`,
              },
              {
                  name: 'twitter:description',
                  content: `${config.brandName} is designed to offer you popular meals at even more popular prices! Delivering everywhere in Bangkok. Expect exciting, locally trending meals at an affordable price!`,
              },
              {
                  name: 'twitter:image',
                  content:
                      'https://image.popcontent.link/metatag-images/polpa-og-image.jpg',
              },
          ];

export const walletTags =
    GetCity() === cities.kl
        ? [
              {
                  name: 'description',
                  content: `Enjoy cashless delivery with Pop Wallet.`,
              },
              {
                  itemprop: 'name',
                  content: `Wallet by ${config.brandName} | Popular meals at even more popular prices`,
              },
              {
                  itemprop: 'description',
                  content: `Enjoy cashless delivery with Pop Wallet.`,
              },
              {
                  itemprop: 'image',
                  content:
                      'https://image.popcontent.link/metatag-images/og-image.jpg',
              },
              { property: 'og:url', content: ogurl + '/wallet' },
              { property: 'og:type', content: 'website' },
              {
                  property: 'og:description',
                  content: `Enjoy cashless delivery with Pop Wallet.`,
              },
              {
                  property: 'og:image',
                  content:
                      'https://image.popcontent.link/metatag-images/og-image.jpg',
              },
              { property: 'fb:app_id', content: config.facebookAppId },
              {
                  property: 'og:title',
                  content: `Pop Wallet by ${config.brandName} | Popular meals at even more popular prices`,
              },
              { name: 'twitter:card', content: 'summary_large_image' },
              {
                  name: 'twitter:title',
                  content: `Pop Wallet by ${config.brandName} | Popular meals at even more popular prices`,
              },
              {
                  name: 'twitter:description',
                  content: `Enjoy cashless delivery with Pop Wallet.`,
              },
              {
                  name: 'twitter:image',
                  content:
                      'https://image.popcontent.link/metatag-images/og-image.jpg',
              },
          ]
        : [
              {
                  name: 'description',
                  content: `Enjoy cashless delivery with Pop Wallet.`,
              },
              {
                  itemprop: 'name',
                  content: `Wallet by ${config.brandName} | Popular meals at even more popular prices`,
              },
              {
                  itemprop: 'description',
                  content: `Enjoy cashless delivery with Pop Wallet.`,
              },
              {
                  itemprop: 'image',
                  content:
                      'https://image.popcontent.link/metatag-images/polpa-og-image.jpg',
              },
              { property: 'og:url', content: ogurl + '/wallet' },
              { property: 'og:type', content: 'website' },
              {
                  property: 'og:description',
                  content: `Enjoy cashless delivery with Pop Wallet.`,
              },
              {
                  property: 'og:image',
                  content:
                      'https://s3-apx-southeast-1.amazonaws.com/dahmakan-images/metatag-images/polpa-og-image.jpg',
              },
              { property: 'fb:app_id', content: config.facebookAppId },
              {
                  property: 'og:title',
                  content: `Wallet by ${config.brandName} | Popular meals at even more popular prices`,
              },
              { name: 'twitter:card', content: 'summary_large_image' },
              {
                  name: 'twitter:title',
                  content: `Wallet by ${config.brandName} | Popular meals at even more popular prices`,
              },
              {
                  name: 'twitter:description',
                  content: `Enjoy cashless delivery with Pop Wallet.`,
              },
              {
                  name: 'twitter:image',
                  content:
                      'https://image.popcontent.link/metatag-images/polpa-og-image.jpg',
              },
          ];
