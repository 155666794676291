import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import BasePage from './basePage';
import OrderHistoryRoot from '../components/OrderHistoryRoot';
import { getGTMScript } from '../components/core/ScriptTag';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('orderHistory');

export default class extends Component {
    componentDidMount() {
        document.body.classList.add('order-history');
    }

    render() {
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: 'Menu',
            lpType: '',
            lpVersion: '',
        });

        return (
            <Fragment>
                <Helmet>
                    <meta name="nosnippet" />
                    <title>{pageTitle}</title>
                    {localizationTags(`history`).map((tag) => tag)}
                    {getCanonicalTag(`history`)}
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <OrderHistoryRoot />
            </Fragment>
        );
    }
}
