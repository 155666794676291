import React from 'react';
import { Provider } from 'mobx-react';

import TerminalMenuStore from '../store/terminalMenuStore';

import BaseLayout from './core/baseLayout/Master_BaseLayout';
import Entry from './page/terminalLearnMorePage/Entry';
import TerminalHeader from './core/headers/TerminalHeader';

const stores = {
    terminalMenuStore: TerminalMenuStore,
};

const goToMenu = () => {
    window.location.href = '/terminal';
};

const Header = () => {
    return (
        <Provider {...stores}>
            <TerminalHeader onHeaderClick={goToMenu} />
        </Provider>
    );
};

export default class extends React.Component {
    render() {
        return (
            <BaseLayout.Page HeaderComponent={Header}>
                <main>
                    <Provider {...stores}>
                        <Entry />
                    </Provider>
                </main>
            </BaseLayout.Page>
        );
    }
}
