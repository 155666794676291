import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import ShareBox from './ShareBox';

import LoginPopup from '../../core/LoginPopup';
import RewardsBox from './RewardsBox';
import HalalPopup from '../../core/HalalPopup';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

const base = 'rewards';

@inject('rewardsStore')
@inject('loginPopupStore')
@inject('userStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.loginPopupStore.checkUser();
        this.props.rewardsStore.getRewards();
    }

    render() {
        const { showHalalPopup, setHideHalalPopup } = this.props.userStore;
        const isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
        return (
            <div className={base}>
                <LoginPopup />
                <div className={`${base}__header`}>
                    <center className={`${base}__name-greeting ellipsis m0a`}>
                        <h1 className={`${base}__title`}>
                            {intl.get('rewards.rewards')}
                        </h1>
                    </center>
                </div>
                <div className={`${base}__content`}>
                    <ShareBox />
                    <RewardsBox />
                </div>
                {showHalalPopup && isSmallScreen && (
                    <HalalPopup
                        showModal={showHalalPopup}
                        handleClose={setHideHalalPopup}
                    />
                )}
            </div>
        );
    }
}
