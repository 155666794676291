import { observable } from 'mobx';

class RewardsPopupStore {
    @observable showPopup = false;

    handleTogglePopup = (status) => {
        this.showPopup = status;
    };
}

const store = new RewardsPopupStore();
export default store;
