import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

const base = 'reviews-view';

@inject('dishStore', 'terminalDishStore')
@observer
export default class ReviewsView extends Component {
    capitalizeFirstLetter = (name) => {
        return name[0].toUpperCase() + name.slice(1);
    };

    render() {
        const { dishStore, terminalDishStore, terminal } = this.props;
        const store = terminal ? terminalDishStore : dishStore;
        const lastIndex = store.reviews.length - 1;

        return (
            <div className={base}>
                {store.reviews.map((review, i) => (
                    <div key={i}>
                        <div>
                            <p className={`${base}__header text--bolder`}>
                                {review.user_alias &&
                                    this.capitalizeFirstLetter(
                                        review.user_alias
                                    )}
                            </p>
                            <p className={`${base}__date`}>{review.time}</p>
                            <p className={`${base}__comment`}>
                                {review.comment}
                            </p>
                        </div>
                        {i !== lastIndex && <hr className={`${base}__dash`} />}
                    </div>
                ))}
            </div>
        );
    }
}
