import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Bubble from './Bubble';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import DateAndTimeSelectorUI from './DateAndTimeSelectorUI';

@inject('menuListStore', 'menuHeaderStore', 'cartStore')
@observer
export default class DateAndTimeSelector extends Component {
    state = {
        activeDate: '',
        activeDateTimeslots: [],
        activeTimeSlotId: '',
    };

    componentDidMount() {
        const { menuListStore } = this.props;
        // get valid timeslots for the active date
        const activeDateTimeslots = menuListStore.getValidTimeslotsForOneDate(
            menuListStore.activeDateObj
        );

        let activeTimeSlotId;
        // if active timeslot set -> select that timeslot
        if (menuListStore.activeTimeslotObj) {
            activeTimeSlotId = menuListStore.activeTimeslotObj.id;
            // else select first available timeslot
        } else {
            activeTimeSlotId = activeDateTimeslots[0].id;
        }

        this.setState({
            activeDate: menuListStore.activeDateObj.date,
            activeDateTimeslots: activeDateTimeslots,
            activeTimeSlotId: activeTimeSlotId,
        });
    }

    isActiveDate = (date) => {
        const { activeDate } = this.state;
        if (activeDate === date) {
            return true;
        }
    };

    setActiveDate = (selectedDateObj) => {
        const { menuListStore } = this.props;
        const activeDateTimeslots =
            menuListStore.getValidTimeslotsForOneDate(selectedDateObj);

        // update timeslot list UI with valid timeslots for selected date
        this.setState({
            activeDate: selectedDateObj.date,
            activeDateTimeslots: activeDateTimeslots,
            activeTimeSlotId: activeDateTimeslots[0].id,
        });
    };

    isActiveTimeslot = (timeslotId) => {
        const { activeTimeSlotId } = this.state;
        if (timeslotId === activeTimeSlotId) {
            return true;
        }
    };

    setActiveTimeslot = (timeslotId) => {
        const { activeDateTimeslots } = this.state;
        const activeTimeSlotId = activeDateTimeslots.find(
            (timeslot) => timeslot.id === timeslotId
        ).id;
        this.setState({
            activeTimeSlotId,
        });
    };

    saveTimeslot = () => {
        const { activeDate, activeTimeSlotId, activeDateTimeslots } =
            this.state;
        const { menuListStore, cartStore, hideSelectors } = this.props;
        hideSelectors();
        const activeDateObj = menuListStore.validDates.find(
            (dateObj) => dateObj.date === activeDate
        );
        const activeTimeslotObj = activeDateTimeslots.find(
            (timeslotObj) => timeslotObj.id === activeTimeSlotId
        );

        // on save date and time selection, save new activeDate and activeTimeslot in menuListStore
        menuListStore.storeActiveDateObj(activeDateObj);
        menuListStore.storeActiveTimeslotObj(activeTimeslotObj);

        // if item in cart for the active date, changing the timeslot will update the cart with the new selected timeslot
        cartStore.updateDateTimeslotInCart(
            activeDateObj.date,
            activeTimeslotObj
        );
        menuListStore.refreshMenu();
        window.scrollTo(0, 0);
    };

    renderDateAndTimeSelector = () => {
        const { activeDateTimeslots } = this.state;
        const { menuListStore } = this.props;

        return (
            menuListStore.validDates &&
            menuListStore.validDates.length > 0 && (
                <DateAndTimeSelectorUI
                    mainHeading="When to deliver your food?"
                    subHeading="At what time?"
                    validDates={menuListStore.validDates}
                    validTimeslotsForActiveDate={activeDateTimeslots}
                    isActiveDate={this.isActiveDate}
                    setActiveDate={this.setActiveDate}
                    isActiveTimeslot={this.isActiveTimeslot}
                    setActiveTimeslot={this.setActiveTimeslot}
                    saveTimeslot={this.saveTimeslot}
                />
            )
        );
    };

    render() {
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return isMobile ? (
            this.renderDateAndTimeSelector()
        ) : (
            <Bubble>{this.renderDateAndTimeSelector()}</Bubble>
        );
    }
}
