import React from 'react';
import { inject, observer } from 'mobx-react';
import template from 'lodash/template';

import intl from 'react-intl-universal';

const base = 'order-status-banner';

@inject('orderStatusStore')
@observer
export default class extends React.Component {
    render() {
        const { orderStatusStore } = this.props;
        let tta = orderStatusStore.tta;
        tta = tta > 0 ? tta : 1;
        const s = tta === 1 ? '' : 's';

        return (
            <div className={`${base}__content ${base}__content--delayed`}>
                <p className={`${base}__text`}>
                    {intl.get('orderStatus.delayed.line1')}
                </p>
                <p className={`${base}__text`}>
                    {template(intl.get('orderStatus.delayed.line2'))({
                        time: tta,
                        s: s,
                    })}
                </p>
                <p className={`${base}__text`}>
                    {intl.get('orderStatus.delayed.line3')}
                </p>
            </div>
        );
    }
}
