import React from 'react';
import { inject, observer } from 'mobx-react';

const base = 'terminal-mobile-addon-item';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    handleAddItemToCart = () => {
        const { item, terminalMenuStore } = this.props;
        terminalMenuStore.handleAddItemToCart(item, true);
    };

    render() {
        const { item, terminalCartStore } = this.props;
        const itemQuantity = parseInt(
            terminalCartStore.cartServices.getQuantityOfItem(item)
        );

        return (
            <div className={base}>
                <div className="image-title-price">
                    <img
                        className={`${base}__image`}
                        src={item.image}
                        alt="addon item image"
                    />
                    <div className={`${base}__name_price`}>
                        <p className="name">{item.title_bold}</p>
                        <p className="price">RM {item.price}</p>
                    </div>
                </div>
                <button
                    className={`${base}__cart`}
                    onClick={this.handleAddItemToCart}
                >
                    {itemQuantity && itemQuantity > 0 ? (
                        <p className="item_quanity">{itemQuantity}</p>
                    ) : (
                        <img
                            loading="lazy"
                            src={
                                'https://image.popcontent.link/cart_icon.svg'
                            }
                            className={`${base}__cart_img`}
                        />
                    )}
                </button>
            </div>
        );
    }
}
