import React from 'react';
import { currency } from '../../services';

export default class extends React.Component {
    render() {
        const { strikethrough, price } = this.props;

        const originalPrice = currency.localCurrency(price);

        if (strikethrough) {
            return (
                <s className="text--red">
                    <span style={{ whiteSpace: 'nowrap' }}>
                        {originalPrice}
                    </span>
                </s>
            );
        }

        return <span style={{ whiteSpace: 'nowrap' }}>{originalPrice}</span>;
    }
}
