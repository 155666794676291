import React from 'react';
import { inject, observer } from 'mobx-react';
import MainItems from './MainItems';
import CouponCode from './CouponCode';
import DealCombo from './DealCombo';
const base = 'terminal-menu-items-ui';
import { withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { GetQueryParamFromUrl } from '../../../services/url';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
class MenuItemsUI extends React.Component {
    handleAddItemToCart = (item, isAddon) => {
        const { terminalMenuStore, terminalCartStore, history } = this.props;

        if (item.item_type == "Item") {
            terminalMenuStore.cartItem = item;
            history.push(`/terminal-mealSelection?id=${uuid()}`);
        } else {
            terminalMenuStore.handleAddItemToCart(item, isAddon);
            if (isAddon) {
                terminalCartStore.updateInCartOrder();
            }
        }
    };

    configureCombo = (combo) => {
        const { terminalMenuStore, history } = this.props;
        terminalMenuStore.comboObject = combo;
        history.push(`/terminal-dealSelection?id=${combo.id}`);
    }

    componentDidMount() {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.triggerCardReaderStatusQuery();
    }

    handleShowSplash = () => {
        const { terminalMenuStore, terminalCartStore } = this.props;
        let orderType = GetQueryParamFromUrl('orderType');
        terminalMenuStore.showSplashScreenActions(orderType);
        terminalCartStore.toggleCombo = false;
        terminalMenuStore.setCouponTxt('');
        terminalMenuStore.setCouponDisable(false);
        terminalMenuStore.setCouponErrMessage(null);
        terminalMenuStore.setOrderTypeIfExistsAndReloadMenu(orderType);
    };

    renderContent = () => {
        const { terminalMenuStore, items, sections } = this.props;
        const { itemsUIView } = terminalMenuStore;
        const itemSections = sections;

        if (itemsUIView == 'Coupons') {
            return <CouponCode />;
        } else if (itemsUIView == 'Deals') {
            return <DealCombo />;
        } else {
            return <MainItems items={items} sections={itemSections} onAddToCart={this.handleAddItemToCart} configureCombo={this.configureCombo} handleShowSplash={this.handleShowSplash}/>;
        }
    };

    render() {
        return <div className={base}>{this.renderContent()}</div>;
    }
}
export default withRouter(MenuItemsUI)