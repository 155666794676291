import React, { Component } from 'react';
import intl from 'react-intl-universal';
import SelectPlanUI from './SelectPlanUI';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { inject, observer } from 'mobx-react';
import { services } from '../../../services/index';

const stripePromise = loadStripe(services.getParam('stripeAPIKey'));
const base = 'select-popup';

@inject('selectPopupStore')
@observer
export default class SelectPaymentPage extends Component {
    render() {
        const { selectPopupStore } = this.props;
        const style = {
            base: {
                color: '#000000',
                letterSpacing: '0.025em',
                padding: '15px 18px',
                fontFamily: '"Barlow"',
            },
            invalid: {
                color: '#9e2146',
            },
        };

        const options = {
            fonts: [
                {
                    src: 'url(https://d3tjqo2jh935k9.cloudfront.net/fonts/barlow-regular.ttf)',
                    family: 'Barlow',
                },
            ],
        };

        return (
            <div className={`${base}__payment-container`}>
                <div className={`${base}__payment-header-container`}>
                    <p className={`${base}__payment-header text--bolder`}>
                        {intl.get('selectPopup.pageTwo.header')}
                    </p>
                    <p className={`${base}__payment-subheader`}>
                        {intl.get('selectPopup.pageTwo.subheader')}
                    </p>
                </div>
                <div className={`${base}__payment-plan-container`}>
                    <SelectPlanUI
                        plan={selectPopupStore.selected}
                        onClick={selectPopupStore.selectPlan}
                        singlePlan={true}
                    />
                </div>
                <Elements
                    stripe={stripePromise}
                    options={options}
                    style={style}
                    fonts={options.fonts}
                >
                    <CheckoutForm />
                </Elements>
            </div>
        );
    }
}
