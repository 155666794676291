/* eslint-disable max-statements */
import React from 'react';
import { inject, observer } from 'mobx-react';

import OneDate from '../shoppingCartPage/confirmPayment/OneDate';
import OneItem from '../shoppingCartPage/confirmPayment/OneItemNoWallet';
import Receipt from '../shoppingCartPage/confirmPayment/ReceiptNoWallet';
import LoginPopup from '../../core/LoginPopup';
import Modal from '../../core/Modal';
import PaymentInProgress from '../../core/PaymentInProgress';
import EmptyCart from '../shoppingCartPage/EmptyCart';
import { RESOURCE, MAYBE } from '../../../services/types';
import { services } from '../../../services';

const mapGetUser = (user) => {
    return {
        username: user.username,
        alias: user.alias,
        firstname: user.firstname,
    };
};

const mapGetOrderSummary = (status) => {
    return {
        sub_total: status['gross_total'],
        delivery_charge: status['delivery_charge'],
        discount: status['discount'],
        net_total: status['net_total'],
        payment_method: status['payment_method'],
        redeem_point: status['redeem_point'],
        first_order: status['first_order'],
        price_summary: status['price_summary'],
    };
};

const mapGetGroupId = (status) => {
    return {
        group_id: status.group_id,
    };
};

const mapGetDiscountCode = () => '';

const mapGetAddress = (id) => (addresses) => {
    return addresses.find((address) => address.id === id) || {};
};

@inject('headerStore')
@inject('billPlzStore')
@inject('loginPopupStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.loginPopupStore.checkUser();
        this.props.billPlzStore.loadData();
    }

    render() {
        const store = this.props.billPlzStore;
        const { status, cart } = store;

        if (status === RESOURCE.Initial || RESOURCE.Failure.is(status)) {
            return <LoginPopup />;
        }

        if (cart === MAYBE.None) {
            return (
                <div>
                    <LoginPopup />
                    <EmptyCart />
                </div>
            );
        }

        if (RESOURCE.Success.is(status)) {
            const user = store.user.getOrEmpty(mapGetUser);
            const orderSummary = store.status.getOrEmpty(mapGetOrderSummary);
            const finalOrderSummary = store.status.getOrEmpty(mapGetGroupId);
            const discountCode = store.status.getOrEmpty(mapGetDiscountCode);

            const ordersComponent = store.cart.map((dateOrder) => {
                const itemsLine = dateOrder.menu_item.map((item) => (
                    <OneItem
                        key={item.id}
                        quantity={item.quantity}
                        name={item.title_bold || item.name}
                        price={
                            item.special_offer_price
                                ? item.special_offer_price
                                : item.price
                        }
                    />
                ));

                return (
                    <OneDate
                        key={dateOrder.date}
                        items={itemsLine}
                        date={dateOrder.date}
                        address={store.addresses.getOrEmpty(
                            mapGetAddress(dateOrder.address_id)
                        )}
                        timeslot={dateOrder.time_frame}
                    />
                );
            });
            return (
                <div>
                    <LoginPopup />
                    <Receipt
                        user={user}
                        orderSummary={orderSummary}
                        finalOrderSummary={finalOrderSummary}
                        discountCode={discountCode}
                        orders={ordersComponent}
                    />
                    <Modal
                        showModal={store.reviewPromptModalShowing}
                        showBackdropOnMobile={true}
                        showCloseIcon={true}
                        closeButtonClasses={`login-popup__close`}
                        contentClasses={`review-popup__container modal__content--golden-ratio modal__content--extra-margin`}
                        onBackdropClicked={() => {
                            store.hidePopup(false);
                        }}
                        onCloseClicked={() => {
                            store.hidePopup(false);
                        }}
                    >
                        <p className="lh--125">
                            Would you like to leave a review?
                            <br />
                            Support {services.getParam('brandName')} and give 5
                            stars.
                        </p>
                        <a
                            href="https://search.google.com/local/writereview?placeid=ChIJf54KvvFIzDERaDcy3h_q7vE"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button
                                onClick={() => {
                                    store.hidePopup(true);
                                }}
                                className="mtm button button--medium button--success text--center width--50 display--inline-block"
                            >
                                YES
                            </button>
                        </a>
                        <button
                            onClick={() => {
                                store.hidePopup(false);
                            }}
                            className="button button--medium button--outline text--center width--50 display--inline-block"
                        >
                            NO
                        </button>
                    </Modal>
                </div>
            );
        }

        return (
            <div>
                <LoginPopup />
                <PaymentInProgress color="Green" />
            </div>
        );
    }
}
