import React, { Component } from 'react';
import Modal from './Modal';
import AddressAutocomplete from './AddressAutocomplete';
import { inject, observer } from 'mobx-react';
import { parseLocation } from '../../services/geoparser';
import { when } from 'mobx';
import { SMALL_SCREEN_MEDIA } from '../../services/constants';
import DateAndTimeSelector from '../page/menuPage/DateAndTimeSelector';
import Hammer from 'hammerjs';
import AddressList from '../page/menuPage/AddressList';
import intl from 'react-intl-universal';

const base = 'address-selector-popup';

@inject(
    'userStore',
    'cartStore',
    'menuCheckoutStore',
    'addressAutocompleteStore',
    'deliveryAddressStore',
    'menuListStore'
)
@observer
export default class AddressSelectorPopup extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.savedAddressContainer = React.createRef();
        this.nearbyAddressContainer = React.createRef();
        this.hammer = new Hammer(document.body);
        this.state = {
            focus: null,
        };
    }

    handleClickOutside = (e) => {
        const clickedOutside = this.savedAddressContainer.current
            ? this.myRef.current &&
              !this.myRef.current.contains(e.target) &&
              this.savedAddressContainer.current &&
              !this.savedAddressContainer.current.contains(e.target)
            : this.myRef.current && !this.myRef.current.contains(e.target);
        if (clickedOutside) {
            this.setState({ focus: false });
            e.target.focus();
        }
    };

    componentDidMount() {
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        if (isMobile) {
            window.scrollTo(0, 0);
        }
        // this.hammer.on('tap', (e) => this.handleClickOutside(e));
        this.hammer.on('tap', (e) => this.handleClickOutside(e));
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.hammer.off('tap', (e) => this.handleClickOutside(e));
        // this.hammer.off('tap', (e) => this.handleClickOutside(e));
    }

    closeModal = () => {
        const { cartStore } = this.props;
        cartStore.showAddressSelectorPopup = false;
    };

    handleAddressConfirmed = (place) => {
        const { onMenu } = this.props;
        const { deliveryAddressStore } = this.props;

        if (place) {
            deliveryAddressStore.setPlace(place);
        }

        this.handleSubmitAddress(place);
        if (!onMenu) {
            this.closeModal();
        }
    };

    handleAddressSelected = (prediction, cb) => {
        const { addressAutocompleteStore, deliveryAddressStore } = this.props;
        addressAutocompleteStore.handleAddressSelected(prediction, cb);

        when(
            () => deliveryAddressStore.place,
            () => {
                deliveryAddressStore.place.geometry = {};
                deliveryAddressStore.place.geometry.location =
                    addressAutocompleteStore.setAddressCoordinate(
                        deliveryAddressStore.place
                    );
            }
        );
    };

    handleSubmitAddress = (place) => {
        const { deliveryAddressStore } = this.props;
        const coords = new window.google.maps.LatLng(
            place.latitude,
            place.longitude
        );
        deliveryAddressStore.setNewAddress(parseLocation(place), true);
        deliveryAddressStore.place.geometry = {};
        deliveryAddressStore.place.geometry.location = coords;
        when(
            () => deliveryAddressStore.newAddress,
            () => {
                this.setState({ focus: false });
                deliveryAddressStore.saveAddress({ refreshMenu: true });
            }
        );
    };

    toggleFocus = (focusState) => {
        this.setState({ focus: focusState });
    };

    handleSelectAddress = (address) => {
        const { deliveryAddressStore, menuListStore } = this.props;
        deliveryAddressStore.setSelected({
            address,
            date: menuListStore.activeDateObj.date,
            refreshMenu: true,
        });
        this.setState({ focus: false });
    };

    handleNearbyAddressClicked = (place) => {
        const { addressAutocompleteStore } = this.props;
        addressAutocompleteStore.nearbyLocation = place;
        this.handleAddressSelected(place, this.handleAddressConfirmed);
    };

    handleDisplayAddress = () => {
        const { deliveryAddressStore } = this.props;
        const { building_name, name, street, district, city } =
            deliveryAddressStore.selectedAddress;
        const buildingName = building_name;
        const displayStreet = street && street !== '' && street + ', ';
        const displayDistrict =
            district && district !== '' && displayStreet + district + ', ';
        const displayCity =
            displayStreet + (city && city !== '' && city) + ', ';

        let addressToDisplay =
            buildingName ||
            name ||
            displayDistrict ||
            displayCity ||
            this.deliveryAddressStore.selectedAddress.display_address;

        if (
            addressToDisplay &&
            addressToDisplay[addressToDisplay.length - 2] === ','
        ) {
            addressToDisplay = addressToDisplay.slice(0, -2);
        }

        return addressToDisplay;
    };

    renderAddressSelector = () => {
        const { deliveryAddressStore, addressAutocompleteStore } = this.props;
        const { focus } = this.state;
        const base = 'address-selector-popup';
        const savedAddresses =
            deliveryAddressStore.addresses &&
            deliveryAddressStore.addresses
                .filter(
                    (address) =>
                        !address.pickup_location &&
                        JSON.stringify(address) !==
                            JSON.stringify(deliveryAddressStore.selectedAddress)
                )
                .slice(0, 2);
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return (
            <div className={`${base}__popup`}>
                <div className={`${base}__main-container`}>
                    <div className={`${base}__header-container`}>
                        <p className={`${base}__header text--bolder`}>
                            {intl.get('addressSelector.title')}
                        </p>
                    </div>
                    <div className={`${base}__input-box`}>
                        <div
                            className={`${base}__auto-complete-container`}
                            ref={this.myRef}
                        >
                            <AddressAutocomplete
                                customInputClasses={`${base}__input`}
                                disableAutoFocus={true}
                                displayBlack={isMobile}
                                showBorder={isMobile ? focus : true}
                                inputContainerClass={`${base}__input-container`}
                                marginLeft={(isMobile ? focus : true) ? 15 : 0}
                                onAddressSelected={this.handleAddressSelected}
                                onAddressConfirmed={this.handleAddressConfirmed}
                                selectedAddress={
                                    deliveryAddressStore.selectedAddress &&
                                    this.handleDisplayAddress()
                                }
                                toggleFocus={this.toggleFocus}
                                focus={focus}
                            />
                        </div>
                    </div>

                    {(isMobile ? focus : true) && (
                        <div ref={this.savedAddressContainer}>
                            <AddressList
                                title={intl.get(
                                    'addressSelector.savedAddresses'
                                )}
                                addresses={savedAddresses}
                                onClick={this.handleSelectAddress}
                                containerId="saved-address-container"
                                base="address-selector-popup"
                            />
                            <AddressList
                                title={intl.get(
                                    'addressSelector.nearbyLocations'
                                )}
                                addresses={
                                    addressAutocompleteStore.nearbyLocations
                                }
                                onClick={this.handleNearbyAddressClicked}
                                marginTop={30}
                                base="address-selector-popup"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    render() {
        const { cartStore, deliveryAddressStore } = this.props;
        const { focus } = this.state;
        const contentClasses = `${base}`;
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return (
            <Modal
                showModal={cartStore.showAddressSelectorPopup}
                contentClasses={`${contentClasses} ${
                    !isMobile && 'modal__content--golden-ratio'
                }`}
                closeButtonClasses="fill--white"
                closeButtonMargin="modal__extra-margin"
                onBackdropClicked={this.closeModal}
                onBackClicked={this.handleNavigateBack}
                onCloseClicked={this.closeModal}
                showCloseIcon={true}
            >
                {this.renderAddressSelector()}
                {focus === false &&
                    isMobile &&
                    deliveryAddressStore.selectedAddress && (
                        <DateAndTimeSelector hideSelectors={this.closeModal} />
                    )}
            </Modal>
        );
    }
}
