import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { inject, observer } from 'mobx-react';
import { RESOURCE } from '../../../services/types';
import InputUI from '../InputUI';
import SubheaderUI from './SubheaderUI';
import ButtonUI from './ButtonUI';

const base = 'login-popup';

@inject('loginPopupStore')
@observer
export default class EmailView extends Component {
    componentDidMount() {
        const { loginPopupStore } = this.props;
        loginPopupStore.phoneNumber = '';
    }

    handleSubmitUsername(e) {
        e.preventDefault();
        this.props.loginPopupStore.checkUsername();
    }

    renderSubheader = () => {
        return (
            <SubheaderUI
                customWidthPercentage="80%"
                text={intl.get('loginPopup.usernameView.enterEmail')}
            />
        );
    };

    renderInput = () => {
        const { loginPopupStore } = this.props;

        return (
            <InputUI
                id="input-email"
                autoFocus="true"
                autoComplete="email"
                containerClass={`${base}__input-container`}
                inputClass={`${base}__input`}
                placeholder={intl.get('loginPopup.usernameView.emailAddress')}
                type="email"
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
                required="true"
                value={loginPopupStore.email}
                onChange={::loginPopupStore.setEmail}
                onInvalid={(e) =>
                    e.target.setCustomValidity(
                        e.target.willValidate
                            ? ''
                            : 'Please enter a valid email address'
                    )
                }
            />
        );
    };

    renderButton = () => {
        const { loginPopupStore } = this.props;

        return (
            <ButtonUI
                text={intl.get('loginPopup.usernameView.emailCta')}
                customClasses="button--success"
                loading={loginPopupStore.usernameChecker === RESOURCE.Loading}
                id="submit-password-btn"
            />
        );
    };

    render() {
        return (
            <div className={base}>
                <h3 className={`${base}__title`}>
                    {intl.get('loginPopup.emailView.enterEmail')}
                </h3>
                {this.renderSubheader()}
                <form method="POST" onSubmit={::this.handleSubmitUsername}>
                    {this.renderInput()}
                    {this.renderButton()}
                </form>
            </div>
        );
    }
}
