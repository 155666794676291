import { httpGet } from '../http';
import Cookies from 'js-cookie';

export const GetWalletOptions = () => httpGet(`/wallet/options`);

export const CheckWalletPurchaseStatus = (walletOrderId) =>
    httpGet(`/v5/orders/package/${walletOrderId}`, {
        authType: 'Bearer',
    });

export const SelectingWalletPackage = {
    get: () => {
        try {
            return JSON.parse(Cookies.get('selected_wallet'));
        } catch (ex) {
            return null;
        }
    },
    set: (value) => {
        Cookies.set('selected_wallet', value, { expires: 30 });
    },
    remove: () => {
        Cookies.remove('selected_wallet');
    },
};
