import React from 'react';
import intl from 'react-intl-universal';
import { CircleLoader } from '../../core/Loading';
import { stripRepeatedCommas } from '../../../services';

const base = 'profile-address__item';

export default ({ address, onDeleteClicked }) => {
    const typeDisplay =
        address['address_type'] === 'Else'
            ? address['location_type']
            : address['address_type'];

    return (
        <div className={`${base} display--flex flex-align-items-center`}>
            <div className={`${base}__address-wrapper flex-1`}>
                <p className="mbs">{typeDisplay}</p>
                <p className="fs--small-medium lh--150 ellipsis">
                    {stripRepeatedCommas(address['address'])}
                </p>
            </div>
            <div className={`${base}__delete-btn-wrapper flex-none`}>
                <button
                    type="button"
                    className={`${base}__delete-btn`}
                    onClick={onDeleteClicked.bind(null, address)}
                >
                    {intl.get('profile.address.delete')}
                </button>
            </div>
        </div>
    );
};

export const AddressBusy = () => {
    return (
        <div className={`${base} display--flex flex-align-items-center`}>
            <CircleLoader />
            <div className="mlm fs--small-medium">
                {intl.get('profile.address.loading')}
            </div>
        </div>
    );
};
