import uniqBy from 'lodash/uniqBy';
import countBy from 'lodash/countBy';
import { getCompanyFromLocalStorage } from '../../services/company';

export default class {
    constructor(items) {
        this.items = items || [];
    }

    static getItemType(item) {
        return item.addon_category;
    }

    static isAddon(item) {
        return Boolean(item.addon_category) === true;
    }

    getItems() {
        return this.items;
    }

    getMainMeals() {
        return this.getItems().filter(
            (item) => item.item_type === 'Item' || item.item_type === 'Combo'
        );
    }

    getUniqueItemsAt() {
        const items = this.getItems();

        return uniqBy(items, 'today_menu_id');
    }

    getQuantityOfDishOn({ dishId, date }) {
        const items = this.getItems();
        const dishes = items.filter((item) => item.today_menu_id === dishId);

        return dishes ? dishes.length : 0;
    }

    getQuantityOfItem(item) {
        return this.items.filter(
            (eachAddedItem) =>
                eachAddedItem.menu_date === item.menu_date &&
                eachAddedItem.today_menu_id === item.today_menu_id
        ).length;
    }

    getGTMProductList() {
        const uniqItems = uniqBy(this.items, 'today_menu_id');

        return uniqItems.map((item) => {
            return {
                id: item.id,
                name: item.name,
                price: item.price,
                variant: this.constructor.getItemType(item),
                quantity: this.getQuantityOfItem(item),
                today_menu_id: item.today_menu_id,
            };
        });
    }

    getTagsAsMixPanelList = () => {
        const tags = this.items
            .filter((item) => Boolean(item.tag))
            .map((item) => item.tag.name);
        return [...new Set(tags)];
    };

    getLinesAsMixPanelList = () => {
        const lines = this.items
            .filter((item) => Boolean(item.line))
            .map((item) => item.line.name);
        return [...new Set(lines)];
    };

    getMealTimeAsMixPanelList = () => {
        const tags = this.items
            .filter((item) => Boolean(item.menu_type))
            .map((item) => item.menu_type);
        return [...new Set(tags)];
    };

    getMealsAsMixPanelList() {
        return this.items
            .filter((item) => Boolean(item.addon_category) === false)
            .map((item) => item.name || item.title_bold);
    }

    getAddonsAsMixPanelList() {
        return this.items
            .filter(this.constructor.isAddon)
            .map((item) => item.name || item.title_bold);
    }

    createOrders({ items, dateString, timeSlotString, addressId }) {
        const counts = countBy(items, 'today_menu_id');
        const uniqueItems = uniqBy(items, 'today_menu_id');

        return {
            date: dateString,
            instant_delivery: false,
            time_frame: timeSlotString,
            address_id: addressId,
            menu_item: uniqueItems.map((item) => ({
                id: item['today_menu_id'],
                quantity: counts[item['today_menu_id']],
                price: item.price,
                item_type: item['addon_category'] ? 'Addons' : 'Item',
            })),
            use_company: getCompanyFromLocalStorage(),
        };
    }

    createPosOrders({ items }) {
        const allValueMealItems = items.filter(
            (cartItem) => cartItem.isValueMealOption
        );
        const allMainMealItems = items.filter(
            (cartItem) =>
                !cartItem.isValueMealOption && cartItem.type != 'combo' && cartItem.type != 'meal'
        );
        const allComboItems = items.filter(
            (cartItem) => cartItem.type == 'combo' || cartItem.type == 'meal'
        );
        const counts = countBy(allMainMealItems, 'menu_item_id');
        const uniqueItems = uniqBy(allMainMealItems, 'menu_item_id').concat(
            uniqBy(allComboItems, 'id')
        );



        return uniqueItems
            .map((item) => {
                const totalSingleItemQuantity = counts[item['menu_item_id']];

                let comboOrMealItem = {
                    menu_item_id: item['menu_item_id'],
                    quantity: totalSingleItemQuantity,
                };

                if (item.type == 'combo' || item.type == "meal") {
                    comboOrMealItem.menu_item_id = item.type == 'combo' ? item.id : item.today_menu_id.menu_item_id;
                    comboOrMealItem.quantity = item.quantity;
                    let menuitemList = [];
                    item.value_meal_menu_item.map((o, key) => {
                        menuitemList.push({
                            menu_item_id: o.value.menu_item_id,
                            quantity: parseInt(o.quantity),
                        });
                    });
                    comboOrMealItem['value_meal_menu_item'] = menuitemList;
                } else {
                    const mainItemRandomIds = items
                        .filter(
                            (cartItem) =>
                                cartItem['menu_item_id'] ===
                                item['menu_item_id']
                        )
                        .map((mainItem) => mainItem.randomItemId);

                    const valueMealItems = allValueMealItems.filter(
                        (cartItem) =>
                            mainItemRandomIds.includes(cartItem.randomItemId) &&
                            item.menu_item_id !== cartItem.menu_item_id &&
                            cartItem.isValueMealOption
                    );
                    let totalValueMealsQuantity = 0;

                    if (valueMealItems && valueMealItems.length > 0) {
                        const uniqueValueMealItems = uniqBy(
                            valueMealItems,
                            'menu_item_id'
                        );
                        const valueMealMenuItems = uniqueValueMealItems.map(
                            (valueMealItem) => {
                                const valueMealCounts = countBy(
                                    valueMealItems,
                                    'menu_item_id'
                                );
                                const valueMealId =
                                    valueMealItem['menu_item_id'];
                                const valueMealQuantity =
                                    valueMealCounts[valueMealId];
                                totalValueMealsQuantity += valueMealQuantity;

                                return {
                                    menu_item_id: valueMealId,
                                    quantity: valueMealQuantity,
                                };
                            }
                        );
                        comboOrMealItem['value_meal_menu_item'] =
                            valueMealMenuItems;
                    }

                    if (
                        totalValueMealsQuantity > 0 &&
                        totalSingleItemQuantity > totalValueMealsQuantity
                    ) {
                        //edge case handle: when one combo is ordered + one meal ordered which is similar to meal ordered as part of combo
                        comboOrMealItem['quantity'] = totalValueMealsQuantity;
                        //mainItemPlusCombos: Instead of just one item, now we will have two elements in a list, one corresponding to combo and one for base meal.
                        const mainItemPlusCombos = [
                            comboOrMealItem,
                            {
                                menu_item_id: item['menu_item_id'],
                                quantity:
                                    totalSingleItemQuantity -
                                    totalValueMealsQuantity,
                            },
                        ];
                        comboOrMealItem = mainItemPlusCombos;
                    }
                }

                return comboOrMealItem;
            })
            .filter((item) => item)
            .reduce((a, b) => a.concat(b), []);


    }

    isEmptyAt({ date }) {
        return this.getItems().length === 0;
    }

    isEmpty() {
        return this.items.length === 0;
    }
}
