import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import PopupExplainedUI from '../../shoppingCartPage/deliveryAddress/PopupExplainedUI';

@inject('deliveryAddressStore')
@observer
export default class extends React.Component {
    render() {
        const store = this.props.deliveryAddressStore;
        const options = [
            {
                title: store.PICKUP_GUARD_HOUSE,
                description: intl.get(
                    'deliveryAddress.popupExplain.pickUpFromGuardHouseDetail'
                ),
                preferred: true,
            },
            {
                title: store.MEET_DRIVER_AT_DOOR,
                description: intl.get(
                    'deliveryAddress.popupExplain.meetTheDriverAtMyGateDetail'
                ),
                preferred: false,
            },
        ];

        return <PopupExplainedUI options={options} />;
    }
}
