import React from 'react';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';

import intl from 'react-intl-universal';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

const base = 'view-switcher';

@inject('dishStore', 'terminalDishStore')
@observer
export default class extends React.Component {
    getHeaderClasses(view) {
        const { dishStore, terminalDishStore, terminal } = this.props;
        const store = terminal ? terminalDishStore : dishStore;

        return cx(`${base}-header`, {
            [`${base}-header--${terminal ? 'active-terminal' : 'active'}`]:
                store.view === view,
        });
    }

    componentWillUnmount() {
        const { dishStore, terminalDishStore, terminal } = this.props;
        const store = terminal ? terminalDishStore : dishStore;

        store.view = 'GeneralView';
    }

    render() {
        const { dishStore, terminal, terminalDishStore } = this.props;
        const store = terminal ? terminalDishStore : dishStore;
        const dish = store.dish;
        const noReviews = store.reviews && store.reviews.length === 0;
        const noAttributes = (dish.attributes || []).length === 0;
        const noIngredients = (dish.ingredients || []).length === 0;
        const showHalal = dishStore.showHalal;

        return (
            <div className={base}>
                <div className={`${base}__container`}>
                    {dish.description ? (
                        <span className="mrl">
                            <span
                                className={this.getHeaderClasses(
                                    store.GENERAL_VIEW
                                )}
                                onClick={::store.setView(store.GENERAL_VIEW)}
                            >
                                {intl.get('dish.description')}
                            </span>
                            <hr
                                className={`${base}__${
                                    store.view === store.GENERAL_VIEW
                                        ? 'dash'
                                        : 'dash-non-selected'
                                }`}
                            />
                        </span>
                    ) : null}
                    {(dish.ingredients || []).length !== 0 ? (
                        <span className="mrl hide--if-small show--if-large">
                            <span
                                className={this.getHeaderClasses(
                                    store.INGREDIENT_VIEW
                                )}
                                onClick={::store.setView(store.INGREDIENT_VIEW)}
                            >
                                {intl.get('dish.ingredients')}
                            </span>
                            <hr
                                className={`${base}__${
                                    store.view === store.INGREDIENT_VIEW
                                        ? 'dash'
                                        : 'dash-non-selected'
                                }`}
                            />
                        </span>
                    ) : null}
                    {(dish.ingredients || []).length !== 0 ? (
                        <span className="mrl hide--if-large show--if-small">
                            <span
                                className={this.getHeaderClasses(
                                    store.INGREDIENT_VIEW
                                )}
                                onClick={::store.setView(store.INGREDIENT_VIEW)}
                            >
                                {intl.get('dish.ingredientsAndNutri')}
                            </span>
                            <hr
                                className={`${base}__${
                                    store.view === store.INGREDIENT_VIEW
                                        ? 'dash'
                                        : 'dash-non-selected'
                                }`}
                            />
                        </span>
                    ) : null}
                    {(dish.attributes || []).length !== 0 ? (
                        <span className="mrl hide--if-small show--if-large">
                            <span
                                className={this.getHeaderClasses(
                                    store.NUTRITIONAL_VIEW
                                )}
                                onClick={::store.setView(
                                    store.NUTRITIONAL_VIEW
                                )}
                            >
                                {intl.get('dish.nutritionalNotes')}
                            </span>
                            <hr
                                className={`${base}__${
                                    store.view === store.NUTRITIONAL_VIEW
                                        ? 'dash'
                                        : 'dash-non-selected'
                                }`}
                            />
                        </span>
                    ) : null}
                    {store.reviews && store.reviews.length > 0 ? (
                        <span className="mrl">
                            <span
                                className={this.getHeaderClasses(
                                    store.REVIEWS_VIEW
                                )}
                                onClick={::store.setView(store.REVIEWS_VIEW)}
                            >
                                {window.matchMedia(SMALL_SCREEN_MEDIA).matches
                                    ? intl.get('dish.customer') + ' '
                                    : ''}
                                {intl.get('dish.reviews')}
                            </span>
                            <hr
                                className={`${base}__${
                                    store.view === store.REVIEWS_VIEW
                                        ? 'dash'
                                        : 'dash-non-selected'
                                } ${showHalal ? '' : 'last'}`}
                            />
                        </span>
                    ) : null}

                    {showHalal && (
                        <span className="">
                            <span
                                className={this.getHeaderClasses(
                                    store.HALAL_VIEW
                                )}
                                onClick={::store.setView(store.HALAL_VIEW)}
                            >
                                {intl.get('dish.halal')}
                            </span>
                            <hr
                                className={`${base}__${
                                    store.view === store.HALAL_VIEW
                                        ? 'dash'
                                        : 'dash-non-selected'
                                } last`}
                            />
                        </span>
                    )}
                </div>
            </div>
        );
    }
}
