import Bacon from 'baconjs';
import { observable, action } from 'mobx';

import Notifiable from './services/notifiable';
import { currency } from '../services';
import { services } from '../services/index';
import { RESOURCE } from '../services/types';
import { SMALL_SCREEN_MEDIA } from '../services/constants';
import { getCardTypeText } from '../services/paymentProviders';

import qs from 'query-string';
import Cookies from 'js-cookie';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(services.getParam('stripeAPIKey'));
const { clevertap } = window;

const INTERVAL = 10 * 1000;

export default class extends Notifiable {
    PENDING = 'Pending';
    COMPLETE = 'Complete';
    CANCELLED = 'Cancelled';
    FAILED = 'Failed';

    CHECKOUT_VIEW = 'CheckoutView';
    EMPTY_VIEW = 'EmptyView';
    CONFIRM_VIEW = 'ConfirmView';
    LOADING_VIEW = 'LoadingView';
    PAYMENT_IN_PROGRESS_VIEW = 'PaymentInProgressView';

    @observable discountCode = '';
    @observable autoTopUpEnabled = false;
    @observable hasUserUpdatedProfile = false;
    @observable walletOrderId = '';
    @observable.ref status = RESOURCE.Initial;
    @observable.ref user = null;
    @observable.ref paymentMethod = null;
    @observable.ref walletPackage = null;
    @observable.ref orderSummary = null;
    @observable.ref finalOrderSummary = null;
    @observable openBoostPopup = false;
    @observable openBoostUrl = '';

    @observable isBusy = true;
    @observable message = '';
    @observable view = 'LoadingView';

    onUserLoaded$ = new Bacon.Bus();
    onPaymentMethodsLoaded$ = new Bacon.Bus();
    onOrderSummaryLoaded$ = new Bacon.Bus();
    onPlacingOrderFailed$ = new Bacon.Bus();

    constructor() {
        super();

        this.setUpNotification();

        Bacon.onValues(this.onUserLoaded$, this.onPaymentMethodsLoaded$, () => {
            this.getOrderSummary();
            this.onUserLoaded$.end();
            this.onPaymentMethodsLoaded$.end();
        });
    }

    loadData() {
        this.startPolling();
    }

    startPolling() {
        this.loadStatus();

        this.interval = window.setInterval(::this.loadStatus, INTERVAL);
    }

    @action loadStatus() {
        services.api
            .CheckWalletPurchaseStatus(this.walletOrderId)
            .then((res) => {
                if (res['transaction_status'] === this.COMPLETE) {
                    window.clearInterval(this.interval);
                    this.setStatus(res);
                    this.finalOrderSummary = res;
                    this.view = this.CONFIRM_VIEW;
                    this.sendTrackingEvents();
                    this.emptyShoppingCart();
                }
            })
            .catch((responseData) => {
                if (responseData.status === 404) {
                    this.setStatus({
                        transaction_status: this.FAILED,
                        message: `Order number ${this.walletOrderId} is invalid.`,
                    });
                } else {
                    this.message = responseData.message;
                }

                window.clearInterval(this.interval);
            })
            .then(() => {
                const isSuccessAlready = RESOURCE.Success.is(this.status);
                const isNotPending = isSuccessAlready && this.status.data['transaction_status'] !== 'Pending';
                if (isNotPending) {
                    window.clearInterval(this.interval);
                }
            });
    }

    getOrderSummary() {
        const requestBody = {
            customer_id: this.user.id,
            card_id: this.paymentMethod ? this.paymentMethod.id : null,
            payment_method: this.paymentMethod ? 'Card' : null,
            discount_code: this.discountCode,
            orders: [
                {
                    menu_item: [
                        {
                            id: this.walletPackage.id,
                            quantity: 1,
                            price: this.walletPackage.price,
                            item_type: 'Package',
                        },
                    ],
                },
            ],
        };

        this.isBusy = true;
        services.api
            .GetOrderSummary(requestBody)
            .then((responseData) => {
                this.walletOrderId = responseData.wallet_order_id;
                this.orderSummary = responseData;
                this.onOrderSummaryLoaded$.push(responseData);
            })
            .catch((responseData) => {
                this.message = responseData.message;
            })
            .then(() => {
                this.isBusy = false;
            });
    }

    getRequestPayload({ paymentType }) {
        const params = qs.parse(window.location.search);
        const fromBoostApp = params.platform === 'boost' || Cookies.get('boost_user');

        return {
            customer_id: this.user.id,
            card_id: this.paymentMethod.id,
            payment_method: paymentType,
            discount_code: this.discountCode,
            fpx_redirect_url: services.getParam('fpxRedirectUrlWallet'),
            fpx_gateway: this.paymentMethod ? this.paymentMethod['fpx_gateway'] : null,
            from_boost_app: fromBoostApp,
            disable_deeplink: true,
            orders: [
                {
                    menu_item: [
                        {
                            id: this.walletPackage.id,
                            quantity: 1,
                            item_type: 'Package',
                            auto_top_up_enabled: this.autoTopUpEnabled,
                        },
                    ],
                },
            ],
        };
    }

    @action placeOrderWithCard(deviceData) {
        const requestBody = this.getRequestPayload({
            paymentType: this.paymentMethod.payment_method_type,
        });

        this.setView(this.PAYMENT_IN_PROGRESS_VIEW);
        services.api
            .PlaceOrder(requestBody, deviceData)
            .then(async (responseData) => {
                this.finalOrderSummary = responseData;
                if (responseData.client_secret) {
                    const stripe = await stripePromise;
                    stripe
                        .handleNextAction({
                            clientSecret: responseData.client_secret,
                        })
                        .then((result) => {
                            console.log(result, 'results');
                            if (result.error) {
                                services.api.checkWalletID(responseData.wallet_order_id).then(async (responseData) => {
                                    this.setView(this.CHECKOUT_VIEW);
                                    this.message = result.error.message;
                                });
                            } else {
                                services.api.checkWalletID(responseData.wallet_order_id).then(async (responseData) => {
                                    this.view = this.CONFIRM_VIEW;
                                    this.emptyShoppingCart;
                                });
                            }
                            // Handle result.error or result.paymentIntent
                        });
                } else {
                    this.view = this.CONFIRM_VIEW;
                    this.emptyShoppingCart;
                }
            })

            .then(::this.sendTrackingEvents)
            .catch((responseData) => {
                this.setView(this.CHECKOUT_VIEW);
                this.message = responseData.message;
                this.onPlacingOrderFailed$.push();
            })
            .then(() => {
                this.isBusy = false;
            });
    }

    @action placeOrderWithOnlineBanking_billplz() {
        const requestBody = this.getRequestPayload({ paymentType: 'BillPlz' });

        this.setView(this.PAYMENT_IN_PROGRESS_VIEW);
        services.api
            .PlaceOrder(requestBody)
            .then((responseData) => {
                if (responseData.bill_url) {
                    window.location.href = responseData.bill_url;
                }
                // below is deprecated, order processing with billplz is external, result processing in walletBillPlzStore.js
                else if (responseData.success) {
                    this.finalOrderSummary = responseData;
                    this.view = this.CONFIRM_VIEW;
                    this.sendTrackingEvents();
                    this.emptyShoppingCart();
                } else {
                    this.setView(this.CHECKOUT_VIEW);
                    this.message = responseData.message;
                }
            })
            .then((this.isBusy = false))
            .catch((responseData) => {
                this.setView(this.CHECKOUT_VIEW);
                this.message = responseData.message;
                this.onPlacingOrderFailed$.push();
            });
    }

    @action placeOrderWithOnlineBanking_boost = () => {
        const params = qs.parse(window.location.search);
        const fromBoostApp = params.platform === 'boost' || Cookies.get('boost_user');
        const requestBody = this.getRequestPayload({ paymentType: 'Boost' });

        this.setView(this.PAYMENT_IN_PROGRESS_VIEW);
        this.isBusy = true;

        services.api
            .PlaceOrder(requestBody)
            .then((responseData) => {
                this.walletOrderId = responseData.wallet_order_id;
                if (responseData.bill_deeplink && window.matchMedia(SMALL_SCREEN_MEDIA).matches) {
                    if (fromBoostApp) {
                        window.location = responseData.bill_deeplink;
                    } else {
                        window.open(responseData.bill_deeplink, '_blank');
                    }
                } else if (responseData.bill_url) {
                    window.open(responseData.bill_url, '_blank');
                } else if (responseData.success) {
                    // below is deprecated, order processing with billplz is external, result processing in walletBillPlzStore.js
                    this.finalOrderSummary = responseData;
                    this.view = this.CONFIRM_VIEW;
                    this.sendTrackingEvents();
                    this.emptyShoppingCart();
                } else {
                    this.setView(this.CHECKOUT_VIEW);
                    this.message = responseData.message;
                }
            })
            .then((this.isBusy = false))
            .catch((responseData) => {
                this.setView(this.CHECKOUT_VIEW);
                this.message = responseData.message;
                this.onPlacingOrderFailed$.push();
            });
    };

    @action placeOrderWithOnlineBanking_omise() {
        const requestBody = this.getRequestPayload({
            paymentType: 'Omise_Bank',
        });

        this.setView(this.PAYMENT_IN_PROGRESS_VIEW);
        services.api
            .PlaceOrder(requestBody)
            .then((responseData) => {
                if (responseData.bill_url) {
                    window.location.href = responseData.bill_url;
                }
                // below is deprecated, orderprocessing happens on omise and order processing is stored in menuBillPlzStore
                else if (responseData.success) {
                    this.finalOrderSummary = responseData;
                    this.view = this.CONFIRM_VIEW;
                    this.sendTrackingEvents();
                    this.emptyShoppingCart();
                } else {
                    this.setView(this.CHECKOUT_VIEW);
                    this.message = responseData.message;
                }
            })
            .then((this.isBusy = false))
            .catch((responseData) => {
                this.setView(this.CHECKOUT_VIEW);
                this.message = responseData.message;
                this.onPlacingOrderFailed$.push();
            });
    }

    emptyShoppingCart() {
        services.api.SelectingWalletPackage.remove();
    }

    sendTrackingEvents() {
        const purchaseEventName = services.getParam('purchaseEventName');
        const packageName = this.walletPackage.title;
        const priceAsNumber = this.walletPackage.price;

        window.dataLayer.push({
            event: 'Purchase',
            value: currency.to2digits(priceAsNumber),
            currency: services.getParam('currencyCode'),
            content_name: packageName,
            content_type: 'Meal_Package',
        });

        window.dataLayer.push({
            event: 'Wallet_Package_Purchase',
            package: packageName,
            value: currency.to2digits(priceAsNumber),
        });

        if (this.autoTopUpEnabled) {
            window.dataLayer.push({
                event: 'SmartReload',
                package: packageName,
            });
        }

        // temporary fix because backend is sending back the wrong payment_method.
        // checking if the payment method set in the frontend differs. if yes use the frontend value
        const payment_method =
            this.orderSummary['payment_method'] !== this.paymentMethod.payment_method_type
                ? this.paymentMethod.payment_method_type
                : this.orderSummary['payment_method'];
    }

    @action setUser(user) {
        this.user = user;
        this.onUserLoaded$.push();
    }

    @action setPaymentMethod(paymentMethod) {
        this.paymentMethod = paymentMethod;
        this.onPaymentMethodsLoaded$.push(paymentMethod);
        if (paymentMethod) {
            if (paymentMethod.payment_method_type === 'Card') {
                const cardType = getCardTypeText(paymentMethod);
                this.sendPaymentAddedTracking(cardType);
            } else if (paymentMethod.card_type === 'BillPlz') {
                this.sendPaymentAddedTracking('BillPlz');
            } else {
                this.sendPaymentAddedTracking(paymentMethod.payment_method_type);
            }
        }
    }

    @action getAutoTopupEnabled() {
        services.api
            .GetWalletStatus()
            .then((response) => {
                if (response.auto_top_up_enabled) {
                    this.autoTopUpEnabled = true;
                }
            })
            .catch(() => {});
    }

    sendPaymentAddedTracking(method) {
        clevertap.event.push('Payment method added', {
            'payment method': method,
        });

        window.dataLayer.push({ event: 'AddPaymentInfo' });
    }

    disableOnlineBankingAndBoost = () => {
        return false;
    };

    willShowDiscount() {
        if (this.orderSummary) {
            if (this.orderSummary.used_reward_codes && this.orderSummary.used_reward_codes.length > 0) {
                return false;
            }

            return true;
        }

        return false;
    }

    setWalletPackage(walletPackage) {
        this.walletPackage = walletPackage;
    }
    setDiscountCode(discountCode) {
        this.discountCode = discountCode;
    }
    setAutoTopup(value) {
        this.autoTopUpEnabled = value;
    }
    setView(view) {
        this.view = view;
    }
    setStatus(status) {
        this.status = status;
    }
}
