import { observable, action, computed, toJS } from 'mobx';
import get from 'lodash/get';

import { services } from '../services/index';
import userStore from '../store/userStore';

import { MAYBE, RESOURCE } from '../services/types';
import { RATING_TYPE_DELIVERY, RATING_TYPE_MEAL } from '../services/constants';

const { clevertap } = window;

export default class {
    POPUP_STAR_RATING = 'PopupStarRating';
    POPUP_NPS_RATING = 'PopupNPSRating';
    POPUP_FEEDBACK_COMMENT = 'PopupFeedbackComment';
    POPUP_THANK_YOU = 'PopupThankYou';

    @observable npsRating = 0;
    @observable feedbackComment = '';
    @observable ratings = [];
    @observable showingPopup = 'PopupStarRating';
    @observable hasRated = false;
    @observable.ref order = MAYBE.None;
    @observable.ref ratingInfoFetcher = RESOURCE.Loading;

    @action setOrder(order) {
        if (order) {
            this.order = MAYBE.Some(order);

            this.fetchRatingInfo(order.id);
        } else {
            this.npsRating = 0;
            this.showingPopup = 'PopupStarRating';
            this.ratings = [];
            this.order = MAYBE.None;
        }
    }

    @action fetchRatingInfo(id) {
        services.api
            .GetRatingInfo({ id })
            .then((response) => {
                (response.today_menu_rating_forms || []).forEach((item) => {
                    this.ratings.push({
                        type: RATING_TYPE_MEAL,
                        score: null,
                        id: item.today_menu_id,
                        orderNumber: item.order_id,
                        name: get(item, ['meal_name'], ''),
                        imageUrl: get(item, ['image_url'], ''),
                        tagsMap: get(item, ['tags_for_stars'], {}),
                        tagsSelected: [],
                        comment: '',
                    });
                });

                this.ratings.push({
                    type: RATING_TYPE_DELIVERY,
                    score: null,
                    id: '',
                    orderNumber: get(response, [
                        'delivery_rating_form',
                        'order_id',
                    ]),
                    name: 'Delivery & Rider',
                    imageUrl: '',
                    tagsMap: get(
                        response,
                        ['delivery_rating_form', 'tags_for_stars'],
                        {}
                    ),
                    tagsSelected: [],
                    comment: '',
                });

                this.ratingInfoFetcher = RESOURCE.Success(response);
            })
            .catch((error) => {
                this.ratingInfoFetcher = RESOURCE.Failure(error.message, error);
            });
    }

    @computed get hasPutStarRating() {
        return this.ratings.some((rating) => rating.score !== null);
    }

    @computed get hasRatedAll() {
        return this.ratings.every((rating) => rating.score !== null);
    }

    @action setShowingPopup(showingPopup) {
        return () => {
            this.showingPopup = showingPopup;
        };
    }

    @action setNPSRating(rating) {
        this.npsRating = rating;

        if (rating < 7) {
            this.showingPopup = this.POPUP_FEEDBACK_COMMENT;
        } else {
            this.showingPopup = this.POPUP_THANK_YOU;
        }

        clevertap.event.push('Submitted NPS score', {
            Rating: rating,
        });
    }

    @action submitRating(rating) {
        const { id, name, orderNumber, score, type } = rating;
        const ratings = [
            {
                name,
                type,
                order_id: orderNumber,
                score,
                today_menu_id: id,
            },
        ];

        services.api.SubmitRatingInfo({ ratings }).then(() => {
            this.hasRated = true;

            if (type === RATING_TYPE_MEAL) {
                clevertap.event.push('Reviewed Item', {
                    'Product name': name,
                    'Menu ID': id,
                    Rating: score === 1 ? 'Positive' : 'Negative',
                });
            } else if (type === RATING_TYPE_DELIVERY) {
                clevertap.event.push('Reviewed Delivery', {
                    'Product name': name,
                    Rating: score === 1 ? 'Positive' : 'Negative',
                });
            }
        });
    }

    @action submitComment() {
        const ratings = this.getRatingRequestBody();

        services.api.SubmitRatingInfo({ ratings }).then(() => {
            this.hasRated = true;
            ratings.forEach((rating) => {
                const { score, comment, today_menu_id, name, tags } = rating;

                clevertap.event.push('Submitted Feedback Tags Or Comment', {
                    Rating: score === 1 ? 'Positive' : 'Negative',
                    Comment: comment,
                    'Product name': name,
                    'Menu ID': today_menu_id,
                    Tags: tags.toString(),
                });
            });
        });
    }

    @action submitFeedbackComment() {
        const firstName = get(this.order, ['data', 'firstname']);
        const lastName = get(this.order, ['data', 'lastname']);
        const orderId = get(this.order, ['data', 'order_id']);
        const commentWithOrderNumber =
            this.feedbackComment + `\nOrder #${orderId}`;
        const selectTag = userStore.isSelect && 'SELECT';
        const newCustomerTag =
            !userStore.user.data.has_ordered_before && 'New Customer';
        const city = services.getParam('city').toLowerCase();

        let tagArray = [];
        if (selectTag) {
            tagArray.push(selectTag);
        }
        if (newCustomerTag) {
            tagArray.push(newCustomerTag);
        }
        if (city) {
            tagArray.push(city);
        }

        const freshDeskRequest = {
            freshdeskRequestObject: {
                email: userStore.user.data && userStore.user.data.email,
                name: `${firstName} ${lastName}`,
                subject: 'NPS Feedback Comment',
                unique_external_id:
                    userStore.user.data && userStore.user.data.id,
                tags: tagArray,
                description: commentWithOrderNumber,
                status: 2,
                priority: 1,
            },
        };

        services.api.CreateFreshDeskRequest(freshDeskRequest).catch(() => {});

        this.showingPopup = this.POPUP_THANK_YOU;
    }

    getRatingRequestBody() {
        return this.ratings
            .map((rating) => {
                return {
                    comment: rating.comment,
                    order_id: rating.orderNumber,
                    score: rating.score,
                    tags: toJS(rating.tagsSelected),
                    today_menu_id: rating.id,
                    name: rating.name,
                    type: rating.type,
                };
            })
            .filter((rating) => rating.score !== null && rating.comment);
    }
}
