import React, { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import BasePage from './basePage';
import LandingRoot from '../components/LandingRoot';
import { GoogleTagManager } from '../components/core/ScriptTag';
import { onboardingTags } from '../services/metatags';
import Cookies from 'js-cookie';
import qs from 'query-string';
import isBot from 'isbot';
import config from '../configs/parameters';
import intl from 'react-intl-universal';
import { GetCity } from '../services/city';

import UtmStore from '../store/utmStore';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';
import template from 'lodash/template';
import { when } from 'mobx';
import { storeAutoAssignDiscountCampaign } from '../services/autoAssignDiscount';

const utmStore = new UtmStore();

@inject('userStore', 'loginPopupStore')
@observer
export default class extends Component {
    constructor(props) {
        super(props);

        this.state = {
            wait: true,
            redirect: false,
        };
    }

    componentDidMount() {
        const { userStore, location } = this.props;
        const params = qs.parse(location.search);

        utmStore.params = params;
        utmStore.handleSendParams(params);

        if (
            config.willRedirectOnboarding &&
            !isBot(window.navigator.userAgent)
        ) {
            const onboardingVersion = '1.0';

            if (
                Cookies.get('has_seen_onboarding_version') === onboardingVersion
            ) {
                this.setState({ redirect: true });
            }
            Cookies.set('has_seen_onboarding_version', onboardingVersion, {
                expires: 100,
            });
        }

        window.dataLayer.push({
            PageType: 'HomePage',
        });

        // checks for auto applied discount campaigns
        when(
            () => userStore.isNotLoggedIn,
            () => {
                if (params.benefit) {
                    storeAutoAssignDiscountCampaign(params);
                }
            }
        );
    }

    render() {
        const { match, location } = this.props;
        const { redirect } = this.state;
        const id = match.params && match.params.id;
        const slug = id ? `/${id}` : '';

        const brandName = config.brandName;
        const onBoardingVersion = {
            url: '/',
            pageTitle: template(intl.get(`${GetCity()}.onboarding.pageTitle`))({
                brandName,
            }),
            titleLine1: intl.get('onboarding.sectionHero.titleLine1'),
            titleLine2: intl.get('onboarding.sectionHero.titleLine2'),
            subtitle: [
                <div key="0">
                    {intl.get('onboarding.sectionHero.subtitleLine1')}
                </div>,
            ],
            mobileTitleLine1: intl.get(
                'onboarding.sectionHero.mobileTitleLine1'
            ),
            mobileSubtitle: intl.get(
                'onboarding.sectionHero.mobileSubtitleLine1'
            ),
            mixPanelPage: 'Onboarding Page',
            mixPanelProperty: {},
            class: 'onboarding',
            GCE: null,
        };

        if (redirect) {
            return <Redirect to={{ ...location, pathname: '/menu' }} />; //TODO: check if the code is used
        }

        return (
            <div>
                <Helmet meta={onboardingTags}>
                    <title>{onBoardingVersion.pageTitle}</title>
                    {localizationTags('food-delivery' + slug).map((tag) => tag)}
                    {getCanonicalTag('food-delivery' + slug)}
                    <script type="text/javascript">
                        {GoogleTagManager(onBoardingVersion)}
                    </script>
                </Helmet>
                <BasePage />
                <Provider utmStore={utmStore}>
                    <LandingRoot
                        variety={onBoardingVersion}
                        cuisine={id}
                        showReferralOnboarding={false}
                    />
                </Provider>
            </div>
        );
    }
}
