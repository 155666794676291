import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import Hammer from 'hammerjs';

const base = 'shopping-cart-meal';

@inject('cartStore')
@inject('userStore')
@observer
class MealTotal extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            showMoneyBack: false,
        };

        this.hammer = new Hammer(document.body);
    }

    componentDidMount() {
        this.hammer.on('tap', (e) => this.handleClickOutside(e));
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.hammer.off('tap', (e) => this.handleClickOutside(e));
    }

    handleClickOutside = (e) => {
        if (!this.myRef && !this.myRef.current.contains(e.target)) {
            this.setState({ showMoneyBack: false });
        }
    };

    handleShowMoneyBack = () => {
        this.setState({ showMoneyBack: !this.state.showMoneyBack });
    };

    renderMoneyBackInfo = () => {
        const { userStore } = this.props;
        const { showMoneyBack } = this.state;
        const existingUser =
            userStore.user.data && userStore.user.data.has_ordered_before;

        if (showMoneyBack && window.matchMedia(SMALL_SCREEN_MEDIA).matches) {
            setTimeout(() => {
                document.body.scrollTop = 10000;
                document.documentElement.scrollTop = 10000;
            }, 10);
        }

        if (!existingUser) {
            return (
                <div ref={this.myRef} key={this.props.key}>
                    <div
                        className="display--flex"
                        onClick={this.handleShowMoneyBack}
                    >
                        <div
                            className={`${base}__money-back-button-container cursor-pointer`}
                        >
                            <p>{intl.get('mealRowCartPreview.moneyBack')}</p>
                            <svg className={`iconmoon-icon`}>
                                <use xlinkHref="#icon-information" />
                            </svg>
                        </div>
                    </div>
                    <div className={`${base}__money-back-bubble-container`}>
                        {showMoneyBack && (
                            <div className="position--relative show">
                                <div
                                    className={`${base}__money-back-bubble__triangle`}
                                />
                                <div
                                    className={`${base}__money-back-bubble`}
                                    id="money-back-bubble"
                                >
                                    <p
                                        className={`${base}__money-back-bubble__text`}
                                    >
                                        {intl.get(
                                            'mealRowCartPreview.moneyBackBubbleLine1'
                                        )}
                                        <br />
                                        <br />
                                        {intl.get(
                                            'mealRowCartPreview.moneyBackBubbleLine2'
                                        )}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        } else {
            return <div />;
        }
    };

    render() {
        const { preliminaryOrderSummary } = this.props.cartStore;
        const { showMoneyBack } = this.props;

        return (
            preliminaryOrderSummary &&
            preliminaryOrderSummary.price_summary.rows.map((line, i) => {
                const noOfItems =
                    preliminaryOrderSummary.price_summary.rows.length;

                let color = '';
                if (line.color === 'GREEN') {
                    color = 'text--primary';
                } else if (line.color === 'BLUE') {
                    color = 'text--blue';
                }
                const bold = line.bold ? 'text--bold' : '';
                return (
                    <Fragment key={i}>
                        <div key={i}>
                            {/* the last item in the array */}
                            {i === noOfItems - 1 ? (
                                <hr className={`${base}__quantity-separator`} />
                            ) : null}
                            <div className={`${base}__subtotal-row clearfix`}>
                                <p
                                    className={`float-left ${bold} fs--small-medium ${color}`}
                                >
                                    {line.name}
                                </p>
                                <div
                                    className={`float-right ${bold} fs--small-medium ${color}`}
                                >
                                    {line.value}
                                </div>
                            </div>
                        </div>
                        {showMoneyBack &&
                            i === noOfItems - 1 &&
                            this.renderMoneyBackInfo()}
                    </Fragment>
                );
            })
        );
    }
}

export default MealTotal;
