import React, { Fragment } from 'react';
import { Provider } from 'mobx-react';

import { Link } from '../services/types';
import BaseLayout from './core/baseLayout/Master_BaseLayout';
import MenuHeader from './core/headers/MenuHeader';
import MenuFooter from './core/MenuFooter';
import Entry from './page/orderHistory/Entry';

import menuHeaderStore from '../store/menuHeaderStore';
import loginPopupStore from '../store/loginPopupStore';
import orderHistoryStore from '../store/orderHistoryStore';
import orderStatusStore from '../store/orderStatusStore';
import OrderRatingStore from '../store/orderRatingStore';

import setUpStoreInteractions from '../store/interactions/historyInteractions';
import PromotionBar from './core/PromotionBar';

const orderRatingStore = new OrderRatingStore();

setUpStoreInteractions(orderStatusStore, orderHistoryStore);
loginPopupStore.setBackLink(new Link('/menu', 'BACK TO MENU'));

const Header = () => (
    <Provider menuHeaderStore={menuHeaderStore}>
        <Fragment>
            <MenuHeader />
            <PromotionBar />
        </Fragment>
    </Provider>
);

const stores = {
    orderHistoryStore,
    orderRatingStore,
};

export default class extends React.Component {
    render() {
        return (
            <BaseLayout.Page HeaderComponent={Header}>
                <main>
                    <Provider {...stores}>
                        <Entry />
                    </Provider>
                </main>
                <Provider menuHeaderStore={menuHeaderStore}>
                    <MenuFooter />
                </Provider>
            </BaseLayout.Page>
        );
    }
}
