import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { CircleLoader } from '../../core/Loading';
import { RESOURCE } from '../../../services/types';
import FullForm from './FullForm';
import SmallForm from './SmallForm';
import UserServices from '../../../store/services/userServices';
import { EMAIL, PHONE } from '../../../services/constants';

const base = 'complete-signup';

@inject('completeSignUpStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.completeSignUpStore.fetchUser();
    }

    handleSubmitCompleteSignUp(e) {
        const { completeSignUpStore } = this.props;
        const hasPhoneVerified = UserServices.from(
            completeSignUpStore.userFetcher.data
        ).hasPhoneVerified();
        const field = hasPhoneVerified ? EMAIL : PHONE;
        const validationResult = completeSignUpStore.validate(field);

        e.preventDefault();

        if (validationResult.pass()) {
            this.props.completeSignUpStore.handleVerifyAccount(field);
        }
    }

    renderRegistrationForm = () => {
        const { completeSignUpStore } = this.props;
        const { userFetcher } = completeSignUpStore;
        const userType = completeSignUpStore.getUserType();
        const hasPhoneVerified = UserServices.from(
            userFetcher.data
        ).hasPhoneVerified();

        return (
            <div className="mtm text--gray">
                <form
                    className="row"
                    autoComplete="off"
                    onSubmit={::this.handleSubmitCompleteSignUp}
                >
                    {userType === 'Normal' ? (
                        <FullForm showEmail={hasPhoneVerified} />
                    ) : null}
                    {userType === 'Facebook' || userType === 'Google' ? (
                        <SmallForm />
                    ) : null}
                </form>
            </div>
        );
    };

    render() {
        const { completeSignUpStore } = this.props;
        const { user, userFetcher } = completeSignUpStore;
        const willShowForm = completeSignUpStore.willShowForm();

        if (user === RESOURCE.Loading) {
            return (
                <div className={`${base} well`}>
                    <p className="text--bolder flex-1 lh--150">
                        {intl.get('completeSignUp.widget.title')}
                    </p>
                    <CircleLoader />
                </div>
            );
        }

        if (willShowForm === false) {
            return <div />;
        }

        const userType = completeSignUpStore.getUserType();
        const updateSuccess =
            userType === 'Normal' ? (
                <p className="text--primary ptm">
                    {intl.get('user.completeSignUp.normal')}
                </p>
            ) : (
                <p className="text--primary ptm">
                    {intl.get('user.completeSignUp.small')}
                </p>
            );

        return (
            userFetcher.data &&
            userFetcher.data.id && (
                <div className={`${base} well`}>
                    <p className="text--bolder flex-1 lh--150">
                        {userType === 'Normal'
                            ? intl.get('completeSignUp.widget.yourInfo')
                            : intl.get('completeSignUp.widget.phoneNumber')}
                    </p>
                    {completeSignUpStore.hasUserUpdatedProfile
                        ? updateSuccess
                        : this.renderRegistrationForm()}
                </div>
            )
        );
    }
}
