import { observable, action } from 'mobx';
import Bacon from 'baconjs';
import Notifiable from '../services/notifiable';
import { services } from '../../services/index';
import { getCardTypeText } from '../../services/paymentProviders';

const { clevertap } = window;

export default class extends Notifiable {
    @observable message = '';
    @observable isAddingCreditCard = false;
    @observable dataCollectorInstance = null;

    newCard = null;
    onCreditCardAdded$ = new Bacon.Bus();

    constructor() {
        super();

        this.setUpNotification();
        this.state = {
            clientInstance: null,
        };
    }

    @action handleStoreStripeToken = (stripeResponse) => {
        this.isAddingCreditCard = true;
        services.api
            .StoreStripeToken(stripeResponse)
            .then((response) => this.setNewCard(response))
            .then(() => this.sendTrackingEvents())
            .then(() => {
                this.onCreditCardAdded$.push(this.newCard);
                this.isAddingCreditCard = false;
            })
            .catch((e) => {
                this.message = e.message;
                this.isAddingCreditCard = false;
            });
    };

    @action confirmSetupIntent = () => {
        this.isAddingCreditCard = true;
        return services.api
            .ConfirmSetupIntent()
            .then((response) => {
                this.isAddingCreditCard = false;
                return response.client_secret;
            })
            .catch((e) => {
                this.message = e.message;
                this.isAddingCreditCard = false;
            });
    };

    sendTrackingEvents() {
        const method =
            this.newCard['payment_method_type'] === 'Card'
                ? getCardTypeText(this.newCard)
                : this.newCard['payment_method_type'];

        clevertap.event.push('Payment method added', {
            'payment method': method,
        });

        window.dataLayer.push({ event: 'AddPaymentInfo' });
    }

    reset() {
        this.message = '';
        this.newCard = null;
    }

    setNewCard = (newCard) => {
        this.newCard = newCard;
    };
}
