import { observable, action, computed } from 'mobx';

import { RESOURCE } from '../services/types';
import { ValidationResult } from '../services/validator';
import {
    fullFormValidatorMobile,
    fullFormValidatorEmail,
    smallFormValidator,
} from './validators/completeSignUpValidator';
import UserServices from './services/userServices';
import PhoneVerificationStore from './phoneVerificationStore';
import { COUNTRY_CODES, EMAIL } from '../services/constants';

import { services } from '../services/index';
import intl from 'react-intl-universal';

const { clevertap } = window;

export default class extends PhoneVerificationStore {
    @observable firstName = '';
    @observable lastName = '';
    @observable countryCode = COUNTRY_CODES[services.getParam('country')];
    @observable phoneNumber = '';
    @observable verificationCode = '';
    @observable hasUserUpdatedProfile = false;
    @observable email = '';
    @observable field = '';

    @observable.ref validationResult = new ValidationResult();
    @observable.ref userFetcher = RESOURCE.Initial;
    @observable.ref userUpdater = RESOURCE.Initial;

    @computed get mobileNumber() {
        return this.countryCode + this.phoneNumber;
    }

    countDownInterval = null;

    @action fetchUser() {
        this.userFetcher = RESOURCE.Loading;

        services.api.GetUserWithMemoizer.runFresh()
            .then((user) => {
                this.userFetcher = RESOURCE.Success(user);

                if (user.mobileno && user.email) {
                    this.hasUserUpdatedProfile = true;
                }
            })
            .catch((response) => {
                this.userFetcher = RESOURCE.Failure(response.message, response);
            });
    }

    @action updateUser() {
        const requestBody = this.getRequestBody();

        this.userUpdater = RESOURCE.Loading;
        services.api
            .UpdateUser(requestBody)
            .then(() => {
                this.hasUserUpdatedProfile = true;
            })
            .then(this.sendTrackingEvents())
            .catch((response) => {
                this.handleUserFieldUpdate(response);
                this.userUpdater = RESOURCE.Initial;
            });
    }

    @action handleUserFieldUpdate(response) {
        const duplicateMessage = this.email
            ? intl.get('user.duplicateEmail')
            : intl.get('user.duplicatePhone');
        const invalidMessage = this.email
            ? intl.get('user.emailInvalid')
            : intl.get('user.phoneInvalid');

        switch (response.status) {
            case 409:
                services.bannerEvents.showError(duplicateMessage);
                break;
            case 400:
                services.bannerEvents.showError(invalidMessage);
                break;
            default:
                services.bannerEvents.showError(response.message);
        }
    }

    @action verifyPhone = () => {
        services.api
            .VerifyWithMobileNumber(
                this.mobileNumber,
                this.verificationCode,
                false
            )
            .then(() => this.updateUser())
            .catch((e) => {
                services.bannerEvents.showError('Invalid verification code');
            });
    };

    @action handleVerifyAccount = (field) => {
        this.field = field;
        if (field === EMAIL) {
            this.updateUser();
        } else {
            this.verifyPhone();
        }
    };

    @action validate(field) {
        const userType = this.getUserType();
        const fullFormValidator =
            field === EMAIL ? fullFormValidatorEmail : fullFormValidatorMobile;

        const validator =
            userType === 'Normal' ? fullFormValidator : smallFormValidator;

        this.validationResult = validator.validate(this);
        return this.validationResult;
    }

    getUserType() {
        if (RESOURCE.Success.is(this.userFetcher)) {
            return !this.userFetcher.data.alias
                ? 'Normal'
                : UserServices.from(this.userFetcher.data).getUserType();
        }

        return 'Unknown';
    }

    willShowForm() {
        if (RESOURCE.Success.is(this.userFetcher)) {
            return (
                !UserServices.from(this.userFetcher.data).hasPhoneVerified() ||
                !UserServices.from(this.userFetcher.data).hasEmailVerified()
            );
        }

        return false;
    }

    sendTrackingEvents = () => {
        const userType = this.getUserType();

        if (userType === 'Normal') {
            if (this.field === EMAIL) {
                clevertap.event.push('Completed Signup Name & Email Address');
            } else {
                clevertap.event.push('Completed Signup Name & Phone Number');
            }
        } else if (userType === 'Facebook' || userType === 'Google') {
            clevertap.event.push('Completed Signup Phone');
        }
    };

    getRequestBody() {
        const email = this.email ? this.email : null;

        const mobileno = this.phoneNumber ? this.mobileNumber : null;

        return {
            firstname: this.firstName || null,
            lastname: this.lastName || null,
            mobileno,
            email,
        };
    }
}
