import React from 'react';
import { inject } from 'mobx-react';
import intl from 'react-intl-universal';
import Resolver from '../../core/Resolver';
import { services } from '../../../services/index';

const User = ({ data }) => <div>{data.username}</div>;

@inject('store')
export default class extends React.Component {
    render() {
        const base = 'user-info';
        const { store } = this.props;

        return (
            <div className={`${base} well`}>
                <p className="text--bolder flex-1 lh--150">
                    {intl.get('packageCheckout.userAccount')}
                </p>
                <div className="mtm">
                    <Resolver
                        when={services.api.GetUser()}
                        onComplete={::store.setUser}
                    >
                        <User />
                    </Resolver>
                </div>
            </div>
        );
    }
}
