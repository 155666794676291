import React, { Component } from 'react';
import { reaction, when } from 'mobx';
import { Helmet } from 'react-helmet';
import config from '../configs/parameters';
import { observer, inject } from 'mobx-react';

import {
    fbSdk,
    WalletGoogleAnalytics,
    BaseGTMScript,
} from '../components/core/ScriptTag';
import { RESOURCE } from '../services/types';
import { services } from '../services/index';
import { GetLanguage } from '../services/translation';
import { SMALL_SCREEN_MEDIA } from '../services/constants';

const noop = () => {};
/**
 * This class may be used to add additional scripts, metaTags, bodyClasses, linkTags and stylesheets for a brand
 */

@inject('userStore', 'cartStore')
@observer
class BrandbasePage extends Component {
    constructor() {
        super();
        this.hideChatWidget = window.location.pathname.includes('terminal');
    }

    componentDidMount() {
        const isSmallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches;
        if (!window.location.href.includes('cart') || !isSmallScreen) {
            const waitForFreshChat = setInterval(() => {
                if (typeof window.fcWidget !== 'undefined') {
                    clearInterval(waitForFreshChat);

                    if (!this.hideChatWidget) {
                        // this.setFreshChat();
                    }
                }
            }, 100);

            setTimeout(() => {
                // this.handleDisplayFreshChat();
            }, 1000);
        }
    }

    setFreshChat() {
        const { userStore } = this.props;
        const script = document.createElement('script');
        const city = services.getParam('city').toLowerCase();

        when(
            () => userStore.user.data,
            () => {
                if (RESOURCE.Success.is(userStore.user)) {
                    const userId = userStore.user.data.id;
                    const restoreId = userStore.user.data.freshchat_id;
                    // const selectTag = userStore.user.data.select_customer && 'SELECT';
                    // const newCustomerTag = !userStore.user.data.has_ordered_before && 'New Customer';

                    script.innerHTML = `window.fcWidget.init({
                        token: "f24750d1-971e-4972-b736-a2d24f87b8ea",
                        host: "https://wchat.freshchat.com",
                        externalId: "${userId}",
                        restoreId: ${restoreId ? `"${restoreId}"` : null},
                        tags: ["${city}"],
                        faqTags : {
                            tags : ["${city}"],
                            filterType: 'category'
                        }
                    })`;

                    document.body.insertBefore(
                        script,
                        document.body.firstChild
                    );

                    window.fcWidget.user.get(function (resp) {
                        const status = resp && resp.status;
                        if (status !== 200) {
                            window.fcWidget.user.setProperties({
                                firstName: userStore.user.data.firstname,
                                lastName: userStore.user.data.lastname,
                                email: userStore.user.data.email,
                                phone: userStore.user.data.mobileno,
                                'New Customer':
                                    !userStore.user.data.has_ordered_before,
                                SELECT: userStore.isSelect,
                            });

                            services.api
                                .GetUpcomingOrders()
                                .then((upcoming) => {
                                    if (upcoming.deliveries.length > 0) {
                                        window.fcWidget.user.setProperties({
                                            'Last Order':
                                                '#' +
                                                upcoming.deliveries[
                                                    upcoming.deliveries.length -
                                                        1
                                                ].order_id, // meta property 1
                                        });
                                    } else {
                                        services.api
                                            .GetPastOrders()
                                            .then((history) => {
                                                if (
                                                    history.deliveries.length >
                                                    0
                                                ) {
                                                    window.fcWidget.user.setProperties(
                                                        {
                                                            'Last Order':
                                                                '#' +
                                                                history
                                                                    .deliveries[0]
                                                                    .order_id,
                                                        }
                                                    );
                                                }
                                            });
                                    }
                                })
                                .catch(noop);

                            window.fcWidget.on('user:created', function (resp) {
                                const status = resp && resp.status,
                                    data = resp && resp.data;
                                if (status === 200) {
                                    if (data.restoreId) {
                                        userStore.storeFreshChatId(
                                            data.restoreId
                                        );
                                        // Update restoreId in your database
                                    }
                                }
                            });
                        }
                    });
                } else {
                    script.innerHTML = `window.fcWidget.init({
                        token: "f24750d1-971e-4972-b736-a2d24f87b8ea",
                        host: "https://wchat.freshchat.com",
                        tags: ["${city}"],
                        faqTags : {
                            tags : ["${city}"],
                            filterType: 'category'
                        }
                    })`;
                    document.body.insertBefore(
                        script,
                        document.body.firstChild
                    );
                }
            }
        );
    }

    handleDisplayFreshChat = () => {
        const { cartStore } = this.props;
        const isSmallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        if (isSmallScreen) {
            reaction(
                () => cartStore.itemsAdded && cartStore.itemsAdded.length > 0,
                () => {
                    const waitForFreshChat = setInterval(() => {
                        if (document.getElementById('fc_frame')) {
                            clearInterval(waitForFreshChat);
                            if (
                                !document
                                    .getElementById('fc_frame')
                                    .classList.contains('hide')
                            ) {
                                document.getElementById('fc_frame').className +=
                                    ' hide';
                            }
                        }
                    }, 100);
                }
            );

            reaction(
                () => cartStore.itemsAdded && cartStore.itemsAdded.length === 0,
                () => {
                    const waitForFreshChat = setInterval(() => {
                        if (document.getElementById('fc_frame')) {
                            clearInterval(waitForFreshChat);
                            document
                                .getElementById('fc_frame')
                                .classList.remove('hide');
                        }
                    }, 100);
                }
            );
        }
    };

    render() {
        return (
            <Helmet>
                {/* <title>{config.brand}</title> */}
                <html lang={GetLanguage()} />
                <meta name="theme-color" content="#fec700" />
                <meta
                    name="apple-mobile-web-app-status-bar-style"
                    content="#ffffff"
                />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta
                    name="apple-mobile-web-app-title"
                    content={config.brandName}
                />
                <meta
                    name="msapplication-TileImage"
                    content="resources/ms-icon-144x144.png"
                />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="apple-itunes-app" content="app-id=1030383844" />
                <meta http-equiv="x-ua-compatible" content="ie=edge" />
                <meta
                    name="google-signin-client_id"
                    content={config.googleClientId}
                />
                {/* TODO: this is highly problematic, because scripts are not guaranteed to be loaded before components mount */}
                {/* see: https://github.com/nfl/react-helmet/pull/299 */}
                <script
                    type="text/javascript"
                    src="https://js.braintreegateway.com/web/3.42.0/js/client.min.js"
                />
                <script
                    type="text/javascript"
                    src="https://js.braintreegateway.com/web/3.42.0/js/hosted-fields.js"
                />
                <script
                    type="text/javascript"
                    src="https://js.braintreegateway.com/web/3.42.0/js/paypal.min.js"
                />
                <script
                    type="text/javascript"
                    src="https://js.braintreegateway.com/web/3.42.0/js/data-collector.min.js"
                />
                {!this.hideChatWidget && (
                    <script src="https://wchat.freshchat.com/js/widget.js" />
                )}
                <script type="text/javascript">{fbSdk}</script>
                <script type="text/javascript">{BaseGTMScript}</script>
                <script type="text/javascript">{WalletGoogleAnalytics}</script>
                {/* LEAD FORENSICS TRACKING */}
                <script
                    type="text/javascript"
                    src="https://secure.keet1liod.com/js/157249.js"
                ></script>
                <noscript>{`<img alt="" src="https://secure.keet1liod.com/157249.png" style="display:none;" />`}</noscript>
            </Helmet>
        );
    }
}

export default BrandbasePage;
