import { observable, toJS } from 'mobx';
import Bacon from 'baconjs';
import { services } from '../services/index'

import Notifiable from './services/notifiable';
import Cookies from "js-cookie";

export default class extends Notifiable {
    LIST_VIEW = 'ListView';
    SHOW_VIEW = 'ShowView';
    ADD_PAYMENT = 'AddPaymentMethod';
    ADD_PAYPAL = 'AddPaypal';
    ADD_CREDIT_CARD = 'AddCreditCard';

    @observable view = 'ShowView';
    @observable paymentList = [];
    @observable isBusy = true;
    @observable message = '';

    onCardsLoaded$ = new Bacon.Bus();

    constructor() {
        super();

        this.setUpNotification();
    }

    goToView(view) {
        return () => {
            this.view = view;
        };
    }

    loadPaymentMethods() {
        this.isBusy = true;
        const boostUser = Cookies.get('boost_user');

        services.api.GetPaymentMethods()
            .then(cards => {
                const stubBoost = {
                    id: 'boost',
                    card_type: 'Boost',
                    payment_method_type: 'Boost',
                    description: 'You will be redirected to the Boost app to complete payment'
                };

                if (boostUser) {
                    cards.unshift(stubBoost)
                }
                this.paymentList = cards.filter(card => card['payment_method_type'] !== 'Wallet' && card['payment_method_type'] !== 'Cash');
            })
            .then(() => {
                this.onCardsLoaded$.push(this.paymentList);
                this.onCardsLoaded$.end();
            })
            .catch(responseData => { this.message = responseData.message; })
            .then(() => { this.isBusy = false; });
    }

    addPaymentMethod(paymentMethod) {
        this.paymentList = this.paymentList
            .filter(card => card.id !== paymentMethod.id)
            .concat(paymentMethod);
    }

    getFirstPaymentMethod() {
        const wallet = this.paymentList.find(card => card['payment_method_type'] === 'Wallet')
        if (wallet) return wallet;
        return toJS(this.paymentList)[0];
    }
}
