import React, { Component } from 'react';
import Arrows from './Arrows';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import config from '../../../configs/parameters';
import intl from 'react-intl-universal';
import { template } from 'lodash';

const base = 'careers__testimonials';

export default class Testimonials extends Component {
    state = {
        activeIndex: 0,
    };

    content = [
        {
            imgUrl: 'https://d3tjqo2jh935k9.cloudfront.net/images/static/dahmakan-employee.jpg',
            imgUrlMobile:
                'https://d3tjqo2jh935k9.cloudfront.net/images/static/dahmakan-employee-mobile.png',
            testimonial: template(
                intl.get('careers.testimonials.item1.testimonial')
            )({ brandName: config.brandName }),
            name: intl.get('careers.testimonials.item1.name'),
            title: intl.get('careers.testimonials.item1.title'),
        },
        {
            imgUrl: 'https://d3tjqo2jh935k9.cloudfront.net/images/static/dahmakan-employee6.jpg',
            imgUrlMobile:
                'https://d3tjqo2jh935k9.cloudfront.net/images/static/dahmakan-employee5-mobile.png',
            testimonial: template(
                intl.get('careers.testimonials.item2.testimonial')
            )({ brandName: config.brandName }),
            name: intl.get('careers.testimonials.item2.name'),
            title: intl.get('careers.testimonials.item2.title'),
        },
        {
            imgUrl: 'https://d3tjqo2jh935k9.cloudfront.net/images/static/dahmakan-employee2.jpg',
            imgUrlMobile:
                'https://d3tjqo2jh935k9.cloudfront.net/images/static/dahmakan-employee2-mobile.png',
            testimonial: template(
                intl.get('careers.testimonials.item3.testimonial')
            )({ brandName: config.brandName }),
            name: intl.get('careers.testimonials.item3.name'),
            title: intl.get('careers.testimonials.item3.title'),
        },
        {
            imgUrl: 'https://d3tjqo2jh935k9.cloudfront.net/images/static/dahmakan-employee4.jpg',
            imgUrlMobile:
                'https://d3tjqo2jh935k9.cloudfront.net/images/static/dahmakan-employee4-mobile.png',
            testimonial: template(
                intl.get('careers.testimonials.item4.testimonial')
            )({ brandName: config.brandName }),
            name: intl.get('careers.testimonials.item4.name'),
            title: intl.get('careers.testimonials.item4.title'),
        },
    ];

    onClickLeft = () => {
        if (this.state.activeIndex !== 0) {
            this.setState({ activeIndex: this.state.activeIndex - 1 });
        }
    };

    onClickRight = () => {
        if (this.state.activeIndex !== this.content.length - 1) {
            this.setState({ activeIndex: this.state.activeIndex + 1 });
        }
    };

    render() {
        const { activeIndex } = this.state;
        const activeContent = this.content[activeIndex];
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return (
            <div className={base}>
                <div className={`${base}__green-bg hide--if-small`} />
                <div className={`${base}__green-bg-2 hide--if-small`} />
                <div className={`${base}__main-container`}>
                    <div
                        className={`${base}__testimonial-header hide--if-large`}
                    >
                        <p className="text--bolder">
                            {intl.get('careers.testimonials.thoughtsFromTeam')}
                        </p>
                    </div>
                    <div className={`${base}__img-container`}>
                        <div
                            className={`${base}__img`}
                            style={{
                                background: `url(${
                                    isMobile
                                        ? activeContent.imgUrlMobile
                                        : activeContent.imgUrl
                                })`,
                                height: isMobile ? '63px' : '552px',
                                width: isMobile ? '63px' : '382px',
                                backgroundSize: 'cover',
                                borderRadius: '10px',
                            }}
                        />
                        <div
                            className={`${base}__employee-info hide--if-large`}
                        >
                            <p className={`${base}__employee-info--name`}>
                                {activeContent.name}
                            </p>
                            <p className={`${base}__employee-info--title`}>
                                {activeContent.title}
                            </p>
                        </div>
                    </div>
                    <div className={`${base}__content-container`}>
                        <div
                            className={`${base}__testimonial-header hide--if-small`}
                        >
                            <p className="text--bolder">
                                {intl.get(
                                    'careers.testimonials.thoughtsFromTeam'
                                )}
                            </p>
                        </div>
                        <div className={`${base}__testimonial`}>
                            <svg className={`${base}__icon iconmoon-icon`}>
                                <use xlinkHref="#icon-quote" />
                            </svg>
                            <p>{activeContent.testimonial}</p>
                        </div>
                        <div
                            className={`${base}__employee-info hide--if-small`}
                        >
                            <p className={`${base}__employee-info--name`}>
                                {activeContent.name}
                            </p>
                            <p className={`${base}__employee-info--title`}>
                                {activeContent.title}
                            </p>
                        </div>
                        <Arrows
                            centerAlign={isMobile}
                            onClickLeft={this.onClickLeft}
                            onClickRight={this.onClickRight}
                            disableLeftArrow={activeIndex === 0}
                            disableRightArrow={
                                activeIndex === this.content.length - 1
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}
