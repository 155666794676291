import React from 'react';
import DatePicker from '../../core/DatePicker';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import Modal from '../../core/Modal';
import PopupThankYou from './PopupThankYou';
import {
    SPECIAL_EVENT,
    RECURRED_EVENT,
    SUBMIT_STATUS,
} from '../../../store/businessFormStore';
import { cities, GetCity } from '../../../services/city';
import config from '../../../configs/parameters';
import { template } from 'lodash';

const city = GetCity();
const isKL = city === cities.kl;

const base = 'business-form';

@inject('businessFormStore')
@observer
export default class extends React.Component {
    handleFormSubmit(e) {
        e.preventDefault();

        this.props.businessFormStore.submit();
    }

    handleClosePopup() {
        const store = this.props.businessFormStore;

        store.hidePopup();
    }

    renderSubmitButton() {
        const store = this.props.businessFormStore;
        const { submitStatus } = store;

        if (submitStatus === SUBMIT_STATUS.Initial) {
            return (
                <button className="button button--success button--bigger-on-mobile width--100">
                    {intl.get(`business.${city}.form.cta`)}
                </button>
            );
        } else if (submitStatus === SUBMIT_STATUS.Submitting) {
            return (
                <button className="button button--success button--busy button--bigger-on-mobile width--100">
                    {intl.get(`business.${city}.form.submitting`)}
                </button>
            );
        }

        return null;
    }

    render() {
        const store = this.props.businessFormStore;
        const updateModel = ::store.updateModel;
        const brandName = config.brandName;

        return (
            <div className={`${base} border--rounded`}>
                <form id="business-form" onSubmit={::this.handleFormSubmit}>
                    <h3 className={`${base}__title`}>
                        {template(intl.get(`business.${city}.form.heading`))({
                            brandName,
                        })}
                    </h3>
                    <div className="separator separator--green m0a mtm mbm" />
                    <p
                        className={`${base}__subheading fs--small-medium mbm lh--125`}
                    >
                        {intl.get(`business.${city}.form.subheading`)}
                    </p>

                    <div className="row mbm">
                        <div
                            className={`${base}__name-container col-lg-6 col-md-6 col-sm-6 col-reduced-gutter--right`}
                        >
                            <input
                                type="text"
                                className="input width--100"
                                placeholder={intl.get(
                                    `business.${city}.form.placeholders.name`
                                )}
                                name="name"
                                value={store.name}
                                onChange={updateModel}
                            />
                        </div>
                        <div
                            className={`${base}__phone-container col-lg-6 col-md-6 col-sm-6 col-reduced-gutter--left`}
                        >
                            <input
                                type="tel"
                                className="input width--100"
                                placeholder={intl.get(
                                    `business.${city}.form.placeholders.phoneNumber`
                                )}
                                name="phoneNumber"
                                value={store.phoneNumber}
                                onChange={updateModel}
                            />
                        </div>
                    </div>
                    <div className="row mbm">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <input
                                type="email"
                                className="input width--100"
                                placeholder={intl.get(
                                    `business.${city}.form.placeholders.emailAddress`
                                )}
                                name="emailAddress"
                                value={store.emailAddress}
                                onChange={updateModel}
                            />
                        </div>
                    </div>
                    <div className="row mbm">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <input
                                type="text"
                                className="input width--100"
                                placeholder={intl.get(
                                    `business.${city}.form.placeholders.companyName`
                                )}
                                name="companyName"
                                value={store.companyName}
                                onChange={updateModel}
                            />
                        </div>
                    </div>
                    <div className="row mbm">
                        <div
                            className={`${base}__name-container col-lg-6 col-md-6 col-sm-6 col-reduced-gutter--right`}
                        >
                            <DatePicker
                                placeholder={intl.get(
                                    `business.${city}.form.placeholders.eventDate`
                                )}
                                containerClassName={`${base}__date-picker-container`}
                                name="eventDate"
                                onChange={updateModel}
                                value={store.eventDate}
                                inputClassName="input width--100"
                            />
                        </div>
                        <div
                            className={`${base}__phone-container col-lg-6 col-md-6 col-sm-6 col-reduced-gutter--left`}
                        >
                            <input
                                type="number"
                                className="input input--show-spinner width--100"
                                placeholder={intl.get(
                                    `business.${city}.form.placeholders.numberOfPeople`
                                )}
                                name="numberOfPeople"
                                value={store.numberOfPeople}
                                onChange={updateModel}
                            />
                        </div>
                    </div>
                    <div className="row mbm">
                        <div className="col-lg-12 col-md-12 col-sm-12 text--left">
                            <input
                                id="type-special-occasion"
                                type="radio"
                                className="mrs"
                                name="eventType"
                                value={SPECIAL_EVENT}
                                checked={store.eventType === SPECIAL_EVENT}
                                onChange={updateModel}
                            />
                            <label
                                htmlFor="type-special-occasion"
                                className="fs--small-medium"
                            >
                                {intl.get(
                                    `business.${city}.form.special_occassion`
                                )}
                            </label>
                            <input
                                id="type-recurred-event"
                                type="radio"
                                className="mrs mll"
                                name="eventType"
                                value={RECURRED_EVENT}
                                checked={store.eventType === RECURRED_EVENT}
                                onChange={updateModel}
                            />
                            <label
                                htmlFor="type-recurred-event"
                                className="fs--small-medium"
                            >
                                {intl.get(
                                    `business.${city}.form.recurring_events`
                                )}
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <p className="text--red text--left fs--small-medium pbs">
                                {store.validationResult.getFirstError()}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            {this.renderSubmitButton()}
                        </div>
                    </div>
                </form>
                {!isKL && (
                    <div className="row mtm">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <a
                                href="https://d3tjqo2jh935k9.cloudfront.net/dahmakan-catering-menu.pdf"
                                download
                                target="_blank"
                            >
                                <button className="button button--success button--bigger-on-mobile width--100">
                                    {intl.get(
                                        `business.${city}.form.downloadCta`
                                    )}
                                </button>
                            </a>
                        </div>
                    </div>
                )}
                <Modal
                    showModal={store.submitStatus === SUBMIT_STATUS.Submitted}
                    showCloseIcon={true}
                    closeButtonClasses="fill--gray"
                    contentClasses="modal__content--golden-ratio"
                    onBackdropClicked={::this.handleClosePopup}
                    onCloseClicked={::this.handleClosePopup}
                >
                    <PopupThankYou />
                </Modal>
            </div>
        );
    }
}
