/* eslint-disable no-undef */
import { httpSubmit } from '../http';
import { services } from '../index';

export const RequestCode = ({ phone }) => {
    const appKey = services.getParam('ringCaptchaAppId');
    const apiKey = services.getParam('ringCaptchaAPIKey');
    const requestBody = {
        app_key: appKey,
        api_key: apiKey,
        phone: phone,
        service: 'SMS',
    };

    return httpSubmit(
        `https://api.ringcaptcha.com/${appKey}/code/sms`,
        requestBody
    );
};

export const VerifyCode = ({ phone, code, token }) => {
    const appKey = services.getParam('ringCaptchaAppId');
    const apiKey = services.getParam('ringCaptchaAPIKey');
    const requestBody = {
        app_key: appKey,
        api_key: apiKey,
        phone: phone,
        code: code,
        token: token,
    };

    return httpSubmit(
        `https://api.ringcaptcha.com/${appKey}/verify`,
        requestBody
    );
};
