import { action, computed, observable, toJS, when } from 'mobx';
import menuListStore from './menuListStore';
import deliveryAddressStore from './deliveryAddressStore';
import userStore from './userStore';
import dropdownStore from './dropdownStore';

import CartServices from './services/cartServices';
import Notifiable from './services/notifiable';
import { handleSentTrackLinkEvent } from '../components/core/ClevertapHelpers';

import { services } from '../services/index';
import { currency } from '../services';
import intl from 'react-intl-universal';

import { UserCart } from '../services/api/userCartServices';
import { UserAddressSettings } from '../services/api/userAddressServices';
import { getCompanyFromLocalStorage } from '../services/company';
import { pick, pull, template } from 'lodash';
import qs from 'query-string';
import Cookies from 'js-cookie';

const { clevertap } = window;

class CartStore extends Notifiable {
    @observable message = '';
    @observable hasValidated = false;
    @observable preliminaryOrderSummary = null;
    @observable orderSummaryLoading = false;
    @observable.ref user = null;
    @observable dishSoldOut = false;
    @observable orderSummary = [];
    @observable isTimeframeLoading = true;
    @observable noAddress = false;
    @observable showAddressSelectorPopup = false;
    @observable latestItemAdded = {};
    @observable isLoading = true;
    @observable isEditing = false;
    @observable cutleryChargePerUnit = null;
    @observable cart = UserCart.getCart() || [];

    /**
     * Returns a list of deliveries ready to be dispatched in a request for checkout or check-cart
     * @param cart
     * @returns {*}
     */
    getDeliveriesForRequest(cart) {
        return cart.map((delivery) => {
            const deliveryRequest = { ...delivery };
            deliveryRequest.use_company = getCompanyFromLocalStorage();
            deliveryRequest.menu_item = delivery.menu_item.map((item) => {
                const itemRequest = pick(item, ['id', 'quantity', 'price']);
                itemRequest.item_type = item.addon_category ? 'Addons' : 'Item';
                return itemRequest;
            });
            return deliveryRequest;
        });
    }

    @computed get cartTotal() {
        const total = this.itemsInCart.reduce(
            (prices, item) => prices + item.price * item.quantity,
            0
        );
        if (userStore.isLoggedIn) {
            return total;
        } else {
            const params = qs.parse(window.location.href);
            const autoAssignedCodeAmount =
                params.benefit || Cookies.get('benefit');
            const discountAmount = autoAssignedCodeAmount;
            return total - discountAmount;
        }
    }

    @computed get loggedInUserSubTotal() {
        if (this.preliminaryOrderSummary) {
            return (
                this.preliminaryOrderSummary.discounted_sub_total ||
                this.preliminaryOrderSummary.sub_total
            );
        }
    }

    @computed get showSelectUpsell() {
        return (
            this.preliminaryOrderSummary &&
            this.preliminaryOrderSummary.show_select_upsell
        );
    }

    @computed get rewardSummary() {
        return (
            this.preliminaryOrderSummary &&
            this.preliminaryOrderSummary.reward_summary
        );
    }

    @computed get itemsInCart() {
        let itemsInCart = [];
        this.cart.forEach((dateOrder) => {
            itemsInCart = itemsInCart.concat.apply([], dateOrder.menu_item);
        });

        return itemsInCart;
    }

    @computed get numOfItems() {
        return this.cart.reduce((acc, cur) => {
            return (
                acc + cur.menu_item.reduce((acc, cur) => acc + cur.quantity, 0)
            );
        }, 0);
    }

    constructor() {
        super();
        this.setUpNotification();
    }

    getUserWithMemoizer() {
        const token = services.api.AccessTokenStorage.getToken();

        return new Promise((resolve, reject) => {
            if (token) {
                return services.api.GetUserWithMemoizer.run()
                    .then(resolve)
                    .catch(reject);
            }
            return reject();
        });
    }

    updatePreliminaryOrderSummary() {
        when(
            () => userStore.user && userStore.user.data,
            () => {
                this.user = userStore.user && userStore.user.data;
                this.getPreliminaryOrderSummary();
            }
        );
    }

    hasItems() {
        return this.itemsInCart.length > 0;
    }

    isInCart(item) {
        return this.itemsInCart.find(
            (itemInCart) => itemInCart.menu_id === item.menu_id
        );
    }

    @action addToCart = (item, date) => {
        let itemToAdd = item;
        if (!deliveryAddressStore.selectedAddress) {
            this.noAddress = true;
            this.showAddressSelectorPopup = true;
        }

        when(
            () => this.noAddress === false,
            () => {
                this.showAddressSelectorPopup = false;
                const address = UserAddressSettings.getCurrentAddress();
                const isItemAvailableAt = !!(
                    itemToAdd.quantity_left === 0 && itemToAdd.available_at
                );

                // if itemToAdd is available_at use available_at_time_slot_id, else use timeslot selected from DateAndTimeSelector
                const time_slot_id = isItemAvailableAt
                    ? itemToAdd.available_at_time_slot_id
                    : menuListStore.activeTimeslotObj.id;
                const time_frame = isItemAvailableAt
                    ? itemToAdd.available_at
                    : menuListStore.activeTimeslotObj.display_text;

                // update activeTimeslot to available_at_time_slot_id if available_at itemToAdd is added to cart
                // and refresh menu with new menu items for this timeslot
                if (isItemAvailableAt) {
                    const activeTimeslotObj =
                        menuListStore.activeDateObj.time_slots.find(
                            (timeslot) => timeslot.id === time_slot_id
                        );
                    menuListStore.storeActiveTimeslotObj(activeTimeslotObj);
                    menuListStore.refreshMenu();
                }

                when(
                    () => menuListStore.isLoading === false,
                    () => {
                        if (isItemAvailableAt) {
                            itemToAdd = menuListStore.menu[0].items.find(
                                (item) => item.menu_id === itemToAdd.menu_id
                            );
                        }
                        const menuDate = date || menuListStore.menu[0].date;
                        // construct date specific delivery object to store in cart
                        const delivery = {
                            date: menuDate,
                            time_slot_id,
                            time_frame,
                            address_id: address.id,
                            latitude: address.latitude,
                            longitude: address.longitude,
                            provide_cutlery: false,
                            menu_item: [
                                {
                                    ...itemToAdd,
                                    quantity: 1,
                                },
                            ],
                        };

                        const sameDateDelivery = this.cart.find(
                            (delivery) => delivery.date === menuDate
                        );

                        // if cart has a delivery for a date, and an itemToAdd is added to cart for the same date
                        if (sameDateDelivery) {
                            // update delivery timeslot and selected address for that date's delivery
                            sameDateDelivery.time_slot_id = time_slot_id;
                            sameDateDelivery.time_frame = time_frame;
                            sameDateDelivery.address_id = address.id;
                            sameDateDelivery.latitude = address.latitude;
                            sameDateDelivery.longitude = address.longitude;

                            const sameItem = sameDateDelivery.menu_item.find(
                                (menuItem) =>
                                    menuItem.menu_id === itemToAdd.menu_id
                            );
                            console.log(sameItem);
                            // if same itemToAdd is added to the cart for the same delivery date
                            if (sameItem) {
                                if (
                                    itemToAdd.quantity_left <= sameItem.quantity
                                ) {
                                    // and if quantity exceeded, display mealNotAvailable message
                                    this.message =
                                        intl.get(
                                            'cart.messages.mealNotAvailable'
                                        ) +
                                        (itemToAdd.name ||
                                            itemToAdd.title_bold);
                                } else {
                                    // else, +1 quantity
                                    sameItem.quantity += 1;
                                    this.handleItemAdded(itemToAdd, menuDate);
                                }
                                // else add new itemToAdd to menu_items array for the same delivery date
                            } else {
                                sameDateDelivery.menu_item.push({
                                    ...itemToAdd,
                                    quantity: 1,
                                });
                                this.handleItemAdded(itemToAdd, menuDate);
                            }
                            // else, push new delivery for new date to cart
                        } else {
                            console.log('delivery', delivery);
                            this.cart.push(delivery);
                            this.handleItemAdded(itemToAdd, menuDate);
                        }
                    }
                );
            }
        );
    };

    handleItemAdded = (item, date) => {
        this.sendTrackingEvents(item, date);
        dropdownStore.handleShowDropdown(dropdownStore.DROPDOWN_CART);
        this.updateCart();
    };

    updateCart = (checkCart = true) => {
        UserCart.saveCart(toJS(this.cart));
        this.updatePreliminaryOrderSummary();

        if (checkCart) {
            this.checkCart();
        }
    };

    updateDateTimeslotInCart = (date, timeslot, checkCart = true) => {
        const dateOrder = this.cart.find((delivery) => delivery.date === date);
        if (dateOrder) {
            dateOrder.time_slot_id = timeslot.id;
            dateOrder.time_frame = timeslot.display_text;

            this.updateCart(checkCart);
        }
    };

    updateDateAddressInCart = (date, address) => {
        const dateOrder = this.cart.find((delivery) => delivery.date === date);
        if (dateOrder) {
            dateOrder.address_id = address.id;
            dateOrder.latitude = address.latitude;
            dateOrder.longitude = address.longitude;

            this.updateCart();
        }
    };

    updateCutleryProvidedInCart = (date, isCutleryProvided) => {
        const dateOrder = this.cart.find((delivery) => delivery.date === date);
        if (dateOrder) {
            dateOrder.provide_cutlery = isCutleryProvided;

            this.updateCart(false);
        }
    };

    checkCart = (fromCheckout = false) => {
        when(
            () => userStore.user.data && this.cart.length > 0,
            () => {
                const deliveries = this.getDeliveriesForRequest(this.cart);
                services.api
                    .CheckCart({ deliveries })
                    .then((res) => {
                        this.checkItemAvailable(res.deliveries, fromCheckout);
                    })
                    .catch((e) => {
                        services.bannerEvents.showWarning(e.message);
                    });
            }
        );
    };

    checkItemAvailable = (deliveries, fromCheckout) => {
        this.cutleryChargePerUnit = deliveries.length
            ? deliveries[0].cutlery_charge_per_unit
            : null;
        deliveries.forEach((delivery) => {
            const { time_slots } = delivery.time_slot_information;
            const deliveryInCart = this.cart.find(
                (dateOrder) => dateOrder.date === delivery.date
            );

            if (delivery.any_item_available) {
                const deliveryDateObj = menuListStore.validDates.find(
                    (validDate) => validDate.date === delivery.date
                );
                deliveryDateObj.time_slots = time_slots;
                const updatedValidTimeslotList =
                    menuListStore.getValidTimeslotsForOneDate(deliveryDateObj);

                if (delivery.selected_time_frame_open) {
                    delivery.menu_item.forEach((item) => {
                        if (!item.selected_time_frame_open) {
                            const currentItem = this.itemsInCart.find(
                                (itemInCart) => itemInCart.menu_id === item.id
                            );

                            if (currentItem) {
                                const unavailableItems =
                                    delivery.menu_item.filter(
                                        (item) => !item.selected_time_frame_open
                                    );
                                if (
                                    unavailableItems &&
                                    unavailableItems.length > 1
                                ) {
                                    services.bannerEvents.showWarning(
                                        intl.get(
                                            'cart.messages.someItemsSoldOut'
                                        )
                                    );
                                } else {
                                    services.bannerEvents.showWarning(
                                        template(
                                            intl.get(
                                                'cart.messages.mealNotAvailableAnymore'
                                            )
                                        )({
                                            dish:
                                                currentItem.name ||
                                                currentItem.title_bold,
                                        })
                                    );
                                }
                                this.removeItemFromCart(
                                    currentItem,
                                    delivery.date
                                );
                            }
                        }
                    });
                    if (delivery.time_slot_id !== deliveryInCart.time_slot_id) {
                        const newTimeslotObj = updatedValidTimeslotList.find(
                            (timeslot) => timeslot.id === delivery.time_slot_id
                        );
                        this.updateDateTimeslotInCart(
                            delivery.date,
                            newTimeslotObj,
                            false
                        );
                    }
                } else {
                    const newTimeslotObjIndex =
                        updatedValidTimeslotList.findIndex(
                            (timeslot) => timeslot.id === delivery.time_slot_id
                        );
                    const nextTimeslotObj = updatedValidTimeslotList.find(
                        (_timeslot, timeslotIndex) =>
                            timeslotIndex > newTimeslotObjIndex
                    );

                    if (!nextTimeslotObj) {
                        // not future open timeSlot -> abort here
                        this.removeDeliveryFromCart(deliveryInCart);
                    } else {
                        // next timeSlot exists, so we can try again
                        this.updateDateTimeslotInCart(
                            delivery.date,
                            nextTimeslotObj
                        );
                    }
                }
            } else {
                this.removeDeliveryFromCart(deliveryInCart);
            }
        });

        if (fromCheckout) menuListStore.isLoading = false;
    };

    @action removeItemFromCart(item, date) {
        const delivery = this.cart.find((delivery) => delivery.date === date);

        if (delivery) {
            const menuItem = delivery.menu_item.find(
                (menuItem) => menuItem.id === item.id
            );

            if (menuItem.quantity === 1) {
                if (delivery.menu_item.length === 1) {
                    pull(this.cart, delivery);
                } else {
                    pull(delivery.menu_item, menuItem);
                }
            } else {
                menuItem.quantity -= 1;
            }

            this.updateCart(false);
        }
    }

    @action removeDeliveryFromCart(deliveryInCart) {
        pull(this.cart, deliveryInCart);
        this.updateCart(false);
    }

    @action getPreliminaryOrderSummary(initialLoad) {
        if (!initialLoad) {
            this.orderSummaryLoading = true;
        }

        const requestBody = {
            orders: this.getDeliveriesForRequest(this.cart),
        };

        requestBody['card_id'] = 'cod-temp';
        requestBody['payment_method'] = 'Cash';

        services.api
            .GetOrderSummary(requestBody)
            .then((responseData) => {
                this.preliminaryOrderSummary = responseData;
                this.orderSummaryLoading = false;
            })
            .catch(() => {});
    }

    @action handleCartClicked = () => {
        handleSentTrackLinkEvent('', 'Clicked on Cart');
    };

    sendTrackingEvents(item, date) {
        clevertap.event.push('Item added to Cart', {
            id: item.menu_id,
            'Product name': item.name,
            category: CartServices.getItemType(item) || '',
            weekday: date,
        });

        window.dataLayer.push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'MYR',
                add: {
                    products: [
                        {
                            name: item.name,
                            id: item.menu_id,
                            price: item.price,
                            variant: CartServices.getItemType(item),
                            quantity: 1,
                        },
                    ],
                },
            },
        });

        window.dataLayer.push({
            event: 'AddToCartFbPixel',
            value: item.price,
            currency: services.getParam('currencyCode'),
            content_name: item.name,
            content_type: 'Product',
            content_ids: [item.id],
            contents: [{ id: item.id, quantity: 1, item_price: item.price }],
        });
    }

    sendCartDataToDataLayer() {
        const userCart = this.itemsInCart;
        if (userCart.length > 0) {
            var product_list = [];

            userCart.forEach((item) => {
                product_list.push({
                    id: item.menu_id,
                    price: item.price,
                    quantity: item.quantity,
                });
            });

            window.dataLayer.push({
                PageType: 'BasketPage',
                ProductBasketProducts: product_list,
            });
        }
    }

    sendReceiptDataToDataLayer() {
        const userCart = this.itemsInCart;
        if (userCart.length > 0) {
            var product_list = [];

            userCart.forEach((item) => {
                product_list.push({
                    id: item.menu_id,
                    price: item.price,
                    quantity: item.quantity,
                });
            });

            window.dataLayer.push({
                ProductTransactionProducts: product_list,
            });
        }
    }
}

const store = new CartStore();
export default store;
