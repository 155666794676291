import XDate from 'xdate';
import memoize from 'lodash/memoize';
import assert from 'assert';

const today = new XDate();

export const isXdateEqual = (dateA, dateB) => {
    if (!dateA || !dateB) {
        return false;
    }

    return dateA.toString('dd-MM-yyyy') === dateB.toString('dd-MM-yyyy');
};

export const parseDate = memoize((dateString) => {
    if (!dateString) {
        return null;
    }

    const parts = dateString.split('-');
    const year = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1;
    const date = parseInt(parts[0], 10);
    const result = new XDate(year, month, date);

    return result.valid() ? result : null;
});

export const fromTimestamp = (timestamp) => {
    return new XDate(timestamp);
};

// We do not extend XDate on purpose, since this might have effects on other parts of the app
export const parseToXDate = (dateStr) => {
    const parts = dateStr.split('-');
    if (parts.length !== 3) {
        throw new assert.AssertionError(
            'Date is not parseable. Needs to be in format dd-MM-yyyy'
        );
    }

    return new XDate(
        parseInt(parts[2], 10),
        parseInt(parts[1] - 1, 10),
        parseInt(parts[0], 10)
    );
};

export const isToday = (xdate) => {
    return isXdateEqual(xdate, today);
};

export const isTomorrow = (xdate) => {
    return isXdateEqual(xdate, new XDate().addDays(1));
};

export const getDateDisplay = (xdate) => {
    if (isToday(xdate)) {
        return 'Today, ' + xdate.toString('dd/MM/yy');
    }

    return xdate ? xdate.toString('dddd, dd/MM/yy') : '';
};

export const constructXDate = (year, month, day) => {
    return new XDate(year, month, day);
};

export const getDateDisplayFull = (xdate) => {
    if (isToday(xdate)) {
        return `Today, ${xdate.toString('dd MMMM')}`;
    } else {
        return `${xdate.toString('dddd, dd MMMM')}`;
    }
};

export const getTerminalCTADate = (xdate) => {
    if (isToday(xdate)) {
        return "today's";
    } else if (isTomorrow(xdate)) {
        return "tomorrow's";
    }

    return '';
};

export const sortDate = (dateStringA, dateStringB) => {
    return parseDate(dateStringA) > parseDate(dateStringB);
};

export const secondsInDay = (xdate) => {
    const hour = xdate.getHours();
    const minute = xdate.getMinutes();
    const second = xdate.getSeconds();

    return hour * 3600 + minute * 60 + second;
};

export const timeToSeconds = (time) => {
    const parts = time.split(':').join(' ').split(' ');

    let [hour, minute, period] = parts; // eslint-disable-line prefer-const
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);

    if (period.toLowerCase() === 'pm' && hour !== 12) {
        hour += 12;
    }

    return hour * 3600 + minute * 60;
};

export const getStartTime = (timeslot) => {
    return timeslot.split(' -')[0];
};
