import React from 'react';
import { inject, observer } from 'mobx-react';
import AddonUpsellColumn from './AddonUpsellColumn';
import { CircleLoader } from '../Loading';

const base = 'addon-upsell';

@inject('menuListStore')
@observer
export default class extends React.Component {
    render() {
        const { date, menuListStore } = this.props;
        const menuForDay =
            menuListStore.menuForEachDelivery.find(
                (menu) => menu.date === date
            ) || menuListStore.menu[0];
        const addOnsForDay = menuForDay
            ? menuListStore.getAddOnsForDay(menuForDay)
            : null;

        if (menuForDay === undefined) {
            return <CircleLoader />;
        }

        if (!addOnsForDay) {
            return null;
        }

        return (
            <div className={base}>
                {Object.entries(addOnsForDay).map(([category, addOns]) => (
                    <AddonUpsellColumn
                        key={category}
                        category={category}
                        addOns={addOns}
                        date={date}
                    />
                ))}
            </div>
        );
    }
}
