import React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import Modal from '../../core/Modal';
import BusyBox from './BusyBox';
import PopupTypeAddress from '../shoppingCartPage/deliveryAddress/PopupTypeAddress';
import PopupAdjustAddress from '../shoppingCartPage/deliveryAddress/PopupAdjustAddress';
import PopupDeliveryNote from '../shoppingCartPage/deliveryAddress/PopupDeliveryNote';
import PopupOfficeExplained from '../shoppingCartPage/deliveryAddress/PopupOfficeExplained';
import PopupCondoExplained from '../shoppingCartPage/deliveryAddress/PopupCondoExplained';
import PopupHouseExplained from '../shoppingCartPage/deliveryAddress/PopupHouseExplained';
import Address, { AddressBusy } from './Address';
import { RESOURCE, MAYBE } from '../../../services/types';

const base = 'profile';

@inject('deliveryAddressStore', 'addressAutocompleteStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.deliveryAddressStore.fetchAddresses();

        const store = this.props.deliveryAddressStore;
        var ua = navigator.userAgent,
            iOS = /iPad|iPhone|iPod/.test(ua);
        if (iOS) {
            if (store.showingPopup !== '') {
                document.body.classList.add('modal-ios-fix');
            }
            reaction(
                () => store.showingPopup,
                () => {
                    store.showingPopup === ''
                        ? document.body.classList.remove('modal-ios-fix')
                        : document.body.classList.add('modal-ios-fix');
                }
            );
        }
    }

    handleDeleteClicked(address) {
        const result = confirm('Are you sure you want to delete this address?'); //eslint-disable-line

        if (result) {
            this.props.deliveryAddressStore.deleteAddress(address);
        }
    }

    handleNavigateBack = () => {
        const { addressAutocompleteStore } = this.props;
        const store = this.props.deliveryAddressStore;

        if (store.showingPopup === store.POPUP_TYPING) {
            store.place = null;
            addressAutocompleteStore.getPlacePredictions(null);
            store.showMap = false;
        } else if (store.showingPopup === store.POPUP_ADJUSTING) {
            store.place = null;
            addressAutocompleteStore.getPlacePredictions(null);
            store.showingPopup = store.POPUP_TYPING;
        } else if (store.showingPopup === store.POPUP_NOTE) {
            store.showingPopup = store.POPUP_ADJUSTING;
        } else if (
            store.showingPopup === store.POPUP_OFFICE_EXPLAINED ||
            store.showingPopup === store.POPUP_HOUSE_EXPLAINED
        ) {
            store.showingPopup = store.POPUP_NOTE;
        }
    };

    handleShowBackButton = () => {
        const store = this.props.deliveryAddressStore;

        if (store.showingPopup === store.POPUP_TYPING) {
            return store.showMap;
        } else {
            return true;
        }
    };

    renderPopup() {
        const store = this.props.deliveryAddressStore;
        const contentClasses = `shopping-cart-address__popup-content--${store.showingPopup} modal__content--golden-ratio`;
        const showBackButton = this.handleShowBackButton();

        return (
            <Modal
                showModal={store.showingPopup}
                contentClasses={contentClasses}
                closeButtonClasses="fill--white modal__extra-margin"
                closeButtonMargin="modal__extra-margin"
                onBackdropClicked={::store.hidePopup}
                onCloseClicked={::store.hidePopup}
                onBackClicked={this.handleNavigateBack}
                showCloseIcon={true}
                showBackIcon={showBackButton}
            >
                {store.showingPopup === store.POPUP_TYPING ? (
                    <PopupTypeAddress />
                ) : null}
                {store.showingPopup === store.POPUP_ADJUSTING ? (
                    <PopupAdjustAddress />
                ) : null}
                {store.showingPopup === store.POPUP_NOTE ? (
                    <PopupDeliveryNote />
                ) : null}
                {store.showingPopup === store.POPUP_OFFICE_EXPLAINED ? (
                    <PopupOfficeExplained />
                ) : null}
                {store.showingPopup === store.POPUP_CONDO_EXPLAINED ? (
                    <PopupCondoExplained />
                ) : null}
                {store.showingPopup === store.POPUP_HOUSE_EXPLAINED ? (
                    <PopupHouseExplained />
                ) : null}
            </Modal>
        );
    }

    render() {
        const store = this.props.deliveryAddressStore;
        const { addressesFetcher, addressBeingDeleted } = store;

        if (RESOURCE.Success.is(addressesFetcher) === false) {
            return <BusyBox headerText="Saved Addresses" />;
        }

        const addresses = addressesFetcher.data.filter(
            (address) => !address.pickup_location
        );
        return (
            <div className={`${base}__box`}>
                <div className={`${base}__box-header`}>
                    {intl.get('profile.address.header')}
                </div>
                <div className={`${base}__box-body p0 display--none-if-empty`}>
                    {addresses.map((address) => {
                        if (
                            MAYBE.Some.is(addressBeingDeleted) &&
                            addressBeingDeleted.data.id === address.id
                        ) {
                            return <AddressBusy key={address.id} />;
                        }

                        return (
                            <Address
                                key={address.id}
                                address={address}
                                onDeleteClicked={::this.handleDeleteClicked}
                            />
                        );
                    })}
                </div>
                <div className={`${base}__box-footer`}>
                    <center>
                        <button
                            className="button button--success profile__button"
                            onClick={() => store.showPopup(store.POPUP_TYPING)}
                        >
                            {intl.get('profile.address.cta')}
                        </button>
                    </center>
                </div>
                {this.renderPopup()}
            </div>
        );
    }
}
