import React, { Component } from 'react';
import { SMALL_SCREEN_MEDIA } from '../services/constants';
import Modal from './core/Modal';

export default class PopupUI extends Component {
    render() {
        const {
            titleLine1,
            titleLine2,
            onClickYes,
            onClickNo,
            yesCta,
            noCta,
            link,
        } = this.props;

        return (
            <Modal
                showModal={true}
                showCloseIcon={false}
                showBackdropOnMobile={true}
            >
                <div className="pl text--center">
                    <p className="mm text--bolder fs--medium-large">
                        {titleLine1}{' '}
                        {window.matchMedia(SMALL_SCREEN_MEDIA).matches ? (
                            ''
                        ) : (
                            <br />
                        )}{' '}
                        {titleLine2}
                    </p>
                    {/* If link provided, attach directly to <a href="">  */}
                    {link ? (
                        <button className="button button--large button--success width--100 mtm mbm">
                            <a
                                className="fs--medium text--bolder link--no-underline text--white show lh--200"
                                href={link}
                                target="_blank"
                                onClick={onClickYes}
                            >
                                {yesCta}
                            </a>
                        </button>
                    ) : (
                        <button
                            className="button button--large button--success width--100 mtm mbm"
                            onClick={onClickYes}
                        >
                            <p className="fs--medium text--bolder">{yesCta}</p>
                        </button>
                    )}
                    <div className="cursor-pointer" onClick={onClickNo}>
                        <p className="text--bolder">{noCta}</p>
                    </div>
                </div>
            </Modal>
        );
    }
}
