function timeout(ms, promise) {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            reject(new Error('timeout'));
        }, ms);
        promise.then(resolve, reject);
    });
}

export const GetUserLocation = () =>
    timeout(
        1000,
        fetch(`https://pro.ip-api.com/json/?key=HzlF7KNAgZT2A34`)
    ).then((res) => res.json());
