import { Component } from 'react';
import React from 'react';

const base = 'bubble';
export default class Bubble extends Component {
    render() {
        const { children } = this.props;

        return (
            <div className={`${base}`}>
                <div className={`${base}__margin-container`}>
                    <div className={`${base}__box`}>{children}</div>
                </div>
            </div>
        );
    }
}
