import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

const base = 'terminal-confirm-popup';
@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setTimeout(() => {
            const { onClosePopup } = this.props;
            onClosePopup();
        }, 10 * 1 * 1000); //10 seconds
    }

    isPaidPaymentMethod(paymentType) {
        return paymentType == 'Kiosk_Ewallet' || paymentType == 'Card';
    }

    render() {
        const {
            terminalMenuStore: { customerName, arrowDirection, askForName, isSkipClicked, hubCode, paymentType },
        } = this.props;
        const rightArrow = 'https://image.popcontent.link/Right+Arrow+.png';
        const leftArrow = 'https://image.popcontent.link/Left+Arrow+.png';

        return (
            <div className={base}>
                <div className={`${base}__content`}>
                    {arrowDirection == 'up' ? (
                        <div className={`${base}__flex-content`}>
                            {!this.isPaidPaymentMethod(paymentType) ? (
                                <div>
                                    <p className="customer-name">
                                        {isSkipClicked
                                            ? intl.get('terminal.popupConfirm.title1')
                                            : intl.get('terminal.popupConfirm.title2')}
                                        , {customerName}!
                                    </p>
                                    <p className="pay-text">{intl.get('terminal.popupConfirm.subheading')}</p>
                                </div>
                            ) : (
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: intl.get('terminal.popupConfirm.subheading2'),
                                    }}
                                    style={{ textAlign: 'center' }}
                                    className="pay-text"
                                ></p>
                            )}
                            {!this.isPaidPaymentMethod(paymentType) ? (
                                <img src="/upward-arrow.png" className="arrow" alt="arrow image" />
                            ) : null}
                        </div>
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                            <p className="customer-name">
                                {isSkipClicked
                                    ? intl.get('terminal.popupConfirm.title1')
                                    : intl.get('terminal.popupConfirm.title2')}
                                , {customerName}!
                            </p>
                            {!this.isPaidPaymentMethod(paymentType) ? (
                                <p className="pay-text">{intl.get('terminal.popupConfirm.subheading')}</p>
                            ) : (
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: intl.get('terminal.popupConfirm.subheading2'),
                                    }}
                                    style={{ textAlign: 'center' }}
                                    className="pay-text"
                                ></p>
                            )}
                            {!this.isPaidPaymentMethod(paymentType) ? (
                                <img
                                    src={arrowDirection === 'left' ? leftArrow : rightArrow}
                                    style={{ marginTop: '20px' }}
                                    className="arrow"
                                    alt="arrow image"
                                />
                            ) : null}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
