import React, { Fragment } from 'react';
import { Provider, observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import basePage from './basePage';
import WalletRoot from '../components/WalletRoot';
import { walletTags } from '../services/metatags';
import { services } from '../services/index';
import qs from 'query-string';
import UtmStore from '../store/utmStore';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const utmStore = new UtmStore();

@inject('userStore', 'walletStore', 'walletStatusStore')
@observer
export default class extends basePage {
    componentDidMount() {
        const { location, walletStore, walletStatusStore } = this.props;
        const params = qs.parse(location.search);
        utmStore.params = params;
        utmStore.handleSendParams(params);

        walletStore.getWalletOptions();
        walletStatusStore.getWalletStatus();

        document.body.classList.add('wallet');
        this.getMetaTags();
    }

    getMetaTags() {
        var tags = walletTags.slice();
        return tags;
    }

    render() {
        const { location } = this.props;
        const from = location.state ? location.state.from : '/menu';

        return (
            <Fragment>
                <Provider utmStore={utmStore}>
                    <WalletRoot
                        backLink={location.state && location.state.from}
                    />
                </Provider>
                <Helmet meta={this.getMetaTags()}>
                    <title>{services.getTitle('wallet')}</title>
                    {localizationTags(`wallet`).map((tag) => tag)}
                    {getCanonicalTag(`wallet`)}
                </Helmet>
            </Fragment>
        );
    }
}
