import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import Tag from './Tag';

const base = 'rating-popup__rating-pane';

@inject('dishStore', 'orderRatingStore')
@observer
export default class extends React.Component {
    handleRateItem(score) {
        const { rating, orderRatingStore } = this.props;

        rating.score = score;
        rating.tagsSelected = [];

        orderRatingStore.submitRating(rating);
    }

    handleTagSelected(tag) {
        const { rating } = this.props;
        const { tagsSelected } = rating;

        if (tagsSelected.indexOf(tag) === -1) {
            tagsSelected.push(tag);
        } else {
            tagsSelected.remove(tag);
        }
    }

    handleCommentChanged(e) {
        const comment = e.currentTarget.value;
        const { rating } = this.props;

        rating.comment = comment;
    }

    handleContactSupport = () => {
        if (typeof window.fcWidget !== 'undefined') {
            window.fcWidget.open();
        }
    };

    render() {
        const { isDelivery, rating, title, orderRatingStore } = this.props;
        const { name, score, comment, tagsMap, tagsSelected, imageUrl } =
            rating;
        const { order } = orderRatingStore;
        return (
            <div className={`${base} border--rounded`}>
                {score === 1 ? (
                    <div
                        className={`${base}-container thumbs-up ${
                            isDelivery ? 'no-image' : ''
                        }`}
                    >
                        {isDelivery ? (
                            <Fragment>
                                <p
                                    className={`${base}-title m0 lh--200 text--bolder`}
                                >
                                    {intl.get(
                                        'rating.deliveryRating.thumbsUpLine1'
                                    )}
                                </p>
                                <p
                                    className={`${base}-title m0 lh--200 text--bolder`}
                                >{`${intl.get(
                                    'rating.deliveryRating.thumbsUpLine2'
                                )} ${order.data.firstname} 🙏`}</p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div
                                    className={`${base}-image border--rounded`}
                                    style={
                                        imageUrl && {
                                            backgroundImage: `url(${imageUrl})`,
                                        }
                                    }
                                />
                                <p className={`${base}-title text--bolder`}>
                                    {intl.get('rating.goodRating')}
                                </p>
                                <input
                                    type="text"
                                    className={`${base}-comment border--non-rounded`}
                                    placeholder={intl.get('rating.comment')}
                                    value={comment}
                                    onChange={(e) =>
                                        this.handleCommentChanged(e)
                                    }
                                />
                            </Fragment>
                        )}
                    </div>
                ) : score === 0 ? (
                    <div
                        className={`${base}-container thumbs-down ${
                            isDelivery ? 'no-image' : ''
                        }`}
                    >
                        {isDelivery ? (
                            <Fragment>
                                <p
                                    className={`${base}-title m0 lh--200 text--bolder`}
                                >
                                    {intl.get(
                                        'rating.deliveryRating.thumbsDownLine1'
                                    )}
                                </p>
                                <p
                                    className={`${base}-title m0 lh--200 text--bolder ${base}__missing-food-cta text--primary cursor-pointer`}
                                    onClick={this.handleContactSupport}
                                >
                                    {intl.get(
                                        'rating.deliveryRating.thumbsDownLine2'
                                    )}
                                </p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div
                                    className={`${base}-image border--rounded`}
                                    style={
                                        imageUrl && {
                                            backgroundImage: `url(${imageUrl})`,
                                        }
                                    }
                                />
                                <p className={`${base}-title text--bolder`}>
                                    {intl.get('rating.badRating')}
                                </p>
                                <div className={`${base}-tag-panel`}>
                                    <div className={`${base}-tag-container`}>
                                        {tagsMap[score].map((tag) => (
                                            <Tag
                                                key={tag}
                                                selected={
                                                    tagsSelected.indexOf(
                                                        tag
                                                    ) !== -1
                                                }
                                                onSelect={() =>
                                                    this.handleTagSelected(tag)
                                                }
                                                name={tag}
                                            />
                                        ))}
                                    </div>
                                </div>
                                {tagsSelected.length > 0 && (
                                    <input
                                        type="text"
                                        className={`${base}-comment border--non-rounded`}
                                        placeholder={intl.get(
                                            'rating.suggestion'
                                        )}
                                        value={comment}
                                        onChange={(e) =>
                                            this.handleCommentChanged(e)
                                        }
                                    />
                                )}
                            </Fragment>
                        )}
                    </div>
                ) : (
                    <div
                        className={`${base}-container ${
                            isDelivery ? 'no-image' : ''
                        }`}
                    >
                        {!isDelivery && (
                            <div
                                className={`${base}-image border--rounded`}
                                style={
                                    imageUrl && {
                                        backgroundImage: `url(${imageUrl})`,
                                    }
                                }
                            />
                        )}
                        <p
                            className={`${base}-title ${
                                isDelivery ? 'text--bolder' : ''
                            }`}
                        >
                            {title || name}
                        </p>
                        <div className={`${base}-btn-wrapper`}>
                            <div
                                className={`${base}-button ${base}-button-down`}
                                onClick={() => this.handleRateItem(0)}
                            >
                                <svg className={`${base}-icon iconmoon-icon`}>
                                    <use xlinkHref="#icon-thumbs-down" />
                                </svg>
                            </div>
                            <div
                                className={`${base}-button ${base}-button-up`}
                                onClick={() => this.handleRateItem(1)}
                            >
                                <svg className={`${base}-icon iconmoon-icon`}>
                                    <use xlinkHref="#icon-thumbs-up" />
                                </svg>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
