import { parse, parsePhoneNumberFromString } from 'libphonenumber-js';
import { COUNTRY_CODES } from './constants';
import countryTelData from 'country-telephone-data';

export const parsePhone = (number) => {
    return parse(number);
};

export const getCountryCode = (cc) => {
    return COUNTRY_CODES[cc.toLowerCase()] || '';
};

export const parsePhoneNumber = (phoneNumber, countryCode) => {
    let parsedNumber = null;
    if (countryCode) {
        countryCode = countryCode.replace('+', '');
        // checks to see if user selected country code matches a valid country code from country-telephone-data library
        let selectedCountryArray = countryTelData.allCountries.filter(
            (country) => country.dialCode === countryCode.toString()
        );
        // if it does, checks to see if the user's inputted mobile number matches a valid format for any of the countries
        if (selectedCountryArray.length > 0) {
            selectedCountryArray.forEach((country) => {
                parsedNumber = parsePhoneNumberFromString(
                    phoneNumber,
                    country.iso2.toUpperCase()
                );
            });
        }
    }
    // if a valid number is returned from libphonenumber-js, returns an object with the countryCode and phoneNumber
    if (parsedNumber) {
        return {
            countryCode: parsedNumber.countryCallingCode,
            phoneNumber: parsedNumber.nationalNumber,
        };
    }
};
