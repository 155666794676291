export const SMALL_SCREEN_MEDIA = '(min-width: 300px) and (max-width: 650px)';

export const MEDIUM_SCREEN_MEDIA = '(min-width: 651px) and (max-width: 900px)';

export const HUGE_SCREEN_MEDIA = '(min-width: 1368px)';

export const IS_1200_OR_LESS = '(max-width: 1199px)';

export const HOME_ADDRESS = 'House';

export const OFFICE_ADDRESS = 'Office';

export const CONDO_ADDRESS = 'Condo';

export const OTHER_ADDRESS = 'Other';

export const RING_CAPTCHA_ERROR_MESSAGES = {
    ERROR_INVALID_NUMBER:
        'Phone number is incorrect, either the area code is missing or it contains invalid numbers',
    TOO_SHORT: 'Phone number is too short',
    TOO_LONG: 'Phone number is too long',
    ERROR_WAIT_TO_RETRY:
        'You have requested verification code too many times. Please wait before requesting another one',
    ERROR_INVALID_SESSION: 'Phone verification timeout, please resend the code',
};

export const COUNTRY_CODES = {
    my: '+60',
    vn: '+84',
    th: '+66',
};

export const DELIVERY_STATUS_PENDING = 'Pending';
export const DELIVERY_STATUS_INTRANSIT = 'Intransit';
export const DELIVERY_STATUS_ARRIVED = 'Driverarrived';
export const DELIVERY_STATUS_DELIVERED = 'Delivered';
export const DELIVERY_STATUS_DELAYED = 'Delayed';
export const DELIVERY_STATUS_CANCELLED = 'Cancelled';

export const RATING_TYPE_DELIVERY = 'DELIVERY_BINARY';
export const RATING_TYPE_MEAL = 'TODAY_MENU_BINARY';

export const RATING_LEVEL = {
    1: 'Terrible',
    2: 'Bad',
    3: 'Okay',
    4: 'Good',
    5: 'Great',
};

export const EMAIL = 'Email';
export const PHONE = 'Phone';
export const MAGICLINK = 'MagicLink';

export const SIGNUP = 'Signup';
export const LOGIN = 'Login';
export const CUTLERY_PRICE = 0.5;
