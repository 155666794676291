const show = (message, type, extra) => {
    const event = new CustomEvent('showBanner', {
        detail: {
            data: {
                text:
                    typeof message === 'string'
                        ? message.toString()
                        : JSON.stringify(message),
                extra,
            },
            type,
        },
    });

    if (type === 'error') {
        console.error(message); //eslint-disable-line
    }

    document.dispatchEvent(event);
};

export default {
    showError: (message, extra = {}) => show(message, 'error', extra),
    showSuccess: (message, extra = {}) => show(message, 'success', extra),
    showInfo: (message, extra = {}) => show(message, 'info', extra),
    showWarning: (message, extra = {}) => show(message, 'warning', extra),
};
