// Client-side: If AJAX fails because of network/downtime, reload the page
export class ClientGuard {
    handleRoute(next) {
        if (typeof window !== 'undefined') {
            document.addEventListener('serverNotCommunicating', () => {
                window.location.reload();
            });
        }

        return next();
    }
}
