import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

@inject('creditCardStore')
@observer
class CreditCardForm extends React.Component {
    handleAddCreditCard = (e) => {
        e.preventDefault();

        this.props.creditCardStore.doAddCreditCard();
    };

    cc_format(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        var matches = v.match(/\d{4,16}/g);
        var match = (matches && matches[0]) || '';
        var parts = [];
        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4));
        }
        if (parts.length) {
            return parts.join(' ');
        } else {
            return value;
        }
    }

    render() {
        const { creditCardStore } = this.props;
        const updateModel = ::creditCardStore.updateModel;

        return (
            <form
                className={this.props.formClasses}
                onSubmit={this.handleAddCreditCard}
            >
                <div className="mbm">
                    <input
                        id="card-number"
                        value={this.cc_format(creditCardStore.cardNumber)}
                        placeholder={intl.get(
                            'packageCheckout.addCreditCard.creditcardNumber'
                        )}
                        className="input width--100"
                        onChange={updateModel}
                        name="cardNumber"
                    />
                </div>
                <div className="mbm">
                    <input
                        id="card-holder"
                        value={creditCardStore.cardHolder}
                        placeholder={intl.get(
                            'packageCheckout.addCreditCard.cardHolderName'
                        )}
                        className="input width--100"
                        onChange={updateModel}
                        name="holderName"
                    />
                </div>
                <div className="mbm">
                    <input
                        id="expiration-month"
                        value={creditCardStore.expiryMonth}
                        placeholder="MM"
                        className="input width--25"
                        onChange={updateModel}
                        name="expiryMonth"
                    />
                    <input
                        id="expiration-year"
                        value={creditCardStore.expiryYear}
                        placeholder="YYYY"
                        className="input width--25"
                        onChange={updateModel}
                        name="expiryYear"
                    />
                    <input
                        style={{ float: 'right' }}
                        id="cvv"
                        value={creditCardStore.cvc}
                        placeholder="CVV"
                        className="input width--30"
                        onChange={updateModel}
                        name="cvc"
                    />
                </div>
                <div>
                    <button type="submit" className={this.props.buttonClasses}>
                        {intl.get('packageCheckout.addCreditCard.cta')}
                    </button>
                </div>
            </form>
        );
    }
}

export default CreditCardForm;
