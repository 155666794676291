import React from 'react';
import { currency } from '../../../../services';

const base = 'menu-cart-confirm';

export default ({ quantity, name, price }) => {
    return (
        <div className="display--flex fs--small-medium">
            <div className={`${base}__quantity-col flex-none`}>x{quantity}</div>
            <div className="flex-1-only">{name}</div>
            <div className="flex-none">
                {currency.localCurrency(price * quantity)}
            </div>
        </div>
    );
};
