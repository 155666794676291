import React, { Component, Fragment } from 'react';
import CarouselUI from '../careersPage/CarouselUI';
import {
    MEDIUM_SCREEN_MEDIA,
    SMALL_SCREEN_MEDIA,
} from '../../../services/constants';
import intl from 'react-intl-universal';
import { template } from 'lodash';
import config from '../../../configs/parameters';

const base = 'about__section-how-it-works';

export default class SectionHowItWorks extends Component {
    state = {
        activeIndex: 0,
    };

    getActiveIndex = (i) => {
        if (this.state.activeIndex !== i) {
            this.setState({ activeIndex: i });
        }
    };

    clickStep = (i) => {
        const name = 'about-section-how-it-works';
        const scrollLength =
            document.getElementById(`${name}-box`).offsetWidth +
            (window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 15 : 25);
        const carousel = document.getElementById(name);
        carousel.scrollLeft = scrollLength * i;
    };

    render() {
        const smallAndMediumScreens =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches ||
            window.matchMedia(MEDIUM_SCREEN_MEDIA).matches;
        const { activeIndex } = this.state;
        const brandName = config.brandName;

        const content = [
            {
                header: intl.get(
                    'about.section-how-it-works.content.one.header'
                ),
                content: intl.get(
                    'about.section-how-it-works.content.one.content'
                ),
                contentImg:
                    'https://d3tjqo2jh935k9.cloudfront.net/images/static/step-one.png',
            },
            {
                header: intl.get(
                    'about.section-how-it-works.content.two.header'
                ),
                content: intl.get(
                    'about.section-how-it-works.content.two.content'
                ),
                contentImg:
                    'https://d3tjqo2jh935k9.cloudfront.net/images/static/step-two.png',
            },
            {
                header: template(
                    intl.get('about.section-how-it-works.content.three.header')
                )({ brandName }),
                content: intl.get(
                    'about.section-how-it-works.content.three.content'
                ),
                contentImg:
                    'https://d3tjqo2jh935k9.cloudfront.net/images/static/step-three.png',
            },
            {
                header: intl.get(
                    'about.section-how-it-works.content.four.header'
                ),
                content: template(
                    intl.get('about.section-how-it-works.content.four.content')
                )({ brandName }),
                contentImg:
                    'https://d3tjqo2jh935k9.cloudfront.net/images/static/step-four.png',
            },
        ];

        return (
            <div className={base}>
                <div className={`${base}__header-container container`}>
                    <h3 className={`${base}__header text--bolder`}>
                        {template(
                            intl.get('about.section-how-it-works.header')
                        )({ brandName })}
                    </h3>
                    <p className={`${base}__sub-header`}>
                        {intl.get(
                            'about.section-how-it-works.sub-header-line1'
                        )}{' '}
                        {!window.matchMedia(SMALL_SCREEN_MEDIA).matches && (
                            <br />
                        )}
                        {intl.get(
                            'about.section-how-it-works.sub-header-line2'
                        )}
                    </p>
                </div>
                {!smallAndMediumScreens && (
                    <CarouselUI
                        content={content}
                        contentWidth={420}
                        height={307}
                        showStep={true}
                        name="about-section-how-it-works"
                        width={990}
                        backgroundColor="#fff9e6"
                        imgWidth={411}
                        imgHeight={217}
                        marginBottom={55}
                        leftAlignArrows={true}
                        arrowLeftMargin={180}
                        fullLengthScroll={true}
                        getActiveIndex={this.getActiveIndex}
                        index={this.state.activeIndex}
                    />
                )}
                <div className={`${base}__carousel-titles container`}>
                    {content.map((c, i) => (
                        <Fragment key={i}>
                            <div
                                className={`${base}__title-container cursor-pointer ${
                                    i === activeIndex ||
                                    window.matchMedia(SMALL_SCREEN_MEDIA)
                                        .matches
                                        ? 'text--secondary'
                                        : 'greyed-out'
                                }`}
                                onClick={() => this.clickStep(i)}
                            >
                                <p
                                    className={` ${base}__carousel-number text--bolder`}
                                >
                                    0{i + 1}.
                                </p>
                                <div>
                                    <p
                                        className={`${base}__carousel-title text--bolder`}
                                    >
                                        {c.header}
                                    </p>
                                    {smallAndMediumScreens && (
                                        <p
                                            className={`${base}__carousel-content hide--if-large`}
                                        >
                                            {c.content}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </div>
        );
    }
}
