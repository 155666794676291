import React from 'react';
import intl from 'react-intl-universal';

import { GetCity } from '../../../services/city';

const base = 'delivery-area__hero-section';

export default class extends React.Component {
    handleScrollToMap() {
        const scrollToElement = require('scroll-to-element');
        const mapSection = document.getElementById('map-section');

        scrollToElement(mapSection, { duration: 500 });
    }

    render() {
        return (
            <div className={base}>
                <div className={`${base}__container container`}>
                    <div className={`row`}>
                        <div className="col-sm-12 col-md-6 col-lg-7" />
                        <div className="col-sm-12 col-md-6 col-lg-5">
                            <h1
                                className={`${base}__heading text--sofiapro mbs`}
                            >
                                {intl.get('deliveryArea.heroHeading')}
                            </h1>
                            <p className={`${base}__subheading mbl`}>
                                {intl.get('deliveryArea.heroSubheading')}
                            </p>
                            <div className="separator separator--green mbl" />
                            <p className={`${base}__intro-text mbl lh--150`}>
                                {intl.get(
                                    `${GetCity()}.deliveryArea.heroIntroText`
                                )}
                            </p>
                            <div className={`${base}__benefit-container`}>
                                <div className="mbm display--flex">
                                    <svg className="iconmoon-icon display--inline-block">
                                        <use
                                            xlinkHref={`#icon-black-checkmark`}
                                        />
                                    </svg>
                                    <span className={`${base}__benefit mlm`}>
                                        {intl.get(
                                            `${GetCity()}.deliveryArea.heroBenefit_1`
                                        )}
                                    </span>
                                </div>
                                <div className="mbm display--flex">
                                    <svg className="iconmoon-icon display--inline-block">
                                        <use
                                            xlinkHref={`#icon-black-checkmark`}
                                        />
                                    </svg>
                                    <span className={`${base}__benefit mlm`}>
                                        {intl.get('deliveryArea.heroBenefit_2')}
                                    </span>
                                </div>
                            </div>
                            <button
                                className={`${base}__cta button button--success button--bigger-on-mobile mtl`}
                                onClick={::this.handleScrollToMap}
                            >
                                {intl.get('deliveryArea.heroCTA')}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`${base}__overlay`}></div>
            </div>
        );
    }
}
