import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import { services } from '../../services/index';
import { when } from 'mobx';
import { observer, inject } from 'mobx-react';
import { CircleLoader } from '../core/Loading';

import { RESOURCE } from '../../services/types';
import { SMALL_SCREEN_MEDIA } from '../../services/constants';

const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_ENTER = 13;
const base = 'place-autocomplete';

export const PredictionItem = ({ prediction }) => {
    const { matched_substrings, description } = prediction;

    if (!matched_substrings) {
        return <span className="ellipsis prm">{description}</span>;
    }

    let children = [];
    for (let i = 0; i < matched_substrings.length; i++) {
        const { offset, length } = matched_substrings[i];
        children.push(
            <span
                key={`${prediction.place_id}-${offset}`}
                className="text--bolder text--dark"
            >
                {description.substring(offset, offset + length)}
            </span>
        );
        // add non-bold text
        let nextOffset = undefined;
        if (i + 1 < matched_substrings.length) {
            // has next substring -> fill gap from current position up to next offset
            nextOffset = matched_substrings[i + 1].offset;
        }
        // else no next substring -> take remaining string from current position
        children.push(
            <span key={`${prediction.place_id}-${offset + length}`}>
                {description.substring(offset + length, nextOffset)}
            </span>
        );
    }

    return (
        <span className="ellipsis prm" key={prediction.place_id}>
            {children}
        </span>
    );
};

@inject('addressAutocompleteStore', 'userStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);

        this.input = '';

        this.state = {
            focus: false,
            inputValue: '',
        };
    }

    componentDidMount() {
        const { selectedAddress, toggleFocus } = this.props;

        setTimeout(() => {
            if (this.input && !selectedAddress) {
                if (toggleFocus) {
                    toggleFocus(true);
                }
                this.input.focus();
            } else {
                if (toggleFocus) {
                    toggleFocus(false);
                }
            }
        }, 500);

        when(
            () => selectedAddress,
            () => this.setInputValue(selectedAddress)
        );
    }

    componentDidUpdate(prevProps) {
        const { selectedAddress, focus } = this.props;
        if (prevProps.selectedAddress !== selectedAddress) {
            this.setInputValue(selectedAddress);
        }
        if (focus === false && prevProps.focus !== focus && selectedAddress) {
            this.setInputValue(selectedAddress);
        }
    }

    setInputValue = (value) => {
        this.setState({ inputValue: value });
        this.handleInputValueCB(value);
    };

    handleInputValueCB = (value) => {
        const { liftInputValueCallback } = this.props;
        if (liftInputValueCallback) {
            liftInputValueCallback(value);
        }
    };

    handleTyping = (e) => {
        const { addressAutocompleteStore } = this.props;
        // check why this is the case
        const input = e.currentTarget.value;

        window.clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(() => {
            addressAutocompleteStore.getPlacePredictions(input);
        }, 150);
        this.setInputValue(input);
    };

    handleKeyUp = (e) => {
        const { addressAutocompleteStore } = this.props;
        const inputValue = e.currentTarget.value.trim();

        if (!inputValue) {
            return;
        }

        if (e.keyCode === KEY_UP) {
            addressAutocompleteStore.selectUp();
        } else if (e.keyCode === KEY_DOWN) {
            addressAutocompleteStore.selectDown();
        } else if (e.keyCode === KEY_ENTER) {
            if (addressAutocompleteStore.selectedIndex >= 0) {
                this.handlePredictionClicked(
                    addressAutocompleteStore.selectedIndex,
                    addressAutocompleteStore.selectedPrediction
                );
            }
        }
    };

    handlePredictionClicked = (index, prediction) => {
        const { addressAutocompleteStore, onAddressSelected } = this.props;
        addressAutocompleteStore.setConfirmedIndex(index);
        onAddressSelected(prediction, this.props.onAddressConfirmed);

        if (prediction) {
            setTimeout(() => {
                when(
                    () =>
                        RESOURCE.Failure.is(
                            addressAutocompleteStore.addressChecker
                        ),
                    () => {
                        this.setInputValue(prediction.description);
                    }
                );
            }, 1000);
        }
    };

    handleMouseOver = (index) => {
        const { addressAutocompleteStore } = this.props;
        addressAutocompleteStore.selectedIndex = index;
    };

    handleFocus = () => {
        const { toggleFocus } = this.props;
        this.setState({ focus: true });
        this.setInputValue('');
        if (toggleFocus) {
            toggleFocus(true);
        }
        // this.getNearbyLocations(); <--- disabled for now
    };

    //disabled for now as it is very costly
    getNearbyLocations = () => {
        const { addressAutocompleteStore } = this.props;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                const usersLocation = new window.google.maps.LatLng(pos);
                const request = {
                    location: usersLocation,
                    radius: '500',
                    type: ['point_of_interest'],
                };

                const map = new window.google.maps.Map(
                    document.createElement('div')
                );

                const service = new window.google.maps.places.PlacesService(
                    map
                );
                service.textSearch(request, (results, status) => {
                    if (status === 'OK') {
                        addressAutocompleteStore.getNearbyLocations(results);
                    }
                });
            });
        }
    };

    handleFocusOut = () => {
        const { toggleFocus } = this.props;
        this.setState({ focus: false });
        if (toggleFocus) {
            toggleFocus(false);
        }
    };

    renderList() {
        const { addressAutocompleteStore, showBorder, displayErrorInline } =
            this.props;
        const { inputValue } = this.state;
        if (
            !displayErrorInline &&
            addressAutocompleteStore.predictions.length > 0 &&
            RESOURCE.Failure.is(addressAutocompleteStore.addressChecker) &&
            inputValue !== ''
        ) {
            return (
                <div>
                    <p className="text--red mtm mbl">
                        {intl.get('placeAutoComplete.title')}
                    </p>
                    <a
                        className={`${base}__delivery-area-link text--primary`}
                        href="/delivery-area"
                        target="_blank"
                    >
                        {intl.get('placeAutoComplete.cta')}
                    </a>
                </div>
            );
        }
        if (
            addressAutocompleteStore.predictions.length === 0 &&
            inputValue.length > 3 &&
            !RESOURCE.Success.is(addressAutocompleteStore.addressChecker)
        ) {
            return (
                <ul
                    className={`${base}__prediction-wrapper ${
                        showBorder ? 'no-margin' : ''
                    }`}
                >
                    <li className={`${base}__prediction`} id="prediction-item">
                        <svg
                            className={`${base}__prediction-icon iconmoon-icon flex-none`}
                        ></svg>
                        No address found
                    </li>
                </ul>
            );
        }

        if (
            addressAutocompleteStore.predictions.length &&
            !RESOURCE.Failure.is(addressAutocompleteStore.addressChecker)
        ) {
            return (
                <ul
                    className={`${base}__prediction-wrapper ${
                        showBorder ? 'no-margin' : ''
                    }`}
                >
                    {addressAutocompleteStore.predictions.map(
                        (prediction, index) => {
                            const selectedCls =
                                index ===
                                    addressAutocompleteStore.selectedIndex &&
                                !addressAutocompleteStore.loading
                                    ? `${base}__prediction--selected`
                                    : '';
                            const confirmed =
                                index ===
                                addressAutocompleteStore.confirmedIndex
                                    ? `${base}__prediction--selected`
                                    : '';

                            return (
                                <li
                                    className={`${base}__prediction ${selectedCls} ${confirmed} cursor-pointer`}
                                    id="prediction-item"
                                    onMouseOver={() =>
                                        this.handleMouseOver(index)
                                    }
                                    key={index}
                                    onClick={this.handlePredictionClicked.bind(
                                        this,
                                        index,
                                        prediction
                                    )}
                                >
                                    <svg
                                        className={`${base}__prediction-icon iconmoon-icon flex-none`}
                                    ></svg>
                                    <PredictionItem prediction={prediction} />
                                </li>
                            );
                        }
                    )}
                </ul>
            );
        }
        return null;
    }

    renderCheckMark = () => {
        return (
            <svg className={`${base}__check iconmoon-icon`}>
                <use xlinkHref="#icon-checkmark-noborder-account-switcher" />
            </svg>
        );
    };

    renderInput = () => {
        const {
            marginLeft,
            addressAutocompleteStore,
            displayBlack,
            disableAutoFocus,
            customInputClasses,
            large,
        } = this.props;
        const { inputValue } = this.state;
        const placeholder =
            services.getParam('country') === 'th' ? 'Empire Tower' : 'Petronas';

        const inputClasses = RESOURCE.Failure.is(
            addressAutocompleteStore.addressChecker
        )
            ? `${base}__input input input--error`
            : `${base}__input input`;

        return (
            <Fragment>
                {addressAutocompleteStore.addressChecker ===
                RESOURCE.Loading ? (
                    <div
                        className="center--vertical"
                        style={{
                            marginLeft: (marginLeft ? marginLeft : 0) + 'px',
                            marginRight: '10px',
                        }}
                    >
                        <CircleLoader
                            className={`${base}__busy ${large ? 'large' : ''}`}
                        />
                    </div>
                ) : (
                    <div
                        className="center--vertical"
                        style={{
                            marginLeft: (marginLeft ? marginLeft : 0) + 'px',
                            marginRight: '10px',
                        }}
                    >
                        <svg
                            className={`${base}__search-icon iconmoon-icon ${
                                displayBlack ? 'black' : ''
                            } ${large ? 'large' : ''}`}
                        >
                            <use xlinkHref="#icon-search" />
                        </svg>
                    </div>
                )}
                <input
                    type="text"
                    ref={(inputNode) => {
                        this.input = inputNode;
                    }}
                    onChange={this.handleTyping}
                    onKeyUp={this.handleKeyUp}
                    placeholder={`Eg. ${placeholder}`}
                    className={`${inputClasses} ${customInputClasses} ${
                        displayBlack ? 'black' : ''
                    } ${large ? 'large' : ''} ellipsis`}
                    autoFocus={!disableAutoFocus}
                    onFocus={this.handleFocus}
                    onBlur={disableAutoFocus ? () => {} : this.handleFocusOut}
                    value={inputValue}
                />
            </Fragment>
        );
    };

    render() {
        const { focus, inputValue } = this.state;
        const {
            inputContainerClass,
            showBorder,
            absolutePosition,
            userStore,
            displayErrorInline,
            addressAutocompleteStore,
            disableAutoFocus,
            liftInputValueCallback,
        } = this.props;
        const displayInline = displayErrorInline && inputValue !== '';

        return (
            <div className={`${base} ${absolutePosition ? 'absolute' : ''}`}>
                <div
                    className={`${base}__input-container display--flex ${
                        showBorder ? 'border-bottom-none' : ''
                    } ${inputContainerClass ? inputContainerClass : ''}`}
                >
                    <div
                        className={`${
                            showBorder
                                ? `${base}__border`
                                : 'display--flex flex-align-items-center'
                        } ${
                            focus ||
                            (displayInline &&
                                RESOURCE.Success.is(
                                    addressAutocompleteStore.addressChecker
                                ))
                                ? 'focus'
                                : ''
                        }`}
                        style={{ width: '100%' }}
                    >
                        {this.renderInput()}
                        {disableAutoFocus &&
                            !this.props.focus &&
                            window.matchMedia(SMALL_SCREEN_MEDIA).matches && (
                                <div
                                    className={`${base}__change-button-container`}
                                    onClick={this.handleFocus}
                                >
                                    <span>Change</span>
                                    <svg
                                        className={`${base}__arrow-icon iconmoon-icon`}
                                    >
                                        <use xlinkHref="#icon-chevron-thin-right" />
                                    </svg>
                                </div>
                            )}
                        {displayInline &&
                            addressAutocompleteStore.predictions.length > 0 &&
                            RESOURCE.Failure.is(
                                addressAutocompleteStore.addressChecker
                            ) && (
                                <span className={`${base}__inline-error`}>
                                    Outside delivery area
                                </span>
                            )}
                        {displayInline &&
                            RESOURCE.Success.is(
                                addressAutocompleteStore.addressChecker
                            ) &&
                            this.renderCheckMark()}
                    </div>
                </div>
                {this.renderList()}
            </div>
        );
    }
}
