import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import BasePage from './basePage';
import ProfileRoot from '../components/ProfileRoot';
import { getGTMScript } from '../components/core/ScriptTag';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('profile');

export default class extends Component {
    componentDidMount() {
        document.body.classList.add('profile');
    }
    render() {
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: 'Profile',
            lpType: '',
            lpVersion: '',
        });
        return (
            <Fragment>
                <Helmet>
                    <meta name="nosnippet" />
                    <title>{pageTitle}</title>
                    {localizationTags(`me`).map((tag) => tag)}
                    {getCanonicalTag(`me`)}
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <ProfileRoot />
            </Fragment>
        );
    }
}
