import React from 'react';

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isShowing: false };
        this.toggleTooltip = ::this.toggleTooltip;
        this.setNode = ::this.setNode;
        this.handleDisplayTooltip = ::this.handleDisplayTooltip;
    }

    setNode(node) {
        this.contentNode = node;
    }

    handleDisplayTooltip() {
        this.contentNode.classList.remove('help-popup--as-modal');

        const { showModalIfMatch } = this.props;
        if (window.matchMedia(showModalIfMatch).matches) {
            this.contentNode.classList.add('help-popup--as-modal');
        }
    }

    toggleTooltip() {
        this.setState(
            { isShowing: !this.state.isShowing },
            this.handleDisplayTooltip
        );
    }

    render() {
        const base = 'help-popup';
        const { className, text, contentClass, triggerClass } = this.props;
        const contentClasses = [
            contentClass,
            `${base}__content`,
            this.state.isShowing
                ? `${base}__content--show`
                : `${base}__content--hide`,
        ].join(' ');
        const triggerClasses = [
            triggerClass,
            'iconmoon-icon',
            `${base}__trigger`,
        ].join(' ');

        return (
            <span className={[className, base].join(' ')} ref={this.setNode}>
                <div
                    className={contentClasses}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
                {this.state.isShowing ? (
                    <div
                        className={`${base}__backdrop`}
                        onClick={this.toggleTooltip}
                    />
                ) : null}
                <div
                    className={`${base}__trigger-wrapper`}
                    onClick={this.toggleTooltip}
                >
                    <svg className={triggerClasses}>
                        <use xlinkHref="#icon-info" />
                    </svg>
                </div>
            </span>
        );
    }
}
