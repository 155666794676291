import React, { Component } from 'react';
import config from '../configs/parameters';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { CircleLoader } from '../components/core/Loading';

export default class extends Component {
    render() {
        const {
            location: { pathname },
        } = this.props;
        const pathIncludesCity =
            pathname.startsWith('/kuala-lumpur') ||
            pathname.startsWith('/bangkok');

        if (pathIncludesCity) {
            return (
                <div className="menu__list--loading text--center">
                    <div className="display--inline-block text--center">
                        <CircleLoader className="m0a" />
                        <p className="mtm">Redirecting...</p>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <p className="mxl">{intl.get('404.title')}</p>
                <Link className="mxl" to="/menu">
                    {intl.get('404.cta')}
                </Link>
            </div>
        );
    }

    getMetaTags() {
        return [
            { charset: 'utf-8' },
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
            },
            { name: 'google-signin-client_id', content: config.googleClientId },
            { name: 'apple-itunes-app', content: 'app-id=1030383844' },
            { name: 'msapplication-TileColor', content: '#ffffff' },
            {
                name: 'msapplication-TileImage',
                content: '/ms-icon-144x144.png',
            },
            { name: 'apple-mobile-web-app-title', content: config.brandName },
            { name: 'apple-mobile-web-app-capable', content: 'yes' },
            {
                name: 'apple-mobile-web-app-status-bar-style',
                content: '#ffffff',
            },
            { name: 'theme-color', content: '#ffffff' },
        ];
    }
}
