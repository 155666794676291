import { observable, action, when, computed } from 'mobx';
import { Link as URL, RESOURCE, VERIFICATION_STATUS } from '../services/types';
import { parsePhoneNumber } from '../services/phoneParser';
import rewardsStore from '../store/rewardsStore';
import { services } from '../services/index';
import { cities, GetCity } from '../services/city';
import { decodeJwtToken } from '../services/api/googleServices';
import {
    COUNTRY_CODES,
    EMAIL,
    PHONE,
    SIGNUP,
    LOGIN,
    MAGICLINK,
} from '../services/constants';
import { storeAutoAssignDiscountCampaign } from '../services/autoAssignDiscount';
import { getReferral, setReferral } from '../services/referrals';

const { clevertap } = window;
const noop = () => {};

const reload = () => {
    if (localStorage.getItem('redirectToSelectPage')) {
        localStorage.removeItem('redirectToSelectPage');
        window.location = '/kuala-lumpur/promotions?selectYourPlan=true';
    } else if (window.location.pathname.includes('/referred')) {
        window.location = '/menu';
    } else {
        window.location.reload();
    }
};

class LoginPopupStore {
    SELECT_LOGIN_TYPE_VIEW = 'SelectLoginTypeView';
    EMAIL_VIEW = 'EmailView';
    MOBILE_NUMBER_VERIFY_VIEW = 'MobileNumberVerifyView';
    PASSWORD_VIEW = 'PasswordView';
    BUSY_VIEW = 'BusyView';
    FORGET_PASSWORD_VIEW = 'ForgetPaswordView';
    COMPLETE_SIGNUP = 'CompleteSignup';
    LOGIN_COMPLETED_VIEW = 'LoginCompletedView';

    @observable.ref verifier = VERIFICATION_STATUS.Initial;

    @observable otpTimerRunning = false;
    @observable otpRetryTime = null;
    @observable email = '';
    @observable username = '';
    @observable password = '';

    @observable comingFrom = '';
    @observable phoneNumber = '';
    @observable countryCode = COUNTRY_CODES[services.getParam('country')];
    @observable verificationCode = '';
    @observable validNumber = '';
    @observable loginOtpRetryTime = null;
    @observable timerRunning = false;
    @observable redirectToSelectPage = false;

    @observable view = 'SelectLoginTypeView';
    @observable referralVariantView = 'MobileNumberView';
    @observable isShowing = false;
    @observable magicLinkAccessToken = '';
    @observable message = '';
    @observable.ref resetPassword = RESOURCE.Initial;
    @observable.ref usernameChecker = RESOURCE.Initial;
    @observable autoApplyDiscount = false;
    @observable referralMessage;
    @observable microwaving = false; // TRUE if user is currently in the signup process of ANY campaign
    @observable campaign = null; // this variable holds the name of the specific campaign
    @observable channel = null; // this variable holds the name of the channel on the offline signup page
    @observable details = null; // this variable holds the name of the details on the offline signup page
    @observable offlineCheapDish = false; // true if the switch is rockchop on the offline signup
    @observable lowerValueCode = '';
    @observable higherValueCode = '';
    @observable showOfflineSignupSuccessModal = false;
    @observable offlineSignupModalMessage =
        'SUCCESS! You have signed up and received your reward.';
    @observable isOfflineCampaign = false;

    @computed get mobileNumber() {
        return this.countryCode + this.phoneNumber;
    }
    referral() {
        return getReferral() || null;
    }
    backLink = null;

    constructor() {
        when(
            () => this.isShowing === true,
            () => {
                clevertap.event.push('See Signup Modal');
            }
        );
    }

    @action handleCredentials = (credentials) => {
        return new Promise((resolve) => {
            const accessToken = credentials['access_token'];
            services.api.AccessTokenStorage.setToken(accessToken);
            setTimeout(resolve, 1000);
        });
    };

    @action handleOfflineTracking = (branchResult) => {
        this.channel = branchResult.data_parsed[`~channel`];
        this.details = branchResult.data_parsed[`~details`];
        this.campaign = branchResult.data_parsed[`~campaign`];
        this.lowerValueCode = branchResult.data_parsed[`lowerValueCode`];
        this.higherValueCode = branchResult.data_parsed[`higherValueCode`];
    };

    @action handleOfflineCallback = (err) => {
        services.api.GetUserWithMemoizer.runFresh().then(() => {
            if (!err) {
                rewardsStore.redeemCode();
            } else {
                rewardsStore.isBusyApplyCode = false;
            }
            when(
                () => rewardsStore.isBusyApplyCode === false,
                () => {
                    services.api.AccessTokenStorage.clearToken();
                    if (err) {
                        this.message =
                            'Sorry, something went wrong. Please try again.';
                    } else {
                        this.view = this.SELECT_LOGIN_TYPE_VIEW;
                        this.email = '';
                        this.password = '';
                        this.phoneNumber = '';
                        this.verificationCode = '';
                        this.showOfflineSignupSuccessModal = true;
                    }
                }
            );
        });
    };

    @action handleReferral = (referral, event) => {
        if (event === LOGIN) {
            const referredByUsername = referral
                ? referral.referredByUsername
                : undefined;
            return rewardsStore.checkEligibility(
                referredByUsername,
                'logging-in'
            );
        } else {
            return new Promise((resolve) => {
                if (referral) {
                    services.api.GetUserWithMemoizer.runFresh()
                        .then(() => {
                            rewardsStore.code =
                                referral['code'] ||
                                referral['~referring_link']
                                    .split('/e/')[1]
                                    .substring(0, 9);
                            rewardsStore.redeemCode({});
                        })
                        .then(() => resolve())
                        .catch(noop);
                } else {
                    resolve();
                }
            });
        }
    };

    @action checkUser(disableAutoOpenPopup) {
        services.api.Ping().catch(() => {
            this.email = '';
            this.password = '';

            if (!disableAutoOpenPopup) {
                this.isShowing = true;
            }
        });
    }

    @action setReferral = (referralInfo) => {
        setReferral(referralInfo);
    };

    @action handleTryNow = (itemsInCart = null) => {
        this.setBackLink(new URL(window.location.pathname, 'Back'));

        const isKL = GetCity() === cities.kl;
        const discount = {
            utm_source: 'promotion',
            promotion: isKL ? 'NEW25' : 'NEW30',
            benefit: isKL ? 0.25 : 0.3,
            benefit_type: 'percentage',
        };

        services.api
            .Ping()
            .then(() => {
                if (itemsInCart) {
                    rewardsStore.code = discount.promotion;
                    rewardsStore.redeemCode({
                        discountAmount: discount.benefit,
                        discountType: discount.benefit_type,
                    });
                    when(
                        () => rewardsStore.isBusyApplyCode === false,
                        () => (window.location.href = '/cart')
                    );
                } else {
                    storeAutoAssignDiscountCampaign(discount);
                    setTimeout(() => {
                        window.location.href = '/menu';
                    }, 500);
                }
            })
            .catch(() => {
                storeAutoAssignDiscountCampaign(discount);
                setTimeout(() => {
                    window.location.href = '/menu';
                }, 500);
            });
    };

    @action handleRefer = () => {
        this.setBackLink(new URL(window.location.pathname, 'Back'));

        services.api
            .Ping()
            .then(() => {
                window.location.href = '/rewards';
            })
            .catch(() => {
                this.email = '';
                this.password = '';
                // this.view = this.SELECT_LOGIN_TYPE_VIEW;
                this.isShowing = true;
            });
    };

    @action handleSignInClicked = () => {
        this.setShowing(true);
        this.showLogin();
        this.clearPassword();
    };

    removeCountryCodeFromPhoneNumber = () => {
        const parsedPhoneNumber = parsePhoneNumber(
            this.phoneNumber,
            this.countryCode
        );
        if (parsedPhoneNumber) {
            this.phoneNumber = parsedPhoneNumber.phoneNumber;
            this.countryCode = '+' + parsedPhoneNumber.countryCode;
        }
    };

    @action handleChange = (evt) => {
        const field = evt.currentTarget.name;
        this[field] = evt.currentTarget.value;

        if (field === 'phoneNumber' || field === 'countryCode') {
            this.verifier = VERIFICATION_STATUS.Initial;
            this.allowResendCode();
        }

        this.removeCountryCodeFromPhoneNumber();
    };

    @action handleLoginWithMobileNumber = () => {
        services.api.VerifyWithMobileNumber(
            this.phoneNumber + this.countryCode,
            this.verificationCode
        );
    };

    @action handleLoginMethod = (method, token) => {
        if (method === PHONE) {
            const mobileNumber = this.countryCode + this.phoneNumber;
            return services.api.VerifyWithMobileNumber(
                mobileNumber,
                this.verificationCode
            );
        } else if (method === EMAIL) {
            return services.api.UserLogin(this.email, this.password);
        } else if (method === MAGICLINK && token) {
            return services.api.GetMagicSignInToken({ token });
        }
    };

    @action doLogin = (method, token) => {
        this.handleLoginMethod(method, token)
            .then((credentials) => {
                if (method === PHONE) {
                    clevertap.event.push('Confirmed OTP');
                }
                return services.api
                    .GetUserWithToken(credentials['access_token'])
                    .then((user) => {
                        let payload = { Type: method };
                        this.handleCredentials(credentials)
                            .then(() => {
                                if (this.isOfflineCampaign) {
                                    this.handleOfflineCallback();
                                    payload['Campaign'] = this.campaign;
                                    payload['Event Building'] = this.building;
                                    this.initTrackingEvents(
                                        LOGIN,
                                        user,
                                        payload,
                                        this.referral()
                                    );
                                } else {
                                    this.initTrackingEvents(
                                        LOGIN,
                                        user,
                                        payload,
                                        this.referral()
                                    )
                                        .then(reload)
                                        .catch(reload);
                                }
                            })
                            .catch(noop);
                    })
                    .catch(noop);
            })
            .catch((responseBody) => {
                if (method === PHONE) {
                    if (
                        method === PHONE &&
                        this.verificationCode.length === 0
                    ) {
                        this.message =
                            'It seems like you did not enter a PIN code.';
                    } else {
                        this.message = responseBody.message;
                    }
                    this.view = this.MOBILE_NUMBER_VERIFY_VIEW;
                } else if (method === MAGICLINK) {
                    services.bannerEvents.showWarning(responseBody.message);
                } else {
                    this.message = responseBody.message;
                    this.view = this.PASSWORD_VIEW;
                }
            });
    };

    @action handleFacebookFailure() {
        this.view = this.SELECT_LOGIN_TYPE_VIEW;
        this.message =
            'The Facebook account you use does not have a valid email address.';

        clevertap.event.push('Facebook Signup Failed');

        return services.api
            .HasLoggedInFacebook()
            .then(services.api.LogOutFacebook)
            .catch(noop);
    }

    @action handleFacebookSuccess(profile) {
        services.api
            .LoginWithFacebook({
                email: profile.email,
                facebook_id: profile.id,
                firstname: profile.first_name,
                lastname: profile.last_name,
                marketing_campaign: this.campaign,
            })
            .then((credentials) => {
                const isNewUser = credentials.$$xhr.status === 201;

                return services.api
                    .GetUserWithToken(credentials['access_token'])
                    .then((user) => {
                        if (isNewUser) {
                            let property = {
                                Type: 'Facebook',
                                Referred: this.referral() ? true : false,
                            };
                            this.handleCredentials(credentials)
                                .then(() => {
                                    this.initTrackingEvents(
                                        'socialSignup',
                                        profile.email,
                                        property,
                                        this.referral(),
                                        profile.first_name,
                                        profile.last_name,
                                        'Facebook'
                                    )
                                        .then(reload)
                                        .catch(reload);
                                })
                                .catch(noop);
                        } else {
                            this.handleCredentials(credentials)
                                .then(() => {
                                    this.initTrackingEvents(LOGIN, user, {
                                        Type: 'Facebook',
                                    })
                                        .then(reload)
                                        .catch(reload);
                                })
                                .catch(noop);
                        }
                    })
                    .catch(noop);
            })
            .catch(::this.handleFacebookFailure);
    }

    @action doGoogleSignIn(profileData) {
        if (profileData) {
            const profile = decodeJwtToken(profileData.credential);
            const requestBody = {
                firstname: profile.given_name,
                lastname: profile.family_name,
                email: profile.email,
                google_id: profile.sub,
                marketing_campaign: this.campaign,
            };

            services.api
                .LoginWithGoogle(requestBody)
                .then((credentials) => {
                    const isNewUser = credentials.$$xhr.status === 201;

                    return services.api
                        .GetUserWithToken(credentials['access_token'])
                        .then((user) => {
                            if (isNewUser) {
                                let property = {
                                    Type: 'Google',
                                    Referred: this.referral(),
                                };
                                this.handleCredentials(credentials)
                                    .then(() => {
                                        this.initTrackingEvents(
                                            'socialSignup',
                                            profile.getEmail(),
                                            property,
                                            this.referral(),
                                            profile.getGivenName(),
                                            profile.getFamilyName(),
                                            'Google'
                                        )
                                            .then(reload)
                                            .catch(reload);
                                    })
                                    .catch(noop);
                            } else {
                                this.handleCredentials(credentials)
                                    .then(() => {
                                        this.initTrackingEvents(
                                            LOGIN,
                                            user,
                                            { Type: 'Google' },
                                            null
                                        )
                                            .then(reload)
                                            .catch(reload);
                                    })
                                    .catch(noop);
                            }
                        })
                        .catch(noop);
                })
                .catch(() => {
                    this.view = this.SELECT_LOGIN_TYPE_VIEW;
                });
        }
    }

    @action checkUsername = (loginMethod) => {
        this.usernameChecker = RESOURCE.Loading;
        this.message = '';

        if (loginMethod === PHONE) {
            this.removeCountryCodeFromPhoneNumber();
        }

        services.api
            .CheckUsername(this.email || this.mobileNumber)
            .then((response) => {
                this.message = '';
                this.showCompleteSignup(loginMethod);
                this.usernameChecker = RESOURCE.Success(response);
                this.comingFrom = SIGNUP;
            })
            .catch((response) => {
                if (this.isOfflineCampaign) {
                    if (response.has_ordered) {
                        // replace with response.has_ordered
                        this.message =
                            'Sorry, the 1 for 1 deal is only available for new customers';
                        this.email = '';
                        this.usernameChecker = RESOURCE.Failure(
                            this.message,
                            response
                        );
                    } else {
                        this.usernameChecker = RESOURCE.Failure(
                            this.message,
                            response
                        );
                        this.handleShowLogin(loginMethod);
                    }
                } else {
                    this.usernameChecker = RESOURCE.Failure(
                        response.message,
                        response
                    );
                    this.message = '';
                    this.handleShowLogin(loginMethod);
                }
                this.comingFrom = LOGIN;
            });
    };

    @action handleShowLogin = (loginMethod) => {
        if (loginMethod === PHONE) {
            this.requestOTP();
        } else {
            this.showPassword();
        }
    };

    @action requestOTP = () => {
        this.verifier = VERIFICATION_STATUS.CodeRequesting;
        services.api
            .GetOtp({ phone_no: this.mobileNumber })
            .then((res) => {
                if (res.status === 'ERROR') {
                    if (res.message) {
                        this.message = res.message;
                    } else {
                        this.message =
                            'There was an error with verifying your phone number';
                    }
                    this.verifier = VERIFICATION_STATUS.Initial;
                } else {
                    clevertap.event.push('Confirmed Phone Number');
                    this.verifier = VERIFICATION_STATUS.CodeRequestSuccess(res);
                    this.validNumber = true;
                    this.loginOtpRetryTime = res.retry_in;
                    this.timerRunning = true;
                }
            })
            .catch((e) => {
                this.message = e.message;
                this.verifier = VERIFICATION_STATUS.Initial;
            });
    };

    @action completeSignup = (method) => {
        const isReferred = this.referral() ? true : false;

        this.referralMessage = isReferred
            ? 'Applying Referral Credits ...'
            : '';
        this.handleSignupMethod(method)
            .then((credentials) => {
                if (method === PHONE) {
                    clevertap.event.push('Confirmed OTP');
                }
                this.handleCredentials(credentials)
                    .then(() => {
                        services.api
                            .GetUserWithToken(credentials['access_token'])
                            .then((user) => {
                                if (this.isOfflineCampaign) {
                                    this.handleOfflineCallback();
                                    this.initTrackingEvents(
                                        SIGNUP,
                                        user,
                                        { Type: method, Referred: isReferred },
                                        this.referral()
                                    );
                                } else {
                                    this.initTrackingEvents(
                                        SIGNUP,
                                        user,
                                        { Type: method, Referred: isReferred },
                                        this.referral()
                                    )
                                        .then(reload)
                                        .catch(reload);
                                }
                            });
                    })
                    .catch(noop);
            })
            .catch((e) => {
                if (method === PHONE) {
                    if (
                        method === PHONE &&
                        this.verificationCode.length === 0
                    ) {
                        this.message =
                            'It seems like you did not enter a PIN code.';
                    } else {
                        this.message = e.message;
                    }
                    this.view = this.MOBILE_NUMBER_VERIFY_VIEW;
                    this.referralVariantView = this.MOBILE_NUMBER_VERIFY_VIEW;
                } else {
                    this.message =
                        'Something went wrong. Please go back and try again.';
                }
            });
    };

    @action handleSignupMethod = (method) => {
        if (method === PHONE) {
            return services.api.VerifyWithMobileNumber(
                this.mobileNumber,
                this.verificationCode
            );
        } else {
            return services.api.CreateNewUser(
                this.email,
                this.password,
                this.campaign
            );
        }
    };

    @action clearPassword() {
        this.password = '';
        this.message = '';
    }

    @action forgetPassword() {
        this.resetPassword = RESOURCE.Loading;

        services.api
            .ResetPassword(this.email)
            .then(() => {
                this.view = this.FORGET_PASSWORD_VIEW;
            })
            .catch(noop)
            .then(() => {
                this.resetPassword = RESOURCE.Initial;
            });
    }

    setLanguage() {
        const lang = localStorage.getItem('lang')
            ? localStorage.getItem('lang')
            : 'en';
        return new Promise((resolve) => {
            services.api.SetLanguage(lang).then(() => {
                clevertap.profile.push({
                    Site: {
                        Language: lang,
                    },
                });
                resolve();
            });
            setTimeout(resolve, 1000);
        });
    }

    sendLoginTracking(user, property) {
        const { id, firstname, lastname, email, mobileno } = user;
        return new Promise((resolve) => {
            clevertap.onUserLogin.push({
                Site: {
                    Identity: id,
                    ...(firstname && { 'First Name': firstname }), // String
                    ...(lastname && { 'Last Name': lastname }), // String
                    ...(email && { Email: email }), // Email address of the user
                    ...(mobileno && { Phone: mobileno }), // Phone (with the country code)
                },
            });

            if (this.isOfflineCampaign) {
                clevertap.profile.push({
                    Site: {
                        Campaign: this.campaign,
                        Channel: this.channel,
                        Details: this.details,
                    },
                });
            }

            clevertap.event.push('User logged in', property);
            setTimeout(resolve, 1000);
        });
    }

    initTrackingEvents = (
        event,
        user,
        property,
        referred,
        firstName,
        lastName,
        platform
    ) => {
        if (this.redirectToSelectPage) {
            localStorage.setItem('redirectToSelectPage', true);
        }
        if (event === LOGIN) {
            return Promise.all([
                this.sendLoginTracking(user, property),
                this.setLanguage(),
                this.handleReferral(referred, event),
            ]);
        } else if (event === SIGNUP) {
            return Promise.all([
                this.sendSignUpTracking(user, property),
                this.setLanguage(),
                this.handleReferral(referred, event),
            ]);
        } else if (event === 'socialSignup') {
            return Promise.all([
                this.sendSocialSignUpTracking(
                    user,
                    property,
                    firstName,
                    lastName,
                    platform
                ),
                this.setLanguage(),
                this.handleReferral(referred, event),
            ]);
        }
    };

    sendSignUpTracking(user, property) {
        const { id, email, mobileno } = user;

        return new Promise((resolve) => {
            window.dataLayer.push({
                event: 'CompleteRegistration',
                registrationMethod: this.email ? EMAIL : PHONE,
            });

            clevertap.onUserLogin.push({
                Site: {
                    Identity: id,
                    ...(this.email
                        ? { Type: EMAIL, Email: email }
                        : { Type: PHONE, Phone: mobileno }),
                },
            });

            clevertap.event.push('Signup', property);
            setTimeout(resolve, 1000);
        });
    }

    sendSocialSignUpTracking(email, property, firstname, lastname, platform) {
        return new Promise((resolve) => {
            window.dataLayer.push({
                event: 'CompleteRegistration',
                registrationMethod: platform,
            });

            clevertap.profile.push({
                Site: {
                    Type: platform,
                    Email: email,
                    Name: firstname + ' ' + lastname,
                    'First Name': firstname,
                    'Last Name': lastname,
                },
            });

            clevertap.event.push('Signup', property);
            setTimeout(resolve, 1000);
        });
    }

    @action allowResendCode = () => {
        this.timerRunning = false;
    };

    getView() {
        return this.view;
    }
    setEmail(e) {
        this.email = e.currentTarget.value;
    }
    setPassword(e) {
        this.password = e.currentTarget.value;
    }
    setShowing(isShowing) {
        this.isShowing = isShowing;
    }
    setMagicLinkAccessToken(magicLinkAccessToken) {
        this.magicLinkAccessToken = magicLinkAccessToken;
    }
    showPassword() {
        this.view = this.PASSWORD_VIEW;
        clevertap.event.push('See Login Password Modal');
    }
    showCompleteSignup(method) {
        if (method === PHONE) {
            this.requestOTP();
        } else {
            this.view = this.COMPLETE_SIGNUP;
            clevertap.event.push('See Signup Password Modal');
        }
    }
    showLogin() {
        this.view = this.SELECT_LOGIN_TYPE_VIEW;
        this.message = '';
    }
    showEmail = () => {
        this.view = this.EMAIL_VIEW;
        this.message = '';
    };
    showVerifyNumber = () => {
        this.view = this.MOBILE_NUMBER_VERIFY_VIEW;
        this.message = '';
    };
    showVariantVerifyNumber = () => {
        this.referralVariantView = this.MOBILE_NUMBER_VERIFY_VIEW;
        this.message = '';
    };

    showBusy() {
        this.view = this.BUSY_VIEW;
    }
    setBackLink(backLink) {
        this.backLink = backLink;
    }
}

const store = new LoginPopupStore();
export default store;
