import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { currency } from '../../../services';
import SummaryItem from './SummaryItem';

import intl from 'react-intl-universal';
import SelectUpsell from '../../core/SelectUpsell';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

@inject('store')
@inject('cartStore')
@observer
export default class extends React.Component {
    render() {
        const base = 'order-summary';
        const { cartStore } = this.props;
        const { orderSummary } = this.props.store;

        return (
            orderSummary && (
                <div className={`${base} well--no-margin`}>
                    {orderSummary.price_summary.rows.map((item, i) => (
                        <SummaryItem
                            key={i}
                            title={item.name}
                            value={item.value}
                            bold={item.bold}
                            color={item.color}
                        />
                    ))}
                    {cartStore.showSelectUpsell && (
                        <SelectUpsell
                            marginBottom={
                                window.matchMedia(SMALL_SCREEN_MEDIA).matches
                                    ? 10
                                    : 0
                            }
                        />
                    )}
                </div>
            )
        );
    }
}
