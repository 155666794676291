import React from 'react';

export default class extends React.Component {
    static defaultProps = {
        onFinish: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            current: this.props.from,
        };
    }

    componentWillReceiveProps(newProps) {
        if (this.props.from !== newProps.from) {
            this.setState({
                current: newProps.from,
            });
        }
    }

    componentDidMount() {
        if (this.state.current > 0) {
            this.timer = setInterval(() => {
                if (this.state.current > 0) {
                    this.setState({ current: this.state.current - 1 });
                } else {
                    this.props.onFinish();
                    window.clearInterval(this.timer);
                }
            }, 1000);
        }
    }

    componentWillUnmount() {
        this.props.onFinish();
        window.clearInterval(this.timer);
    }

    render() {
        const { customMessage } = this.props;
        return (
            <span>
                {customMessage && customMessage + ' '}
                {this.props.displayFn(this.state.current)}
            </span>
        );
    }
}
