import daggy from 'daggy';
import { observable, action } from 'mobx';

import { services } from '../services/index';
import { ValidationResult } from '../services/validator';
import businessFormValidator from './validators/businessFormValidator';

const { clevertap } = window;

export const SPECIAL_EVENT = 'SpecialEvent';
export const RECURRED_EVENT = 'RecurredEvent';
export const SUBMIT_STATUS = daggy.taggedSum('SUBMIT_STATUS', {
    Initial: [],
    Submitting: [],
    Submitted: [],
});

export default class {
    @observable name = '';
    @observable phoneNumber = '';
    @observable emailAddress = '';
    @observable companyName = '';
    @observable eventDate = '';
    @observable numberOfPeople = '';
    @observable eventType = null;
    @observable.ref validationResult = new ValidationResult();
    @observable.ref submitStatus = SUBMIT_STATUS.Initial;

    @action updateModel(e) {
        this[e.currentTarget.name] = e.currentTarget.value;
    }

    @action submit() {
        const model = {
            name: this.name,
            phoneNumber: this.phoneNumber,
            emailAddress: this.emailAddress,
            companyName: this.companyName,
            eventType: this.eventType,
        };

        this.validationResult = businessFormValidator.validate(model);
        if (this.validationResult.pass()) {
            this.submitStatus = SUBMIT_STATUS.Submitting;

            services.api
                .SubmitBusinessForm({
                    company: model.companyName,
                    email: model.emailAddress,
                    name: model.name,
                    phone_number: model.phoneNumber,
                    number_of_people: this.numberOfPeople,
                    event_date: this.eventDate,
                    type: model.eventType,
                })
                .catch(() => {})
                .then(() => {
                    this.submitStatus = SUBMIT_STATUS.Submitted;
                })
                .then(() => {
                    clevertap.event.push('Submit Business Form');
                })
                .then(() => {
                    window.dataLayer.push({ event: 'submitBusinessForm' });
                });
        }
    }

    @action hidePopup() {
        this.submitStatus = SUBMIT_STATUS.Initial;
    }
}
