import React, { Fragment } from 'react';
import { Provider } from 'mobx-react';

import BaseLayout from './core/baseLayout/Master_BaseLayout';
import MenuHeader from './core/headers/MenuHeader';
import MenuFooter from './core/MenuFooter';
import Entry from './page/chefPage/Entry';

import menuHeaderStore from '../store/menuHeaderStore';
import PromotionBar from './core/PromotionBar';

const { clevertap } = window;

const Header = () => (
    <Provider menuHeaderStore={menuHeaderStore}>
        <Fragment>
            <MenuHeader />
            <PromotionBar />
        </Fragment>
    </Provider>
);

export default class extends React.Component {
    componentDidMount() {
        clevertap.event.push('View Page', {
            'Page Title': document.title,
            Type: 'About',
        });
    }

    render() {
        return (
            <BaseLayout.Page HeaderComponent={Header}>
                <main>
                    <Entry />
                </main>
                <Provider menuHeaderStore={menuHeaderStore}>
                    <MenuFooter />
                </Provider>
            </BaseLayout.Page>
        );
    }
}
