import React from 'react';
import intl from 'react-intl-universal';
import { template } from 'lodash';
import config from '../../../configs/parameters';

const base = 'terminal-about';

export default class extends React.Component {
    render() {
        const brandName = config.brandName;

        return (
            <div className={`${base}__what`}>
                <h5 className={`${base}__question text--bold`}>
                    {intl.get('about.what.question')}
                </h5>
                <h1 className={`${base}__what-title lh--125`}>
                    {intl.get('about.what.title')}
                </h1>
                <div
                    className={`${base}__separator separator separator--white mbm mtm`}
                />
                <p className={`${base}__what-subheading lh--150`}>
                    {template(intl.get('about.what.subheading'))({ brandName })}
                </p>
            </div>
        );
    }
}
