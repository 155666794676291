import { observable, action, computed, when } from 'mobx';
import { services } from '../services';
import { setCompanyToLocalStorage } from '../services/company';

const { clevertap } = window;

class SelectPopupStore {
    PAGE_ONE = 'pageOne';
    PAGE_TWO = 'pageTwo';
    PAGE_THANK_YOU = 'pageThankYou';

    @observable show = false;
    @observable success = false;
    @observable isLoading = false;
    @observable view = this.PAGE_ONE;
    @observable data = {};
    @observable selected = {};
    @observable popupContent = {};
    @observable message = '';

    @action setShow() {
        this.show = true;
    }

    @computed get plans() {
        if (this.data.list && this.data.list.length > 0) {
            return this.data.list;
        } else {
            return [];
        }
    }

    @computed get publishedPlans() {
        if (this.data.list && this.data.list.length > 0) {
            return this.data.list.filter((plan) => plan.published);
        } else {
            return [];
        }
    }

    @action handleGetSelectPopupContent = () => {
        services.api
            .GetSelectPopupContent()
            .then((res) => (this.popupContent = res))
            .catch((e) => services.bannerEvents.showError(e.message));
    };

    @action handleGetSelectOptions = () => {
        services.api
            .GetAllSelectOptions(services.getParam('city'))
            .then((res) => {
                this.data = res;
                when(
                    () => this.publishedPlans && this.publishedPlans.length > 0,
                    () => {
                        const planNames = this.publishedPlans
                            .map((plan) => plan.name)
                            .toString();
                        clevertap.event.push('View Select Plans', {
                            'Plan nicknames': planNames,
                        });
                    }
                );
            });
    };

    @action selectPlan = (plan) => {
        this.selected = plan;
        clevertap.event.push('Tapped On Select Plan', {
            Plan: plan.name,
        });
    };

    handleChangeView = (view) => {
        if (view === this.PAGE_TWO) {
            clevertap.event.push('Tapped On Join Select', {
                Plan: this.selected.name,
            });
        }
        this.view = view;
    };

    @action
    handleShowPopup = () => {
        this.show = true;
        clevertap.event.push('Tapped On Select Upsell In Cart');
    };

    @action
    handleHidePopup = () => {
        if (this.view === this.PAGE_THANK_YOU) {
            if (window.location.href.includes('promotion')) {
                window.location = '/menu';
            } else {
                window.location.reload();
            }
        } else {
            this.show = false;
        }
    };

    @action handleStoreStripeToken = (stripeResponse) => {
        this.isLoading = true;
        services.api
            .StoreStripeToken(stripeResponse)
            .then(() => {
                this.handleCreateSelectSubscription();
            })
            .catch((e) => {
                this.message = e.message;
                this.isLoading = false;
            });
    };

    @action confirmSetupIntent = () => {
        return services.api
            .ConfirmSetupIntent()
            .then((response) => {
                return response.client_secret;
            })
            .catch((e) => {
                this.message = e.message;
            });
    };

    @action handleCreateSelectSubscription = () => {
        services.api
            .CreateSelectSubscription(this.selected.id)
            .then((res) => {
                if (res.success) {
                    clevertap.event.push('Subscribed To Select', {
                        Plan: this.selected.name,
                    });
                    setCompanyToLocalStorage(true);
                    this.view = this.PAGE_THANK_YOU;
                    this.success = true;
                } else {
                    this.success = false;
                    this.message = res.message;
                }
            })
            .catch((e) => {
                this.success = false;
                this.message = e.message;
                services.bannerEvents.showWarning(e.message);
            })
            .then(() => (this.isLoading = false));
    };
}

const store = new SelectPopupStore();
export default store;
