import React from 'react';
import cx from 'classnames';
import brandLogo from '../../core/brandLogo/MasterBrandLogo';
import { services } from '../../../services/index';
import intl from 'react-intl-universal';

const base = 'password-reset';

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            message: '',
            token: props.params.token,
            id: props.params.id,
            isBusy: false,
            successMessage: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
    }

    handleChange(event) {
        this.setState({ password: event.target.value });
    }

    handleSubmitPassword(event) {
        event.preventDefault();
        if (this.state.password && this.state.password.length > 5) {
            this.setState({ isBusy: true });
            const requestBody = {
                id: this.state.id,
                password: this.state.password,
            };
            services.api
                .UpdatePassword({ token: this.state.token, requestBody })
                .then((response) => {
                    this.setState({
                        successMessage: intl.get('resetPassword.success'),
                    });
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 3000);
                })
                .catch((response) => {
                    this.setState({ message: response.message });
                })
                .then(() => {
                    this.setState({ isBusy: false });
                });
        } else {
            this.setState({ message: intl.get('resetPassword.tooShort') });
        }
    }

    render() {
        // const
        const inputClasses = cx(
            'input input--bigger-on-mobile width--100 mtm',
            {
                'input--error': Boolean(this.state.message),
            }
        );

        const buttonClasses = cx(
            'button button--bigger-on-mobile button--medium button--success width--100 mtm',
            {
                'button--busy': Boolean(this.state.isBusy),
            }
        );

        return (
            <div className={`${base} `}>
                <div className={`${base}__container ptl`}>
                    <a href="/">
                        <brandLogo.PurpleLogo />
                    </a>
                    <h3 className={`${base}__title mtl`}>
                        {intl.get('resetPassword.title')}
                    </h3>
                    <hr className={`${base}__title-separator`} />
                    <br />
                    <form method="POST" onSubmit={this.handleSubmitPassword}>
                        <label htmlFor="input-password">
                            {intl.get('resetPassword.subtitle')}
                        </label>
                        <input
                            id="input-password"
                            autoFocus="true"
                            className={inputClasses}
                            placeholder="New password"
                            type="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                        <p className="text--red fs--small-medium pts">
                            {this.state.message}
                        </p>
                        <button className={buttonClasses}>
                            {intl.get('resetPassword.cta')}
                        </button>
                    </form>
                    <p className="text--primary fs--small-medium pts">
                        {this.state.successMessage}
                    </p>
                    <div className="hide--if-large show--if-small mbl" />
                    <div className="hide--if-large show--if-small mbm" />
                </div>
            </div>
        );
    }
}
