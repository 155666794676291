import React, { Component } from 'react';
import MenuItemUI from './MenuItemUI';

const base = 'menu-one-day';

export default class MenuSkeleton extends Component {
    render() {
        const skeletonItems = [...Array(10)];
        return (
            <div className={`${base}`}>
                <div className={`${base}__header`}>
                    <div className={`${base}__date-container`}>
                        <div style={{ marginBottom: '6px' }}>
                            <p
                                className={`${base}__date text--bolder skeleton`}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${base}__item-container active`}>
                    {skeletonItems.map((item, index) => (
                        <MenuItemUI showSkeleton={true} item={{}} key={index} />
                    ))}
                </div>
            </div>
        );
    }
}
