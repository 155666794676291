/* eslint-disable max-statements */
import { observable, computed, action } from 'mobx';
import XDate from 'xdate';
import { MAYBE } from '../services/types';
import { constructXDate } from '../services/dateTime';

export default class {
    @observable isOpening = false;
    @observable value = MAYBE.None;
    @observable inputValue = '';
    @observable monthOffset = 0;

    today = new XDate();

    constructor(value) {
        if (value) {
            this.value = MAYBE.Some(value);
        }
    }

    get xDate() {
        if (this.value === MAYBE.None) {
            return null;
        }

        return constructXDate(
            this.value.data.year,
            this.value.data.month,
            this.value.data.day
        );
    }

    @computed get viewingMonth() {
        if (this.value === MAYBE.None) {
            return new XDate().addMonths(this.monthOffset);
        } else {
            return this.xDate.clone().addMonths(this.monthOffset);
        }
    }

    @action handleDayClicked(date) {
        this.value = MAYBE.Some(date);
        this.isOpening = false;
    }

    @action showPicker() {
        this.isOpening = true;
        this.monthOffset = 0;
    }

    @action hidePicker() {
        this.isOpening = false;
    }
    @action showPrevMonth() {
        this.monthOffset = this.monthOffset - 1;
    }
    @action showNextMonth() {
        this.monthOffset = this.monthOffset + 1;
    }
    @action showPrevYear() {
        this.monthOffset = this.monthOffset - 12;
    }
    @action showNextYear() {
        this.monthOffset = this.monthOffset + 12;
    }

    getDaysForCurrentViewingMonth() {
        const viewingDays = [];
        const month = this.viewingMonth.getMonth();
        const year = this.viewingMonth.getFullYear();
        const nDays = XDate.getDaysInMonth(year, month);
        const prevMonth = this.viewingMonth.clone().addMonths(-1);
        const nDaysPrevMonth = XDate.getDaysInMonth(
            prevMonth.getFullYear(),
            prevMonth.getMonth()
        );
        const firstOfThisMonth = constructXDate(
            this.viewingMonth.getFullYear(),
            this.viewingMonth.getMonth(),
            1
        );
        let weekday = firstOfThisMonth.getDay();

        if (weekday === 0) {
            weekday = 7;
        }

        for (let i = weekday - 1, j = 0; i > 0; --i, ++j) {
            viewingDays[j] = {
                type: 'lastmonth',
                year: month === 0 ? year - 1 : year,
                month: month === 0 ? 11 : month - 1,
                day: nDaysPrevMonth - i + 1,
            };
        }
        for (let i = 0; i < nDays; ++i) {
            viewingDays[weekday + i - 1] = {
                type: 'thismonth',
                year: year,
                month: month,
                day: i + 1,
            };
        }
        for (let i = 0; viewingDays.length % 7 !== 0; ++i) {
            viewingDays.push({
                type: 'nextmonth',
                year: month === 11 ? year + 1 : year,
                month: month === 11 ? 0 : month + 1,
                day: i + 1,
            });
        }

        return viewingDays;
    }

    isDateEqual(dateObject, xDate) {
        return (
            dateObject.day === xDate.getDate() &&
            dateObject.month === xDate.getMonth() &&
            dateObject.year === xDate.getFullYear()
        );
    }

    isDateSelected(dateObject) {
        if (this.value === MAYBE.None) {
            return false;
        } else {
            return (
                dateObject.day === this.value.data.day &&
                dateObject.month === this.value.data.month &&
                dateObject.year === this.value.data.year
            );
        }
    }
}
