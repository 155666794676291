import React from 'react';
import Carousel from 'nuka-carousel';

const base = 'terminal-about';

const images = [
    'https://d3tjqo2jh935k9.cloudfront.net/images/static/about-slide-1.jpg',
    'https://d3tjqo2jh935k9.cloudfront.net/images/static/about-slide-2.jpg',
    'https://d3tjqo2jh935k9.cloudfront.net/images/static/about-slide-3.jpg',
];

export default class extends React.Component {
    render() {
        return (
            <div className={`${base}__slider`}>
                <Carousel
                    wrapAround={true}
                    autoplay={true}
                    autoplayInterval={5000}
                    initialSlideHeight={460}
                    fixedHeight={true}
                >
                    {images.map((image) => {
                        return (
                            <div
                                key={image}
                                className={`${base}__slider-item`}
                                style={{ backgroundImage: `url(${image})` }}
                            ></div>
                        );
                    })}
                </Carousel>
            </div>
        );
    }
}
