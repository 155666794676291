import React from 'react';
import { observer, inject } from 'mobx-react';

import intl from 'react-intl-universal';

import { RESOURCE } from '../../../services/types';

import BusyBox from '../../page/profilePage/BusyBox';
import Timeframe from './Timeframe';

const base = 'profile';

@inject('orderStatusStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.orderStatusStore.fetchTimeframes();
    }

    handleTimeframeClicked(timeframe) {
        this.props.orderStatusStore.updateTimeframe(timeframe);
    }

    hideChangeTimeframe() {
        this.props.orderStatusStore.isShowingChangeTimeframe = false;
    }

    render() {
        if (
            RESOURCE.Success.is(
                this.props.orderStatusStore.timeframesFetcher
            ) === false
        ) {
            return <BusyBox headerText="Change timeframe for this delivery" />;
        }

        const timeframes = this.props.orderStatusStore.timeframesFetcher.data;
        return (
            <div className={`${base}__box text--left`}>
                <div className={`${base}__box-header lh--125`}>
                    {intl.get('changeOrder.changeTimeframe')}
                </div>
                <div className="modal__close">
                    <button
                        className="button--nothing"
                        onClick={() => {
                            this.hideChangeTimeframe();
                        }}
                    >
                        <svg className="modal__close-icon">
                            <use xlinkHref="#svg--x-close" />
                        </svg>
                    </button>
                </div>
                <div className={`${base}__box-body p0`}>
                    {timeframes.map((frame, index) => {
                        return (
                            <Timeframe
                                key={frame.id}
                                timeframe={frame}
                                isDisabled={frame.open === false}
                                onTimeframeClicked={
                                    ::this.handleTimeframeClicked
                                }
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}
