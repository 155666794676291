import React from 'react';

import LoginPopup from '../../core/LoginPopup';
import SectionHero from './SectionHero';
import SectionDetails from './SectionDetails';
import { observer, inject } from 'mobx-react';
import SectionHowItWorks from './SectionHowItWorks';
import SectionSave from './SectionSave';
import SectionFAQ from './SectionFAQ';
import GoogleReviews from '../../core/GoogleReviews';
import HalalPopup from '../../core/HalalPopup';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

const base = 'about';

@inject('userStore')
@observer
export default class extends React.Component {
    render() {
        const isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
        const { showHalalPopup, setHideHalalPopup } = this.props.userStore;
        return (
            <div className={base}>
                <LoginPopup dismissable={true} showCloseIcon={true} />
                <SectionHero />
                <SectionDetails />
                <SectionHowItWorks />
                <SectionSave />
                <SectionFAQ />
                <GoogleReviews />
                {showHalalPopup && isSmallScreen && (
                    <HalalPopup
                        showModal={showHalalPopup}
                        handleClose={setHideHalalPopup}
                    />
                )}
            </div>
        );
    }
}
