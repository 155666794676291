import { httpGet, httpPost } from '../http';

export const GetCredits = () =>
    httpGet('/v3/rewards/get', { authType: 'Bearer' });

export const RedeemCode = (requestBody = { code: '' }) => {
    return httpPost('/v4/rewards/redeem', requestBody, { authType: 'Bearer' });
};

export const GetRewardRules = () =>
    httpGet('/v4/referrals/code', { authType: 'Bearer' });

export const GetAllRewards = () =>
    httpGet('/v4/rewards', { authType: 'Bearer' });

export const GetNextReward = () =>
    httpGet('/v4/rewards/next', { authType: 'Bearer' });
