import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { onDocumentIdle } from '../../../services/domUtils';

const base = 'terminal-splash-screen';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
  state = {
    language: localStorage.getItem('lang') || 'ms',
  };

  componentDidMount() {
    if (!this.props.isSmallScreen) {
      onDocumentIdle({
        seconds: 60,
        callback: this.props.onIdleTimeReached, //don't execute(change the lang) on mobile kiosk
        checkEverySeconds: 10,
      });
    }
  }

  handleOrderTypeClick = (orderType) => {
    const { terminalMenuStore, terminalCartStore } = this.props;
    terminalMenuStore.setSplashScreen(false);
    terminalMenuStore.setOrderType(orderType);

    const isEmpty = terminalCartStore.cartServices.isEmptyAt({
      date: terminalMenuStore.menuDate,
    });

    if (!isEmpty) {
      //this handles edge case, when user don't select order type on splash screen, flow:
      // we show splash screen after confirm order CTA  and before phone popup, after splash screen -> show summary
      terminalMenuStore.setShowSummary(true);
    }

    terminalMenuStore.fetchTerminalInfo().then(() => terminalMenuStore.fetchMenu());
  };

  checkMark = () => {
    return (
      <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.25293 5.66665L7.00048 12.4142L8.41469 11L1.66714 4.25244L0.25293 5.66665Z" fill="white" />
        <path d="M7.00015 12.4142L17.7477 1.66665L16.3335 0.252441L5.58594 11L7.00015 12.4142Z" fill="white" />
      </svg>
    );
  };

  handleSetLanguage = (language) => {
    const { terminalMenuStore } = this.props;
    const selectedLang = terminalMenuStore.getLanguage();
    if (selectedLang !== language) {
      this.setState({ language });
      terminalMenuStore.setLanguage(language);
    }
  };

  render() {
    const {
      terminalMenuStore: { ORDER_TYPES },
    } = this.props;
    const { language } = this.state;
    const isEnglishSelected = language === 'en';
    const isMalaySelected = language === 'ms';

    return (
      // terminalAuthStore.authed &&
      <div className={base}>
        <p className={`${base}__makan`}>Makan apa?</p>
        <p className={`${base}__reward_lucky_wheel`}>{intl.get('terminal.splashScreen.reward_lucky_wheel')}</p>
        <div className={`${base}__DI_TA`}>
          <div
            onClick={() => this.handleOrderTypeClick(ORDER_TYPES.DINE_IN)}
            className={`${base}__order_option dine_in`}
          >
            <img
              src={'https://image.popcontent.link/Dinein+two+people.png'}
              className={`${base}__order_option_img`}
              alt="dine-in image"
            />
            <p className={`${base}__order_option_text`}>{intl.get('terminal.splashScreen.dineIn')}</p>
          </div>
          <div
            onClick={() => this.handleOrderTypeClick(ORDER_TYPES.TAKE_AWAY)}
            className={`${base}__order_option takeaway`}
          >
            <img
              src={'https://image.popcontent.link/Takeaway+bg.png'}
              className={`${base}__order_option_img`}
              alt="takeaway image"
            />
            <p className={`${base}__order_option_text`}>{intl.get('terminal.splashScreen.takeaway')}</p>
          </div>
        </div>
        <div className={`${base}__language_selector`}>
          <button
            onClick={() => this.handleSetLanguage('ms')}
            className={`${base}__language ${isMalaySelected ? 'selected' : ''}`}
          >
            <p className="name">Bahasa Melayu</p>
            {isMalaySelected && this.checkMark()}
          </button>
          <button
            onClick={() => this.handleSetLanguage('en')}
            className={`${base}__language ${isEnglishSelected ? 'selected' : ''}`}
          >
            <p className="name">English</p>
            {isEnglishSelected && this.checkMark()}
          </button>
        </div>
      </div>
    );
  }
}
