import React from 'react';
import intl from 'react-intl-universal';
import { template } from 'lodash';
import config from '../../../configs/parameters';

const base = 'terminal-about';

export default class extends React.Component {
    render() {
        const brandName = config.brandName;

        return (
            <div className={`${base}__why`}>
                <div className={`${base}__why-img`} />
                <div className={`${base}__why-content`}>
                    <h5 className={`${base}__question`}>
                        {intl.get('about.why.question')}
                    </h5>
                    <div className="show--if-large hide--if-small">
                        <div
                            className={`${base}__separator--short separator separator--red mbl`}
                        />
                    </div>
                    <h2 className={`${base}__why-title lh--125`}>
                        {intl.get('about.why.title')}
                    </h2>
                    <center className="show--if-small hide--if-large">
                        <div
                            className={`${base}__separator separator separator--red mtm mbm`}
                        />
                    </center>
                    <p className={`${base}__why-subheading lh--150`}>
                        {template(intl.get('about.why.subheading'))({
                            brandName,
                        })}
                    </p>
                    <hr />
                </div>
            </div>
        );
    }
}
