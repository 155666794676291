import React from 'react';
import { services } from './index';
import { GetUrl } from './url';

export const getCanonicalTag = (path) => {
    const hostname = services.getParam('deploymentUrl');
    const url = GetUrl();

    if (path[0] === '/') {
        path = path.slice(1);
    }

    return <link rel="canonical" href={`${hostname}${url}/${path}`} />;
};
