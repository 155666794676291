import React from 'react';
import { inject, observer } from 'mobx-react';

import AddonItem from './AddonItem';

const base = 'terminal-mobile-addons-screen';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    selectUIView = (view) => {
        const {
            terminalMenuStore: { setAddonsView },
        } = this.props;
        setAddonsView(view);
    };

    handleCloseClick = () => {
        const {
            terminalMenuStore: { mobileScreens, setShowMobileScreen },
        } = this.props;
        setShowMobileScreen(mobileScreens.ITEM);
    };

    render() {
        const {
            terminalMenuStore: { addonTypes, addonsUIView },
            items,
        } = this.props;

        const itemsToShow = items
            .filter(
                (item) =>
                    item.item_type === 'Addons' &&
                    item.addon_category === addonsUIView &&
                    item.quantity_left > 0 &&
                    !item.title_bold.toLowerCase().includes('cutlery')
            )
            .sort((a, b) => a.order - b.order);

        return (
            <div className={`${base}`}>
                <svg
                    className={'close_btn'}
                    onClick={this.handleCloseClick}
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="1.45337"
                        y="0.0144653"
                        width="17.8442"
                        height="1.3587"
                        transform="rotate(45 1.45337 0.0144653)"
                        fill="#131415"
                    />
                    <rect
                        width="17.8442"
                        height="1.3587"
                        transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.1104 0.0144653)"
                        fill="#131415"
                    />
                </svg>

                <div className={`${base}__addon_types`}>
                    <div
                        onClick={() => this.selectUIView(addonTypes.SNACKS)}
                        className={`addon_type ${
                            addonsUIView === addonTypes.SNACKS
                                ? 'selected'
                                : 'not-selected'
                        }`}
                    >
                        <p className="name">Snacks</p>
                        <div className={`horizontal_line`}></div>
                    </div>
                    <div
                        onClick={() => this.selectUIView(addonTypes.DRINKS)}
                        className={`addon_type drinks ${
                            addonsUIView === addonTypes.DRINKS
                                ? 'selected'
                                : 'not-selected'
                        }`}
                    >
                        <p className="name drinks">Drinks</p>
                        <div className={`horizontal_line drinks_line`}></div>
                    </div>
                </div>
                <div className={`${base}__addon_items`}>
                    {itemsToShow &&
                        itemsToShow.map((addonItem) => (
                            <AddonItem item={addonItem} />
                        ))}
                </div>
            </div>
        );
    }
}
