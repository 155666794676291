import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import Carousel from 'nuka-carousel';
import { SMALL_SCREEN_MEDIA } from '../../services/constants';

const base = 'slider-banner';

@inject('menuListStore', 'orderStatusStore', 'userStore')
@observer
class SliderBanner extends Component {
    state = {
        slides: [],
        slideIndex: 0,
        length: 0,
    };

    componentDidMount = () => {
        const { menuListStore } = this.props;

        when(
            () => menuListStore.contentCards && menuListStore.contentCards.list,
            () => {
                const isSmallScreen =
                    window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
                this.setState({
                    length: menuListStore.contentCards.list.filter((slide) =>
                        isSmallScreen ? slide.image : slide.web_image
                    ).length,
                    slides: menuListStore.contentCards.list.filter((slide) =>
                        isSmallScreen ? slide.image : slide.web_image
                    ),
                });
            }
        );
    };

    _handleLoadImage = () => {
        this.carousel.setDimensions();
    };

    render() {
        const { slides, slideIndex, length } = this.state;
        const { orderStatusStore, userStore } = this.props;
        const isLoading =
            orderStatusStore.statusFetcher &&
            !orderStatusStore.statusFetcher.data;
        const showBanner =
            !isLoading &&
            !orderStatusStore.deliveryStatus.data &&
            !userStore.isSelect;
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return slides.length && showBanner ? (
            <div className={`${base} ${isMobile ? 'container' : ''}`}>
                <Carousel
                    ref={(c) => (this.carousel = c)}
                    slideIndex={slideIndex}
                    afterSlide={(slideIndex) => this.setState({ slideIndex })}
                    heightMode="current"
                    slideWidth={1}
                    renderCenterLeftControls={({ previousSlide }) =>
                        slideIndex === 0 ? null : (
                            <button
                                onClick={previousSlide}
                                className={`${base}__nav-btn`}
                            >
                                <svg className={`${base}__nav-btn--prev`}>
                                    <use xlinkHref="#svg--chevron-left" />
                                </svg>
                            </button>
                        )
                    }
                    renderCenterRightControls={({ nextSlide }) =>
                        slideIndex === length - 1 ? null : (
                            <button
                                onClick={nextSlide}
                                className={`${base}__nav-btn`}
                            >
                                <svg className={`${base}__nav-btn--next`}>
                                    <use xlinkHref="#svg--chevron-right" />
                                </svg>
                            </button>
                        )
                    }
                    renderBottomCenterControls={() => null}
                >
                    {slides.map((slide, i) => (
                        <a
                            key={i}
                            className={`${base}__slide-link`}
                            href={slide.has_cta ? slide.cta_url : '#'}
                        >
                            <img
                                className={`${base}__slide-image`}
                                src={isMobile ? slide.image : slide.web_image}
                                alt=""
                                onLoad={this._handleLoadImage}
                            />
                        </a>
                    ))}
                </Carousel>
            </div>
        ) : (
            <div className={`${base} display--none-if-empty`} />
        );
    }
}

export default SliderBanner;
