import React from 'react';
import { observable, computed } from 'mobx';
import get from 'lodash/get';

import { fromTimestamp, getDateDisplay } from '../../services/dateTime';
import { currency } from '../../services';
import {
    DELIVERY_STATUS_PENDING,
    DELIVERY_STATUS_INTRANSIT,
    DELIVERY_STATUS_ARRIVED,
    DELIVERY_STATUS_DELIVERED,
    DELIVERY_STATUS_DELAYED,
    DELIVERY_STATUS_CANCELLED,
} from '../../services/constants';

export default class {
    @observable.ref deliveryInfo = null;

    constructor(deliveryInfo) {
        this.deliveryInfo = deliveryInfo;
    }

    @computed get orderNumber() {
        return get(this.deliveryInfo, ['order_id'], '');
    }
    @computed get menuType() {
        return get(this.deliveryInfo, ['menu_type'], '');
    }
    @computed get address() {
        return get(this.deliveryInfo, ['address', 'display_address'], '');
    }
    @computed get deliveryFee() {
        return currency.localCurrency(
            get(this.deliveryInfo, ['delivery_charge'])
        );
    }
    @computed get serviceCharge() {
        return currency.localCurrency(
            get(this.deliveryInfo, ['service_charge'])
        );
    }
    @computed get orderSummaryLines() {
        return get(this.deliveryInfo, ['price_summary', 'rows']);
    }
    @computed get credits() {
        return currency.localCurrency(get(this.deliveryInfo, ['redeem_point']));
    }
    @computed get hasAddons() {
        return this.get(this.deliveryInfo, ['addon_price'], 0) !== 0;
    }
    @computed get status() {
        return get(this.deliveryInfo, ['status']);
    }
    @computed get change_allowed() {
        return get(this.deliveryInfo, ['change_allowed']);
    }
    @computed get cancelled_allowed() {
        return get(this.deliveryInfo, ['cancelled_allowed']);
    }

    @computed get total() {
        return currency.localCurrency(get(this.deliveryInfo, ['net_total']));
    }

    @computed get name() {
        return (
            get(this.deliveryInfo, ['firstname'], '') +
            ' ' +
            get(this.deliveryInfo, ['lastname'], '')
        );
    }

    @computed get itemLines() {
        const items = get(this.deliveryInfo, ['menu_item']);

        return items.map((item) => {
            let price = currency.localCurrency(item.price);

            return {
                id: item.id,
                quantity: 'x' + item.quantity,
                name: item.name,
                price: price,
            };
        });
    }

    @computed get orderDateAsXDate() {
        return fromTimestamp(get(this.deliveryInfo, ['time_slot_start']));
    }

    @computed get orderDate() {
        const date = get(this.deliveryInfo, ['time_slot_start']);

        return date ? fromTimestamp(date).toString('dddd dd/MM/yy') : '';
    }

    @computed get shortOrderDate() {
        const date = get(this.deliveryInfo, ['time_slot_start']);

        return date ? fromTimestamp(date).toString('dddd dd/MM') : '';
    }

    @computed get orderCreatedDate() {
        const date = get(this.deliveryInfo, ['created_date']);

        return date ? fromTimestamp(date).toString('dddd dd/MM/yy') : '';
    }

    @computed get deliveryLabel() {
        const status = get(this.deliveryInfo, ['status']);

        switch (status) {
            case DELIVERY_STATUS_PENDING:
            case DELIVERY_STATUS_INTRANSIT:
                return 'Delivery on';
            case DELIVERY_STATUS_DELAYED:
                return 'Apologies, your order is delayed';
            case DELIVERY_STATUS_ARRIVED:
            case DELIVERY_STATUS_DELIVERED:
            case DELIVERY_STATUS_CANCELLED:
                return 'Delivered on';
            default:
                return '';
        }
    }

    @computed get deliveryDate() {
        const eta = get(this.deliveryInfo, ['eta']);
        const status = get(this.deliveryInfo, ['status']);
        const timeSlotStart = get(this.deliveryInfo, ['time_slot_start']);
        const timeSlotEnd = get(this.deliveryInfo, ['time_slot_end']);

        switch (status) {
            case DELIVERY_STATUS_PENDING:
            case DELIVERY_STATUS_INTRANSIT:
                return (
                    fromTimestamp(eta).toString('dddd dd/MM/yy') +
                    ' ' +
                    fromTimestamp(timeSlotStart).toString('h.mm tt') +
                    ' - ' +
                    fromTimestamp(timeSlotEnd).toString('h.mm tt')
                );
            case DELIVERY_STATUS_DELAYED:
                return (
                    'ETA ' +
                    fromTimestamp(eta).toString('dddd dd/MM/yy h.mm tt')
                );
            case DELIVERY_STATUS_ARRIVED:
            case DELIVERY_STATUS_DELIVERED:
                return fromTimestamp(eta).toString('dddd dd/MM/yy h.mm tt');
            case DELIVERY_STATUS_CANCELLED:
                return 'Cancelled';
            default:
                return '';
        }
    }

    @computed get timeframe() {
        const timeSlotStart = get(this.deliveryInfo, ['time_slot_start']);
        const timeSlotEnd = get(this.deliveryInfo, ['time_slot_end']);

        return (
            fromTimestamp(timeSlotStart).toString('h.mm tt') +
            ' - ' +
            fromTimestamp(timeSlotEnd).toString('h.mm tt')
        );
    }

    @computed get cutofftime() {
        const delivery_date = get(this.deliveryInfo, ['delivery_date']);
        const day = delivery_date
            ? getDateDisplay(fromTimestamp(delivery_date))
            : '';
        const delivery_time = get(this.deliveryInfo, ['change_cut_off']);
        const time = delivery_time
            ? ' ' + delivery_time.toString('dddd h.mm tt')
            : '';

        return day + time;
    }

    @computed get subTotal() {
        return currency.localCurrency(get(this.deliveryInfo, ['gross_total']));
    }

    @computed get paymentMethod() {
        const paymentMethod = get(this.deliveryInfo, ['payment_method'], '');
        return paymentMethod;
    }
}
