import React from 'react';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';

import CountDownClock from '../CountDownClock';
import { VERIFICATION_STATUS } from '../../../services/types';

@inject('completeSignUpStore')
@observer
export default class extends React.Component {
    render() {
        const { completeSignUpStore } = this.props;
        const { verifier, timerRunning } = completeSignUpStore;
        const verifyButtonClasses = cx('button button--success width--100', {
            'button--busy': verifier === VERIFICATION_STATUS.CodeRequesting,
            'button--disabled': timerRunning === true,
        });

        return (
            <button
                className={verifyButtonClasses}
                type="button"
                onClick={this.props.onClick}
            >
                {completeSignUpStore.verifyButtonText}
                {timerRunning === true ? (
                    <CountDownClock
                        from={completeSignUpStore.retryTime}
                        displayFn={(tick) => ' (' + tick + ')'}
                        onFinish={::completeSignUpStore.allowResendCode}
                    />
                ) : null}
            </button>
        );
    }
}
