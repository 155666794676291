import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import BasePage from './basePage';
import TerminalLoginRoot from '../components/TerminalLoginRoot';
import { getGTMScript } from '../components/core/ScriptTag';
import terminalAuthStore from '../store/terminalAuthStore';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';
import { when } from 'mobx';
import menuHeaderStore from '../store/menuHeaderStore';

const pageTitle = services.getTitle('terminalLogin');

@observer
export default class extends Component {
    state = { redirect: false };
    componentDidMount() {
        terminalAuthStore.checkAuth();
        if (terminalAuthStore.authed === false) {
            document.body.classList.add('terminal-login');
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('terminal-login');
    }

    render() {
        const location = this.props.location;
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: 'Terminal',
            lpType: '',
            lpVersion: '',
        });

        if (this.state.redirect) {
            if (location.state) {
                return <Redirect to={location.state.from.pathname} />;
            } else {
                return <Redirect to="/terminal" />;
            }
        }

        if (terminalAuthStore.authed) {
            return <Redirect to="/terminal" />;
        }

        return (
            terminalAuthStore.authed === false && (
                <Fragment>
                    <Helmet>
                        <title>{pageTitle}</title>
                        {localizationTags(`terminal-login`).map((tag) => tag)}
                        {getCanonicalTag(`terminal-login`)}
                        <script type="text/javascript">{GTM}</script>
                    </Helmet>
                    <BasePage />
                    <TerminalLoginRoot />
                </Fragment>
            )
        );
    }
}
