/**
 * Runs any defined data migrations on the AsyncStorage. This way is preferred to changing any logic to accommodate
 * legacy values stored in storage.
 * @returns {Promise<void>}
 */
/* eslint-disable no-await-in-loop */
export const runDataMigrations = () => {
    console.info('[INFO] Running data migrations ...');
    const lastMigration = parseInt(
        localStorage.getItem('latestMigration') || 0
    );
    let lastMigrationRunning = null;
    dataMigrations.forEach((dataMigration) => {
        if (dataMigration.id > lastMigration) {
            console.info(
                `[INFO] Running migration ${dataMigration.id}: ${dataMigration.description}`
            );
            dataMigration.run();
            lastMigrationRunning = dataMigration.id;
        } else {
            console.debug(
                `[DEBUG] Ignoring migration ${dataMigration.id} since it has been executed before.`
            );
        }
    });

    if (lastMigrationRunning != null) {
        localStorage.setItem('latestMigration', lastMigrationRunning);
    }

    console.info('[INFO] Finished running data migrations.');
};

/**
 * id should be strictly increasing (+1 the latest). Description is only used for understanding and logging. The run function
 * runs the migration and returns a promise.
 * @type {{id: number, description: string, run: run}[]}
 */
const dataMigrations = [
    {
        id: 1,
        description: 'Changing language flag from my to ms (my = Burmese)',
        run: () => {
            const language = localStorage.getItem('lang');
            if (language === 'my') {
                localStorage.setItem('lang', 'ms');
            }
        },
    },
];

export const setPartnerIntegrationData = (partnerIntegration) =>
    sessionStorage.setItem(
        'partner_integration',
        JSON.stringify(partnerIntegration)
    );

export const getPartnerIntegrationData = () =>
    JSON.parse(sessionStorage.getItem('partner_integration'));
