import React from 'react';
import intl from 'react-intl-universal';
import Modal from './Modal';

export default function HalalPopup({ showModal, handleClose }) {
    const base = 'halal-popup';
    return (
        <Modal
            showModal={showModal}
            showCloseIcon={true}
            showBackdropOnMobile={true}
            onBackdropClicked={handleClose}
            onCloseClicked={handleClose}
            closeButtonClasses={'border--rounded '}
            contentClasses={'border--rounded'}
            manualStyles={{ borderRadius: '5px' }}
        >
            <div className={`${base} pm border--rounded`}>
                <p className={`${base}__content`}>
                    {intl.get('dish.halal_description')}
                </p>
            </div>
        </Modal>
    );
}
