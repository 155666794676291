import React from 'react';
import { inject, observer } from 'mobx-react';

import {
    OFFICE_ADDRESS,
    HOME_ADDRESS,
    SMALL_SCREEN_MEDIA,
} from '../../../../services/constants';
import intl from 'react-intl-universal';

const base = 'address-type';

@inject('deliveryAddressStore')
@observer
export default class extends React.Component {
    handleSetAddressType = (type) => {
        this.props.deliveryAddressStore.setAddressType(type);
    };

    render() {
        const store = this.props.deliveryAddressStore;
        const selected = store.newAddress.addressType;

        return (
            <div className={`${base}__wrapper`}>
                <div
                    className={`${base}__cell-container`}
                    onClick={() => this.handleSetAddressType(OFFICE_ADDRESS)}
                >
                    <div
                        className={`${base}__cell ${
                            selected === OFFICE_ADDRESS ? 'selected' : ''
                        }`}
                    >
                        <div className={`${base}__building-icon-office`} />
                        <p className={`${base}__title fs--small-medium`}>
                            {window.matchMedia(SMALL_SCREEN_MEDIA).matches
                                ? intl.get(
                                      'shoppingCart.deliveryAddress.officeCondo'
                                  )
                                : intl.get(
                                      'shoppingCart.deliveryAddress.officeCondoShoplot'
                                  )}
                        </p>
                        <div></div>
                    </div>
                </div>
                <div
                    className={`${base}__cell-container`}
                    onClick={() => this.handleSetAddressType(HOME_ADDRESS)}
                >
                    <div
                        className={`${base}__cell ${
                            selected === HOME_ADDRESS ? 'selected' : ''
                        }`}
                    >
                        <div className={`${base}__building-icon-home`} />
                        <p className={`${base}__title fs--small-medium`}>
                            {intl.get(
                                'shoppingCart.deliveryAddress.houseLanded'
                            )}
                        </p>
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }
}
