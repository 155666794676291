import React, { Component } from 'react';

const base = 'new-counter';

export default class NewCounter extends Component {
    render() {
        const { itemCount } = this.props;
        return (
            <div className={base}>
                <div className={`${base}__count-container`}>
                    <span className={`${base}__count text--bolder`}>
                        {itemCount}
                    </span>
                </div>
            </div>
        );
    }
}
