import React from 'react';
import { inject, observer } from 'mobx-react';
import brandLogo from '../brandLogo/MasterBrandLogo';

const base = 'shopping-cart-header';

@inject('headerStore')
@observer
export default class extends React.Component {
    renderBackLink() {
        const { headerStore } = this.props;
        const { backLink, showBackLink } = headerStore;

        if (showBackLink) {
            return (
                <a
                    className="link--no-underline text--black fs--small-medium mrl text--bold"
                    href={backLink.href}
                >
                    <svg
                        className={`${base}__back-link-icon iconmoon-icon iconmoon-icon--middle text--primary mrs`}
                    >
                        <use xlinkHref="#icon-chevron-thin-left" />
                    </svg>
                    <span className={`${base}__back-link-text`}>
                        {backLink.text}
                    </span>
                </a>
            );
        }

        return null;
    }

    render() {
        return (
            <div className={base}>
                <div className="display--flex flex-align-items-center container">
                    <div className="flex-1-only">{this.renderBackLink()}</div>
                    <div className="flex-none ma">
                        <a href="/">
                            <brandLogo.PurpleLogo className={`${base}__logo`} />
                        </a>
                    </div>
                    <div className="flex-1-only" />
                </div>
            </div>
        );
    }
}
