import React from 'react';
import BaseLayout from './core/baseLayout/Master_BaseLayout';
import WalletFooter from './page/walletPage/WalletFooter';
import WalletHeader from './core/headers/WalletHeader';
import WalletStatusBanner from './page/walletPage/WalletStatusBanner';
import SectionPackages from './page/walletPage/SectionPackages';
import SectionFAQ from './page/walletPage/SectionFAQ';
import HeaderStore from '../store/walletHeaderStore';

import { inject, observer } from 'mobx-react';

const { clevertap } = window;

@inject('walletStatusStore', 'walletStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);
        const fallback = '/wallet';

        const backLink = props.backLink ? props.backLink : '/menu';
        this.headerStore = new HeaderStore({
            backLink: props.redirected ? backLink : fallback,
            walletPageBackLink: backLink,
        });
    }

    componentDidMount() {
        const { walletStatusStore } = this.props;

        clevertap.event.push('View Wallet Packages Page');
        walletStatusStore.getWalletStatus();
    }

    renderHeader = () => <WalletHeader headerStore={this.headerStore} />;

    render() {
        const { walletStore } = this.props;

        return (
            <BaseLayout.Page HeaderComponent={this.renderHeader}>
                <main>
                    <WalletStatusBanner />
                    <SectionPackages
                        packages={walletStore.enabledWalletPackages}
                    />
                    <SectionFAQ />
                </main>
                <WalletFooter />
            </BaseLayout.Page>
        );
    }
}
