import { inject, observer } from 'mobx-react';
import { Component, Fragment } from 'react';
import { when } from 'mobx';
import { PHONE, SIGNUP, SMALL_SCREEN_MEDIA } from '../../../services/constants';
import SectionMobileNumberUI from './SectionMobileNumberUI';
import { VERIFICATION_STATUS } from '../../../services/types';
import { currency, services } from '../../../services';
import menuHeaderStore from '../../../store/menuHeaderStore';
import template from 'lodash/template';
import intl from 'react-intl-universal';
import { cities, GetCity } from '../../../services/city';
import React from 'react';
import UserAvatar from '../../core/UserAvatar';

const base = 'section-hero';

@inject('loginPopupStore')
@observer
export default class ReferralHero extends Component {
    state = { isBusy: false };

    componentDidMount() {
        const { loginPopupStore } = this.props;

        when(
            () => loginPopupStore.validNumber,
            () => {
                loginPopupStore.showVariantVerifyNumber();
            }
        );

        when(
            () => loginPopupStore.message,
            () => this.setState({ isBusy: false })
        );

        document.addEventListener('keydown', this.onKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPress);
    }

    handleVerifyNumber = () => {
        const { loginPopupStore } = this.props;
        loginPopupStore.checkUsername(PHONE);
    };

    handleSubmit = () => {
        const { loginPopupStore } = this.props;
        loginPopupStore.message = '';
        this.setState({ isBusy: true });

        if (loginPopupStore.comingFrom === SIGNUP) {
            loginPopupStore.completeSignup(PHONE);
        } else {
            loginPopupStore.doLogin(PHONE);
        }
    };

    onKeyPress = (e) => {
        const { loginPopupStore } = this.props;

        if (e.key === 'Enter') {
            if (loginPopupStore.referralVariantView === 'MobileNumberView') {
                this.handleVerifyNumber();
            } else {
                this.handleSubmit();
            }
        }
    };

    renderMobileNumberView = () => {
        const { loginPopupStore } = this.props;
        const referral = loginPopupStore.referral();
        const buttonText = referral.rewardIsPercentage
            ? `${referral.rewardUsages} x ${referral.reward * 100}`
            : currency.localCurrency(referral.reward);

        return (
            <SectionMobileNumberUI
                name="phoneNumber"
                mobileNumberInput={true}
                autoFocus="true"
                countryCode={loginPopupStore.countryCode}
                value={loginPopupStore.phoneNumber}
                base={base}
                inputClass={`${base}__referral-input`}
                placeholder="Mobile number"
                type="tel"
                required="true"
                onChange={loginPopupStore.handleChange}
                errorMessage={loginPopupStore.message}
                onClick={this.handleVerifyNumber}
                loading={
                    loginPopupStore.verifier ===
                    VERIFICATION_STATUS.CodeRequesting
                }
                buttonText={`Claim ${buttonText}`}
                headerText="Enter your mobile number"
            />
        );
    };

    renderMobileNumberVerifyView = () => {
        const { loginPopupStore } = this.props;

        return (
            <SectionMobileNumberUI
                name="verificationCode"
                autoFocus="true"
                value={loginPopupStore.verificationCode}
                base={base}
                inputClass={`${base}__referral-input`}
                placeholder="Verification code"
                type="number"
                required="true"
                onChange={loginPopupStore.handleChange}
                errorMessage={loginPopupStore.message}
                onClick={this.handleSubmit}
                loading={this.state.isBusy && !loginPopupStore.message}
                buttonText="Confirm"
                headerText={`Last step! Enter the code that we sent to ${loginPopupStore.mobileNumber}`}
            />
        );
    };

    openLoginPopup = () => {
        this.props.loginPopupStore.isShowing = true;
    };

    render() {
        const { loginPopupStore } = this.props;
        const referral = loginPopupStore.referral();
        const base = 'section-hero';
        const brandName = services.getParam('brandName');

        return (
            <div
                className={`${base} referral`}
                id="sectionHero"
                style={{ marginTop: menuHeaderStore.headerHeight + 'px' }}
            >
                {/*background images*/}
                <div className={`${base}__image-overlay bg-referral show`} />
                <div
                    className={`${base}__container container`}
                    id="sectionHeroContainer"
                    style={{
                        paddingTop:
                            !window.matchMedia(SMALL_SCREEN_MEDIA).matches &&
                            '80px',
                        paddingBottom:
                            !window.matchMedia(SMALL_SCREEN_MEDIA).matches &&
                            '80px',
                    }}
                >
                    {referral && (
                        <div
                            className={`${base}__referral-intro variant-two`}
                            style={{
                                marginTop: window.matchMedia(SMALL_SCREEN_MEDIA)
                                    .matches
                                    ? '60px'
                                    : '',
                            }}
                        >
                            <div className={`${base}__intro-text`}>
                                <h1
                                    className={`${base}__referral-header text--bolder text--black text--left`}
                                >
                                    {template(
                                        intl.get(
                                            'onboarding.sectionReferralHero.titleLine1'
                                        )
                                    )({ name: referral.referring_user })}
                                    {!window.matchMedia(SMALL_SCREEN_MEDIA)
                                        .matches ? (
                                        <br />
                                    ) : (
                                        ' '
                                    )}
                                    {template(
                                        intl.get(
                                            'onboarding.sectionReferralHero.titleLine2'
                                        )
                                    )({
                                        amount: `${services.getParam(
                                            'currency'
                                        )}${referral.reward}`,
                                        brandName,
                                    })}
                                </h1>
                            </div>
                            <div
                                className={`${base}__subtitle referral text--black text--left`}
                            >
                                {template(
                                    intl.get(
                                        'onboarding.sectionReferralHero.subtitleLine1'
                                    )
                                )({ brandName })}{' '}
                                {!window.matchMedia(SMALL_SCREEN_MEDIA)
                                    .matches && <br />}
                                {intl.get(
                                    'onboarding.sectionReferralHero.subtitleLine2'
                                )}{' '}
                                {!window.matchMedia(SMALL_SCREEN_MEDIA)
                                    .matches && <br />}
                                {intl.get(
                                    'onboarding.sectionReferralHero.subtitleLine3'
                                )}
                            </div>
                            {/*Variant 2*/}
                            <Fragment>
                                {loginPopupStore.referralVariantView ===
                                    'MobileNumberView' &&
                                    this.renderMobileNumberView()}
                                {loginPopupStore.referralVariantView ===
                                    'MobileNumberVerifyView' &&
                                    this.renderMobileNumberVerifyView()}
                            </Fragment>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
