import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import AddonItemCombo from './AddonItemCombo';

const base = 'terminal-addon-items-popup';

@inject('terminalMenuStore')
@inject('terminalCartStore')
@observer
export default class extends Component {
    closeAddonPopup = () => {
        const { terminalMenuStore, terminalCartStore } = this.props;
        terminalCartStore.getTotalPrice();
        terminalMenuStore.setShowPopup('');
        terminalMenuStore.pinValidation = false;
    };

    addAddonItemToCart = (item) => {
        const { terminalCartStore } = this.props;
        terminalCartStore.addToCart(item, {
            edit: false,
            isValueMealItem: true,
        });
        this.closeAddonPopup();
    };

    render() {
        const {
            items,
            terminalMenuStore: { cheapestAddonItemPrice },
        } = this.props;
        const savingAmount = cheapestAddonItemPrice.toFixed(0);
        return (
            <div className={`${base}`}>
                <div className={`${base}__ipad_header`}>
                    <p className={`addon-text`}>
                        {intl.get('terminal.popupAddonsCombo.title')}
                    </p>
                    {items && items.length > 0 && (
                        <div className={`save-text-container`}>
                            <p>
                                {' '}
                                <span className="save-text">
                                    {intl.get(
                                        'terminal.popupAddonsCombo.saveText'
                                    )}
                                </span>{' '}
                                <br />{' '}
                                <span className="rm-price">
                                    RM{savingAmount}!
                                </span>
                            </p>
                        </div>
                    )}
                </div>
                <div className={`${base}__mobile_header`}>
                    {items && items.length > 0 && (
                        <div className={`save-text-container`}>
                            <p className="save-text">
                                {intl.get('terminal.popupAddonsCombo.saveText')}
                            </p>
                            <p className="rm-price">RM{savingAmount}!</p>
                        </div>
                    )}
                    <div className={`${base}__text_close`}>
                        <p className={`addon-text`}>
                            {intl.get('terminal.popupAddonsCombo.title')}
                        </p>

                        <svg
                            className={`${base}__close_btn`}
                            onClick={this.closeAddonPopup}
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="1.45337"
                                y="0.0144653"
                                width="17.8442"
                                height="1.3587"
                                transform="rotate(45 1.45337 0.0144653)"
                                fill="#131415"
                            />
                            <rect
                                width="17.8442"
                                height="1.3587"
                                transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.1104 0.0144653)"
                                fill="#131415"
                            />
                        </svg>
                    </div>
                </div>
                <div className={`${base}__combo-items`}>
                    {items &&
                        items.length > 0 &&
                        items.map((item) => (
                            <AddonItemCombo
                                className="item"
                                key={item.today_menu_id}
                                item={item}
                                cheapestAddonItemPrice={cheapestAddonItemPrice}
                                addAddonItemToCart={(item) =>
                                    this.addAddonItemToCart(item)
                                }
                            />
                        ))}
                    <div className="not-select-item">
                        <div
                            className={`item-container cursor-pointer`}
                            onClick={this.closeAddonPopup}
                        >
                            <svg
                                width="46"
                                height="45"
                                viewBox="0 0 46 45"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M25.5413 22.5001L45.4737 3.00087C46.1754 2.31436 46.1754 1.20131 45.4737 0.514885C44.7719 -0.17154 43.6341 -0.171628 42.9324 0.514885L23 20.0141L3.06755 0.514885C2.36578 -0.171628 1.228 -0.171628 0.526326 0.514885C-0.175352 1.2014 -0.175442 2.31444 0.526326 3.00087L20.4587 22.5L0.526326 41.9992C-0.175442 42.6858 -0.175442 43.7988 0.526326 44.4852C0.877164 44.8284 1.33707 45 1.79698 45C2.25689 45 2.71671 44.8284 3.06764 44.4852L23 24.9861L42.9324 44.4852C43.2832 44.8284 43.7431 45 44.203 45C44.6629 45 45.1227 44.8284 45.4737 44.4852C46.1754 43.7987 46.1754 42.6857 45.4737 41.9992L25.5413 22.5001Z"
                                    fill="#B0B2B3"
                                />
                            </svg>
                        </div>
                        <p className="text">No drink</p>
                    </div>
                </div>
            </div>
        );
    }
}
