import Bacon from 'baconjs';
import { Maybe } from 'monet';
import { parseUA } from '../index';

const isMobileLandscape = () => {
    const isLandscape = window.matchMedia('(orientation: landscape)').matches;
    const isMobile = Maybe.fromNull(parseUA(window.navigator.userAgent))
        .map((parseResult) => parseResult.device)
        .chain((device) => Maybe.fromNull(device.type))
        .map((type) => type === 'tablet' || type === 'mobile')
        .orSome(false);

    return isLandscape && isMobile;
};

export default () => {
    const initialStream$ = Bacon.once(isMobileLandscape());

    return Bacon.fromEvent(window, 'orientationchange')
        .map(isMobileLandscape)
        .merge(initialStream$)
        .skipDuplicates();
};
