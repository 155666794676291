import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SMALL_SCREEN_MEDIA } from '../../services/constants';

const base = 'user-avatar';
@inject('loginPopupStore')
@observer
export default class UserAvatar extends Component {
    render() {
        const { loginPopupStore, marginBottom, marginTop } = this.props;

        return loginPopupStore.avatarUrl ? (
            <div
                className={`${base}__avatar-container`}
                style={{
                    marginBottom: marginBottom + 'px',
                    marginTop:
                        window.matchMedia(SMALL_SCREEN_MEDIA).matches &&
                        marginTop
                            ? marginTop + 'px'
                            : '',
                }}
            >
                <div
                    className={`${base}__avatar`}
                    style={{ background: `url(${loginPopupStore.avatarUrl})` }}
                />
            </div>
        ) : (
            <div />
        );
    }
}
