import React from 'react';
import { inject, observer } from 'mobx-react';
import { IS_1200_OR_LESS } from '../../../services/constants';
import Price from '../../core/Price';

@inject(
    'menuHeaderStore',
    'userStore',
    'cartStore',
    'userStore',
    'rewardsStore'
)
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isHovering: false };
    }

    handleMouseEnter = () => {
        const canHover = !window.matchMedia(IS_1200_OR_LESS).matches;

        if (canHover) {
            this.setState({ isHovering: true });
        }
    };

    handleMouseLeave = () => {
        const canHover = !window.matchMedia(IS_1200_OR_LESS).matches;

        if (canHover) {
            this.setState({ isHovering: false });
        }
    };

    setNode(node) {
        this.rootNode = node;
    }

    renderPrice() {
        const { discountedPrice, displayDiscount, price, text } = this.props;

        if (displayDiscount) {
            return (
                <span>
                    {this.props.text} • <wbr />
                    <Price price={price} strikethrough />{' '}
                    <Price price={discountedPrice} />
                </span>
            );
        } else {
            return (
                <span>
                    {text} • <wbr />
                    <Price price={price} />
                </span>
            );
        }
    }

    render() {
        const { customClass, isTerminal } = this.props;
        const base = 'add-to-cart-button';

        return (
            <button
                className={`${base}__button ${customClass} ${
                    this.state.isHovering ? 'hover' : ''
                }`}
                onClick={this.props.onClick}
                ref={::this.setNode}
                onMouseOver={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {isTerminal ? this.renderPrice() : this.props.text}
            </button>
        );
    }
}
