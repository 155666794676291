import React from 'react';
import { inject, observer } from 'mobx-react';

import intl from 'react-intl-universal';

import { CircleLoader } from '../../core/Loading';

import { RESOURCE } from '../../../services/types';

@inject('orderStatusStore')
@observer
export default class extends React.Component {
    render() {
        const { orderStatusStore } = this.props;

        if (orderStatusStore.cancellingOrder === RESOURCE.Loading) {
            return (
                <div className="pxl">
                    <CircleLoader />
                </div>
            );
        }
        return (
            <div className="pm text--center fs--small-medium">
                <p className="mm text--bolder fs--medium">
                    {intl.get('changeOrder.popupCancel.title')}
                </p>
                <p>{intl.get('changeOrder.popupCancel.subtitle')}</p>
                <button
                    className="button button--large button--success width--100 mtm mbm"
                    onClick={::orderStatusStore.setShowPopup(false)}
                >
                    <p className="fs--medium">
                        {intl.get('changeOrder.popupCancel.keepOrder')}
                    </p>
                </button>
                <p className="text--primary cursor-pointer">
                    <span
                        onClick={() => {
                            orderStatusStore.cancelOrder();
                        }}
                    >
                        {intl.get('changeOrder.popupCancel.cancelOrder')}
                    </span>
                </p>
            </div>
        );
    }
}
