import React, { Component } from 'react';
import PopupUI from '../PopupUI';
import { inject, observer } from 'mobx-react';

@inject('headerStore', 'store')
@observer
export default class BoostPopup extends Component {
    handleNavigate = () => {
        const { store, headerStore } = this.props;
        if (store.boostDeepLink) {
            store.setView(store.PAYMENT_IN_PROGRESS_VIEW);
            store.isBusy = true;
        } else {
            store.placeOrderWithOnlineBanking();
        }
        headerStore.setShowBackLink(false);
        store.openBoostPopup = false;
    };

    handleClosePopup = () => {
        const { store } = this.props;
        if (store.boostDeepLink) {
            store.cancelOrder();
        }
        store.openBoostPopup = false;
        store.isBusy = false;
    };

    render() {
        const { store } = this.props;

        return (
            <PopupUI
                titleLine1="You will be redirected to Boost."
                titleLine2="Please return to this window after payment"
                onClickYes={this.handleNavigate}
                link={store.boostDeepLink}
                onClickNo={this.handleClosePopup}
                yesCta="Proceed to Boost"
                noCta="Change payment method"
            />
        );
    }
}
