import React from 'react';
import intl from 'react-intl-universal';
import { template } from 'lodash';
import config from '../../../configs/parameters';

const base = 'terminal-about';

export default class extends React.Component {
    render() {
        const brandName = config.brandName;

        return (
            <div className={`${base}__who`}>
                <h5 className={`${base}__question`}>
                    {intl.get('about.who.question')}
                </h5>
                <div className="show--if-large hide--if-small">
                    <div
                        className={`${base}__separator--short separator separator--red mbl`}
                    />
                </div>
                <h2 className={`${base}__who-title lh--125`}>
                    {intl.get('about.who.title')}
                </h2>
                <center className="hide--if-large show--if-small">
                    <div
                        className={`${base}__separator separator separator--red mtm mbm`}
                    />
                </center>
                <p
                    className={`${base}__who-subheading lh--150`}
                    dangerouslySetInnerHTML={{
                        __html: intl.get('about.who.subheading'),
                    }}
                />
                <a href="https://popmeals.com.my/kuala-lumpur/careers">
                    <button className={`${base}__who-CTA button button--red`}>
                        {template(intl.get('about.who.CTA'))({
                            brandName: brandName.toUpperCase(),
                        })}
                    </button>
                </a>
            </div>
        );
    }
}
