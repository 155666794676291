import { observable } from 'mobx';

const { clevertap } = window;

export default class {
    @observable params = null;
    @observable discount = null;

    sendParams() {
        for (let param in this.params) {
            if (param === 'utm_source') {
                clevertap.profile.push({
                    Site: {
                        'Web UTM Attribution Channel': this.params[param],
                    },
                });
            }
            if (param === 'utm_campaign') {
                clevertap.profile.push({
                    Site: {
                        'Web UTM Attribution Campaign': this.params[param],
                    },
                });
            }
            if (param === 'utm_content') {
                clevertap.profile.push({
                    Site: {
                        'Web UTM Attribution Details': this.params[param],
                    },
                });
            }
        }
    }

    handleSendParams = () => {
        if (this.params) {
            this.sendParams();
        }
    };
}
