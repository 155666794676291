import Validator from '../../services/validator';

export default new Validator()
    .add(
        'buildingName',
        (model) => Boolean(model.buildingName),
        'Building Name should not be empty'
    )
    .add('floor', (model) => Boolean(model.floor), 'Floor should not be empty')
    .add(
        'contactPerson',
        (model) => Boolean(model.contactPerson),
        'Contact person should not be empty'
    )
    .add(
        'contactPhoneNumber',
        (model) => Boolean(model.contactPhoneNumber),
        'Contact person phone number should not be empty'
    );
