export const getCompanyFromLocalStorage = () => {
    if (localStorage.getItem('useCompany') === 'true') {
        return true;
    } else {
        return false;
    }
};

export const setCompanyToLocalStorage = (preference) => {
    localStorage.setItem('useCompany', preference);
};

export const clearCompanyFromLocalStorage = () => {
    localStorage.removeItem('useCompany');
};
