import React, { Component } from 'react';

const base = 'careers__carousel';

export default class Arrows extends Component {
    render() {
        const {
            customClasses,
            centerAlign,
            onClickLeft,
            onClickRight,
            disableLeftArrow,
            disableRightArrow,
        } = this.props;
        return (
            <div
                className={`${base}__arrow-container display--flex ${customClasses}`}
            >
                <div
                    className={`${base}__arrows${
                        centerAlign ? '--center-align' : ''
                    }`}
                >
                    <svg
                        className={`${base}__arrow-left iconmoon-icon cursor-pointer ${
                            disableLeftArrow ? 'disabled' : ''
                        }`}
                        onClick={onClickLeft}
                    >
                        <use xlinkHref={`#icon-proceed-arrow`} />
                    </svg>
                    <svg
                        className={`${base}__arrow-right iconmoon-icon cursor-pointer ${
                            disableRightArrow ? 'disabled' : ''
                        }`}
                        onClick={onClickRight}
                    >
                        <use xlinkHref={`#icon-proceed-arrow`} />
                    </svg>
                </div>
            </div>
        );
    }
}
