import React from 'react';
import intl from 'react-intl-universal';
import { inject, observer } from 'mobx-react';
import { CircleLoader } from '../../core/Loading';
import BangkokCreditCardForm from './creditCardForm/BangkokCreditCardForm';
import KualaLumpurCreditCardForm from './creditCardForm/KualaLumpurCreditCardForm';
import { services } from '../../../services/index';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(services.getParam('stripeAPIKey'));

@inject('creditCardStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        if (services.getParam('paymentGateway') === 'omise') {
            // init omise
            this.props.creditCardStore.initOmise();
        }
    }

    componentWillUnmount() {
        this.props.creditCardStore.reset();
    }

    render() {
        const base = 'add-credit-card';
        const { creditCardStore } = this.props;
        const formClasses = creditCardStore.isBusy
            ? `${base}__form col-lg-7 ${base}__form--busy`
            : `${base}__form col-lg-7`;
        const buttonClasses = creditCardStore.isAddingCreditCard
            ? 'button button--medium button--success width--100 button--busy'
            : 'button button--medium button--success width--100';

        return (
            <div className={base}>
                <div className="display--flex flex-align-items-center">
                    <div className="flex-1">
                        <p className="text--bold">{intl.get('packageCheckout.addCreditCard.addCard')}</p>
                    </div>
                    <div>
                        <button className="button button--small width--100px" onClick={this.props.onCancel}>
                            {intl.get('packageCheckout.addCreditCard.cancel')}
                        </button>
                    </div>
                </div>
                <div className="row mts">
                    {creditCardStore.isBusy ? (
                        <div className="col-lg-7">
                            <CircleLoader />
                        </div>
                    ) : null}
                    {services.getParam('city') === 'Kuala_Lumpur' ? (
                        <Elements stripe={stripePromise}>
                            <KualaLumpurCreditCardForm formClasses={formClasses} buttonClasses={buttonClasses} />
                        </Elements>
                    ) : (
                        <BangkokCreditCardForm formClasses={formClasses} buttonClasses={buttonClasses} />
                    )}
                </div>
            </div>
        );
    }
}
