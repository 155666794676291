export const deliveryAreas = {
    'Kuala Lumpur': [
        'Alam Damai',
        'Ampang',
        'Bandar Menjalara',
        'Bandar Sri Permaisuri',
        'Bandar Tasik Selatan',
        'Bandar Tun Razak',
        'Bangsar',
        'Bangsar South',
        'Batu',
        'Brickfields',
        'Bukit Bintang',
        'Bukit Damansara',
        'Bukit Jalil',
        'Bukit Kiara',
        'Bukit Nanas',
        'Bukit Petaling',
        'Bukit Tunku',
        'Cheras',
        'Chow Kit',
        'Cyberjaya',
        "Damansara Heights's",
        'Damansara Town Centre',
        'Damansara',
        'Dang Wangi',
        'Desa Petaling',
        'Federal Hill',
        'Happy Garden',
        'Hartamas',
        'Jalan Cochrane',
        'Jalan Duta',
        'Jalan Klang Lama',
        'Jinjang',
        'Kampung Baru',
        'Kampung Datuk Keramat',
        'Kepong',
        'KL Eco City',
        'Kuchai Lama',
        'Lembah Pantai',
        'Maluri',
        'Medan Tuanku',
        'Midvalley City',
        'Miharja',
        'Mont Kiara',
        'Pantai Dalam',
        'Pudu',
        'Putrajaya',
        'Salak South',
        'Segambut',
        'Selayang',
        'Semarak',
        'Sentul Raya',
        'Setapak',
        'Setiawangsa',
        'Shamelin',
        'Sri Hartamas',
        'Sri Petaling',
        'Sungai Besi',
        'Taman Bukit Maluri',
        'Taman Cheras Hartamas',
        'Taman Connaught',
        'Taman Desa',
        'Taman Ibukota',
        'Taman Len Seng',
        'Taman Melati',
        'Taman Midah',
        'Taman OUG',
        'Taman P. Ramlee',
        'Taman Sri Sinar',
        'Taman Taynton View',
        'Taman Tun Dr Ismail',
        'Taman U-Thant',
        'Taman Wahyu',
        'Titiwangsa',
        'Wangsa Maju',
    ],
    'Petaling Jaya': [
        'Seksyen 1',
        'Seksyen 2',
        'Seksyen 3',
        'Seksyen 4',
        'Seksyen 5',
        'Seksyen 6',
        'Seksyen 7',
        'Seksyen 8',
        'Seksyen 9',
        'Seksyen 10',
        'Seksyen 11',
        'Seksyen 12',
        'Seksyen 13',
        'Seksyen 14',
        'Seksyen 15',
        'Seksyen 16',
        'Seksyen 17',
        'Seksyen 17A',
        'Seksyen 19',
        'Seksyen 20',
        'Seksyen 21',
        'Seksyen 22',
        'Seksyen 51',
        'Seksyen 51A',
        'Seksyen 52',
        'SS1',
        'SS2',
        'SS3',
        'SS4',
        'SS5',
        'SS6',
        'SS7',
        'SS8',
        'SS9',
        'SS9A',
        'SS10',
        'SS11',
        'SS20',
        'SS21',
        'SS22',
        'SS22A',
        'SS23',
        'SS24',
        'SS25',
        'SS26',
        'PJU1',
        'PJU1A',
        'PJU2',
        'PJU3',
        'PJU4',
        'PJU5',
        'PJU6',
        'PJU7',
        'PJU8',
        'PJU9',
        'PJU10',
        'PJS1',
        'PJS2',
        'PJS3',
        'PJS4',
        'PJS5',
        'PJS6',
        'PJS7',
        'PJS8',
        'PJS10',
        'Bandar Sunway',
        'Old Town',
        'Bukit Gasing',
        'Kelana Jaya',
        'Sungei Way',
        'Tropicana',
        'Bandar Utama',
    ],
    'Shah Alam': [
        'Section 1',
        'Section 2',
        'Section 3',
        'Section 4',
        'Section 5',
        'Section 6',
        'Section 7',
        'Section 8',
        'Section 9',
        'Section 10',
        'Section 11',
        'Section 12',
        'Section 13',
        'Section 14',
        'Section 15',
        'Section 16',
        'Section 17',
        'Section 18',
        'Section 19',
        'Section 20',
        'Section 21',
        'Section 22',
        'Section 23',
        'Section 24',
        'Section 25',
        'Section 26',
        'Section 27',
        'Section 28',
        'Section 29',
        'Section 30',
        'Section 31',
        'Section 32',
        'Section 33',
        'Section 34',
        'Section 35',
        'Section 36',
        'Section U1',
        'Section U2',
        'Section U3',
        'Section U4',
        'Section U5',
        'Section U6',
        'Section U7',
        'Section U8',
        'Section U9',
        'Section U10',
        'Section U11',
        'Section U12',
        'Section U13',
        'Section U14',
        'Section U15',
        'Section U16',
        'Section U17',
        'Section U18',
        'Section U19',
        'Section U20',
        'Glenmarie',
        'Setia Alam',
        'Bukit Jelutong',
        'Puncak Alam',
    ],
    'Subang Jaya': [
        'SS12',
        'SS13',
        'SS14',
        'SS15',
        'SS16',
        'SS17',
        'SS18',
        'SS19',
        'PJS9',
        'PJS11',
    ],
    'Putrajaya & Cyberjaya': [
        'Presint 1',
        'Presint 2',
        'Presint 3',
        'Presint 4',
        'Presint 5',
        'Presint 6',
        'Presint 7',
        'Presint 8',
        'Presint 9',
        'Presint 10',
        'Presint 11',
        'Presint 12',
        'Presint 13',
        'Presint 14',
        'Presint 15',
        'Presint 16',
        'Presint 17',
        'Presint 18',
        'Presint 19',
        'Shaftsbury Square',
        "D'Pulze",
        'Serin Residency',
        'Cybersquare',
        'Crystal',
        'The Place',
        'Pangea',
        'Arc',
        "D'Melor",
        'The Domain',
        'Cyberia',
        'Masreca19',
        'Sejati Residences',
        'Symphony Hills',
        'Garden Residence',
        'Summerglades',
        'Mirage by the Lake',
        'Setia Eco Glades',
        'Cyber 3',
        'Cyber 4',
        'Cyber 5',
        'Cyber 6',
        'Cyber 7',
        'Cyber 8',
        'Cyber 9',
        'Cyber 11',
        'Cyber 12',
    ],
    Damansara: [
        'Bukit Damansara',
        'Damansara Utama',
        'Damansara Jaya',
        'Aman Suria Damansara',
        'Ara Damansara',
        'Damansara Indah',
        'Sunway Damansara',
        'Kota Damansara',
        'Mutiara Damansara',
        'Damansara Perdana',
        'Bandar Sri Damansara',
        'Damansara Damai',
        'Taman Tun Dr Ismail',
        'Desa Park City',
        'Bukit Kiara',
    ],
};
