import React, { Component } from 'react';
import { currency } from '../../../services';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import FloatingCta from '../FloatingCta';
import { inject, observer } from 'mobx-react';
import SelectPlanUI from './SelectPlanUI';

const base = 'select-popup';

@inject('selectPopupStore', 'userStore')
@observer
export default class SelectDetailsPage extends Component {
    renderSelectPlans = () => {
        const { selectPopupStore } = this.props;

        return (
            <div className={`${base}__plans-container`}>
                {selectPopupStore.plans.map(
                    (plan, i) =>
                        plan.published && (
                            <SelectPlanUI
                                key={i}
                                plan={plan}
                                selected={selectPopupStore.selected}
                                onClick={selectPopupStore.selectPlan}
                                isFirst={i === 0}
                            />
                        )
                )}
            </div>
        );
    };

    handleOnClick = () => {
        const { userStore, selectPopupStore } = this.props;
        if (userStore.hasSelectPaymentMethod) {
            selectPopupStore.isLoading = true;
            selectPopupStore.handleCreateSelectSubscription();
        } else {
            selectPopupStore.handleChangeView(selectPopupStore.PAGE_TWO);
        }
    };

    render() {
        const { selectPopupStore } = this.props;

        let topText = null;
        if (
            selectPopupStore.selected.package_value &&
            selectPopupStore.selected.package_value_desc
        ) {
            topText =
                currency.localCurrency(
                    selectPopupStore.selected.package_value,
                    true
                ) +
                ' ' +
                selectPopupStore.selected.package_value_desc;
        }

        return (
            <div className={`${base}__container`}>
                <div className={`${base}__header-container`} />
                <div className={`${base}__body`}>
                    <div className={`${base}__body-header-container`}>
                        <p className={`${base}__body-header text--bolder`}>
                            {selectPopupStore.data.heading}
                        </p>
                        <p className={`${base}__body-subheader`}>
                            {selectPopupStore.data.sub_heading}
                        </p>
                    </div>
                    <div className={`${base}__points-container`}>
                        {selectPopupStore.data.benefits &&
                            selectPopupStore.data.benefits.length > 0 &&
                            selectPopupStore.data.benefits.map((point, i) => (
                                <div className={`${base}__point`} key={i}>
                                    <svg
                                        className={`${base}__check-mark iconmoon-icon`}
                                    >
                                        <use
                                            xlinkHref={`#icon-select-check-mark`}
                                        />
                                    </svg>
                                    <div
                                        className={`${base}__point-text-container`}
                                    >
                                        <p className={`${base}__point-text`}>
                                            {point.bold_text ? (
                                                <strong>
                                                    {point.bold_text}{' '}
                                                </strong>
                                            ) : (
                                                ''
                                            )}
                                            {point.normal_text}
                                        </p>
                                        {(point.bold_text_small ||
                                            point.normal_text_small) && (
                                            <p
                                                className={`${base}__point-subtext`}
                                            >
                                                {point.bold_text_small ? (
                                                    <strong>
                                                        {point.bold_text_small}{' '}
                                                    </strong>
                                                ) : (
                                                    ''
                                                )}
                                                {point.normal_text_small}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                    {this.renderSelectPlans()}
                    <div className={`${base}__terms-container`}>
                        <p className={`${base}__terms`}>
                            {selectPopupStore.data.terms}
                        </p>
                    </div>
                    <div className={`${base}__cta-container`}>
                        <FloatingCta
                            onClick={this.handleOnClick}
                            customButtonClass={`${base}__cta button--select select`}
                            centerText={selectPopupStore.data.cta_text}
                            marginTop={
                                window.matchMedia(SMALL_SCREEN_MEDIA).matches
                                    ? 140
                                    : 120
                            }
                            topText={topText}
                            showDesktop={true}
                            busy={selectPopupStore.isLoading}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
