import { action, computed, observable } from 'mobx';
import { RESOURCE } from '../services/types';
import { services } from '../services/index'
import { debounce } from "lodash/function";

class AddressAutocompleteStore {
    @observable.ref addressChecker = RESOURCE.Initial;
    @observable.ref predictions = [];
    @observable selectedIndex = null;
    @observable confirmedIndex = null;
    @observable loading = null;
    @observable nearbyLocations = [];
    @observable nearbyLocation = {};

    constructor() {
        // debounce autocomplete requests by 400 ms to save costs (as these are without session)
        const doAutocomplete = (input) => {
            this.addressChecker = RESOURCE.Initial;

            services.api.AddressAutocomplete(input)
                .then((result) => {
                    if (result.status === "OK") {
                        this.predictions = result.list.filter(pred => 'place_id' in pred);
                        this.selectedIndex = 0;
                    }
                });
        };
        this.debouncedAutocomplete = debounce(doAutocomplete, 400);
    }

    @computed get selected() {
        return this.predictions[this.confirmedIndex];
    }

    @computed get selectedPrediction() {
        return this.predictions[this.selectedIndex];
    }


    @action clearPredictions = () => {
        this.predictions = [];
    };

    @action getNearbyLocations = (places) => {
        if (places && places.length > 0) {
            this.nearbyLocations = places.slice(0, 2)
        }
    };

    @action getPlacePredictions(input) {
        let doPredictions = input && input.length > 3;
        if (!doPredictions) {
            this.predictions = [];
            this.selectedIndex = 0;

            return;
        }

        this.debouncedAutocomplete(input);
    }

    @action selectNone() {
        this.selectedIndex = null;
    }

    @action selectUp() {
        this.selectedIndex = Math.max(0, this.selectedIndex - 1);
    }

    @action selectDown() {
        this.selectedIndex = Math.min(this.predictions.length - 1, this.selectedIndex + 1);
    }

    @action setAddressCoordinate = (place) => {
        return new window.google.maps.LatLng(
            place.latitude,
            place.longitude
        );
    };

    @action handleAddressSelected = (place, onSuccess) => {
        services.api.GetAddressDetails(place)
            .then((result) => {
                if (result && result.in_delivery_area) {
                    this.addressChecker = RESOURCE.Success(result);

                    const placeWithPredictedAddress = Object.assign({}, result, {
                        suggested_address: this.selected ? this.selected.description : this.nearbyLocation.formatted_address
                    });
                    onSuccess(placeWithPredictedAddress);
                    this.clearPredictions();
                    this.loading = false;
                } else {
                    this.addressChecker = RESOURCE.Failure(result.message, result);
                    this.loading = false;
                }
            })
            .catch((responseData) => {
                this.addressChecker = RESOURCE.Failure(responseData.message, responseData);
                this.loading = false;
            });
    };

    @action setConfirmedIndex(index) {
        this.confirmedIndex = index;
    }
}

const store = new AddressAutocompleteStore();
export default store;