import React, { Component, Fragment } from 'react';
import { services } from '../services/index';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { GetCity } from '../services/city';

import BasePage from './basePage';
import DishRoot from '../components/DishRoot';
import { CircleLoader } from '../components/core/Loading';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';
import { when } from 'mobx';
import { GetDishURL } from '../services/url';

const base = 'dish';

@inject('menuListStore', 'dishStore')
@observer
export default class extends Component {
    constructor() {
        super();

        this.state = {
            initDone: false,
            url: '',
        };
    }

    throw404 = () => {
        this.props.history.push(`/notfound`);
    };

    handleAppendUrlSlug = () => {
        const { dishStore, history } = this.props;
        when(
            () => dishStore.loading === false,
            () => {
                const url = GetDishURL(dishStore.dish);
                history.replace(`${url}`);
                this.setState({ url });
            }
        );
    };

    componentDidMount() {
        const { dishStore, match, menuListStore } = this.props;
        const isTerminal = window.location.href.includes('terminal');

        if (!isTerminal) {
            menuListStore.getMenuDates(true);
        }
        document.body.classList.add('dish');
        dishStore.handleGetDish(match.params.id, this.throw404);
        when(
            () => dishStore.dish && menuListStore.menu.length,
            () => {
                const menuItem = menuListStore.activeDateMenu.items.find(
                    (item) =>
                        item.menu_id === dishStore.dish.menu_id &&
                        (item.quantity_left > 0 || item.available_at)
                );

                if (
                    menuItem &&
                    menuItem.menu_id === dishStore.dish.menu_id &&
                    menuItem.id !== dishStore.dish.id
                ) {
                    dishStore.handleGetDish(menuItem.id, this.throw404);
                    this.handleAppendUrlSlug();
                }

                window.dataLayer.push({
                    PageType: 'ProductPage',
                    DailyProductID: dishStore.dish.id,
                    ProductId: dishStore.dish.menu_id,
                });
            }
        );

        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        const {
            dishStore,
            match: { params, url },
        } = this.props;

        if (url !== prevProps.match.url) {
            dishStore.handleGetDish(params.id, this.throw404);
            this.handleAppendUrlSlug();
        }
    }

    render() {
        const { url } = this.state;
        const {
            dishStore: { dish, loading },
            menuListStore: { activeDateMenu },
        } = this.props;
        let dishDescription;

        if (dish) {
            const dishName = dish.name;
            const dishSubheading = dish.sub_heading
                ? ' ' + dish.sub_heading
                : '';
            let dishExtension;
            if (dish.tag) {
                if (
                    dish.tag &&
                    (dish.tag.name === 'New' || dish.tag.name === 'Vegetarian')
                ) {
                    dishExtension =
                        ` by ${services.getParam('brandName')} • ` +
                        dish.tag.name +
                        '!';
                } else {
                    dishExtension =
                        ` by ${services.getParam('brandName')} ` +
                        dish.tag.name;
                }
            } else {
                dishExtension = ` by ${services.getParam('brandName')}`;
            }
            dishDescription = dishName + dishSubheading + dishExtension;
        }

        return (
            <Fragment>
                {activeDateMenu && !loading ? (
                    <div>
                        <Helmet
                            meta={[
                                {
                                    name: 'description',
                                    content: dishDescription,
                                },
                                {
                                    itemprop: 'name',
                                    content:
                                        dish.name +
                                        ` by ${services.getParam('brandName')}`,
                                },
                                {
                                    itemprop: 'description',
                                    content: dish.description,
                                },
                                { itemprop: 'image', content: dish.image },
                                {
                                    property: 'og:url',
                                    content: `${services.getParam(
                                        'deploymentUrl'
                                    )}/${GetCity()}${url}`,
                                },
                                {
                                    property: 'og:type',
                                    content: 'product.item',
                                },
                                {
                                    property: 'og:description',
                                    content: dish.description,
                                },
                                { property: 'og:image', content: dish.image },
                                {
                                    property: 'fb:app_id',
                                    content: '865803290168313',
                                },
                                {
                                    property: 'og:title',
                                    content:
                                        dish.name +
                                        ` by ${services.getParam('brandName')}`,
                                },
                                {
                                    property: 'product:brand',
                                    content: services.getParam('brand'),
                                },
                                {
                                    property: 'product:availability',
                                    content: dish.available
                                        ? 'in stock'
                                        : 'out of stock',
                                },
                                {
                                    property: 'product:condition',
                                    content: 'new',
                                },
                                {
                                    property: 'product:price:amount',
                                    content: dish.price.toString(),
                                },
                                {
                                    property: 'product:price:currency',
                                    content: services.getParam('currencyCode'),
                                },
                                {
                                    property: 'product:retailer_item_id',
                                    content: dish.id,
                                },
                                {
                                    name: 'twitter:card',
                                    content: 'summary_large_image',
                                },
                                {
                                    name: 'twitter:title',
                                    content:
                                        dish.name +
                                        ` by ${services.getParam('brandName')}`,
                                },
                                {
                                    name: 'twitter:description',
                                    content: dish.description,
                                },
                                { name: 'twitter:image', content: dish.image },
                            ]}
                        >
                            <title>
                                {dish.name +
                                    ` by ${services.getParam('brand')}`}
                            </title>
                            {localizationTags(url).map((tag) => tag)}
                            {getCanonicalTag(url)}
                        </Helmet>
                        <BasePage />
                        <DishRoot dish={dish} />
                    </div>
                ) : (
                    <div className={`${base}__list--loading text--center`}>
                        <div className="display--inline-block text--center">
                            <CircleLoader className="m0a" />
                            <p className="mtm">{intl.get('dish.loading')}</p>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}
