import React from 'react';
import { inject, observer } from 'mobx-react';

const base = 'terminal-mobile-help-screen';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    handleCloseClick = () => {
        const {
            terminalMenuStore: {
                previouslyOpenedMobileScreen,
                setShowMobileScreen,
            },
        } = this.props;
        setShowMobileScreen(previouslyOpenedMobileScreen);
    };

    render() {
        return (
            <div className={base}>
                <svg
                    className={`${base}__close_btn`}
                    onClick={this.handleCloseClick}
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="1.45337"
                        y="\0.0144653"
                        width="17.8442"
                        height="1.3587"
                        transform="rotate(45 1.45337 0.0144653)"
                        fill="#131415"
                    />
                    <rect
                        width="17.8442"
                        height="1.3587"
                        transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.1104 0.0144653)"
                        fill="#131415"
                    />
                </svg>
                <h3 className={`${base}__heading`}>How to order?</h3>
                <div className={`${base}__steps`}>
                    <div className={`${base}__step`}>
                        <h2 className={`${base}__serial_num`}>1</h2>
                        <div className={`${base}__headline_desc`}>
                            <h4 className={`${base}__heading`}>
                                Choose your meal
                            </h4>
                            <div className="description">
                                Tap on the
                                <span>
                                    <img
                                        className={`${base}__cart_number_icon ${base}__cart_icon`}
                                        src={
                                            'https://image.popcontent.link/cart_icon.svg'
                                        }
                                        alt="cart icon"
                                    />
                                </span>
                                icon to add your meal to cart
                            </div>
                        </div>
                    </div>
                    <div className={`${base}__step`}>
                        <h2 className={`${base}__serial_num`}>2</h2>
                        <div className={`${base}__headline_desc`}>
                            <h4 className={`${base}__heading`}>
                                Add-on any sides?
                            </h4>
                            <div className="description step2_desc">
                                Add any sides, drinks or desserts you want
                            </div>
                        </div>
                    </div>
                    <div className={`${base}__step`}>
                        <h2 className={`${base}__serial_num`}>3</h2>
                        <div className={`${base}__headline_desc`}>
                            <h4 className={`${base}__heading`}>Checkout</h4>
                            <div className="description">
                                Tap on
                                <span>
                                    <img
                                        className={`${base}__cart_number_icon`}
                                        src={
                                            'https://image.popcontent.link/cart_dummy_1.svg'
                                        }
                                        alt="cart icon"
                                    />
                                </span>
                                at the bottom right to checkout
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    className={`${base}__halal_pop_logo`}
                    src={'https://image.popcontent.link/halal_pop.svg'}
                    alt="dijamin halal and popmeals logo"
                />
            </div>
        );
    }
}
