import React, { Component } from 'react';
import InputUI from '../../core/InputUI';
import ButtonUI from '../../core/loginPopup/ButtonUI';

export default class SectionMobileNumberUI extends Component {
    render() {
        const {
            headerText,
            type,
            countryCode,
            value,
            base,
            onChange,
            errorMessage,
            buttonText,
            loading,
            onClick,
            placeholder,
            autoFocus,
            mobileNumberInput,
            name,
            hideFooter,
            displaySameLine,
        } = this.props;

        return (
            <div className={`${base}__referral-login-container`}>
                {headerText && (
                    <div
                        className={`${base}__referral-login-header text--bolder`}
                    >
                        {headerText}
                    </div>
                )}
                <div
                    className={`${base}__referral-login ${
                        displaySameLine ? 'display--flex' : ''
                    }`}
                >
                    <div>
                        <InputUI
                            name={name}
                            mobileNumberInput={mobileNumberInput}
                            autoFocus={autoFocus}
                            autoComplete="tel"
                            countryCode={countryCode}
                            value={value}
                            containerClass={`${base}__referral-input-container`}
                            inputClass={`${base}__referral-input`}
                            placeholder={placeholder}
                            type={type}
                            required="true"
                            onChange={onChange}
                        />
                        {errorMessage && (
                            <div
                                className={`${base}__error`}
                                style={{ marginTop: '5px' }}
                            >
                                <p className="text--red fs--small-medium">
                                    {errorMessage}
                                </p>
                            </div>
                        )}
                    </div>
                    <ButtonUI
                        text={buttonText}
                        customClasses={`${base}__referral-login-cta button--success`}
                        loading={loading}
                        onClick={onClick}
                    />
                </div>
                {!hideFooter && (
                    <div className={`${base}__referral-login-footer`}>
                        Voucher expires in 24 hours. Claim now!
                    </div>
                )}
            </div>
        );
    }
}
