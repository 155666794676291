import React, { Component } from 'react';
import {
    MEDIUM_SCREEN_MEDIA,
    SMALL_SCREEN_MEDIA,
} from '../../../services/constants';
import { observer } from 'mobx-react';

const base = 'chef__section-hero';

@observer
export default class SectionHero extends Component {
    render() {
        const smallAndMediumScreens =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches ||
            window.matchMedia(MEDIUM_SCREEN_MEDIA).matches;

        return (
            <div className={`${base}`}>
                <div className={`${base}__bg-image`} />
                {!smallAndMediumScreens && (
                    <div className={`${base}__content-container container`}>
                        <div className={`${base}__content`}>
                            <h1 className={`${base}__header text--bolder`}>
                                Our 5-star chefs
                            </h1>
                            <p className={`${base}__body`}>
                                Our culinary wizards cook delicious food that is
                                good for your soul and body.
                            </p>
                        </div>
                    </div>
                )}
                {smallAndMediumScreens && (
                    <div className={`${base}__content-container container`}>
                        <div className={`${base}__content`}>
                            <h1 className={`${base}__header text--bolder`}>
                                Our 5-star chefs
                            </h1>
                            <p className={`${base}__body`}>
                                Our culinary wizards cook delicious food that is
                                good for your soul and body.
                            </p>
                        </div>
                    </div>
                )}
                {smallAndMediumScreens ? (
                    <div className="container">
                        <hr className={`${base}__dash`} />
                    </div>
                ) : (
                    <div className={`${base}__grey-box hide--if-small`} />
                )}
            </div>
        );
    }
}
