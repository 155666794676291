import React, { Component } from 'react';
import ContentUI from './ContentUI';
import intl from 'react-intl-universal';

export default class Mission extends Component {
    render() {
        return (
            <ContentUI
                heading={intl.get('careers.mission.heading')}
                content={intl.get('careers.mission.content')}
            />
        );
    }
}
