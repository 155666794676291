import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const base = 'careers__why';

export default class Why extends Component {
    render() {
        const { heading, content, readMore, link } = this.props;

        return (
            <div className={base}>
                <div className={`${base}__text-container`}>
                    <h1 className={`${base}__heading text--bolder`}>
                        {heading}
                    </h1>
                    <div>
                        <p className={`${base}__main-text`}>{content}</p>
                        {readMore && (
                            <Link
                                className={`${base}__read-more text--bolder cursor-pointer link--no-underline`}
                                to={link}
                            >
                                {readMore}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
