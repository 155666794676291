import React from 'react';
import { inject, observer } from 'mobx-react';

import { services } from '../../../services/index';
import intl from 'react-intl-universal';

import { parseDate, getDateDisplayFull } from '../../../services/dateTime';
import { currency } from '../../../services';
import ViewSwitcher from './ViewSwitcher';
import GeneralView from './GeneralView';
import IngredientView from './IngredientView';
import NutritionalView from './NutritionalView';
import HalalView from './HalalView';

import AddonUpsell from '../../core/addonUpsell/AddonUpsell';
import { RESOURCE } from '../../../services/types';
import Price from '../../core/Price';
import { cities, GetCity } from '../../../services/city';
import {
    MEDIUM_SCREEN_MEDIA,
    SMALL_SCREEN_MEDIA,
} from '../../../services/constants';
import { when } from 'mobx';
import ReviewsView from './ReviewsView';
import AddressSelectorPopup from '../../core/AddressSelectorPopup';

const base = 'dish';

@inject(
    'menuListStore',
    'menuCheckoutStore',
    'dropdownStore',
    'walletStatusStore',
    'cartStore',
    'dishStore'
)
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.walletStatusStore.getWalletStatus();

        const dish = this.props.dishStore.dish;

        if (this.props.dish && this.props.dish.addon_category) {
            this.props.dishStore.reviews = [];
        } else {
            when(
                () => this.props.dishStore.dish && this.props.dishStore.dish.id,
                () => {
                    this.props.dishStore.handleGetReviews(
                        this.props.dishStore.dish.id
                    );
                }
            );
        }

        window.dataLayer.push({
            event: 'ViewContent',
            content_type: 'product',
            currency: services.getParam('currencyCode'),
            value: dish.price,
            content_name: dish.name,
            content_ids: [dish.id],
            contents: [{ id: dish.id, quantity: 1, item_price: dish.price }],
        });
    }

    throw404 = () => {
        this.props.history.push(`/notfound`);
    };

    handleAddToCart() {
        const { dishStore, cartStore } = this.props;
        const dish = dishStore.dish;

        cartStore.addToCart(dish, dish.menu_date);

        if (dish.available_at) {
            setTimeout(() => {
                dishStore.handleGetDish(dish.id, this.throw404, dish.menu_date);
            }, 1000);
        }
    }

    renderStars(id) {
        const { menuListStore } = this.props;
        const { rating_text, show_thumb_for_rating_text } =
            menuListStore.activeDateMenu.items.find((item) => item.id === id);

        return (
            <div className="mts display--flex fs--small-medium">
                {show_thumb_for_rating_text && (
                    <span className="mrxs">
                        <svg className={`iconmoon-icon`}>
                            <use xlinkHref="#icon-thumbs-up-menu-item" />
                        </svg>
                    </span>
                )}
                <span>{rating_text}</span>
            </div>
        );
    }

    renderButton() {
        const { cartStore, dishStore, walletStatusStore } = this.props;

        if (
            RESOURCE.Success.is(walletStatusStore.walletStatus) ||
            RESOURCE.Failure.is(walletStatusStore.walletStatus)
        ) {
            const dish = dishStore.dish;
            const onemore = cartStore.isInCart(dish)
                ? intl.get('dish.ctaOrdered')
                : intl.get('menuPage.menuItem.cta');

            const price = dish.special_offer_price
                ? dish.special_offer_price
                : dish.price;

            if (dish.available) {
                if (walletStatusStore.walletStatus.data.has_wallet) {
                    return (
                        <div>
                            <p className={`text--bold ${base}__price`}>
                                <Price price={currency.localCurrency(price)} />
                                {dish.delivery_fee === 0 ? (
                                    <span
                                        className={` ${base}__free-delivery mls`}
                                    >
                                        {intl.get('dish.freeDelivery')}
                                    </span>
                                ) : null}
                            </p>
                            {this.renderStars(dish.id)}
                            <button
                                type="button"
                                className={`add-to-cart-button button--success ${base}__add-to-cart button`}
                                onClick={::this.handleAddToCart}
                            >
                                {onemore}
                            </button>
                        </div>
                    );
                }
                return (
                    <div>
                        <p className={`${base}__price`}>
                            <Price
                                price={currency.localCurrency(price, false)}
                            />
                            {dish.delivery_fee === 0 ? (
                                <span className={` ${base}__free-delivery mls`}>
                                    {intl.get('dish.freeDelivery')}
                                </span>
                            ) : null}
                        </p>
                        {this.renderStars(dish.id)}
                        <button
                            type="button"
                            className={`${base}__add-to-cart button button--success`}
                            onClick={::this.handleAddToCart}
                        >
                            {onemore}
                        </button>
                    </div>
                );
            } else if (dish.available_at) {
                return (
                    <div>
                        <p className={`${base}__price`}>
                            <Price price={currency.localCurrency(price)} />
                            {dish.delivery_fee === 0 ? (
                                <span className={` ${base}__free-delivery mls`}>
                                    {intl.get('dish.freeDelivery')}
                                </span>
                            ) : null}
                        </p>
                        {this.renderStars(dish.id)}
                        <button
                            type="button"
                            className={`${base}__add-to-cart button button--success`}
                            onClick={::this.handleAddToCart}
                            style={{ height: '45px' }}
                        >
                            <p className="text--bolder">
                                {intl.get('menuPage.menuItem.cta')}
                            </p>
                            <p
                                className="text--bolder"
                                style={{ fontSize: '10px' }}
                            >
                                Available from {dish.available_at}
                            </p>
                        </button>
                    </div>
                );
            }

            return (
                <div>
                    <p className={`${base}__price`}>
                        <Price price={currency.localCurrency(price)} />
                        {dish.delivery_fee === 0 ? (
                            <span className={` ${base}__free-delivery mls`}>
                                {intl.get('dish.freeDelivery')}
                            </span>
                        ) : null}
                    </p>
                    {this.renderStars(dish.id)}
                    <button
                        type="button"
                        className={`${base}__add-to-cart button button--disabled`}
                    >
                        {intl.get('dish.soldOut')}
                    </button>
                </div>
            );
        } else {
            return <div className="mtxl mbxl">{intl.get('loading')}</div>;
        }
    }

    render() {
        const { dishStore, menuListStore } = this.props;
        const dish = dishStore.dish;
        const tabletAndPhoneView =
            window.matchMedia(MEDIUM_SCREEN_MEDIA).matches ||
            window.matchMedia(SMALL_SCREEN_MEDIA).matches;
        const showHalal = dishStore.showHalal;

        return (
            <div className={tabletAndPhoneView ? '' : 'row'}>
                {menuListStore.activeDateObj && <AddressSelectorPopup />}
                <div
                    className={`${base}__image-container ${
                        tabletAndPhoneView ? '' : 'col-lg-5 col-md-5 col-sm-6'
                    }`}
                >
                    <div
                        className={`${base}__date-title show--if-small hide--if-large`}
                    >
                        <span className="text--bold text--sofiapro mrs">
                            {getDateDisplayFull(parseDate(dish.menu_date))}
                        </span>
                    </div>
                    <div
                        className={`${base}__image border--rounded`}
                        style={{ backgroundImage: `url(${dish.image})` }}
                    >
                        {showHalal && (
                            <img
                                className={`halal-logo`}
                                src={
                                    'https://image.popcontent.link/dijamin_halal.svg'
                                }
                                alt="Dijamin halal logo"
                            />
                        )}
                    </div>

                    <div className={`${base}__sharing clearfix mts text--bold`}>
                        <div className={`${base}__share-message float-left`}>
                            {intl.get('dish.share')}
                        </div>
                        <div className="float-right">
                            <svg
                                className={`${base}__social-icon iconmoon-icon mrs`}
                            >
                                <use xlinkHref="#icon-facebook-square" />
                            </svg>
                            <svg
                                className={`${base}__social-icon iconmoon-icon mrs`}
                            >
                                <use xlinkHref="#icon-twitter" />
                            </svg>
                            <svg
                                className={`${base}__social-icon iconmoon-icon`}
                            >
                                <use xlinkHref="#icon-instagram" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div
                    className={`${base}__content ${
                        tabletAndPhoneView ? '' : 'col-lg-7 col-md-7 col-sm-6'
                    }`}
                >
                    <div className="mbm show--if-large hide--if-small">
                        <span className="text--bold text--sofiapro mrs">
                            {getDateDisplayFull(parseDate(dish.menu_date))}
                        </span>
                    </div>
                    <h1 className={`${base}__title`}>{dish.name}</h1>
                    <p className={`${base}__sub-heading`}>{dish.sub_heading}</p>
                    {this.renderButton()}
                    <ViewSwitcher />
                    <div className={`${base}__view-content`}>
                        {dishStore.getView() === dishStore.GENERAL_VIEW &&
                        dish.description ? (
                            <GeneralView dish={dish} />
                        ) : null}
                        {dishStore.getView() === dishStore.INGREDIENT_VIEW &&
                        (dish.ingredients || []).length !== 0 ? (
                            <IngredientView dish={dish} />
                        ) : null}
                        {dishStore.getView() === dishStore.REVIEWS_VIEW &&
                        dishStore.reviews &&
                        dishStore.reviews.length > 0 ? (
                            <ReviewsView />
                        ) : null}
                        {dishStore.getView() === dishStore.NUTRITIONAL_VIEW &&
                        (dish.attributes || []).length !== 0 ? (
                            <NutritionalView dish={dish} />
                        ) : null}
                        {dishStore.getView() === dishStore.HALAL_VIEW && (
                            <HalalView />
                        )}
                        {window.matchMedia(SMALL_SCREEN_MEDIA).matches && (
                            <hr className={`${base}__dash`} />
                        )}
                    </div>
                    <AddonUpsell date={dish.menu_date} />
                </div>
                <div className="mbxl" />
            </div>
        );
    }
}
