import Validator from '../../services/validator';
import {
    CONDO_ADDRESS,
    OFFICE_ADDRESS,
    HOME_ADDRESS,
} from '../../services/constants';

export default new Validator()
    .add(
        'addressType',
        (model) => {
            return Boolean(model.addressType);
        },
        'Please select an address type.'
    )
    .add(
        'buildingName',
        (model) => {
            if (
                model.addressType === CONDO_ADDRESS ||
                model.addressType === OFFICE_ADDRESS
            ) {
                return Boolean(model.buildingName);
            }

            return true;
        },
        'Building Name is required.'
    )
    .add(
        'block',
        (model) => {
            if (
                model.addressType === CONDO_ADDRESS ||
                model.addressType === OFFICE_ADDRESS
            ) {
                return Boolean(model.block);
            }

            return true;
        },
        'Block is required.'
    )
    .add(
        'homeNumber',
        (model) => {
            if (model.addressType === HOME_ADDRESS) {
                return Boolean(model.homeNumber);
            }

            return true;
        },
        'House number is required.'
    );

export const deliveryInstructionValidator = new Validator().add(
    'deliveryInstruction',
    (model) => Boolean(model.deliveryInstruction),
    'Please choose one of the methods above'
);
