import { observable, action, computed } from 'mobx';
import { RESOURCE } from '../services/types';
import { services, getParam } from '../services/index';
import { androidDevice, iOSDevice } from '../services/domUtils';
import menuHeaderStore from './menuHeaderStore';
import { getPartnerIntegrationData } from '../services/storage';
import Cookies from 'js-cookie';

const { clevertap } = window;

class UserStore {
    @observable.ref user = RESOURCE.Initial;
    @observable showTerminalUserPopup = false;
    @observable appDownloadLink = services.getParam('appDownloadPath');
    @observable showHalalPopup = false;

    @computed get isSelect() {
        return this.user.data && this.user.data.select_customer;
    }
    @computed get isLoggedIn() {
        return this.user.data && this.user.data.username;
    }
    @computed get hasToken() {
        return services.api.AccessTokenStorage.getToken() != null;
    }
    @computed get isNotLoggedIn() {
        return this.user.data && !this.user.data.username;
    }
    @computed get hasSelectPaymentMethod() {
        return this.user.data && this.user.data.has_select_payment_method;
    }

    @action
    getUserData() {
        this.user = RESOURCE.Loading;

        const showAppDownloadBanner =
            !window.location.pathname.includes('/referred') &&
            !Cookies.get('hide_get_app_banner') &&
            (androidDevice || iOSDevice);

        const token = services.api.AccessTokenStorage.getToken();
        // get user details if auth token exists
        if (token) {
            services.api.GetUserWithMemoizer.runFresh()
                .then((user) => {
                    let partnerIntegration = getPartnerIntegrationData();
                    if (partnerIntegration) {
                        this.updatePartnerIntegration(partnerIntegration);
                    }

                    services.api.GetInfo().then((res) => {
                        user.has_select_payment_method =
                            res.has_select_payment_method;
                        user.company = res.company_status;
                        this.user = RESOURCE.Success(user);
                        menuHeaderStore.setUser(this.user);
                        window.dataLayer.push({
                            email: this.user.data.email,
                        });

                        if (showAppDownloadBanner) {
                            this.getAppDownloadLink();
                        }
                    });
                })
                .catch((responseData) => {
                    if (
                        responseData.message === 'access denied' &&
                        Cookies.get('terminal_user')
                    ) {
                        this.showTerminalUserPopup = true;
                    } else {
                        services.api.AccessTokenStorage.clearToken();
                    }
                    this.user = RESOURCE.Failure(
                        responseData.message,
                        responseData
                    );
                    menuHeaderStore.setUser(this.user);
                });
        } else {
            this.user = RESOURCE.Failure('Access Denied', {});
            menuHeaderStore.setUser(this.user);
            window.dataLayer.push({
                email: '',
            });

            if (showAppDownloadBanner) {
                menuHeaderStore.setShowAppDownloadBanner(true);
            }
        }
    }

    @action
    storeFreshChatId = (id) => {
        services.api.StoreFreshChatId(id);
    };

    @action
    updatePartnerIntegration = (partnerIntegration) => {
        services.api
            .UpdatePartnerIntegration(partnerIntegration)
            .then((res) => console.debug(res))
            .catch((err) => console.debug(err));
    };

    @action
    getAppDownloadLink = () => {
        services.api
            .GetAppDownloadLink()
            .then(({ url }) => {
                this.appDownloadLink = url;
                menuHeaderStore.setShowAppDownloadBanner(true);
            })
            .catch((err) => console.debug(err));
    };

    @action
    setShowHalalPopup = () => {
        this.showHalalPopup = true;
    };

    @action
    setHideHalalPopup = () => {
        this.showHalalPopup = false;
    };
}

const store = new UserStore();
export default store;
