import React from 'react';
import { inject, observer } from 'mobx-react';

import intl from 'react-intl-universal';

import { RESOURCE } from '../../../services/types';
import SubheaderUI from './SubheaderUI';
import InputUI from '../InputUI';
import OrSeparator from './OrSeparator';
import ButtonUI from './ButtonUI';
import { EMAIL } from '../../../services/constants';

const base = 'login-popup';

@inject('loginPopupStore')
@observer
export default class extends React.Component {
    handleSubmitPassword(e) {
        e.preventDefault();

        this.props.loginPopupStore.showBusy();
        this.props.loginPopupStore.doLogin(EMAIL);
    }

    renderSubheader = () => {
        return (
            <SubheaderUI
                customWidthPercentage="80%"
                text={intl.get('loginPopup.passwordView.enterPassword')}
            />
        );
    };

    renderInput = () => {
        const { loginPopupStore } = this.props;

        return (
            <InputUI
                autoFocus="true"
                containerClass={`${base}__input-container`}
                inputClass={`${base}__input`}
                placeholder="Your password"
                type="password"
                value={loginPopupStore.password}
                onChange={::loginPopupStore.setPassword}
                errorMessage={loginPopupStore.message}
            />
        );
    };

    renderButton = () => {
        return (
            <ButtonUI
                customClasses="button--success"
                text={intl.get('loginPopup.passwordView.cta')}
                marginTop={'10px'}
            />
        );
    };

    render() {
        const { loginPopupStore } = this.props;

        const resetLink =
            loginPopupStore.resetPassword === RESOURCE.Loading ? (
                <span className="lh--125 display--inline-block mbl mtl">
                    {intl.get('loginPopup.passwordView.instructions')}
                    {loginPopupStore.email}...
                </span>
            ) : (
                <a
                    className="text--bolder text--primary display--inline-block cursor-pointer"
                    onClick={::loginPopupStore.forgetPassword}
                >
                    {intl.get('loginPopup.passwordView.forgotPassword')}
                </a>
            );

        return (
            <div className={base}>
                <h3 className={`${base}__title`}>
                    {intl.get('loginPopup.passwordView.welcomeBack')}
                </h3>
                <form method="POST" onSubmit={::this.handleSubmitPassword}>
                    {this.renderSubheader()}
                    {this.renderInput()}
                    {this.renderButton()}
                </form>
                <OrSeparator customMargin={20} />
                <div className="hide--if-large show--if-small mbl" />
                {resetLink}
                <div className="hide--if-large show--if-small mbm" />
            </div>
        );
    }
}
