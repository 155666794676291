import React from 'react';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import Modal from '../../core/Modal';
import { RESOURCE } from '../../../services/types';
import PopupVoteSuccess from './PopupVoteSuccess';
import PopupInputEmail from './PopupInputEmail';

const base = 'delivery-area__vote-section';

@inject('deliveryAreaStore')
@observer
export default class extends React.Component {
    render() {
        const store = this.props.deliveryAreaStore;
        const {
            voter,
            validatePostCodeResult,
            showPopupSuccess,
            showPopupEmail,
        } = store;
        const buttonClasses = cx(
            `${base}__cta button button--success button--bigger-on-mobile`,
            {
                'button--busy': voter === RESOURCE.Loading,
            }
        );

        return (
            <div className={`${base} text--center`}>
                <h1 className={`${base}__heading text--bolder`}>
                    {intl.get('deliveryArea.voteHeading')}
                </h1>
                <div className="separator separator--green mbl mtl" />
                <p className={`${base}__subheading lh--150`}>
                    {intl.get('deliveryArea.voteSubheading_1')}
                </p>
                <form
                    className={`${base}__form-container`}
                    onSubmit={::store.submitVoteAddress}
                >
                    <div className="mbm">
                        <input
                            className={`${base}__input input`}
                            type="number"
                            placeholder={intl.get('deliveryArea.placeholder')}
                            name="postCode"
                            value={store.postCode}
                            onChange={::store.updateModel}
                        />
                    </div>
                    <div>
                        <p className="text--red mts mbs fs--small-medium">
                            {validatePostCodeResult.getFirstError()}
                        </p>
                    </div>
                    <div>
                        <button className={buttonClasses}>
                            {intl.get('deliveryArea.voteCTA')}
                        </button>
                    </div>
                    <div className="separator separator--green mbl" />
                    <p className={`${base}__subheading-2 lh--150`}>
                        {intl.get('deliveryArea.voteSubheading_2')}
                    </p>
                    <div className="mtm">
                        <a
                            href="/business"
                            className={`${base}__business-link dmk-link--secondary text--bolder`}
                        >
                            {intl.get('deliveryArea.voteBusinessLink')}
                        </a>
                    </div>
                </form>
                <Modal
                    showModal={showPopupSuccess || showPopupEmail}
                    showCloseIcon={true}
                    contentClasses="delivery-area__popup-container modal__content--golden-ratio"
                    closeButtonClasses="fill--gray"
                    onBackdropClicked={::store.hidePopup}
                    onCloseClicked={::store.hidePopup}
                >
                    {showPopupEmail ? <PopupInputEmail /> : null}
                    {showPopupSuccess ? <PopupVoteSuccess /> : null}
                </Modal>
            </div>
        );
    }
}
