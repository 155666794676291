import React from 'react';
import { inject, observer } from 'mobx-react';
import PaymentMethod from '../walletCheckoutPage/PaymentMethod';
import intl from 'react-intl-universal';
import Loading from '../../core/Loading';
import AddPaymentMethod from './AddPaymentMethod';
import Cookies from 'js-cookie';
import { when } from 'mobx';
import { cities, GetCity } from '../../../services/city';

@inject('store')
@inject('paymentStore')
@inject('creditCardStore')
@inject('userStore')
@inject('creditCardStore')
@observer
export default class extends React.Component {
    componentDidMount() {}

    renderPaymentMethodInfo() {
        const base = 'show-payment-method';
        const { store, paymentStore } = this.props;
        const boostUser = Cookies.get('boost_user');
        return (
            <div className={`${base} row`}>
                <div className="col-lg-9 col-md-9">
                    <PaymentMethod
                        paymentMethod={store.paymentMethod}
                        underline={false}
                    />
                </div>
                {!boostUser && (
                    <div className="col-lg-3 col-md-3">
                        <button
                            type="button"
                            className={`${base}__change button button--small`}
                            onClick={::paymentStore.goToView(
                                paymentStore.LIST_VIEW
                            )}
                        >
                            {intl.get('packageCheckout.payment.ctaChange')}
                        </button>
                    </div>
                )}
            </div>
        );
    }

    renderSelectionList() {
        return <AddPaymentMethod />;
    }

    render() {
        const { store, paymentStore } = this.props;
        const boostUser = Cookies.get('boost_user');

        if (paymentStore.isBusy) {
            return <Loading />;
        }

        return store.paymentMethod || boostUser
            ? this.renderPaymentMethodInfo()
            : this.renderSelectionList();
    }
}
