import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Modal from '../../core/Modal';
import PopupResetKiosk from './PopupResetKiosk';

const base = 'terminal-deal-combo';
@inject('terminalMenuStore', 'terminalCartStore')
@observer
class DealComboSelection extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        mealList: null,
        id: '',
    };

    componentDidMount() {
        const { terminalMenuStore } = this.props;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let meals = terminalMenuStore.comboObject.number_of_mains;
        let drinks = terminalMenuStore.comboObject.number_of_drinks;
        let addOn = terminalMenuStore.comboObject.number_of_addons;
        let id = decodeURIComponent(urlParams.get('id'));

        let errorObject = {
            error: false,
        };
        let mealLength = [];
        let drinksLength = [];
        let addOnLength = [];
        for (var i = 0; i < meals; i++) {
            mealLength.push(errorObject);
        }
        for (var i = 0; i < drinks; i++) {
            drinksLength.push(errorObject);
        }

        for (var i = 0; i < addOn; i++) {
            addOnLength.push(errorObject);
        }

        let mealList;

        if (!terminalMenuStore.totalComboList[id]) {
            mealList = {
                [id]: {
                    Mains: mealLength,
                    Drinks: drinksLength,
                    Addons: addOnLength,
                    totalPrice: terminalMenuStore.comboObject.default_price,
                    validation: false,
                },
            };

            terminalMenuStore.totalComboList = {
                ...terminalMenuStore.totalComboList,
                ...mealList,
            };
        } else {
            mealList = { ...terminalMenuStore.totalComboList };
        }

        this.setState({ mealList: mealList[id], id: id });
    }

    setMealList(data, type) {
        const { terminalMenuStore, history } = this.props;

        terminalMenuStore.setItemsUIView(type);
        history.push(`/terminal-combo-items?id=${data + 1}&name=${terminalMenuStore.comboObject.id}&type=${type}`);
    }

    renderTitle(title) {
        if (title == 'Mains') {
            return 'meals';
        } else if (title == 'Drinks') {
            return 'drinks';
        } else {
            return 'snacks or desserts';
        }
    }

    handleShowHideSummary = () => {
        const { terminalMenuStore, terminalCartStore } = this.props;
        const isEmpty = terminalCartStore.cartServices.isEmptyAt({
            date: terminalMenuStore.menuDate,
        });

        if (!isEmpty) {
            terminalMenuStore.setShowSummary(true);
        }
    };

    handleClosePopup = () => {
        this.props.terminalMenuStore.setShowPopup('');
    };

    renderMeals() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const { terminalMenuStore, history } = this.props;

        let id = decodeURIComponent(urlParams.get('id'));

        if (terminalMenuStore.totalComboList && terminalMenuStore.totalComboList[id]) {
            let mainObject = terminalMenuStore.totalComboList[id];
            return (
                <div>
                    {Object.keys(mainObject).map((title, key) => {
                        if (mainObject[title].length > 0) {
                            const checkError = mainObject[title].filter((o) => {
                                return o.error && !o.menu_item_id;
                            });

                            return (
                                <div style={{ padding: '10px' }} key={key}>
                                    <div className={`${base}__title-header`}>
                                        Select {mainObject[title].length} {this.renderTitle(title)}
                                        {checkError.length > 0 ? (
                                            <span
                                                style={{
                                                    fontSize: '18px',
                                                    marginLeft: '10px',
                                                    color: '#f4594f',
                                                }}
                                            >
                                                You have not selected items for all the slots
                                            </span>
                                        ) : null}
                                    </div>

                                    <div
                                        style={{
                                            marginTop: '10px',
                                            overflowX: 'auto',
                                        }}
                                        className="display--flex"
                                    >
                                        {mainObject[title].map((value, index) => {
                                            return (
                                                <div key={index} className={`${base}__card-wrapper`}>
                                                    <div
                                                        onClick={(e) => {
                                                            this.setMealList(index, title);
                                                        }}
                                                        className={
                                                            value.error
                                                                ? `${base}__card-selection ${base}__card-error`
                                                                : `${base}__card-selection`
                                                        }
                                                    >
                                                        {value.digital_menu_image ? (
                                                            <div
                                                                style={{
                                                                    backgroundImage: `url(${value.digital_menu_image})`,
                                                                }}
                                                                className={
                                                                    title == 'Mains'
                                                                        ? `${base}__center-digital-main-image`
                                                                        : `${base}__center-digital-image`
                                                                }
                                                            ></div>
                                                        ) : (
                                                            <img
                                                                className={`${base}__center-icon`}
                                                                src="/yellow-add-btn.png"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className={`${base}__card-txt`}>
                                                        {value.error ? (
                                                            <span
                                                                style={{
                                                                    color: '#f4594f',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                You need to select an item
                                                            </span>
                                                        ) : (
                                                            value.title_bold
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const store = this.props.terminalMenuStore;

        return (
            <div className={base}>
                {this.renderMeals()}

                <Modal
                    showModal={Boolean(store.showingPopup)}
                    showCloseIcon={false}
                    contentClasses="modal__content"
                    onBackdropClicked={this.handleClosePopup}
                    disableScrollingOnMobile={true}
                >
                    {store.showingPopup === 'reset-kiosk' ? (
                        <PopupResetKiosk onClosePopup={this.handleClosePopup} />
                    ) : null}
                </Modal>
            </div>
        );
    }
}

export default withRouter(DealComboSelection);
