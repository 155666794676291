export const UserAddressSettings = {
    saveCurrentAddress(address) {
        try {
            localStorage.setItem('current_address', JSON.stringify(address));
        } catch (ex) {
            throw new Error(
                'Please deactivate the Private Browsing mode to continue.'
            );
        }
    },

    getCurrentAddress() {
        try {
            return JSON.parse(localStorage.getItem('current_address'));
        } catch (ex) {
            return null;
        }
    },

    clear() {
        localStorage.removeItem('current_address');
    },
};
