import React, { Component } from 'react';
import BasePage from './basePage';
import { Helmet } from 'react-helmet';
import ShoppingCartRoot from '../components/ShoppingCartRoot';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

export default class extends Component {
    componentDidMount() {
        document.body.classList.add('shopping-cart');
    }

    render() {
        return (
            <div>
                <Helmet>
                    <meta name="nosnippet" />
                    <title>{services.getTitle('shoppingCart')}</title>
                    {localizationTags(`cart`).map((tag) => tag)}
                    {getCanonicalTag(`cart`)}
                </Helmet>
                <BasePage />
                <ShoppingCartRoot />
            </div>
        );
    }
}
