import React from 'react';

import { inject, observer } from 'mobx-react';

const base = 'terminal-coupon-code';
@inject('terminalCartStore')
@inject('terminalMenuStore')
@observer
export default class CouponCode extends React.Component {
    openCoupon = async () => {
        const { terminalMenuStore } = this.props;

        terminalMenuStore.checkCoupon(terminalMenuStore.couponTxt);
    };
    render() {
        const { couponErrMessage, couponDisable, setCouponTxt, couponTxt } =
            this.props.terminalMenuStore;

        return (
            <div className={base}>
                <div className={`${base}__coupon-header`}>
                    Do you have a coupon code?
                </div>
                <div style={{ marginTop: '24px' }}>
                    <input
                        onChange={(e) => {
                            setCouponTxt(e.target.value);
                        }}
                        disabled={couponDisable}
                        placeholder="Enter coupon code here"
                        value={couponTxt}
                        className={
                            couponErrMessage
                                ? `${base}__coupon-input-txt-disabled`
                                : `${base}__coupon-input-txt`
                        }
                        type="text"
                    />
                </div>
                <div style={{ marginTop: '24px' }}>
                    <button
                        onClick={(e) => {
                            this.openCoupon();
                        }}
                        disabled={couponDisable ? true : false}
                        className={
                            couponDisable
                                ? `${base}__coupon-button-disabled`
                                : `${base}__coupon-button`
                        }
                    >
                        Confirm
                    </button>
                </div>
                <div
                    style={{
                        marginTop: '24px',
                        fontWeight: 700,
                    }}
                >
                    {couponErrMessage ? (
                        <span style={{ color: '#f4594f' }}>
                            {couponErrMessage}
                        </span>
                    ) : couponDisable ? (
                        <div style={{ color: 'white' }}>
                            Coupon activated successfully!
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}
