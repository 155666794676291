import { observable, action } from 'mobx';
import Bacon from 'baconjs';
import Notifiable from '../services/notifiable';
import { loadScript } from '../../services/domUtils';
import { services } from '../../services/index';
import { getCardTypeText } from '../../services/paymentProviders';

const { clevertap } = window;

export default class extends Notifiable {
    @observable message = '';
    @observable isBusy = true;
    @observable isAddingCreditCard = false;
    @observable cardNumber = '';
    @observable cvc = '';
    @observable holderName = '';
    @observable expiryMonth = '';
    @observable expiryYear = '';
    @observable generationTime = '';

    @action updateModel(e) {
        this[e.currentTarget.name] = e.currentTarget.value;
    }

    newCard = null;
    onCreditCardAdded$ = new Bacon.Bus();
    csaInstance;

    constructor() {
        super();

        this.setUpNotification();
    }

    initOmise() {
        loadScript('https://cdn.omise.co/omise.js', () => {
            // Set your Omise public key to authenticate against Omise API. This allows card information to be sent directly to Omise.
            window.Omise.setPublicKey(services.getParam('omiseKey'));

            this.isBusy = false;
        });
    }

    doAddCreditCard() {
        this.isAddingCreditCard = true;
        var cardForOmise = {
            name: this.holderName,
            number: this.cardNumber.replace(/\s+/g, '').substr(0, 16),
            expiration_month: this.expiryMonth,
            expiration_year: this.expiryYear,
            security_code: this.cvc,
        };

        window.Omise.createToken(
            'card',
            cardForOmise,
            (statusCode, response) => {
                if (
                    response.object === 'error' ||
                    !response.card.security_code_check
                ) {
                    // Display an error message.
                    var message_text =
                        'SET YOUR SECURITY CODE CHECK FAILED MESSAGE';
                    if (response.object === 'error') {
                        message_text = response.message;
                        this.message = message_text;
                    }
                    this.isAddingCreditCard = false;
                } else {
                    services.api
                        .CreateCreditCardOmise({ token: response.id })
                        .then(::this.setNewCard)
                        .then(::this.sendTrackingEvents)
                        .then(() => this.onCreditCardAdded$.push(this.newCard))
                        .catch((error) => {
                            this.message = error.message;
                        })
                        .then(() => {
                            this.isAddingCreditCard = false;
                        });
                }
            }
        );
    }

    sendTrackingEvents() {
        const method =
            this.newCard['payment_method_type'] === 'Card'
                ? getCardTypeText(this.newCard)
                : this.newCard['payment_method_type'];

        clevertap.event.push('Payment method added', {
            'payment method': method,
        });

        window.dataLayer.push({ event: 'AddPaymentInfo' });
    }

    reset() {
        this.isBusy = true;
        this.message = '';
        this.newCard = null;
    }

    setNewCard(newCard) {
        this.newCard = newCard;
    }
    setCreditCardValue(value) {
        this.cardNumber = value;
    }
}
