import React from 'react';
import intl from 'react-intl-universal';

const base = 'delivery-area__popup-success';

export default class extends React.Component {
    render() {
        return (
            <div className={base}>
                <h1 className={`${base}__heading text--bold text--sofiapro`}>
                    {intl.get('deliveryArea.popupSuccessHeading')}
                </h1>
                <div className="separator separator--green mbl mtl" />
                <p className={`${base}__subheading mbl`}>
                    {intl.get('deliveryArea.popupSuccessSubheading')}
                </p>
                <a href="/menu">
                    <button className="button button--success width--100 button--bigger-on-mobile">
                        {intl.get('deliveryArea.popupSuccessCTA')}
                    </button>
                </a>
            </div>
        );
    }
}
