import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

const base = 'floating-cta';

@inject('userStore')
@observer
export default class FloatingCta extends Component {
    render() {
        const {
            centerText,
            leftText = ' ',
            rightText = ' ',
            onClick,
            customLeftTextClass,
            padding,
            busy,
            disabled,
            marginTop,
            customButtonClass,
            topText,
            showDesktop,
        } = this.props;

        return (
            <div style={{ marginTop: marginTop ? marginTop + 'px' : '80px' }}>
                <div
                    className={`${base} ${topText ? 'with-bg' : ''} ${
                        showDesktop ? '' : 'hide--if-large'
                    }`}
                    style={{
                        padding: padding ? `0 ${padding}px` : '0 25px',
                        backgroundColor: topText ? 'white' : '',
                    }}
                >
                    {topText && (
                        <p className={`${base}__top-text`}>{topText}</p>
                    )}
                    <button
                        className={`${base}__button button ${
                            !busy && !disabled ? 'button--success' : ''
                        } ${busy && 'button--busy'} ${
                            disabled && 'button--disabled'
                        } ${customButtonClass ? customButtonClass : ''}`}
                        onClick={onClick}
                    >
                        <div className={`${base}__button-details-container`}>
                            <span
                                className={`${base}__left-text ${customLeftTextClass}`}
                            >
                                {leftText}
                            </span>
                            <span className={`${base}__cta`}>{centerText}</span>
                            <span className={`${base}__right-text`}>
                                {rightText}
                            </span>
                        </div>
                    </button>
                </div>
            </div>
        );
    }
}
