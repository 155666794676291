import React, { Component } from 'react';
import HalalDropDown from '../HalalDropDown';
import intl from 'react-intl-universal';

const base = 'halal-preview';

export default class HalalPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHalalDropdown: false,
        };
        this.hideDropDown = this.hideDropDown.bind(this);
        this.showDropDown = this.showDropDown.bind(this);
    }
    showDropDown() {
        this.setState({ showHalalDropdown: true });
    }
    hideDropDown() {
        this.setState({ showHalalDropdown: false });
    }
    render() {
        const { showHalalDropdown } = this.state;
        return (
            <div>
                <span
                    onMouseEnter={this.showDropDown}
                    onClick={this.showDropDown}
                >
                    {intl.get('headers.menuHeader.halal')}
                </span>
                <HalalDropDown
                    isShowing={showHalalDropdown}
                    onClickOutside={this.hideDropDown}
                    width={'210px'}
                >
                    <div className={`${base}__content pts pbs`}>
                        <p className={`${base}__text`}>
                            {intl.get('dish.halal_description')}
                        </p>
                    </div>
                </HalalDropDown>
            </div>
        );
    }
}
