import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { services } from '../../services/index';
import { observer, inject } from 'mobx-react';
import { saveLangToStorage, LOCALES } from '../../services/translation';

@observer
class LanguageSelector extends Component {
    state = {
        expand: false,
        lang: null,
    };

    handleSelector = () => {
        this.setState({ expand: !this.state.expand });
    };

    handleClickOutside = () => {
        this.setState({ expand: false });
    };

    setLanguage = (lang) => {
        return new Promise((resolve) => {
            saveLangToStorage(lang);
            this.setState({ lang });
            services.api.SetLanguage(lang);
            resolve((window.location.href = `/`));
        });
    };

    getLanguage = () => {
        const lang = localStorage.getItem('lang');
        return LOCALES[lang]['name'];
    };

    checkMark = (base) => {
        return (
            <svg className={`${base}__check iconmoon-icon`}>
                <use xlinkHref="#icon-checkmark-noborder-account-switcher" />
            </svg>
        );
    };

    componentDidMount() {
        const lang = localStorage.getItem('lang');
        if (lang) {
            this.setState({ lang });
        } else {
            this.setState({ lang: 'en' });
        }
    }

    render() {
        const { expand, lang } = this.state;
        const { base } = this.props;

        return (
            <div>
                {expand && (
                    <div className={`${base}__selector-container`}>
                        {Object.entries(LOCALES).map(([locale, localeData]) => (
                            <a
                                key={locale}
                                className={
                                    `${base}__selector link--no-underline text--black fs--small-medium ms ` +
                                    (lang === localeData.value
                                        ? ''
                                        : 'cursor-pointer')
                                }
                                onClick={() =>
                                    lang === localeData.value
                                        ? null
                                        : this.setLanguage(localeData.value)
                                }
                            >
                                <div className="display--flex">
                                    <span>{localeData.name}</span>
                                    {lang === localeData.value
                                        ? this.checkMark(base)
                                        : null}
                                </div>
                            </a>
                        ))}
                    </div>
                )}
                <div onClick={this.handleSelector}>
                    <a
                        className={`${base}__selector-picker link--no-underline text--black fs--small-medium ms cursor-pointer`}
                    >
                        {this.getLanguage()}
                    </a>
                    <button type="button" className={`${base}__toggle`}>
                        <svg className={`${base}__collapse iconmoon-icon`}>
                            <use
                                xlinkHref={
                                    expand
                                        ? '#icon-chevron-thin-up'
                                        : '#icon-chevron-thin-down'
                                }
                            />
                        </svg>
                    </button>
                </div>
            </div>
        );
    }
}

export default inject('userStore')(onClickOutside(LanguageSelector));
