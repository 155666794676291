import React from 'react';
import { Provider } from 'mobx-react';

import Entry from './page/careersPage/Entry';
import MenuFooter from './core/MenuFooter';
import BaseLayout from './core/baseLayout/Master_BaseLayout';

import menuHeaderStore from '../store/menuHeaderStore';
import CareersHeader from './core/CareersHeader';

const { clevertap } = window;

export default class extends React.Component {
    componentDidMount() {
        clevertap.event.push('View Page', {
            'Page Title': document.title,
            'Page Type': 'Ride',
            'Page URL': window.location.href,
        });
    }
    renderHeader = () => <CareersHeader />;
    render() {
        return (
            <BaseLayout.Page HeaderComponent={this.renderHeader}>
                <main>
                    <Entry />
                </main>
                <Provider menuHeaderStore={menuHeaderStore}>
                    <MenuFooter />
                </Provider>
            </BaseLayout.Page>
        );
    }
}
