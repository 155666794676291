import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

const base = 'terminal-address-header';

@inject('terminalAddressStore')
@observer
export default class extends React.Component {
    handleGoBackStep1() {
        const { terminalAddressStore } = this.props;

        terminalAddressStore.setStep(terminalAddressStore.STEP_INPUT_ADDRESS);
        terminalAddressStore.setPlace(null);
        terminalAddressStore.resetNewAddress();
    }

    renderBackLink() {
        const { terminalAddressStore } = this.props;

        if (
            terminalAddressStore.currentStep ===
            terminalAddressStore.STEP_INPUT_ADDRESS
        ) {
            return (
                <a href="/terminal">
                    <svg className="text--primary iconmoon-icon position--absolute">
                        <use xlinkHref="#icon-chevron-thin-left" />
                    </svg>
                </a>
            );
        }

        return (
            <svg
                className="text--primary iconmoon-icon position--absolute"
                onClick={::this.handleGoBackStep1}
            >
                <use xlinkHref="#icon-chevron-thin-left" />
            </svg>
        );
    }

    render() {
        const store = this.props.terminalAddressStore;

        return (
            <div className={base}>
                {this.renderBackLink()}
                <center>
                    {store.currentStep === store.STEP_INPUT_ADDRESS
                        ? intl.get('terminal.addressHeader.addAddress')
                        : ''}
                    {store.currentStep === store.STEP_ADJUST_ADDRESS
                        ? intl.get('terminal.addressHeader.addAddressContact')
                        : ''}
                </center>
            </div>
        );
    }
}
