import React, { Fragment } from 'react';
import { inject, Provider, observer } from 'mobx-react';
import get from 'lodash/get';
import BaseLayout from './core/baseLayout/Master_BaseLayout';

import SectionHero from './page/landingPage/SectionHero';
import CalendarStore from '../store/calendarStore';
import menuHeaderStore from '../store/menuHeaderStore';
import MenuHeader from './core/headers/MenuHeader';
import MenuFooter from './core/MenuFooter';
import MenuList from './page/menuPage/MenuList';
import setUpStoreInteractions from '../store/interactions/menuInteractions';
import { SMALL_SCREEN_MEDIA } from '../services/constants';
import SectionAbout from './page/landingPage/SectionAbout';
import SectionDeliveryAreas from './page/landingPage/SectionDeliveryAreas';
import SectionFood from './page/landingPage/SectionFood';
import { cities, GetCity } from '../services/city';
import SectionStats from './page/landingPage/SectionStats';
import LoginPopup from './core/LoginPopup';
import menuListStore from '../store/menuListStore';
import cartStore from '../store/cartStore';
import AddressSelectorPopup from './core/AddressSelectorPopup';
import { CircleLoader } from './core/Loading';
import HalalPopup from './core/HalalPopup';

const { clevertap } = window;

const calendarStore = new CalendarStore();

const headerStores = {
    menuHeaderStore,
};

const stores = {
    menuHeaderStore,
    calendarStore,
};

setUpStoreInteractions(calendarStore, menuListStore, cartStore);

@inject('loginPopupStore', 'menuCheckoutStore', 'userStore')
@observer
class LandingPage extends React.Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();
        this.state = {
            heroWidth: 0,
            showAddressSelector: false,
            showDateTimeSelector: false,
        };
    }

    componentDidMount() {
        const { variety } = this.props;

        if (document.getElementById('sectionHeroContainer')) {
            this.setState({
                heroWidth: document.getElementById('sectionHeroContainer')
                    .offsetWidth,
            });
        }

        calendarStore.loadTimeSettings();

        clevertap.event.push('View Page', {
            'Page Type': get(variety, 'mixPanelPage', 'Onboarding Page'),
            'Page Title': get(variety, 'pageTitle', ''),
        });

        this.handleWindowScroll();
        window.addEventListener('scroll', this.handleWindowScroll);

        this.isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    handleWindowScroll = () => {
        if (window.scrollY > 0) {
            this.setState({ stickyHeader: true });
        } else {
            this.setState({ stickyHeader: false });
        }
    };

    renderHeader = () => {
        const { stickyHeader } = this.state;
        if (menuListStore.activeDateObj) {
            return (
                <Provider {...headerStores}>
                    {stickyHeader ? (
                        <MenuHeader onboarding={true} />
                    ) : (
                        <MenuHeader
                            customClasses={'landing-header'}
                            onboarding={true}
                            whiteIcons={true}
                        />
                    )}
                </Provider>
            );
        } else {
            return <div />;
        }
    };

    renderOnboarding = () => {
        const { cuisine } = this.props;
        return (
            <Fragment>
                <div style={{ marginTop: '50px' }} ref={this.myRef}>
                    <MenuList landing />
                </div>
                <Fragment>
                    <SectionAbout cuisine={cuisine} />
                    <SectionDeliveryAreas />
                    <SectionFood />
                </Fragment>
            </Fragment>
        );
    };

    render() {
        const { props } = this;
        const {
            userStore: { showHalalPopup, setHideHalalPopup },
        } = props;
        const smallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return (
            <Provider {...stores}>
                <div>
                    {props.variety ? props.variety.GCE : null}
                    <BaseLayout.Page HeaderComponent={this.renderHeader}>
                        <main>
                            <LoginPopup
                                dismissable={true}
                                showCloseIcon={true}
                            />
                            {menuListStore.activeDateObj ? (
                                <Fragment>
                                    <AddressSelectorPopup />
                                    <SectionHero {...props} />
                                    {!smallScreen && (
                                        <SectionStats
                                            showReferralOnboarding={false}
                                        />
                                    )}
                                    {showHalalPopup && smallScreen && (
                                        <HalalPopup
                                            showModal={showHalalPopup}
                                            handleClose={setHideHalalPopup}
                                        />
                                    )}
                                </Fragment>
                            ) : (
                                <div className="menu__list--loading text--center">
                                    <div className="display--inline-block text--center">
                                        <CircleLoader className="m0a" />
                                        <p className="mtm">Loading...</p>
                                    </div>
                                </div>
                            )}
                            {this.renderOnboarding()}
                        </main>
                        {menuListStore.activeDateObj && (
                            <MenuFooter sticky={!smallScreen} />
                        )}
                    </BaseLayout.Page>
                </div>
            </Provider>
        );
    }
}

export default LandingPage;
