import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { SMALL_SCREEN_MEDIA } from '../../../../services/constants';
import FloatingCta from '../../../core/FloatingCta';

const base = 'delivery-note-explain';

@inject('deliveryAddressStore')
@observer
export default class extends React.Component {
    render() {
        const store = this.props.deliveryAddressStore;

        return (
            <div className="ptxl lh--150 shopping-cart-address__popup--explaining">
                <div className={`${base}__content`}>
                    <h3 className={`${base}__options-title text--sofiapro`}>
                        {intl.get('deliveryAddress.popupExplain.title')}
                    </h3>
                    <div>
                        {this.props.options.map((option) =>
                            option.preferred ? (
                                <div className="display--flex">
                                    <div>
                                        <div className="display--flex">
                                            <p
                                                className={`text--sofiapro fs--small-medium`}
                                            >
                                                {option.title}
                                            </p>
                                            <div className={`${base}__icon`} />
                                        </div>
                                        <p
                                            className={`${base}__option-description fs--small text--dark pts`}
                                        >
                                            {option.description}
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div className="display--flex">
                                    <div>
                                        <p className="text--sofiapro fs--small-medium">
                                            {option.title}
                                        </p>
                                        <p
                                            className={`${base}__option-description fs--small text--dark pts`}
                                        >
                                            {option.description}
                                        </p>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                    <hr style={{ margin: '20px 0' }} />
                    <div className={`${base}__explanation-title-container`}>
                        <h3
                            className={`${base}__explanation-title text--sofiapro`}
                        >
                            {intl.get(
                                'deliveryAddress.popupExplain.bestOptionsTitle'
                            )}
                        </h3>
                        <div
                            className="fs--small"
                            dangerouslySetInnerHTML={{
                                __html: intl.get(
                                    'deliveryAddress.popupExplain.bestOptionsDetail'
                                ),
                            }}
                        />
                    </div>
                    <div
                        className="fs--small width--75 hide--if-large"
                        dangerouslySetInnerHTML={{
                            __html: intl.get(
                                'deliveryAddress.popupExplain.contact'
                            ),
                        }}
                        style={{ marginTop: '10px' }}
                    />
                    {window.matchMedia(SMALL_SCREEN_MEDIA).matches === true ? (
                        <FloatingCta
                            centerText={intl.get(
                                'deliveryAddress.popupExplain.CTA'
                            )}
                            onClick={() => store.showPopup(store.POPUP_NOTE)}
                        />
                    ) : (
                        <button
                            className={`${base}__CTA button button--success button--bigger-on-mobile`}
                            onClick={() => store.showPopup(store.POPUP_NOTE)}
                        >
                            {intl.get('deliveryAddress.popupExplain.CTA')}
                        </button>
                    )}
                    <div
                        className="fs--small width--75 hide--if-small"
                        dangerouslySetInnerHTML={{
                            __html: intl.get(
                                'deliveryAddress.popupExplain.contact'
                            ),
                        }}
                    />
                </div>
            </div>
        );
    }
}
