import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const base = 'section-food';
const dishes = [
    {
        slug: 'malaysian',
        cuisine: 'Malaysian',
        imageUrl:
            'https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/food-image-1.jpg',
    },
    {
        slug: 'healthy',
        cuisine: 'Healthy',
        imageUrl:
            'https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/food-image-11.jpg',
    },
    {
        slug: 'vegetarian',
        cuisine: 'Vegetarian',
        imageUrl:
            'https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/food-image-2.jpg',
    },
    {
        slug: 'international',
        cuisine: 'International',
        imageUrl:
            'https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/food-image-3.jpg',
    },
    {
        slug: 'western',
        cuisine: 'Western',
        imageUrl:
            'https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/food-image-4.jpg',
    },
    {
        slug: 'thai',
        cuisine: 'Thai',
        imageUrl:
            'https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/food-image-5.jpg',
    },
    {
        slug: 'chinese',
        cuisine: 'Chinese',
        imageUrl:
            'https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/food-image-6.jpg',
    },
    {
        slug: 'indian',
        cuisine: 'Indian',
        imageUrl:
            'https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/food-image-7.jpg',
    },
    {
        slug: 'italian',
        cuisine: 'Italian',
        imageUrl:
            'https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/food-image-8.jpg',
    },
    {
        slug: 'desserts',
        cuisine: 'Desserts',
        imageUrl:
            'https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/food-image-12.jpg',
    },
    {
        slug: 'snacks',
        cuisine: 'Snacks',
        imageUrl:
            'https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/food-image-10.jpg',
    },
];

export default class SectionFood extends Component {
    state = {
        imageWidth: 0,
    };

    handleRenderGradientImage = (dish) => {
        return `linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 40%,rgba(0,0,0,0) 40%,rgba(0,0,0,0)), url(${dish.imageUrl})`;
    };

    setWidth = () => {
        this.setState({
            imageWidth: document.getElementsByClassName(`${base}__image`)[0]
                .offsetWidth,
        });
    };

    componentDidMount() {
        this.setWidth();
    }

    render() {
        const { imageWidth } = this.state;
        const imageHeight = imageWidth * 0.7;
        return (
            <div className={`${base} container`}>
                <div className={`${base}__header`}>
                    <h3 className="text--bolder">
                        Delicious food of various types
                    </h3>
                </div>
                <div className={`${base}__dish-container`}>
                    {dishes.map((dish) => (
                        <Link
                            key={dish.cuisine}
                            to={`/food-delivery/${dish.slug}${
                                dish.slug === 'desserts' ||
                                dish.slug === 'snacks'
                                    ? ''
                                    : '-food'
                            }`}
                        >
                            <div
                                className={`${base}__image hide--if-small`}
                                style={{
                                    background:
                                        this.handleRenderGradientImage(dish),
                                    height: imageHeight + 'px',
                                }}
                            >
                                <span
                                    className={`${base}__dish-name text--bolder`}
                                >
                                    {dish.cuisine}{' '}
                                    {dish.slug === 'desserts' ||
                                    dish.slug === 'snacks'
                                        ? ''
                                        : 'food'}
                                </span>
                            </div>
                            <span
                                className={`${base}__dish-name hide--if-large`}
                            >
                                {dish.cuisine}{' '}
                                {dish.slug === 'desserts' ||
                                dish.slug === 'snacks'
                                    ? ''
                                    : 'food'}
                            </span>
                        </Link>
                    ))}
                </div>
            </div>
        );
    }
}
