import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import LoginPopup from '../../core/LoginPopup';
import PaymentInProgress from '../../core/PaymentInProgress';
import CompleteSignUp from '../../core/completeSignUp/Widget';
import { services } from '../../../services/index';

import UserInfo from './UserInfo';
import PaymentManagement from './PaymentManagement';
import Cart from './Cart';
import PlaceOrderButton from './PlaceOrderButton';
import EmptyCart from './EmptyCart';
import ConfirmPayment from './ConfirmPayment';
import OrderSummary from './OrderSummary';
import BankSelector from './BankSelector';
import { when } from 'mobx';
import BoostPopup from '../../core/BoostPopup';
import HalalPopup from '../../core/HalalPopup';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

@inject('store')
@inject('loginPopupStore')
@inject('headerStore')
@inject('userStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const walletPackage = services.api.SelectingWalletPackage.get();
        const { store, loginPopupStore } = this.props;

        loginPopupStore.checkUser();

        if (walletPackage) {
            store.setView(store.CHECKOUT_VIEW);
            store.setWalletPackage(walletPackage);

            store.getAutoTopupEnabled();
        } else {
            store.setView(store.EMPTY_VIEW);
        }

        when(
            () => store.paymentMethod && store.walletOrderId,
            () => {
                if (store.paymentMethod.id === 'boost') {
                    store.startPolling();
                }
            }
        );
    }

    renderBankSelector() {
        return services.getParam('city') === 'Bangkok' && <BankSelector />;
    }
    render() {
        const { userStore } = this.props;
        const { showHalalPopup, setHideHalalPopup } = userStore;
        const isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
        return (
            <Fragment>
                {this.renderMainContent()}
                {showHalalPopup && isSmallScreen && (
                    <HalalPopup
                        showModal={showHalalPopup}
                        handleClose={setHideHalalPopup}
                    />
                )}
            </Fragment>
        );
    }
    renderMainContent() {
        const { store, headerStore } = this.props;

        if (store.view === store.CHECKOUT_VIEW) {
            return (
                <div className="row checkout__container m0--if-small">
                    {store.openBoostPopup && (
                        <BoostPopup
                            store={store}
                            headerStore={headerStore}
                            placeOrder={store.placeOrderWithOnlineBanking_boost}
                        />
                    )}
                    <LoginPopup />
                    <div className="col-lg-7 col-md-7 col-sm-7 checkout__user-info">
                        <UserInfo />
                        <CompleteSignUp />
                        <PaymentManagement />
                        {this.renderBankSelector()}
                        <div className="hide--if-large">
                            <OrderSummary />
                        </div>
                        <div className="text--center">
                            <PlaceOrderButton />
                        </div>
                        <div className="mbxl" />
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-5 checkout__cart-info">
                        <Cart />
                    </div>
                </div>
            );
        }

        if (store.view === store.EMPTY_VIEW) {
            return (
                <div className="row">
                    <div className="col-lg-12">
                        <LoginPopup />
                        <EmptyCart />
                    </div>
                </div>
            );
        }

        if (store.view === store.PAYMENT_IN_PROGRESS_VIEW) {
            return <PaymentInProgress color="Green" />;
        }

        if (store.view === store.CONFIRM_VIEW) {
            return (
                <div>
                    <ConfirmPayment />
                </div>
            );
        }

        return <div />;
    }
}
