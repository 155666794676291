import React from 'react';
import { inject, observer } from 'mobx-react';

import Modal from '../../../core/Modal';
import { Fragment } from 'react-is';

const base = 'terminal-mobile-cart-item';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    state = {
        showEditModal: false,
        showRemoveMealModal: false,
    };

    handleEditClick = () => {
        this.setState({ showEditModal: true });
    };

    handleClosePopup = () => {
        this.setState({ showEditModal: false, showRemoveMealModal: false });
    };

    handleDecreaseItemIconClick = () => {
        const { item, terminalCartStore } = this.props;
        const itemQuantity = terminalCartStore.cartServices.getQuantityOfDishOn({
            dishId: item.today_menu_id,
        });
        if (itemQuantity === 1) {
            //show the keep/remove modal only when the itemQuanity is 1 and decreasing it will remove the item from cart completely
            this.setState({ showEditModal: false, showRemoveMealModal: true });
        } else {
            terminalCartStore.removeItemFromCart(item);
        }
        terminalCartStore.getTotalPrice();
    };

    handleIncreaseItemIconClick = () => {
        const { item } = this.props;
        this.props.terminalCartStore.addToCart(item);
        this.props.terminalCartStore.getTotalPrice();
    };

    handleKeepClick = () => {
        this.setState({ showRemoveMealModal: false });
    };

    handleRemoveMealClick = () => {
        const { item, removeMealCallback } = this.props;
        this.props.terminalCartStore.removeItemFromCart(item);
        this.setState({ showEditModal: false, showRemoveMealModal: false });
        removeMealCallback();
    };

    render() {
        const { terminalCartStore, item } = this.props;
        const { showEditModal, showRemoveMealModal } = this.state;
        const itemQuantity = terminalCartStore.cartServices.getQuantityOfDishOn({
            dishId: item.today_menu_id,
        });
        const itemPrice = terminalCartStore.getPriceOfItemInCart(item);

        return (
            <Fragment>
                <div className={`${base}`} onClick={this.handleEditClick}>
                    <div className={`${base}__img_title_block`}>
                        <div className={`${base}__item_img_container`}>
                            <img src={item.image} className="image" alt="item image" />
                            <p className="item_quantity">x{itemQuantity}</p>
                        </div>
                        <div className={`${base}__title_edit`}>
                            <p className="title">{item.title_bold}</p>
                            <button className="edit_button" onClick={this.handleEditClick}>
                                Edit
                            </button>
                        </div>
                    </div>
                    <p className={`${base}__price`}>RM {itemPrice}</p>
                </div>

                {(showEditModal || showRemoveMealModal) && (
                    <Modal
                        showModal={showEditModal || showRemoveMealModal}
                        showCloseIcon={false}
                        onBackdropClicked={this.handleClosePopup}
                        disableScrollingOnMobile={false}
                        showBackdropOnMobile={true}
                        contentClasses={'rounded'}
                        manualStyles={{ width: '295px' }}
                    >
                        {showEditModal && (
                            <div className={`${base}__edit_modal`}>
                                <p className={`${base}__modal_title`}>{item.title_bold}</p>
                                <div className={`${base}__meal_inc_dec_num`}>
                                    <button onClick={this.handleDecreaseItemIconClick} className="inc_dec_btn">
                                        <img
                                            src="https://image.popcontent.link/minus_icon.svg"
                                            alt="minus icon"
                                        />
                                    </button>
                                    <div className="n_items">{itemQuantity}</div>
                                    <button onClick={this.handleIncreaseItemIconClick} className="inc_dec_btn">
                                        <img
                                            src="https://image.popcontent.link/plus_icon.svg"
                                            alt="plus icon"
                                        />
                                    </button>
                                </div>
                                <button onClick={this.handleClosePopup} className={`${base}__modal_cta_btn`}>
                                    Done
                                </button>
                            </div>
                        )}
                        {showRemoveMealModal && (
                            <div className={`${base}__edit_modal`}>
                                <p className={`${base}__remove_modal_title`}>
                                    Are you sure you want to remove this item?
                                </p>
                                <button onClick={this.handleKeepClick} className={`${base}__modal_cta_btn`}>
                                    Keep
                                </button>
                                <button onClick={this.handleRemoveMealClick} className={`${base}__modal_remove_btn`}>
                                    Remove
                                </button>
                            </div>
                        )}
                    </Modal>
                )}
            </Fragment>
        );
    }
}
