import React from 'react';
import { inject, observer } from 'mobx-react';
import template from 'lodash/template';
import { fromTimestamp } from '../../../services/dateTime';

import intl from 'react-intl-universal';

const base = 'order-status-banner';

@inject('orderStatusStore')
@observer
export default class extends React.Component {
    render() {
        const { orderStatusStore } = this.props;
        const startTime = fromTimestamp(
            orderStatusStore.timeSlotStartUTC
        ).toString('h:mm TT');
        const endTime = fromTimestamp(orderStatusStore.timeSlotEndUTC).toString(
            'h:mm TT'
        );

        return (
            <div className={`${base}__content ${base}__content--pending`}>
                <p className={`${base}__text ${base}__text--large`}>
                    {template(intl.get('orderStatus.pending.line1'))({
                        order_id: orderStatusStore.orderId,
                    })}
                </p>
                <p className={`${base}__text`}>
                    {intl.get('orderStatus.pending.line2')}
                </p>
                <p className={`${base}__text ${base}__text--small`}>
                    {template(intl.get('orderStatus.pending.line3'))({
                        time: startTime + ' - ' + endTime,
                    })}
                </p>
            </div>
        );
    }
}
