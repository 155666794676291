import { action, observable, when } from 'mobx';
import { RESOURCE } from '../services/types';
import Notifiable from './services/notifiable';
import { services } from '../services/index';
import Cookies from 'js-cookie';
import {
    clearAutoAssignDiscountCampaign,
    seenAutoAssignedCodeResponseBanner,
    setSeenAutoAssignedCodeResponseBanner,
} from '../services/autoAssignDiscount';
import {
    clearReferralItemsFromLocalStorage,
    setShowExistingCreditsErrorBanner,
    setShowExsitingUserErrorBanner,
    setShowReferralSuccessBanner,
} from '../services/referrals';
import { currency } from '../services';
import Bacon from 'baconjs';
import loginPopupStore from '../store/loginPopupStore';
import rewardsPopupStore from '../store/rewardsPopupStore';
import menuCheckoutStore from '../store/menuCheckoutStore';
import userStore from '../store/userStore';
import {
    removeRewardToApply,
    rewardToApply,
    setRewardToApply,
    showRewardsToApplyBanner,
} from '../services/rewards';

const { clevertap } = window;

class RewardsStore extends Notifiable {
    @observable rewards = RESOURCE.Initial;
    @observable rules = RESOURCE.Initial;
    @observable message = '';
    @observable code = '';
    @observable amount = 0;
    @observable isBusyApplyCode = false;
    @observable isBusySendSMS = false;
    @observable phone = '';
    @observable countrycode = '+60';
    @observable.ref result = null;
    @observable success;
    @observable smsMessage;
    @observable referralLink = null;
    @observable user;
    @observable shareText;
    @observable canRefer;
    @observable shownCreditsConfirmed = false;
    @observable shownBanner = false;
    @observable autoAssignedCode = Cookies.get('promotion');
    @observable autoAssignedCodeAmount = Cookies.get('benefit');
    @observable generalRewards = [];
    @observable userRewards = [];
    @observable loading = true;

    onGetDiscountResult$ = new Bacon.Bus();
    onGetDiscountResultWallet$ = new Bacon.Bus();

    constructor() {
        super();

        this.setUpNotification({ showCloseIcon: false });
    }

    /*
    Checks eligibility of a user to claim a referral code. Checks if the user has existing credits or has any previous or upcoming orders.
    */
    @action checkEligibility = (referringUserUsername, status) => {
        if (
            referringUserUsername &&
            !localStorage.getItem('show_referral_success_banner')
        ) {
            this.getUser().then((user) => (this.user = user));
            when(
                () => this.user,
                () => {
                    // checks if user has existing credits
                    services.api.GetCredits().then((response) => {
                        if (response.total_rm === 0) {
                            // checks past order history of user
                            services.api
                                .GetPastOrders()
                                .then((history) => {
                                    // checks if user has any upcoming orders
                                    services.api
                                        .GetUpcomingOrders()
                                        .then((upcoming) => {
                                            if (
                                                history.deliveries.length ===
                                                    0 &&
                                                upcoming.deliveries.length === 0
                                            ) {
                                                if (status === 'logged_in') {
                                                    services.bannerEvents.showSuccess(
                                                        'Credits successfully applied! Order now'
                                                    );
                                                } else {
                                                    setShowReferralSuccessBanner();
                                                }
                                                this.shownBanner = true;
                                                clevertap.event.push(
                                                    'Received Referral Credits As Existing User'
                                                );
                                            } else {
                                                if (status === 'logged_in') {
                                                    clearReferralItemsFromLocalStorage();
                                                    services.bannerEvents.showError(
                                                        'Sorry! Referral rewards are only available for new users.'
                                                    );
                                                } else {
                                                    setShowExsitingUserErrorBanner();
                                                }
                                                this.shownBanner = true;
                                            }
                                        });
                                })
                                .catch((response) => {
                                    this.rewards = RESOURCE.Failure(
                                        response.message,
                                        response
                                    );
                                });
                        } else {
                            if (this.shownBanner === false) {
                                if (status === 'logged_in') {
                                    services.bannerEvents.showSuccess(
                                        'You already have credits in your account! Order now'
                                    );
                                } else {
                                    setShowExistingCreditsErrorBanner();
                                }
                                this.shownBanner = true;
                            }
                        }
                    });
                }
            );
        }
    };

    getUser() {
        const token = services.api.AccessTokenStorage.getToken();

        return new Promise((resolve, reject) => {
            if (token) {
                return services.api.GetUserWithMemoizer.run()
                    .then(resolve)
                    .catch(reject);
            }

            return reject();
        });
    }

    rewardToApply() {
        return rewardToApply();
    }

    @action getNextReward() {
        services.api.GetNextReward().then((res) => {
            if (res.id) {
                setRewardToApply(res.id);
                window.location.reload();
            }
        });
    }

    @action getRewards() {
        when(
            () => userStore.isLoggedIn,
            () => {
                this.rewards = RESOURCE.Loading;

                services.api
                    .GetCredits()
                    .then((response) => {
                        this.setRewards(response);
                    })
                    .catch((response) => {
                        this.rewards = RESOURCE.Failure(
                            response.message,
                            response
                        );
                    });
            }
        );
    }

    @action getRules() {
        this.rules = RESOURCE.Loading;
        when(
            () => userStore.isLoggedIn,
            () => {
                this.user = userStore.user.data;
                services.api
                    .GetRewardRules()
                    .then((response) => {
                        this.rules = RESOURCE.Success({
                            referring_user_amount:
                                response.referring_user_reward,
                            referred_user_amount: response.referred_user_reward,
                            referring_text: response.referring_text,
                        });
                        this.canRefer = response.can_refer;
                        this.referralLink = response.link_url;
                        this.shareText = response.share_text;
                    })
                    .catch(
                        (response) =>
                            (this.rules = RESOURCE.Failure(
                                response.message,
                                response
                            ))
                    );
            }
        );
    }

    handleGetAllRewards = () => {
        when(
            () => userStore.isLoggedIn,
            () => {
                this.loading = true;
                services.api
                    .GetAllRewards()
                    .then((res) => {
                        if (res.general_rewards) {
                            this.generalRewards = res.general_rewards;
                        }
                        if (res.my_rewards) {
                            this.userRewards = res.my_rewards;
                        }
                        this.loading = false;
                    })
                    .then(() => {
                        if (!this.rewardToApply()) {
                            this.getNextReward();
                        }
                    });
            }
        );
    };

    @action useReward = (rewardId, onCheckout) => {
        setRewardToApply(rewardId);
        if (onCheckout) {
            menuCheckoutStore.getOrderSummary();
            rewardsPopupStore.handleTogglePopup(false);
        } else {
            showRewardsToApplyBanner();
            window.location.href = '/menu';
        }
    };

    @action removeReward = () => {
        removeRewardToApply();
        menuCheckoutStore.getOrderSummary();
    };

    @action redeemCode({ discountAmount, discountType } = {}, onCheckout) {
        if (this.code || this.isBusyApplyCode) {
            this.isBusyApplyCode = true;

            services.api
                .RedeemCode({ code: this.code })
                .then((response) => {
                    this.getRewards();
                    this.result = response.message;
                    this.success = response.success;
                    if (this.success) {
                        this.useReward(response.reward_id, onCheckout);

                        if (response.general_rewards) {
                            this.generalRewards = response.general_rewards;
                        }
                        if (response.my_rewards) {
                            this.userRewards = response.my_rewards;
                        }
                        if (onCheckout) {
                            if (window.location.href.includes('wallet')) {
                                this.onGetDiscountResultWallet$.push(response);
                            }
                        }
                        clevertap.event.push(
                            'Reward code added successfully',
                            {
                                Code: this.code,
                            },
                            () => {
                                if (response.message.includes('Wallet')) {
                                    this.result =
                                        this.result + ' Refreshing cart ...';
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 4000);
                                }
                            }
                        );
                        if (discountAmount) {
                            if (discountType === 'percentage') {
                                services.bannerEvents.showSuccess(
                                    `Congratulations, you got ${
                                        discountAmount * 100
                                    }% off!`
                                );
                                setSeenAutoAssignedCodeResponseBanner();
                            } else {
                                services.bannerEvents.showSuccess(
                                    `Congratulations, you got ${currency.localCurrency(
                                        discountAmount
                                    )} off!`
                                );
                                setSeenAutoAssignedCodeResponseBanner();
                            }
                        } else {
                            services.bannerEvents.showSuccess(response.message);
                        }
                    } else if (!seenAutoAssignedCodeResponseBanner) {
                        this.trackAddingRewardFail(response.message);
                        services.bannerEvents.showWarning(response.message);
                        setSeenAutoAssignedCodeResponseBanner();
                    } else {
                        this.trackAddingRewardFail(response.message);
                        services.bannerEvents.showWarning(response.message);
                    }
                })
                .catch((response) => {
                    this.trackAddingRewardFail(response.message);
                    services.bannerEvents.showWarning(response.message);
                })
                .then(() => {
                    this.isBusyApplyCode = false;
                    if (!loginPopupStore.isOfflineCampaign) {
                        this.code = '';
                    }
                    this.result = '';
                    this.success = '';
                    clearAutoAssignDiscountCampaign();
                });
        }
    }

    @action trackAddingRewardFail = (message) => {
        clevertap.event.push('Reward Code Add Failed', {
            Code: this.code,
            Error: message,
        });
    };

    @action setRewards(response) {
        this.rewards = RESOURCE.Success({ ...response });
    }

    updateCode(e) {
        this.code = e.currentTarget.value;
    }

    updatePhone(e) {
        this.phone = e.currentTarget.value;
    }

    updateCountry(e) {
        this.countrycode = e.currentTarget.value;
    }

    smsMessageInvalid() {
        this.smsMessage = 'Invalid Phone Number';
        this.isBusySendSMS = false;
    }

    smsMessageValid() {
        this.smsMessage = 'SMS sent';
        this.isBusySendSMS = false;
    }

    setTotalAmount(amount) {
        this.amount = amount;
    }
}

const store = new RewardsStore();
export default store;
