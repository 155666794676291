import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import BrandBasePage from './brandBasePage';
import { parseUA } from '../services';

class BasePage extends Component {
    componentDidMount() {
        // adding classes to body element
        const browserInfo = this.getBrowser();
        const { browser, device } = browserInfo;
        const base = 'user-agent';

        let spaceClasses = [];
        const bodyClasses = [
            browser.name ? `${base}__browser-name--${browser.name}` : null,
            browser.major ? `${base}__browser-ver--${browser.major}` : null,
            device.type ? `${base}__device-type--${device.type}` : null,
            device.vendor ? `${base}__device-vendor--${device.vendor}` : null,
            device.model ? `${base}__device-model--${device.model}` : null,
        ].filter((element, index, bodyClasses) => {
            // check if there is a space in one of the user agent classes. if yes, split and insert into array
            if (element) {
                if (element.includes(' ')) {
                    spaceClasses.push(...bodyClasses[index].split(' '));
                }
                return !element.includes(' ');
            } else {
                return null;
            }
        });
        bodyClasses.push(...spaceClasses);

        const pageClasses = [
            'business',
            'menu',
            'dish',
            'delivery-area',
            'about',
            'chef',
            'promotion',
            'wallet',
            'profile',
            'order-history',
            'rewards',
            'shopping-cart',
            'careers',
            'ride',
            'terms',
            'referal',
        ];
        document.body.classList.remove(...pageClasses);
        document.body.classList.add(...bodyClasses);
    }

    // throw404() {
    //     return <Redirect to="/notfound" />
    //     // return { page: NotFoundPage };
    // }

    getBrowser() {
        return parseUA(window.navigator.userAgent);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <link rel="manifest" href="/manifest.json" />
                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/apple-touch-icon.png"
                    />
                    <link
                        rel="shortcut icon"
                        type="image/png"
                        href="/favicon.png"
                    />
                    <link
                        rel="icon"
                        sizes="192x192"
                        href="/android-chrome-192x192.png"
                    />
                    <link
                        rel="icon"
                        sizes="512x512"
                        href="/android-chrome-512x512.png"
                    />

                    <link
                        href="https://fonts.cdnfonts.com/css/gotham"
                        as="font"
                        crossOrigin
                    />

                    {/* pre-loading fonts */}
                    <link
                        rel="preload"
                        href="/fonts/barlow-regular.woff"
                        as="font"
                        crossOrigin
                    />
                    <link
                        rel="preload"
                        href="/fonts/barlow-regular.ttf"
                        as="font"
                        crossOrigin
                    />
                    <link
                        rel="preload"
                        href="/fonts/barlow-bold.woff"
                        as="font"
                        crossOrigin
                    />
                    <link
                        rel="preload"
                        href="/fonts/barlow-bold.ttf"
                        as="font"
                        crossOrigin
                    />
                    <link
                        rel="preload"
                        href="/fonts/barlow-semi-bold.woff"
                        as="font"
                        crossOrigin
                    />
                    <link
                        rel="preload"
                        href="/fonts/barlow-semi-bold.ttf"
                        as="font"
                        crossOrigin
                    />

                    <link
                        rel="preload"
                        href="https://d3tjqo2jh935k9.cloudfront.net/fonts/prompt.ttf"
                        as="font"
                        crossOrigin
                    />
                    <link
                        rel="preload"
                        href="https://d3tjqo2jh935k9.cloudfront.net/fonts/prompt-light.ttf"
                        as="font"
                        crossOrigin
                    />
                    <link
                        rel="preload"
                        href="https://d3tjqo2jh935k9.cloudfront.net/fonts/prompt-semi-bold.ttf"
                        as="font"
                        crossOrigin
                    />
                </Helmet>
                <BrandBasePage />
            </div>
        );
    }
}

export default BasePage;
