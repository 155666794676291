import React, { Component } from 'react';
import ButtonUI from '../../core/loginPopup/ButtonUI';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import {
    MEDIUM_SCREEN_MEDIA,
    SMALL_SCREEN_MEDIA,
} from '../../../services/constants';
import template from 'lodash/template';
import { cities, GetCity } from '../../../services/city';

const base = 'about__section-hero';

export default class SectionHero extends Component {
    render() {
        const smallAndMediumScreens =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches ||
            window.matchMedia(MEDIUM_SCREEN_MEDIA).matches;
        const city = GetCity() === cities.kl ? 'Malaysia' : 'Bangkok';

        return (
            <div className={base}>
                <div className={`${base}__bg-image`}>
                    {smallAndMediumScreens ? (
                        <div
                            className={`${base}__content-container hide--if-large`}
                        >
                            <div className={`${base}__content`}>
                                <h1 className={`${base}__header text--bolder`}>
                                    {intl.get('about.section-hero.headerLine1')}
                                    <br />
                                    {intl.get('about.section-hero.headerLine2')}
                                </h1>
                                <p className={`${base}__body`}>
                                    {GetCity()
                                        ? template(
                                              intl.get(
                                                  'about.section-hero.body'
                                              )
                                          )({ city })
                                        : 'the country'}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`${base}__content-container hide--if-small`}
                        >
                            <div className={`${base}__content`}>
                                <h1 className={`${base}__header text--bolder`}>
                                    {intl.get('about.section-hero.headerLine1')}
                                    <br />
                                    {intl.get('about.section-hero.headerLine2')}
                                </h1>
                                <p className={`${base}__body`}>
                                    {intl.get('about.section-hero.body')}
                                </p>
                                <Link className="link--no-underline" to="/menu">
                                    <ButtonUI
                                        customClasses={`${base}__cta button--success`}
                                        text={intl.get(
                                            'about.section-hero.cta'
                                        )}
                                    />
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
