import React from 'react';
import { getDateDisplay, parseDate } from '../../../../services/dateTime';
import { stripRepeatedCommas } from '../../../../services';

import intl from 'react-intl-universal';

const base = 'menu-cart-confirm';

export default ({ items, date, mealTime, address, timeslot }) => {
    return (
        <section className={`${base}__order mtm mbm lh--150`}>
            <div className="fs--small-medium">
                <span className="text--bolder text--uppercase mrxs">
                    {getDateDisplay(parseDate(date))}
                </span>
                <span
                    className={`${base}__timeslot text--primary text--lowercase mlxl`}
                >
                    {timeslot}
                </span>
            </div>
            <div className="mtm">
                <p className="text--bolder">
                    {intl.get('shoppingCart.confirmPayment.addressHeader')}
                </p>
                <p className="fs--small-medium ellipsis">
                    {stripRepeatedCommas(address.display_address)}
                </p>
                <p className="fs--small-medium">{address.city}</p>
            </div>
            <div className="mtm">
                <p className="text--bolder">
                    {intl.get('shoppingCart.confirmPayment.orderHeader')}
                </p>
                {items}
            </div>
        </section>
    );
};
