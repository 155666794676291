import React from 'react';

const noop = () => {};

export default ({ checked, readOnly, onChange }) => {
    return (
        <svg
            className="iconmoon-icon cursor-pointer"
            onClick={readOnly ? noop : onChange.bind(null, !checked)}
        >
            <use
                xlinkHref={
                    checked
                        ? '#icon-checkmark-checked'
                        : '#icon-checkmark-unchecked'
                }
            />
        </svg>
    );
};
