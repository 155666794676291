import React from 'react';
import { inject } from 'mobx-react';

import intl from 'react-intl-universal';

const base = 'login-popup';

@inject('loginPopupStore')
export default class extends React.Component {
    render() {
        return (
            <div className={base}>
                <h3 className={`${base}__title`}>
                    {this.props.loginPopupStore.message}
                </h3>
                <hr className={`${base}__title-separator`} />
                <br />
                <button className="button button--bigger-on-mobile button--busy button--disabled width--100">
                    {intl.get('loginPopup.completed')}
                </button>
                <div className="hide--if-large show--if-small mbl" />
            </div>
        );
    }
}
