import React from 'react';
import RideNavbar from './rideNavbar';
import intl from 'react-intl-universal';

const base = 'ride';

export default class extends React.Component {
    sendGTM = (param) => (e) => {
        window.dataLayer.push({ event: 'rider application click ' + param });
    };

    render() {
        return (
            <div className={base}>
                <RideNavbar />

                <div className={`${base}__content-ride container p0--if-small`}>
                    <div
                        className={`${base}__section display--flex m0--if-small border--rounded no-overflow`}
                    >
                        <div
                            className={`flex-1 width--40 ${base}__center-in-div no-overflow-y mantis-background pm`}
                        >
                            <div className="text--white">
                                <h1 className="mbm">
                                    {intl.get('ride.wellPaid')}
                                    <br />
                                    {intl.get('ride.flexibleHours')}
                                </h1>
                                <p className="mbm">{intl.get('ride.join')}</p>
                                <a
                                    onClick={this.sendGTM('google form')}
                                    href="http://bit.ly/dmWebCTA"
                                >
                                    <button
                                        className={`${base}__apply large button button-white`}
                                    >
                                        {intl.get('ride.applyNow')}
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div
                            className={`${base}__ride-hero flex-1 width--60 no-overflow header-image-container  hide--if-small`}
                        ></div>
                    </div>

                    <div
                        className={`${base}__section display--flex flex-wrap m0--if-small border--rounded no-overflow`}
                    >
                        <div
                            className={`${base}__center-horizontal flex-1 right-justified fleet-background hide--if-small prm`}
                        >
                            <img
                                className={`${base}__ride-fleet hide--if-small`}
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/ride-fleet.jpg"
                                alt="Rider Hero"
                            />
                        </div>
                        <div
                            className={`flex-1 ${base}__center-horizontal text-left left-justified plm`}
                        >
                            <div className={` ${base}__textbox`}>
                                <h1 className="mbm">
                                    {intl.get('ride.bePart')}
                                    <br />
                                    {intl.get('ride.deliveryHeroes')}
                                </h1>
                                <p className={`${base}__p `}>
                                    ✓ {intl.get('ride.reason1')}
                                </p>
                                <p className={`${base}__p `}>
                                    ✓ {intl.get('ride.reason2')}
                                </p>
                                <p className={`${base}__p `}>
                                    ✓ {intl.get('ride.reason3')}
                                </p>
                                <p className={`${base}__p `}>
                                    ✓ {intl.get('ride.reason4')}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`${base}__section whitesmoke m0--if-small display--flex flex-wrap border--rounded no-overflow`}
                    >
                        <div
                            className={`${base}__center-horizontal flex-1 right-justified prm ${base}__textbox-container max-width-100`}
                        >
                            <div className={` textbox max-width-100`}>
                                <h1 className="mbm mtm">
                                    {intl.get('ride.goodMoney')}
                                </h1>
                                <div
                                    className={`${base}__rectangle1 ${base}__rectangle mbs text--white border--rounded`}
                                >
                                    <img
                                        className={`${base}__rectangle-icon`}
                                        src="https://d3tjqo2jh935k9.cloudfront.net/images/static/ride-money-bag.png"
                                        alt=""
                                    />
                                    <div
                                        className={`display--inline-block pls ${base}__benefit-text `}
                                    >
                                        <strong>
                                            {intl.get('ride.deliveries')}
                                        </strong>
                                        <p>{intl.get('ride.salary')}</p>
                                    </div>
                                </div>
                                <div
                                    className={`${base}__rectangle2 ${base}__rectangle mbs text--white border--rounded`}
                                >
                                    <img
                                        className={`${base}__rectangle-icon`}
                                        src="https://d3tjqo2jh935k9.cloudfront.net/images/static/ride-podium.png"
                                        alt=""
                                    />
                                    <div
                                        className={`display--inline-block pls ${base}__benefit-text `}
                                    >
                                        <strong>
                                            {intl.get('ride.referrals')}
                                        </strong>
                                        <p>{intl.get('ride.refer')}</p>
                                    </div>
                                </div>
                                <div
                                    className={`${base}__rectangle3 ${base}__rectangle text--white border--rounded`}
                                >
                                    <img
                                        className={`${base}__rectangle-icon`}
                                        src="https://d3tjqo2jh935k9.cloudfront.net/images/static/ride-badge.png"
                                        alt=""
                                    />
                                    <div
                                        className={`display--inline-block pls ${base}__benefit-text `}
                                    >
                                        <strong>
                                            {intl.get('ride.rewards')}
                                        </strong>
                                        <p>{intl.get('ride.perform')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`flex-1 ${base}__center-horizontal text-left left-justified plm hide--if-small`}
                        >
                            <img
                                className={`${base}__ride-fleet2 mtm hide--if-small`}
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/ride-good-money.jpg"
                                alt="Rider Hero"
                            />
                        </div>
                    </div>

                    <div
                        className={`${base}__section text--center m0--if-small`}
                    >
                        <h1 className="mtxl mbl">
                            {intl.get('ride.allYouNeed')}
                        </h1>

                        <div
                            className={`${base}__you-need display--flex flex-wrap`}
                        >
                            <div
                                className={`${base}__you-need-container flex-none`}
                            >
                                <img
                                    className={`${base}__you-need-icons mbm`}
                                    src="https://d3tjqo2jh935k9.cloudfront.net/images/static/ride-motorbike.png"
                                    alt="Motorbike"
                                />
                                <p className={`text--primary mbm`}>
                                    {intl.get('ride.motorbike')}
                                </p>
                                <p>{intl.get('ride.walking')}</p>
                            </div>
                            <div
                                className={`${base}__you-need-container flex-none`}
                            >
                                <img
                                    className={`${base}__you-need-icons mbm`}
                                    src="https://d3tjqo2jh935k9.cloudfront.net/images/static/ride-license.png"
                                    alt="Motorbike"
                                />
                                <p className={`text--primary mbm`}>
                                    {intl.get('ride.license')}
                                </p>
                                <p>{intl.get('ride.customersHate')}</p>
                            </div>
                            <div
                                className={`${base}__you-need-container flex-none`}
                            >
                                <img
                                    className={`${base}__you-need-icons mbm`}
                                    src="https://d3tjqo2jh935k9.cloudfront.net/images/static/ride-phone.png"
                                    alt="Motorbike"
                                />
                                <p className={`text--primary mbm`}>
                                    {intl.get('ride.smartphone')}
                                </p>
                                <p>{intl.get('ride.waze')}</p>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`${base}__section display--flex  m0--if-small flex-wrap border--rounded-top no-overflow`}
                    >
                        <div
                            className={`${base}__ride-hero2 flex-1 width--60 no-overflow `}
                        ></div>
                        <div
                            className={`flex-1 width--40 ${base}__center-in-div mantis-background text--white pl`}
                        >
                            <div>
                                <p className="mbm">
                                    {intl.get('ride.hamizTalks')}
                                </p>

                                <p>- {intl.get('ride.hamiz')}</p>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`${base}__footer-section text--center m0--if-small whitesmoke pts pbs border--rounded-bottom no-overflow`}
                    >
                        <h1 className="mtxl mbl">{intl.get('ride.join')}</h1>
                        <p className="mbm">{intl.get('ride.5minutes')}</p>
                        <a
                            onClick={this.sendGTM('google form')}
                            href="http://bit.ly/dmWebCTA"
                        >
                            <button
                                className={`${base}__apply large cursor-pointer mbm button button--medium button--success`}
                            >
                                {intl.get('ride.applyNow')}
                            </button>
                        </a>
                        <br />
                        <a
                            onClick={this.sendGTM('whatsapp')}
                            className="link--no-underline"
                            href="https://chat.whatsapp.com/DkcEBrjHs1DFFLXutjg7KX"
                        >
                            <p className="display--inline-block mbxl dmk-link--primary-hover cursor-pointer">
                                {intl.get('ride.navbar.orWhatsapp')}
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
