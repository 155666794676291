import React, { Component } from 'react';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import AddonUpsellItem from './AddonUpsellItem';

const base = 'addon-upsell';

@inject('cartStore')
@observer
class AddonUpsellColumn extends Component {
    state = {
        expand: false,
    };

    toggleExpand = () => {
        this.setState((prevState) => ({
            expand: !prevState.expand,
        }));
    };

    handleAddToCart(item) {
        const { cartStore, date } = this.props;
        cartStore.addToCart(item, date);
    }

    render() {
        const { category, addOns, location } = this.props;
        const { expand } = this.state;
        const expandIconClasses = cx(
            `${base}__expand-icon iconmoon-icon float-right`,
            {
                [`${base}__expand-icon--flip`]: expand === true,
            }
        );
        const addOnsToDisplay = expand ? addOns : addOns.slice(0, 1);
        const additionalClass =
            location.pathname !== '/cart' ? 'on-dish-page' : '';

        return addOns.length > 0 ? (
            <div
                className={`${base}__column mbl ${base}__column-${additionalClass}`}
            >
                <p className="text--bold mbs">{`Add ${category}`}</p>
                <ul>
                    {addOnsToDisplay.map((item) => (
                        <AddonUpsellItem
                            key={item.id}
                            item={item}
                            onClick={::this.handleAddToCart}
                        />
                    ))}
                </ul>
                <hr />
                <div
                    className="fs--small-medium text--bold cursor-pointer clearfix"
                    onClick={() => this.toggleExpand(category)}
                >
                    <div className="float-left">
                        {expand
                            ? intl.get('addonUpsell.seeLess') + `${category}`
                            : intl.get('addonUpsell.seeMore') + `${category}`}
                    </div>
                    <svg className={expandIconClasses}>
                        <use xlinkHref="#icon-chevron-thin-down" />
                    </svg>
                </div>
            </div>
        ) : (
            <div />
        );
    }
}

export default withRouter(AddonUpsellColumn);
