import React from 'react';
import { when } from 'mobx';
import { Provider, inject, observer } from 'mobx-react';
import get from 'lodash/get';

import DelayedStatus from './DelayedStatus';
import ArrivedStatus from './ArrivedStatus';
import DeliveringStatus from './DeliveringStatus';
import PendingStatus from './PedingStatus';
import RatingPendingStatus from './RatingPendingStatus';
import FreeMeal from './FreeMeal';
import Modal from '../Modal';
import PopupDeliveryDetail from './PopupDeliveryDetail';
import OrderRatingStore from '../../../store/orderRatingStore';
import PopupRouter from '../ratings/PopupRouter';
import { DELIVERY_STATUS, MAYBE } from '../../../services/types';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

const isSmallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
const base = 'order-status-banner';

@inject('orderStatusStore')
@inject('orderRatingStore')
@observer
class OrderStatus extends React.Component {
    componentDidMount() {
        const { orderStatusStore, orderRatingStore } = this.props;

        when(() => {
            return (
                orderRatingStore.hasRated === true &&
                orderRatingStore.order === MAYBE.None
            );
        }, ::orderStatusStore.resetOrderStatusBanner);
        when(
            () =>
                !(
                    orderStatusStore.deliveryStatus instanceof
                    DELIVERY_STATUS.Delivered
                ),
            () => orderStatusStore.startPolling()
        );
    }

    renderStatus(clickEvent) {
        const { orderStatusStore } = this.props;

        if (DELIVERY_STATUS.Delivering.is(orderStatusStore.deliveryStatus)) {
            return <DeliveringStatus />;
        }

        if (DELIVERY_STATUS.Arrived.is(orderStatusStore.deliveryStatus)) {
            return <ArrivedStatus />;
        }

        if (DELIVERY_STATUS.Delayed.is(orderStatusStore.deliveryStatus)) {
            return <DelayedStatus />;
        }

        if (DELIVERY_STATUS.Pending.is(orderStatusStore.deliveryStatus)) {
            return <PendingStatus />;
        }

        if (DELIVERY_STATUS.Delivered.is(orderStatusStore.deliveryStatus)) {
            const hasRated = get(
                orderStatusStore,
                ['deliveryStatus', 'data', 'rated'],
                false
            );
            if (hasRated === false) {
                return <RatingPendingStatus onClick={clickEvent} />;
            }
        }

        return null;
    }

    componentWillUnmount() {
        const { orderStatusStore } = this.props;
        orderStatusStore.isShowingDetail = false;
    }

    render() {
        const { orderStatusStore } = this.props;

        document.addEventListener(
            'visibilitychange',
            orderStatusStore.startPolling,
            false
        );

        const clickEvent = orderStatusStore.deliveryId
            ? ::orderStatusStore.setShowingDetail(true)
            : null;
        const { orderRatingStore } = this.props;
        const banner = this.renderStatus();
        const isRatingPending = DELIVERY_STATUS.Delivered.is(
            orderStatusStore.deliveryStatus
        );
        return (
            <div
                className={`${base}__container position--relative p0--if-small ${
                    isRatingPending ? 'bottom-card' : ''
                }`}
            >
                {this.renderStatus() && (
                    <div
                        className={`${base} ${isRatingPending ? 'rating' : ''}`}
                    >
                        {banner !== null && !isRatingPending ? (
                            <div className={`${base}__left`} />
                        ) : null}
                        <center onClick={!isRatingPending ? clickEvent : null}>
                            {this.renderStatus(clickEvent)}
                        </center>
                        {banner !== null && !isRatingPending ? (
                            <div className={`${base}__right`} />
                        ) : null}
                    </div>
                )}
                <Modal
                    showModal={orderStatusStore.isShowingDetail}
                    showCloseIcon={true}
                    contentClasses="modal__content--full-mobile"
                    contentId="order-status-popup"
                    onBackdropClicked={::orderStatusStore.setShowingDetail(
                        false
                    )}
                    doNotHideBanner={true}
                >
                    <PopupDeliveryDetail showHistoryLink={true} />
                </Modal>
                <Modal
                    showModal={MAYBE.Some.is(orderRatingStore.order)}
                    showCloseIcon={true}
                    contentClasses="modal__content--full-mobile"
                    whiteIcons={!isSmallScreen}
                    closeButtonMargin="modal__margin"
                    onBackdropClicked={orderRatingStore.setOrder.bind(
                        orderRatingStore,
                        null
                    )}
                >
                    <PopupRouter />
                </Modal>
            </div>
        );
    }
}

const orderRatingStore = new OrderRatingStore();

export default () => (
    <Provider orderRatingStore={orderRatingStore}>
        <OrderStatus />
    </Provider>
);
