import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import MenuList from './MenuList';

import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import OrderStatus from '../../core/orderStatus/OrderStatus';
// import BoostBanner from "../../core/BoostBanner";
import PromotionBar from '../../core/PromotionBar';
import AddressBar from './AddressBar';
import SliderBanner from '../../core/SliderBanner';
import HalalPopup from '../../core/HalalPopup';
const { clevertap } = window;

@inject('loginPopupStore', 'menuHeaderStore', 'userStore', 'orderStatusStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;

        clevertap.notifications.push({
            titleText: 'Would you like to receive Push Notifications?',
            bodyText:
                'Turn on notifications to receive order updates and exclusive promotions',
            okButtonText: 'Yes, please!',
            rejectButtonText: 'Skip',
            okButtonColor: '#fec700',
            hidePoweredByCT: true,
            serviceWorkerPath: '/service-worker.js',
            // these callbacks are not documented by CleverTap, so they might change in the future
            okCallback: function () {
                clevertap.event.push('Clicked Accepted Web Notification');
            },
            rejectCallback: function () {
                clevertap.event.push('Clicked Skip Web Notification');
            },
            subscriptionCallback: function () {
                clevertap.event.push(
                    'Clicked Accepted Web Notification (Browser prompt)'
                );
            },
        });
    }

    render() {
        const { menuHeaderStore, userStore } = this.props;
        const { showHalalPopup, setHideHalalPopup } = userStore;
        const isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
        const isBusinessUser =
            userStore.user.data && userStore.user.data.company;
        const extraMargin = isSmallScreen && isBusinessUser ? 42 : 0;

        return (
            <div>
                {this.props.loginPopupStore.isShowing && this.isSmallScreen ? (
                    <div />
                ) : (
                    <Fragment>
                        <AddressBar />
                        <div
                            style={{
                                top:
                                    menuHeaderStore.headerHeight -
                                    1 +
                                    menuHeaderStore.walletBannerHeight +
                                    menuHeaderStore.addressBarHeight +
                                    'px',
                                position: 'fixed',
                                width: '100%',
                                zIndex: 90,
                                marginTop:
                                    menuHeaderStore.branchBannerHeight + 'px',
                            }}
                        >
                        </div>
                        <div
                            className="text--center"
                            style={{
                                marginTop:
                                    menuHeaderStore.headerHeight +
                                    menuHeaderStore.walletBannerHeight +
                                    menuHeaderStore.branchBannerHeight +
                                    'px',
                            }}
                        >
                            <div
                                className="menu-content"
                                style={{
                                    paddingTop:
                                        menuHeaderStore.calendarHeight +
                                        menuHeaderStore.promotionBarHeight +
                                        menuHeaderStore.addressBarHeight +
                                        extraMargin +
                                        (isSmallScreen ? 20 : 30) +
                                        'px',
                                }}
                            >
                                {/* <BoostBanner /> */}
                                {isSmallScreen && (
                                    <Fragment>
                                        <SliderBanner />
                                        <OrderStatus />
                                    </Fragment>
                                )}
                                <MenuList />
                            </div>
                        </div>
                        {showHalalPopup && isSmallScreen && (
                            <HalalPopup
                                showModal={showHalalPopup}
                                handleClose={setHideHalalPopup}
                            />
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}
