import React from 'react';
import QRCode from 'qrcode';

import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

const base = 'terminal-qrcode';
@inject('terminalCartStore')
@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    renderQRCodeTxt = (signInLink) => {
        var canvas = document.getElementById('qrcode-txt');
        if (canvas) {
            QRCode.toCanvas(canvas, signInLink, {
                errorCorrectionLevel: 'H',
                width: 161,
                height: 161,
                small: true,
                // margin: 10,
            });
        }
    };
    componentDidMount() {
        const { terminalMenuStore } = this.props;

        this.renderQRCodeTxt(terminalMenuStore.magicSignInLink);
    }
    render() {
        const { terminalMenuStore } = this.props;

        return (
            <div className={`${base}__rewardCode`}>
                <div className="display--flex">
                    <div style={{ width: '30%' }}>
                        <div className={`${base}__addon_container`}>
                            <div
                                style={{
                                    position: 'relative',
                                    height: '220px',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${terminalMenuStore.luckyWheelReward.digital_menu_image})`,
                                    }}
                                    className={`${base}__reward-img`}
                                ></div>
                            </div>

                            <div style={{ fontSize: '24px', fontWeight: 600 }}>
                                {terminalMenuStore.luckyWheelReward.sku}
                            </div>
                            <div
                                style={{
                                    position: 'relative',
                                    marginTop: '20px',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                }}
                                className="display--flex"
                            >
                                {terminalMenuStore.luckyWheelReward.price}
                                <div className={`${base}__red-cross-line`}></div>
                                <div
                                    style={{
                                        marginLeft: '10px',
                                        color: '#f4594f',
                                    }}
                                >
                                    FREE
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '70%', textAlign: 'center' }}>
                        <h1
                            style={{
                                fontSize: '48px',
                                fontWeight: 600,
                                textTransform: 'uppercase',
                            }}
                        >
                            {intl.get('terminal.popupWin.title')} {terminalMenuStore.customerName}!
                        </h1>
                        <h2
                            style={{
                                fontSize: '24px',
                                marginTop: '10px',
                                fontWeight: 600,
                            }}
                        >
                            {intl.get('terminal.popupWin.subTitle')}
                        </h2>
                        <canvas style={{ margin: '16px 0px' }} id="qrcode-txt"></canvas>{' '}
                        <div
                            onClick={(e) => {
                                terminalMenuStore.setShowPopup('');
                            }}
                            className="display--flex justify--center"
                        >
                            <div className={`${base}__next_btn`}>{intl.get('terminal.popupWin.actionButton')}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
