import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import LoginPopup from '../../core/LoginPopup';
import AccountDetailBox from './AccountDetailBox';
import PaymentMethodBox from './PaymentMethodBox';
import AddressBox from './AddressBox';
import PasswordBox from './PasswordBox';
import UserPermissions from './UserPermissions';
import HalalPopup from '../../core/HalalPopup';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

const base = 'profile';

@inject('profileAccountDetailStore')
@inject('loginPopupStore')
@inject('userStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.loginPopupStore.checkUser();
    }

    render() {
        const { profileAccountDetailStore } = this.props;
        const isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
        const { showHalalPopup, setHideHalalPopup } = this.props.userStore;
        return (
            <div className={base}>
                <LoginPopup />
                <div className={`${base}__header`}>
                    <center className={`${base}__name-greeting m0a`}>
                        <h1 className={`${base}__title ellipsis`}>
                            {intl.get('profile.hi')}
                            {this.props.profileAccountDetailStore.alias}
                        </h1>
                    </center>
                </div>
                <div className={`${base}__content`}>
                    <AccountDetailBox />
                    <PaymentMethodBox />
                    <AddressBox />
                    <PasswordBox />
                    {profileAccountDetailStore.email && <UserPermissions />}
                </div>
                {showHalalPopup && isSmallScreen && (
                    <HalalPopup
                        showModal={showHalalPopup}
                        handleClose={setHideHalalPopup}
                    />
                )}
            </div>
        );
    }
}
