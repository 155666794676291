import React from 'react';

import { inject, observer } from 'mobx-react';

const base = 'terminal-card-status';
@inject('terminalMenuStore')
@observer
export default class CardReaderStatus extends React.Component {
    render() {
        const { cardReaderStatus } = this.props.terminalMenuStore;
        const isEnabled = this.props.terminalMenuStore.getCardProvider() == 'stripe'
        const isShow = !cardReaderStatus && isEnabled;
        return ( !isShow ? null :
            <div className={base}>
                <div className={`${base}__container`}>
                    <div className={`${base}__text`}>
                        Card Terminal not connected. Retrying..
                    </div>
                    <div className={`${base}__loader`}>
                        <div className='loading--circle'></div>    
                    </div>
                </div>
            </div>
        );
    }
}
