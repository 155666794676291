import React from 'react';
import brandLogo from '../brandLogo/MasterBrandLogo';
import Embed from '../Embed';
import { GoogleTagManagerNoScript } from '../ScriptTag';
import SVGSymbols from '../SVGIcons';
import Banner from '../Banner';
import sprite from '../../../resources/images/sprite.svg';
import { services } from '../../../services/index';

export class Header extends React.Component {
    onNodeRendered(header) {
        if (header) {
            services.headerScrollEvents().onValue(function (status) {
                if (status === 'show') {
                    header.classList.remove('header--show');
                    header.classList.add('header--hide');
                } else if (status === 'hide') {
                    header.classList.add('header--show');
                    header.classList.remove('header--hide');
                }
            });
        }
    }

    render() {
        return (
            <header className="header header--hide" ref={this.onNodeRendered}>
                <div className="header__logo text--center">
                    <a href="/">
                        <brandLogo.BrandText className="header__dahmakan logo__text--default-black" />
                    </a>
                </div>
            </header>
        );
    }
}

export const Page = ({
    children,
    showHeader = true,
    HeaderComponent = Header,
}) => {
    return (
        <div>
            <SVGSymbols />
            <noscript className="noscript-overlay">
                Please enable Javascript to continue
            </noscript>
            {GoogleTagManagerNoScript}
            {/* { AdditionalEmbeddedBrandScripts } */}
            <Banner />
            {showHeader ? <HeaderComponent /> : null}
            {children}
            <Embed resource={sprite} />
        </div>
    );
};
