import React, { Component } from 'react';
import CarouselUI from './CarouselUI';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import config from '../../../configs/parameters';
import intl from 'react-intl-universal';
import { template } from 'lodash';

export default class AboutCompanyCarousel extends Component {
    render() {
        const brandName = config.brandName;
        const content = [
            {
                icon: 'icon-rocket',
                header: intl.get('careers.about.item1.header'),
                content: intl.get('careers.about.item1.content'),
            },
            {
                icon: 'icon-y-combinator',
                header: intl.get('careers.about.item2.header'),
                content: intl.get('careers.about.item2.content'),
            },
            {
                icon: 'icon-light-bulb',
                header: intl.get('careers.about.item3.header'),
                content: intl.get('careers.about.item3.content'),
            },
            {
                icon: 'icon-rocket',
                header: intl.get('careers.about.item4.header'),
                content: intl.get('careers.about.item4.content'),
            },
            {
                icon: 'icon-suitcase',
                header: intl.get('careers.about.item5.header'),
                content: template(intl.get('careers.about.item5.content'))({
                    brandName,
                }),
            },
            {
                icon: 'icon-rocket',
                header: intl.get('careers.about.item6.header'),
                content: intl.get('careers.about.item6.content'),
            },
        ];

        return (
            <CarouselUI
                content={content}
                width={
                    window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 319 : 540
                }
                height={
                    window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 340 : 280
                }
                name="about-carousel"
                contentWidth={420}
                marginBottom={
                    window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 65 : 90
                }
            />
        );
    }
}
