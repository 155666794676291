export const SetCity = (city) => {
    city = city.toLowerCase();
    localStorage.setItem('city', city);
};

export const GetCity = () => {
    if (
        window.location.hostname === 'localhost' ||
        window.location.hostname.includes('.com.my')
    ) {
        SetCity(cities.kl);
        return cities.kl;
    } else if (
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname.includes('.co.th')
    ) {
        SetCity(cities.bangkok);
        return cities.bangkok;
    } else if (localStorage.getItem('city')) {
        return localStorage.getItem('city').toLowerCase();
    } else {
        return cities.kl;
    }
};

export const cities = {
    kl: 'kuala-lumpur',
    bangkok: 'bangkok',
};
