import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import AddPaymentMethod from './AddPaymentMethod';
import PaymentMethod from './PaymentMethod';
import AddCreditCard from '../walletCheckoutPage/AddCreditCard';
import { CircleLoader } from '../../core/Loading';
import { RESOURCE, MAYBE } from '../../../services/types';
import BusyBox from './BusyBox';

const base = 'profile';

@inject('profilePaymentMethodStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.profilePaymentMethodStore.fetchPaymentMethods();
    }

    handleDeleteClicked(paymentMethod) {
        const result = confirm(intl.get('profile.payment.confirmDelete')); //eslint-disable-line

        if (result) {
            this.props.profilePaymentMethodStore.deletePaymentMethod(
                paymentMethod
            );
        }
    }

    renderBody() {
        const store = this.props.profilePaymentMethodStore;
        const { pmFetcher, pmBeingDeleted } = store;
        const paymentMethods = pmFetcher.data;

        if (store.view === store.SHOW_VIEW) {
            return paymentMethods.map((paymentMethod) => {
                if (
                    MAYBE.Some.is(pmBeingDeleted) &&
                    pmBeingDeleted.data.id === paymentMethod.id
                ) {
                    return (
                        <div
                            key={paymentMethod.id}
                            className={`${base}__box-body p0 display--none-if-empty`}
                        >
                            <center className="display--flex flex-align-items-center pm">
                                <CircleLoader />
                                <div className="mls">
                                    {intl.get('profile.payment.deleting')}
                                </div>
                            </center>
                        </div>
                    );
                }

                return (
                    <div
                        key={paymentMethod.id}
                        className={`${base}__box-body p0 display--none-if-empty`}
                    >
                        <PaymentMethod
                            paymentMethod={paymentMethod}
                            onDeleteClicked={::this.handleDeleteClicked}
                        />
                    </div>
                );
            });
        }

        if (store.view === store.ADD_VIEW) {
            return (
                <div className={`${base}__box-body`}>
                    <AddPaymentMethod />
                </div>
            );
        }

        if (store.view === store.CREDIT_CARD_VIEW) {
            return (
                <div className={`${base}__box-body`}>
                    <AddCreditCard onCancel={::store.setView(store.ADD_VIEW)} />
                </div>
            );
        }

        return null;
    }

    renderFooter() {
        const store = this.props.profilePaymentMethodStore;

        if (store.view === store.SHOW_VIEW) {
            return (
                <div className={`${base}__box-footer`}>
                    <center>
                        <button
                            className="button button--success profile__button"
                            onClick={::store.setView(store.ADD_VIEW)}
                        >
                            {intl.get('profile.payment.cta')}
                        </button>
                    </center>
                </div>
            );
        }

        return null;
    }

    render() {
        const store = this.props.profilePaymentMethodStore;
        const { pmFetcher } = store;

        if (RESOURCE.Success.is(pmFetcher) === false) {
            return <BusyBox headerText="Payment Methods" />;
        }

        return (
            <div className={`${base}__box`}>
                <div className={`${base}__box-header`}>
                    {intl.get('profile.payment.header')}
                </div>
                {this.renderBody()}
                {this.renderFooter()}
            </div>
        );
    }
}
