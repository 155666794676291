import React from 'react';
import { inject, observer } from 'mobx-react';

import intl from 'react-intl-universal';

import { MAYBE } from '../../../services/types';

const base = 'rating-popup';

@inject('orderRatingStore')
@observer
export default class extends React.Component {
    handleSubmitComment(e) {
        const { orderRatingStore } = this.props;
        e.preventDefault();

        orderRatingStore.submitFeedbackComment();
    }

    handleUpdateComment(e) {
        this.props.orderRatingStore.feedbackComment = e.currentTarget.value;
    }

    render() {
        const { orderRatingStore } = this.props;

        if (orderRatingStore.order === MAYBE.None) {
            return <div />;
        }

        return (
            <div className={base}>
                <div
                    className={`${base}__header text--bolder text--white text--center`}
                >
                    {`${intl.get('rating.order')} ${
                        orderRatingStore.order.data.order_id
                    }`}
                </div>
                <form
                    className={`${base}__body`}
                    onSubmit={::this.handleSubmitComment}
                >
                    <p className={`${base}__title text--bolder mtl mbm`}>
                        {intl.get('rating.npsRating.title')}
                    </p>
                    <div className="separator separator--green" />
                    <p className="mtm mbm">
                        {intl.get('rating.npsRating.askReason')}
                    </p>
                    <textarea
                        className={`${base}__feedback-comment border--rounded`}
                        placeholder={intl.get('rating.npsRating.suggestions')}
                        name="feedbackComment"
                        value={orderRatingStore.feedbackComment}
                        onChange={::this.handleUpdateComment}
                    ></textarea>
                    <button
                        className={`${base}__cta button button--bigger-on-mobile button--success mts`}
                    >
                        {intl.get('rating.starRating.cta')}
                    </button>
                </form>
            </div>
        );
    }
}
