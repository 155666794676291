import React from 'react';
import { currency } from '../../../services';

const base = 'wallet-progress';

export default ({ balance }) => {
    const labelClasses =
        balance.toString().length > 2
            ? `${base}__label ${base}__label--too-big`
            : `${base}__label`;

    return (
        <div className={`${base} mtxs`}>
            <p className={labelClasses}>{currency.localCurrency(balance)}</p>
        </div>
    );
};
