import React from 'react';
import BasePage from './basePage';
import OfflineSignupRoot from '../components/OfflineSignupRoot';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('offlineSignup');

export default class extends React.Component {
    handleRoute(next) {
        if (qs.parse(this.props.location.search)._branch_match_id) {
            this.props.history.push('/menu');
        }

        return next();
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta name="nosnippet" />
                    <title>{pageTitle}</title>
                    {localizationTags(`offline-signup`).map((tag) => tag)}
                    {getCanonicalTag(`offline-signup`)}
                </Helmet>
                <BasePage />
                <OfflineSignupRoot />
            </React.Fragment>
        );
    }
}
