import React from 'react';
import intl from 'react-intl-universal';

import { CircleLoader } from '../../core/Loading';

const base = 'terminal-phone-popup';

export default (props) => {
    return (
        <div className={`${base}__ordering`}>
            <center>
                <h3 className={`${base}__title ordering text--sofiapro`}>
                    {props.checkIn
                        ? 'Checking in'
                        : intl.get('terminal.popupOrdering.title')}
                </h3>
                <center>
                    <div className="separator separator--red mtm mbm" />
                </center>
                <p>{intl.get('terminal.popupOrdering.loading')}</p>
                <center className="mtm">
                    <CircleLoader />
                </center>
            </center>
        </div>
    );
};
