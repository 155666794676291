import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Price from '../../core/Price';
import { GetDishURL } from '../../../services/url';

const base = 'shopping-cart-item';

@inject('cartStore', 'menuHeaderStore')
@observer
export default class extends React.Component {
    handleIncreaseQuantity = () => {
        const { cartStore, item } = this.props;
        cartStore.addToCart(item);
    };

    handleDecreaseQuantity = () => {
        const { cartStore, date, item } = this.props;
        cartStore.removeItemFromCart(item, date);
    };

    renderImage() {
        const { item } = this.props;
        const url = GetDishURL(item);

        if (item.addon_category) {
            return (
                <div
                    className={`${base}__image mrm flex-none`}
                    style={{ backgroundImage: `url(${item.image})` }}
                />
            );
        }

        return (
            <Link to={url}>
                <div
                    className={`${base}__image mrm flex-none`}
                    style={{ backgroundImage: `url(${item.image})` }}
                />
            </Link>
        );
    }

    render() {
        const { item, key } = this.props;
        const price = item.special_offer_price
            ? item.special_offer_price * item.quantity
            : item.price * item.quantity;

        return (
            <div
                className={`${base} display--flex flex-align-items-center fs--small-medium`}
                key={key}
            >
                {this.renderImage()}
                <div className={`${base}__name flex-1-only prm`}>
                    <span>{item.title_bold || item.name}</span>
                    <div className="mts">
                        <button
                            className={`${base}__up-down-btn mrs`}
                            onClick={this.handleDecreaseQuantity}
                        >
                            <svg className="iconmoon-icon">
                                <use xlinkHref="#icon-minus" />
                            </svg>
                        </button>
                        <span className={`${base}__quantity`}>
                            {item.quantity}
                        </span>
                        <button
                            className={`${base}__up-down-btn mls`}
                            onClick={this.handleIncreaseQuantity}
                        >
                            <svg className="iconmoon-icon">
                                <use xlinkHref="#icon-plus" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="flex-none text--right">
                    <Price price={price} isAddon={item.addon_category} />
                </div>
            </div>
        );
    }
}
