import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { template } from 'lodash';
import config from '../../../configs/parameters';

const base = 'about__section-details';

export default class SectionDetails extends Component {
    render() {
        const brandName = config.brandName;
        const content = [
            {
                icon: 'icon-top-ten',
                line1: intl.get('about.section-details.content.one.line1'),
                line2: intl.get('about.section-details.content.one.line2'),
            },
            {
                icon: 'icon-price-tag',
                line1: intl.get('about.section-details.content.two.line1'),
                line2: intl.get('about.section-details.content.two.line2'),
            },
            {
                icon: 'icon-scooter',
                line1: intl.get('about.section-details.content.three.line1'),
                line2: intl.get('about.section-details.content.three.line2'),
            },
        ];

        return (
            <div className={`${base} container`}>
                <div className={`${base}__header-container hide--if-small`}>
                    <h3 className={`${base}__header text--bolder`}>
                        {intl.get('about.section-details.header')}
                    </h3>
                    <p className={`${base}__sub-header`}>
                        {template(
                            intl.get('about.section-details.sub-header-line1')
                        )({ brandName })}
                        <br />
                        {intl.get('about.section-details.sub-header-line2')}
                    </p>
                </div>
                <div className={`${base}__content-container`}>
                    {content.map((c, i) => (
                        <div className={`${base}__content`} key={i}>
                            <div className={`${base}__content-box`}>
                                <svg
                                    className={`${base}__icon iconmoon-icon ${c.icon}`}
                                >
                                    <use xlinkHref={`#${c.icon}`} />
                                </svg>
                                <p
                                    className={`${base}__content-text text--bolder`}
                                >
                                    {c.line1} <br />
                                    {c.line2}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
