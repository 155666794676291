export const parseLocation = (place) => {
    return {
        name: place.display_name,
        displayName: place.display_name,
        buildingName: place.building_name,
        postalCode: place.postal_code,
        street: place.street,
        district: place.district,
        homeNumber: place.street_no,
        city: place.city,
    };
};

export const parseEditLocation = (address) => {
    return {
        buildingName: address.building_name || '',
        postalCode: address.postal_code || '',
        street: address.street || '',
        district: address.district || '',
        homeNumber: address.street_no || '',
        state: address.state || '',
        city: address.city || '',
    };
};
