import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import intl from 'react-intl-universal';

import { RESOURCE } from '../../services/types';
import { getParam } from '../../services';
import WalletStatusInDropdown from './WalletStatusInDropdown';

import WalletDropdown from './WalletDropdown';
import { GetCity } from '../../services/city';
import config from '../../configs/parameters';
import { template } from 'lodash';
const { clevertap } = window;

const base = 'menu-header';

@inject('dropdownStore', 'walletStatusStore', 'userStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const { walletStatusStore } = this.props;
        walletStatusStore.getWalletStatus();
    }

    sendTrackingTryWallet() {
        clevertap.event.push('Clicked Try Wallet in Dropdown');
    }

    renderWalletUpsell() {
        const { dropdownStore } = this.props;
        const path = window.location.pathname.replace('/' + GetCity(), '');
        const baseDropdown = 'wallet-dropdown';
        const brandNameShort = config.brandNameShort;

        return (
            <span
                className="position--relative mrl z-index-higher-than-backdrop"
                onMouseEnter={() =>
                    dropdownStore.handleShowDropdown(
                        dropdownStore.DROPDOWN_WALLET
                    )
                }
            >
                <Link
                    className={`${base}__desktop-link dmk-link--primary-hover link--no-underline`}
                    to={{ pathname: '/wallet', state: { from: path } }}
                    id="select-wallet"
                >
                    Wallet
                </Link>
                <WalletDropdown
                    isShowing={
                        dropdownStore.showingDropdown ===
                        dropdownStore.DROPDOWN_WALLET
                    }
                    onClickOutside={() =>
                        dropdownStore.handleHideDropdownImmediately()
                    }
                >
                    <div
                        className={`${baseDropdown}__content lh--150`}
                        onMouseLeave={() => dropdownStore.handleHideDropdown()}
                    >
                        <div className="logos mbm">
                            <p className={`text--bold fs--large lh--125`}>
                                {getParam('brandNameShort')}
                                <br></br>
                                <span
                                    className={`${baseDropdown}__header-alt text--bolder`}
                                >
                                    Wallet
                                </span>
                            </p>
                        </div>
                        <p>
                            {template(
                                intl.get(`${GetCity()}.menu.walletMission`)
                            )({ brandName: brandNameShort })}
                        </p>
                        <Link
                            to={{ pathname: '/wallet', state: { from: path } }}
                            className="link--no-underline"
                            onClick={() => {
                                this.sendTrackingTryWallet();
                            }}
                        >
                            <button
                                className={`${baseDropdown}__cta button button--success button--larger text--center text--bolder`}
                            >
                                {intl.get('menu.header.tryWallet')}
                            </button>
                        </Link>
                    </div>
                </WalletDropdown>
            </span>
        );
    }

    render() {
        const { walletStatusStore, dropdownStore } = this.props;
        const path = window.location.pathname.replace('/' + GetCity(), '');

        if (RESOURCE.Success.is(walletStatusStore.walletStatus)) {
            if (walletStatusStore.walletStatus.data.has_wallet) {
                return (
                    <span
                        className="position--relative mrl z-index-higher-than-backdrop"
                        onMouseEnter={() =>
                            dropdownStore.handleShowDropdown(
                                dropdownStore.DROPDOWN_WALLET
                            )
                        }
                    >
                        <Link
                            className={`${base}__desktop-link button button--success button--navbar link--no-underline`}
                            to={{ pathname: '/wallet', state: { from: path } }}
                        >
                            Wallet
                        </Link>

                        <WalletDropdown
                            isShowing={
                                dropdownStore.showingDropdown ===
                                dropdownStore.DROPDOWN_WALLET
                            }
                            onClickOutside={() =>
                                dropdownStore.handleHideDropdownImmediately()
                            }
                        >
                            <div
                                onMouseLeave={() =>
                                    dropdownStore.handleHideDropdown()
                                }
                                className="width--100 height--100"
                            >
                                <WalletStatusInDropdown />
                            </div>
                        </WalletDropdown>
                    </span>
                );
            } else {
                return this.renderWalletUpsell();
            }
        } else {
            return this.renderWalletUpsell();
        }
    }
}
