import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import BasePage from './basePage';
import { getGTMScript } from '../components/core/ScriptTag';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';
import ChefRoot from '../components/ChefRoot';

const pageTitle = services.getTitle('chef');

export default class extends Component {
    componentDidMount() {
        document.body.classList.add('chef');
        window.scrollTo(0, 0);
    }
    render() {
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: '',
            lpType: '',
            lpVersion: '',
        });

        return (
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                    {localizationTags(`chef`).map((tag) => tag)}
                    {getCanonicalTag(`chef`)}
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <ChefRoot />
            </Fragment>
        );
    }
}
