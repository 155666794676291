import React from 'react';
import intl from 'react-intl-universal';
import { services } from '../../../../services/index';
import SummaryItem from './SummaryItem';
import template from 'lodash/template';
import { inject, observer } from 'mobx-react';
import ShareBox from '../../rewardsPage/ShareBox';

const base = 'menu-cart-confirm';

@inject('userStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const { userStore } = this.props;

        userStore.getUserData();
        window.dataLayer.push({
            PageType: 'TransactionPage',
            TransactionID: this.props.finalOrderSummary.group_id,
        });
    }

    render() {
        const { user, orderSummary, finalOrderSummary, orders } = this.props;
        return (
            <div className={`${base} `}>
                <div className={`${base}__header border--rounded-top`}>
                    <section>
                        <span className={`${base}__thank-you`}>
                            {intl.get('shoppingCart.confirmPayment.title')}
                        </span>
                    </section>
                    <section className="lh--125 mtm">
                        <p>
                            {template(
                                intl.get('shoppingCart.confirmPayment.subtitle')
                            )({ firstName: user.firstname })}
                        </p>
                        <p>
                            {intl.get('shoppingCart.confirmPayment.subtitle2')}
                        </p>
                    </section>
                </div>
                <ShareBox customClasses="border--rounded-bottom" />
                <div className={`${base}__body bg--white border--rounded-top`}>
                    <section className="row pm pt0 pb0 m0--if-small">
                        <div>
                            <p className={`${base}__heading`}>
                                {intl.get(
                                    'shoppingCart.confirmPayment.details'
                                )}
                            </p>
                            <p className="fs--small-medium">{user.alias}</p>
                            <p className="fs--small-medium">{user.username}</p>
                        </div>
                        <div>
                            <p className={`${base}__heading`}>
                                {intl.get(
                                    'shoppingCart.confirmPayment.orderNumber'
                                )}
                            </p>
                            <p className="fs--small-medium">
                                #{finalOrderSummary.group_id}
                            </p>
                        </div>
                    </section>
                    {orders}
                    <hr className="show--if-small hide--if-large" />
                    <section
                        className={`${base}__invoices fs--small-medium prm lh--150`}
                    >
                        {orderSummary.price_summary.rows.map((item, i) => (
                            <SummaryItem
                                key={i}
                                title={item.name}
                                value={item.value}
                                bold={item.bold}
                                color={item.color}
                                base={base}
                            />
                        ))}
                    </section>
                    <section
                        className={`${base}__payment-method fs--small-medium`}
                    >
                        <span>
                            {intl.get(
                                'shoppingCart.confirmPayment.paymentMethod'
                            )}
                        </span>
                        <span className={`${base}__invoices-col`}>
                            {orderSummary.payment_method}
                        </span>
                    </section>
                </div>
                <div className={`${base}__apps pm`}>
                    <a
                        href={services.getParam('appStorePath')}
                        className="display--inline-block mrl"
                    >
                        <img
                            className={`${base}__app-button`}
                            src="https://d3tjqo2jh935k9.cloudfront.net/images/static/app-store-black.png"
                            alt={`Download ${services.getParam(
                                'brandName'
                            )} iOS app`}
                        />
                    </a>
                    <a
                        href={services.getParam('googlePlayPath')}
                        className="display--inline-block"
                    >
                        <img
                            className={`${base}__app-button`}
                            src="https://d3tjqo2jh935k9.cloudfront.net/images/static/google-play-black.png"
                            alt={`Download ${services.getParam(
                                'brandName'
                            )} Android app`}
                        />
                    </a>
                </div>
            </div>
        );
    }
}
