export const UserCart = {
    saveCart(cart) {
        try {
            localStorage.setItem('__CART__', JSON.stringify(cart));
        } catch (ex) {
            throw new Error(
                'Please deactivate the Private Browsing mode to continue.'
            );
        }
    },

    getCart() {
        try {
            return JSON.parse(localStorage.getItem('__CART__'));
        } catch (ex) {
            return null;
        }
    },

    clear() {
        localStorage.removeItem('__CART__');
    },
};
