import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { inject, observer } from 'mobx-react';

@inject('userStore')
@observer
export default class ChangeButton extends Component {
    render() {
        const { onClick, userStore } = this.props;

        return (
            <button
                className={`shopping-cart-address__change-btn button`}
                onClick={onClick}
            >
                {intl.get('shoppingCart.deliveryAddress.change')}
            </button>
        );
    }
}
