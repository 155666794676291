import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import MainMenuItem from './MainMenuItem';
import AddonItemsHomeScreenList from './AddonItemsHomeScreenList';
import Modal from '../../../core/Modal';
import PopupAddonItems from '../PopupAddonItems';

const base = 'terminal-mobile-items-menu-screen';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const {
            terminalMenuStore: { yPositionToScroll },
        } = this.props;
        console.log({ yPositionToScroll });
        if (yPositionToScroll) {
            window.scrollTo(0, yPositionToScroll);
        }
    }
    handleCartClick = () => {
        const {
            terminalMenuStore: { mobileScreens, setShowMobileScreen },
            terminalCartStore,
        } = this.props;
        const number_of_items = terminalCartStore.numOfItems;
        if (parseInt(number_of_items) > 0) {
            setShowMobileScreen(mobileScreens.CART);
        }
    };

    handleClosePopup = () => {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.setShowPopup('');
    };

    render() {
        const {
            terminalMenuStore: store,
            terminalCartStore,
            items,
        } = this.props;
        const mainItems = items.filter((item) => item.item_type === 'Item');
        const number_of_items = terminalCartStore.numOfItems;
        const brandColorsMapping = {
            '#FDC601': '#131415',
            '#7056B5': 'white',
            '#00C6BF': 'white',
            '#f2594e': 'white',
        }; //key of object: background color of meal, value of object: text color to be used for meal card.
        const brandColors = Object.keys(brandColorsMapping);

        return (
            <Fragment>
                <div className={`${base}`}>
                    <div className={`${base}__top_20_halal`}>
                        <h3 className="top20">
                            Top {mainItems && mainItems.length} Pop Meals
                        </h3>
                        <img
                            className="halal_logo"
                            src="https://image.popcontent.link/halal_logo.svg"
                            alt="halal logo"
                        />
                    </div>
                    <div className={`${base}__menu_items`}>
                        {mainItems &&
                            mainItems.slice(0, 10).map((item, i) => {
                                const bgColor = brandColors[i % 4];
                                const contentColor =
                                    brandColorsMapping[bgColor];
                                return (
                                    <MainMenuItem
                                        item={item}
                                        bgColor={bgColor}
                                        contentColor={contentColor}
                                    />
                                );
                            })}
                    </div>
                    <div>
                        <AddonItemsHomeScreenList
                            items={items}
                            listName={'Snacks'}
                        />
                    </div>
                    <div className={`${base}__menu_items`}>
                        {mainItems &&
                            mainItems.slice(10, 20).map((item, i) => {
                                const bgColor = brandColors[i % 4];
                                const contentColor =
                                    brandColorsMapping[bgColor];
                                return (
                                    <MainMenuItem
                                        item={item}
                                        bgColor={bgColor}
                                        contentColor={contentColor}
                                    />
                                );
                            })}
                    </div>
                    <div>
                        <AddonItemsHomeScreenList
                            items={items}
                            listName={'Drinks'}
                        />
                    </div>
                    <div className={`${base}__menu_items`}>
                        {mainItems &&
                            mainItems.slice(20, 30).map((item, i) => {
                                const bgColor = brandColors[i % 4];
                                const contentColor =
                                    brandColorsMapping[bgColor];
                                return (
                                    <MainMenuItem
                                        item={item}
                                        bgColor={bgColor}
                                        contentColor={contentColor}
                                    />
                                );
                            })}
                    </div>
                    <img
                        className={`${base}__halal_pop_logo`}
                        src={
                            'https://image.popcontent.link/halal_pop.svg'
                        }
                        alt="dijamin halal and popmeals logo"
                    />

                    <button
                        onClick={this.handleCartClick}
                        className={`${base}__cart_btn ${
                            parseInt(number_of_items) === 0
                                ? 'no-item'
                                : 'n-items'
                        }`}
                    >
                        <img
                            className="cart_icon_img"
                            src={
                                parseInt(number_of_items) === 0
                                    ? 'https://image.popcontent.link/cart_white_bg_icon.svg'
                                    : 'https://image.popcontent.link/cart_icon_yellow_bg.svg'
                            }
                            alt="cart icon"
                        />
                        <p className={`number_of_items`}>{number_of_items}</p>
                    </button>
                </div>

                {Boolean(store.showingPopup) && (
                    <Modal
                        showModal={true}
                        showCloseIcon={false}
                        onBackdropClicked={this.handleClosePopup}
                        disableScrollingOnMobile={false}
                        showBackdropOnMobile={true}
                        contentClasses={'rounded'}
                        manualStyles={{ width: '332px' }}
                    >
                        {store.showingPopup === store.POPUP_ADDON_ITEMS ? (
                            <PopupAddonItems
                                items={store.drinkComboItems}
                                onClosePopup={this.handleClosePopup}
                            />
                        ) : null}
                    </Modal>
                )}
            </Fragment>
        );
    }
}
