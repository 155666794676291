import React, { Component, Fragment } from 'react';
import { when } from 'mobx';
import intl from 'react-intl-universal';
import PromotionTiles from '../../core/PromotionTiles';
import { Link as URL, RESOURCE } from '../../../services/types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ButtonUI from '../../core/loginPopup/ButtonUI';
import LoginPopup from '../../core/LoginPopup';
import { CircleLoader } from '../../core/Loading';
import { cities, GetCity } from '../../../services/city';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import config from '../../../configs/parameters';
import SelectPopup from '../../core/selectPopup/SelectPopup';
import qs from 'query-string';
import { template } from 'lodash';
import HalalPopup from '../../core/HalalPopup';

const { clevertap } = window;

const isKL = GetCity() === cities.kl;
const base = 'promotion';

@inject('loginPopupStore', 'userStore', 'selectPopupStore')
@observer
class Entry extends Component {
    componentDidMount() {
        if (qs.parse(window.location.search).selectYourPlan) {
            const { userStore, loginPopupStore } = this.props;
            when(
                () =>
                    RESOURCE.Success.is(userStore.user) ||
                    RESOURCE.Failure.is(userStore.user),
                () => {
                    if (RESOURCE.Success.is(userStore.user)) {
                        this.props.selectPopupStore.show = true;
                    } else {
                        loginPopupStore.isShowing = true;
                        loginPopupStore.redirectToSelectPage = true;
                    }
                }
            );
        }
    }
    showSelect = () => {
        if (RESOURCE.Success.is(this.props.userStore.user)) {
            this.props.selectPopupStore.setShow();
        } else {
            this.props.loginPopupStore.isShowing = true;
            this.props.loginPopupStore.redirectToSelectPage = true;
        }
    };
    handleClickLink = (url, method, promotionType) => {
        clevertap.event.push('Clicked on Promotion Card', {
            'Promotion Type': promotionType,
        });
        this.props.loginPopupStore.setBackLink(
            new URL(window.location.pathname, 'Back')
        );
        if (method) {
            method();
        } else if (url.includes('https')) {
            window.open(url, '_blank');
        } else {
            this.props.history.push(url);
        }
    };

    onClick = () => {
        window.location.href = '/menu';
    };

    renderLoader = () => {
        return (
            <div className="menu__list--loading text--center">
                <div className="display--inline-block text--center">
                    <CircleLoader className="m0a" />
                    <p className="mtm">{intl.get('promotions.loading')}</p>
                </div>
            </div>
        );
    };

    render() {
        const { userStore } = this.props;
        const loading = userStore.user === RESOURCE.Loading;
        const brandName = config.brandName;
        const brandNameShort = config.brandNameShort;
        const { showHalalPopup, setHideHalalPopup } = userStore;
        const isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
        const content = [
            {
                icon: 'icon-heart',
                promotionType: 'Referral',
                header: intl.get('about.section-save.content.one.header'),
                content: intl.get('about.section-save.content.one.content'),
                linkText: intl.get('about.section-save.content.one.cta'),
                url: '/rewards',
            },            
            {
                icon: 'icon-green-leaf',
                promotionType: 'Wallet',
                header: template(
                    intl.get('about.section-save.content.two.header')
                )({ brandName: brandNameShort }),
                content: template(
                    intl.get('about.section-save.content.two.content')
                )({ brandName: brandNameShort }),
                linkText: intl.get('about.section-save.content.two.cta'),
                url: '/wallet',
            }
        ];

        const contentToRender = isKL
            ? content
            : content.filter((c) => c.promotionType !== 'SELECT');

        return (
            <div className={base}>
                <LoginPopup dismissable={true} showCloseIcon={true} />
                {this.props.selectPopupStore.show && <SelectPopup />}
                {loading ? (
                    this.renderLoader()
                ) : (
                    <Fragment>
                        <div className={`${base}__container`}>
                            {contentToRender.map(
                                (c, i) =>
                                    !(
                                        userStore.user.data &&
                                        userStore.user.data
                                            .has_ordered_before &&
                                        c.icon === 'icon-cash-envelope'
                                    ) && (
                                        <PromotionTiles
                                            customClass={`${base}__promotion-tiles`}
                                            header={c.header}
                                            body={c.content}
                                            ctaText={c.linkText}
                                            onClick={() =>
                                                this.handleClickLink(
                                                    c.url,
                                                    c.method,
                                                    c.promotionType
                                                )
                                            }
                                            icon={c.icon}
                                            key={i}
                                        />
                                    )
                            )}
                        </div>
                        <ButtonUI
                            customClasses={`${base}__cta button--success hide--if-small`}
                            text={intl.get('promotions.cta')}
                            onClick={this.onClick}
                        />
                        {showHalalPopup && isSmallScreen && (
                            <HalalPopup
                                showModal={showHalalPopup}
                                handleClose={setHideHalalPopup}
                            />
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}

export default withRouter(Entry);
