import React, { Component, Fragment } from 'react';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    ElementsConsumer,
} from '@stripe/react-stripe-js';
import FloatingCta from '../FloatingCta';
import { inject, observer } from 'mobx-react';
import { currency } from '../../../services';
import intl from 'react-intl-universal';

const { clevertap } = window;

const style = {
    base: {
        color: '#000000',
        letterSpacing: '0.025em',
        padding: '15px 18px',
        fontFamily: '"Barlow"',
    },
    invalid: {
        color: '#9e2146',
    },
};

const base = 'select-popup';

@inject('selectPopupStore')
@observer
class CheckoutForm extends Component {
    handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        const { stripe, elements, selectPopupStore } = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const clientSecret = await selectPopupStore.confirmSetupIntent();
        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
            },
        });

        if (result) clevertap.event.push('3D-secure Verification Started');

        if (result.error) {
            // Display result.error.message in your UI.
            selectPopupStore.message = result.error.message;
        } else {
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to your server to save the
            // card to a Customer
            selectPopupStore.handleStoreStripeToken({
                id: result.setupIntent.payment_method,
            });
        }
    };

    render() {
        const { selectPopupStore } = this.props;
        return (
            <Fragment>
                <div className={`${base}__payment-form`}>
                    <div className={`${base}__payment-card-container`}>
                        <p className={`${base}__payment-form-title`}>
                            {intl.get('selectPopup.pageTwo.cardNumber')}
                        </p>
                        <CardNumberElement
                            className={`${base}__input card`}
                            style={style}
                        />
                    </div>
                    <div className="display--flex">
                        <div className={`${base}__payment-expiry-container`}>
                            <p className={`${base}__payment-form-title`}>
                                {intl.get('selectPopup.pageTwo.expiryDate')}
                            </p>
                            <CardExpiryElement
                                className={`${base}__input expiry`}
                                style={style}
                            />
                        </div>
                        <div className={`${base}__payment-cvv-container`}>
                            <p className={`${base}__payment-form-title`}>
                                {intl.get('selectPopup.pageTwo.cvc')}
                            </p>
                            <CardCvcElement
                                className={`${base}__input cvv`}
                                style={style}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${base}__payment-footer`}>
                    <p className={`${base}__error-message text--red`}>
                        {selectPopupStore.message}
                    </p>
                </div>
                <FloatingCta
                    onClick={this.handleSubmit}
                    customButtonClass={`${base}__cta button--select select`}
                    centerText={intl.get('selectPopup.pageTwo.cta')}
                    topText={
                        currency.localCurrency(
                            selectPopupStore.selected.package_value,
                            true
                        ) +
                        ' ' +
                        selectPopupStore.selected.package_value_desc
                    }
                    busy={selectPopupStore.isLoading}
                    showDesktop={true}
                />
            </Fragment>
        );
    }
}

export default () => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} />
        )}
    </ElementsConsumer>
);
