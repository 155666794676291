import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import BasePage from './basePage';
import TerminalRoot from '../components/TerminalRoot';
import DealRoot from '../components/DealRoot';
import { getGTMScript } from '../components/core/ScriptTag';
import { services } from '../services/index';
import terminalAuthStore from '../store/terminalAuthStore';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('terminal');

@observer
export default class extends Component {
    componentDidMount() {
        document.body.classList.add('terminal');
        terminalAuthStore.checkAuth();
    }

    render() {
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: 'Terminal',
            lpType: '',
            lpVersion: '',
        });

        return (
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                    {localizationTags(`terminal`).map((tag) => tag)}
                    {getCanonicalTag(`terminal`)}
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <TerminalRoot />
            </Fragment>
        );
    }
}
