import React, { Component } from 'react';
import {
    MEDIUM_SCREEN_MEDIA,
    SMALL_SCREEN_MEDIA,
} from '../../../services/constants';
import config from '../../../configs/parameters';
import intl from 'react-intl-universal';
import { template } from 'lodash';

const base = 'chef__chefs';

export default class SectionChefs extends Component {
    renderFacts = (facts) => {
        return (
            <div className={`${base}__description-fun-facts`}>
                {facts.map((fact, i) => (
                    <div
                        className={`${base}__description-fact display--flex`}
                        key={i}
                    >
                        <div className={`${base}__fact-icon-container`}>
                            <svg
                                className={`${base}__fact-icon iconmoon-icon ${fact.icon}`}
                            >
                                <use xlinkHref={`#icon-${fact.icon}`} />
                            </svg>
                        </div>
                        <div className={`${base}__fact-text text--bolder`}>
                            {fact.text}
                        </div>
                    </div>
                ))}
            </div>
        );
    };
    renderChef = () => {
        const smallAndMediumScreens =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches ||
            window.matchMedia(MEDIUM_SCREEN_MEDIA).matches;
        const brandName = config.brandName;
        const chefFacts = [
            {
                name: intl.get('chefPage.sectionChef.content1.name'),
                description: template(
                    intl.get('chefPage.sectionChef.content1.description')
                )({ brandName }),
                facts: [
                    {
                        icon: 'pen-tool',
                        text: intl.get('chefPage.sectionChef.content1.fact1'),
                    },
                    {
                        icon: 'guitar',
                        text: intl.get('chefPage.sectionChef.content1.fact2'),
                    },
                    {
                        icon: 'chef-star',
                        text: template(
                            intl.get('chefPage.sectionChef.content1.fact3')
                        )({ brandName }),
                    },
                ],
            },
            {
                name: intl.get('chefPage.sectionChef.content2.name'),
                description: intl.get(
                    'chefPage.sectionChef.content2.description'
                ),
                facts: [
                    {
                        icon: 'airplane',
                        text: intl.get('chefPage.sectionChef.content2.fact1'),
                    },
                    {
                        icon: 'chef-hat',
                        text: intl.get('chefPage.sectionChef.content2.fact2'),
                    },
                    {
                        icon: 'policeman',
                        text: intl.get('chefPage.sectionChef.content2.fact3'),
                    },
                ],
            },
            {
                name: intl.get('chefPage.sectionChef.content3.name'),
                description: template(
                    intl.get('chefPage.sectionChef.content3.description')
                )({ brandName }),
                facts: [
                    {
                        icon: 'trophy',
                        text: intl.get('chefPage.sectionChef.content3.fact1'),
                    },
                    {
                        icon: 'chef-hat',
                        text: template(
                            intl.get('chefPage.sectionChef.content3.fact2')
                        )({ brandName }),
                    },
                    {
                        icon: 'sun',
                        text: intl.get('chefPage.sectionChef.content3.fact3'),
                    },
                ],
            },
            {
                name: intl.get('chefPage.sectionChef.content4.name'),
                description: template(
                    intl.get('chefPage.sectionChef.content4.description')
                )({ brandName }),
                facts: [
                    {
                        icon: 'trophy',
                        text: intl.get('chefPage.sectionChef.content4.fact1'),
                    },
                    {
                        icon: 'hands',
                        text: intl.get('chefPage.sectionChef.content4.fact2'),
                    },
                    {
                        icon: 'chef-hat',
                        text: intl.get('chefPage.sectionChef.content4.fact3'),
                    },
                ],
            },
        ];

        return (
            <div className={`${base}__content-container container`}>
                {chefFacts.map((chef, i) =>
                    i % 2 && !smallAndMediumScreens ? (
                        <div
                            className={`${base}__content display--flex`}
                            key={i}
                        >
                            <div
                                className={`${base}__description-container right`}
                            >
                                <div
                                    className={`${base}__description-header-container`}
                                >
                                    <p
                                        className={`${base}__description-header text--bolder`}
                                    >
                                        Chef
                                    </p>
                                    <div className={`${base}__name-container`}>
                                        <p
                                            className={`${base}__description-name text--bolder`}
                                        >
                                            {chef.name}
                                        </p>
                                        <div
                                            className={`${base}__grey-box ${chef.name.toLowerCase()}`}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`${base}__description-body-container`}
                                >
                                    <p className={`${base}__description-body`}>
                                        {chef.description}
                                    </p>
                                </div>
                                {this.renderFacts(chef.facts)}
                            </div>
                            <div className={`${base}__display-pic-container`}>
                                <div>
                                    <div
                                        className={`${base}__display-pic right chef-${chef.name.toLowerCase()}`}
                                    />
                                    <div
                                        className={`${base}__green-box ${chef.name.toLowerCase()} right`}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`${base}__content display--flex`}
                            key={i}
                        >
                            <div className={`${base}__display-pic-container`}>
                                <div>
                                    <div
                                        className={`${base}__display-pic chef-${chef.name.toLowerCase()}`}
                                    />
                                    {!smallAndMediumScreens && (
                                        <div
                                            className={`${base}__green-box ${chef.name.toLowerCase()}`}
                                        />
                                    )}
                                </div>
                                {smallAndMediumScreens && (
                                    <div
                                        className={`${base}__description-header-container`}
                                    >
                                        <p
                                            className={`${base}__description-header`}
                                        >
                                            Chef
                                        </p>
                                        <div
                                            className={`${base}__name-container`}
                                        >
                                            <p
                                                className={`${base}__description-name text--bolder`}
                                            >
                                                {chef.name}
                                            </p>
                                            <div
                                                className={`${base}__grey-box ${chef.name.toLowerCase()}`}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={`${base}__description-container`}>
                                {!smallAndMediumScreens && (
                                    <div
                                        className={`${base}__description-header-container`}
                                    >
                                        <p
                                            className={`${base}__description-header text--bolder`}
                                        >
                                            Chef
                                        </p>
                                        <div
                                            className={`${base}__name-container`}
                                        >
                                            <p
                                                className={`${base}__description-name text--bolder`}
                                            >
                                                {chef.name}
                                            </p>
                                            <div
                                                className={`${base}__grey-box ${chef.name.toLowerCase()}`}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={`${base}__description-body-container`}
                                >
                                    <p className={`${base}__description-body`}>
                                        {chef.description}
                                    </p>
                                </div>
                                {this.renderFacts(chef.facts)}
                                {i !== chefFacts.length - 1 &&
                                    smallAndMediumScreens && (
                                        <hr className={`${base}__dash`} />
                                    )}
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    };
    render() {
        return (
            <div className={`${base}`}>
                <div className={`${base}__header-container container`}>
                    <h3 className={`${base}__header text--bolder`}>
                        {intl.get('chefPage.header')}
                    </h3>
                </div>
                {this.renderChef()}
            </div>
        );
    }
}
