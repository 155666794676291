import React, { Component } from 'react';
import CarouselUI from './CarouselUI';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import config from '../../../configs/parameters';
import intl from 'react-intl-universal';
import { template } from 'lodash';

const base = 'careers__how-we-hire';

export default class HowWeHire extends Component {
    render() {
        const brandName = config.brandName;
        const content = [
            {
                header: intl.get('careers.howWeHire.item1.header'),
                content: intl.get('careers.howWeHire.item1.content'),
            },
            {
                header: intl.get('careers.howWeHire.item2.header'),
                content: intl.get('careers.howWeHire.item2.content'),
            },
            {
                header: intl.get('careers.howWeHire.item3.header'),
                content: template(intl.get('careers.howWeHire.item3.content'))({
                    brandName,
                }),
            },
        ];

        return (
            <div className={base}>
                <div className={`${base}__content`}>
                    <p className={`${base}__header text--bolder`}>
                        {intl.get('careers.howWeHire.header')}
                    </p>
                    <p className={`${base}__sub-header`}>
                        {intl.get('careers.howWeHire.subHeader')}
                    </p>
                </div>
                <CarouselUI
                    content={content}
                    height={
                        window.matchMedia(SMALL_SCREEN_MEDIA).matches
                            ? 379
                            : 332
                    }
                    showStep={true}
                    name="how-we-hire"
                    contentWidth={420}
                    width={
                        window.matchMedia(SMALL_SCREEN_MEDIA).matches
                            ? 319
                            : 540
                    }
                />
            </div>
        );
    }
}
