import React from 'react';
import { Provider } from 'mobx-react';

import TerminalMenuStore from '../store/terminalMenuStore';
import DishHeaderStore from '../store/dishHeaderStore';
import menuHeaderStore from '../store/menuHeaderStore';

import BaseLayout from './core/baseLayout/Master_BaseLayout';
import DealComboSelection from './page/terminalPage/DealComboSelection';
import MainEntry from './page/terminalPage/MainEntry';
import DealComboHeader from './core/headers/DealComboHeader';

const dishHeaderStore = new DishHeaderStore();

const stores = {
    dishHeaderStore,
    menuHeaderStore,
    terminalMenuStore: TerminalMenuStore,
};

const Header = () => {
    return (
        <Provider {...stores}>
            <DealComboHeader />
        </Provider>
    );
};

export default class extends React.Component {
    render() {
        return (
            <BaseLayout.Page HeaderComponent={Header}>
                <main>
                    <Provider {...stores}>
                        <DealComboSelection />
                    </Provider>
                </main>
            </BaseLayout.Page>
        );
    }
}
