import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import Modal from './Modal';
import ButtonUI from './loginPopup/ButtonUI';
import { SMALL_SCREEN_MEDIA, MAGICLINK } from '../../services/constants';

const noop = () => {};
const base = 'login-popup';

@inject('loginPopupStore')
@observer
export default class MagicLoginPopup extends React.Component {
    handleLogin = () => {
        const { loginPopupStore } = this.props;
        loginPopupStore.doLogin(
            MAGICLINK,
            loginPopupStore.magicLinkAccessToken
        );
    };

    handleHidePopup = () => {
        const { loginPopupStore } = this.props;
        loginPopupStore.setMagicLinkAccessToken('');
    };

    render() {
        const { loginPopupStore } = this.props;
        const handleClosing = this.props.dismissable
            ? ::this.handleHidePopup
            : noop;
        const showCloseIcon = Boolean(this.props.showCloseIcon);
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return (
            <Modal
                showModal={loginPopupStore.magicLinkAccessToken}
                showCloseIcon={showCloseIcon}
                closeButtonMargin={`${base}__close-margin`}
                logoMargin={`${base}__logo-margin`}
                backButtonMargin={`${base}__back-margin`}
                backButtonText="Back"
                contentClasses={`${base}__container ${
                    !isMobile && 'modal__content--golden-ratio'
                }`}
                onBackdropClicked={handleClosing}
                onCloseClicked={handleClosing}
            >
                <div className={base}>
                    <h3 className={`${base}__title`}>
                        {intl.get('loginPopup.magicLoginView.header')}
                    </h3>
                    <ButtonUI
                        text={intl.get('loginPopup.magicLoginView.cta')}
                        customClasses="button--success mtxl"
                        id="magic-login-btn"
                        onClick={this.handleLogin}
                    />
                </div>
            </Modal>
        );
    }
}
