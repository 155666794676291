import React from 'react';
import { inject, observer } from 'mobx-react';
import MainItems from './MainItems';
import AddonItems from './AddonItems';
import Modal from '../../core/Modal';
import PopupResetKiosk from './PopupResetKiosk';
import { stubTrue } from 'lodash';
const base = 'terminal-menu-items-ui';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    state = {
        index: '',
        name: '',
        type: '',
    };
    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const idIndex = parseInt(urlParams.get('id'));
        const type = urlParams.get('type');
        const comboName = decodeURIComponent(urlParams.get('name'));

        this.setState({ index: idIndex, name: comboName, type: type });
    }
    handleAddItemToCart = (item, isAddon) => {
        const { terminalMenuStore, terminalCartStore } = this.props;

        let totalComboList = { ...terminalMenuStore.totalComboList };
        totalComboList[this.state.name][this.state.type][this.state.index - 1] = item;

        terminalMenuStore.totalComboList = totalComboList;
        terminalCartStore.addComboCart({ add: false });
        history.back();
    };

    renderNumbering = (index) => {
        if (index == '1') {
            return 1 + 'st';
        } else if (index == '2') {
            return 2 + 'nd';
        } else if (index == '3') {
            return 3 + 'rd';
        } else {
            return index + 'th';
        }
    };

    renderTitle(title) {
        const { terminalMenuStore } = this.props;

        if (terminalMenuStore.itemsUIView == 'Mains') {
            return 'meal';
        } else if (terminalMenuStore.itemsUIView == 'Drinks') {
            return 'drink';
        } else {
            return 'snack or dessert';
        }
    }
    renderContent = () => {
        const store = this.props.terminalMenuStore;
        const { itemsUIView, itemTypes, comboObject, itemsFetcher, currentDealCombo } = store;

        const items = itemsFetcher?.data?.filter((item) => item.quantity_left > 0);

        const mainItems = items?.filter((item) => item.item_type === 'Item');
        const addonItems = items?.filter((item) => item.item_type === 'Addons');

        if (itemsUIView === itemTypes.MAINS) {
            return (
                <>
                    <MainItems
                        onAddToCart={this.handleAddItemToCart}
                        comboItem={comboObject}
                        items={mainItems}
                        comboPrice={20}
                    />
                </>
            );
        } else {
            return (
                <AddonItems
                    items={addonItems}
                    onAddToCart={this.handleAddItemToCart}
                    comboItem={comboObject}
                    itemsUIView={this.state.type}
                />
            );
        }
    };
    handleClosePopup = () => {
        this.props.terminalMenuStore.setShowPopup('');
    };

    render() {
        const { terminalMenuStore, terminalCartStore } = this.props;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const idIndex = parseInt(urlParams.get('id'));
        const type = urlParams.get('type');
        const comboName = decodeURIComponent(urlParams.get('name'));

        return (
            <div className={base}>
                <div
                    style={{
                        fontWeight: 600,
                        fontSize: '36px',
                        color: 'white',
                        marginBottom: '16px',
                        marginLeft: '8px',
                    }}
                >
                    {terminalMenuStore.totalComboList &&
                    terminalMenuStore.totalComboList[comboName][type][idIndex - 1].menu_item_id
                        ? `Change ${this.renderNumbering(this.state.index)}
                        ${this.renderTitle()}`
                        : `Select ${this.renderNumbering(this.state.index)}
                            ${this.renderTitle()}`}
                </div>
                {this.renderContent()}
                <Modal
                    showModal={Boolean(terminalMenuStore.showingPopup)}
                    showCloseIcon={false}
                    contentClasses="modal__content"
                    onBackdropClicked={this.handleClosePopup}
                    disableScrollingOnMobile={true}
                >
                    {terminalMenuStore.showingPopup === 'reset-kiosk' ? (
                        <PopupResetKiosk onClosePopup={this.handleClosePopup} />
                    ) : null}
                </Modal>
            </div>
        );
    }
}
