import { observable, computed, action } from 'mobx';
import Notifiable from './services/notifiable';
import { RESOURCE } from '../services/types';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import { services } from '../services/index'

class OrderHistoryStore extends Notifiable {
    @observable orderHistory = RESOURCE.Initial;
    @observable upcomingOrders = RESOURCE.Initial;
    @observable message = '';
    @observable.ref userFetcher = RESOURCE.Initial;    
    @observable ordersThisWeek = null

    @computed get username() { return get(this.userFetcher, ['data', 'username'], ''); }
    @computed get deliveryInstruction() { return get(this.deliveryInfoFetcher, ['data', 'address', 'delivery_instruction'], '')}    

    constructor() {
        super();

        this.setUpNotification();
        // when(
        //     () => (RESOURCE.Success.is(this.orderHistory) && RESOURCE.Success.is(this.upcomingOrders)),
        //     () => {
        //         this.getNumberOfOrdersThisWeek()
        //     }
        // );
    }

    @computed get isEmpty() {
        if (RESOURCE.Success.is(this.orderHistory) && RESOURCE.Success.is(this.upcomingOrders)) {
            if (this.orderHistory.data.deliveries.length === 0 && this.upcomingOrders.data.deliveries.length === 0) {
                return true;
            }
        }

        return false;
    }

    @action getNumberOfOrdersThisWeek() {
        const combined = this.orderHistory.data.deliveries.concat(this.upcomingOrders.data.deliveries)
        const combinedUnique = uniqBy(combined, 'id')
        this.ordersThisWeek = 0
        for (let order in combinedUnique) {
            services.api.GetDeliveryDetail({id: combinedUnique[order].id})
                .then(response => {
                    if (response.menu_type === "Dinner" && response.time_slot_start > 1524405727260 && response.time_slot_end < 1525010557000 && response.status !== "Cancelled") {
                        this.ordersThisWeek++
                    }
                })
                .catch(() => {})
        }
    }

    @action loadOrderHistory() {
        this.orderHistory = RESOURCE.Loading;
        this.upcomingOrders = RESOURCE.Loading;

        services.api.GetPastOrders()
            .then(history => { this.orderHistory = RESOURCE.Success(history); })
            .catch(response => { this.orderHistory = RESOURCE.Failure(response.message, response); });

        services.api.GetUpcomingOrders()
            .then(upcoming => { this.upcomingOrders = RESOURCE.Success(upcoming); })
            .catch(response => { this.upcomingOrders = RESOURCE.Failure(response.message, response); });
    }
}

const store = new OrderHistoryStore();
export default store;