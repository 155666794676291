import React, { Component, Fragment } from 'react';
import BasePage from './basePage';
import WalletCheckoutRoot from '../components/WalletCheckoutRoot';
import { services } from '../services/index';
import { Helmet } from 'react-helmet';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

export default class extends Component {
    componentDidMount() {
        document.body.classList.add('checkout');
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta name="nosnippet" />
                    <title>{services.getTitle('packageCheckout')}</title>
                    {localizationTags(`wallet/checkout`).map((tag) => tag)}
                    {getCanonicalTag(`wallet/checkout`)}
                </Helmet>
                <BasePage />
                <WalletCheckoutRoot />
            </Fragment>
        );
    }
}
