/* eslint-disable no-unused-vars */
import Bacon from 'baconjs';
import { parseUA } from '../index';
import isBot from 'isbot';

const isMobileSafari = (browserName) => browserName === 'Mobile Safari';
const isMajorLowerThan = (major, comparator) =>
    Number(major) < Number(comparator);
const isMajorLowerThanOrEqualTo = (major, comparator) =>
    Number(major) <= Number(comparator);
const isMajorEqualTo = (major, comparator) =>
    Number(major) === Number(comparator);
const isMajorGreaterThan = (major, comparator) =>
    Number(major) > Number(comparator);
const isMajorGreaterThanOrEqualTo = (major, comparator) =>
    Number(major) >= Number(comparator);

export default () => {
    const parseResult = parseUA(window.navigator.userAgent);
    const { browser } = parseResult;

    // Check Safari 9 or lower
    const isSafari9OrLower =
        isMobileSafari(browser.name) &&
        isMajorLowerThanOrEqualTo(browser.major, '9');
    if (isSafari9OrLower || isBot(navigator.userAgent)) {
        return Bacon.constant(false);
    }

    return Bacon.constant(true);
};
