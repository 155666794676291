import React, { Component } from 'react';

const base = 'terminal-snack-dessert-items';

export default class SnackAndDessertItems extends Component {
    state = {
        percentage: 0,
    };
    componentDidMount() {
        const { item, addAddonItemToCart } = this.props;
        let percentage = ((item.price - item.special_offer_price) / item.price) * 100;
        this.setState({ percentage: Math.round(percentage) });
        console.log(item, 'percentage');
    }

    render() {
        const { item, addAddonItemToCart } = this.props;

        return (
            <div className={`${base}`}>
                {item.special_offer_price ? (
                    <div className="discount-banner">
                        {this.state.percentage}% <span className="off-txt">OFF</span>
                    </div>
                ) : null}

                <img
                    onClick={() => addAddonItemToCart(item)}
                    src={item.image}
                    className="image cursor-pointer"
                    alt="snacks/dessert item image"
                />
                <div className="name">
                    {item.title_bold && <p className="name">{item.title_bold}</p>}
                    {item.special_offer_price ? (
                        <>
                            <p style={{ textDecoration: 'line-through' }} className="price-logic">
                                (+ {item.price.toFixed(2)})
                            </p>
                            <p className="special-price">(+ {item.special_offer_price.toFixed(2)})</p>
                        </>
                    ) : (
                        <p className="price-logic">(+ {item.price.toFixed(2)})</p>
                    )}
                </div>
            </div>
        );
    }
}
