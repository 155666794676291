import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import SubmitButton from './SubmitButton';
import VerificationButton from './VerificationButton';

const base = 'complete-signup';

@inject('completeSignUpStore')
@observer
export default class extends React.Component {
    render() {
        const { completeSignUpStore } = this.props;
        const { validationResult } = completeSignUpStore;
        const handleChange = ::completeSignUpStore.handleChange;

        return (
            <div className="col-lg-12">
                <p className="mbm lh--125 fs--small-medium">
                    {intl.get('user.completeSignUpInstruction.small')}
                </p>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 display--flex col-reduced-gutter--right normal-gutter--if-small">
                        <input
                            type="text"
                            className={`${base}__country-code input fs--medium mbm`}
                            name="countryCode"
                            title={intl.get('completeSignUp.countryCode')}
                            value={completeSignUpStore.countryCode}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            className="input width--100 fs--medium mbm"
                            title={intl.get('completeSignUp.phoneNumber')}
                            name="phoneNumber"
                            placeholder={intl.get('completeSignUp.phoneNumber')}
                            value={completeSignUpStore.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 display--flex col-reduced-gutter--left normal-gutter--if-small">
                        <div
                            className={`${base}--small__submit width--100 mbm`}
                        >
                            <VerificationButton
                                onClick={

                                        ::completeSignUpStore.requestVerificationCode
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduced-gutter--right normal-gutter--if-small">
                        <input
                            type="text"
                            className="input width--100 fs--medium mbm"
                            title={intl.get(
                                'completeSignUp.verificationCode.title'
                            )}
                            name="verificationCode"
                            autoComplete="off"
                            placeholder={intl.get(
                                'completeSignUp.verificationCode.placeholder'
                            )}
                            value={completeSignUpStore.verificationCode}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 display--flex col-reduced-gutter--left normal-gutter--if-small">
                        <div className={`${base}--small__submit width--100`}>
                            <SubmitButton
                                text={intl.get('completeSignUp.ctaSmall')}
                            />
                        </div>
                    </div>
                </div>
                <p className="text--red fs--small-medium mtm">
                    {validationResult.getFirstError()}
                </p>
            </div>
        );
    }
}
