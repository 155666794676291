import React from 'react';
import intl from 'react-intl-universal';
import { cities, GetCity } from '../../../services/city';

const city = GetCity();
const base = 'business';

export default class extends React.Component {
    render() {
        return (
            <div className={`${base}__happy-customer-section`}>
                <div className="container">
                    <h1 className="text--bold fs--medium-large mbl width--75 lh--125 m0a">
                        {intl.get(`business.${city}.happyCustomerTitle`)}
                    </h1>
                    <div className="hide--if-large show--if-small">
                        <div className="separator mtm mbl m0a" />
                    </div>
                    <div className="display--flex flex-wrap mrl mll">
                        <div className={`${base}__logo-container flex-1-only`}>
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/happy-customer-khazanah.png"
                                alt="Khazanah"
                            />
                        </div>
                        <div className={`${base}__logo-container flex-1-only`}>
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/happy-customer-rhb.png"
                                alt="RHB"
                            />
                        </div>
                        <div className={`${base}__logo-container flex-1-only`}>
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/happy-customer-grab.png"
                                alt="Grab"
                            />
                        </div>
                        <div className={`${base}__logo-container flex-1-only`}>
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/happy-customer-paypal.png"
                                alt="Paypal"
                            />
                        </div>
                        <div className={`${base}__logo-container flex-1-only`}>
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/happy-customer-lazada.png"
                                alt="Lazada"
                            />
                        </div>
                    </div>
                    <div className="hide--if-small">
                        <div className="separator mtm mbl m0a" />
                    </div>
                    <p
                        className={`${base}__happy-customer-subheading lh--150 fs--small-medium`}
                        dangerouslySetInnerHTML={{
                            __html: intl.get(
                                `business.${city}.happyCustomerSubheading`
                            ),
                        }}
                    />
                </div>
            </div>
        );
    }
}
