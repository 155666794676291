import React from 'react';
import intl from 'react-intl-universal';
import { template } from 'lodash';
import config from '../../../configs/parameters';

const base = 'terminal-about';

export default class extends React.Component {
    render() {
        const brandName = config.brandName;

        return (
            <div className={`${base}__how`}>
                <h5 className={`${base}__question text--bold`}>
                    {intl.get('about.how.question')}
                </h5>
                <h2 className={`${base}__how-title lh--125`}>
                    {intl.get('about.how.title')}
                </h2>
                <div
                    className={`${base}__separator separator separator--red mtm mbm`}
                />
                <p className={`${base}__how-subheading lh--150 mbxl`}>
                    {template(intl.get('about.how.subheading'))({ brandName })}
                </p>
                <div className={`${base}__how-steps`}>
                    <div className="row">
                        <div
                            className={`${base}__how-step col-lg-6 col-md-6 col-sm-6`}
                        >
                            <div className={`${base}__how-step-1`} />
                            <p
                                className={`${base}__how-step-title text--bolder mtm`}
                            >
                                {intl.get('about.how.step1Title')}
                            </p>
                            <p className={`${base}__how-step-subheading`}>
                                {intl.get('about.how.step1Subheading')}
                            </p>
                        </div>
                        <div
                            className={`${base}__how-step col-lg-6 col-md-6 col-sm-6`}
                        >
                            <div className={`${base}__how-step-2`} />
                            <p
                                className={`${base}__how-step-title text--bolder mtm`}
                            >
                                {intl.get('about.how.step2Title')}
                            </p>
                            <p className={`${base}__how-step-subheading`}>
                                {intl.get('about.how.step2Subheading')}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className={`${base}__how-step col-lg-6 col-md-6 col-sm-6`}
                        >
                            <div className={`${base}__how-step-3`} />
                            <p
                                className={`${base}__how-step-title text--bolder mtm`}
                            >
                                {intl.get('about.how.step3Title')}
                            </p>
                            <p className={`${base}__how-step-subheading`}>
                                {intl.get('about.how.step3Subheading')}
                            </p>
                        </div>
                        <div
                            className={`${base}__how-step col-lg-6 col-md-6 col-sm-6`}
                        >
                            <div className={`${base}__how-step-4`} />
                            <p
                                className={`${base}__how-step-title text--bolder mtm`}
                            >
                                {intl.get('about.how.step4Title')}
                            </p>
                            <p className={`${base}__how-step-subheading`}>
                                {intl.get('about.how.step4Subheading')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
