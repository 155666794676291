import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import PaymentMethod from './PaymentMethod';
import Cookies from 'js-cookie';
import { GetLanguage } from '../../../services/translation';

const SelectIcon = ({ isSelected }) => {
    const icon = isSelected
        ? '#icon-radio_button_checked'
        : '#icon-radio_button_unchecked';

    return (
        <svg className="payment-method-picker__button-icon iconmoon-icon">
            <use xlinkHref={icon} />
        </svg>
    );
};

const PaymentMethodsPicker = ({ currentSelected, items, onSelect }) => {
    const base = 'payment-method-picker';

    return (
        <ul className={base}>
            {items.map((item, index) => (
                <li key={index} className={`${base}__card display--flex`}>
                    <div className="flex-1 flex-shrink-1">
                        <PaymentMethod paymentMethod={item} />
                    </div>
                    <div>
                        <button
                            type="button"
                            className={`${base}__button`}
                            onClick={onSelect.bind(null, item)}
                        >
                            <SelectIcon
                                isSelected={currentSelected.id === item.id}
                            />
                        </button>
                    </div>
                </li>
            ))}
        </ul>
    );
};

@inject('store')
@inject('paymentStore')
@observer
export default class extends React.Component {
    selectAndGoBack(paymentMethod) {
        const { paymentStore, store } = this.props;

        store.setPaymentMethod(paymentMethod);
        paymentStore.goToView(paymentStore.SHOW_VIEW)();
    }

    render() {
        const base = 'list-payment-methods';
        const { paymentStore, store } = this.props;
        const boostUser = Cookies.get('boost_user');
        const isLangMalay = GetLanguage() === 'my';

        return (
            <div className={base}>
                <div className="pts pbs">
                    <PaymentMethodsPicker
                        items={paymentStore.paymentList}
                        currentSelected={store.paymentMethod}
                        onSelect={::this.selectAndGoBack}
                    />
                </div>
                <div className="clearfix">
                    {!boostUser && (
                        <div className="float-left">
                            <button
                                className={`${base}__add button button--medium button--success ${
                                    isLangMalay ? 'small-font' : ''
                                }`}
                                onClick={::paymentStore.goToView(
                                    paymentStore.ADD_PAYMENT
                                )}
                            >
                                {intl.get('packageCheckout.payment.addPayment')}
                            </button>
                        </div>
                    )}
                    <div className="float-right">
                        <button
                            className={`${base}__cancel button button--small`}
                            onClick={::paymentStore.goToView(
                                paymentStore.SHOW_VIEW
                            )}
                        >
                            {intl.get('packageCheckout.payment.cancel')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
