import React, { Component } from 'react';
import AddressList from './AddressList';
import AddressAutocomplete from '../../core/AddressAutocomplete';
import Bubble from './Bubble';
import { inject, observer } from 'mobx-react';
import { parseLocation } from '../../../services/geoparser';
import { when } from 'mobx';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import intl from 'react-intl-universal';

const base = 'address-selector';

@inject(
    'menuListStore',
    'cartStore',
    'addressAutocompleteStore',
    'deliveryAddressStore'
)
@observer
export default class AddressSelector extends Component {
    state = {
        coords: null,
    };

    handleSelectAddress = (address) => {
        const { deliveryAddressStore, hideSelectors, menuListStore } =
            this.props;
        deliveryAddressStore.setSelected({
            address,
            date: menuListStore.activeDateObj.date,
            refreshMenu: true,
        });
        hideSelectors();
    };

    handleSubmitAddress = (place) => {
        const { deliveryAddressStore, menuListStore, hideSelectors } =
            this.props;
        deliveryAddressStore.setNewAddress(parseLocation(place), true);
        when(
            () => deliveryAddressStore.newAddress,
            () => {
                deliveryAddressStore.saveAddress({
                    date: menuListStore.activeDateObj.date,
                    refreshMenu: true,
                });
                hideSelectors();
            }
        );
    };

    handleAddressSelected = (prediction, cb) => {
        const { addressAutocompleteStore, deliveryAddressStore } = this.props;
        addressAutocompleteStore.handleAddressSelected(prediction, cb);

        when(
            () => deliveryAddressStore.place,
            () => {
                deliveryAddressStore.place.geometry = {};
                deliveryAddressStore.place.geometry.location =
                    addressAutocompleteStore.setAddressCoordinate(
                        deliveryAddressStore.place
                    );
            }
        );
    };

    handleAddressConfirmed = (place) => {
        if (place) {
            const { deliveryAddressStore } = this.props;
            deliveryAddressStore.setPlace(place);
        }
        this.handleSubmitAddress(place);
    };

    handleNearbyAddressClicked = (place) => {
        const { addressAutocompleteStore } = this.props;
        addressAutocompleteStore.nearbyLocation = place;
        this.handleAddressSelected(place, this.handleAddressConfirmed);
    };

    renderBody = () => {
        const { deliveryAddressStore, addressAutocompleteStore } = this.props;
        const savedAddresses =
            deliveryAddressStore.addresses &&
            deliveryAddressStore.addresses.filter(
                (address) =>
                    !address.pickup_location &&
                    JSON.stringify(address) !==
                        JSON.stringify(deliveryAddressStore.selectedAddress)
            );

        return (
            <div className={`${base}__container`}>
                <div className={`${base}__header-container`}>
                    <p className={`${base}__header text--bolder`}>
                        {intl.get('addressSelector.title')}
                    </p>
                </div>
                <div className={`${base}__input-box`}>
                    <div className={`${base}__auto-complete-container`}>
                        <AddressAutocomplete
                            showBorder={true}
                            inputContainerClass={`${base}__input-container`}
                            marginLeft={15}
                            onAddressSelected={this.handleAddressSelected}
                            onAddressConfirmed={this.handleAddressConfirmed}
                        />
                    </div>
                </div>
                <AddressList
                    title={intl.get('addressSelector.savedAddresses')}
                    addresses={savedAddresses}
                    onClick={this.handleSelectAddress}
                    savedAddress={true}
                    base="address-selector"
                />
                <AddressList
                    title={intl.get('addressSelector.nearbyLocations')}
                    addresses={addressAutocompleteStore.nearbyLocations}
                    onClick={this.handleNearbyAddressClicked}
                    marginTop={30}
                    base="address-selector"
                />
            </div>
        );
    };

    render() {
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return isMobile ? (
            this.renderBody()
        ) : (
            <Bubble>{this.renderBody()}</Bubble>
        );
    }
}
