import React, { Component } from 'react';
import template from 'lodash/template';
import intl from 'react-intl-universal';
import { inject, observer } from 'mobx-react';
import { services } from '../../../services';

const base = 'section-hero';

@inject('loginPopupStore')
@observer
export default class ReferralCta extends Component {
    render() {
        const { loginPopupStore, onClick, hideIfLarge } = this.props;

        return (
            <div
                className={`${base}__cta-button-container referral ${
                    hideIfLarge ? 'hide--if-large' : ''
                } text--left`}
            >
                <button
                    className={`${base}__cta-button referral button button--success`}
                    onClick={onClick}
                >
                    {template(intl.get('onboarding.sectionReferralHero.cta'))({
                        reward: loginPopupStore.referral().reward,
                        currency: services.getParam('currency'),
                    })}
                </button>
                <p>{intl.get('onboarding.sectionReferralHero.disclaimer')}</p>
            </div>
        );
    }
}
