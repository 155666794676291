import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
const base = 'terminal-phone-popup';

@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    state = {
        pinNumber: ['', '', '', ''],
        numClick: -1,
        checkNum: null,
        errorCheck: false,
    };

    componentDidMount = () => {
        this.props.triggerCountdown();
    };

    componentWillUnmount = () => {
        clearInterval(this.props.timer);
    };

    handleBackspaceClick = () => {
        const { terminalMenuStore } = this.props;
        const filterNum = this.state.pinNumber;
        console.log(filterNum, this.state.checkNum, 'filter num');
        let checkNumNow = this.state.checkNum;
        filterNum[this.state.checkNum] = '';
        checkNumNow = checkNumNow - 1;
        this.setState({
            pinNumber: filterNum,
            checkNum: checkNumNow,
        });

        terminalMenuStore.otpValidationMessage = null;
    };

    handleNumClick = (num) => {
        const PinList = this.state.pinNumber;
        const { terminalMenuStore } = this.props;
        if (PinList[3] == '') {
            let numBreak;
            for (var i = 0; i < PinList.length; i++) {
                if (PinList[i].toString() == '') {
                    PinList[i] = num;
                    console.log(i, 'num i');
                    numBreak = i;
                    break;
                }
            }

            this.setState({
                numClick: num,
                pinNumber: PinList,
                checkNum: numBreak,
            });

            if (numBreak == 3) {
                const pin = PinList.join('');
                terminalMenuStore.sendOTP(pin);
                // this.setState({ errorCheck: true });
            }
        }
    };
    render() {
        const numbersToDisplay = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
        const { numClick } = this.state;
        const { terminalMenuStore } = this.props;

        const errorCheck = terminalMenuStore.otpValidationMessage
            ? true
            : false;

        return (
            <Fragment>
                <div className="display--flex flex-align-items-center">
                    <input
                        type="number"
                        value={this.state.pinNumber[0]}
                        disabled={true}
                        className={`${base} pin-input ${
                            this.state.checkNum == 0 && !errorCheck
                                ? 'pin-input-selected'
                                : errorCheck
                                ? 'pin-input-error'
                                : null
                        } `}
                    ></input>
                    <input
                        type="number"
                        disabled={true}
                        value={this.state.pinNumber[1]}
                        className={`${base} pin-input ${
                            this.state.checkNum == 1 && !errorCheck
                                ? 'pin-input-selected'
                                : errorCheck
                                ? 'pin-input-error'
                                : null
                        }`}
                    ></input>
                    <input
                        type="number"
                        disabled={true}
                        value={this.state.pinNumber[2]}
                        className={`${base} pin-input ${
                            this.state.checkNum == 2 && !errorCheck
                                ? 'pin-input-selected'
                                : errorCheck
                                ? 'pin-input-error'
                                : null
                        }`}
                    ></input>
                    <input
                        type="number"
                        disabled={true}
                        value={this.state.pinNumber[3]}
                        className={`${base} pin-input ${
                            this.state.checkNum == 3 && !errorCheck
                                ? 'pin-input-selected'
                                : errorCheck
                                ? 'pin-input-error'
                                : null
                        }`}
                    ></input>
                    <img
                        onClick={(e) => {
                            this.handleBackspaceClick();
                        }}
                        style={{ width: '29px', height: '24px' }}
                        src="/backspace-cross.png"
                    />
                </div>
                {errorCheck ? (
                    <div style={{ textAlign: 'center' }}>
                        <p className={`${base}__error_message`}>
                            {terminalMenuStore.otpValidationMessage}
                        </p>
                    </div>
                ) : null}

                <div className={`${base}__numpad`}>
                    {numbersToDisplay.map((num) => (
                        <div
                            onClick={() => this.handleNumClick(num)}
                            key={num}
                            className={`${base}__numpad_key ${
                                numClick === num ? 'numpad_clicked_key' : ''
                            }`}
                        >
                            {num}
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    }
}
