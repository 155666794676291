import React from 'react';
import { inject, observer } from 'mobx-react';

import FormInputAddress from './FormInputAddress';
import FormAdjustAddress from './FormAdjustAddress';

@inject('terminalAddressStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.terminalAddressStore.fetchTerminalInfo();
    }

    render() {
        const store = this.props.terminalAddressStore;

        return (
            <div>
                {store.currentStep === store.STEP_INPUT_ADDRESS ? (
                    <FormInputAddress />
                ) : null}
                {store.currentStep === store.STEP_ADJUST_ADDRESS ? (
                    <FormAdjustAddress />
                ) : null}
            </div>
        );
    }
}
