import React, { Component } from 'react';
import intl from 'react-intl-universal';

const base = 'terms-and-conditions';

export default class TermsAndConditions extends Component {
    render() {
        const { header } = this.props;
        return (
            <div className={base}>
                <p>{header}</p>
                <a
                    className={`${base}__terms-link text--primary`}
                    href="/terms"
                    target="_blank"
                >
                    {intl.get('termsAndConditions.termsAndConditions')}
                </a>
                .
            </div>
        );
    }
}
