import React from 'react';

const base = 'cart-dropdown';

export default ({ children, isShowing, onClickOutside }) => {
    let baseClasses;
    if (isShowing) {
        baseClasses = 'position--relative ' + base + '__wrapper show';
    } else if (!isShowing) {
        baseClasses = 'position--relative ' + base + '__wrapper hide';
    }
    // const baseClasses = cx('position--relative', base + '__wrapper', {
    //     'show': isShowing,
    //     'hide': !isShowing
    // });

    return (
        <div className={baseClasses}>
            <div className={`${base}__backdrop`} onClick={onClickOutside}></div>
            <div className={`${base}__triangle`}></div>
            <div className={base}>{children}</div>
        </div>
    );
};
