import React from 'react';
import intl from 'react-intl-universal';

import Loading from './Loading';

const DefaultErrorComponent = ({ error }) => (
    <div className="resolver--error">{error.message}</div>
);

export default class extends React.Component {
    static defaultProps = {
        loadingComponent: Loading,
        showLoading: false,
        errorComponent: DefaultErrorComponent,
        showError: false,
        onComplete: () => {},
        onError: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isError: null,
            error: null,
            data: null,
        };
    }

    componentDidMount() {
        const handleSuccess = (data) => {
            this.setState({ isLoading: false, isError: false, data }, () => {
                this.props.onComplete(data);
            });
        };
        const handleError = (error) => {
            this.setState({ isLoading: false, isError: true, error }, () => {
                this.props.onError(error);
            });
        };

        if (this.props.when) {
            this.props.when.then(handleSuccess).catch(handleError);
        } else if (this.props.whenFn) {
            this.props.whenFn().then(handleSuccess).catch(handleError);
        }
    }

    render() {
        if (this.state.isLoading) {
            if (this.props.showLoading) {
                return React.createElement(
                    this.props.loadingComponent,
                    this.state
                );
            } else {
                return <div />;
            }
        }

        if (this.state.isError) {
            if (this.props.showError) {
                return React.createElement(this.props.errorComponent, {
                    error: this.state.error,
                });
            }

            return <div />;
        }

        if (!this.state.isError) {
            if (this.props.children) {
                return React.cloneElement(this.props.children, {
                    data: this.state.data,
                });
            }
        }

        return <div>{intl.get('resolver')}</div>;
    }
}
