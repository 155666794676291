import React from 'react';
import { inject, observer } from 'mobx-react';

import intl from 'react-intl-universal';

const base = 'rating-widget';

@inject('orderStatusStore')
@inject('orderRatingStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        // if user clicks on orderStatusBanner or an unrated order in orderHistory,
        // we skip the details screen. this is the fastest way to implement this.
        if (!this.props.orderStatusStore.deliveryInfoFetcher.data.rated) {
            this.handleStarsClicked();
        }
    }

    handleStarsClicked() {
        const { orderStatusStore, orderRatingStore } = this.props;

        orderStatusStore.setShowingDetail(false)();
        orderRatingStore.setOrder(orderStatusStore.deliveryInfoFetcher.data);
    }

    render() {
        const { orderStatusStore } = this.props;
        const { deliveryInfoServices } = orderStatusStore;

        return (
            <center className={base}>
                <p>{intl.get('orderStatus.ratingWidget.title')}</p>
                <div
                    onClick={::this.handleStarsClicked}
                    className={`${base}__star-container cursor-pointer`}
                >
                    <svg className={`${base}__star iconmoon-icon`}>
                        <use xlinkHref="#icon-star" />
                    </svg>
                    <svg className={`${base}__star iconmoon-icon`}>
                        <use xlinkHref="#icon-star" />
                    </svg>
                    <svg className={`${base}__star iconmoon-icon`}>
                        <use xlinkHref="#icon-star" />
                    </svg>
                    <svg className={`${base}__star iconmoon-icon`}>
                        <use xlinkHref="#icon-star" />
                    </svg>
                    <svg className={`${base}__star iconmoon-icon`}>
                        <use xlinkHref="#icon-star" />
                    </svg>
                </div>
                <p>
                    {deliveryInfoServices.orderDateAsXDate.toString(
                        'ddd, ddS, MMM, '
                    ) + deliveryInfoServices.menuType}
                </p>
            </center>
        );
    }
}
