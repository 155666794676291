export default (calendarStore, menuListStore, cartStore, menuHeaderStore) => {
    calendarStore.onDateClicked$.onValue((xdate) => {
        const scrollToElement = require('scroll-to-element');
        const elementId = `date-${xdate}`;
        const element = document.getElementById(elementId);
        const marginTop = menuHeaderStore.marginTop;

        window.requestAnimationFrame(() => {
            scrollToElement(element, { offset: -marginTop, duration: 500 });
        });
    });
};
