import { observable } from 'mobx';

export default class {
    @observable backLink = '';
    @observable nextLink = '';
    @observable showBackLink = true;
    @observable showNextLink = false;
    @observable walletPageBackLink = '';

    constructor({ backLink, nextLink, walletPageBackLink } = {}) {
        this.backLink = backLink || '';
        this.nextLink = nextLink || '';
        this.walletPageBackLink = walletPageBackLink || '';
    }

    setBackLink(backLink) {
        this.backLink = backLink;
    }
    setShowBackLink(show) {
        this.showBackLink = show;
    }
    setNextLink(nextLink) {
        this.nextLink = nextLink;
    }
    setShowNextLink(show) {
        this.showNextLink = show;
    }
}
