import React from 'react';
import { inject, observer } from 'mobx-react';
import template from 'lodash/template';

import intl from 'react-intl-universal';

const base = 'order-status-banner';

@inject('orderStatusStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const { orderStatusStore } = this.props;

        orderStatusStore.fetchDeliveryDetail();
    }

    render() {
        const { orderStatusStore } = this.props;

        return (
            <div className={`${base}__content ${base}__content--arrived`}>
                <p className={`${base}__text`}>
                    {intl.get('orderStatus.arrived.line1')}
                </p>
                <p className={`${base}__text`}>
                    {intl.get('orderStatus.arrived.line2')}
                </p>
                <p className={`${base}__text display--none-if-empty`}>
                    {orderStatusStore.deliveryInstruction}
                </p>
            </div>
        );
    }
}
