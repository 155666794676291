import React from 'react';

import NutritionalView from './NutritionalView';

const base = 'ingredient-view';

const IngredientItem = ({ item }) => {
    return (
        <div className={`${base}__item`}>
            <img
                alt="ingredient"
                src={item.image}
                className={`${base}__item-image`}
            />
            <p className={`${base}__item-name`}>{item.name}</p>
        </div>
    );
};

export default ({ dish }) => {
    const sortedIngredients = [...dish.ingredients].sort((item1, item2) => {
        return item1.name.localeCompare(item2.name);
    });
    const textList = sortedIngredients.map((item) => item.name).join(', ');

    return (
        <div className={base}>
            <div className={`${base}__text-list lh--150`}>
                {textList}
                <div className={`${base}__image-list`}>
                    {sortedIngredients.map((item, index) => (
                        <IngredientItem item={item} key={index} />
                    ))}
                </div>
            </div>
            <div className="show--if-small hide--if-large">
                <NutritionalView dish={dish} />
            </div>
        </div>
    );
};
