import React from 'react';
import { inject, observer } from 'mobx-react';
import { SummaryItem } from './SummaryItem';

@inject('store')
@observer
export default class extends React.Component {
    render() {
        const base = 'order-summary';
        const { orderSummary } = this.props.store;

        return (
            <div className={`${base} well--no-margin`}>
                {orderSummary &&
                    orderSummary.price_summary.rows.map((item, i) => (
                        <SummaryItem
                            key={i}
                            title={item.name}
                            value={item.value}
                            bold={item.bold}
                            color={item.color}
                        />
                    ))}
            </div>
        );
    }
}
