import React from 'react';
import OtherHeaderUI from './OtherHeaderUI';
import { SMALL_SCREEN_MEDIA } from '../../services/constants';

export default class extends React.Component {
    render() {
        return (
            <OtherHeaderUI
                customClass={'careers-header'}
                customArrowClass={'careers-header__arrow'}
                backLink={'/menu'}
                showBackLink={true}
                showPopLogo={true}
                logoURL="https://d3tjqo2jh935k9.cloudfront.net/images/static/pop-careers-logo.svg"
                logoWidth={
                    window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 142 : 188
                }
            />
        );
    }
}
