import React from 'react';
import { inject } from 'mobx-react';
import template from 'lodash/template';
import intl from 'react-intl-universal';

import Checkbox from '../../core/Checkbox';
import { currency, services } from '../../../services';
import { ConfirmPaymentSummaryItem } from './SummaryItem';

const { clevertap } = window;

@inject('store')
@inject('headerStore')
export default class extends React.Component {
    componentWillMount() {
        clevertap.event.push('Show Order Confirmation');
    }

    componentDidMount() {
        const { headerStore } = this.props;

        headerStore.setShowNextLink(true);
        headerStore.setShowBackLink(false);
    }

    render() {
        const base = 'wallet-payment-confirm';
        const {
            user,
            walletPackage,
            orderSummary,
            paymentMethod,
            finalOrderSummary,
            autoTopUpEnabled,
        } = this.props.store;
        const netTotal = orderSummary ? orderSummary['net_total'] : '--';

        return (
            <div>
                <div className="mbm" />
                <div className={`${base} lh--150 border--rounded no-overflow`}>
                    <div className={`${base}__header`}>
                        <h1>
                            <strong>
                                {intl.get('packageCheckout.confirm.title')}
                            </strong>
                        </h1>
                        <p className="mtm">
                            {template(
                                intl.get('packageCheckout.confirm.subtitle')
                            )({ userAlias: user.alias })}
                        </p>
                    </div>
                    <div className={`${base}__details text--left pl`}>
                        <p
                            className={`${base}__order-number-label fs--medium text--black`}
                        >
                            <strong>
                                {intl.get(
                                    'packageCheckout.confirm.orderHeader'
                                )}
                            </strong>
                        </p>
                        <p className={`${base}__order-number`}>
                            {finalOrderSummary['wallet_order_id']}
                        </p>
                        <table className={`${base}__item-description mtm`}>
                            <thead>
                                <tr>
                                    <td>
                                        {intl.get(
                                            'packageCheckout.confirm.description'
                                        )}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text--dark">
                                        <p>{`${walletPackage.title} topup`}</p>
                                    </td>
                                    <td className="text--right text--dark hide--if-small">
                                        {currency.localCurrency(
                                            netTotal,
                                            false
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={`${base}__smart-reload`}>
                            <Checkbox checked={autoTopUpEnabled} readOnly />
                            <span className="mlm text--black">
                                {intl.get(
                                    'packageCheckout.confirm.smartReload'
                                )}
                            </span>
                        </div>
                        <hr className="mbm" />
                        <div className="clearfix">
                            <table className={`${base}__order-summary`}>
                                <tbody>
                                    {orderSummary.price_summary.rows.map(
                                        (item, i) => (
                                            <ConfirmPaymentSummaryItem
                                                key={i}
                                                title={item.name}
                                                value={item.value}
                                                bold={item.bold}
                                                color={item.color}
                                                base={base}
                                            />
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className={`${base}__payment-method clearfix mtm`}>
                            <div className="float-right">{`Payment Method: ${paymentMethod.card_type}`}</div>
                        </div>
                        <hr className="mtm" />
                        <div className="text--center text--black pm">
                            <a
                                className="dmk-link--primary"
                                href="https://bnc.lt/dahmakan"
                            >
                                {intl.get(
                                    'packageCheckout.confirm.appCta.downloadApp'
                                )}
                            </a>
                            {intl.get(
                                'packageCheckout.confirm.appCta.sentence'
                            )}
                            <a className="dmk-link--primary" href="/menu">
                                {intl.get(
                                    'packageCheckout.confirm.appCta.order'
                                )}
                            </a>
                            {intl.get(
                                'packageCheckout.confirm.appCta.sentence2'
                            )}
                        </div>
                        <div>
                            <a href="/menu" id="wallet-order-now-btn">
                                <button
                                    className={`${base}__start-ordering button button--bigger-on-mobile button--success width--100`}
                                >
                                    {intl.get('packageCheckout.confirm.cta')}
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className={`${base}__apps pm`}>
                        <a
                            href={services.getParam('appStorePath')}
                            className="display--inline-block mrl"
                        >
                            <img
                                className={`${base}__app-button`}
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/app-store-black.png"
                                alt={`Download ${services.getParam(
                                    'brandName'
                                )} iOS app`}
                            />
                        </a>
                        <a
                            href={services.getParam('googlePlayPath')}
                            className="display--inline-block"
                        >
                            <img
                                className={`${base}__app-button`}
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/google-play-black.png"
                                alt={`Download ${services.getParam(
                                    'brandName'
                                )} Android app`}
                            />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
