import React, { Component } from 'react';
import Modal from '../../core/Modal';
import { inject, observer } from 'mobx-react';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import Rewards from '../../core/Rewards';

const base = 'rewards-popup';

@inject('rewardsPopupStore', 'rewardsStore')
@observer
export default class RewardsPopup extends Component {
    handleClosePopup = () => {
        const { rewardsPopupStore, rewardsStore } = this.props;

        rewardsPopupStore.handleTogglePopup(false);
        if (rewardsStore.rewardToApply()) rewardsStore.removeReward();
    };

    render() {
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return (
            <Modal
                showModal={true}
                contentClasses={`${base} ${
                    !isMobile && 'modal__content--golden-ratio'
                }`}
                onBackdropClicked={this.handleClosePopup}
                onCloseClicked={this.handleClosePopup}
                showCloseIcon={true}
            >
                <Rewards isPopup={true} onCheckout={true} />
            </Modal>
        );
    }
}
