import React, { Component } from 'react';
import { Online, Offline } from 'react-detect-offline';
import parameters from '../../configs/parameters';

const pollingUrl = `${parameters['services']}/eb/health`;

export class OnlineDetect extends Component {
    render() {
        const { children } = this.props;
        return <Online 
                    polling={{ url: pollingUrl }}>
                    {children}
                </Online>;
    }
}

export class OfflineDetect extends Component {
    render() {
        const { children } = this.props;
        return <Offline polling={{ url: pollingUrl }} onChange={ () => window.location.reload()}>{children}</Offline>;
    }
}
