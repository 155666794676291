import React from 'react';
import { inject, observer } from 'mobx-react';
import MenuOneDay from './MenuOneDay';
import { reaction, when } from 'mobx';
import {
    seenAutoAssignedCodeResponseBanner,
    seenNonUserAutoAssignedCodeResponseBanner,
    setSeenAutoAssignedCodeResponseBanner,
    setSeenNonUserAutoAssignedCodeResponseBanner,
    autoAssignedCodeAmount,
    autoAssignedCodeType,
} from '../../../services/autoAssignDiscount';
import { currency, services } from '../../../services';
import Cookies from 'js-cookie';
import MenuSkeleton from './MenuSkeleton';
import CategoryPicker from './CategoryPicker';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import qs from 'query-string';

const isSmallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
const { clevertap } = window;

@inject(
    'menuListStore',
    'cartStore',
    'userStore',
    'rewardsStore',
    'deliveryAddressStore'
)
@observer
export default class extends React.Component {
    state = {
        pageYOffset: 0,
    };

    componentDidMount() {
        const { rewardsStore, userStore, menuListStore, deliveryAddressStore } =
            this.props;
        const params = qs.parse(location.search, { decoded: true });

        menuListStore.getMenuDates({
            ...(params.destination === 'menu' &&
                params.lat &&
                params.lng && { lat: params.lat, lng: params.lng }),
        });

        menuListStore.sendItemListToDatalayer();

        when(
            () => userStore.isLoggedIn && deliveryAddressStore.addresses.length,
            () => {
                if (params.destination === 'menu' && params.address_id) {
                    const address = deliveryAddressStore.addresses.find(
                        (address) =>
                            address.pickup_location &&
                            address.id === params.address_id
                    );
                    if (address) {
                        deliveryAddressStore.setSelected({
                            address,
                            refreshMenu: true,
                        });
                    }
                }

                if (userStore.user.data.username) {
                    const autoAssignedCode = Cookies.get('promotion');
                    if (autoAssignedCode) {
                        if (userStore.user.data.has_ordered_before) {
                            if (!seenAutoAssignedCodeResponseBanner) {
                                services.bannerEvents.showWarning(
                                    'Sorry, this discount is only available for new users.'
                                );
                                setSeenAutoAssignedCodeResponseBanner();
                            }
                        } else {
                            rewardsStore.code = autoAssignedCode;
                        }
                    }
                } else {
                    if (
                        autoAssignedCodeAmount &&
                        !seenAutoAssignedCodeResponseBanner &&
                        !seenNonUserAutoAssignedCodeResponseBanner
                    ) {
                        if (autoAssignedCodeType === 'percentage') {
                            services.bannerEvents.showSuccess(
                                `Congratulations, you got ${
                                    autoAssignedCodeAmount * 100
                                }% off!`
                            );
                            setSeenNonUserAutoAssignedCodeResponseBanner();
                        } else {
                            services.bannerEvents.showSuccess(
                                `Congratulations, you got ${currency.localCurrency(
                                    autoAssignedCodeAmount
                                )} off!`
                            );
                            setSeenNonUserAutoAssignedCodeResponseBanner();
                        }
                    }
                }
            }
        );

        when(
            () => rewardsStore.code,
            () => {
                if (autoAssignedCodeAmount) {
                    rewardsStore.redeemCode({
                        discountAmount: autoAssignedCodeAmount,
                        discountType: autoAssignedCodeType,
                    });
                }
            }
        );

        reaction(
            () => menuListStore.contentCards && menuListStore.contentCards.list,
            () => {
                const {
                    activeDateMenu,
                    activeTimeslotDisplay,
                    contentCards,
                    flattenedMenu,
                } = menuListStore;
                const totalItems = (itemType) =>
                    activeDateMenu.items.filter(
                        (item) =>
                            item.quantity_left > 0 &&
                            !item.available_at &&
                            item.item_type === itemType
                    ).length;

                const cards = contentCards.list ? contentCards.list
                    .filter((slide) =>
                        isSmallScreen ? slide.image : slide.web_image
                    )
                    .map((slide) => slide.title)
                    .toString() : '';

                clevertap.event.push('See Menu', {
                    'Total Line Available': flattenedMenu.length,
                    'Total Item Available': totalItems('Item'),
                    'Total Combo Available': totalItems('Combo'),
                    'Total Addons Available': totalItems('Addons'),
                    'Menu Date': activeDateMenu.date,
                    Timeslot: activeTimeslotDisplay,
                    'Content Card': cards,
                });
            }
        );
    }

    render() {
        const { menuListStore, landing } = this.props;
        const isLoading = menuListStore.isLoading;
        const isSmallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return (
            <div className="menu__list-wrapper">
                <div className="menu__list-container container">
                    {!isSmallScreen && (
                        <CategoryPicker
                            categories={menuListStore.allCategories}
                        />
                    )}
                    {isLoading ? (
                        <MenuSkeleton />
                    ) : (
                        <MenuOneDay
                            menuObj={menuListStore.activeDateMenu}
                            landing={landing}
                            isSmallScreen={isSmallScreen}
                        />
                    )}
                </div>
            </div>
        );
    }
}
