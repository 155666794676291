/* eslint-disable max-statements */
import Bacon from 'baconjs';
import { observable, reaction, action, when } from 'mobx';
import { services } from '../services/index';
import { deliveryInstructionValidator } from './validators/addressValidator';
import ProfileAddressStore from './profileAddressStore';
import { MAYBE, RESOURCE } from '../services/types';
import menuListStore from './menuListStore';
import cartStore from './cartStore';
import userStore from './userStore';
import { UserAddressSettings } from '../services/api/userAddressServices';
import addressAtom from './atoms/addressAtom';

const { clevertap } = window;

/*
This store is not initialised when App loads. Instead, it is initialised on use in Menu, Dish Page, and Shopping Cart
Each deliveryAddressStore is created to be date-specific, and accepts date, mealTime, and an addressAtom as an arguments to initialize it.
*/
class DeliveryAddressStore extends ProfileAddressStore {
    SHOW_VIEW = 'ShowView';
    LIST_VIEW = 'ListView';

    @observable message = '';
    @observable addressLoading = true;
    @observable isAddressLoading = true;
    @observable view = 'ShowView';
    @observable editing = false;
    @observable editableAddress = null;
    @observable date = '';

    @observable.ref addresses = [];
    @observable.ref selectedAddress = {};

    onAddressSelected$ = new Bacon.Bus();

    constructor() {
        super();

        this.setUpNotification();
        this.addressAtom = addressAtom;
        const addressList = addressAtom.getAddresses();

        this.addressLoading = true;
        this.selectedAddress = null;

        if (addressList.length === 0) {
            when(
                () => userStore.user.data,
                () => {
                    const userIsLoggedIn = userStore.isLoggedIn;
                    // for logged in users, we get address from address atom
                    if (userIsLoggedIn) {
                        reaction(
                            () => addressAtom.getAddresses(),
                            (addresses) => {
                                if (addresses.length) {
                                    this.addresses = addresses; // Whenever new addresses are loaded from server, we use it.
                                    this.addressLoading = false;
                                } else {
                                    this.addressLoading = false;
                                }
                            }
                        );
                        // for non-logged in users we get address from local storage if available
                    } else {
                        const savedAddress =
                            UserAddressSettings.getCurrentAddress();
                        if (savedAddress) {
                            this.setSelected({ address: savedAddress });
                        }
                        this.addressLoading = false;
                    }
                }
            );
        } else {
            this.addresses = addressList;
            this.addressLoading = false;
        }

        // runs when we have set a default address for the user. sets address saved in storage as the selected address. if not available, selects first valid address in a user's saved addresses
        when(
            () => this.addressLoading === false,
            () => {
                const addressInStorage =
                    UserAddressSettings.getCurrentAddress();
                // checks to see if the address in storage has already been added. if it has not, we will add it to the user's addresses
                if (addressInStorage) {
                    const isAlreadyAdded = this.addresses.find(
                        (address) => address.id === addressInStorage.id
                    );
                    if (isAlreadyAdded) {
                        this.setSelected({ address: isAlreadyAdded });
                        this.isAddressLoading = false;
                    } else if (!addressInStorage.pickup_location) {
                        this.saveAddress({
                            refreshMenu: false,
                            nonLoggedInAddress: addressInStorage,
                        });
                        this.isAddressLoading = false;
                    }
                } else {
                    // selects first valid address from a user's saved addresses if no address is stored in storage
                    const savedAddressInDeliveryArea = this.addresses.find(
                        (address) =>
                            !address.pickup_location &&
                            address.within_delivery_area
                    );
                    if (savedAddressInDeliveryArea) {
                        this.setSelected({
                            address: savedAddressInDeliveryArea,
                        });
                    }
                    this.isAddressLoading = false;
                }
            }
        );
    }

    @observable.ref addressesDeleter;
    @observable.ref addressBeingDeleted = MAYBE.None;

    @action editAddress = (id) => {
        this.editing = true;
        this.editableAddress = this.addresses.find(
            (address) => address.id === id
        );
        this.showPopup(this.POPUP_TYPING);
    };

    @action hidePopup() {
        this.showingPopup = '';
        this.showMap = false;
        this.addressAtom.reload();
        this.resetNewAddress();
    }

    @action saveAddress = ({ refreshMenu, nonLoggedInAddress, date }) => {
        return new Promise((resolve) => {
            const userIsLoggedIn = userStore.isLoggedIn;
            if (
                this.newAddress &&
                !this.newAddress.address_incomplete &&
                !nonLoggedInAddress
            ) {
                this.deliveryInstructionValRes =
                    deliveryInstructionValidator.validate({
                        deliveryInstruction:
                            this.newAddress.deliveryInstruction,
                    });

                if (!this.deliveryInstructionValRes.pass()) {
                    return;
                }
            }

            this.isSavingAddress = true;
            let newlyCreatedAddress;

            if (userIsLoggedIn) {
                // checks if a 'user added an address before they were logged in. If yes, then use that address, but if not, get address from profileaAddressStore
                const requestBody = nonLoggedInAddress
                    ? nonLoggedInAddress
                    : this.getRequestBody(userStore.user.data.id);
                services.api
                    .CreateAddress(requestBody)
                    .then((newAddress) => {
                        newlyCreatedAddress = newAddress;
                    })
                    .then(services.api.GetAddresses.runFresh)
                    .then((freshAddresses) => {
                        //IMP: giving the value of newlyCreatedAddress as fallback is PATCH/Fix which is only meant to solve the problem stated in FOOD-7435 for safari browser.
                        const addressToSet =
                            freshAddresses.find(
                                (address) =>
                                    address.id === newlyCreatedAddress.id
                            ) || newlyCreatedAddress;
                        this.setSelected({ address: addressToSet, date });
                        this.isSavingAddress = false;
                        this.showingPopup = '';
                        this.goToView(this.SHOW_VIEW)();
                        this.addressesFetcher =
                            RESOURCE.Success(freshAddresses);
                        // only send tracking event for new addresses
                        if (!this.editing) {
                            this.sendTrackingEvents();
                        }
                    })
                    .then(() => this.addressAtom.reload())
                    .then(() => this.resetNewAddress())
                    .catch((responseData) => {
                        this.message = responseData.message;
                        this.isSavingAddress = false;
                        this.showingPopup = '';
                        this.goToView(this.SHOW_VIEW)();
                        this.resetNewAddress()
                    })
                    .then(() => {
                        // is for fetching an updated menu on address change i.e. getting a menu that is specific to a particular hub
                        if (refreshMenu) {
                            menuListStore.getMenuDates(false, true);
                        }
                        this.editing = false;
                        resolve();
                    });
            } else {
                const address = nonLoggedInAddress || this.getRequestBody();
                this.setSelected({ address, date });
                if (refreshMenu) {
                    menuListStore.getMenuDates(false, true);
                }
                this.isSavingAddress = false;
                resolve();
            }
        });
    };

    @action setSelected({ address, refreshMenu, date } = {}) {
        this.selectedAddress = address;
        UserAddressSettings.saveCurrentAddress(address);
        cartStore.noAddress = false;

        if (date) {
            cartStore.updateDateAddressInCart(date, address);
        }
        if (refreshMenu) {
            menuListStore.getMenuDates();
        }
    }

    sendTrackingEvents = () => {
        clevertap.event.push('Selected Address Suggestion', {
            Selection: 'Autocomplete',
        });
    };

    goToView(view) {
        return () => {
            this.view = view;
        };
    }

    getSelected = () => {
        return this.selectedAddress;
    };
}

const store = new DeliveryAddressStore();
export default store;
