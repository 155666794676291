import React from 'react';

import LoginPopup from '../../core/LoginPopup';
import SectionHero from './SectionHero';
import SectionHappyCustomer from './SectionHappyCustomers';
import SectionBenefit from './SectionBenefit';

export default class extends React.Component {
    render() {
        return (
            <div>
                <LoginPopup dismissable={true} showCloseIcon={true} />
                <SectionHero />
                <SectionHappyCustomer />
                <SectionBenefit />
            </div>
        );
    }
}
