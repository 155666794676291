import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { RESOURCE } from '../../services/types';

import intl from 'react-intl-universal';

import LoginDropdown from './LoginDropdown';

const base = 'login-cta';

@inject('userStore')
@inject('dropdownStore')
@inject('menuHeaderStore')
@inject('loginPopupStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);

        this.handleSignInClicked = this.handleSignInClicked.bind(this);
        this.handleSignOutClicked = this.handleSignOutClicked.bind(this);
    }

    handleNameClicked() {
        this.setState({ isShowingDropdown: !this.state.isShowingDropdown });
    }

    handleSignInClicked() {
        this.props.loginPopupStore.setShowing(true);
        this.props.loginPopupStore.showLogin();
        this.props.loginPopupStore.clearPassword();
    }

    handleSignOutClicked() {
        this.props.menuHeaderStore.logOut();
    }

    renderUser() {
        const { dropdownStore, userStore } = this.props;
        const { user } = userStore;

        if (user === RESOURCE.Loading) {
            return <div>...</div>;
        }

        if (RESOURCE.Success.is(user)) {
            return (
                <span>
                    <span
                        className="cursor-pointer dmk-link--primary-hover cursor-pointer z-index-higher-than-backdrop"
                        onClick={() =>
                            dropdownStore.handleShowDropdown(
                                dropdownStore.DROPDOWN_MENU
                            )
                        }
                        onMouseEnter={() =>
                            dropdownStore.handleShowDropdown(
                                dropdownStore.DROPDOWN_MENU
                            )
                        }
                    >
                        <span>{`${intl.get('profile.hi')} ${
                            user.data.firstname || ''
                        }`}</span>
                        <svg
                            className={`${base}__login-dropdown iconmoon-icon cursor-pointer dmk-link--primary-hover`}
                        >
                            <use xlinkHref="#icon-chevron-thin-down" />
                        </svg>
                        <svg
                            className={`${base}__notification-dot`}
                            focusable="false"
                        >
                            <circle cx="3" cy="3" r="3"></circle>
                        </svg>
                    </span>

                    <LoginDropdown
                        isShowing={
                            dropdownStore.showingDropdown ===
                            dropdownStore.DROPDOWN_MENU
                        }
                        onClickOutside={() =>
                            dropdownStore.handleHideDropdownImmediately()
                        }
                    >
                        <ul
                            className="ps ptm pbm"
                            onMouseLeave={() =>
                                dropdownStore.handleHideDropdown()
                            }
                        >
                            <li className="fs--small-medium text--center dmk-link--primary-hover cursor-pointer pbm">
                                <Link
                                    to="/me"
                                    className="dmk-link--primary-hover"
                                >
                                    {intl.get('loginCTA.profile')}
                                </Link>
                            </li>
                            <li className="fs--small-medium text--center dmk-link--primary-hover cursor-pointer pbm">
                                <Link
                                    to="/history"
                                    className="dmk-link--primary-hover"
                                >
                                    {intl.get('loginCTA.myOrders')}
                                </Link>
                            </li>
                            <li className="fs--small-medium text--center dmk-link--primary-hover cursor-pointer pbm">
                                <Link
                                    to="/rewards"
                                    className="dmk-link--primary-hover"
                                >
                                    {intl.get('rewards.rewards')}
                                </Link>
                                <svg
                                    className={`${base}__notification-dot`}
                                    focusable="false"
                                >
                                    <circle cx="3" cy="3" r="3"></circle>
                                </svg>
                            </li>
                            <li
                                className="fs--small-medium text--center dmk-link--primary-hover cursor-pointer"
                                onClick={this.handleSignOutClicked}
                            >
                                {intl.get('loginCTA.logOut')}
                            </li>
                        </ul>
                    </LoginDropdown>
                </span>
            );
        }

        return (
            <span
                className="cursor-pointer"
                onClick={this.handleSignInClicked}
                id="select-login"
            >
                {intl.get('loginCTA.signIn')}
            </span>
        );
    }

    render() {
        return <div className="display--inline-block">{this.renderUser()}</div>;
    }
}
