import React from 'react';
import { Provider } from 'mobx-react';

import BaseLayout from './core/baseLayout/Master_BaseLayout';
import DishHeader from './core/headers/DishHeader';
import Entry from './page/dishPage/Entry';

import { Link } from '../services/types';
import dishStore from '../store/dishStore';
import DishHeaderStore from '../store/dishHeaderStore';
import menuHeaderStore from '../store/menuHeaderStore';
import LoginPopup from './core/LoginPopup';

const { clevertap } = window;

const dishHeaderStore = new DishHeaderStore();

dishHeaderStore.setBackLink(new Link('/menu', 'BACK TO MENU'));

const stores = {
    dishHeaderStore,
    menuHeaderStore,
};

const Header = (dish) => (
    <Provider {...stores}>
        <DishHeader dish={dish} />
    </Provider>
);

export default class extends React.Component {
    constructor(props) {
        super(props);

        dishStore.setDish(this.props.dish);
    }

    componentDidMount() {
        const { dish } = this.props;
        const eventName = dish.addon_category
            ? 'Open Addon Detail View'
            : 'Open Dish Detail View';

        if (dish.name) {
            clevertap.event.push(eventName, {
                'Product name': dish.name,
            });
        }

        window.dataLayer.push({
            event: 'viewPage',
            page_title: document.title,
            page_type: 'Dish Detail View',
        });
    }

    render() {
        return (
            <BaseLayout.Page HeaderComponent={() => Header(this.props.dish)}>
                <main className="container">
                    <LoginPopup dismissable={true} showCloseIcon={true} />
                    <Provider {...stores}>
                        <Entry dish={this.props.dish} />
                    </Provider>
                </main>
            </BaseLayout.Page>
        );
    }
}
