import React from 'react';
import cx from 'classnames';
import range from 'lodash/range';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

const base = 'date-picker';
const noop = () => {};

@inject('datePickerStore')
@observer
export default class extends React.Component {
    handleDateSelected(date) {
        const { datePickerStore } = this.props;

        datePickerStore.handleDayClicked(date);
        const event = {
            currentTarget: {
                name: this.props.name,
                value: datePickerStore.xDate.toString('dd-MM-yyyy'),
            },
        };
        this.props.onChange(event);
    }

    renderDays() {
        let cursor = 0;
        const { datePickerStore } = this.props;
        const viewingDays = datePickerStore.getDaysForCurrentViewingMonth();
        const dayRange = [0, 1, 2, 3, 4, 5, 6];
        const { today } = datePickerStore;

        return range(0, viewingDays.length / 7).map(() => {
            return (
                <div key={cursor} className={`${base}__day-row display--flex`}>
                    {dayRange.map(() => {
                        cursor++;
                        const date = viewingDays[cursor - 1];
                        const classNames = cx('flex-none cursor-pointer', {
                            [`${base}__date-cell`]: true,
                            [`${base}__date-cell--${date.type}`]: true,
                            [`${base}__date-cell--today`]:
                                datePickerStore.isDateEqual(date, today),
                            [`${base}__date-cell--selected`]:
                                datePickerStore.isDateSelected(date),
                        });

                        return (
                            <div
                                key={cursor}
                                className={cx(classNames)}
                                onClick={this.handleDateSelected.bind(
                                    this,
                                    date
                                )}
                            >
                                <div className={`${base}__date-cell-inner`}>
                                    {date.day}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        });
    }

    renderPicker() {
        const { datePickerStore } = this.props;
        const { viewingMonth, isOpening } = datePickerStore;
        const containerClass = cx(
            `${base}__picker`,
            this.props.containerClassName
        );

        if (isOpening) {
            return (
                <div className={containerClass}>
                    <div className={`${base}__year-row display--flex`}>
                        <div className="flex-none">
                            <svg
                                className="iconmoon-icon cursor-pointer"
                                onClick={::datePickerStore.showPrevYear}
                            >
                                <use xlinkHref="#icon-chevron-thin-left" />
                            </svg>
                        </div>
                        <div className="flex-1-only">
                            {viewingMonth.getFullYear()}
                        </div>
                        <div className="flex-none">
                            <svg
                                className="iconmoon-icon cursor-pointer"
                                onClick={::datePickerStore.showNextYear}
                            >
                                <use xlinkHref="#icon-chevron-thin-right" />
                            </svg>
                        </div>
                    </div>
                    <div className={`${base}__month-row display--flex`}>
                        <div className="flex-none">
                            <svg
                                className="iconmoon-icon cursor-pointer"
                                onClick={::datePickerStore.showPrevMonth}
                            >
                                <use xlinkHref="#icon-chevron-thin-left" />
                            </svg>
                        </div>
                        <div className="flex-1-only">
                            {`${
                                viewingMonth.getMonth() + 1
                            } - ${viewingMonth.toString('MMMM')}`}
                        </div>
                        <div className="flex-none">
                            <svg
                                className="iconmoon-icon cursor-pointer"
                                onClick={::datePickerStore.showNextMonth}
                            >
                                <use xlinkHref="#icon-chevron-thin-right" />
                            </svg>
                        </div>
                    </div>
                    <div className={`${base}__week-row`}>
                        <div className="display--flex">
                            <div className="flex-1-only">
                                {intl.get('datePicker.monday')}
                            </div>
                            <div className="flex-1-only">
                                {intl.get('datePicker.tuesday')}
                            </div>
                            <div className="flex-1-only">
                                {intl.get('datePicker.wednesday')}
                            </div>
                            <div className="flex-1-only">
                                {intl.get('datePicker.thursday')}
                            </div>
                            <div className="flex-1-only">
                                {intl.get('datePicker.friday')}
                            </div>
                            <div className="flex-1-only">
                                {intl.get('datePicker.saturday')}
                            </div>
                            <div className="flex-1-only">
                                {intl.get('datePicker.sunday')}
                            </div>
                        </div>
                    </div>
                    {this.renderDays()}
                </div>
            );
        } else {
            return null;
        }
    }

    renderInput() {
        const { datePickerStore, placeholder } = this.props;
        const { inputValue } = datePickerStore;
        const inputClass = cx(`${base}__input`, this.props.inputClassName);

        if (!inputValue) {
            return (
                <div className="display--flex">
                    <input
                        type="text"
                        maxLength="10"
                        placeholder={placeholder}
                        className={inputClass}
                        name={this.props.name}
                        value={this.props.value}
                        onChange={noop}
                        onFocus={::datePickerStore.showPicker}
                    />
                </div>
            );
        } else {
            return (
                <div className="display--flex">
                    <input
                        type="text"
                        maxLength="10"
                        className={inputClass}
                        name={this.props.name}
                        value={this.props.value}
                        onChange={noop}
                        onFocus={::datePickerStore.showPicker}
                    />
                </div>
            );
        }
    }

    renderBackdrop() {
        const { datePickerStore } = this.props;

        if (datePickerStore.isOpening) {
            return (
                <div
                    className={`${base}__backdrop`}
                    onClick={::datePickerStore.hidePicker}
                />
            );
        }

        return null;
    }

    render() {
        const baseClasses = cx(base, this.props.className, 'fs--small-medium');

        return (
            <div className={baseClasses}>
                {this.renderBackdrop()}
                <div className={`${base}__container`}>
                    {this.renderInput()}
                    {this.renderPicker()}
                </div>
            </div>
        );
    }
}
