import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import BasePage from './basePage';
import TerminalRoot from '../components/TerminalRoot';
import { getGTMScript } from '../components/core/ScriptTag';
import { services } from '../services/index';
import terminalAuthStore from '../store/terminalAuthStore';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';
import DealRoot from '../components/DealRoot';
const pageTitle = services.getTitle('terminal-dealSelection');

@observer
export default class extends Component {
    componentDidMount() {
        document.body.classList.add('terminal');
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                    {localizationTags(`terminal-dealSelection`).map(
                        (tag) => tag
                    )}
                    {getCanonicalTag(`terminal-dealSelection`)}
                </Helmet>
                <BasePage />

                <DealRoot />
            </Fragment>
        );
    }
}
