import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import TerminalLearnMoreRoot from '../components/TerminalLearnMoreRoot';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('terminalLearnMore');

export default class extends Component {
    componentDidMount() {
        document.body.classList.add('terminal-learnmore');
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                    {localizationTags(`terminal-learnmore`).map((tag) => tag)}
                    {getCanonicalTag(`terminal-learnmore`)}
                </Helmet>
                <TerminalLearnMoreRoot />
            </Fragment>
        );
    }
}
