import React, { Component } from 'react';

export default class AddressList extends Component {
    setIcon = (address, base) => {
        if (address.location_type === 'House') {
            return (
                <svg className={`${base}__pin-icon house iconmoon-icon`}>
                    <use xlinkHref="#icon-location-house" />
                </svg>
            );
        } else if (address.location_type === 'Office') {
            return (
                <svg className={`${base}__pin-icon house iconmoon-icon`}>
                    <use xlinkHref="#icon-location-office" />
                </svg>
            );
        } else {
            return (
                <svg className={`${base}__pin-icon iconmoon-icon`}>
                    <use xlinkHref="#icon-location-pin" />
                </svg>
            );
        }
    };

    render() {
        const {
            title,
            addresses,
            onClick,
            marginTop,
            savedAddress,
            containerId,
            base,
        } = this.props;
        const districtCity = (address) =>
            address.district && address.city
                ? `${address.district}, ${address.city}`
                : '';
        const formattedAddress = (address) =>
            address.formatted_address || address.name;
        const displayAddress = (address) =>
            savedAddress && districtCity(address)
                ? districtCity(address)
                : formattedAddress(address);

        return addresses && addresses.length > 0 ? (
            <div
                className={`${base}__saved-addresses-container`}
                style={{ marginTop: marginTop + 'px' }}
            >
                <div className={`${base}__saved-addresses-header-container`}>
                    <p className={`${base}__saved-addresses-header`}>{title}</p>
                </div>
                <div
                    className={`${base}__saved-address-container`}
                    id={containerId}
                >
                    {addresses.map((address, i) => (
                        <div
                            className="display--flex"
                            key={i}
                            style={{
                                marginBottom:
                                    i === addresses.length - 1 ? '' : '20px',
                            }}
                        >
                            <div
                                className={`${base}__saved-address-icon-container`}
                            >
                                {this.setIcon(address, base)}
                            </div>
                            <div
                                className={`${base}__saved-address-location-container cursor-pointer`}
                                onClick={() => onClick(address)}
                            >
                                <p
                                    className={`${base}__saved-address-location-header`}
                                >
                                    {address.building_name || address.name}
                                </p>
                                <p
                                    className={`${base}__saved-address-location-subheader`}
                                >
                                    {displayAddress(address)}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ) : (
            <div />
        );
    }
}
