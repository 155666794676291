import React from 'react';

export default function ArrowUp({ width, height, color, manualStyles }) {
    return (
        <svg
            width={`${width}`}
            height={`${height}`}
            viewBox="0 0 9 11"
            fill={`${color ? color : '#131415'}`}
            xmlns="http://www.w3.org/2000/svg"
            style={manualStyles}
        >
            <path d="M0.602955 6.32307L0.145328 5.77804C-0.0484425 5.54726 -0.0484425 5.17409 0.145328 4.94577L4.1506 0.173083C4.34437 -0.0576945 4.6577 -0.0576945 4.8494 0.173083L8.85467 4.94331C9.04844 5.17409 9.04844 5.54726 8.85467 5.77558L8.39705 6.32061C8.20121 6.55384 7.8817 6.54893 7.68999 6.31079L5.32558 3.35487V10.4108C5.32558 10.7373 5.10502 11 4.83085 11H4.17121C3.89705 11 3.67648 10.7373 3.67648 10.4108V3.35487L1.31001 6.31325C1.1183 6.55385 0.798786 6.55876 0.602955 6.32307Z" />
        </svg>
    );
}
