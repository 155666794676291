import React from 'react';
import template from 'lodash/template';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

const base = 'delivery-note-explain';

@inject('deliveryAddressStore')
@observer
export default class extends React.Component {
    render() {
        const store = this.props.deliveryAddressStore;

        return (
            <div className="ptxl pbl prl pll lh--150 shopping-cart-address__popup--explaining">
                <div className={`${base}__content`}>
                    <center>
                        <h3 className="text--bolder">
                            {intl.get('deliveryAddress.popupExplain.title')}
                        </h3>
                        <div className="separator separator--green mtm mbm" />
                    </center>

                    <div>
                        <div className="display--flex position--relative">
                            <div className={`${base}__most-convenient`} />
                            <div
                                style={{
                                    backgroundImage: `url('https://d3tjqo2jh935k9.cloudfront.net/images/static/pickup.png')})`,
                                }}
                                className={`flex-none ${base}__icon mrm`}
                            />
                            <div>
                                <p className="text--bold">
                                    {store.PICKUP_GROUND_FLOOR}
                                </p>
                                <p className="fs--small text--dark pts pbm">
                                    {intl.get(
                                        'deliveryAddress.popupExplain.pickUpFromReceptionDetail'
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="display--flex position--relative">
                            <div className={`${base}__most-convenient`} />
                            <div
                                style={{
                                    backgroundImage: `url('https://d3tjqo2jh935k9.cloudfront.net/images/static/guardhouse.png')})`,
                                }}
                                className={`flex-none ${base}__icon mrm`}
                            />
                            <div>
                                <p className="text--bold">
                                    {store.PICKUP_GUARD_HOUSE}
                                </p>
                                <p className="fs--small text--dark pts pbm">
                                    {intl.get(
                                        'deliveryAddress.popupExplain.pickUpFromGuardHouseDetail'
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="display--flex position--relative">
                            <div
                                style={{
                                    backgroundImage: `url('https://d3tjqo2jh935k9.cloudfront.net/images/static/meetfloor.png')})`,
                                }}
                                className={`flex-none ${base}__icon mrm`}
                            />
                            <div>
                                <p className="text--bold">
                                    {template(store.MEET_DRIVER_AT_FLOOR)({
                                        floor: store.newAddress.block,
                                    })}
                                </p>
                                <p className="fs--small text--dark pts pbm">
                                    {intl.get(
                                        'deliveryAddress.popupExplain.meetTheDriverAtMyFloorDetail'
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="display--flex position--relative">
                            <div
                                style={{
                                    backgroundImage: `url('https://d3tjqo2jh935k9.cloudfront.net/images/static/meetlobby.png')})`,
                                }}
                                className={`flex-none ${base}__icon mrm`}
                            />
                            <div>
                                <p className="text--bold">
                                    {store.MEET_DRIVER_AT_LOBBY}
                                </p>
                                <p className="fs--small text--dark pts pbm">
                                    {intl.get(
                                        'deliveryAddress.popupExplain.meetTheDriverInLobbyDetail'
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <center>
                            <h3 className="text--bolder">
                                {intl.get(
                                    'deliveryAddress.popupExplain.bestOptionsTitle'
                                )}
                            </h3>
                            <div className="separator separator--green mtm mbm" />
                        </center>
                        <div
                            className="fs--small"
                            dangerouslySetInnerHTML={{
                                __html: intl.get(
                                    'deliveryAddress.popupExplain.bestOptionsDetail'
                                ),
                            }}
                        />
                    </div>
                    <center>
                        <button
                            className={`${base}__CTA button button--success button--bigger-on-mobile mtm mbm width--200px`}
                            onClick={() => store.showPopup(store.POPUP_NOTE)}
                        >
                            {intl.get('deliveryAddress.popupExplain.CTA')}
                        </button>
                        <div
                            className="fs--small width--75"
                            dangerouslySetInnerHTML={{
                                __html: intl.get(
                                    'deliveryAddress.popupExplain.contact'
                                ),
                            }}
                        />
                    </center>
                </div>
            </div>
        );
    }
}
