import React from 'react';
import intl from 'react-intl-universal';

export default () => {
    const base = 'wallet';

    return (
        <div className={`${base}__section ${base}__section-savings`}>
            <div className="m0a">
                <div className={`${base}__section-savings-wrapper row`}>
                    <div
                        className={`${base}__section-savings-item col-lg-4 col-md-4`}
                    >
                        <svg
                            className={`${base}__section-savings-icon iconmoon-icon`}
                        >
                            <use xlinkHref={`#icon-checkmark-noborder`} />
                        </svg>
                        <div>
                            <p className={`${base}__savings-item-title`}>
                                {intl.get('wallet.savings.saving1.title')}
                            </p>
                            <p
                                className={`${base}__savings-item-subtitle lh--150 fs--medium-medium-large`}
                            >
                                {intl.get('wallet.savings.saving1.subtitle')}
                            </p>
                        </div>
                    </div>
                    <div
                        className={`${base}__section-savings-item col-lg-4 col-md-4`}
                    >
                        <svg
                            className={`${base}__section-savings-icon iconmoon-icon`}
                        >
                            <use xlinkHref={`#icon-checkmark-noborder`} />
                        </svg>
                        <div>
                            <p className={`${base}__savings-item-title`}>
                                {intl.get('wallet.savings.saving2.title')}
                            </p>
                            <p
                                className={`${base}__savings-item-subtitle lh--150 fs--medium-medium-large`}
                            >
                                {intl.get('wallet.savings.saving2.subtitle')}
                            </p>
                        </div>
                    </div>
                    <div
                        className={`${base}__section-savings-item col-lg-4 col-md-4`}
                    >
                        <svg
                            className={`${base}__section-savings-icon iconmoon-icon`}
                        >
                            <use xlinkHref={`#icon-checkmark-noborder`} />
                        </svg>
                        <div>
                            <p className={`${base}__savings-item-title`}>
                                {intl.get('wallet.savings.saving3.title')}
                            </p>
                            <p
                                className={`${base}__savings-item-subtitle lh--150 fs--medium-medium-large`}
                            >
                                {intl.get('wallet.savings.saving3.subtitle')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
