import { intercept } from 'mobx';
import { services } from '../../services/index'
export default class {
    setUpNotification(bannerOptions) {
        intercept(this, 'message', change => {
            const errorMessage = change.newValue;

            if (change.newValue) {
                services.bannerEvents.showError(errorMessage, bannerOptions);
            }
        });
    }
}