import { action, computed, observable, toJS, when } from 'mobx';
import Bacon from 'baconjs';
import cartStore from './cartStore';
import { services } from '../services/index';

import Notifiable from './services/notifiable';
import userStore from './userStore';
import rewardsStore from './rewardsStore';
import {
    getActiveDateObj,
    getActiveTimeslotObj,
    storeActiveDateObj,
    storeActiveTimeslotObj,
} from '../services/timeslot';

/*
Contains all menu-related data. Contains logic to fetch menu dates and menu data, as well as setting the current valid timeslot for menu and dish pages.
Primarily used on the menu and dish components, but is also accessed by other components and stores that need menu-related data.
*/
class MenuListStore extends Notifiable {
    @observable isLoading = true;
    @observable message = '';

    onMealsLoaded$ = new Bacon.Bus();
    calendarStore = null;

    @observable validDates = [];
    @observable menu = [];
    @observable menuForEachDelivery = [];
    @observable activeDateObj = getActiveDateObj() || {};
    @observable activeTimeslotObj = getActiveTimeslotObj() || {};
    @observable nextDateObj = {};
    @observable contentCards = null;

    @computed get activeDateDisplay() {
        return this.activeDateObj && this.activeDateObj.display_date;
    }

    @computed get nextDateDisplay() {
        return this.nextDateObj && this.nextDateObj.display_date;
    }

    @computed get activeTimeslotDisplay() {
        return this.activeTimeslotObj && this.activeTimeslotObj.display_text;
    }

    @computed get activeTimeslotId() {
        return this.activeTimeslotObj && this.activeTimeslotObj.id;
    }

    @computed get flattenedMenu() {
        if (this.activeDateMenu) {
            return this.activeDateMenu.lines
                .sort((a, b) => a.order - b.order)
                .filter(line => !line.name.includes("BUY 1 FREE 1"))
                .map((line) => ({
                    ...line,
                    items: this.activeDateMenu.items
                        .filter((item) => item.line_ids.includes(line.id))
                        .filter((item) => !item.title_bold.includes("BUY 1 FREE 1"))
                        .sort((a, b) => a.order - b.order),
                }));
        } else {
            return [];
        }
    }

    @computed get allCategories() {
        if (this.activeDateMenu && this.activeDateMenu.lines.length > 0) {
            return this.activeDateMenu.lines
                .filter(line => line.name !== "BUY 1 FREE 1") // temporarily remove buy 1 free 1 line
                .sort((a, b) => a.order - b.order)
                .map((line) => line.name);
        } else {
            return [];
        }
    }

    // accepts date as an argument, and returns all valid timeslots for that particular date.
    @action getValidTimeslotsForOneDate = (dateObj) => {
        const validTimeslots =
            dateObj && dateObj.time_slots.filter((timeslot) => timeslot.open);
        return validTimeslots;
    };

    @action refreshMenu = () => {
        // have to validate cart on refresh
        this.isLoading = true;
        this.getMenu();
    };

    @computed get isLastDate() {
        if (this.validDates && this.validDates.length > 0) {
            return (
                this.activeDateObj ===
                this.validDates[this.validDates.length - 1]
            );
        }
    }

    @computed get activeDateMenu() {
        if (this.menu && this.menu.length > 0) {
            return (
                this.menu.find(
                    (menu) => menu.date === this.activeDateObj.date
                ) || null
            );
        } else {
            return null;
        }
    }

    @action getNextDateMenu = () => {
        this.storeActiveDateObj(this.nextDateObj);
        this.refreshMenu();
    };

    @action getCurrentDayMenu = () => {
        this.refreshMenu();
    };

    @action getMenuForEachDelivery = ({
        startDateString,
        endDateString,
        customTimeslotId,
        customAddress,
    }) => {
        const delivery = cartStore.cart.find(
            (delivery) => delivery.date === startDateString
        );

        let requestPayload = {
            startDateString,
            endDateString,
            customTimeslotId: customTimeslotId || delivery.time_slot_id,
            customAddress: customAddress || delivery.address_id,
        };

        this.menuForEachDelivery = this.menuForEachDelivery.filter(
            (menu) => menu.date !== startDateString
        );
        services.api
            .GetMenu(requestPayload)
            .then((responseData) => {
                this.menuForEachDelivery = [
                    ...this.menuForEachDelivery,
                    ...responseData.dates,
                ];
            })
            .catch((responseData) => {
                this.message = responseData.message;
            });
    };

    // fetches menu from the backend. repeats the call if the backend returns additional dates in the response (next_page)
    @action getMenu = ({ startDate, endDate, lat, lng } = {}) => {
        this.menu = [];
        when(
            // is only called when user is not applying any rewards, so that we can decide whether to show discounted prices on the menu or not
            () => rewardsStore.isBusyApplyCode === false,
            () => {
                this.isLoading = true;
                const startDateToUse = startDate || this.activeDateObj.date;
                const endDateToUse = endDate || this.activeDateObj.date;

                let requestPayload = {
                    startDateString: startDateToUse,
                    endDateString: endDateToUse,
                    ...(lat && lng && { lat, lng }),
                };

                services.api
                    .GetMenu(requestPayload)
                    .then((responseData) => {
                        this.menu = [...responseData.dates];
                        // if the function is not called for a specific date, we invoke it again as long as backend returns next_page

                        const indexOfCurrentDate = this.validDates.findIndex(
                            (dateObj) => dateObj.date === startDateToUse
                        );
                        this.nextDateObj =
                            this.validDates[indexOfCurrentDate + 1];
                    })
                    .catch((responseData) => {
                        this.message = responseData.message;
                    })
                    .then(() => {
                        this.getContentCards();
                        this.isLoading = false;
                    });
            }
        );
    };

    @action getMenuDates = ({ fromCheckout, lat, lng } = {}) => {
        this.isLoading = true;
        services.api
            .GetMenuDates()
            .then((responseData) => {
                this.validDates =
                    responseData.dates &&
                    responseData.dates.filter((date) => date.available);

                const prevActiveDateObj = this.validDates.find(
                    (dateObj) => dateObj.date === this.activeDateObj.date
                );
                // if activeDate already set, preselect that date
                if (prevActiveDateObj) {
                    this.storeActiveDateObj(prevActiveDateObj);
                    // else preselect first available date from response
                } else {
                    this.storeActiveDateObj(this.validDates[0]);
                }

                const activeTimeslots = this.getValidTimeslotsForOneDate(
                    this.activeDateObj
                );
                const prevActiveTimeslotObj = activeTimeslots.find(
                    (timeslotObj) =>
                        timeslotObj.id === this.activeTimeslotObj.id
                );
                let activeTimeslotObj;

                // if activeTimeslot already set, preselect that timeslot for new activeDate
                if (prevActiveTimeslotObj) {
                    activeTimeslotObj = prevActiveTimeslotObj;
                    // else preselect first available timeslot
                } else {
                    activeTimeslotObj = activeTimeslots[0];
                }
                this.storeActiveTimeslotObj(activeTimeslotObj);
            })
            .catch((responseData) => {
                this.message = responseData.message;
            })
            .then(() => {
                if (fromCheckout) {
                    cartStore.checkCart(true);
                } else {
                    this.getMenu({ lat, lng });
                }
            });
    };

    @action getContentCards = () => {
        services.api
            .GetContentCards()
            .then((responseData) => {
                this.contentCards = responseData;
            })
            .catch((responseData) => {
                this.message = responseData.message;
            });
    };

    storeActiveDateObj = (activeDateObj) => {
        this.activeDateObj = activeDateObj;
        storeActiveDateObj(toJS(activeDateObj));
    };

    storeActiveTimeslotObj = (activeTimeslotObj) => {
        this.activeTimeslotObj = activeTimeslotObj;
        storeActiveTimeslotObj(toJS(activeTimeslotObj));
    };

    sendItemListToDatalayer() {
        let menuItemIdArray = [];
        let menuIdArray = [];
        when(
            () => this.menu.length !== 0,
            () => {
                this.menu.forEach((day) => {
                    day.items.forEach((item) => {
                        menuIdArray.push(item.menu_id);
                        menuItemIdArray.push(item.id);
                    });
                });
                window.dataLayer.push({
                    PageType: 'ListingPage',
                    ProductIDList: menuIdArray,
                    DailyProductIDList: menuItemIdArray,
                });
            }
        );
    }

    getAddOnsForDay = (menuForDay) => {
        const availableAddOns = menuForDay.items.filter(
            (item) =>
                item.addon_category &&
                (item.quantity_left > 0 || item.available_at)
        );

        return menuForDay.lines
            .filter((line) => line.addon)
            .sort((a, b) => a.order - b.order)
            .reduce((addOnObj, line) => {
                const items = availableAddOns
                    .filter((item) => item.line_ids.includes(line.id))
                    .sort((a, b) => a.order - b.order);

                addOnObj[line.name] = items || [];
                return addOnObj;
            }, {});
    };
}

const store = new MenuListStore();
export default store;
