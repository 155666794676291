import React from 'react';
import { observer, inject } from 'mobx-react';
import { GetLanguage } from '../../../services/translation';
import OtherHeaderUI from '../OtherHeaderUI';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

@inject('headerStore', 'userStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        if (window.location.href.includes('ref=wallet-lp')) {
            this.props.headerStore.setBackLink('/wallet-healthy-food-delivery');
        }
    }

    render() {
        const { hasWallet, headerStore } = this.props;
        const {
            backLink,
            nextLink,
            showBackLink,
            showNextLink,
            walletPageBackLink,
            customClass,
        } = headerStore;
        const lang = GetLanguage();
        const backLinkMinusLang =
            lang === 'th' || lang === 'ms'
                ? backLink.replace(`${lang}/`, '')
                : backLink;

        return (
            <OtherHeaderUI
                backLink={backLinkMinusLang}
                nextLink={nextLink}
                showBackLink={showBackLink}
                fromURL={walletPageBackLink}
                customClass={customClass}
                showNextLink={showNextLink}
                logoURL="https://d3tjqo2jh935k9.cloudfront.net/images/static/prime-logo-black.png"
                logoWidth={
                    window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 80 : 100
                }
                showWalletLogo={hasWallet}
            />
        );
    }
}
