import enJson from './locales/en.json';
import thJson from './locales/th.json';
import msJson from './locales/ms.json';
import Cookies from 'js-cookie';
import isBot from 'isbot';
import { getParam } from '../services';

export const saveLangToStorage = (lang) => {
    if (!isBot(window.navigator.userAgent)) {
        localStorage.setItem('lang', lang);
    }
};

export const getLangFromStorage = () => {
    return localStorage.getItem('lang');
};

export const LOCALES = {
    en: {
        name: 'English',
        value: 'en',
        json: enJson,
    },
    ms: {
        name: 'Malay',
        value: 'ms',
        json: msJson,
    },
};

export const GetLanguage = () => {
    //language setting logic
    if (typeof window !== 'undefined') {
        const { pathname, hostname } = window.location;

        // get locale from url i.e. 'en/'
        let locale;
        if (pathname.slice(1, 4).endsWith('/')) {
            locale = pathname.slice(1, 3);
        }

        // if locale in url
        if (Object.keys(LOCALES).includes(locale)) {
            saveLangToStorage(locale);
            return LOCALES[locale]['value'];
        }
        // if rider page, set locale to my
        if (
            pathname.includes('ride') &&
            Cookies.get('ridepage') !== 'english'
        ) {
            return LOCALES.ms.value;
        }
        // if locale in localStorage
        if (getLangFromStorage()) {
            locale = getLangFromStorage();
            saveLangToStorage(locale);
            if (Object.keys(LOCALES).includes(locale)) {
                return LOCALES[locale]['value'];
            }
        }
        saveLangToStorage('en');
        return LOCALES.en.value;
    }
    return LOCALES.en.value;
};

/**
 * Wraps intl.get(.). This function will consider the current city to get an even more localized (not only language but city) string.
 * This allows us to avoid HOCs for components that are basically the same except some strings.
 * Example:
 * 'my.key' and city 'Bangkok' will result in a return of 'Bangkok.my.key' IF it exists, otherwise we return 'my.key'
 *
 * Structure in locale json file:
 * {
 *   // anything in there overrides the default for this locale
 *   "Bangkok": {
 *     "my": {
 *       "key": "hong-hong"
 *     }
 *   },
 *   "my": {
 *     "key": "wuff-wuff",
 *     "other": "hi"
 *   }
 * }
 *
 * @param key
 * @returns {*|string}
 */
// export const get = (key) => {
//     let result;
//     // double try-catch necessary for Fetch as Google (test via PhantomJS)
//     try {
//         result = intl.get(`${GetCity()}.${key}`).defaultMessage(intl.get(key));
//     } catch (e) {
//         try {
//             result = intl.get(key);
//         } catch (err) {
//             console.log(err);
//             result = "";
//         }
//     }
//
//     return result;
// };
