import React from 'react';
import { inject, observer } from 'mobx-react';

import AddonItem from './AddonItem';

const base = 'terminal-mobile-addon-items-home-screen-list';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    handleViewAllClick = () => {
        const {
            terminalMenuStore: {
                mobileScreens,
                setAddonsView,
                setShowMobileScreen,
                setYPositionToScroll,
            },
            listName,
        } = this.props;
        const viewAllElement = document.getElementById(`view-all-${listName}`);
        if (viewAllElement) {
            const viewAllPosition = viewAllElement.getBoundingClientRect();
            setYPositionToScroll(viewAllPosition.top + window.scrollY - 76); //here 76 is the height of header shown on mobile kiosk
        }

        setAddonsView(this.props.listName);
        setShowMobileScreen(mobileScreens.ADDON);
    };

    render() {
        const { items, listName } = this.props;
        const addonItems = items
            .filter(
                (item) =>
                    item.item_type === 'Addons' &&
                    item.addon_category === listName &&
                    item.quantity_left > 0 &&
                    !item.title_bold.toLowerCase().includes('cutlery')
            )
            .sort((a, b) => a.order - b.order)
            .slice(0, 3);

        return (
            <div className={`${base}`}>
                <div className={`${base}__list_name_viewall`}>
                    <p className="list-name">{listName}</p>
                    <button
                        className="view-all-btn"
                        onClick={this.handleViewAllClick}
                        id={`view-all-${listName}`}
                    >
                        View all
                    </button>
                </div>
                <div className={`${base}__addon_items`}>
                    {addonItems &&
                        addonItems.map((item) => <AddonItem item={item} />)}
                </div>
                <div></div>
            </div>
        );
    }
}
