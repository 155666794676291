import React from 'react';
import BaseLayout from './core/baseLayout/Master_BaseLayout';

import Entry from './page/offlineSignupPage/Entry';
import loginPopupStore from '../store/loginPopupStore';
import { CircleLoader } from './core/Loading';

const { clevertap } = window;
const none = () => <div />;

export default class LandingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    isSameDay(d1, d2) {
        return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );
    }

    componentDidMount() {
        clevertap.event.push('View Page', {
            'Page Type': 'Offline Signup Page',
            'Page Title': document.title,
            'Page URL': window.location.href,
        });
    }

    // add metatags

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        };

        if (this.state.loading) {
            return (
                <div style={style}>
                    <CircleLoader />
                </div>
            );
        }
        return (
            <BaseLayout.Page HeaderComponent={none}>
                <main>
                    <Entry />
                </main>
            </BaseLayout.Page>
        );
    }
}
