import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import FloatingCta from '../../core/FloatingCta';
import { cities, GetCity } from '../../../services/city';
import qs from 'query-string';
import Cookies from 'js-cookie';
const { clevertap } = window;

@inject('store')
@inject('headerStore')
@inject('creditCardStore')
@observer
export default class extends React.Component {
    handlePlaceOrderClicked(next) {
        const { store } = this.props;
        const params = qs.parse(window.location.search);
        const fromBoostApp = params.platform === 'boost' || Cookies.get('boost_user');

        return () => {
            this.props.headerStore.setShowBackLink(false);

            clevertap.event.push('Placing_Order');
            clevertap.event.push('Tapped On Confirm Order');
            if (GetCity() === cities.kl && store.paymentMethod && store.paymentMethod.payment_method_type === 'Card') {
                next();
            } else {
                if (store.paymentMethod.id === 'boost' && !fromBoostApp) {
                    store.openBoostPopup = true;
                } else {
                    next();
                }
            }
        };
    }

    render() {
        const base = 'place-order-button';
        const { store } = this.props;
        const successButtons = `${base} button button--bigger-on-mobile button--success width--100`;

        if (store.isBusy) {
            return window.matchMedia(SMALL_SCREEN_MEDIA).matches === true ? (
                <FloatingCta centerText={intl.get('packageCheckout.placeorder.busy')} busy={true} />
            ) : (
                <button className={`${base} button button--bigger-on-mobile button--busy width--100`}>
                    {intl.get('packageCheckout.placeorder.busy')}
                </button>
            );
        }

        if (!store.hasUserUpdatedProfile) {
            return window.matchMedia(SMALL_SCREEN_MEDIA).matches === true ? (
                <FloatingCta centerText={intl.get('packageCheckout.placeorder.noUpdateProfile')} disabled={true} />
            ) : (
                <button className={`${base} button button--bigger-on-mobile button--disabled width--100`}>
                    {intl.get('packageCheckout.placeorder.noUpdateProfile')}
                </button>
            );
        }

        if (!store.paymentMethod) {
            return window.matchMedia(SMALL_SCREEN_MEDIA).matches === true ? (
                <FloatingCta centerText={intl.get('packageCheckout.placeorder.noPayment')} disabled={true} />
            ) : (
                <button className={`${base} button button--bigger-on-mobile button--disabled width--100`}>
                    {intl.get('packageCheckout.placeorder.noPayment')}
                </button>
            );
        }

        switch (store.paymentMethod['card_type']) {
            case 'Paypal':
                return window.matchMedia(SMALL_SCREEN_MEDIA).matches === true ? (
                    <FloatingCta
                        centerText={intl.get('packageCheckout.placeorder.paypal')}
                        onClick={::this.handlePlaceOrderClicked(::store.placeOrderWithCard)}
                    />
                ) : (
                    <button
                        className={successButtons}
                        onClick={::this.handlePlaceOrderClicked(::store.placeOrderWithCard)}
                    >
                        {intl.get('packageCheckout.placeorder.paypal')}
                    </button>
                );
            case 'BillPlz':
                return window.matchMedia(SMALL_SCREEN_MEDIA).matches === true ? (
                    <FloatingCta
                        centerText={intl.get('packageCheckout.placeorder.billplz')}
                        onClick={::this.handlePlaceOrderClicked(::store.placeOrderWithOnlineBanking_billplz)}
                    />
                ) : (
                    <button
                        className={successButtons}
                        onClick={::this.handlePlaceOrderClicked(::store.placeOrderWithOnlineBanking_billplz)}
                    >
                        {intl.get('packageCheckout.placeorder.billplz')}
                    </button>
                );
            case 'Boost':
                return window.matchMedia(SMALL_SCREEN_MEDIA).matches === true ? (
                    <FloatingCta
                        centerText={intl.get('packageCheckout.placeorder.card')}
                        onClick={::this.handlePlaceOrderClicked(::store.placeOrderWithOnlineBanking_boost)}
                    />
                ) : (
                    <button
                        className={successButtons}
                        onClick={::this.handlePlaceOrderClicked(::store.placeOrderWithOnlineBanking_boost)}
                    >
                        {intl.get('packageCheckout.placeorder.card')}
                    </button>
                );
            case 'Online Banking':
                if (!store.paymentMethod.fpx_gateway) {
                    return window.matchMedia(SMALL_SCREEN_MEDIA).matches === true ? (
                        <FloatingCta centerText={intl.get('packageCheckout.placeorder.noPayment')} disabled={true} />
                    ) : (
                        <button className={`${base} button button--bigger-on-mobile button--disabled width--100`}>
                            {intl.get('packageCheckout.placeorder.noPayment')}
                        </button>
                    );
                } else {
                    return window.matchMedia(SMALL_SCREEN_MEDIA).matches === true ? (
                        <FloatingCta
                            centerText={intl.get('packageCheckout.placeorder.onlineBanking')}
                            onClick={::this.handlePlaceOrderClicked(::store.placeOrderWithOnlineBanking_omise)}
                        />
                    ) : (
                        <button
                            className={successButtons}
                            onClick={::this.handlePlaceOrderClicked(::store.placeOrderWithOnlineBanking_omise)}
                        >
                            {intl.get('packageCheckout.placeorder.onlineBanking')}
                        </button>
                    );
                }
            default:
                return window.matchMedia(SMALL_SCREEN_MEDIA).matches === true ? (
                    <FloatingCta
                        centerText={intl.get('packageCheckout.placeorder.card')}
                        onClick={::this.handlePlaceOrderClicked(::store.placeOrderWithCard)}
                    />
                ) : (
                    <button
                        className={successButtons}
                        onClick={::this.handlePlaceOrderClicked(::store.placeOrderWithCard)}
                    >
                        {intl.get('packageCheckout.placeorder.card')}
                    </button>
                );
        }
    }
}
