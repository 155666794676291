import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import { RESOURCE } from '../../../../services/types';
import { GetQueryParamFromUrl } from '../../../../services/url';
import { CircleLoader } from '../../../core/Loading';
import ItemsHomeScreen from './ItemsHomeScreen';
import AddonsScreen from './AddonsScreen';
import CartScreen from './CartScreen';
import HelpScreen from './HelpScreen';
import OrderConfirmationScreen from './OrderConfirmationScreen';
import OrderTypeScreen from '../OrderTypeScreen';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.bearerToken = GetQueryParamFromUrl('bearerToken');

        terminalMenuStore
            .fetchTerminalInfo()
            .then(() => terminalMenuStore.fetchMenu());
    }

    handleClickCta = () => {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.confirmOrder(true);
    };

    render() {
        const store = this.props.terminalMenuStore;
        const { itemsFetcher, mobileScreens, showMobileScreen } = store;
        console.log(itemsFetcher, 'items fetcher');
        let items;
        if (RESOURCE.Success.is(itemsFetcher)) {
            items = itemsFetcher.data.filter((item) => item.quantity_left > 0);
        }

        return (
            <div>
                {itemsFetcher === RESOURCE.Loading ||
                itemsFetcher === RESOURCE.Initial ? (
                    <center>
                        <CircleLoader />
                    </center>
                ) : RESOURCE.Success.is(itemsFetcher) ? (
                    <Fragment>
                        {showMobileScreen === mobileScreens.HOME ? (
                            <OrderTypeScreen />
                        ) : null}
                        {showMobileScreen === mobileScreens.ITEM ? (
                            <ItemsHomeScreen items={items} />
                        ) : null}
                        {showMobileScreen === mobileScreens.ADDON ? (
                            <AddonsScreen items={items} />
                        ) : null}
                        {showMobileScreen === mobileScreens.CART ? (
                            <CartScreen />
                        ) : null}
                        {showMobileScreen === mobileScreens.HELP ? (
                            <HelpScreen />
                        ) : null}
                        {showMobileScreen ===
                        mobileScreens.ORDER_CONFIRMATION ? (
                            <OrderConfirmationScreen />
                        ) : null}
                    </Fragment>
                ) : RESOURCE.Failure ? (
                    <Fragment>
                        {showMobileScreen === mobileScreens.HOME ? (
                            <OrderTypeScreen />
                        ) : null}
                        {showMobileScreen === mobileScreens.HELP ? (
                            <HelpScreen />
                        ) : null}
                    </Fragment>
                ) : (
                    <p>{intl.get('terminal.page.entry.noMeals')}</p>
                )}
            </div>
        );
    }
}
