import React from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import { IS_1200_OR_LESS } from '../../../services/constants';

@inject('menuHeaderStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.state = { isHovering: false };
    }

    handleMouseEnter() {
        const canExpand = !window.matchMedia(IS_1200_OR_LESS).matches;

        if (canExpand) {
            this.setState({ isHovering: true }, () => {
                this.rootNode.classList.add('add-to-cart-button--expanded');
            });
        }
    }

    handleMouseLeave() {
        this.rootNode.classList.remove('add-to-cart-button--expanded');
        setTimeout(() => {
            this.setState({ isHovering: false });
        }, 200);
    }

    setNode(node) {
        this.rootNode = node;
    }

    render() {
        const base = 'addon-add-to-cart-button';
        const baseClass = cx(base, 'mlxs', {
            [`${base}--disabled`]: this.props.disabled,
        });

        return (
            <div
                className={baseClass}
                ref={::this.setNode}
                onClick={this.props.onClick}
                style={{ maxWidth: this.props.maxWidth }}
            >
                <div className={`${base}__content`}>
                    {this.state.isHovering ? (
                        <p className={`${base}__hover-text`}>
                            {this.props.hoverText}
                        </p>
                    ) : (
                        <svg className={`${base}__icon iconmoon-icon`}>
                            <use xlinkHref="#icon-plus-black" />
                        </svg>
                    )}
                </div>
            </div>
        );
    }
}
