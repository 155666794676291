/*global google*/

import React from 'react';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';

import { services } from '../../../services/index';
import mapStyles from './mapStyle';

import { RESOURCE } from '../../../services/types';

const base = 'delivery-area__map-section';

@inject('deliveryAreaStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const Office = services.getParam('kitchenLocation');

        this.map = new google.maps.Map(this.mapNode, {
            center: Office,
            zoom: 10,
            scrollwheel: false,
            styles: mapStyles,
        });

        const { deliveryAreaStore } = this.props;
        deliveryAreaStore.fetchDeliveryAreaPolygons();

        when(
            () => RESOURCE.Success.is(deliveryAreaStore.polygons),
            () => {
                const polygons = deliveryAreaStore.polygons.data;
                const polygonObjects = polygons.map(p => (new google.maps.Polygon({
                    paths: p,
                    strokeColor: '#fec700',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#fec700',
                    fillOpacity: 0.32,
                })));
                polygonObjects.forEach(o => o.setMap(this.map));
            }
        );
    }

    componentWillUnmount() {
        this.map = null;
    }

    render() {
        return (
            <div className={base} id="map-section">
                <div className="container">
                    <div className="row">
                        <div
                            className={`${base}__text-area col-lg-5 col-md-5 col-sm-5`}
                        >
                            <h1 className={`${base}__heading text--bolder`}>
                                {intl.get('deliveryArea.mapHeading')}
                            </h1>
                            <div className="separator separator--green mtl mbl" />
                            <p
                                className="lh--150 mbl hide--if-small"
                                dangerouslySetInnerHTML={{
                                    __html: intl.get(
                                        'deliveryArea.mapSubheading'
                                    ),
                                }}
                            />
                            <a href="/menu" className="hide--if-small">
                                <button
                                    className={`${base}__cta button button--success`}
                                >
                                    {intl.get('deliveryArea.mapCTA')}
                                </button>
                            </a>
                        </div>
                        <div
                            className={`${base}__map-container col-lg-7 col-md-7 col-sm-7`}
                        >
                            <div
                                className={`${base}__map`}
                                ref={(node) => {
                                    this.mapNode = node;
                                }}
                            />
                        </div>
                        <div className="hide--if-large show--if-small pll prl">
                            <Link to="/menu">
                                <button
                                    className={`${base}__cta2 button button--success button--bigger-on-mobile width--100`}
                                >
                                    {intl.get('deliveryArea.mapCTA')}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
