import { reaction } from 'mobx';
import { RESOURCE, Link } from '../../services/types';

export default (billPlzStore, headerStore) => {
    /*
     * Every time the payment status change:
     * ----- If status is COMPLETE, then loadUser to display Confirm screen, then clear the cart
     * ----- If status is CANCELLED or FAILED, show the back link and show error
     */
    reaction(
        () => billPlzStore.status,
        (status) => {
            if (RESOURCE.Success.is(status)) {
                headerStore.setShowBackLink(true);
                headerStore.setBackLink(new Link('/menu', 'BACK TO MENU'));

                billPlzStore.loadUser();
                billPlzStore.loadAddresses();
                billPlzStore.clearCart();
            } else if (RESOURCE.Failure.is(status)) {
                headerStore.setShowBackLink(true);
                headerStore.setBackLink(new Link('/cart', 'BACK TO CART'));
            }
        }
    );
};
