// App Config for development profile
export default {
    // the brand name we display
    brandName: 'Pop Meals',
    brandNameShort: 'Pop',

    // Hostnames
    hostnameMy: 'localhost',
    hostnameTh: 'localhost2',

    // Base URL
    baseUrlMy: 'http://localhost:3000',
    baseUrlTh: 'http://127.0.0.1:3001',
    // Hostname for static endpoints
    staticAssets: 'http://localhost:3000',

    deploymentUrl: 'http://127.0.0.1:3001',

    // Hostname for API endpoints
    services: 'http://52.74.100.84',
    basicAuth: 'UkVGSVRVRkw6UVU0NlJrOVBSQQ==',

    // Current onBoarding version.
    // When the user visits onboarding page, they will be marked as "has seen onboarding version x"
    // The next time they visit, if the version matches, they will be redirected to order.dahmakan.com
    // This feature can be turn off by setting willRedirectOnboarding to false
    willRedirectOnboarding: true,
    onboardingVersion: '1.0',

    // Default city
    defaultCity: 'Kuala_Lumpur',

    //brand & city for http headers
    brand: 'popmeals',
    city: 'Kuala_Lumpur',

    //country for place autocomplete suggestions
    country: 'my',
    currency: 'RM',
    currencyCode: 'MYR',

    //Universal download link
    appDownloadPath: 'https://app.dahmakan.com/install-staging',

    // AppStore download link
    appStorePath: 'https://app.popmeals.com.my/download-popmeals-for-ios',

    // GooglePlay download link
    googlePlayPath: 'https://app.popmeals.com.my/download-popmeals-for-android',

    // Huawei App Gallery download link
    huaweiAppGalleryPath:
        'https://app.popmeals.com.my/download-popmeals-huawei-app-gallery',

    facebookLink: 'https://www.facebook.com/popmeals_my',
    twitterLink: 'http://www.twitter.com/popmeals_my',
    instagramLink: 'https://www.instagram.com/popmeals_my/',
    linkedInLink: 'https://www.linkedin.com/company/dah-makan',

    facebookAppId: '865803290168313',
    facebookPixel: '1644193302561355',
    GTMid: 'GTM-NGWB36',
    googleClientId:
        '743060368799-44tq1b72ha96ek6gg1fp4oh8mgku796l.apps.googleusercontent.com',
    googleMapKey: 'AIzaSyBNPySbOaJG-PxNJ1YwuPfsIL5wRLKqrAc',

    fpxRedirectUrlMenu:
        'http://' + window.location.host + '/purchase',
    fpxRedirectUrlWallet:
        'http://' + window.location.host + '/wallet/purchase',

    branchioKey: 'key_test_gbf0v6iRRBBcT61Trw1qChnbzulTvd7q',
    mixpanelKey: '60c113fb03808cab839d69314c0aaad7',

    ringCaptchaAppId: 'ny3aqaby6y9y5i6u2u8e',
    ringCaptchaAPIKey: 'b2298beed2bc061ceacb720119a81460ae56d54a',

    freshDeskHc: 'https://help.popmeals.com.my',
    freshDeskSupportUrl:
        'https://us-central1-dahmakan-64232.cloudfunctions.net/createFreshdeskTicket',

    addonsLaunched: true,

    signupEventName: 'open',
    purchaseEventName: 'order_placed',

    freeMealMinimum: 19,
    lowestMealCost: 21,

    showGoogleMapsReviewPrompt: true,
    googleMapsReviewUrl:
        'https://search.google.com/local/writereview?placeid=ChIJf54KvvFIzDERaDcy3h_q7vE',

    kitchenLocation: { lat: 3.079247, lng: 101.650905 },
    showChallenge: false,

    menuHeaderHeight: '130px',

    stripeAPIKey: 'pk_test_WC6qcitLHtpfZh1ZiEqzpWBc',
};
