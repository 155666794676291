import React from 'react';
import intl from 'react-intl-universal';

export default () => (
    <div className="resolver--loading">{intl.get('loading')}</div>
);

export const CircleLoader = ({ className }) => {
    const isTerminal = window.location.href.includes('terminal');
    return (
        <div
            className={`loading--circle ${className || ''} ${
                isTerminal ? 'red' : ''
            }`}
        />
    );
};
