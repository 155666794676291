import React, { Component } from 'react';
import brandLogo from './brandLogo/MasterBrandLogo';

const base = 'promotion-tiles';

export default class PromotionTiles extends Component {
    render() {
        const {
            customClass,
            icon,
            header,
            body,
            ctaText,
            onClick,
            logoColor = 'green',
            linkColor,
        } = this.props;
        return (
            <div className={`${base}__info-box ${customClass}`}>
                <div className={`${base}__info-content info-content`}>
                    <div className={`${base}__info-icon-container info-icon`}>
                        <svg className={`${base}__icon iconmoon-icon ${icon}`}>
                            <use xlinkHref={`#${icon}`} />
                        </svg>
                    </div>
                    <div className={`${base}__info-text-container`}>
                        <p className={`${base}__info-text-header text--bolder`}>
                            {header}
                        </p>
                        <p className={`${base}__info-text-body`}>{body}</p>
                        <p
                            className={`link--no-underline ${
                                linkColor
                                    ? 'text--' + linkColor
                                    : 'text--secondary'
                            } text--bolder cursor-pointer`}
                            onClick={() => onClick()}
                        >
                            {ctaText}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
