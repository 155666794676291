import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import BasePage from './basePage';
import RewardsRoot from '../components/RewardsRoot';
import { getGTMScript } from '../components/core/ScriptTag';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('rewards');

export default class extends Component {
    componentDidMount() {
        document.body.classList.add('rewards');
    }
    render() {
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: 'Rewards',
            lpType: '',
            lpVersion: '',
        });

        return (
            <Fragment>
                <Helmet>
                    <meta name="nosnippet" />
                    <title>{pageTitle}</title>
                    {localizationTags(`rewards`).map((tag) => tag)}
                    {getCanonicalTag(`rewards`)}
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <RewardsRoot />
            </Fragment>
        );
    }
}
