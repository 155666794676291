import React from 'react';
import { inject, observer } from 'mobx-react';

import LoginPopup from '../../core/LoginPopup';
import PaymentInProgress from '../../core/PaymentInProgress';
import ConfirmPayment from '../walletCheckoutPage/ConfirmPayment';

@inject('headerStore')
@inject('billPlzStore')
@inject('loginPopupStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.loginPopupStore.checkUser();
    }

    renderSuccess() {
        const store = this.props.billPlzStore.getDataForConfirmPayment();
        this.props.billPlzStore.sendTrackingEvents(store);
        const { headerStore } = this.props;

        return (
            <div>
                <LoginPopup />
                <ConfirmPayment store={store} headerStore={headerStore} />
            </div>
        );
    }

    renderError() {
        return <div />;
    }

    renderWaiting() {
        return (
            <div>
                <LoginPopup />
                <PaymentInProgress color="Green" />
            </div>
        );
    }

    render() {
        const { billPlzStore } = this.props;
        const transactionStatus = billPlzStore.status['transaction_status'];

        if (
            transactionStatus === billPlzStore.COMPLETE &&
            billPlzStore.isLoading === false
        ) {
            return this.renderSuccess();
        }

        if (
            transactionStatus === billPlzStore.CANCELLED ||
            transactionStatus === billPlzStore.FAILED
        ) {
            return this.renderError();
        }

        return this.renderWaiting();
    }
}
