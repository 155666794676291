import React from 'react';
import { inject } from 'mobx-react';

import SectionWhat from './SectionWhat';
import SectionHow from './SectionHow';
import SectionWhy from './SectionWhy';
import SectionWho from './SectionWho';
import SectionSlider from './SectionSlider';

@inject('terminalMenuStore')
export default class extends React.Component {
    componentDidMount() {
        const { terminalMenuStore } = this.props;

        terminalMenuStore
            .fetchTerminalInfo()
            .then(::terminalMenuStore.fetchMenu);
    }

    render() {
        return (
            <div>
                <SectionWhat />
                <SectionHow />
                <SectionWhy />
                <SectionWho />
                <SectionSlider />
            </div>
        );
    }
}
