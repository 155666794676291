import React, { Component } from 'react';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import intl from 'react-intl-universal';
import { currency } from '../../../services';

const base = 'select-popup';

export default class SelectPlanUI extends Component {
    render() {
        const { selected, plan, onClick, singlePlan, key } = this.props;
        const isSelected = selected && selected.name === plan.name;
        const selectedClass = isSelected ? 'selected' : '';
        const popular = plan.most_popular && !singlePlan;
        const marginTop =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches && popular ? 13 : 0;

        return (
            <div
                key={key}
                className={`${base}__plan-container cursor-pointer ${
                    singlePlan ? 'single-plan' : ''
                } ${popular ? 'popular' : ''} ${selectedClass}`}
                onClick={() => onClick(plan)}
                style={{ marginTop }}
            >
                {popular && (
                    <div
                        className={`${base}__popular-indicator ${selectedClass}`}
                    >
                        <svg className={`${base}__star-icon iconmoon-icon`}>
                            <use xlinkHref={`#icon-star-select`} />
                        </svg>
                        <p className={`${base}__popular-text`}>
                            {plan.most_popular_text}
                        </p>
                    </div>
                )}
                <div
                    className={`${base}__plan-body ${selectedClass} ${
                        popular ? 'popular' : ''
                    }`}
                >
                    <p className={`${base}__plan-header text--bolder`}>
                        {plan.name}
                    </p>
                    <p className={`${base}__plan-subheader`}>
                        {plan.plan_desc}
                    </p>
                    {plan.percent_saved > 0 && !singlePlan && (
                        <p className={`${base}__plan-savings`}>
                            {intl.get('selectPopup.save')} {plan.percent_saved}%
                        </p>
                    )}
                </div>
                <div className={`${base}__plan-footer ${selectedClass}`}>
                    <p className={`${base}__price`}>
                        {currency.localCurrency(plan.price)}/
                        {intl.get('selectPopup.month')}
                    </p>
                </div>
            </div>
        );
    }
}
