import React from 'react';
import { Provider } from 'mobx-react';

import { Link } from '../services/types';
import BaseLayout from './core/baseLayout/Master_BaseLayout';
import Entry from './page/walletCheckoutPage/Entry';
import WalletHeader from './core/headers/WalletHeader';
import Store from '../store/walletCheckoutStore';
import rewardsStore from '../store/rewardsStore';
import PaymentStore from '../store/walletPaymentStore';
import CreditCardStore from '../store/creditCardStore/Master_CreditCardStore';
import PaypalStore from '../store/paypalStore';
import CompleteSignUpStore from '../store/completeSignUpStore';
import WalletHeaderStore from '../store/walletHeaderStore';
import loginPopupStore from '../store/loginPopupStore';
import setUpStoreInteractions from '../store/interactions/packageCheckoutInteractions';

const paymentStore = new PaymentStore();
const creditCardStore = new CreditCardStore();
const paypalStore = new PaypalStore();
const completeSignUpStore = new CompleteSignUpStore();
const store = new Store();

loginPopupStore.setBackLink(new Link('/wallet', 'BACK'));

const headerStore = new WalletHeaderStore({
    backLink: '/wallet',
    nextLink: '/menu',
});
const Header = () => <WalletHeader headerStore={headerStore} />;

const stores = {
    completeSignUpStore,
    paymentStore,
    creditCardStore,
    paypalStore,
    headerStore,
    store,
};

setUpStoreInteractions(
    creditCardStore,
    paypalStore,
    store,
    rewardsStore,
    paymentStore,
    completeSignUpStore,
    headerStore
);

export default class extends React.Component {
    render() {
        return (
            <BaseLayout.Page HeaderComponent={Header}>
                <main className="container p0--if-small">
                    <Provider {...stores}>
                        <Entry />
                    </Provider>
                </main>
            </BaseLayout.Page>
        );
    }
}
