import React from 'react';

import { services } from '../../../services/index';
import { GetCity } from '../../../services/city';

const googlePlayLink = services.getParam('googlePlayPath');
const appStoreLink = services.getParam('appStorePath');

const city = GetCity();

export const questions = (intl) =>
    city === 'kuala-lumpur'
        ? [
              {
                  question: intl.get(
                      `wallet.faq.kuala-lumpur.question1.question`
                  ),
                  answer: intl.get(`wallet.faq.kuala-lumpur.question1.answer`),
                  extras: (
                      <div className="lh--150">
                          <a
                              className="dmk-link--secondary mrl m0--if-small"
                              href={appStoreLink}
                          >
                              {intl.get(
                                  `wallet.faq.kuala-lumpur.question1.extras1`
                              )}
                          </a>
                          <br className="show--if-small hide--if-large" />
                          <a
                              className="dmk-link--secondary"
                              href={googlePlayLink}
                          >
                              {intl.get(
                                  `wallet.faq.kuala-lumpur.question1.extras2`
                              )}
                          </a>
                      </div>
                  ),
              },
              {
                  question: intl.get(
                      `wallet.faq.kuala-lumpur.question2.question`
                  ),
                  answer: intl.get(`wallet.faq.kuala-lumpur.question2.answer`),
              },
              {
                  question: intl.get(
                      `wallet.faq.kuala-lumpur.question3.question`
                  ),
                  answer: intl.get(`wallet.faq.kuala-lumpur.question3.answer`),
                  extras: (
                      <a className="dmk-link--secondary" href="/delivery-area">
                          {intl.get(`wallet.faq.kuala-lumpur.question3.extras`)}
                      </a>
                  ),
              },
              {
                  question: intl.get(
                      `wallet.faq.kuala-lumpur.question4.question`
                  ),
                  answer: intl.get(`wallet.faq.kuala-lumpur.question4.answer`),
              },
              {
                  question: intl.get(
                      `wallet.faq.kuala-lumpur.question5.question`
                  ),
                  answer: intl.get(`wallet.faq.kuala-lumpur.question5.answer`),
              },
          ]
        : [
              {
                  question: intl.get(`wallet.faq.bangkok.question1.question`),
                  answer: intl.get(`wallet.faq.bangkok.question1.answer`),
                  extras: (
                      <div className="lh--150">
                          <a
                              className="dmk-link--secondary mrl m0--if-small"
                              href={appStoreLink}
                          >
                              {intl.get(`wallet.faq.bangkok.question1.extras1`)}
                          </a>
                          <br className="show--if-small hide--if-large" />
                          <a
                              className="dmk-link--secondary"
                              href={googlePlayLink}
                          >
                              {intl.get(`wallet.faq.bangkok.question1.extras2`)}
                          </a>
                      </div>
                  ),
              },
              {
                  question: intl.get(`wallet.faq.bangkok.question2.question`),
                  answer: intl.get(`wallet.faq.bangkok.question2.answer`),
              },
              {
                  question: intl.get(`wallet.faq.bangkok.question3.question`),
                  answer: intl.get(`wallet.faq.bangkok.question3.answer`),
                  extras: (
                      <a className="dmk-link--secondary" href="/delivery-area">
                          {intl.get(`wallet.faq.bangkok.question3.extras`)}
                      </a>
                  ),
              },
              {
                  question: intl.get(`wallet.faq.bangkok.question4.question`),
                  answer: intl.get(`wallet.faq.bangkok.question4.answer`),
              },
              {
                  question: intl.get(`wallet.faq.bangkok.question5.question`),
                  answer: intl.get(`wallet.faq.bangkok.question5.answer`),
              },
          ];
