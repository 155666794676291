import Validator from '../../services/validator';

export const postCodeValidator = new Validator().add(
    'postCode',
    (model) => Boolean(model.postCode),
    'Please give us Postal Code'
);

export const emailValidator = new Validator().add(
    'email',
    (model) => Boolean(model.email),
    'Please give us Email'
);
