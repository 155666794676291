import React, { Component } from 'react';
import intl from 'react-intl-universal';
import SubheaderUI from './SubheaderUI';
import template from 'lodash/template';
import { inject, observer } from 'mobx-react';
import InputUI from '../InputUI';
import ButtonUI from './ButtonUI';
import CountDownClock from '../CountDownClock';
import { PHONE, SIGNUP } from '../../../services/constants';
import TermsAndConditions from '../TermsAndConditions';

const base = 'login-popup';

@inject('loginPopupStore', 'phoneVerificationStore')
@observer
export default class MobileNumberVerifyView extends Component {
    componentDidMount() {
        document.addEventListener('keydown', this.onKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPress);
    }

    handleSubmit = (e) => {
        const { loginPopupStore } = this.props;
        e.preventDefault();
        loginPopupStore.showBusy();

        if (loginPopupStore.comingFrom === SIGNUP) {
            loginPopupStore.completeSignup(PHONE);
        } else {
            loginPopupStore.doLogin(PHONE);
        }
    };

    handleChange = (e) => {
        const { phoneVerificationStore, loginPopupStore } = this.props;

        phoneVerificationStore.handleChange(e);
        loginPopupStore.handleChange(e);
    };

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit(e);
        }
    };

    renderSubheader = () => {
        const { loginPopupStore } = this.props;
        const text = template(
            intl.get('loginPopup.mobileNumberVerifyView.subheading')
        )({
            mobileNumber: loginPopupStore.mobileNumber,
        });

        return <SubheaderUI customWidthPercentage="80%" text={text} />;
    };

    renderInput = () => {
        const { loginPopupStore } = this.props;
        return (
            <InputUI
                name="verificationCode"
                autoComplete="off"
                containerClass={`${base}__input-container`}
                inputClass={`${base}__input`}
                placeholder="Verification code"
                type="number"
                required="true"
                autoFocus="true"
                onChange={this.handleChange}
                value={loginPopupStore.verificationCode}
                errorMessage={loginPopupStore.message}
                customMessage={
                    loginPopupStore.timerRunning ? (
                        <CountDownClock
                            from={loginPopupStore.loginOtpRetryTime}
                            displayFn={(tick) => ' (' + tick + ')'}
                            customMessage="Resend code"
                            onFinish={loginPopupStore.allowResendCode}
                        />
                    ) : (
                        <span
                            className="cursor-pointer"
                            onClick={loginPopupStore.requestOTP}
                        >
                            Resend code
                        </span>
                    )
                }
            />
        );
    };

    renderButton = () => {
        return (
            <ButtonUI
                text={intl.get('loginPopup.mobileNumberView.cta')}
                customClasses="button--success"
                onClick={this.handleSubmit}
            />
        );
    };

    render() {
        const { loginPopupStore } = this.props;

        return (
            <div className={base}>
                <h3 className={`${base}__title lh--150`}>
                    {loginPopupStore.comingFrom === 'Signup'
                        ? intl.get(
                              'loginPopup.mobileNumberVerifyView.signupTitle'
                          )
                        : intl.get(
                              'loginPopup.mobileNumberVerifyView.loginTitle'
                          )}
                </h3>
                {this.renderSubheader()}
                {this.renderInput()}
                {this.renderButton()}
                <TermsAndConditions
                    header={intl.get('termsAndConditions.headerOTP')}
                />
            </div>
        );
    }
}
