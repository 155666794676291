import React from 'react';
import intl from 'react-intl-universal';
import { GetCity } from '../../../services/city';

const city = GetCity();

const base = 'business-form__thank-you';

export default class extends React.Component {
    render() {
        return (
            <div className={base}>
                <h2 className={`${base}__title`}>
                    {intl.get(`business.${city}.thankYou.title`)}
                </h2>
                <p className={`${base}__separator`} />
                <p className="fs--small-medium mtl mbl">
                    {intl.get(`business.${city}.thankYou.subheading`)}
                </p>
                <a href="/menu">
                    <button
                        className={`${base}__cta button button--success button--large button--bigger-on-mobile mbs text--bold`}
                    >
                        {intl.get(`business.${city}.thankYou.cta`)}
                    </button>
                </a>
            </div>
        );
    }
}
