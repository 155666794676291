import React from 'react';
import { inject, observer } from 'mobx-react';
import MenuItemUI from './MenuItemUI';
import { GetDishURL } from '../../../services/url';
import { getStartTime } from '../../../services/dateTime';

@inject('cartStore', 'menuListStore', 'rewardsStore')
@observer
export default class extends React.Component {
    render() {
        const { item, cartStore, discountedPrice, onItemAddedToCart } =
            this.props;
        const availableAt =
            item && item.available_at && getStartTime(item.available_at);
        const redirectURL = item && GetDishURL(item);

        let itemCount = 0;
        if (cartStore.itemsInCart.length) {
            const itemforDate = cartStore.itemsInCart.find(
                (menuItem) => menuItem.id === item.id
            );
            itemCount = itemforDate ? itemforDate.quantity : 0;
        }

        return (
            <MenuItemUI
                redirectURL={redirectURL}
                item={item}
                itemCount={itemCount}
                price={item.price}
                tag={item.tag}
                availableAt={availableAt}
                onAddToCart={onItemAddedToCart}
                discountedPrice={discountedPrice}
                ratingText={item.rating_text}
                showThumbForRatingText={item.show_thumb_for_rating_text}
            />
        );
    }
}
