import request from 'superagent';
import { handleResponse, httpPut } from '../http';
import { services } from '../index';

// eslint-disable-next-line no-undef
export const SubmitBusinessForm = (
    requestBody = {
        company,
        email,
        name,
        phone_number,
        number_of_people,
        event_date,
        type,
    }
) => {
    //eslint-disable-line
    return httpPut('/v4/web/form/business', requestBody);
};

export const CreateFreshDeskRequest = (requestBody) => {
    return new Promise((resolve, reject) => {
        request
            .post(services.getParam('freshDeskSupportUrl'))
            .type('application/json')
            .send(JSON.stringify(requestBody))
            .end(handleResponse(resolve, reject, false));
    });
};
