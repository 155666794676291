import React from 'react';
import { inject, observer } from 'mobx-react';
import template from 'lodash/template';
import { fromTimestamp } from '../../../services/dateTime';

import intl from 'react-intl-universal';

const base = 'order-status-banner';

@inject('orderStatusStore')
@observer
export default class extends React.Component {
    render() {
        const { orderStatusStore } = this.props;
        let tta = orderStatusStore.tta;
        tta = tta > 0 ? tta : 1;

        let line1 = 'line1';
        let line2 = 'line2';
        const s = tta === 1 ? '' : 's';

        if (tta > 45) {
            tta = fromTimestamp(orderStatusStore.eta).toString('h:mm TT');
            line1 = 'line1alt';
            line2 = 'line2alt';
        }

        return (
            <div className={`${base}__content ${base}__content--delivering`}>
                <p className={`${base}__text`}>
                    {intl.get(`orderStatus.delivering.${line1}`)}
                </p>
                <p className={`${base}__text`}>
                    {template(intl.get(`orderStatus.delivering.${line2}`))({
                        eta: tta,
                        s: s,
                    })}
                </p>
            </div>
        );
    }
}
