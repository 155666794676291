import applicationAPI from './api';

import uaParser from 'ua-parser-js';

import headerScrollEvents from './events/headerScrollEvents';
import videoResizeEvents from './events/videoResizeEvents';
import orientationChangeEvents from './events/orientationChangeEvents';
import videoCapabilityEvents from './events/videoCapabilityEvents';
import bannerEvents from './events/bannerEvents';
import Validator from './validator';
// import { TranslationHelper } from './translation';
import parameters from '../configs/parameters'; //eslint-disable-line import/no-unresolved
import pagetitles from './pagetitles/pagetitles';
// import Cookies from 'js-cookie';

import config from '../configs/parameters';

export const getParam = (key) => {
    return parameters[key];
};

export const setParam = (key, value) => {
    return (parameters[key] = value);
};

export const getTitle = (key) => {
    return pagetitles[key];
};

export const parseUA = (userAgentString) => {
    return uaParser(userAgentString);
};

export const getCardType = (cardType) => {
    switch (cardType) {
        case 'visa':
            return 'Visa Card';
        case 'master-card':
            return 'Mastercard';
        case 'american-express':
            return 'American Express';
        case 'maestro':
            return 'Maestro';
        case 'diners-club':
            return 'Diners Card';
        case 'discover':
            return 'Discover Card';
        case 'jcb':
            return 'JCB Card';
        default:
            return '';
    }
};

export const truncateDecimal = (
    decValue,
    numberOfDecimals = 2,
    removeTralingZeroes = true
) => {
    const multiplier = Math.pow(10, numberOfDecimals);
    const base = Math.round(decValue * multiplier);
    const result = base / multiplier;

    if (isNaN(result)) {
        return result;
    }

    if (Number.isInteger(result) && removeTralingZeroes) {
        return parseFloat(
            (base / multiplier).toFixed(numberOfDecimals)
        ).toString();
    }

    return (base / multiplier).toFixed(numberOfDecimals);
};

export const currency = {
    localCurrency: (moneyValue, removeTrailingZeroes = true) => {
        const country = config.country;

        if (country === 'th') {
            const floatVal = parseFloat(moneyValue);

            if (isNaN(floatVal) === false) {
                return `THB ${truncateDecimal(
                    floatVal || 0,
                    0,
                    removeTrailingZeroes
                )}`;
            }

            return moneyValue;
        } else if (country === 'my') {
            const floatVal = parseFloat(moneyValue);

            if (isNaN(floatVal) === false) {
                return `RM ${truncateDecimal(
                    floatVal || 0,
                    2,
                    removeTrailingZeroes
                )}`;
            }

            return moneyValue;
        }
        return null;
    },

    to2digits: (moneyValue) => {
        return truncateDecimal(moneyValue, 2, false);
    },
};

export const multi = (...fns) => {
    return (...args) => {
        [...fns].forEach((fn) => fn(...args));
    };
};

export const stripRepeatedCommas = (str) => {
    return (str || '')
        .split(', ')
        .filter((x) => x)
        .join(', ');
};

const MEAL_WEIGHT = { Lunch: 1, Dinner: 2 };
export const sortMeal = (mealTime1, mealTime2) => {
    return MEAL_WEIGHT[mealTime1] > MEAL_WEIGHT[mealTime2];
};

// //language setting logic
// if (typeof window !== 'undefined') {
//     if (window.location.pathname === '/ride' && Cookies.get('ridepage') !== 'english') {
//         global.trans = new TranslationHelper('ms')
//     }
//     else {
//         global.trans = new TranslationHelper('en')
//     }
// }
// else {
//     global.trans = new TranslationHelper('en')
// }

const api = applicationAPI;
// Allowed globals
// global.resource = resource;
export const services = {
    setParam,
    getParam,
    getTitle,
    headerScrollEvents,
    bannerEvents,
    videoResizeEvents,
    orientationChangeEvents,
    videoCapabilityEvents,
    Validator,
    api,
};

// export const dahmakanApi = applicationAPI;
