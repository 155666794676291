import React from 'react';
import { stripRepeatedCommas } from '../../../services';

const base = 'profile-address__item';

export default ({ address, onAddressClicked }) => {
    const typeDisplay =
        address['address_type'] === 'Else'
            ? address['location_type']
            : address['address_type'];

    return (
        <div
            className={`${base} display--flex flex-align-items-center cursor-pointer`}
            onClick={onAddressClicked.bind(null, address)}
        >
            <div className={`${base}__address-wrapper flex-1`}>
                <p className="mbs">{typeDisplay}</p>
                <p className="fs--small-medium lh--150 ellipsis">
                    {stripRepeatedCommas(address['address'])}
                </p>
            </div>
        </div>
    );
};
