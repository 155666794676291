import React from 'react';
import { Provider } from 'mobx-react';

import TerminalMenuStore from '../store/terminalMenuStore';
import DishHeaderStore from '../store/dishHeaderStore';
import menuHeaderStore from '../store/menuHeaderStore';

import BaseLayout from './core/baseLayout/Master_BaseLayout';
import MealSelection from './page/terminalPage/MealSelection';

const dishHeaderStore = new DishHeaderStore();

const stores = {
    dishHeaderStore,
    menuHeaderStore,
    terminalMenuStore: TerminalMenuStore,
};



export default class extends React.Component {
    render() {
        return (
            <BaseLayout.Page>
                <main>
                    <Provider {...stores}>
                        <MealSelection />
                    </Provider>
                </main>
            </BaseLayout.Page>
        );
    }
}
