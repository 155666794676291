import React from 'react';
import WalletBenefit from './WalletBenefit';

export default ({ packages }) => {
    const base = 'wallet';

    return (
        <div
            className={`${base}__section ${base}__section-packages`}
            id={`${base}__section-packages`}
        >
            <div className="display--flex">
                <div className={`${base}__benefit-wrapper`}>
                    <h1 className="mbl text--bolder">Select a topup value</h1>
                    {packages.map((option, index) => (
                        <WalletBenefit
                            key={index}
                            {...option}
                            mostPopular={index === 1 ? true : false}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
