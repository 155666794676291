import React from 'react';
import { currency } from '../../../services';
import { fromTimestamp } from '../../../services/dateTime';
import { inject, observer } from 'mobx-react';
import { multi } from '../../../services';

const base = 'order-history';

@inject('orderHistoryStore', 'orderStatusStore')
@observer
export default class extends React.Component {
    getStatusColor = (status) => {
        const lcStatus = status.toLowerCase();

        switch (lcStatus) {
            case 'pending':
                return 'text--blue';
            case 'intransit':
            case 'driverarrived':
                return 'text--primary';
            case 'delayed':
                return 'text--orange';
            case 'cancelled':
                return 'text--red';
            case 'delivered':
                return 'text--primary';
            default:
                return '';
        }
    };

    getStatusText = (status) => {
        const lcStatus = status.toLowerCase();

        switch (lcStatus) {
            case 'pending':
                return 'Confirmed';
            case 'driverarrived':
                return 'Driver Arrived';
            default:
                return status;
        }
    };

    getDate = (order) => {
        return fromTimestamp(order['time_slot_start']).toString(
            'dddd, dd/MM/yyyy'
        );
    };

    getTime = (order) => {
        const lcStatus = order.status.toLowerCase();
        const startTime = order['time_slot_start'];
        const endTime = order['time_slot_end'];
        const eta = order['eta'];

        switch (lcStatus) {
            case 'delivered':
            case 'intransit':
            case 'driverarrived':
                return eta
                    ? fromTimestamp(eta).toString('hh:mm TT')
                    : fromTimestamp(startTime).toString('hh:mm TT');
            case 'cancelled':
                return '-';
            default:
                return (
                    fromTimestamp(startTime).toString('hh:mm TT') +
                    ' - ' +
                    fromTimestamp(endTime).toString('hh:mm TT')
                );
        }
    };

    getTotal = (order) => {
        return currency.localCurrency(order.net_total);
    };

    handleclick(order, e) {
        const { orderHistoryStore } = this.props;
        orderHistoryStore.setShowingDetail(true);
    }

    render() {
        const { orderStatusStore, lastRow, index } = this.props;
        const order = this.props.order;
        const statusClasses = this.getStatusColor(order.status);
        const status = order.status;
        const orderNumber = '#' + order.order_id;
        const statusDisplay = this.getStatusText(status);
        const dateDisplay = this.getDate(order);
        const timeDisplay = this.getTime(order);
        const totalDisplay = this.getTotal(order);

        const clickEvent = order.id
            ? ::orderStatusStore.setShowingDetail(true)
            : null;
        const setOrder = order.id
            ? ::orderStatusStore.setOrderId(order.id)
            : null;
        const addRoundedBorder = index === lastRow;

        return (
            <div
                className={`${base}__table-row fs--small-medium cursor-pointer ${
                    addRoundedBorder ? 'border--rounded' : ''
                }`}
                onClick={multi(clickEvent, setOrder)}
            >
                <div className="row show--if-large hide--if-small">
                    <div
                        className={`col-lg-2 col-md-2 col-sm-2 col-xs-2 ${statusClasses}`}
                    >
                        {statusDisplay}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        {dateDisplay}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 text--sofiapro">
                        {timeDisplay}
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 text--gray">
                        {orderNumber}
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 plxl p0--if-small">
                        {totalDisplay}
                    </div>
                </div>
                <div className="row show--if-small hide--if-large">
                    <div className="col-xs-8">
                        <p className={`${statusClasses} lh--150`}>
                            {statusDisplay}
                        </p>
                        <p className="lh--150">{dateDisplay}</p>
                        <p className="lh--150 text--sofiapro">{timeDisplay}</p>
                    </div>
                    <div className="col-xs-4">
                        <p className="lh--150">
                            <br />
                        </p>
                        <p className="text--gray lh--150">{orderNumber}</p>
                        <p className="lh--150 text--sofiapro">{totalDisplay}</p>
                    </div>
                </div>
            </div>
        );
    }
}
