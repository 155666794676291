import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import ListPaymentMethods from './ListPaymentMethods';
import AddPaymentMethod from './AddPaymentMethod';
import AddCreditCard from './AddCreditCard';
import ShowPaymentMethod from '../shoppingCartPage/ShowPaymentMethod';

@inject('paymentStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.paymentStore.loadPaymentMethods();
    }

    render() {
        const base = 'payment';
        const { paymentStore } = this.props;

        return (
            <div className={`${base} well`}>
                <p className="text--bolder flex-1 lh--150">
                    {intl.get('packageCheckout.payment.title')}
                </p>
                <div className="mtm text--gray m0--if-small">
                    {paymentStore.view === paymentStore.SHOW_VIEW ? (
                        <ShowPaymentMethod />
                    ) : null}
                    {paymentStore.view === paymentStore.LIST_VIEW ? (
                        <ListPaymentMethods />
                    ) : null}
                    {paymentStore.view === paymentStore.ADD_PAYMENT ? (
                        <AddPaymentMethod />
                    ) : null}
                    {paymentStore.view === paymentStore.ADD_CREDIT_CARD ? (
                        <AddCreditCard
                            onCancel={::paymentStore.goToView(
                                paymentStore.ADD_PAYMENT
                            )}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}
