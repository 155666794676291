import { toJS } from 'mobx';
import WalletPaymentStore from './walletPaymentStore';
import { services } from '../services/index';
import Cookies from 'js-cookie';

export default class extends WalletPaymentStore {
    loadPaymentMethods() {
        this.isBusy = true;
        const boostUser = Cookies.get('boost_user');

        services.api
            .GetPaymentMethods()
            .then((cards) => {
                if (boostUser) {
                    const stubBoost = {
                        id: 'boost',
                        card_type: 'Boost',
                        payment_method_type: 'Boost',
                        description:
                            'You will be redirected to the Boost app to complete payment',
                    };

                    if (cards) {
                        cards = cards.filter(
                            (card) => card['payment_method_type'] === 'Prime'
                        );
                    }
                    cards.push(stubBoost);
                }

                this.paymentList = cards.filter(
                    (card) => card['card_type'] !== 'Prime'
                );
                const isCODPresent = cards.find(
                    (card) => card['card_type'] === 'Cash'
                );
                if (!isCODPresent) {
                    this.paymentList.push({
                        id: 'cod-temp',
                        card_type: 'Cash',
                        payment_method_type: 'Cash',
                        description: 'Pay directly to the driver.',
                    });
                }
            })
            .then(() => {
                const list = toJS(this.paymentList);
                console.log;
                this.onCardsLoaded$.push(list);
                this.onCardsLoaded$.end();
            })
            .catch((responseData) => {
                this.message = responseData.message;
            })
            .then(() => {
                this.isBusy = false;
            });
    }
}
