import React from 'react';
import { observer, inject } from 'mobx-react';

import LoginPopup from '../../core/LoginPopup';
import SectionHero from './SectionHero';
import SectionMap from './SectionMap';
import SectionVote from './SectionVote';
import HalalPopup from '../../core/HalalPopup';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

@inject('userStore')
@observer
export default class extends React.Component {
    render() {
        const isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
        const { showHalalPopup, setHideHalalPopup } = this.props.userStore;
        return (
            <div>
                <LoginPopup dismissable={true} showCloseIcon={true} />
                <SectionHero />
                <SectionMap />
                <SectionVote />
                {showHalalPopup && isSmallScreen && (
                    <HalalPopup
                        showModal={showHalalPopup}
                        handleClose={setHideHalalPopup}
                    />
                )}
            </div>
        );
    }
}
