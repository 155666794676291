import React from 'react';

import { services } from '../../../services/index';

const appStorePath = services.getParam('appStorePath');
const googlePlayPath = services.getParam('googlePlayPath');
const facebookLink = services.getParam('facebookLink');
const instagramLink = services.getParam('instagramLink');
const twitterLink = services.getParam('twitterLink');

export default () => {
    const base = 'wallet-footer';

    return (
        <div className={base}>
            <div
                className={`${base}__container container display--flex flex-align-items-center`}
            >
                <div className={`${base}__apps flex-1-only`}>
                    <a href={appStorePath} className="mrm">
                        <img
                            className={`${base}__app-button download-app--small cursor-pointer`}
                            src="https://d3tjqo2jh935k9.cloudfront.net/images/static/app-store-black.png"
                            alt="app store link"
                        />
                    </a>
                    <a href={googlePlayPath}>
                        <img
                            className={`${base}__app-button download-app--small cursor-pointer`}
                            src="https://d3tjqo2jh935k9.cloudfront.net/images/static/google-play-black.png"
                            alt="play store link"
                        />
                    </a>
                </div>
                <div className={`${base}__socials flex-1-only text--right`}>
                    <a className="prs" href={facebookLink}>
                        <svg className={`${base}__social-icon iconmoon-icon`}>
                            <use xlinkHref="#icon-facebook-square" />
                        </svg>
                    </a>
                    <a className="prs" href={twitterLink}>
                        <svg className={`${base}__social-icon iconmoon-icon`}>
                            <use xlinkHref="#icon-twitter" />
                        </svg>
                    </a>
                    <a href={instagramLink}>
                        <svg className={`${base}__social-icon iconmoon-icon`}>
                            <use xlinkHref="#icon-instagram" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
};
