import React from 'react';

export default function ArrowDown({ width, height, color, manualStyles }) {
    return (
        <svg
            width={`${width}`}
            height={`${height}`}
            viewBox="0 0 9 11"
            fill={`${color ? color : '#131415'}`}
            xmlns="http://www.w3.org/2000/svg"
            style={manualStyles}
        >
            <path d="M8.39705 4.67693L8.85467 5.22196C9.04844 5.45274 9.04844 5.82591 8.85467 6.05423L4.8494 10.8269C4.65563 11.0577 4.3423 11.0577 4.1506 10.8269L0.145327 6.05669C-0.0484432 5.82591 -0.0484432 5.45274 0.145327 5.22442L0.602954 4.67939C0.798786 4.44616 1.1183 4.45107 1.31001 4.68921L3.67442 7.64513L3.67442 0.589219C3.67442 0.262693 3.89498 -5.18847e-07 4.16915 -5.18847e-07H4.82879C5.10295 -5.18847e-07 5.32352 0.262693 5.32352 0.589219L5.32352 7.64513L7.68999 4.68675C7.8817 4.44616 8.20121 4.44125 8.39705 4.67693V4.67693Z" />
        </svg>
    );
}
