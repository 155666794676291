import { httpGet, httpPut, httpPost } from '../http';
import { version } from '../../../package.json';
export const GetTerminalInfo = ({ bearerToken }) => {
    return httpGet('/v4/terminal', { authType: 'Bearer', bearerToken });
};

export const UpdateTerminalInfo = (requestBody) => {
    return httpPut('/v4/terminal', requestBody, { authType: 'Bearer' });
};

export const PlaceTerminalOrder = (requestBody) => {
    return httpPost('/v4/terminal/order', requestBody, { authType: 'Bearer' });
};

export const CheckCouponValidity = (requestBody) => {
    return httpPost('/v2/pos/check-code-validity', requestBody, {
        authType: 'Bearer',
    });
};

export const CheckPaymentStatus = (requestBody) => {
    return httpGet(`/v2/pos/payment-status/${requestBody.orderNumber}`, {
        authType: 'Bearer',
    });
};

export const EwalletStatusCheck = (requestBody) => {
    return httpGet(`/v2/pos/ewallet/status`, {
        authType: 'Bearer',
    });
};

export const RefreshQRCode = ({ orderNumber, bearerToken }) => {
    return httpPost(
        `/v2/pos/refresh-qr-code/${orderNumber}`,
        {},
        {
            authType: 'Bearer',
        }
    );
};

export const CashPaymentReorder = ({ orderNumber }) => {
    return httpPost(`/v2/pos/change-to-cash-payment/${orderNumber}`, {}, { authType: 'Bearer' });
};

export const PosPricesUpdate = ({ requestBody, bearerToken }) => {
    return httpPost('/v2/pos/prices', requestBody, {
        authType: 'Bearer',
        bearerToken,
        additionalHeaders: { "Version": version }
    });
};

export const UpdateInCartOrder = ({ requestBody, bearerToken }) => {
    return httpPost('/v2/pos/in-cart/orders', requestBody, {
        authType: 'Bearer',
        bearerToken,
        additionalHeaders: { "Version": version }
    });
};

export const ValidateCoupon = (requestBody) => {
    return httpPost('/v2/pos/validate-order-reward', requestBody.body, {
        authType: 'Bearer',
    });
};

export const SendOTP = (requestBody) => {
    return httpPost('/v2/pos/verify-otp', requestBody.body, {
        authType: 'Bearer',
    });
};
export const ResendOTP = (requestBody) => {
    return httpPost('/v2/pos/resend-otp', requestBody.body, {
        authType: 'Bearer',
    });
};

export const PlaceTerminalOrderViaPos = ({ requestBody, bearerToken, isMobileKioskOrder }) => {
    return httpPost('/v2/pos/order', requestBody, {
        authType: 'Bearer',
        bearerToken,
        additionalHeaders: isMobileKioskOrder ? { 'X-User-Agent': 'mobile-kiosk', "Version": version } : { "Version": version },
    });
};

export const GetAccountExistInfo = ({ phoneNo, bearerToken }) => {
    return httpGet(`/v4/terminal/name-by-phone-number/${phoneNo}`, {
        authType: 'Bearer',
        bearerToken,
    });
};

export const GetDealComboOption = ({ bearerToken, orderType, hubCode }) => {
    return httpGet(`/v9/menus/combo-options?imageDimension=Small&hubCode=${hubCode}&t=${Date.now()}`, {
        authType: 'Bearer',
        bearerToken,
        additionalHeaders: {
            'X-Order-Mode': orderType == 'TAKE_AWAY' ? 'PICK_UP' : orderType,
        },
    });
};

export const SendLogToBackend = (requestBody) => {
    return httpPost('/v1/admin/log', requestBody, { authType: 'Bearer' });
};

export const RewardRedeemCode = (requestBody) => {
    return httpPost('/v4/terminal/reward', requestBody, {
        authType: 'Bearer',
    });
};

export const GetPendingOrderTransaction = ({ requestBody, bearerToken }) => {
    return httpPost('/v2/pos/pending-transaction', requestBody, {
        authType: 'Bearer',
        bearerToken
    });
};

export const CancelReaderAction = ({ requestBody, bearerToken }) => {
    return httpPost('/v2/pos/cancel-action', requestBody, {
        authType: 'Bearer',
        bearerToken
    });
};
