import { when } from 'mobx';

export default (
    creditCardStore,
    paypalStore,
    store,
    rewardsStore,
    paymentStore,
    completeCheckoutStore,
    headerStore
) => {
    /*
     * Every time a card is added:
     * ----- Add that card to the list
     * ----- Set the card as the current one
     * ----- Go back to root view
     * ----- Reset the add credit card form
     */
    creditCardStore.onCreditCardAdded$.onValue((newCard) => {
        paymentStore.addPaymentMethod(newCard);
        store.setPaymentMethod(newCard);
        paymentStore.goToView(paymentStore.SHOW_VIEW)();
        creditCardStore.reset();
    });

    /*
     * Every time Paypal card is added:
     * ----- Add that card to the list
     * ----- Set the card as the current one
     * ----- Go back to root view
     * ----- Reset the Paypal button
     */
    paypalStore.onPaypalAdded$.onValue((newPaypal) => {
        paymentStore.addPaymentMethod(newPaypal);
        store.setPaymentMethod(newPaypal);
        paymentStore.goToView(paymentStore.SHOW_VIEW)();
        paypalStore.reset();
    });

    /*
     * Every time Order Summary is loaded
     * RewardsStore needs to know about the amount because amount is required to calculate discount percentage
     */
    store.onOrderSummaryLoaded$.onValue((orderSummary) => {
        rewardsStore.setTotalAmount(orderSummary['sub_total']);
    });

    /*
     * Every time we apply a discount code
     * Valid or not we need to
     * ----- Give the discountCode to main store because it's needed to render Order Summary
     * ----- Refresh order summary again
     */
    rewardsStore.onGetDiscountResultWallet$.onValue((discountResult) => {
        store.getOrderSummary();
        // const discountCodeApplied = discountResult && discountResult['is_valid'] === true;
        // const discountCodeRemoved = discountResult === null;

        // if (discountCodeApplied || discountCodeRemoved) {
        //     store.setDiscountCode(rewardsStore.code);
        //     store.getOrderSummary();
        // }
    });

    /*
     * Every time user's card list is loaded
     * We set the selecting card to be the first one of the list.
     */
    paymentStore.onCardsLoaded$.onValue(() => {
        store.setPaymentMethod(paymentStore.getFirstPaymentMethod());
    });

    /*
     * When user has finished updating profile, we allow him to place order
     */
    when(
        () => completeCheckoutStore.hasUserUpdatedProfile === true,
        () => {
            store.hasUserUpdatedProfile = true;
        }
    );

    /*
     * When placing an order fails and we go back to CheckoutView, we need to show the 'Go back to cart' link in the header
     */
    store.onPlacingOrderFailed$.onValue(() => {
        headerStore.setShowBackLink(true);
    });
};
