import React from 'react';
import { inject, observer } from 'mobx-react';

import intl from 'react-intl-universal';
import SubheaderUI from './SubheaderUI';
import InputUI from '../InputUI';
import ButtonUI from './ButtonUI';
import { EMAIL } from '../../../services/constants';
import TermsAndConditions from '../TermsAndConditions';

const base = 'login-popup';

@inject('loginPopupStore')
@observer
export default class extends React.Component {
    handleSubmitPassword(e) {
        e.preventDefault();

        const { loginPopupStore } = this.props;

        if (loginPopupStore.password.length < 6) {
            loginPopupStore.message = intl.get(
                'loginPopup.completeSignUp.length'
            );
        } else {
            loginPopupStore.message = '';
            loginPopupStore.showBusy();
            loginPopupStore.completeSignup(EMAIL);
        }
    }

    renderSubheader = () => {
        return (
            <SubheaderUI
                text={intl.get('loginPopup.completeSignUp.subtitle')}
            />
        );
    };

    renderInput = () => {
        const { loginPopupStore } = this.props;

        return (
            <InputUI
                autoFocus="true"
                containerClass={`${base}__input-container`}
                inputClass={`${base}__input`}
                placeholder={intl.get('loginPopup.completeSignUp.password')}
                type="password"
                value={loginPopupStore.password}
                onChange={::loginPopupStore.setPassword}
                errorMessage={loginPopupStore.message}
            />
        );
    };

    renderButton = () => {
        return (
            <ButtonUI
                customClasses="button--success"
                text={intl.get('loginPopup.completeSignUp.cta')}
            />
        );
    };

    render() {
        return (
            <div className={base}>
                <h3 className={`${base}__title`}>
                    {intl.get('loginPopup.completeSignUp.title')}
                </h3>
                <form method="POST" onSubmit={::this.handleSubmitPassword}>
                    {this.renderSubheader()}
                    {this.renderInput()}
                    {this.renderButton()}
                    <TermsAndConditions
                        header={intl.get('termsAndConditions.headerPassword')}
                    />
                </form>
            </div>
        );
    }
}
