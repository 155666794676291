import React from 'react';
import { inject, observer } from 'mobx-react';

const base = 'terminal-header';

@inject('terminalMenuStore')
@inject('terminalCartStore')
@observer
export default class extends React.Component {
    render() {
        const {
            terminalMenuStore: { showSplashScreen, showLuckyWheelScreen, comboObject, totalComboList, setItemsUIView },
            terminalCartStore,
            type,
        } = this.props;
        const notShowHeaderOnIpad = showSplashScreen || showLuckyWheelScreen;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let id = decodeURIComponent(urlParams.get('id'));

        let itemsCheck = terminalCartStore.itemsAdded?.filter((o) => {
            if (o.type == 'combo') {
                return o.id == id;
            }
        });

        return (
            !notShowHeaderOnIpad && (
                <div className={`${base}`} id={base}>
                    <div
                        style={{ alignItems: 'center' }}
                        className="display--flex"
                        onClick={(e) => {
                            history.back();
                            //setItemsUIView('Deals');
                        }}
                    >
                        <img src="/back-arrow.png" />

                        <span
                            style={{
                                fontSize: '18px',
                                marginLeft: '20px',
                                fontWeight: 600,
                            }}
                        >
                            Back
                        </span>
                    </div>
                    {type != 'comboitems' ? (
                        <div
                            style={{
                                alignItems: 'center',
                                marginTop: '20px',
                                marginRight: '10px',
                            }}
                            className="display--flex"
                        >
                            <span
                                style={{
                                    color: '#FEC700',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    marginRight: '20px',
                                }}
                            >
                                Total:{' '}
                                {totalComboList && totalComboList[comboObject['id']]
                                    ? totalComboList[comboObject['id']].totalPrice
                                    : null}
                            </span>
                            <div
                                onClick={(e) => {
                                    terminalCartStore.toggleCombo = true;
                                    if (
                                        totalComboList &&
                                        totalComboList[comboObject['id']] &&
                                        totalComboList[comboObject['id']].validation
                                    ) {
                                        terminalCartStore.addComboCart({
                                            add: itemsCheck.length > 0 ? false : true,
                                        });

                                        history.back();
                                        terminalCartStore.getTotalPrice();
                                        //setItemsUIView('Deals');
                                    } else {
                                        terminalCartStore.addComboCart({
                                            validate: true,
                                            add: true
                                        });
                                    }
                                }}
                                style={{
                                    opacity:
                                        totalComboList &&
                                        totalComboList[comboObject['id']] &&
                                        totalComboList[comboObject['id']].validation
                                            ? 1
                                            : 0.5,
                                }}
                                className={`${base}__add-btn`}
                            >
                                {itemsCheck.length > 0 ? 'Done' : 'Add to cart'}
                            </div>
                        </div>
                    ) : null}
                </div>
            )
        );
    }
}
