import React from 'react';

const base = 'profile-address__item';

export default ({ timeframe, onTimeframeClicked, isDisabled }) => {
    if (isDisabled) {
        return (
            <div
                className={`${base} ${base}-disabled display--flex flex-align-items-center`}
                style={{ color: '#cdcdcd' }}
            >
                <div className={`${base}__address-wrapper flex-1`}>
                    <p className="fs--small-medium lh--150 ellipsis">
                        {timeframe.display_text}
                    </p>
                </div>
            </div>
        );
    }
    return (
        <div
            className={`${base} display--flex flex-align-items-center cursor-pointer`}
            onClick={onTimeframeClicked.bind(null, timeframe)}
        >
            <div className={`${base}__address-wrapper flex-1`}>
                <p className="fs--small-medium lh--150 ellipsis">
                    {timeframe.display_text}
                </p>
            </div>
        </div>
    );
};
