import React from 'react';
import intl from 'react-intl-universal';

import AddonItem from './Items/AddonItem';
const base = 'terminal-addon-items';

export default function AddonItems({ items, itemsUIView, onAddToCart, comboItem }) {
  const itemsToShow = items.filter((item) => {
    if (itemsUIView == 'Addons') {
      return item.addon_category == 'Snacks' || item.addon_category == 'Desserts';
    } else {
      return item.addon_category == itemsUIView;
    }
  });
  const showMessage = !itemsToShow || itemsToShow.length === 0;

  return (
    <div className={`${base} ${showMessage ? 'show-message' : ''}`}>
      {showMessage && (
        <p className={`${base}__message`}>
          {intl.get('terminal.addonItems.notAvailableMessageLine1')} <br />{' '}
          {intl.get('terminal.addonItems.notAvailableMessageLine2')}
        </p>
      )}
      <div className={`${base}__items`}>
        {itemsToShow &&
          itemsToShow.length > 0 &&
          itemsToShow.map((item) => (
            <AddonItem
              key={item.menu_item_id}
              comboItem={comboItem}
              itemsUIView={itemsUIView}
              item={item}
              onAddToCart={onAddToCart}
            />
          ))}
      </div>
    </div>
  );
}
