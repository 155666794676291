export const rewardToApply = () =>
    sessionStorage.getItem('discount_code_to_apply');

export const setRewardToApply = (code) =>
    sessionStorage.setItem('discount_code_to_apply', code);

export const removeRewardToApply = () =>
    sessionStorage.removeItem('discount_code_to_apply');

export const showRewardsToApplyBanner = () =>
    localStorage.setItem('show_reward_applied_banner', true);

export const clearRewardsToApplyBanner = () =>
    localStorage.removeItem('show_reward_applied_banner');
