const getSingletonMap = () => {
    if (typeof window !== 'undefined') {
        if (!window.$$memoizerMap) {
            window.$$memoizerMap = new Map();
        }

        return window.$$memoizerMap;
    }

    return new Map();
};

const identity = (x) => x;
export default (name, fn, cacheFn = identity, context) => {
    const internalMap = getSingletonMap();

    return {
        run: (...args) => {
            const hashKey = String(cacheFn.call(context, ...args));
            const hasValueInCache = internalMap.has(name + hashKey);

            if (!hasValueInCache) {
                const realResult = fn.call(context, ...args);
                internalMap.set(name + hashKey, realResult);

                return realResult;
            }

            return internalMap.get(name + hashKey);
        },
        runFresh: (...args) => {
            const realResult = fn.call(context, ...args);
            const hashKey = String(cacheFn.call(context, ...args));
            internalMap.set(name + hashKey, realResult);

            return realResult;
        },
    };
};
