import React, { Fragment } from 'react';
import { when } from 'mobx';
import { Provider, inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import CalendarStore from '../store/calendarStore';
import menuHeaderStore from '../store/menuHeaderStore';
import cartStore from '../store/cartStore';
import menuListStore from '../store/menuListStore';
import setUpStoreInteractions from '../store/interactions/menuInteractions';
import qs from 'query-string';
import BaseLayout from './core/baseLayout/Master_BaseLayout';
import MenuHeader from './core/headers/MenuHeader';
import MenuFooter from './core/MenuFooter';
import { SMALL_SCREEN_MEDIA } from '../services/constants';
import Referral from './page/menuPage/Referral';
import LoginPopup from './core/LoginPopup';
import Loading from './core/Loading';

const { clevertap } = window;

const calendarStore = new CalendarStore();

const headerStores = {
    menuHeaderStore,
};
const stores = {
    menuHeaderStore,
    calendarStore,
};

setUpStoreInteractions(
    calendarStore,
    menuListStore,
    cartStore,
    menuHeaderStore
);

const Header = () => (
    <Provider {...headerStores}>
        <MenuHeader hideBranchBanner onboarding />
    </Provider>
);

@inject('loginPopupStore', 'rewardsStore', 'menuCheckoutStore', 'userStore')
@observer
class ReferralRoot extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showReferralOnboarding: false,
        };
    }

    componentDidMount() {
        const { history, location, loginPopupStore, userStore } = this.props;
        const { reward, rewardIsPercentage, rewardUsages, ...rest } = qs.parse(
            location.search
        );

        when(
            () => userStore.user?.data,
            () => {
                const userIsLoggedIn = userStore.user.data.id;

                if (reward) {
                    const referral = {
                        reward: parseInt(reward),
                        rewardIsPercentage: JSON.parse(
                            rewardIsPercentage.toLowerCase()
                        ),
                        rewardUsages: parseInt(rewardUsages),
                        ...rest,
                    };
                    loginPopupStore.setReferral(referral);

                    if (userIsLoggedIn) {
                        loginPopupStore.handleReferral(referral);
                        history.push('/menu');
                    } else {
                        loginPopupStore.isShowing = true;
                        this.setState({ showReferralOnboarding: true });
                        this.handleEventTracking();
                    }
                } else {
                    history.push('/menu');
                }
            }
        );
    }

    handleEventTracking = () => {
        clevertap.event.push('See Referral Signup Modal', {
            Variant: 'Popup On Referral Page With Input Bar',
        });

        clevertap.event.push('View Page', {
            'Page Title': document.title,
            'Page Type': 'Menu',
            'Page URL': window.location.href,
        });
    };

    render() {
        const { showReferralOnboarding } = this.state;
        const { loginPopupStore } = this.props;
        const stickyFooter = window.matchMedia(SMALL_SCREEN_MEDIA).matches
            ? false
            : true;

        return (
            <Provider {...stores}>
                <BaseLayout.Page HeaderComponent={Header}>
                    <Fragment>
                        <main className="p0--if-small">
                            {showReferralOnboarding ? (
                                <Fragment>
                                    {loginPopupStore.isShowing && (
                                        <LoginPopup dismissable showCloseIcon />
                                    )}
                                    <Referral />
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </main>
                        <MenuFooter sticky={stickyFooter} />
                    </Fragment>
                </BaseLayout.Page>
            </Provider>
        );
    }
}

export default withRouter(ReferralRoot);
