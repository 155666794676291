import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { services } from '../../../services/index';

import PaymentMethodType from '../../core/PaymentMethodType';

@inject('paymentStore')
@inject('paypalStore')
@inject('store')
@observer
export default class extends React.Component {
    chooseOnlineBanking = () => {
        const { paymentStore, store } = this.props;

        let stubOnlineBanking;
        if (services.getParam('paymentGateway') === 'omise') {
            // show the selector
            this.props.store.showBankSelector = true;
            stubOnlineBanking = {
                id: 'billplz-temp',
                card_type: 'Online Banking',
                payment_method_type: 'Omise_Bank',
                description:
                    'You will be redirected to complete checkout with FPX payment.',
            };
        } else {
            stubOnlineBanking = {
                id: 'billplz-temp',
                card_type: 'BillPlz',
                description:
                    'You will be redirected to complete checkout with FPX payment.',
                fpx_gateway: 'BillPlz',
            };
        }

        paymentStore.addPaymentMethod(stubOnlineBanking);
        store.setPaymentMethod(stubOnlineBanking);
        paymentStore.goToView(paymentStore.SHOW_VIEW)();
    };

    choosePaypal = () => {
        this.props.paypalStore.doAddPaypal();
    };

    componentDidMount() {
        this.props.paypalStore.initPaypal();
    }

    componentWillUnmount() {
        this.props.paypalStore.reset();
    }

    renderPaypalButton = () => {
        const base = 'add-payment-method';
        const { paypalStore } = this.props;

        if (paypalStore.isBusy) {
            return (
                <li className="mts mbm">
                    <button className={`button button--busy ${base}__button`}>
                        <span className="pts pbs">
                            {' '}
                            {intl.get('packageCheckout.payment.paypalInit')}
                        </span>
                    </button>
                </li>
            );
        }

        return (
            <PaymentMethodType
                onClick={this.choosePaypal}
                text={intl.get('packageCheckout.payment.add.paypal')}
                paymentProviderIcon="payment-provider payment-provider--paypal"
            />
        );
    };

    render() {
        const base = 'add-payment-method';
        const { paymentStore, paypalStore } = this.props;

        if (paypalStore.isAddingPaypal) {
            return (
                <div className={base}>
                    {intl.get('packageCheckout.payment.paypalLoading')}
                </div>
            );
        }

        return (
            <div className={base}>
                <div className="display--flex flex-align-items-center">
                    <div className="flex-1">
                        <p className="text--bold">
                            {intl.get('packageCheckout.payment.add.title')}
                        </p>
                    </div>
                </div>
                <ul className={`${base}__list`}>
                    <PaymentMethodType
                        onClick={this.chooseOnlineBanking}
                        text={intl.get(
                            `packageCheckout.payment.add.onlineBanking_${services.getParam(
                                'city'
                            )}`
                        )}
                        paymentProviderIcon="payment-provider payment-provider--onlinebanking"
                    />
                    <PaymentMethodType
                        onClick={paymentStore.goToView(
                            paymentStore.ADD_CREDIT_CARD
                        )}
                        text={intl.get(
                            'packageCheckout.payment.add.creditcard'
                        )}
                        paymentProviderIcon="payment-provider payment-provider--unknown"
                    />
                    {this.renderPaypalButton()}
                </ul>
            </div>
        );
    }
}
