import React from 'react';
import {
    getCardIcon,
    getCardType,
    getCardNumber,
} from '../../../services/paymentProviders';

const base = 'payment-method-item';

export default ({ paymentMethod }) => {
    return (
        <div className={`${base} display--flex flex-align-items-center`}>
            <div className={getCardIcon(paymentMethod) + ' flex-none'} />
            <div className="mlm">
                <strong>
                    <label className="text--black">
                        {getCardType(paymentMethod)}
                    </label>
                </strong>
                <br />
                <label className={`${base}__sub-heading`}>
                    {getCardNumber(paymentMethod)}
                </label>
            </div>
        </div>
    );
};
