import React from 'react';
import { CircleLoader } from '../../core/Loading';

const base = 'profile';

export default ({ headerText }) => {
    return (
        <div className={`${base}__box`}>
            <div className={`${base}__box-header lh--125`}>{headerText}</div>
            <center className={`${base}__box-body`}>
                <CircleLoader />
            </center>
        </div>
    );
};
