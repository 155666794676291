import React from 'react';

import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

const base = 'terminal-payment-option';
@inject('terminalCartStore')
@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const { terminalMenuStore } = this.props;
        setTimeout(() => {
            if (!terminalMenuStore.askForName || terminalMenuStore.isSkipClicked) {
                terminalMenuStore.showingPopup =
                    terminalMenuStore.POPUP_CONFIRM_ORDER;
            } else {
                terminalMenuStore.showLuckyWheelScreen = true;
            }
        }, 2000); //10 seconds
    }
    render() {
        const { terminalMenuStore } = this.props;
        return (
            <div className={base}>
                { terminalMenuStore.paymentType == 'Kiosk_Ewallet' ? (<div className={`${base}__title`}>
                    <p>Scan your QR code to pay</p>
                </div>) : null }
                
                <div style={{ marginTop: '50px' }}>
                    <img src="/greenTick.png" />
                </div>

                <div
                    style={{
                        fontSize: '64px',
                        fontWeight: 600,
                        maxWidth: '384px',
                        margin: '35px auto',
                    }}
                >
                    {intl.get('terminal.qrPopup.payment_successful')}
                </div>
            </div>
        );
    }
}
