import intl from 'react-intl-universal';
import React from 'react';

const FooterButton = (props) => {
    const { displayFooter } = props;
    return (
        <div className={`footer-button`}>
            {displayFooter ? (
                <button>
                    <span className="fs--small-medium">
                        {intl.get('footer.close')}
                    </span>
                    <svg className="footer-button__close-icon">
                        <use xlinkHref="#svg--x-close" />
                    </svg>
                </button>
            ) : (
                <button>
                    <span className="fs--small-medium">
                        {intl.get('footer.title')}
                    </span>
                    <svg className={`footer-button__collapse iconmoon-icon`}>
                        <use xlinkHref="#icon-chevron-thin-up" />
                    </svg>
                </button>
            )}
        </div>
    );
};

export default FooterButton;
