import React from 'react';

const base = 'general-view';

export default ({ dish }) => {
    const tag = dish.tag;
    const style = tag
        ? {
              backgroundColor: '#' + tag.color,
              borderRadius: '4px',
              zIndex: 4,
              color: 'white',
              fontSize: '10pt',
              padding: '3px 7px 2px 7px',
              display: 'inline-block',
          }
        : null;

    return (
        <div className={`${base} lh--150`}>
            {dish.description}
            <img
                className={`${base}__halal-logo`}
                src={'https://image.popcontent.link/dijamin_halal.svg'}
                alt="Dijamin halal logo"
            />
            {dish.tag ? (
                <div>
                    <div className="mtm mbm" style={style}>
                        {tag.name}
                    </div>
                    <div>{dish.tag.description}</div>
                </div>
            ) : null}
        </div>
    );
};
