import { observable, action } from 'mobx';
import { services } from '../services/index';

class DishStore {
    GENERAL_VIEW = 'GeneralView';
    INGREDIENT_VIEW = 'IngredientView';
    CHEF_VIEW = 'ChefView';
    NUTRITIONAL_VIEW = 'NutritionalView';
    REVIEWS_VIEW = 'ReviewsView';

    @observable dish = null;
    @observable loading = true;
    @observable view = 'GeneralView';
    @observable reviews = [];

    getView() {
        return this.view;
    }
    setView(view) {
        return () => {
            this.view = view;
        };
    }

    setDish(dish) {
        this.dish = dish;
    }

    @action
    handleGetDish = (id, handleError) => {
        this.loading = true;
        services.api
            .GetTerminalDish({ id })
            .then((dish) => {
                this.dish = dish;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
                handleError();
            });
    };

    @action handleGetReviews = (id) => {
        services.api.GetReviewsForDish({ id }).then((review) => {
            this.reviews = review.ratings;
        });
    };
}

const store = new DishStore();
export default store;
