/* eslint-disable no-undef */
import { httpGet, httpPost, httpPatch } from '../http';

export const GetDeliveryStatus = () => {
    return httpGet('/v4/deliveries/pull-notifications', { authType: 'Bearer' });
};

export const GetDeliveryDetail = ({ id }) => {
    return httpGet(`/v4/deliveries/details/${id}`, { authType: 'Bearer' });
};

export const GetRatingInfo = ({ id }) => {
    return httpGet(`/v5/rating/${id}`, { authType: 'Bearer' });
};

export const SubmitRatingInfo = (requestBody) => {
    return httpPost('/v5/rating', requestBody, { authType: 'Bearer' });
};

export const GetDeliveryPreferences = (requestBody) => {
    return httpPost('/v4/address/get-preferences', requestBody, {
        authType: 'Bearer',
    });
};

export const CancelOrder = ({ id }) => {
    return httpPost(`/v4/deliveries/cancel/${id}`, null, {
        authType: 'Bearer',
    });
};

export const UpdateDelivery = (requestBody) => {
    return httpPatch('/v4/deliveries/update', requestBody, {
        authType: 'Bearer',
    });
};
