import React from 'react';
import { inject, observer } from 'mobx-react';

import Modal from './Modal';
import SelectLoginTypeView from './loginPopup/SelectLoginTypeView';
import PasswordView from './loginPopup/PasswordView';
import ResetPasswordView from './loginPopup/ResetPasswordView';
import BusyView from './loginPopup/BusyView';
import CompleteSignupView from './loginPopup/CompleteSignupView';
import LoginCompletedView from './loginPopup/LoginCompletedView';

import { loadScript } from '../../services/domUtils';
import EmailView from './loginPopup/EmailView';
import { SMALL_SCREEN_MEDIA } from '../../services/constants';
import MobileNumberVerifyView from './loginPopup/MobileNumberVerifyView';
import { services } from '../../services/index';
import { when } from 'mobx';

const noop = () => {};
const base = 'login-popup';

@inject('loginPopupStore')
@observer
export default class LoginPopupRoot extends React.Component {
    constructor(props) {
        super(props);

        if (typeof window !== 'undefined') {
            loadScript('https://accounts.google.com/gsi/client', () => {
                this.forceUpdate();
            });
        }
    }

    componentDidMount() {
        const { loginPopupStore } = this.props;
        const referral = loginPopupStore.referral();

        if (referral) {
            this.props.loginPopupStore.checkUser(true);
        }

        when(
            () => loginPopupStore.isShowing && referral,
            () => {
                window.dataLayer.push({
                    event: 'Lead',
                });
            }
        );
    }

    componentWillUnmount() {
        const { loginPopupStore } = this.props;

        loginPopupStore.isShowing = false;
        loginPopupStore.redirectToSelectPage = false;
    }

    handleHidePopup = () => {
        const { loginPopupStore } = this.props;
        loginPopupStore.setShowing(false);
        loginPopupStore.redirectToSelectPage = false;
    };

    handleNavigateBack = () => {
        const { loginPopupStore } = this.props;
        const currentView = loginPopupStore.getView();
        const { backLink } = loginPopupStore;

        if (
            currentView === loginPopupStore.EMAIL_VIEW ||
            currentView === loginPopupStore.MOBILE_NUMBER_VERIFY_VIEW
        ) {
            loginPopupStore.showLogin();
        }

        if (
            currentView === loginPopupStore.COMPLETE_SIGNUP ||
            currentView === loginPopupStore.PASSWORD_VIEW
        ) {
            loginPopupStore.showEmail();
        }

        if (currentView === loginPopupStore.FORGET_PASSWORD_VIEW) {
            loginPopupStore.showLogin();
        }

        if (
            backLink &&
            currentView === loginPopupStore.SELECT_LOGIN_TYPE_VIEW
        ) {
            window.location.href = backLink.href;
        }
    };

    render() {
        const { loginPopupStore, notAsModal } = this.props;
        const currentView = loginPopupStore.getView();
        const { backLink, referral } = loginPopupStore;
        const customBackLink =
            backLink &&
            currentView === loginPopupStore.SELECT_LOGIN_TYPE_VIEW &&
            !referral();
        const handleClosing = this.props.dismissable
            ? ::this.handleHidePopup
            : noop;
        const showCloseIcon = Boolean(this.props.showCloseIcon);
        const showBackIcon =
            currentView !== loginPopupStore.SELECT_LOGIN_TYPE_VIEW &&
            currentView !== loginPopupStore.BUSY_VIEW &&
            !referral();
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;
        if (loginPopupStore.isShowing) {
            if (notAsModal) {
                return (
                    <div>
                        {loginPopupStore.isShowing ? (
                            <div style={{ marginTop: '40px' }}>
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html: `.${services.getParam(
                                            'city'
                                        )} .banner--show { display: none }`,
                                    }}
                                />
                                {showBackIcon && (
                                    <div className="display--flex">
                                        <button
                                            className="button--nothing cursor-pointer"
                                            onClick={this.handleNavigateBack}
                                            style={{ paddingRight: '0' }}
                                        >
                                            <svg
                                                className={`modal__back-icon iconmoon-icon mrs text--primary`}
                                            >
                                                <use xlinkHref="#icon-chevron-thin-up" />
                                            </svg>
                                        </button>
                                        <p
                                            className="cursor-pointer fs--small-medium"
                                            onClick={this.handleNavigateBack}
                                            style={{ marginTop: '5px' }}
                                        >
                                            Back
                                        </p>
                                    </div>
                                )}
                                <div
                                    className={`login-non-modal__content`}
                                    style={{
                                        padding: '20px',
                                        backgroundColor: '#fff',
                                        marginTop: window.matchMedia(
                                            SMALL_SCREEN_MEDIA
                                        ).matches
                                            ? '-80px'
                                            : '',
                                    }}
                                >
                                    {currentView ===
                                    loginPopupStore.SELECT_LOGIN_TYPE_VIEW ? (
                                        <SelectLoginTypeView />
                                    ) : null}
                                    {currentView ===
                                    loginPopupStore.EMAIL_VIEW ? (
                                        <EmailView />
                                    ) : null}
                                    {currentView ===
                                    loginPopupStore.MOBILE_NUMBER_VERIFY_VIEW ? (
                                        <MobileNumberVerifyView />
                                    ) : null}
                                    {currentView ===
                                    loginPopupStore.COMPLETE_SIGNUP ? (
                                        <CompleteSignupView />
                                    ) : null}
                                    {currentView ===
                                    loginPopupStore.PASSWORD_VIEW ? (
                                        <PasswordView />
                                    ) : null}
                                    {currentView ===
                                    loginPopupStore.BUSY_VIEW ? (
                                        <BusyView buttonText="Logging in" />
                                    ) : null}
                                    {currentView ===
                                    loginPopupStore.FORGET_PASSWORD_VIEW ? (
                                        <ResetPasswordView />
                                    ) : null}
                                    {currentView ===
                                    loginPopupStore.LOGIN_COMPLETED_VIEW ? (
                                        <LoginCompletedView />
                                    ) : null}
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                );
            } else {
                return (
                    <Modal
                        showModal={loginPopupStore.isShowing}
                        showCloseIcon={showCloseIcon}
                        closeButtonMargin={`${base}__close-margin`}
                        logoMargin={`${base}__logo-margin`}
                        backButtonMargin={`${base}__back-margin`}
                        showBackIcon={showBackIcon || customBackLink}
                        onBackClicked={this.handleNavigateBack}
                        backButtonText="Back"
                        contentClasses={`${base}__container ${
                            !isMobile && 'modal__content--golden-ratio'
                        }`}
                        onBackdropClicked={handleClosing}
                        onCloseClicked={handleClosing}
                    >
                        <section>
                            {currentView ===
                            loginPopupStore.SELECT_LOGIN_TYPE_VIEW ? (
                                <SelectLoginTypeView />
                            ) : null}
                            {currentView === loginPopupStore.EMAIL_VIEW ? (
                                <EmailView />
                            ) : null}
                            {currentView ===
                            loginPopupStore.MOBILE_NUMBER_VERIFY_VIEW ? (
                                <MobileNumberVerifyView />
                            ) : null}
                            {currentView === loginPopupStore.COMPLETE_SIGNUP ? (
                                <CompleteSignupView />
                            ) : null}
                            {currentView === loginPopupStore.PASSWORD_VIEW ? (
                                <PasswordView />
                            ) : null}
                            {currentView === loginPopupStore.BUSY_VIEW ? (
                                <BusyView buttonText="Logging in" />
                            ) : null}
                            {currentView ===
                            loginPopupStore.FORGET_PASSWORD_VIEW ? (
                                <ResetPasswordView />
                            ) : null}
                            {currentView ===
                            loginPopupStore.LOGIN_COMPLETED_VIEW ? (
                                <LoginCompletedView />
                            ) : null}
                        </section>
                    </Modal>
                );
            }
        } else {
            return <div />;
        }
    }
}
