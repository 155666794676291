import React, { Component } from 'react';
import { currency } from '../../../services';
import { inject, observer } from 'mobx-react';
import { when, reaction } from 'mobx';
import { RESOURCE } from '../../../services/types';
import qs from 'query-string';
import Cookies from 'js-cookie';

const base = 'cart-preview';

@inject('cartStore', 'userStore', 'rewardsStore')
@observer
class MealRowCartSummary extends Component {
    state = {};

    componentDidMount() {
        const { userStore, rewardsStore } = this.props;
        // checks if user has existing items in their cart, and gets preliminaryOrderSummary to display cart total from the start
        when(
            () => userStore.user.data,
            () => {
                if (RESOURCE.Success.is(userStore.user)) {
                    this.handleGetPreliminaryOrderSummary();
                    when(
                        () => rewardsStore.result,
                        () => {
                            this.handleGetPreliminaryOrderSummary();
                        }
                    );
                } else {
                    this.handleGetPreliminaryOrderSummary();
                }
                this.handleManuallyAppendDiscountCode();
            }
        );
    }

    handleGetPreliminaryOrderSummary = () => {
        const { cartStore } = this.props;

        if (cartStore.cart.length > 0) {
            cartStore.updatePreliminaryOrderSummary();
        }
    };

    componentDidUpdate() {
        const { manuallyAppendDiscountCode, discountField } = this.state;
        const { cartStore } = this.props;

        if (manuallyAppendDiscountCode) {
            const orderSummaryContainsDiscountField =
                cartStore.preliminaryOrderSummary.price_summary &&
                cartStore.preliminaryOrderSummary.price_summary.rows.filter(
                    (row) => row.name === discountField.name
                ).length > 0;
            if (!orderSummaryContainsDiscountField) {
                const summaryRows =
                    this.props.cartStore.preliminaryOrderSummary.price_summary
                        .rows;
                const total = summaryRows[summaryRows.length - 1];
                this.handleManuallyDeductTotal(total);
                cartStore.preliminaryOrderSummary.price_summary.rows.splice(
                    2,
                    0,
                    discountField
                );
            }
        }
    }

    handleManuallyAppendDiscountCode = () => {
        const { cartStore } = this.props;
        const params = qs.parse(window.location.href);
        const autoAssignedCode = params.promotion || Cookies.get('promotion');
        const autoAssignedCodeAmount = params.benefit || Cookies.get('benefit');
        const autoAssignedCodeType =
            params.benefit_type || Cookies.get('benefit_type');
        const manuallyAppendDiscountCode =
            autoAssignedCode && !RESOURCE.Success.is(this.props.userStore.user);

        if (manuallyAppendDiscountCode) {
            reaction(
                () =>
                    cartStore.preliminaryOrderSummary &&
                    cartStore.preliminaryOrderSummary.price_summary.rows,
                (reaction) => {
                    const summaryRows =
                        this.props.cartStore.preliminaryOrderSummary
                            .price_summary.rows;
                    const total = summaryRows[summaryRows.length - 1];
                    const totalWithoutCurrency =
                        total.value.match(/[0-9, \.]+/g)[0];
                    const value =
                        autoAssignedCodeType === 'percentage'
                            ? currency.localCurrency(
                                  totalWithoutCurrency * autoAssignedCodeAmount
                              )
                            : currency.localCurrency(autoAssignedCodeAmount);

                    const discountField = {
                        bold: null,
                        color: 'GREEN',
                        name: `Code ${autoAssignedCode}`,
                        value,
                    };

                    this.setState({
                        manuallyAppendDiscountCode: true,
                        autoAssignedCode,
                        autoAssignedCodeAmount,
                        autoAssignedCodeType,
                        discountField,
                    });
                    reaction.dispose();
                }
            );
        }
    };

    handleManuallyDeductTotal = (currentTotal) => {
        const { autoAssignedCodeAmount, autoAssignedCodeType } = this.state;
        const totalWithoutCurrency = currentTotal.value.match(/[0-9, \.]+/g)[0];
        if (autoAssignedCodeType === 'percentage') {
            currentTotal.value = currency.localCurrency(
                totalWithoutCurrency * (1 - autoAssignedCodeAmount)
            );
        } else {
            currentTotal.value = currency.localCurrency(
                totalWithoutCurrency - autoAssignedCodeAmount
            );
        }
    };

    render() {
        const { cartStore } = this.props;

        return (
            cartStore.preliminaryOrderSummary &&
            cartStore.preliminaryOrderSummary.price_summary.rows.map(
                (line, i) => {
                    const noOfItems =
                        cartStore.preliminaryOrderSummary.price_summary.rows
                            .length;
                    let color = '';
                    if (line.color === 'GREEN') {
                        color = 'text--primary';
                    } else if (line.color === 'BLUE') {
                        color = 'text--blue';
                    }

                    const bold = line.bold ? 'text--bold' : '';
                    return (
                        <div key={i}>
                            {/* the last item in the array */}
                            {i === noOfItems - 1 ? (
                                <hr className={`${base}__quantity-separator`} />
                            ) : null}
                            <div
                                className={`${base}__subtotal-row clearfix mbs`}
                            >
                                <p
                                    className={`float-left ${bold} fs--small-medium ${color}`}
                                >
                                    {line.name}
                                </p>
                                <div
                                    className={`float-right ${bold} fs--small-medium ${color}`}
                                >
                                    {line.value}
                                </div>
                            </div>
                        </div>
                    );
                }
            )
        );
    }
}

export default MealRowCartSummary;
