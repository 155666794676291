import { observable, action } from 'mobx';
import Notifiable from './services/notifiable';
import { currency } from '../services';
import { services } from '../services/index';

const { clevertap } = window;

const INTERVAL = 10 * 1000;

export default class extends Notifiable {
    PENDING = 'Pending';
    COMPLETE = 'Complete';
    CANCELLED = 'Cancelled';
    FAILED = 'Failed';

    @observable isLoading = true;
    @observable message = '';

    @observable.ref user = null;
    @observable.ref status = { transaction_status: 'Pending' };

    constructor(walletOrderId) {
        super();

        this.walletOrderId = walletOrderId;
        this.setUpNotification({ showCloseIcon: false });
    }

    @action loadUser() {
        this.isLoading = true;
        services.api
            .GetUser()
            .then(::this.setUser)
            .catch((responseData) => {
                this.message = responseData.message;
            })
            .then(() => {
                this.isLoading = false;
            });
    }

    @action startPolling() {
        this.check();

        this.interval = window.setInterval(::this.check, INTERVAL);
    }

    @action check() {
        services.api
            .CheckWalletPurchaseStatus(this.walletOrderId)
            .then(::this.setStatus)
            .catch((responseData) => {
                if (responseData.status === 404) {
                    this.setStatus({
                        transaction_status: this.FAILED,
                        message: `Order number ${this.walletOrderId} is invalid.`,
                    });
                } else {
                    this.message = responseData.message;
                }
                window.clearInterval(this.interval);
            })
            .then(() => {
                if (this.status['transaction_status'] !== this.PENDING) {
                    window.clearInterval(this.interval);
                }
            });
    }

    sendTrackingEvents(data) {
        this.clearCart();
        const purchaseEventName = services.getParam('purchaseEventName');
        const packageName = data.walletPackage.title;
        const priceAsNumber = data.orderSummary.net_total;

        window.dataLayer.push({
            event: 'Purchase',
            value: currency.to2digits(priceAsNumber),
            currency: services.getParam('currencyCode'),
            content_name: 'packageName',
            content_type: 'Meal_Package',
        });

        window.dataLayer.push({
            event: 'Wallet_Package_Purchase',
            package: packageName,
            value: currency.to2digits(priceAsNumber),
        });

        if (data.autoTopUpEnabled) {
            window.dataLayer.push({
                event: 'SmartReload',
                package: packageName,
            });
        }
    }

    clearCart() {
        services.api.SelectingWalletPackage.remove();
    }

    @action notifyError() {
        this.message = this.status.message;
    }

    getDataForConfirmPayment() {
        const walletPackage = this.status['line_items'][0];

        return {
            user: {
                alias: this.user.alias,
            },
            walletPackage: {
                title: walletPackage['title'],
                size: walletPackage['size'],
            },
            orderSummary: {
                price_summary: this.status.price_summary,
            },
            paymentMethod: {
                card_type: this.status['payment_method'],
            },
            finalOrderSummary: {
                wallet_order_id: this.status['wallet_order_id'],
                used_discount_codes: this.status['used_discount_codes'],
            },
            autoTopUpEnabled: walletPackage['auto_top_up_enabled'],
        };
    }

    setUser(user) {
        this.user = user;
    }
    setStatus(status) {
        this.status = status;
    }
}
