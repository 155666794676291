import React from 'react';
import { inject, observer } from 'mobx-react';

import ArrowUp from '../../../core/SVGs/ArrowUp';
import ArrowDown from '../../../core/SVGs/ArrowDown';

const base = 'terminal-mobile-main-menu-item';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    handleAddItemToCart = () => {
        const { item, terminalMenuStore } = this.props;
        terminalMenuStore.handleAddItemToCart(item, false);
    };

    render() {
        const { terminalCartStore, item, bgColor, contentColor } = this.props;
        const movement_status = item.movement === 0 ? 'constant' : item.movement > 0 ? 'up' : 'down';
        const itemQuantity = parseInt(terminalCartStore.cartServices.getQuantityOfItem(item));
        const rankingToShow = item.current_ranking < 10 ? `0${item.current_ranking}` : item.current_ranking;
        return (
            <div className={base}>
                <div
                    className={`${base}__item_card`}
                    onClick={this.handleAddItemToCart}
                    style={{ backgroundColor: bgColor, color: contentColor }}
                >
                    <div className={`${base}__ranking_price`}>
                        <div className={`${base}__ranking`}>
                            <h3 className={`${base}__current_ranking`}>{rankingToShow}</h3>
                            <div className={`${base}__separator`} style={{ backgroundColor: contentColor }}></div>
                            <div className={`${base}__ranking_change`}>
                                {movement_status === 'constant' ? (
                                    <></>
                                ) : movement_status === 'up' ? (
                                    <>
                                        <ArrowUp
                                            width={9}
                                            height={15}
                                            color={contentColor}
                                            manualStyles={{
                                                marginRight: '2px',
                                            }}
                                        />
                                        <p className={`${base}__ranking_change_num`}>{item.movement}</p>
                                    </>
                                ) : (
                                    <>
                                        <ArrowDown
                                            width={9}
                                            height={15}
                                            color={contentColor}
                                            manualStyles={{
                                                marginRight: '2px',
                                            }}
                                        />
                                        <p className={`${base}__ranking_change_num`}>{Math.abs(item.movement)}</p>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={`${base}__price`}>
                            <p className="rm">RM</p>
                            <p className="price">{item.special_offer_price ? item.special_offer_price : item.price}</p>
                        </div>
                    </div>

                    <img
                        loading="lazy"
                        src={
                            item.digital_menu_image
                                ? item.digital_menu_image
                                : 'https://image.popcontent.link/DIGITAL_MENU_IMAGE_placeholder.png'
                        }
                        className={`${base}__image`}
                    />
                    {/* <div className={`${base}__name_container`}>
                        {item.title_bold}
                    </div> */}

                    <div className={`${base}__name`}>{item.title_bold}</div>
                </div>

                <div className={`${base}__order_again`}>
                    <img
                        loading="lazy"
                        src={'https://image.popcontent.link/like_order_again_icon.svg'}
                        className={`thumbs_up_img`}
                    />
                    <p className="order_again_text">{item.rating_text}</p>
                </div>
                <button className={`${base}__cart`} onClick={this.handleAddItemToCart}>
                    {itemQuantity && itemQuantity > 0 ? (
                        <p className="item_quanity">{itemQuantity}</p>
                    ) : (
                        <img
                            loading="lazy"
                            src={'https://image.popcontent.link/cart_icon.svg'}
                            className={`${base}__cart_img`}
                        />
                    )}
                </button>
            </div>
        );
    }
}
