import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import DateAndTimeSelector from './DateAndTimeSelector';
import AddressSelector from './AddressSelector';
import { when } from 'mobx';
import AddressSelectorPopup from '../../core/AddressSelectorPopup';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import menuHeaderStore from '../../../store/menuHeaderStore';
import AccountSwitcher from './AccountSwitcher';
import intl from 'react-intl-universal';

const base = 'address-bar';

@inject(
    'menuListStore',
    'calendarStore',
    'menuCheckoutStore',
    'cartStore',
    'userStore',
    'dropdownStore',
    'deliveryAddressStore'
)
@observer
export default class AddressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddressSelector: false,
            showDateTimeSelector: false,
        };
    }

    handleSetAddressBarHeight = () => {
        if (document.getElementById('address-bar')) {
            menuHeaderStore.setAddressBarHeight(
                document.getElementById('address-bar').offsetHeight
            );
        }
    };

    componentDidMount() {
        const { cartStore } = this.props;
        this.handleSetAddressBarHeight();
        window.addEventListener('resize', this.handleSetAddressBarHeight);

        when(
            () => cartStore.showAddressSelectorPopup,
            () => {
                this.setState({ showAddressSelector: true });
            }
        );
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleSetAddressBarHeight);
    }

    toggleAddressSelector = () => {
        this.setState({
            showAddressSelector: true,
            showDateTimeSelector: false,
        });
    };

    toggleDateTimeSelector = () => {
        const isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
        const { cartStore } = this.props;
        if (isSmallScreen) {
            cartStore.showAddressSelectorPopup = false;
        }

        this.setState({
            showDateTimeSelector: true,
            showAddressSelector: false,
        });
    };

    hideSelectors = () => {
        const { cartStore } = this.props;
        this.setState({
            showAddressSelector: false,
            showDateTimeSelector: false,
        });

        cartStore.showAddressSelectorPopup = false;
    };

    showAddressSelectorPopup = () => {
        const { cartStore } = this.props;
        const isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;

        if (isSmallScreen) {
            cartStore.showAddressSelectorPopup = true;
        }
    };

    handleDisplayAddress = () => {
        const { deliveryAddressStore } = this.props;
        const { building_name, name, display_name, street, homeNumber } =
            deliveryAddressStore.selectedAddress;
        const displayStreet =
            street && (homeNumber ? homeNumber + ', ' : '') + street;

        let addressToDisplay =
            name || display_name || building_name || displayStreet;

        if (
            addressToDisplay &&
            addressToDisplay[addressToDisplay.length - 2] === ','
        ) {
            addressToDisplay = addressToDisplay.slice(0, -2);
        }

        return addressToDisplay;
    };

    render() {
        const { showAddressSelector, showDateTimeSelector } = this.state;
        const {
            menuListStore,
            cartStore,
            userStore,
            deliveryAddressStore,
            dropdownStore,
        } = this.props;
        const addressDisplay =
            deliveryAddressStore.selectedAddress && this.handleDisplayAddress();
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;
        const dateAndTimeslotDisplay = `${menuListStore.activeDateDisplay} • ${menuListStore.activeTimeslotDisplay}`;
        const isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;
        const isBusinessUser =
            userStore.user.data && userStore.user.data.company;

        return (
            <Fragment>
                {isSmallScreen && menuListStore.activeDateObj && (
                    <AddressSelectorPopup onMenu={true} />
                )}
                <div
                    className={`${base}`}
                    onClick={this.showAddressSelectorPopup}
                    id="address-bar"
                    style={{
                        top:
                            menuHeaderStore.headerHeight -
                            1 +
                            menuHeaderStore.walletBannerHeight +
                            'px',
                        marginTop: menuHeaderStore.branchBannerHeight + 'px',
                    }}
                >
                    {isMobile ? (
                        <div className={`${base}__container container`}>
                            <div className={`${base}__address`}>
                                <div className={`${base}__address-header`}>
                                    <p
                                        className={`${base}__address-header-text`}
                                    >
                                        {deliveryAddressStore.selectedAddress &&
                                            menuListStore.activeDateDisplay &&
                                            dateAndTimeslotDisplay}
                                    </p>
                                </div>
                                {addressDisplay ? (
                                    <span
                                        className={`${base}__selected-address-text cursor-pointer ellipsis`}
                                        onClick={this.toggleAddressSelector}
                                    >
                                        <strong>{addressDisplay}</strong>
                                    </span>
                                ) : (
                                    <div
                                        className="display--flex cursor-pointer margin--right"
                                        onClick={this.toggleAddressSelector}
                                    >
                                        <span
                                            className={`${base}__address-text`}
                                        >
                                            Enter office, condo or street
                                        </span>
                                    </div>
                                )}
                            </div>
                            {addressDisplay && (
                                <div className={`${base}__change-button`}>
                                    <span>Change</span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={`${base}__container container`}>
                            <div>
                                <div
                                    className={`${base}__selection-button`}
                                    onClick={this.toggleAddressSelector}
                                >
                                    <div className={`${base}__address`}>
                                        {addressDisplay ? (
                                            <div className="display--flex">
                                                <span
                                                    className={`${base}__selected-address-text cursor-pointer`}
                                                >
                                                    Deliver to{' '}
                                                    <strong>
                                                        {addressDisplay}
                                                    </strong>
                                                </span>
                                                <svg
                                                    className={`${base}__arrow-icon iconmoon-icon`}
                                                >
                                                    <use
                                                        xlinkHref={`#icon-chevron-thin-down`}
                                                    />
                                                </svg>
                                            </div>
                                        ) : (
                                            <div className="display--flex cursor-pointer margin--right">
                                                <svg
                                                    className={`${base}__search-icon iconmoon-icon`}
                                                >
                                                    <use xlinkHref="#icon-search" />
                                                </svg>
                                                <span
                                                    className={`${base}__address-text`}
                                                >
                                                    {intl.get(
                                                        'addressSelector.title'
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {(showAddressSelector ||
                                    cartStore.showAddressSelectorPopup) && (
                                    <AddressSelector
                                        hideSelectors={this.hideSelectors}
                                    />
                                )}
                            </div>
                            {menuListStore.activeDateDisplay && (
                                <div>
                                    <div
                                        className={`${base}__selection-button`}
                                        onClick={this.toggleDateTimeSelector}
                                    >
                                        <div className={`${base}__date-time`}>
                                            <div className="display--flex">
                                                <span
                                                    className={`${base}__date-time-text cursor-pointer`}
                                                >
                                                    Deliver on{' '}
                                                    <strong>
                                                        {dateAndTimeslotDisplay}
                                                    </strong>
                                                </span>
                                                <svg
                                                    className={`${base}__arrow-icon iconmoon-icon`}
                                                >
                                                    <use
                                                        xlinkHref={`#icon-chevron-thin-down`}
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {showDateTimeSelector && (
                                        <DateAndTimeSelector
                                            hideSelectors={this.hideSelectors}
                                        />
                                    )}
                                </div>
                            )}
                            {!isSmallScreen && (
                            <a style={{marginLeft: "auto"}} href="https://wa.me/601154193392?text=Hi%2C%20I%20would%20like%20to%20place%20an%20order" target="_blank" >
                                <div className={`${base}__whatsapp-order-button`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M19.05 4.91C18.1332 3.98392 17.0412 3.24967 15.8377 2.75005C14.6341 2.25043 13.3432 1.99546 12.04 2C6.58005 2 2.13005 6.45 2.13005 11.91C2.13005 13.66 2.59005 15.36 3.45005 16.86L2.05005 22L7.30005 20.62C8.75005 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.9501 17.38 21.9501 11.92C21.9501 9.27 20.92 6.78 19.05 4.91ZM12.04 20.15C10.56 20.15 9.11005 19.75 7.84005 19L7.54005 18.82L4.42005 19.64L5.25005 16.6L5.05005 16.29C4.2278 14.977 3.79119 13.4592 3.79005 11.91C3.79005 7.37 7.49005 3.67 12.03 3.67C14.23 3.67 16.3 4.53 17.85 6.09C18.6175 6.85396 19.2257 7.76266 19.6394 8.76342C20.0531 9.76419 20.2641 10.8371 20.26 11.92C20.2801 16.46 16.58 20.15 12.04 20.15ZM16.56 13.99C16.31 13.87 15.09 13.27 14.87 13.18C14.64 13.1 14.48 13.06 14.31 13.3C14.14 13.55 13.67 14.11 13.53 14.27C13.39 14.44 13.24 14.46 12.99 14.33C12.74 14.21 11.94 13.94 11 13.1C10.26 12.44 9.77005 11.63 9.62005 11.38C9.48005 11.13 9.60005 11 9.73005 10.87C9.84005 10.76 9.98005 10.58 10.1 10.44C10.22 10.3 10.27 10.19 10.35 10.03C10.43 9.86 10.39 9.72 10.33 9.6C10.27 9.48 9.77005 8.26 9.57005 7.76C9.37005 7.28 9.16005 7.34 9.01005 7.33H8.53005C8.36005 7.33 8.10005 7.39 7.87005 7.64C7.65005 7.89 7.01005 8.49 7.01005 9.71C7.01005 10.93 7.90005 12.11 8.02005 12.27C8.14005 12.44 9.77005 14.94 12.25 16.01C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.69 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.03 14.27C16.96 14.16 16.81 14.11 16.56 13.99Z" fill="white"/>
                                    </svg>
                                    <p>Order on WhatsApp (Pickup & Delivery)</p>
                                </div>
                            </a>
                            )}
                            {!isSmallScreen && isBusinessUser && (
                                <div
                                    className={`${base}__selection-button`}
                                    style={{ marginLeft: 'auto' }}
                                    onMouseEnter={() =>
                                        dropdownStore.handleShowDropdown(
                                            dropdownStore.DROPDOWN_ACCOUNT_SWITCHER
                                        )
                                    }
                                >
                                    <AccountSwitcher
                                        dropdownStore={this.props.dropdownStore}
                                        mobile={true}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {isSmallScreen && isBusinessUser && (
                    <div
                        className={`${base}__account-switcher-container`}
                        style={{
                            top:
                                menuHeaderStore.headerHeight -
                                1 +
                                menuHeaderStore.walletBannerHeight +
                                menuHeaderStore.addressBarHeight +
                                'px',
                            marginTop:
                                menuHeaderStore.branchBannerHeight + 'px',
                        }}
                    >
                        <AccountSwitcher
                            dropdownStore={this.props.dropdownStore}
                            mobile={true}
                        />
                    </div>
                )}
                {!isMobile &&
                    (showAddressSelector ||
                        showDateTimeSelector ||
                        cartStore.showAddressSelectorPopup) && (
                        <div
                            className={`modal__backdrop show cursor-pointer`}
                            id="modal-backdrop"
                            onClick={this.hideSelectors}
                        />
                    )}
            </Fragment>
        );
    }
}
