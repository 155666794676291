import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { services } from '../../../services/index';
import SwitchButton from 'lyef-switch-button';

import Modal from '../../core/Modal';
import brandLogo from '../../core/brandLogo/MasterBrandLogo';

import LoginPopup from '../../core/LoginPopup';

const base = 'offline-signup-page';

@inject('loginPopupStore', 'rewardsStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const { rewardsStore, loginPopupStore } = this.props;
        loginPopupStore.setShowing(true);
        loginPopupStore.isOfflineCampaign = true;

        rewardsStore.code =
            loginPopupStore.lowerValueCode || loginPopupStore.higherValueCode;
    }

    callbackFunction() {
        const { loginPopupStore, rewardsStore } = this.props;
        loginPopupStore.offlineCheapDish = !loginPopupStore.offlineCheapDish;
        if (rewardsStore.code === loginPopupStore.higherValueCode) {
            rewardsStore.code = loginPopupStore.lowerValueCode;
        } else {
            rewardsStore.code = loginPopupStore.higherValueCode;
        }
    }

    hideModal() {
        this.props.loginPopupStore.showOfflineSignupSuccessModal = false;
    }

    renderSwitcher = () => {
        const { loginPopupStore, rewardsStore } = this.props;
        if (loginPopupStore.lowerValueCode && loginPopupStore.higherValueCode) {
            return (
                <SwitchButton
                    id="switch"
                    labelLeft="RM20 for 3"
                    labelRight="RM10 for 1"
                    isChecked={
                        rewardsStore.code === loginPopupStore.lowerValueCode
                    }
                    action={::this.callbackFunction}
                />
            );
        }
    };

    render() {
        const style = {
            maxWidth: '350px',
            margin: '0 auto',
            textAlign: 'center',
        };

        const styles = {
            padding: '50px',
            textAlign: 'center',
            backgroundColor: '#fec700',
            color: 'white',
        };

        return (
            <div className={base}>
                <div style={style} className={`${base}__container`}>
                    <div style={{ margin: '20px 0px' }}>
                        <brandLogo.BrandText />
                    </div>
                    {this.renderSwitcher()}
                    <LoginPopup
                        dismissable={true}
                        showCloseIcon={true}
                        notAsModal={true}
                    />
                    <div className={`${base}__download-container`}>
                        <p className="lh--125">
                            Download the {services.getParam('brandName')} app
                            <br />
                            on iOS or Android
                        </p>
                        <div className={`${base}__qr-code`} />
                    </div>
                </div>
                <Modal
                    showModal={
                        this.props.loginPopupStore.showOfflineSignupSuccessModal
                    }
                    onCloseClicked={::this.hideModal}
                    onBackdropClicked={::this.hideModal}
                    showCloseIcon={true}
                    contentClasses={`modal__content--full-mobile ${base}__modal`}
                    closeButtonClasses="order-status-popup__close"
                    manualStyles={styles}
                >
                    <p className="text--bold lh--150 fs--medium-medium-large">
                        {this.props.loginPopupStore.offlineSignupModalMessage}
                    </p>
                    <button
                        type="button"
                        className={`button mtl`}
                        onClick={::this.hideModal}
                    >
                        {intl.get('offlineSignup.close')}
                    </button>
                </Modal>
                <style
                    dangerouslySetInnerHTML={{
                        __html: `.${services.getParam(
                            'city'
                        )} .zEWidget-launcher { display: none!important } .${services.getParam(
                            'city'
                        )} #percept-widget { display: none!important }`,
                    }}
                />
            </div>
        );
    }
}
