import React from 'react';

const base = 'order-history';

export default class extends React.Component {
    render() {
        return (
            <div
                className={`${base}__table-header fs--small-medium show--if-large hide--if-small`}
            >
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        Status
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        Date
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        Time
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        Order Number
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 plxl p0--if-small">
                        Total
                    </div>
                </div>
            </div>
        );
    }
}
