import React from 'react';

const base = 'dropdown';

export default ({ children, isShowing, onClickOutside, width, arrowSize }) => {
    return (
        <div
            className={`dropdown__container position--absolute ${
                isShowing ? 'show' : 'hide'
            }`}
            style={{ width }}
        >
            <div className={`${base}__backdrop`} onClick={onClickOutside} />
            <div
                className={`${base}__triangle`}
                style={{ fontSize: arrowSize + 'px' }}
            />
            <div className={base}>{children}</div>
        </div>
    );
};
