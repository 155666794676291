import React, { Component } from 'react';
import CarouselUI from './CarouselUI';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import { GetLanguage } from '../../../services/translation';
import config from '../../../configs/parameters';
import intl from 'react-intl-universal';
import { template } from 'lodash';

const isLangMalay = GetLanguage() === 'ms';
const isSmallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

export default class TeamsCarousel extends Component {
    render() {
        const brandName = config.brandName;
        const content = [
            {
                icon: 'icon-chef-hat',
                header: intl.get('careers.teams.item1.header'),
                content: template(intl.get('careers.teams.item1.content'))({
                    brandName,
                }),
            },
            {
                icon: 'icon-tech',
                header: intl.get('careers.teams.item2.header'),
                content: template(intl.get('careers.teams.item2.content'))({
                    brandName,
                }),
            },
            {
                icon: 'icon-delivery',
                header: intl.get('careers.teams.item3.header'),
                content: intl.get('careers.teams.item3.content'),
            },
        ];

        const height = () => {
            if (isLangMalay) {
                if (isSmallScreen) return 582;
                return 416;
            }
            if (isSmallScreen) return 454;
            return 346;
        };

        return (
            <CarouselUI
                content={content}
                height={height()}
                name="team-carousel"
                contentWidth={420}
                width={
                    window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 319 : 540
                }
                marginBottom={
                    window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 65 : 90
                }
            />
        );
    }
}
