import { observable, action, computed } from 'mobx';
import menuListStore from './menuListStore';
import { services } from '../services/index';
import { ValidationResult } from '../services/validator';
import addressValidator, {
    deliveryInstructionValidator,
} from './validators/addressValidator';
import { RESOURCE, MAYBE } from '../services/types';
import { OFFICE_ADDRESS, CONDO_ADDRESS } from '../services/constants';
import Notifiable from './services/notifiable';

const { clevertap } = window;

export default class extends Notifiable {
    POPUP_TYPING = 'PopupTyping';
    POPUP_ADJUSTING = 'PopupAdjusting';
    POPUP_NOTE = 'PopupNote';
    POPUP_OFFICE_EXPLAINED = 'PopupOfficeExplained';
    POPUP_CONDO_EXPLAINED = 'PopupCondoExplained';
    POPUP_HOUSE_EXPLAINED = 'PopupHouseExplained';

    PICKUP_GROUND_FLOOR = 'Pick from reception at ground floor';
    PICKUP_GUARD_HOUSE = 'Pick up from guard house';
    PICKUP_AT_FLOOR = 'Pick from reception at floor <%= floor %>';
    MEET_DRIVER_AT_FLOOR = 'Meet the driver at floor <%= floor %>';
    MEET_DRIVER_AT_LOBBY = 'Meet the driver in the lobby';
    MEET_DRIVER_AT_DOOR = 'Meet the driver at my gate';

    @observable place = null;

    @observable.ref addressesFetcher = RESOURCE.Initial;
    @observable.ref addressesDeleter = RESOURCE.Initial;
    @observable.ref deliveryPreferencesFetcher = RESOURCE.Initial;
    @observable.ref adjustAddressValRes = new ValidationResult();
    @observable.ref deliveryInstructionValRes = new ValidationResult();
    @observable.ref addressBeingDeleted = MAYBE.None;

    @observable showMap = false;
    @observable isSavingAddress = false;
    @observable showingPopup = '';
    @observable newAddress = {
        addressType: '',
        buildingName: '',
        postalCode: '',
        district: '',
        homeNumber: '',
        city: '',
        displayName: '',
        name: '',
        deliveryInstruction: '',
        additionalInfo: '',
        block: '',
        address_incomplete: '',
    };

    @computed get headerText() {
        switch (this.showingPopup) {
            case this.POPUP_TYPING:
                return 'Add Address 1 / 3';
            case this.POPUP_ADJUSTING:
                return 'Add Address 2 / 3';
            case this.POPUP_NOTE:
                return 'Add Address 3 / 3';
            case this.POPUP_OFFICE_EXPLAINED:
            case this.POPUP_CONDO_EXPLAINED:
            case this.POPUP_HOUSE_EXPLAINED:
                return 'Delivery options';
            default:
                return 'Address';
        }
    }

    @action handleHeaderClick() {
        switch (this.showingPopup) {
            case this.POPUP_TYPING:
                this.showingPopup = '';
                break;
            case this.POPUP_ADJUSTING:
                this.showingPopup = this.POPUP_TYPING;
                break;
            case this.POPUP_NOTE:
                this.showingPopup = this.POPUP_ADJUSTING;
                break;
            default:
                this.showingPopup = this.POPUP_NOTE;
        }
    }

    @action fetchAddresses = () => {
        this.addressesFetcher = RESOURCE.Loading;

        services.api.GetAddresses.runFresh()
            .then((addresses) => {
                this.addressesFetcher = RESOURCE.Success(addresses);
            })
            .catch((response) => {
                this.addressesFetcher = RESOURCE.Failure(
                    response.message,
                    response
                );
            });
    };

    @action showPopup = (popup) => {
        this.showingPopup = popup;
    };

    @action hidePopup() {
        this.showingPopup = '';
        this.showMap = false;
        this.resetNewAddress();
    }

    @action setPlace = (place) => {
        this.place = place;
        this.adjustAddressValRes = new ValidationResult();
        this.deliveryInstructionValRes = new ValidationResult();
    };

    @action setNewAddress(address, addressIncomplete) {
        this.newAddress.address_incomplete = addressIncomplete ? true : '';
        this.newAddress.buildingName = address.buildingName
            ? address.buildingName
            : '';
        this.newAddress.block = address.block ? address.block : '';

        this.newAddress.name = address.name || '';
        this.newAddress.displayName = address.displayName || '';

        this.newAddress.homeNumber = address.homeNumber || '';

        this.newAddress.postalCode = address.postalCode
            ? address.postalCode
            : '';
        this.newAddress.district = address.district ? address.district : '';
        this.newAddress.city = address.city ? address.city : '';
    }

    @action updateNewAddress(evt) {
        this.newAddress[evt.currentTarget.name] = evt.currentTarget.value;
    }

    @action validate() {
        this.adjustAddressValRes = addressValidator.validate(this.newAddress);

        return this.adjustAddressValRes;
    }

    @action resetNewAddress = () => {
        for (const field in this.newAddress) {
            this.newAddress[field] = '';
        }

        this.editing = false;
        this.editableAddress = '';
        this.place = null;
    };

    @action saveAddress() {
        this.deliveryInstructionValRes = deliveryInstructionValidator.validate({
            deliveryInstruction: this.newAddress.deliveryInstruction,
        });

        if (!this.deliveryInstructionValRes.pass()) {
            return;
        }

        this.isSavingAddress = true;

        services.api
            .GetUser()
            .then((user) => {
                const requestBody = this.getRequestBody(user.id);
                return services.api.CreateAddress(requestBody);
            })
            .then(services.api.GetAddresses.runFresh)
            .then((addresses) => {
                this.sendTrackingEvents(this.newAddress);

                this.addressesFetcher = RESOURCE.Success(addresses);
                this.isSavingAddress = false;
                this.showingPopup = '';
            })
            .then(() => {
                this.resetNewAddress();
                menuListStore.refreshMenu();
            })
            .catch((response) => {
                this.addressesFetcher = RESOURCE.Failure(
                    response.message,
                    response
                );
            });
    }

    @action setAddressType = (type) => {
        this.newAddress.addressType = type;
        this.adjustAddressValRes = new ValidationResult();
        this.deliveryInstructionValRes = new ValidationResult();
    };

    @action setDeliveryInstruction(instruction) {
        this.newAddress.deliveryInstruction = instruction;
        this.adjustAddressValRes = new ValidationResult();
        this.deliveryInstructionValRes = new ValidationResult();
    }

    @action deleteAddress = (address) => {
        this.addressBeingDeleted = MAYBE.Some(address);
        this.addressesDeleter = RESOURCE.Loading;

        services.api
            .DeleteAddress({ id: address.id })
            .then((response) => {
                this.addressesDeleter = RESOURCE.Success(response);
            })
            .then(() => this.fetchAddresses())
            .catch((response) => {
                this.addressesDeleter = RESOURCE.Failure(response);
            })
            .then(() => {
                this.addressBeingDeleted = MAYBE.None;
            });
    };

    @action fetchAddressPreference() {
        this.deliveryPreferencesFetcher = RESOURCE.Loading;

        services.api.GetUser().then((user) => {
            services.api
                .GetDeliveryPreferences(this.getRequestBody(user.id))
                .then((preferences) => {
                    this.deliveryPreferencesFetcher =
                        RESOURCE.Success(preferences);
                });
        });
    }

    getExplainingPopup() {
        if (this.newAddress.addressType === OFFICE_ADDRESS) {
            return this.POPUP_OFFICE_EXPLAINED;
        } else if (this.newAddress.addressType === CONDO_ADDRESS) {
            return this.POPUP_CONDO_EXPLAINED;
        }
        return this.POPUP_HOUSE_EXPLAINED;
    }

    sendTrackingEvents(newAddress) {
        clevertap.event.push('Saved Delivery Preferences', {
            'Delivery Preference': newAddress.deliveryInstruction,
        });
    }

    getRequestBody(userId) {
        const newAddress = this.newAddress;
        const place = this.getPlace();
        const lat = place
            ? (place.geometry && place.geometry.location.lat()) ||
              place.latitude
            : this.editableAddress && this.editableAddress.latitude;
        const lng = place
            ? (place.geometry && place.geometry.location.lng()) ||
              place.longitude
            : this.editableAddress && this.editableAddress.longitude;
        const addressType = newAddress.addressType;
        const address = place
            ? place.suggested_address
            : this.editableAddress && this.editableAddress.formatted_address;
        let requestBody = {
            address_incomplete: newAddress.address_incomplete,
            street_no: newAddress.homeNumber,
            building_name: newAddress.buildingName,
            postal_code_new: newAddress.postalCode,
            city: newAddress.city,
            latitude: lat,
            longitude: lng,
            district: newAddress.district,
            address: address,
            display_name: newAddress.displayName,
            name: newAddress.name,
            additional_info: newAddress.additionalInfo,
            delivery_preference_key: newAddress.deliveryInstruction
                ? newAddress.deliveryInstruction
                : null,
            customer_id: userId,
            block: newAddress.block,
            place_id: place
                ? place.place_id
                : this.editableAddress && this.editableAddress.place_id,
            id: this.editableAddress ? this.editableAddress.id : null,
            location_type: addressType ? addressType : null,
        };
        // if (addressType) {
        //     requestBody.location_type = addressType
        // }

        return requestBody;
    }

    getPlace = () => {
        return this.place;
    };
}
