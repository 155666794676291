import React from 'react';
import { observer } from 'mobx-react';

import intl from 'react-intl-universal';

const base = 'order-status-banner';

@observer
export default class extends React.Component {
    render() {
        return (
            <div className={`${base}__content ${base}__content--arrived`}>
                <p className={`${base}__text`}>
                    {intl.get('orderStatus.freeMeal.title')}
                </p>
                <p className={`${base}__text`}>
                    {intl.get('orderStatus.freeMeal.subtitle')}
                </p>
            </div>
        );
    }
}
