import React from 'react';

const base = 'halal-dropdown';

export default ({ children, isShowing, width, onClickOutside }) => {
    return (
        <div
            className={`dropdown__container position--absolute ${
                isShowing ? 'show' : 'hide'
            }`}
            style={{ width }}
        >
            <div
                className={`${base}__backdrop`}
                onMouseLeave={onClickOutside}
                onClick={onClickOutside}
            />
            <div className={`${base}__triangle`} style={{ fontSize: '30px' }} />
            <div className={base}>{children}</div>
        </div>
    );
};
