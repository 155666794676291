import React from 'react';
import { createBrowserHistory } from 'history';
import { inject, observer } from 'mobx-react';
import { services } from '../../../services/index';
import XDate from 'xdate';

import Receipt from './confirmPayment/Receipt';
import OneDate from './confirmPayment/OneDate';
import OneItem from './confirmPayment/OneItem';

import Modal from '../../core/Modal';

import { clearAutoAssignDiscountCampaign } from '../../../services/autoAssignDiscount';
const { clevertap } = window;

const history = createBrowserHistory({
    forceRefresh: true,
});

@inject('store')
@inject('addressAtom')
@inject('headerStore')
@inject('cartStore')
@inject('rewardsStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShowing: false,
        };
    }

    componentDidMount() {
        const { headerStore } = this.props;

        // removes all items related to a discount campaign
        clearAutoAssignDiscountCampaign();

        headerStore.setShowBackLink(true);
        if (
            services.getParam('showGoogleMapsReviewPrompt') &&
            this.props.store.finalOrderSummary.first_order
        ) {
            this.setState({ modalShowing: true });
        }

        history.listen((location, action) => {
            if (action === 'POP') window.location.reload(true);
        });
    }

    renderOrders() {
        const { addressAtom, cartStore, store } = this.props;
        const allAddresses = addressAtom.getAddresses();

        const cart = cartStore.cart;
        // push data into datalayer
        cartStore.sendReceiptDataToDataLayer();

        return cart.map((dateOrder) => {
            const itemsLine = dateOrder.menu_item.map((item) => (
                <OneItem
                    key={item.id}
                    quantity={item.quantity}
                    name={item.title_bold || item.name}
                    isAddon={item.addon_category}
                    price={
                        item.special_offer_price
                            ? item.special_offer_price
                            : item.price
                    }
                />
            ));

            const order = store.finalOrderSummary.list.find(
                (order) =>
                    new XDate(order.delivery_date).toString('dd-MM-yyyy') ===
                    dateOrder.date
            );

            return (
                <OneDate
                    key={dateOrder.date}
                    items={itemsLine}
                    date={dateOrder.date}
                    address={allAddresses.find(
                        (address) => address.id === dateOrder.address_id
                    )}
                    timeslot={order.timeframe}
                />
            );
        });
    }

    handleHideClicked = (clickedYes) => {
        this.setState({ modalShowing: false });

        clevertap.event.push('Prompted for Google Maps Review', {
            accepted: clickedYes,
        });
    };

    render() {
        const { store, rewardsStore } = this.props;
        const { user, finalOrderSummary, orderSummary } = store;
        const discountCode = rewardsStore.code;

        return (
            <div>
                <Receipt
                    user={user}
                    orderSummary={orderSummary}
                    finalOrderSummary={finalOrderSummary}
                    orders={this.renderOrders()}
                    discountCode={discountCode}
                />
                <Modal
                    showModal={this.state.modalShowing}
                    showBackdropOnMobile={true}
                    showCloseIcon={true}
                    closeButtonClasses={`login-popup__close`}
                    contentClasses={`review-popup__container modal__content--golden-ratio modal__content--extra-margin`}
                    onBackdropClicked={() => {
                        this.handleHideClicked(false);
                    }}
                    onCloseClicked={() => {
                        this.handleHideClicked(false);
                    }}
                >
                    <p className="lh--125">
                        Would you like to leave a review?
                        <br />
                        Support {services.getParam('brandName')} and give 5
                        stars.
                    </p>
                    <a
                        href={services.getParam('googleMapsReviewUrl')}
                        target="_blank"
                    >
                        <button
                            onClick={() => {
                                this.handleHideClicked(true);
                            }}
                            className="mtm button button--medium button--success text--center width--50 display--inline-block border--rounded-left"
                        >
                            YES
                        </button>
                    </a>
                    <button
                        onClick={() => {
                            this.handleHideClicked(false);
                        }}
                        className="button button--medium button--outline text--center width--50 display--inline-block border--rounded-right"
                    >
                        NO
                    </button>
                </Modal>
            </div>
        );
    }
}
