/*global google*/

import React, { Component, Fragment } from 'react';
import StarRatings from 'react-star-ratings';
import { CircleLoader } from './Loading';
import Arrows from '../page/careersPage/Arrows';
import {
    MEDIUM_SCREEN_MEDIA,
    SMALL_SCREEN_MEDIA,
} from '../../services/constants';
import { cities, GetCity } from '../../services/city';
import config from '../../configs/parameters';

const base = 'google-reviews';

export default class GoogleReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            show: false,
            reviews: [],
        };

        this.content =
            GetCity() === cities.kl
                ? [
                      {
                          name: 'athirah yahya',
                          avatarUrl:
                              'https://lh3.ggpht.com/-7D1pj_Olvjg/AAAAAAAAAAI/AAAAAAAAAAA/O2OXbvaO1HA/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'i just like the apps. its easy, the navigation was so clear and the description for every meals are all informative. The taste were great and i am so happy with the food, packaging, delivery and also the apps.',
                          rating: 5,
                      },
                      {
                          name: 'Aina Azmi',
                          avatarUrl:
                              'https://lh4.ggpht.com/-FwNqqVFIAO8/AAAAAAAAAAI/AAAAAAAAAAA/k8G3lfm5k-s/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Foods all nice and healthy. So far the drivers are friendly with a bright smile. The website and phone apps easy to use. Overall, 5 stars for you guys. Good job, and hope you can maintain this 5 stars service.\n',
                          rating: 5,
                      },
                      {
                          name: 'Vick Menon',
                          avatarUrl:
                              'https://lh6.ggpht.com/-vWkLcYO5zyE/AAAAAAAAAAI/AAAAAAAAAAA/ifVzeyABdxQ/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: "The menu was so easy to use and easy to handle. A friendly user interface and a very good set of menu, That caters to people who can't eat certain kinds of meat. Thank you for the wonderful service",
                          rating: 5,
                      },
                      {
                          name: 'Rebecca Tan',
                          avatarUrl:
                              'https://lh3.ggpht.com/-kMaEMhwAoME/AAAAAAAAAAI/AAAAAAAAAAA/3qm_zmxvTas/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: `Had a seamless experience placing my order on ${config.brandName}' website. Looking forward to lunch, every single time I order with you guys. :) Keep up the great work!`,
                          rating: 5,
                      },
                      {
                          name: 'Yunna Tan',
                          avatarUrl:
                              'https://lh5.ggpht.com/-P-Cm-Pm73Aw/AAAAAAAAAAI/AAAAAAAAAAA/5E-a-yqISxU/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: `${config.brandName} very convinient and the food is gooddddddddddd, yumyummmm\n`,
                          rating: 5,
                      },
                      {
                          name: 'Verena Chin',
                          avatarUrl:
                              'https://lh6.ggpht.com/-qdUeeGOCxz8/AAAAAAAAAAI/AAAAAAAAAAA/XPQFliL8x24/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'My first time trying out the food.  Ordered the Soft Herb Crusted Perch and Honey BBQ Chicken.  Both dish were yummy and the best part it is not oily, salty and full of msg.  Will order again soon.  Thank you for delivering to Taman TTDI Jaya Shah Alam',
                          rating: 5,
                      },
                      {
                          name: 'Hajar Hajar',
                          avatarUrl:
                              'https://lh6.ggpht.com/-8ZDF1KmgQbs/AAAAAAAAAAI/AAAAAAAAAAA/jYVdCWCqYSg/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: `I love that they have excellent customer service. appreciate their assistance when i had trouble ordering my food. keep up the good work, ${config.brandName} team!`,
                          rating: 5,
                      },
                      {
                          name: 'Beto Bd',
                          avatarUrl:
                              'https://lh6.ggpht.com/-sxUuebdmgCQ/AAAAAAAAAAI/AAAAAAAAAAA/RhNwgAGQkN4/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: "I would recommend this apps and service. I once ordered my dinner and suddenly I had to go outstation plus my phone are not working to cancel the order. I had to use my friends' phone to chat with their customer service and they help me very well. definitely 5 stars for them",
                          rating: 5,
                      },
                      {
                          name: 'siti mariam',
                          avatarUrl:
                              'https://lh5.ggpht.com/--8i0KqqZYMY/AAAAAAAAAAI/AAAAAAAAAAA/hEDFAmKd6WU/s40-ba3-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Delivery is on time, and they even inform in advance if slight late. Most important, the food is really good! Especially their tenderloin is always premium and prepared very well.',
                          rating: 5,
                      },
                      {
                          name: 'Tomas AS',
                          avatarUrl:
                              'https://lh3.ggpht.com/-KGkiOCtaQEk/AAAAAAAAAAI/AAAAAAAAAAA/fr9GiIqRxao/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Delicious food at an amazing price a amazingly reliable delivery!',
                          rating: 5,
                      },
                  ]
                : [
                      {
                          name: 'Jan Klimo',
                          avatarUrl:
                              'https://lh5.ggpht.com/-ZjzhFxGSGdA/AAAAAAAAAAI/AAAAAAAAAAA/_OB5UViOLV8/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Head and shoulders above any other local food delivery service. Flawless customer support, excellent app, tasty food. Overall superb value.',
                          rating: 5,
                      },
                      {
                          name: 'Jane',
                          avatarUrl:
                              'https://lh3.ggpht.com/-ubW0M0OoJ-Q/AAAAAAAAAAI/AAAAAAAAAAA/E7aYMcB5fj8/s40-ba3-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Healthy and tasty. Good rotation of dishes. Good value. New app is easy to use. Most importantly always delivered on time.',
                          rating: 5,
                      },
                      {
                          name: 'Rattana .Tangkitsombat',
                          avatarUrl:
                              'https://lh3.ggpht.com/-kVD79jf5c3s/AAAAAAAAAAI/AAAAAAAAAAA/4NGX-R29l3o/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Premium meals. Good offer!',
                          rating: 5,
                      },
                      {
                          name: 'Nakarin Paringkarn',
                          avatarUrl:
                              'https://lh4.ggpht.com/-1XIs_zkncdA/AAAAAAAAAAI/AAAAAAAAAAA/1JDoGOdtsc4/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Great services! The website is very lovely',
                          rating: 5,
                      },
                      {
                          name: 'C Chawla',
                          avatarUrl:
                              'https://lh5.ggpht.com/-jPegU__ax8I/AAAAAAAAAAI/AAAAAAAAAAA/8HrTEZK-rZU/s40-ba2-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Great tasting food. Useful nutrition data at point of order. Delivers on time and well presented.',
                          rating: 5,
                      },
                      {
                          name: 'Zachary Strebeck',
                          avatarUrl:
                              'https://lh3.ggpht.com/-CQM3FV-fzfI/AAAAAAAAAAI/AAAAAAAAAAA/4HfS-LVWrYU/s40-ba4-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Delicious food, prepared fresh and delivered on time. Love it!',
                          rating: 5,
                      },
                      {
                          name: 'Pornrumpa Taluengchit',
                          avatarUrl:
                              'https://lh4.ggpht.com/-RU1WuQn3sKk/AAAAAAAAAAI/AAAAAAAAAAA/qSf7cSiKO80/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'I always come back for their poke bowl! Honest food i love 😊',
                          rating: 5,
                      },
                      {
                          name: 'Angela Somwaiya',
                          avatarUrl:
                              'https://lh3.ggpht.com/-pwfYgsvW2AA/AAAAAAAAAAI/AAAAAAAAAAA/2gITKeF-pwo/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Fresh healthy selections, same day delivery - I love it!',
                          rating: 5,
                      },
                      {
                          name: 'Aphatsara Khongthon',
                          avatarUrl:
                              'https://lh4.ggpht.com/-x8lIoXbFAMs/AAAAAAAAAAI/AAAAAAAAAAA/Ld2-tABlF9o/s40-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Very easy to use. App is very clean and your food is the best !!!',
                          rating: 5,
                      },
                      {
                          name: 'Arthur Tan',
                          avatarUrl:
                              'https://lh4.ggpht.com/-EQbEhYVajU4/AAAAAAAAAAI/AAAAAAAAAAA/Hqh9uIyTmXM/s40-ba2-c0x00000000-cc-rp-mo/photo.jpg',
                          review: 'Deliciously good and healthy meals delivered to your home.',
                          rating: 5,
                      },
                  ];
    }

    componentDidMount() {
        this.setState({
            show: true,
            rating: 4.7,
            total_ratings: 7639,
        });

        this.getRandomizedReviews();
    }

    renderStars(rating) {
        return (
            <StarRatings
                rating={rating}
                starEmptyColor="#cdcdcd"
                starRatedColor={'#ffa300'}
                starDimension="20px"
                starSpacing="0"
            />
        );
    }

    onClickLeft = () => {
        const name = 'reviews-container';
        const scrollLength =
            document.getElementById(`review-container`).offsetWidth +
            (window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 15 : 25);
        const carousel = document.getElementById(name);
        if (this.state.activeIndex !== 0) {
            this.setState({ activeIndex: this.state.activeIndex - 1 });
        }

        carousel.scrollLeft = carousel.scrollLeft - scrollLength;
    };

    onClickRight = () => {
        const name = 'reviews-container';
        const scrollLength =
            document.getElementById(`review-container`).offsetWidth +
            (window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 15 : 25);
        const carousel = document.getElementById(name);
        if (this.state.activeIndex !== 3) {
            this.setState({
                activeIndex: this.state.activeIndex + 1,
                disableLeftArrow: false,
            });
        }

        carousel.scrollLeft = carousel.scrollLeft + scrollLength;
    };

    getRandomizedReviews = () => {
        const max = this.content.length - 3;
        const randomNumber = Math.floor(Math.random() * max);

        if (randomNumber === max) {
            this.setState({ reviews: this.content.slice(randomNumber) });
        } else {
            this.setState({
                reviews: this.content.slice(randomNumber, randomNumber + 3),
            });
        }
    };

    render() {
        const {
            show,
            rating,
            total_ratings,
            disableLeftArrow,
            disableRightArrow,
        } = this.state;
        const smallAndMediumScreens =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches ||
            window.matchMedia(MEDIUM_SCREEN_MEDIA).matches;

        return (
            <div className={`${base} container`}>
                <div className={`${base}__header-container`}>
                    <h3 className={`${base}__header text--bolder`}>
                        Thoughts from our customers
                    </h3>
                </div>
                {show ? (
                    <Fragment>
                        <div className={`${base}__rating-container`}>
                            <div className={`${base}__rating`}>
                                <p
                                    className={`${base}__rating-score text--bolder`}
                                >
                                    {rating}
                                </p>
                                <p className={`${base}__rating-count`}>
                                    out of {total_ratings} reviews
                                </p>
                            </div>
                            <div className={`${base}__star-rating`}>
                                <svg
                                    className={`${base}__google-logo iconmoon-icon`}
                                >
                                    <use xlinkHref="#icon-google-logo" />
                                </svg>
                                {this.renderStars(4.7)}
                            </div>
                        </div>
                        <div
                            className={`${base}__reviews-container`}
                            id={'reviews-container'}
                        >
                            {this.state.reviews.map((c, i) => (
                                <div
                                    className={`${base}__overflow-container`}
                                    key={i}
                                >
                                    <div
                                        className={`${base}__review-container`}
                                        key={i}
                                        id={'review-container'}
                                    >
                                        <div className={`${base}__review`}>
                                            <div
                                                className={`${base}__review-user-container`}
                                            >
                                                <div
                                                    className={`${base}__review-avatar`}
                                                    style={{
                                                        background: `url(${c.avatarUrl})`,
                                                    }}
                                                />
                                                <div
                                                    className={`${base}__review-name-container`}
                                                >
                                                    <p
                                                        className={`${base}__review-name text--bolder`}
                                                    >
                                                        {c.name}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className={`${base}__review-body`}
                                            >
                                                <p
                                                    className={`${base}__review-content`}
                                                >
                                                    {c.review}
                                                </p>
                                            </div>
                                            <div className={`${base}__stars`}>
                                                {this.renderStars(c.rating)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {smallAndMediumScreens && (
                            <div>
                                <Arrows
                                    centerAlign={true}
                                    onClickLeft={this.onClickLeft}
                                    onClickRight={this.onClickRight}
                                    disableLeftArrow={disableLeftArrow}
                                    disableRightArrow={disableRightArrow}
                                />
                            </div>
                        )}
                    </Fragment>
                ) : (
                    <div className="display--flex flex-column justify--center">
                        <CircleLoader className="m0a" />
                        <p className="mtm center--horizontal">
                            Loading reviews
                        </p>
                    </div>
                )}
            </div>
        );
    }
}
