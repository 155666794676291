import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import SnackAndDessertItems from './SnackAndDessertItems';

const base = 'terminal-addon-items-popup';

@inject('terminalMenuStore')
@inject('terminalCartStore')
@observer
export default class PopupSnackDessertItems extends Component {
    state = {
        timer: 60,
        timeDisplay: '1:00',
    };
    closeAddonPopup = () => {
        const { terminalMenuStore, terminalCartStore } = this.props;
        terminalMenuStore.setShowPopup(terminalMenuStore.POPUP_PHONE_NUMBER);

        terminalCartStore.getTotalPrice();
    };

    componentDidMount() {
        const interval = setInterval(() => {
            let time = this.state.timer;
            if (time == 0) {
                clearInterval(interval);
                this.closeAddonPopup();
            } else {
                time -= 1;
                if (time < 10) {
                    this.setState({ timeDisplay: '0:0' + time });
                } else {
                    this.setState({ timeDisplay: '0:' + time });
                }
            }

            this.setState({ timer: time });
        }, 1000);
    }

    addAddonItemToCart = (item) => {
        const { terminalCartStore } = this.props;
        terminalCartStore.addToCart(item);
        terminalCartStore.updateInCartOrder();
        this.closeAddonPopup();
    };

    render() {
        const { items } = this.props;

        return (
            <div className={`${base}`}>
                <div className={`${base}__addon_item_header`}>
                    <div className="display--flex flex-align-items-center">
                        <p className={`snack-dessert-text`}>{intl.get('terminal.popupSnackDessert.title')}</p>
                        <div className={`timer-container`}>
                            <img width="16px" height="16px" src="/timer.png" />
                            <span style={{ marginLeft: '10px', width: '36px' }}>{this.state.timeDisplay}</span>
                        </div>
                    </div>
                </div>
                <div className={`${base}__mobile_header`}>
                    {items && items.length > 0 && (
                        <div className={`highlight-text-container`}>
                            <p className="save-text">{intl.get('terminal.popupSnackDessert.highlightText')}</p>
                            <p className="rm-price">ITEM</p>
                        </div>
                    )}
                    <div className={`${base}__text_close`}>
                        <p className={`addon-text`}>{intl.get('terminal.popupSnackDessert.title')}</p>

                        <svg
                            className={`${base}__close_btn`}
                            onClick={this.closeAddonPopup}
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="1.45337"
                                y="0.0144653"
                                width="17.8442"
                                height="1.3587"
                                transform="rotate(45 1.45337 0.0144653)"
                                fill="#131415"
                            />
                            <rect
                                width="17.8442"
                                height="1.3587"
                                transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.1104 0.0144653)"
                                fill="#131415"
                            />
                        </svg>
                    </div>
                </div>
                <div className={`${base}__snack-dessert-items`}>
                    {items &&
                        items.length > 0 &&
                        items.map((item) => (
                            <SnackAndDessertItems
                                className="item"
                                key={item.today_menu_id}
                                item={item}
                                addAddonItemToCart={(item) => this.addAddonItemToCart(item)}
                            />
                        ))}
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div
                        className={`${base}__skip-snack-dessert-items item-container cursor-pointer`}
                        onClick={this.closeAddonPopup}
                    >
                        <p>{intl.get('terminal.popupSnackDessert.skip')}</p>
                    </div>
                </div>
            </div>
        );
    }
}
