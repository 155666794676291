import React, { Component } from 'react';
import Modal from '../../../core/Modal';
import PopupTypeAddress from './PopupTypeAddress';
import PopupAdjustAddress from './PopupAdjustAddress';
import PopupDeliveryNote from './PopupDeliveryNote';
import PopupOfficeExplained from './PopupOfficeExplained';
import PopupCondoExplained from './PopupCondoExplained';
import PopupHouseExplained from './PopupHouseExplained';
import { inject, observer } from 'mobx-react';

const base = 'shopping-cart-address';

@inject('deliveryAddressStore', 'addressAutocompleteStore')
@observer
export default class AddressPopup extends Component {
    handleShowBackButton = () => {
        const store = this.props.deliveryAddressStore;

        if (store.editing && store.showingPopup === store.POPUP_TYPING) {
            return false;
        } else if (store.showingPopup === store.POPUP_TYPING) {
            return store.showMap;
        } else {
            return true;
        }
    };

    handleNavigateBack = () => {
        const store = this.props.deliveryAddressStore;

        if (store.showingPopup === store.POPUP_TYPING) {
            store.place = null;
            this.props.addressAutocompleteStore.getPlacePredictions(null);
            store.showMap = false;
        } else if (store.showingPopup === store.POPUP_ADJUSTING) {
            store.place = null;
            this.props.addressAutocompleteStore.getPlacePredictions(null);
            store.showingPopup = store.POPUP_TYPING;
        } else if (store.showingPopup === store.POPUP_NOTE) {
            store.showingPopup = store.POPUP_ADJUSTING;
        } else if (
            store.showingPopup === store.POPUP_OFFICE_EXPLAINED ||
            store.showingPopup === store.POPUP_HOUSE_EXPLAINED
        ) {
            store.showingPopup = store.POPUP_NOTE;
        }
    };

    render() {
        const store = this.props.deliveryAddressStore;

        const contentClasses = `${base}__popup-content--${store.showingPopup} modal__content--golden-ratio`;
        const showBackButton = this.handleShowBackButton();

        return (
            <Modal
                showModal={store.showingPopup}
                contentClasses={contentClasses}
                closeButtonClasses="fill--white"
                closeButtonMargin="modal__extra-margin"
                onBackClicked={this.handleNavigateBack}
                onCloseClicked={::store.hidePopup}
                showCloseIcon={true}
                showBackIcon={showBackButton}
            >
                {store.showingPopup === store.POPUP_TYPING ? (
                    <PopupTypeAddress />
                ) : null}
                {store.showingPopup === store.POPUP_ADJUSTING ? (
                    <PopupAdjustAddress />
                ) : null}
                {store.showingPopup === store.POPUP_NOTE ? (
                    <PopupDeliveryNote date={this.props.date} />
                ) : null}
                {store.showingPopup === store.POPUP_OFFICE_EXPLAINED ? (
                    <PopupOfficeExplained />
                ) : null}
                {store.showingPopup === store.POPUP_CONDO_EXPLAINED ? (
                    <PopupCondoExplained />
                ) : null}
                {store.showingPopup === store.POPUP_HOUSE_EXPLAINED ? (
                    <PopupHouseExplained />
                ) : null}
            </Modal>
        );
    }
}
