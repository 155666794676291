import * as Kuala_Lumpur_BaseLayout from './Kuala_Lumpur_BaseLayout';
import * as Bangkok_BaseLayout from './Bangkok_BaseLayout';
import { services } from '../../../services/index';

const baseLayouts = {
    Kuala_Lumpur: Kuala_Lumpur_BaseLayout,
    Bangkok: Bangkok_BaseLayout,
};

const baseLayout = baseLayouts[services.getParam('city')];

export default {
    ...baseLayout,
};
