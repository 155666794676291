import React from 'react';
import HamburgerMenu from './HamburgerMenu';
import Cookies from 'js-cookie';
import qs from 'query-string';
import { inject, observer } from 'mobx-react';

const base = 'hamburger-icon';

@inject('loginPopupStore', 'menuHeaderStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isOpening: false };
        this.handleShowMenu = ::this.handleShowMenu;
        this.handleHideMenu = ::this.handleHideMenu;
    }

    componentDidMount() {
        const Hammer = require('hammerjs');
        const swipeNode = document.getElementById('swipe-node');
        const hammerEle = new Hammer.Manager(swipeNode);
        const swipeLeft = new Hammer.Swipe();

        hammerEle.add(swipeLeft);
        hammerEle.on('swipeleft', () => {
            this.setState({ isOpening: false });
        });
    }

    handleShowMenu() {
        const { menuHeaderStore } = this.props;
        this.setState({ isOpening: true });
        menuHeaderStore.setShowAppDownloadBanner(false, false);
    }

    handleHideMenu() {
        const { menuHeaderStore } = this.props;
        this.setState({ isOpening: false });
        menuHeaderStore.setShowAppDownloadBanner(true, false);
    }

    handleClickBackDrop = () => {
        const params = qs.parse(window.location.search);
        this.handleHideMenu();
    };

    render() {
        const { whiteIcons, hideBranchBanner } = this.props;
        return (
            <div className={base}>
                <div onClick={this.handleShowMenu} className="cursor-pointer">
                    <div
                        className={
                            whiteIcons
                                ? `${base}__line white`
                                : `${base}__line dark`
                        }
                    />
                    <div
                        className={
                            whiteIcons
                                ? `${base}__line white`
                                : `${base}__line dark`
                        }
                    />
                    <div
                        className={
                            whiteIcons
                                ? `${base}__line white`
                                : `${base}__line dark`
                        }
                    />
                </div>
                {this.state.isOpening ? (
                    <div
                        className={`${base}__backdrop`}
                        onClick={this.handleClickBackDrop}
                    />
                ) : null}
                <div id="swipe-node">
                    <HamburgerMenu
                        isOpening={this.state.isOpening}
                        onHidingMenu={this.handleHideMenu}
                        hideBranchBanner={hideBranchBanner}
                    />
                </div>
            </div>
        );
    }
}
