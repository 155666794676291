import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import CartIcon from '../CartIcon';
import { getDateDisplayFull, parseDate } from '../../../services/dateTime';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

@inject('dishHeaderStore', 'userStore')
@observer
export default class extends React.Component {
    render() {
        const base = 'dish-header';
        const { backLink } = this.props.dishHeaderStore;
        const phoneView = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return (
            <div className={base} id={base}>
                <div
                    className={`${base}__header-container display--flex container`}
                >
                    <div>
                        <Link to={backLink.href}>
                            <svg
                                className={`${base}__back-icon iconmoon-icon mrs`}
                            >
                                <use xlinkHref="#icon-chevron-thin-up" />
                            </svg>
                        </Link>
                        <Link
                            className="link--no-underline text--black fs--small-medium mrl text--bold hide--if-small"
                            to={backLink.href}
                        >
                            {backLink.text}
                        </Link>
                    </div>
                    <div className={`${base}__date-container hide--if-large`}>
                        <span className="text--bold text--sofiapro mrs">
                            {getDateDisplayFull(
                                parseDate(this.props.dish.menu_date)
                            )}
                        </span>
                    </div>
                    {phoneView ? (
                        <CartIcon />
                    ) : (
                        <div className={`${base}__cart-icon-container`}>
                            <CartIcon />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
