import React from 'react';
import { inject, observer } from 'mobx-react';

const base = 'terminal-mobile-order-type-screen';

const ORDER_TYPES = {
    DINE_IN: 'DINE_IN',
    TAKE_AWAY: 'TAKE_AWAY',
};

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);
    }
    handleOrderClick = (orderType) => {
        const { terminalMenuStore, terminalCartStore } = this.props;
        terminalCartStore.loadItems([]);
        terminalMenuStore.setOrderTypeIfExistsAndReloadMenu(orderType);
        terminalMenuStore.setShowMobileScreen(terminalMenuStore.mobileScreens.ITEM);
    };

    render() {
        const dineInImg = 'https://image.popcontent.link/dinein_new_illustration.svg';
        const takeAwayImg = 'https://image.popcontent.link/new_takeaway_type.svg';

        return (
            <div className={base}>
                <div onClick={() => this.handleOrderClick(ORDER_TYPES.DINE_IN)} className={`${base}__order_type`}>
                    <img className={`${base}__order_type_img`} src={dineInImg} alt="dine-in image" />
                    <p className={`${base}__order_type_name`}>Dine-in</p>
                </div>
                <div onClick={() => this.handleOrderClick(ORDER_TYPES.TAKE_AWAY)} className={`${base}__order_type`}>
                    <img className={`${base}__order_type_img`} src={takeAwayImg} alt="dine-in image" />
                    <p className={`${base}__order_type_name`}>Takeaway</p>
                </div>
            </div>
        );
    }
}
