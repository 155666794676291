export const scrollDocumentTo = (to, duration = 200) => {
    if (duration < 0) {
        return;
    }

    let scrollTop =
        document.body.scrollTop + document.documentElement.scrollTop;
    const difference = to - scrollTop;
    const perTick = (difference / duration) * 10;

    setTimeout(function () {
        scrollTop += perTick;

        document.body.scrollTop = scrollTop;
        document.documentElement.scrollTop = scrollTop;

        if (scrollTop === to) {
            return;
        }

        scrollDocumentTo(to, duration - 10);
    }, 10);
};

export const loadScript = (url, onLoad, onError) => {
    const scriptTag = document.createElement('script');

    scriptTag.src = url;
    scriptTag.onload = onLoad;
    scriptTag.onerror = onError;
    document.head.appendChild(scriptTag);
};

export const onDocumentIdle = ({ seconds, callback, checkEverySeconds }) => {
    window.idleTimer = 0;
    window.clearInterval(window.idleIntervalHandle);

    document.body.addEventListener('touchstart', () => {
        window.idleTimer = 0;
    });

    document.body.addEventListener('click', () => {
        window.idleTimer = 0;
    });

    window.idleIntervalHandle = window.setInterval(() => {
        window.idleTimer += checkEverySeconds;

        if (window.idleTimer >= seconds) {
            window.idleTimer = 0;

            callback();
        }
    }, checkEverySeconds * 1000);
};

export const mobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
export const iOSDevice = /iPad|iPhone|iPod/i.test(navigator.userAgent);
export const androidDevice = /Android/i.test(navigator.userAgent);
