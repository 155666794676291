import Kuala_Lumpur_CreditCardStore from './Kuala_Lumpur_CreditCardStore';
import Bangkok_CreditCardStore from './Bangkok_CreditCardStore';
import { services } from '../../services/index';

const creditCardStores = {
    Kuala_Lumpur: Kuala_Lumpur_CreditCardStore,
    Bangkok: Bangkok_CreditCardStore,
};

const CreditCardStore = creditCardStores[services.getParam('city')];

export default CreditCardStore;
