import React from 'react';
import { inject, observer } from 'mobx-react';

const base = 'shopping-cart-address__popup-header';

@inject('deliveryAddressStore')
@observer
export default class extends React.Component {
    render() {
        const { customClasses } = this.props;

        return (
            <div className={`${base}`}>
                <p
                    className={`${base}__text text--bolder show--if-large ${customClasses}`}
                >
                    {this.props.text}
                </p>
            </div>
        );
    }
}
