import { action, observable } from 'mobx';

class DropdownStore {
    DROPDOWN_CART = 'CartDropdown';
    DROPDOWN_WALLET = 'WalletDropdown';
    DROPDOWN_MENU = 'MenuDropdown';
    DROPDOWN_ACCOUNT_SWITCHER = 'AccountSwitcherDropdown';

    @observable showingDropdown = '';

    @action handleShowDropdown(dropdown) {
        clearTimeout(this.closeDropdownTimeout);
        this.closeDropdownTimeout = setTimeout(() => {
            this.showingDropdown = dropdown;
        }, 0);
    }

    @action handleHideDropdown() {
        this.closeDropdownTimeout = setTimeout(() => {
            this.showingDropdown = null;
        }, 500);
    }

    @action handleHideDropdownImmediately() {
        this.showingDropdown = null;
    }
}

const store = new DropdownStore();
export default store;
