import { observable, action, when } from 'mobx';
import { services } from '../services/index';
import menuListStore from './menuListStore';

class DishStore {
    GENERAL_VIEW = 'GeneralView';
    INGREDIENT_VIEW = 'IngredientView';
    CHEF_VIEW = 'ChefView';
    NUTRITIONAL_VIEW = 'NutritionalView';
    REVIEWS_VIEW = 'ReviewsView';
    HALAL_VIEW = 'HalalView';

    @observable dish = null;
    @observable loading = true;
    @observable view = 'GeneralView';
    @observable reviews = [];
    @observable showHalal = false;

    getView() {
        return this.view;
    }
    setView(view) {
        return () => {
            this.view = view;
        };
    }

    setDish(dish) {
        this.dish = dish;
    }

    @action
    handleGetDish = (id, handleError) => {
        this.loading = true;
        when(
            () => menuListStore.validDates,
            () => {
                services.api
                    .GetDish({
                        id,
                        customTimeslot: menuListStore.activeTimeslotObj.id,
                    })
                    .then((dish) => {
                        this.dish = dish;
                        this.loading = false;
                    })
                    .catch(() => {
                        handleError();
                        this.loading = false;
                    });
            }
        );
    };

    @action handleGetReviews = (id) => {
        services.api.GetReviewsForDish({ id }).then((review) => {
            this.reviews = review.ratings;
        });
    };
}

const store = new DishStore();
export default store;
