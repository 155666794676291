import React from 'react';
import { inject, observer } from 'mobx-react';

import { services } from '../../../services/index';
import intl from 'react-intl-universal';
import SubheaderUI from './SubheaderUI';
import ButtonUI from './ButtonUI';

const base = 'login-popup';

@inject('loginPopupStore')
@observer
export default class extends React.Component {
    renderSubHeader = () => {
        return (
            <SubheaderUI text={intl.get('user.forgotPasswordInstruction')} />
        );
    };

    renderButton = () => {
        const { loginPopupStore } = this.props;

        return (
            <ButtonUI
                text={intl.get('loginPopup.resetPassword.cta')}
                onClick={::loginPopupStore.showLogin}
                customClasses="button--success"
            />
        );
    };
    render() {
        const helpCenter = services.getParam('freshDeskHc');

        return (
            <div className={base}>
                <h3 className={`${base}__title`}>
                    {intl.get('loginPopup.resetPassword.title')}
                </h3>
                {this.renderSubHeader()}
                {this.renderButton()}
                <div className={`${base}__cannot-login-container`}>
                    <a
                        href={helpCenter}
                        className={`text--bolder text--primary text--no-underlined`}
                    >
                        {intl.get('user.stillCannotLogin')}
                    </a>
                </div>
            </div>
        );
    }
}
