import React, { Component } from 'react';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

const base = 'login-popup';

export default class ButtonUI extends Component {
    render() {
        const {
            customClasses,
            text,
            icon,
            loading,
            onClick,
            marginTop,
            id,
            customContent,
            marginText,
            disabled,
        } = this.props;
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return (
            <div
                id={id}
                className={`${base}__button-container`}
                style={{ marginTop }}
            >
                <button
                    className={`${base}__button button width--100 text--thin button--bigger-on-mobile ${
                        disabled ? 'button--disabled' : ''
                    } ${customClasses} ${loading && 'button--busy'}`}
                    onClick={onClick}
                >
                    {icon && (
                        <div className={`${base}__button-icon-container`}>
                            {icon}
                        </div>
                    )}
                    {customContent ? (
                        customContent
                    ) : (
                        <span style={{ marginRight: !isMobile && marginText }}>
                            {text}
                        </span>
                    )}
                </button>
            </div>
        );
    }
}
