import Validator from '../../services/validator';

export default new Validator()
    .add('name', (model) => Boolean(model.name), 'Name is required')
    .add(
        'phoneNumber',
        (model) => Boolean(model.phoneNumber),
        'Phone Number is required'
    )
    .add(
        'emailAddress',
        (model) => Boolean(model.emailAddress),
        'Email Address is required'
    )
    .add(
        'eventType',
        (model) => Boolean(model.eventType),
        'Event Type is required'
    );
