import React from 'react';
import { observer, inject } from 'mobx-react';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import FloatingCta from '../../core/FloatingCta';

@inject('cartStore')
@inject('menuListStore')
@inject('store')
@inject('cartViewStore')
@inject('addressAtom')
@observer
export default class extends React.Component {
    handleGoToCheckout() {
        const { store } = this.props;
        store.handleGoToCheckout();
    }

    render() {
        const base = 'shopping-cart-meal';
        const { cartStore, menuListStore } = this.props;
        const isEmpty = !cartStore.cart.length;
        const isLoading = menuListStore.isLoading;

        if (isEmpty === false) {
            if (window.matchMedia(SMALL_SCREEN_MEDIA).matches === true) {
                return (
                    <FloatingCta
                        onClick={::this.handleGoToCheckout}
                        centerText="ONE MORE STEP"
                        padding={15}
                        busy={isLoading}
                    />
                );
            } else {
                return (
                    <button
                        className={`${base}__next button button--bigger-on-mobile ${
                            isLoading ? 'button--busy' : 'button--success'
                        } width--100`}
                        onClick={::this.handleGoToCheckout}
                        id="one-more-step-btn"
                    >
                        ONE MORE STEP
                    </button>
                );
            }
        }

        return <div />;
    }
}
