import Cookies from 'js-cookie';

export const AnnouncementStorage = {
    setRemember: (cookie) => {
        Cookies.set('announcement', cookie, { expires: 30 });
    },
    willUserSeeAnnouncement: (userId, announcementId) => {
        const stored = Cookies.get('announcement');

        try {
            const value = JSON.parse(stored);

            if (userId in value && value[userId] === announcementId) {
                return false;
            }

            return true;
        } catch (ex) {
            return true; // If not logged in or cookie is malformed, show banner
        }
    },
    clear: () => {
        Cookies.remove('announcement');
    },
};
