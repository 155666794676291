import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

const base = 'bank-selector';

@inject('paymentStore')
@inject('store')
@observer
class BankSelector extends React.Component {
    selectBank(bank) {
        const { paymentStore, store } = this.props;
        const stubOnlineBanking = {
            id: 'billplz-temp',
            card_type: 'Online Banking',
            payment_method_type: 'Omise_Bank',
            description:
                'You will be redirected to complete checkout with FPX payment.',
            fpx_gateway: bank,
        };

        paymentStore.addPaymentMethod(stubOnlineBanking);
        store.setPaymentMethod(stubOnlineBanking);
    }

    render() {
        const { store, paymentStore } = this.props;
        return store.paymentMethod &&
            store.showBankSelector &&
            paymentStore.view === paymentStore.SHOW_VIEW &&
            store.paymentMethod.card_type === 'Online Banking' ? (
            <div className={`${base} well`}>
                <p className="text--bolder flex-1 lh--150">
                    {intl.get('packageCheckout.bankSelector.title')}
                </p>
                <div className="mtm text--gray m0--if-small">
                    <div className="display--flex">
                        <div className="mrm display--inline-flex">
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/krungsibank.jpg"
                                height="35px"
                                alt="Krungsi Bank logo"
                            />
                        </div>
                        <button
                            onClick={() =>
                                this.selectBank('internet_banking_bay')
                            }
                            className={
                                store.paymentMethod.fpx_gateway ===
                                'internet_banking_bay'
                                    ? 'button mbm width--50 button--success width--100-if-small'
                                    : 'button mbm width--50 width--100-if-small'
                            }
                        >
                            {intl.get('packageCheckout.bankSelector.krungsi')}
                        </button>
                    </div>
                    <div className="display--flex">
                        <div className="mrm display--inline-flex">
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/bangkokbank.png"
                                height="35px"
                                alt="Bangkok Bank Logo"
                            />
                        </div>
                        <button
                            onClick={() =>
                                this.selectBank('internet_banking_bbl')
                            }
                            className={
                                store.paymentMethod.fpx_gateway ===
                                'internet_banking_bbl'
                                    ? 'button mbm width--50 button--success width--100-if-small'
                                    : 'button mbm width--50 width--100-if-small'
                            }
                        >
                            {intl.get('packageCheckout.bankSelector.bangkok')}
                        </button>
                    </div>
                    <div className="display--flex">
                        <div className="mrm display--inline-flex">
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/krungthaibank.png"
                                height="35px"
                                width="35px"
                                alt="Krung Thai Bank logo"
                            />
                        </div>
                        <button
                            onClick={() =>
                                this.selectBank('internet_banking_ktb')
                            }
                            className={
                                store.paymentMethod.fpx_gateway ===
                                'internet_banking_ktb'
                                    ? 'button mbm width--50 button--success width--100-if-small'
                                    : 'button mbm width--50 width--100-if-small'
                            }
                        >
                            {intl.get('packageCheckout.bankSelector.krungthai')}
                        </button>
                    </div>
                    <div className="display--flex">
                        <div className="mrm display--inline-flex">
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/siambank.png"
                                height="35px"
                                alt="Siam Bank logo"
                            />
                        </div>
                        <button
                            onClick={() =>
                                this.selectBank('internet_banking_scb')
                            }
                            className={
                                store.paymentMethod.fpx_gateway ===
                                'internet_banking_scb'
                                    ? 'button mbm width--50 button--success width--100-if-small'
                                    : 'button mbm width--50 width--100-if-small'
                            }
                        >
                            {intl.get('packageCheckout.bankSelector.siam')}
                        </button>
                    </div>
                </div>
            </div>
        ) : null;
    }
}

export default BankSelector;
