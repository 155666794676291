import React from 'react';
import { inject, observer } from 'mobx-react';

import intl from 'react-intl-universal';
import { services } from '../../../services/index';

import { MAYBE } from '../../../services/types';
import ShareBox from '../../page/rewardsPage/ShareBox';
import { template } from 'lodash';

const base = 'rating-popup';

@inject('orderRatingStore')
@observer
export default class extends React.Component {
    render() {
        const { orderRatingStore } = this.props;
        const brandName = services.getParam('brandName');

        if (orderRatingStore.order === MAYBE.None) {
            return <div />;
        }

        return (
            <div className={base}>
                <div
                    className={`${base}__header text--bolder text--white text--center`}
                >
                    {intl.get('rating.order')}
                    {orderRatingStore.order.data.order_id}
                </div>
                <div className={`${base}__body ${base}__body--nps`}>
                    <p className={`${base}__title text--bolder mtl mbm`}>
                        {intl.get('rating.npsRating.title')}
                    </p>
                    {orderRatingStore.npsRating > 6 ? (
                        <ShareBox />
                    ) : (
                        <div className="separator separator--green mbl" />
                    )}
                    <p className="lh--125">
                        {intl.get('rating.thankYou.orderAgain')}
                    </p>
                    <div className="mtl">
                        <a
                            href={services.getParam('appStorePath')}
                            className={`${base}__app-store display--inline-block mrm`}
                        >
                            <img
                                className={`${base}__app-button`}
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/app-store-black.png"
                                alt={template(
                                    intl.get('rating.thankYou.altIphone')
                                )({ brandName })}
                            />
                        </a>
                        <a
                            href={services.getParam('googlePlayPath')}
                            className="display--inline-block"
                        >
                            <img
                                className={`${base}__app-button`}
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/google-play-black.png"
                                alt={template(
                                    intl.get('rating.thankYou.altAndroid')
                                )({ brandName })}
                            />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
