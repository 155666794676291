import React, { Component } from 'react';
import ButtonUI from '../../core/loginPopup/ButtonUI';
import intl from 'react-intl-universal';

const base = 'careers__view-jobs';

export default class ViewJobs extends Component {
    onClick = () => {
        window.open('https://odoo.popmeals.com/jobs', '_blank');
    };

    render() {
        return (
            <div className={base}>
                <div className={`${base}__container`}>
                    <div className={`${base}__information`}>
                        <span className={`${base}__highlight text--bolder`}>
                            {intl.get('careers.viewJobs.numberOfJobs')}
                        </span>
                        <span>{intl.get('careers.viewJobs.in')}</span>
                        <span className={`${base}__highlight text--bolder`}>
                            {intl.get('careers.viewJobs.locations')}
                        </span>
                        <span>{intl.get('careers.viewJobs.across')}</span>
                        <span className={`${base}__highlight text--bolder`}>
                            {intl.get('careers.viewJobs.teams')}
                        </span>
                    </div>
                    <div className={`${base}__button`}>
                        <ButtonUI
                            customClasses={`${base}__cta button--success`}
                            text={intl.get('careers.viewJobs.cta')}
                            onClick={this.onClick}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
