import React from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import intl from 'react-intl-universal';

import { RESOURCE } from '../../../services/types';

import BusyBox from '../../page/profilePage/BusyBox';
import Address from './Address';

const base = 'profile';

@inject('orderStatusStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.orderStatusStore.fetchAddresses();
    }

    handleAddressClicked = (address) => {
        this.props.orderStatusStore.updateAddress(address);
    };

    hideChangeAddress = () => {
        this.props.orderStatusStore.isShowingChangeAddress = false;
    };

    render() {
        if (
            RESOURCE.Success.is(
                this.props.orderStatusStore.addressesFetcher
            ) === false
        ) {
            return <BusyBox headerText="Change address for this delivery" />;
        }

        const addresses = this.props.orderStatusStore.addressesFetcher.data;
        return (
            <div className={`${base}__box text--left`}>
                <div className={`${base}__box-header lh--125`}>
                    {intl.get('changeOrder.changeAddress')}
                </div>
                <div className="modal__close">
                    <button
                        className="button--nothing"
                        onClick={this.hideChangeAddress}
                    >
                        <svg className="modal__close-icon">
                            <use xlinkHref="#svg--x-close" />
                        </svg>
                    </button>
                </div>
                <div className={`${base}__box-body p0`}>
                    {addresses.map((address) => {
                        return (
                            <Address
                                key={address.id}
                                address={address}
                                onAddressClicked={() =>
                                    this.handleAddressClicked(address.id)
                                }
                            />
                        );
                    })}
                </div>
                <div className={`${base}__box-footer`}>
                    <Link className="link--no-underline" to="/me">
                        {intl.get('changeOrder.addAddress')}
                    </Link>
                </div>
            </div>
        );
    }
}
