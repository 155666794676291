import React from 'react';
import { inject, observer } from 'mobx-react';

import intl from 'react-intl-universal';
import ButtonUI from './ButtonUI';

const base = 'login-popup';

@inject('loginPopupStore')
@observer
export default class extends React.Component {
    renderButton = () => {
        const { buttonText } = this.props;

        return (
            <ButtonUI
                text={buttonText}
                customClasses="button--busy button--disabled"
            />
        );
    };

    render() {
        return (
            <div className={base}>
                <h3 className={`${base}__title`}>
                    {intl.get('loginPopup.busy')}
                </h3>
                {this.renderButton()}
                <div className="hide--if-large show--if-small mbl" />
            </div>
        );
    }
}
