import React from 'react';

export default function PopmealsIcon({ width, height }) {
    return (
        <svg
            width="561"
            height="561"
            viewBox="0 0 561 561"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width, height }}
        >
            <rect width="561" height="561" rx="64" fill="#FEC700" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M297.259 340.538C276.038 340.792 260.126 327.056 253.741 307.777C241.742 271.54 268.952 224.14 302.36 223.989C314.935 223.933 325.392 230.545 328.804 240.851C331.446 248.829 327.31 245.85 319.325 247.884C283.196 255.081 275.615 291.281 279.696 309.679C281.606 318.285 284.843 324.158 293.878 325.572C306.106 327.484 317.339 313.679 321.592 295.832C324.31 284.431 322.863 275.647 320.956 267.436C319.29 260.26 316.736 253.969 323.334 253.939C330.409 253.907 338.852 259.196 342.814 271.164C346.887 283.463 345.105 298.792 338.21 312.054C328.825 330.105 315.127 340.325 297.259 340.538Z"
                fill="#685594"
            />
            <mask
                id="mask0"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="99"
                y="170"
                width="363"
                height="222"
            >
                <path
                    d="M99.6079 170.604H461.736V391.758H99.6079V170.604Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M433.261 285.81C430.946 295.542 424.294 308.08 414.23 313.239C402.516 319.243 390.351 309.784 390.984 294.246C391.555 280.28 395.546 271.689 398.175 267.459C406.528 254.014 421.392 248.037 428.682 257.261C434.905 265.135 435.401 276.813 433.261 285.81ZM457.769 251.364C450.994 237.103 435.213 229.542 419.644 232.756C409.2 234.912 402.357 240.302 393.354 251.082C394.057 248.353 395.483 243.152 395.914 241.94C397.806 236.612 390.788 234.849 388.128 234.36C384.451 233.683 379.695 234.575 376.665 236.681C372.054 239.887 369.846 245.965 368.155 250.867C361.758 269.413 360.112 289.988 359.427 309.362C358.777 327.723 360.658 353.082 365.728 370.977C369.417 383.999 380.725 396.584 398.017 389.188C404.084 386.593 400.281 379.944 398.017 373.047C395.419 365.124 387.94 340.046 389.239 319.923C397.138 333.235 415.561 337.931 431.403 329.605C445.356 322.268 454.719 307.574 458.54 294.023C462.006 281.724 463.786 264.028 457.769 251.364Z"
                    fill="#685594"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M248.919 221.753C247.775 206.021 238.567 191.361 223.653 181.531C206.124 169.975 183.962 167.081 164.367 173.784C138.472 181.199 118.212 196.663 107.315 217.328C100.656 229.958 98.2862 240.939 100.274 249.97C102.396 259.617 110.921 272.45 120.993 276.359C123.228 277.23 125.634 276.834 127.432 275.294C129.298 273.698 130.12 271.203 129.585 268.825C125.835 249.11 135.407 228.634 145.616 216.043C156.773 202.286 180.562 191.778 196.857 198.694C214.273 206.089 223.314 224.606 219.889 245.871C218.403 255.096 212.16 272.863 187.1 288.175C185.787 288.87 183.275 290.076 179.625 291.767C179.82 278.292 180.675 264.495 182.172 250.729C183.106 242.146 185.156 226.683 188.37 219.602C188.708 218.965 188.985 217.912 188.358 216.457C186.592 212.917 183.677 210.542 180.151 209.772C176.559 208.989 172.83 209.966 169.653 212.524C164.314 216.82 162.636 224.484 160.24 231.033C155.664 243.537 144.86 302.466 153.081 349.768C155.04 361.04 159.264 369.426 166.102 373.207C168.723 374.656 171.601 375.289 174.652 375.379C180.199 375.543 185.039 373.363 186.061 372.769C187.086 372.175 188.986 370.399 187.433 366.265C181.72 351.967 179.57 337.105 179.295 309.74C201.732 304.597 218.452 294.756 230.382 279.674C247.415 258.144 249.864 236.298 248.919 221.753Z"
                    fill="#685594"
                />
            </g>
        </svg>
    );
}
