import React, { Component } from 'react';
import { deliveryAreas } from '../../../services/deliveryAreas';

const base = 'section-delivery-areas';

export default class SectionDeliveryAreas extends Component {
    state = {
        showAll: false,
        totalAreas: 0,
    };

    renderAreaList() {
        const { showAll } = this.state;
        const sortAlphaNum = (a, b) =>
            a.localeCompare(b, 'en', { numeric: true });

        return (
            <div className={`${base}__area-list`}>
                {Object.keys(deliveryAreas)
                    .sort(
                        (a, b) =>
                            deliveryAreas[b].length - deliveryAreas[a].length
                    )
                    .map((city, i) => (
                        <div className={`${base}__list-item`} key={i}>
                            <div
                                className={`${base}__city-container ${
                                    showAll && 'add-margin'
                                }`}
                            >
                                <p>{city}</p>
                            </div>
                            <div
                                className={`${base}__area-container ${
                                    showAll && 'show'
                                }`}
                            >
                                {deliveryAreas[city]
                                    .sort(sortAlphaNum)
                                    .map((area) => (
                                        <p>{area}</p>
                                    ))}
                            </div>
                        </div>
                    ))}
            </div>
        );
    }

    handleExpandAreas = () => {
        this.setState({ showAll: !this.state.showAll });
    };

    calculateNumberOfAreas = () => {
        let total = 0;
        Object.values(deliveryAreas).forEach((areas) => {
            total = total + areas.length;
        });
        this.setState({ totalAreas: total });
    };

    componentDidMount() {
        this.calculateNumberOfAreas();
    }

    render() {
        const { showAll, totalAreas } = this.state;

        return (
            <div className={`${base} container`}>
                <div className={`${base}__header`}>
                    <h3 className="text--bolder">
                        Delivery areas in Kuala Lumpur
                    </h3>
                </div>
                {this.renderAreaList()}
                <div className="display--flex" onClick={this.handleExpandAreas}>
                    {showAll ? (
                        <p
                            className={`${base}__cta text--bolder cursor-pointer`}
                        >
                            Show less
                        </p>
                    ) : (
                        <p
                            className={`${base}__cta text--bolder cursor-pointer`}
                        >
                            See all {totalAreas} areas
                        </p>
                    )}

                    <svg
                        className={`${base}__cta-arrow iconmoon-icon cursor-pointer`}
                    >
                        {showAll ? (
                            <use xlinkHref="#icon-chevron-thin-up" />
                        ) : (
                            <use xlinkHref="#icon-chevron-thin-down" />
                        )}
                    </svg>
                </div>
            </div>
        );
    }
}
