import { action, computed, observable } from 'mobx';
import Bacon from 'baconjs';
import Notifiable from './services/notifiable';

import { services } from '../services/index';
import { parseDate } from '../services/dateTime';

export default class extends Notifiable {
    @observable.ref timeSettings = null;
    @observable.ref activeDate = null;
    @observable.ref emptyDates = [];
    @observable isLoading = true;
    @observable message = '';
    @observable dateIndex;
    @observable conversionContent = [];

    @observable validDates = [];

    onDateClicked$ = new Bacon.Bus();

    @computed get firstValidDate() {
        return this.validDates[0];
    }

    constructor() {
        super();

        this.setUpNotification();
    }

    get startXDate() {
        return parseDate(this.timeSettings.start_date);
    }
    get endXDate() {
        return parseDate(this.timeSettings.end_date);
    }

    @action setActiveDate(xdate, willNotify = true) {
        this.activeDate = xdate;

        if (willNotify) {
            this.onDateClicked$.push(xdate);
        }
    }

    @action loadTimeSettings() {
        this.isLoading = true;
        const city = services.getParam('city');

        services.api
            .GetMenuTimeSettings({ city })
            .then((res) => {
                this.getConversionContent(res.onboarding_facts);
            })
            .catch((responseData) => {
                this.message = responseData.message;
            })
            .then(() => {
                this.isLoading = false;
            });
    }

    @action getMenuDates = () => {
        services.api
            .GetMenuDates()
            .then(
                (responseData) =>
                    (this.validDates =
                        responseData.dates &&
                        responseData.dates.filter((date) => date.available))
            )
            .catch((responseData) => {
                this.message = responseData.message;
            })
            .then(() => {
                this.isLoading = false;
            });
    };

    @action getConversionContent = (content) => {
        const contentArray = [];
        content.forEach((c) => {
            c.slides.forEach((slide) => {
                contentArray.push(slide);
            });
        });
        this.conversionContent = contentArray;
    };
}
