import React from 'react';
import intl from 'react-intl-universal';
import { template } from 'lodash';
import { GetCity } from '../../../services/city';
import config from '../../../configs/parameters';

const city = GetCity();

const base = 'business';

export default class extends React.Component {
    render() {
        const brandName = config.brandName;

        return (
            <div className={`${base}__benefit-section text--center`}>
                <div className={`${base}__benefit-section-container container`}>
                    <h1 className="text--bold fs--medium-large mbl">
                        {intl.get(`business.${city}.benefitTitle`)}
                    </h1>
                    <div className="row">
                        <div
                            className={`${base}__benefit-container col-lg-4 col-md-4 col-sm-4`}
                        >
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/reliable-service.png"
                                alt="Reliable service"
                            />
                            <h3 className="mbm mtm text--primary text--bolder">
                                {intl.get(`business.${city}.benefit_1_title`)}
                            </h3>
                            <p className="fs--small-medium mtm lh--125">
                                {intl.get(`business.${city}.benefit_1_desc`)}
                            </p>
                        </div>
                        <div
                            className={`${base}__benefit-container col-lg-4 col-md-4 col-sm-4`}
                        >
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/corporate-discounts.png"
                                alt="Corporate discounts"
                            />
                            <h3 className="mbm mtm text--primary text--bolder">
                                {intl.get(`business.${city}.benefit_2_title`)}
                            </h3>
                            <p className="fs--small-medium mtm lh--125">
                                {intl.get(`business.${city}.benefit_2_desc`)}
                            </p>
                        </div>
                        <div
                            className={`${base}__benefit-container col-lg-4 col-md-4 col-sm-4`}
                        >
                            <img
                                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/fresh-tasty.png"
                                alt="Fresh and tasty"
                            />
                            <h3 className="mbm mtm text--primary text--bolder">
                                {intl.get(`business.${city}.benefit_3_title`)}
                            </h3>
                            <p className="fs--small-medium mtm lh--125">
                                {template(
                                    intl.get(`business.${city}.benefit_3_desc`)
                                )({ brandName })}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
