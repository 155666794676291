import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

const base = 'terminal-address-form';

@inject('terminalAddressStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    handleSubmitForm(e) {
        e.preventDefault();

        this.props.terminalAddressStore.confirmAddress();
    }

    render() {
        const store = this.props.terminalAddressStore;
        const updateForm = ::store.updateForm;

        return (
            <div>
                <div className={`${base}__preview ellipsis fs--small-medium`}>
                    {store.addressPreview}
                </div>
                <form className={base} onSubmit={this.handleSubmitForm}>
                    <div className={`${base}__form-group`}>
                        <h3 className={`${base}__form-title`}>
                            {intl.get('terminal.address.form.addressTitle')}
                        </h3>
                        <div className={`${base}__form-container`}>
                            <div className="row mbm">
                                <div className="col-xs-9 col-reduced-gutter--right">
                                    <label htmlFor="inputBuildingName">
                                        {intl.get(
                                            'terminal.address.labels.buildingName'
                                        )}
                                    </label>
                                    <br />
                                    <input
                                        id="inputBuildingName"
                                        type="text"
                                        className={
                                            store.validationResult.getInputErrorClassOf(
                                                'buildingName'
                                            ) + ' input width--100'
                                        }
                                        name="buildingName"
                                        value={store.newAddress.buildingName}
                                        onChange={updateForm}
                                    />
                                </div>
                                <div className="col-xs-3 col-reduced-gutter--left">
                                    <label htmlFor="inputFloor">
                                        {intl.get(
                                            'terminal.address.labels.floor'
                                        )}
                                    </label>
                                    <br />
                                    <input
                                        id="inputFloor"
                                        type="text"
                                        className={
                                            store.validationResult.getInputErrorClassOf(
                                                'floor'
                                            ) + ' input width--100'
                                        }
                                        name="floor"
                                        value={store.newAddress.floor}
                                        onChange={updateForm}
                                    />
                                </div>
                            </div>
                            <div className="row mbm">
                                <div className="col-xs-3 col-reduced-gutter--right">
                                    <label htmlFor="inputHomeNumber">
                                        {intl.get(
                                            'terminal.address.labels.houseNo'
                                        )}
                                    </label>
                                    <br />
                                    <input
                                        id="inputHomeNumber"
                                        type="text"
                                        className="input width--100"
                                        name="homeNumber"
                                        value={store.newAddress.homeNumber}
                                        onChange={updateForm}
                                    />
                                </div>
                                <div className="col-xs-9 col-reduced-gutter--left">
                                    <label htmlFor="inputStreet">
                                        {intl.get(
                                            'terminal.address.labels.street'
                                        )}
                                    </label>
                                    <br />
                                    <input
                                        id="inputStreet"
                                        type="text"
                                        className="input width--100"
                                        name="street"
                                        value={store.newAddress.street}
                                        onChange={updateForm}
                                    />
                                </div>
                            </div>
                            <div className="row mbm">
                                <div className="col-xs-8 col-reduced-gutter--right">
                                    <label htmlFor="inputDistrict">
                                        {intl.get(
                                            'terminal.address.labels.district'
                                        )}
                                    </label>
                                    <br />
                                    <input
                                        id="inputDistrict"
                                        type="text"
                                        className="input width--100"
                                        name="district"
                                        value={store.newAddress.district}
                                        onChange={updateForm}
                                    />
                                </div>
                                <div className="col-xs-4 col-reduced-gutter--left">
                                    <label htmlFor="inputPostalCode">
                                        {intl.get(
                                            'terminal.address.labels.postcode'
                                        )}
                                    </label>
                                    <br />
                                    <input
                                        id="inputPostalCode"
                                        type="text"
                                        className="input width--100"
                                        name="postalCode"
                                        value={store.newAddress.postalCode}
                                        onChange={updateForm}
                                    />
                                </div>
                            </div>
                            <div className="row mbm">
                                <div className="col-xs-12">
                                    <label htmlFor="inputCity">
                                        {intl.get(
                                            'terminal.address.labels.city'
                                        )}
                                    </label>
                                    <br />
                                    <input
                                        id="inputCity"
                                        type="text"
                                        className="input width--100"
                                        name="city"
                                        value={store.newAddress.city}
                                        onChange={updateForm}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${base}__form-group`}>
                        <h3 className={`${base}__form-title`}>
                            {intl.get('terminal.address.form.contactTitle')}
                        </h3>
                        <div className={`${base}__form-container`}>
                            <div className="row mbm">
                                <div className="col-xs-12">
                                    <label htmlFor="inputContactPerson">
                                        {intl.get(
                                            'terminal.address.labels.contactName'
                                        )}
                                    </label>
                                    <br />
                                    <input
                                        id="inputContactPerson"
                                        type="text"
                                        className={
                                            store.validationResult.getInputErrorClassOf(
                                                'contactPerson'
                                            ) + ' input width--100'
                                        }
                                        name="contactPerson"
                                        value={store.newAddress.contactPerson}
                                        onChange={updateForm}
                                    />
                                </div>
                            </div>
                            <div className="row mbm">
                                <div className="col-xs-12">
                                    <label htmlFor="inputContactPhoneNumber">
                                        {intl.get(
                                            'terminal.address.labels.phoneNumber'
                                        )}
                                    </label>
                                    <br />
                                    <input
                                        id="inputContactPhoneNumber"
                                        type="tel"
                                        className={
                                            store.validationResult.getInputErrorClassOf(
                                                'contactPhoneNumber'
                                            ) + ' input width--100'
                                        }
                                        name="contactPhoneNumber"
                                        value={
                                            store.newAddress.contactPhoneNumber
                                        }
                                        onChange={updateForm}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${base}__form-group`}>
                        <h3 className={`${base}__form-title`}>
                            {intl.get('terminal.address.form.instructionTitle')}
                        </h3>
                        <div className={`${base}__form-container`}>
                            <div className="row mbm">
                                <div className="col-xs-12">
                                    <textarea
                                        className={`${base}__delivery-instruction input width--100`}
                                        placeholder="Enter your suggestions..."
                                        name="deliveryInstruction"
                                        value={
                                            store.newAddress.deliveryInstruction
                                        }
                                        onChange={updateForm}
                                    />
                                </div>
                            </div>
                            <center className="mbm">
                                <p className="text--red fs--small-medium display--none-if-empty">
                                    {store.validationResult.getFirstError()}
                                </p>
                            </center>
                            <button className="button button--success width--100">
                                {intl.get('terminal.address.form.cta')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
