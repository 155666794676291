import React from 'react';

import intl from 'react-intl-universal';

export default () => {
    const base = 'cart--empty-preview';

    return (
        <div className={base}>
            <img
                className={` ${base}__cart__image `}
                src="https://d3tjqo2jh935k9.cloudfront.net/images/static/empty-bag.png"
                alt="Empty shopping cart"
            />
            <p className="text--bolder fs--medium-large mtm mbm">
                {intl.get('packageCheckout.empty.title')}
            </p>
            <p className="mtm mbm text--gray">
                {intl.get('cartPreview.isEmptySubtitle')}
            </p>
        </div>
    );
};
