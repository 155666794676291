import React from 'react';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import { currency } from '../../../services';
import AddonAddToCartButton from './AddonAddToCartButton';

import intl from 'react-intl-universal';
import { GetDishURL } from '../../../services/url';

const base = 'addon-upsell__item';

@inject('menuHeaderStore')
export default class extends React.Component {
    render() {
        const { item, onClick } = this.props;
        const title = item.title_bold + ' ' + item.title;
        const url = GetDishURL(item);
        const redirectPayload = {
            pathname: url,
        };

        return (
            <li className={`${base} fs--small-medium display--flex`}>
                <Link to={redirectPayload}>
                    <div
                        className={`${base}-image flex-none`}
                        style={{ backgroundImage: `url(${item.image})` }}
                    />
                </Link>
                <div className={`${base}__name-and-icon-wrapper `}>
                    <div
                        className="fs--small-medium mls mrs lh--125"
                        title={title}
                    >
                        <div className={`${base}-name text--bold`}>
                            <Link
                                className="link--no-underline"
                                to={redirectPayload}
                            >
                                <p>{item.title_bold}</p>
                            </Link>
                            <span>
                                {' '}
                                -&nbsp;
                                {currency.localCurrency(item.price, true)}
                            </span>
                        </div>
                    </div>
                    <div className="width--100">
                        <AddonAddToCartButton
                            hoverText={intl.get('addonUpsell.hover')}
                            maxWidth="100px"
                            onClick={onClick.bind(null, item)}
                            isAddon="true"
                        />
                    </div>
                </div>
            </li>
        );
    }
}
