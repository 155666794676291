import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import CartDropDown from './cartPreview/CartDropDown';
import CartPreview from './cartPreview/cartPreview';

import { when } from 'mobx';
import { currency, truncateDecimal } from '../../services';
import FloatingCta from './FloatingCta';

@inject('userStore', 'cartStore', 'dropdownStore', 'rewardsStore')
@observer
class CartIcon extends Component {
    handleGetFinalTotal = (amount) => {
        const { userStore } = this.props;
        return currency.localCurrency(amount);
    };

    handleCartClicked = () => {
        const { cartStore, history } = this.props;

        if (cartStore.cart.length) {
            cartStore.handleCartClicked();
            history.push({ pathname: `/cart` });
        }
    };

    render() {
        const { cartStore, dropdownStore, whiteIcons } = this.props;
        const { cartTotal, numOfItems } = cartStore;
        const subTotal = currency.localCurrency(cartTotal);
        const base = 'cart-icon';
        const mobileBase = 'cart-button';

        return (
            <Fragment>
                {/* Cart icon for desktop */}
                <div className={`${base} hide--if-small`}>
                    <a
                        className="z-index-higher-than-backdrop"
                        onClick={this.handleCartClicked}
                        onMouseEnter={() =>
                            dropdownStore.handleShowDropdown(
                                dropdownStore.DROPDOWN_CART
                            )
                        }
                        id="select-cart"
                    >
                        <div className={`${base}__content`}>
                            {/* <svg className={`${base}__icon iconmoon-icon`}>
                                <use xlinkHref="#icon-cart" />
                            </svg> */}
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2101_901)">
                                    <path d="M28.6066 9.83306C28.3763 9.43389 28.0463 9.10124 27.6491 8.86756C27.2518 8.63388 26.8008 8.50715 26.34 8.49973H9.27329L8.49996 5.48639C8.42183 5.19551 8.24751 4.93965 8.0054 4.76049C7.76328 4.58133 7.46764 4.48942 7.16663 4.49973H4.49996C4.14634 4.49973 3.8072 4.6402 3.55715 4.89025C3.3071 5.1403 3.16663 5.47944 3.16663 5.83306C3.16663 6.18668 3.3071 6.52582 3.55715 6.77587C3.8072 7.02592 4.14634 7.16639 4.49996 7.16639H6.15329L9.83329 20.8464C9.91143 21.1373 10.0857 21.3931 10.3279 21.5723C10.57 21.7515 10.8656 21.8434 11.1666 21.8331H23.1666C23.4129 21.8323 23.6541 21.7634 23.8635 21.634C24.073 21.5045 24.2425 21.3196 24.3533 21.0997L28.7266 12.3531C28.9162 11.9558 29.0044 11.5177 28.9835 11.078C28.9626 10.6383 28.8331 10.2106 28.6066 9.83306ZM22.34 19.1664H12.18L10.0066 11.1664H26.34L22.34 19.1664Z" fill="#131415" />
                                    <path d="M10.5 28.5C11.6046 28.5 12.5 27.6046 12.5 26.5C12.5 25.3954 11.6046 24.5 10.5 24.5C9.39543 24.5 8.5 25.3954 8.5 26.5C8.5 27.6046 9.39543 28.5 10.5 28.5Z" fill="#131415" />
                                    <path d="M23.8334 28.5C24.9379 28.5 25.8334 27.6046 25.8334 26.5C25.8334 25.3954 24.9379 24.5 23.8334 24.5C22.7288 24.5 21.8334 25.3954 21.8334 26.5C21.8334 27.6046 22.7288 28.5 23.8334 28.5Z" fill="#131415" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2101_901">
                                        <rect width="32" height="32" fill="white" transform="translate(0.5 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>

                            {numOfItems > 0 && (
                                <div
                                    className={`${base}__number-wrapper ${
                                        whiteIcons ? 'bg-purple' : ''
                                    }`}
                                    key={numOfItems}
                                >
                                    <div className={`${base}__number`}>
                                        {numOfItems}
                                    </div>
                                </div>
                            )}
                        </div>
                    </a>
                    <CartDropDown
                        isShowing={
                            dropdownStore.showingDropdown ===
                            dropdownStore.DROPDOWN_CART
                        }
                        onClickOutside={() =>
                            dropdownStore.handleHideDropdownImmediately()
                        }
                    >
                        <div
                            onMouseLeave={() =>
                                dropdownStore.handleHideDropdown()
                            }
                        >
                            <CartPreview onClick={this.handleCartClicked} />
                        </div>
                    </CartDropDown>
                </div>

                {/* Cart icon for mobile */}
                {numOfItems > 0 && (
                    <FloatingCta
                        marginTop={'0'}
                        onClick={this.handleCartClicked}
                        leftText={numOfItems === '0' ? '' : numOfItems}
                        centerText="Go to checkout"
                        rightText={cartTotal ? subTotal : ''}
                        customLeftTextClass={`${mobileBase}__number-of-items`}
                    />
                )}
            </Fragment>
        );
    }
}

export default withRouter(CartIcon);
