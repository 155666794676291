import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, Events, scrollSpy } from 'react-scroll';

const base = 'category-picker';

@inject('menuListStore', 'menuHeaderStore')
@observer
export default class CategoryPicker extends Component {
    state = {
        isScrollspyActive: true,
    };

    componentDidMount() {
        Events.scrollEvent.register(
            'begin',
            function (to, element) {
                this.setState({ isScrollspyActive: false });
            }.bind(this)
        );

        Events.scrollEvent.register(
            'end',
            function (to, element) {
                this.setState({ isScrollspyActive: true });
            }.bind(this)
        );

        scrollSpy.update();
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        const { menuListStore, categories, menuHeaderStore } = this.props;
        const { isScrollspyActive } = this.state;

        return (
            <div className={base}>
                <div
                    className={`${base}__container`}
                    style={{ top: menuHeaderStore.marginTop + 30 + 'px' }}
                >
                    <div className={`${base}__header`}>
                        <p className={`${base}__date text--bolder`}>
                            {menuListStore.activeDateMenu &&
                                menuListStore.activeDateMenu.title}
                        </p>
                    </div>
                    <ul>
                        {categories.map((category, i) => {
                            const scrollToElement = `${category
                                .replace(/\s+/g, '-')
                                .toLowerCase()}`;
                            return (
                                <li
                                    key={i}
                                    className={`${base}__category cursor-pointer`}
                                >
                                    <Link
                                        activeClass="active"
                                        to={scrollToElement}
                                        spy={isScrollspyActive}
                                        smooth={true}
                                        offset={-menuHeaderStore.marginTop - 35}
                                        duration={250}
                                        delay={0}
                                        ignoreCancelEvents={true}
                                    >
                                        <span
                                            className={`${base}__category-text`}
                                        >
                                            {category}
                                        </span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}
