import React from 'react';
import intl from 'react-intl-universal';
import { inject, observer } from 'mobx-react';
import WalletProgress from './WalletProgress';
import { RESOURCE } from '../../../services/types';

const base = 'wallet-status-banner';

@inject('walletStatusStore')
@observer
export default class extends React.Component {
    render() {
        const { walletStatusStore } = this.props;

        if (RESOURCE.Success.is(walletStatusStore.walletStatus)) {
            const walletStatus = walletStatusStore.walletStatus.data;

            if (walletStatus.has_wallet) {
                return (
                    <div className={base}>
                        <div className="row">
                            <div className={`${base}__col-balance col-sm-6`}>
                                <p
                                    className={`${base}__col-label fs--small-medium lh--125`}
                                >
                                    {intl.get('wallet.statusBanner.mealsLeft')}
                                </p>
                                <WalletProgress
                                    balance={walletStatus.balance}
                                />
                            </div>
                            <div
                                className={`${base}__col-smart-reload col-sm-6`}
                            >
                                <p
                                    className={`${base}__col-label fs--small-medium lh--125`}
                                >
                                    {intl.get(
                                        'wallet.statusBanner.smartReload'
                                    )}
                                </p>
                                <p className="fs--medium-large text--bold mtm">
                                    {walletStatus['auto_top_up_enabled']
                                        ? 'On'
                                        : 'Off'}
                                </p>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return <div />;
    }
}
