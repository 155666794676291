import React, { Component } from 'react';
import ButtonUI from '../core/loginPopup/ButtonUI';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import intl from 'react-intl-universal';
import { CircleLoader } from './Loading';

const { clevertap } = window;
const base = 'rewards-popup';
const GENERAL_REWARDS = 'generalRewards';
const USER_REWARDS = 'userRewards';

@inject('rewardsPopupStore', 'rewardsStore')
@observer
export default class RewardsPopup extends Component {
    state = {
        activeTab: USER_REWARDS,
        expandPromoBox: false,
        code: '',
    };

    componentDidMount() {
        const { rewardsStore } = this.props;
        rewardsStore.handleGetAllRewards();
        document.addEventListener('keydown', this.onKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPress);
    }

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleRedeemCode();
        }
    };

    setActiveTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    handleTypeCode = (e) => {
        e.preventDefault();
        this.setState({ code: e.target.value });
    };

    handleRedeemCode = (rewardCode) => {
        const { activeTab } = this.state;
        const { rewardsStore, onCheckout } = this.props;
        if (rewardCode) {
            clevertap.event.push('Tapped on Redeem Reward', {
                Code: rewardCode,
            });
            rewardsStore.code = rewardCode;
        } else {
            clevertap.event.push('Tapped on Apply Code', {
                'Accessed from':
                    activeTab === USER_REWARDS ? 'My Rewards' : 'Get Rewards',
                Code: this.state.code,
            });
            rewardsStore.code = this.state.code;
        }

        when(
            () => rewardsStore.code,
            () => {
                rewardsStore.redeemCode({}, onCheckout);
                this.setState({ code: '' });
            }
        );
    };

    handleUseReward = (reward) => {
        const { rewardsStore, rewardsPopupStore, onCheckout } = this.props;
        clevertap.event.push('Tapped on Use Now', {
            Code: reward.code,
        });
        if (reward.id) {
            rewardsStore.useReward(reward.id, onCheckout);
        } else {
            rewardsPopupStore.handleTogglePopup(false);
        }
    };

    handleTogglePromoBox = () => {
        const { activeTab, expandPromoBox } = this.state;
        if (!expandPromoBox) {
            clevertap.event.push('Clicked on Enter Code', {
                'Accessed from':
                    activeTab === USER_REWARDS ? 'My Rewards' : 'Get Rewards',
            });
            this.setState({ expandPromoBox: true });
        }
    };

    renderPromoCodeContainer = () => {
        const { expandPromoBox, code } = this.state;
        const { isPopup } = this.props;

        return (
            <div
                className={`${base}__reward-container ${
                    isPopup ? 'popup' : ''
                } collapse ${expandPromoBox ? '' : 'cursor-pointer'}`}
                onClick={this.handleTogglePromoBox}
            >
                <div className={`${base}__header-container`}>
                    <p className={`${base}__header text--bolder`}>
                        {intl.get('rewardsPopup.addCode')}
                    </p>
                    {!expandPromoBox && (
                        <svg className={`${base}__arrow-icon iconmoon-icon`}>
                            <use xlinkHref={`#icon-chevron-thin-down`} />
                        </svg>
                    )}
                </div>
                {expandPromoBox && (
                    <div className={`${base}__cta-container`}>
                        <input
                            className={`${base}__promo-input ${
                                code ? 'filled' : ''
                            }`}
                            type="text"
                            autoFocus={true}
                            placeholder={intl.get(
                                'rewardsPopup.addCodePlaceholder'
                            )}
                            value={code}
                            onChange={this.handleTypeCode}
                        />
                        <ButtonUI
                            text={intl.get('rewardsPopup.addCodeCta')}
                            customClasses={`${base}__cta button--success`}
                            marginTop={'14px'}
                            onClick={() => this.handleRedeemCode()}
                            disabled={!code}
                        />
                    </div>
                )}
            </div>
        );
    };

    renderRewardContainer = (reward, i) => {
        const { activeTab } = this.state;
        const { isPopup, rewardsStore } = this.props;
        const isApplied = reward.id === rewardsStore.rewardToApply();

        return (
            <div
                className={`${base}__reward-container ${
                    isPopup ? 'popup' : ''
                }`}
                key={i}
            >
                <div className={`${base}__header-container`}>
                    <p className={`${base}__header text--bolder`}>
                        {reward.title}
                    </p>
                </div>
                <div className={`${base}__cta-container`}>
                    <p className={`${base}__text`}>{reward.expires_at}</p>
                    {isApplied ? (
                        <div className={`${base}__applied display--flex`}>
                            <svg className={`${base}__check-mark`}>
                                <use xlinkHref="#icon-checkmark-noborder" />
                            </svg>
                            <p className="text--primary text--bolder">
                                Applied
                            </p>
                        </div>
                    ) : (
                        <ButtonUI
                            text={
                                activeTab === GENERAL_REWARDS
                                    ? intl.get('rewardsPopup.redeem')
                                    : intl.get('rewardsPopup.useNow')
                            }
                            customClasses={`${base}__cta button--success`}
                            onClick={() =>
                                activeTab === GENERAL_REWARDS
                                    ? this.handleRedeemCode(reward.code)
                                    : this.handleUseReward(reward)
                            }
                        />
                    )}
                </div>
            </div>
        );
    };

    render() {
        const { activeTab } = this.state;
        const { rewardsStore, isPopup } = this.props;
        const rewardsToDisplay =
            activeTab === GENERAL_REWARDS
                ? rewardsStore.generalRewards
                : rewardsStore.userRewards;

        return rewardsStore.loading === false ? (
            <div className={`${isPopup ? `${base}__container` : ''}`}>
                <div className={`${base}__switcher ${isPopup ? 'popup' : ''}`}>
                    <div
                        className={`${base}__switch ${
                            activeTab === GENERAL_REWARDS ? 'active' : ''
                        }`}
                        onClick={() => this.setActiveTab(GENERAL_REWARDS)}
                    >
                        <p
                            className={`${base}__switch-text text--bolder ${
                                activeTab === GENERAL_REWARDS ? 'active' : ''
                            }`}
                        >
                            {intl.get('rewardsPopup.getRewards')}
                        </p>
                    </div>
                    <div
                        className={`${base}__switch ${
                            activeTab === USER_REWARDS ? 'active' : ''
                        }`}
                        onClick={() => this.setActiveTab(USER_REWARDS)}
                    >
                        <p
                            className={`${base}__switch-text text--bolder ${
                                activeTab === USER_REWARDS ? 'active' : ''
                            }`}
                        >
                            {intl.get('rewardsPopup.myRewards')}
                        </p>
                    </div>
                </div>
                <div className={`${base}__rewards-container`}>
                    {this.renderPromoCodeContainer()}
                    {rewardsToDisplay.length > 0 &&
                        rewardsToDisplay.map(
                            (reward, i) =>
                                !reward.referral_code &&
                                this.renderRewardContainer(reward, i)
                        )}
                </div>
            </div>
        ) : (
            <div
                className={`${
                    isPopup
                        ? `${base}__container display--flex flex--align-items-center justify--center`
                        : ''
                }`}
            >
                <div className="display--inline-block text--center">
                    <CircleLoader className="m0a" />
                    <p className="mtm">Loading...</p>
                </div>
            </div>
        );
    }
}
