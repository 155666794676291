import React, { Component, Fragment } from 'react';
import ButtonUI from '../../core/loginPopup/ButtonUI';
import CarouselUI from '../careersPage/CarouselUI';
import {
    MEDIUM_SCREEN_MEDIA,
    SMALL_SCREEN_MEDIA,
} from '../../../services/constants';
import { withRouter } from 'react-router-dom';
import { Link as URL } from '../../../services/types';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import PromotionTiles from '../../core/PromotionTiles';
import { cities, GetCity } from '../../../services/city';
import config from '../../../configs/parameters';
import { GetLanguage } from '../../../services/translation';
import { template } from 'lodash';
import { RESOURCE } from '../../../services/types';
import SelectPopup from '../../core/selectPopup/SelectPopup';

const isKL = GetCity() === cities.kl;
const base = 'about__section-save';

@inject('loginPopupStore', 'selectPopupStore', 'userStore')
@observer
class SectionSave extends Component {
    handleClickLink = (url, method) => {
        this.props.loginPopupStore.setBackLink(
            new URL(window.location.pathname, 'BACK')
        );
        if (method) {
            method();
        } else if (url.includes('https')) {
            window.open(url, '_blank');
        } else {
            this.props.history.push(url);
        }
    };

    showSelect = () => {
        if (RESOURCE.Success.is(this.props.userStore.user)) {
            this.props.selectPopupStore.setShow();
        } else {
            this.props.loginPopupStore.isShowing = true;
            this.props.loginPopupStore.redirectToSelectPage = true;
        }
    };

    onClick = () => {
        this.props.history.push('/menu');
    };

    render() {
        const smallAndMediumScreens =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches ||
            window.matchMedia(MEDIUM_SCREEN_MEDIA).matches;
        const brandName = config.brandName;
        const brandNameShort = config.brandNameShort;

        const content = [
            {
                icon: 'icon-heart',
                header: intl.get('about.section-save.content.one.header'),
                content: template(
                    intl.get('about.section-save.content.one.content')
                )({ brandName: brandName }),
                linkText: intl.get('about.section-save.content.one.cta'),
                url: '/rewards',
            },
            {
                icon: 'icon-blue-leaf',
                header: template(
                    intl.get('about.section-save.content.two.header')
                )({ brandName: brandNameShort }),
                content: template(
                    intl.get('about.section-save.content.two.content')
                )({ brandName: brandNameShort }),
                linkText: intl.get('about.section-save.content.two.cta'),
                linkColor: 'blue',
                url: '/wallet',
            },
            {
                icon: 'icon-cash-envelope',
                header: isKL
                    ? intl.get('about.section-save.content.three.header')
                    : intl.get(
                          'about.section-save.content.threeBangkok.header'
                      ),
                content: isKL
                    ? intl.get('about.section-save.content.three.content')
                    : intl.get(
                          'about.section-save.content.threeBangkok.content'
                      ),
                linkText: intl.get('about.section-save.content.three.cta'),
                method: this.props.loginPopupStore.handleTryNow,
            },
        ];
        const isLangMalay = GetLanguage() === 'my';
        const contentToRender = isKL ? content : content.slice(0, 3);

        return (
            <div
                className={`${base} ${
                    !smallAndMediumScreens ? 'container' : ''
                }`}
            >
                <div
                    className={`${base}__header-container ${
                        smallAndMediumScreens ? 'container' : ''
                    }`}
                >
                    <h3 className={`${base}__header text--bolder`}>
                        {intl.get('about.section-save.header')}
                    </h3>
                    <p className={`${base}__sub-header`}>
                        {intl.get('about.section-save.sub-header')}
                    </p>
                </div>
                {smallAndMediumScreens ? (
                    <Fragment>
                        <CarouselUI
                            content={contentToRender}
                            width={
                                window.matchMedia(SMALL_SCREEN_MEDIA).matches
                                    ? 319
                                    : 500
                            }
                            height={isLangMalay ? 402 : 356}
                            name="about-section-save-carousel"
                            customContentClass={`${base}__carousel-content`}
                            marginBottom={45}
                            onLinkClick={this.handleClickLink}
                        />
                        <ButtonUI
                            customClasses={`${base}__cta button--success`}
                            text="Get started"
                            onClick={
                                this.props.loginPopupStore.handleSignInClicked
                            }
                        />
                    </Fragment>
                ) : (
                    <div className={`${base}__info-container hide--if-small`}>
                        <div className={`${base}__green-square`} />
                        <div
                            className="display--flex"
                            style={{ marginBottom: '14px' }}
                        >
                            <PromotionTiles
                                customClass={`one ${
                                    isLangMalay ? 'alt-height' : ''
                                }`}
                                icon="icon-heart"
                                header={contentToRender[0].header}
                                body={contentToRender[0].content}
                                ctaText={contentToRender[0].linkText}
                                onClick={() =>
                                    this.handleClickLink(contentToRender[0].url)
                                }
                            />
                            <PromotionTiles
                                customClass={`two ${
                                    isLangMalay ? 'alt-height' : ''
                                }`}
                                header={contentToRender[1].header}
                                body={contentToRender[1].content}
                                ctaText={contentToRender[1].linkText}
                                onClick={() =>
                                    this.handleClickLink(contentToRender[1].url)
                                }
                                icon="icon-green-leaf"
                            />
                        </div>
                        {isKL && (
                            <div
                                className="display--flex justify--center"
                                style={{ marginBottom: '50px' }}
                            >
                                {contentToRender[3] && (
                                    <PromotionTiles
                                        customClass={`four ${
                                            isLangMalay ? 'alt-height' : ''
                                        }`}
                                        header={contentToRender[3].header}
                                        body={contentToRender[3].content}
                                        ctaText={contentToRender[3].linkText}
                                        onClick={() =>
                                            this.handleClickLink(
                                                null,
                                                contentToRender[3].method
                                            )
                                        }
                                        icon="icon-green-leaf"
                                    />
                                )}
                            </div>
                        )}
                        <ButtonUI
                            customClasses={`${base}__cta button--success`}
                            text={intl.get('about.section-save.cta')}
                            onClick={this.onClick}
                        />
                    </div>
                )}
                {this.props.selectPopupStore.show && <SelectPopup />}
            </div>
        );
    }
}

export default withRouter(SectionSave);
