import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import CountDownClock from '../../core/CountDownClock';
import BusyBox from './BusyBox';
import { RESOURCE, VERIFICATION_STATUS } from '../../../services/types';

const base = 'profile';

@inject('profileAccountDetailStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = { loading: false };
    }

    componentDidMount() {
        this.props.profileAccountDetailStore.fetchUserInfo();
    }

    renderVerificationButton() {
        const store = this.props.profileAccountDetailStore;
        const { verifier, timerRunning } = store;

        const verifyButtonClasses =
            timerRunning === true
                ? 'button--disabled'
                : verifier === VERIFICATION_STATUS.CodeRequesting
                ? 'button--busy'
                : '';

        return (
            <button
                type="button"
                className={`button button--success width--100 ${verifyButtonClasses}`}
                onClick={::store.requestVerificationCode}
            >
                {store.verifyButtonText}
                {timerRunning === true ? (
                    <CountDownClock
                        from={store.retryTime}
                        displayFn={(tick) => ' (' + tick + ')'}
                        onFinish={::store.allowResendCode}
                    />
                ) : null}
            </button>
        );
    }

    renderSubmitButton() {
        const store = this.props.profileAccountDetailStore;
        const { userUpdater, verifier } = store;
        const isBusy =
            userUpdater === RESOURCE.Loading ||
            VERIFICATION_STATUS.Verifying.is(verifier);
        const notRequestVerifyCodeYet =
            !VERIFICATION_STATUS.CodeRequestSuccess.is(verifier);
        let submitButtonClasses = 'button button--success profile__button';
        submitButtonClasses = isBusy
            ? (submitButtonClasses += ' button--busy')
            : submitButtonClasses;
        submitButtonClasses = notRequestVerifyCodeYet
            ? (submitButtonClasses += ' button--disabled')
            : submitButtonClasses;

        return (
            <button
                type={notRequestVerifyCodeYet ? 'button' : 'submit'}
                className={submitButtonClasses}
            >
                {intl.get('profile.accountDetail.cta')}
            </button>
        );
    }

    renderLoader = () => {
        const store = this.props.profileAccountDetailStore;
        const { userFetcher } = store;

        if (RESOURCE.Success.is(userFetcher) === false) {
            this.setState({ loading: true });
        } else {
            this.setState({ loading: false });
        }
    };

    render() {
        const store = this.props.profileAccountDetailStore;
        const { validationResult } = store;
        const updateModel = ::store.updateModel;

        if (this.state.loading) {
            return <BusyBox headerText="Account Details" />;
        }

        return (
            <div className={`${base}__box`}>
                <div className={`${base}__box-header`}>
                    {intl.get('profile.accountDetail.header')}
                </div>
                <div className={`${base}__box-body`}>
                    <form onSubmit={store.verifyPhone}>
                        <div className="row mbm">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <label>
                                    {intl.get('profile.accountDetail.email')}
                                </label>
                                <br />
                                <input
                                    id="inputEmail"
                                    type="text"
                                    name="email"
                                    value={store.email}
                                    onChange={updateModel}
                                    className={
                                        'input width--100 ' +
                                        store.validationResult.getInputErrorClassOf(
                                            'email'
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 mbm col-reduced-gutter--right normal-gutter--if-small">
                                <label htmlFor="inputFirstName">
                                    {intl.get(
                                        'profile.accountDetail.firstName'
                                    )}
                                </label>
                                <br />
                                <input
                                    id="inputFirstName"
                                    type="text"
                                    name="firstName"
                                    value={store.firstName}
                                    onChange={updateModel}
                                    className={
                                        'input width--100 ' +
                                        store.validationResult.getInputErrorClassOf(
                                            'firstName'
                                        )
                                    }
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mbm col-reduced-gutter--left normal-gutter--if-small">
                                <label htmlFor="inputLastName">
                                    {intl.get('profile.accountDetail.lastName')}
                                </label>
                                <br />
                                <input
                                    id="inputLastName"
                                    type="text"
                                    name="lastName"
                                    value={store.lastName}
                                    onChange={updateModel}
                                    className={
                                        'input width--100 ' +
                                        store.validationResult.getInputErrorClassOf(
                                            'lastName'
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 mbm col-reduced-gutter--right normal-gutter--if-small">
                                <label htmlFor="inputPhoneNumber">
                                    {intl.get(
                                        'profile.accountDetail.phoneNumber'
                                    )}
                                </label>
                                <br />
                                <div className="display--flex">
                                    <input
                                        autoComplete="tel-country-code"
                                        type="tel"
                                        name="countryCode"
                                        value={store.countryCode}
                                        onChange={updateModel}
                                        className={
                                            `${base}__country-code input width--100 border--rounded-left ` +
                                            store.validationResult.getInputErrorClassOf(
                                                'countryCode'
                                            )
                                        }
                                    />
                                    <input
                                        type="tel"
                                        id="inputPhoneNumber"
                                        autoComplete="tel"
                                        name="phoneNumber"
                                        value={store.phoneNumber}
                                        onChange={updateModel}
                                        className={
                                            'input width--100 border--rounded-right ' +
                                            store.validationResult.getInputErrorClassOf(
                                                'phoneNumber'
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mbm col-reduced-gutter--left normal-gutter--if-small">
                                <label> </label>
                                <br />
                                {this.renderVerificationButton()}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 mbm col-reduced-gutter--right normal-gutter--if-small">
                                <label htmlFor="inputVerificationCodde">
                                    {intl.get(
                                        'profile.accountDetail.verificationCode'
                                    )}
                                </label>
                                <br />
                                <input
                                    id="inputVerificationCodde"
                                    type="text"
                                    name="verificationCode"
                                    value={store.verificationCode}
                                    onChange={updateModel}
                                    className={
                                        'input width--100 ' +
                                        store.validationResult.getInputErrorClassOf(
                                            'verificationCode'
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <p className="text--red text--left fs--small-medium display--none-if-empty">
                            {validationResult.getFirstError()}
                        </p>

                        <center className="mtm">
                            {this.renderSubmitButton()}
                        </center>
                    </form>
                </div>
            </div>
        );
    }
}
