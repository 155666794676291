import React, { Component, Fragment } from 'react';
import ButtonUI from '../../core/loginPopup/ButtonUI';
import { cities, GetCity } from '../../../services/city';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import intl from 'react-intl-universal';

const base = 'date-time-selector';

export default class DateAndTimeSelectorUI extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.scrollToActiveDate();
    }

    scrollToActiveDate = () => {
        const { autoScroll } = this.props;

        if (autoScroll) {
            const waitForActiveDate = setInterval(() => {
                if (document.getElementById('active-timeslot')) {
                    const activeTimeslotContainer =
                        document.getElementById('active-timeslot');
                    const timeslotContainer =
                        document.getElementById('times-container');
                    const diff =
                        timeslotContainer.offsetTop -
                        activeTimeslotContainer.offsetTop;
                    timeslotContainer.scrollTop = Math.abs(diff);
                    clearInterval(waitForActiveDate);
                }
            }, 100);
        }
    };

    render() {
        const {
            validDates,
            validTimeslotsForActiveDate,
            isActiveDate,
            isActiveTimeslot,
            setActiveDate,
            setActiveTimeslot,
            saveTimeslot,
            sendFullTimeslot,
            hideSubmit,
            mainHeading,
            subHeading,
            autoScroll,
        } = this.props;
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;

        return (
            <Fragment>
                <div
                    className={base}
                    style={{
                        marginBottom: hideSubmit && isMobile ? '20px' : '',
                    }}
                >
                    <div className={`${base}__main-date-container`}>
                        {mainHeading && (
                            <div className={`${base}__header-container`}>
                                <p className={`${base}__header text--bolder`}>
                                    {mainHeading}
                                </p>
                            </div>
                        )}
                        {validDates && (
                            <div className={`${base}__dates-container`}>
                                {validDates.map((date, i) => (
                                    <div
                                        className={`${base}__date-container cursor-pointer ${
                                            isActiveDate(date.date)
                                                ? 'active'
                                                : ''
                                        }`}
                                        onClick={() => setActiveDate(date)}
                                        key={i}
                                    >
                                        <p className={`${base}__date`}>
                                            {date.display_date}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div
                        className={`${base}__main-time-container`}
                        style={{ marginTop: validDates ? '' : '50px' }}
                    >
                        <div className={`${base}__header-container`}>
                            <p className={`${base}__header text--bolder`}>
                                {subHeading}
                            </p>
                        </div>
                        <div
                            className={`${base}__times-container ${
                                autoScroll ? 'overflow' : ''
                            }`}
                            id="times-container"
                        >
                            {validTimeslotsForActiveDate &&
                                validTimeslotsForActiveDate.map(
                                    (timeslot, i) => (
                                        <div
                                            ref={
                                                isActiveTimeslot(
                                                    sendFullTimeslot
                                                        ? timeslot
                                                        : timeslot.id
                                                ) && this.myRef
                                            }
                                            className={`${base}__time-container cursor-pointer ${
                                                isActiveTimeslot(
                                                    sendFullTimeslot
                                                        ? timeslot
                                                        : timeslot.id
                                                )
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                setActiveTimeslot(
                                                    sendFullTimeslot
                                                        ? timeslot
                                                        : timeslot.id
                                                )
                                            }
                                            key={i}
                                            id={
                                                isActiveTimeslot(
                                                    sendFullTimeslot
                                                        ? timeslot
                                                        : timeslot.id
                                                )
                                                    ? 'active-timeslot'
                                                    : ''
                                            }
                                        >
                                            <p className={`${base}__time`}>
                                                {timeslot.display_text}
                                            </p>
                                            {GetCity() === cities.kl &&
                                                timeslot.delivery_charge_display_text && (
                                                    <p
                                                        className={`${base}__delivery-text`}
                                                    >
                                                        {
                                                            timeslot.delivery_charge_display_text
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                </div>
                {!hideSubmit && (
                    <div className={`${base}__cta-container`}>
                        <ButtonUI
                            customClasses={`${base}__cta button--success`}
                            text={intl.get('promotions.cta')}
                            onClick={saveTimeslot}
                        />
                    </div>
                )}
            </Fragment>
        );
    }
}
