import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
const { clevertap } = window;

const base = 'shopping-cart-meal';

@inject('selectPopupStore', 'cartStore')
@observer
export default class SelectUpsell extends Component {
    componentDidMount() {
        const { selectPopupStore } = this.props;
        selectPopupStore.handleGetSelectPopupContent();
        clevertap.event.push('See Select Upsell In Cart');
    }

    render() {
        const { selectPopupStore, cartStore, marginBottom } = this.props;

        return selectPopupStore.popupContent &&
            selectPopupStore.popupContent.heading ? (
            <div
                className={`${base}__select-upsell display--flex cursor-pointer`}
                onClick={selectPopupStore.handleShowPopup}
                style={{ marginBottom: marginBottom + 'px' }}
            >
                <p className={`${base}__select-text text--bolder`}>
                    {cartStore.preliminaryOrderSummary.select_upsell_text}
                </p>
                <div className={`${base}__select-button-container`}>
                    <svg className={`${base}__select-button iconmoon-icon`}>
                        <use xlinkHref={`#select-button`} />
                    </svg>
                </div>
            </div>
        ) : (
            <div />
        );
    }
}
