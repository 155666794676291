import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { ClientGuard } from './middlewares/clientGuard';
import WalletBillPlzProcessRoot from '../components/WalletBillPlzProcessRoot';
import BasePage from './basePage';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

export default class extends Component {
    static middleware() {
        return [ClientGuard];
    }

    componentDidMount() {
        document.body.classList.add('checkout');
        document.body.classList.add('checkout--no-fixed-banner');
    }

    getTitle() {
        return services.getTitle('walletBillPlzProcess');
    }

    render() {
        const { match } = this.props;

        return (
            <Fragment>
                <Helmet>
                    <title>{this.getTitle()}</title>
                    {localizationTags(`wallet/purchase/${match.params.id}`).map(
                        (tag) => tag
                    )}
                    {getCanonicalTag(`wallet/purchase/${match.params.id}`)}
                </Helmet>
                <BasePage />
                <WalletBillPlzProcessRoot walletOrderId={match.params.id} />
            </Fragment>
        );
    }
}
