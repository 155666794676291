import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { inject, observer } from 'mobx-react';

@observer
export default class PaymentInProgress extends Component {
    render() {
        const { color } = this.props;
        let chosenColor = color ? color : 'Blue';
        const base = 'payment-in-progress';
        let loaderClass;
        if (chosenColor === 'Green') {
            loaderClass = base + '__loader ' + base + '__loader--green';
        } else if (chosenColor === 'Blue') {
            loaderClass = base + '__loader ' + base + '__loader--blue';
        } else {
            loaderClass = base + '__loader';
        }

        return (
            <div className={base}>
                <h2 className={`${base}__title text--bold`}>
                    {intl.get('packageCheckout.inProgress.warning')}
                </h2>
                <div className={loaderClass}>
                    <div className={`${base}__loader-content`}>
                        {intl.get('packageCheckout.inProgress.loading')}
                    </div>
                </div>
            </div>
        );
    }
}
