import React, { Component, Fragment } from 'react';
import SwitchButton from 'lyef-switch-button';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import LeafIcon from '../../../resources/images/svg/icon-leaf.svg';

const { clevertap } = window;

const base = 'shopping-cart-meal';

@inject('userStore', 'cartStore')
@observer
class CutlerySwitcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCutleryProvided: false,
            showCutleryInfo: false,
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        const { cartStore, date } = this.props;

        const isCutleryProvided = cartStore.cart.find(
            (delivery) => delivery.date === date
        ).provide_cutlery;
        this.setState({ isCutleryProvided });

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                showCutleryInfo: false,
            });
        }
    }

    handleToggleSwitch = () => {
        const { cartStore, date } = this.props;
        const { isCutleryProvided } = this.state;
        this.setState(
            {
                isCutleryProvided: !isCutleryProvided,
            },
            () => {
                cartStore.updateCutleryProvidedInCart(date, !isCutleryProvided);
                const hasEnabledCutlery = !isCutleryProvided
                    ? 'Enabled Cutlery'
                    : 'Disabled Cutlery';
                clevertap.event.push(hasEnabledCutlery);
            }
        );
    };

    handleShowCutleryInfo = () => {
        this.setState({
            showCutleryInfo: !this.state.showCutleryInfo,
        });
    };

    render() {
        const { isCutleryProvided, showCutleryInfo } = this.state;
        const { id, cartStore, isLastRow } = this.props;
        const { cutleryChargePerUnit } = cartStore;

        return (
            <Fragment>
                {cutleryChargePerUnit && (
                    <Fragment>
                        <div className={`${base}__cutlery-switcher`}>
                            <div className="display--flex flex-align-items-center justify--space-between mbm">
                                <div>
                                    <p className="text--bolder mbs">
                                        Cutlery
                                        <span
                                            className="cursor-pointer position--relative"
                                            onClick={this.handleShowCutleryInfo}
                                        >
                                            <svg className="iconmoon-icon">
                                                <use xlinkHref="#icon-information" />
                                            </svg>
                                        </span>
                                        {showCutleryInfo && (
                                            <Fragment>
                                                <div
                                                    className={`${base}__cutlery-bubble`}
                                                    id="cutlery-bubble"
                                                    ref={this.setWrapperRef}
                                                >
                                                    <div
                                                        className={`${base}__cutlery-bubble__triangle`}
                                                    />
                                                    <p
                                                        className={`${base}__cutlery-bubble__text`}
                                                    >
                                                        {intl.get(
                                                            'mealRowCartPreview.cutleryBubbleLine1'
                                                        )}
                                                        <br />
                                                        <br />
                                                        {intl.get(
                                                            'mealRowCartPreview.cutleryBubbleLine2'
                                                        )}
                                                        <br />
                                                        <br />
                                                        {intl.get(
                                                            'mealRowCartPreview.cutleryBubbleLine3'
                                                        )}
                                                        <br />
                                                        <br />
                                                        {intl.get(
                                                            'mealRowCartPreview.cutleryBubbleLine4'
                                                        )}
                                                    </p>
                                                </div>
                                            </Fragment>
                                        )}
                                    </p>
                                    <p
                                        className={`${base}__cutlery-switcher-subheader fs--small opacity--half`}
                                    >
                                        {isCutleryProvided ? (
                                            `${intl.get(
                                                'cutlerySelection.enabled'
                                            )} ${cutleryChargePerUnit}.`
                                        ) : (
                                            <Fragment>
                                                <img
                                                    src={LeafIcon}
                                                    className={`${base}__cutlery-switcher-icon`}
                                                />
                                                <span>
                                                    {intl.get(
                                                        'cutlerySelection.disabled'
                                                    )}
                                                </span>
                                            </Fragment>
                                        )}
                                    </p>
                                </div>
                                <div>
                                    <SwitchButton
                                        id={`switch${id}`}
                                        isChecked={isCutleryProvided}
                                        action={this.handleToggleSwitch}
                                    />
                                </div>
                            </div>
                            {!isCutleryProvided && (
                                <p className="fs--small cutlery-text">
                                    {intl.get('cutlerySelection.thankyou')}
                                </p>
                            )}
                        </div>
                        {isLastRow && (
                            <hr className={`${base}__quantity-separator`} />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default CutlerySwitcher;
