import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import BasePage from './basePage';
import DeliveryAreaRoot from '../components/DeliveryAreaRoot';
import { getGTMScript } from '../components/core/ScriptTag';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('deliveryArea');

export default class extends Component {
    componentDidMount() {
        document.body.classList.add('delivery-area');
        window.scrollTo(0, 0);
    }

    render() {
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: 'Profile',
            lpType: '',
            lpVersion: '',
        });

        return (
            <div>
                <Helmet>
                    <title>{pageTitle}</title>
                    {localizationTags(`delivery-area`).map((tag) => tag)}
                    {getCanonicalTag('delivery-area')}
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <DeliveryAreaRoot />
            </div>
        );
    }
}
