import React from 'react';
import template from 'lodash/template';
import intl from 'react-intl-universal';
import { services } from '../../../../services/index';
import ShareBox from '../../rewardsPage/ShareBox';
import SummaryItem from './SummaryItem';

const base = 'menu-cart-confirm';

export default ({ user, orderSummary, finalOrderSummary, orders }) => {
    return (
        <div className={base}>
            <div className={`${base}__header`}>
                <section>
                    <span className={`${base}__thank-you`}>
                        {intl.get('shoppingCart.confirmPayment.title')}
                    </span>
                </section>
                <section className="lh--125 mtm">
                    <p>
                        {template(
                            intl.get('shoppingCart.confirmPayment.subtitle')
                        )({ firstName: user.firstname })}
                    </p>
                    <p>{intl.get('shoppingCart.confirmPayment.subtitle2')}</p>
                </section>
            </div>
            <ShareBox customClasses="border--rounded-bottom" />
            <div className={`${base}__body bg--white`}>
                <section className="row pm pt0 pb0">
                    <div>
                        <p className={`${base}__heading`}>
                            {intl.get('shoppingCart.confirmPayment.details')}
                        </p>
                        <p className="fs--small-medium">{user.alias}</p>
                        <p className="fs--small-medium">{user.username}</p>
                    </div>
                    <div>
                        <p className={`${base}__heading`}>
                            {intl.get(
                                'shoppingCart.confirmPayment.orderNumber'
                            )}
                        </p>
                        <p className="fs--small-medium">
                            #{finalOrderSummary.group_id}
                        </p>
                    </div>
                </section>
                {orders}
                <hr className="show--if-small hide--if-large" />
                <section
                    className={`${base}__invoices fs--small-medium prm lh--150`}
                >
                    {orderSummary.price_summary.rows.map((item, i) => (
                        <SummaryItem
                            key={i}
                            title={item.name}
                            value={item.value}
                            bold={item.bold}
                            color={item.color}
                            base={base}
                        />
                    ))}
                </section>
                <section className={`${base}__payment-method fs--small-medium`}>
                    <span>
                        {intl.get('shoppingCart.confirmPayment.paymentMethod')}
                    </span>
                    <span className={`${base}__invoices-col`}>
                        {orderSummary.payment_method}
                    </span>
                </section>
            </div>
            <div className={`${base}__apps pm`}>
                <a
                    href={services.getParam('appStorePath')}
                    className="display--inline-block mrl"
                >
                    <img
                        className={`${base}__app-button`}
                        src="https://d3tjqo2jh935k9.cloudfront.net/images/static/app-store-black.png"
                        alt={`Download ${services.getParam(
                            'brandName'
                        )} iOS app`}
                    />
                </a>
                <a
                    href={services.getParam('googlePlayPath')}
                    className="display--inline-block"
                >
                    <img
                        className={`${base}__app-button`}
                        src="https://d3tjqo2jh935k9.cloudfront.net/images/static/google-play-black.png"
                        alt={`Download ${services.getParam(
                            'brandName'
                        )} Android app`}
                    />
                </a>
            </div>
        </div>
    );
};
