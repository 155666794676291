import React from 'react';
import { services } from '../services/index';

export const localizationTags = (path) => {
    const hostname = services.getParam('deploymentUrl');

    if (path[0] === '/') {
        path = path.slice(1);
    }

    return [
        <link
            rel="alternate"
            hrefLang="en-my"
            href={`${hostname}/kuala-lumpur/${path}`}
            key="en-my"
        />,
        <link
            rel="alternate"
            hrefLang="en-th"
            href={`${hostname}/bangkok/${path}`}
            key="en-th"
        />,
        <link
            rel="alternate"
            hrefLang="th-my"
            href={`${hostname}/th/kuala-lumpur/${path}`}
            key="th-my"
        />,
        <link
            rel="alternate"
            hrefLang="th-th"
            href={`${hostname}/th/bangkok/${path}`}
            key="th-th"
        />,
        <link
            rel="alternate"
            hrefLang="en"
            href={`${hostname}/kuala-lumpur/${path}`}
            key="en"
        />,
        <link
            rel="alternate"
            hrefLang="th"
            href={`${hostname}/th/kuala-lumpur/${path}`}
            key="th"
        />,
        <link
            rel="alternate"
            hrefLang="x-default"
            href={`${hostname}/kuala-lumpur/${path}`}
            key="default"
        />,
    ];
};
