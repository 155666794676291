import React, { Component } from 'react';
import SectionHero from './SectionHero';
import LoginPopup from '../../core/LoginPopup';
import { observer } from 'mobx-react';
import SectionChefs from './SectionChefs';
import ButtonUI from '../../core/loginPopup/ButtonUI';
import intl from 'react-intl-universal';

const base = 'chef';

@observer
export default class ChefPage extends Component {
    onClick = () => {
        window.location.href = '/menu';
    };

    render() {
        return (
            <div className={base}>
                <LoginPopup dismissable={true} showCloseIcon={true} />
                <SectionHero />
                <SectionChefs />
                <ButtonUI
                    text={intl.get('promotions.cta')}
                    customClasses={`${base}__cta button--success`}
                    onClick={this.onClick}
                />
            </div>
        );
    }
}
