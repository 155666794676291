import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import BasePage from './basePage';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';
import MealSelectionRoot from '../components/MealSelectionRoot';
const pageTitle = services.getTitle('terminal-dealSelection');

@observer
export default class extends Component {
    componentDidMount() {
        document.body.classList.add('terminal');
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                    {localizationTags(`terminal-mealSelection`).map(
                        (tag) => tag
                    )}
                    {getCanonicalTag(`terminal-mealSelection`)}
                </Helmet>
                <BasePage />

                <MealSelectionRoot />
            </Fragment>
        );
    }
}
