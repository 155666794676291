import React from 'react';

import BaseLayout from './core/baseLayout/Master_BaseLayout';
import MenuFooter from './core/MenuFooter';
import Entry from './page/resetPasswordPage/Entry';
import { Provider } from 'mobx-react';
import menuHeaderStore from '../store/menuHeaderStore';

export default class extends React.Component {
    render() {
        return (
            <BaseLayout.Page>
                <main>
                    <Entry params={this.props.params} />
                </main>
                <div>
                    <Provider menuHeaderStore={menuHeaderStore}>
                        <MenuFooter />
                    </Provider>
                </div>
            </BaseLayout.Page>
        );
    }
}
