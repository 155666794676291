import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import SubmitButton from './SubmitButton';
import VerificationButton from './VerificationButton';

const base = 'complete-signup';

@inject('completeSignUpStore')
@observer
export default class extends React.Component {
    renderMobileForm() {
        const { completeSignUpStore } = this.props;
        const handleChange = completeSignUpStore.handleChange;

        return (
            <Fragment>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-reduced-gutter--right normal-gutter--if-small display--flex">
                        <input
                            type="tel"
                            autoComplete="tel-country-code"
                            className={`${base}__country-code mbm input fs--medium`}
                            name="countryCode"
                            title={intl.get('completeSignUp.countryCode')}
                            value={completeSignUpStore.countryCode}
                            onChange={handleChange}
                        />
                        <input
                            type="tel"
                            autoComplete="tel"
                            className={`${base}__phone input mbm fs--medium`}
                            title={intl.get('completeSignUp.phoneNumber')}
                            name="phoneNumber"
                            placeholder={intl.get('completeSignUp.phoneNumber')}
                            value={completeSignUpStore.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-reduced-gutter--left normal-gutter--if-small">
                        <div className="width--100 mbm">
                            <VerificationButton
                                onClick={

                                        ::completeSignUpStore.requestVerificationCode
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-reduced-gutter--right normal-gutter--if-small">
                        <input
                            type="number"
                            className="input width--100 fs--medium mbm"
                            title={intl.get(
                                'completeSignUp.verificationCode.title'
                            )}
                            name="verificationCode"
                            autoComplete="off"
                            placeholder={intl.get(
                                'completeSignUp.verificationCode.placeholder'
                            )}
                            value={completeSignUpStore.verificationCode}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }

    renderEmailForm = () => {
        const { completeSignUpStore } = this.props;
        const handleChange = completeSignUpStore.handleChange;

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 normal-gutter--if-small">
                    <input
                        type="email"
                        className="input width--100 fs--medium mbm"
                        title="Email"
                        name="email"
                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
                        onInvalid={(e) =>
                            e.target.setCustomValidity(
                                e.target.willValidate
                                    ? ''
                                    : 'Please enter a valid email address'
                            )
                        }
                        autoComplete="off"
                        placeholder="Email"
                        value={completeSignUpStore.email}
                        onChange={handleChange}
                    />
                </div>
            </div>
        );
    };
    render() {
        const { completeSignUpStore, showEmail } = this.props;
        const { validationResult } = completeSignUpStore;
        const handleChange = ::completeSignUpStore.handleChange;

        return (
            <div className="col-lg-11">
                <p className="mbm lh--125 fs--small-medium">
                    {intl.get('user.completeSignUpInstruction.normal')}
                </p>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-reduced-gutter--right normal-gutter--if-small">
                        <input
                            type="text"
                            title={intl.get('completeSignUp.firstName')}
                            name="firstName"
                            className="input width--100 mbm fs--medium"
                            placeholder={intl.get('completeSignUp.firstName')}
                            value={completeSignUpStore.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-reduced-gutter--left normal-gutter--if-small">
                        <input
                            type="text"
                            title={intl.get('completeSignUp.lastName')}
                            name="lastName"
                            className="input width--100 mbm fs--medium"
                            placeholder={intl.get('completeSignUp.lastName')}
                            value={completeSignUpStore.lastName}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                {showEmail ? this.renderEmailForm() : this.renderMobileForm()}
                <p className="text--red fs--small-medium mbm display--none-if-empty">
                    {validationResult.getFirstError()}
                </p>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <SubmitButton text="SAVE" showEmail={showEmail} />
                    </div>
                </div>
            </div>
        );
    }
}
