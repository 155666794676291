import React, { Component } from 'react';
import Rewards from '../../core/Rewards';

const base = 'rewards';

export default class RewardsBox extends Component {
    render() {
        return (
            <div className={`${base}__rewards-box border--rounded`}>
                <Rewards />
            </div>
        );
    }
}
