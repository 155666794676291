/* eslint-disable no-undef */
import Cookies from 'js-cookie';
import once from 'lodash/once';
import { httpSubmit, httpGet, httpPost, httpPut, httpDelete } from '../http';
import smartMemoize from '../smartMemoize';

import { services } from '../../services/index';
import { getCompanyFromLocalStorage } from '../company';

const company = getCompanyFromLocalStorage();

export const UserLogin = (username, password, grant_type = 'password') => {
    const payload = { username, password, grant_type };

    return httpSubmit('/oauth/token', payload);
};

export const TerminalLogin = (username, password, grant_type = 'password') => {
    const payload = {
        username,
        password,
        grant_type,
        terminal: true,
    };

    return httpSubmit('/oauth/token', payload);
};

export const GetUser = once(() => {
    return httpGet('/v3/users', { authType: 'Bearer' });
});

export const GetUserWithMemoizer = smartMemoize('user', () => {
    return httpGet('/v3/users', { authType: 'Bearer' });
});

export const GetUserWithToken = (accessToken) => {
    return httpGet('/v3/users', { authType: 'Token', accessToken });
};

export const GetMagicSignInToken = (requestBody = { token }) => {
    return httpPost('/v3/users/magic-sign-in', requestBody, { json: true });
};

export const GetAppDownloadLink = () => {
    return httpGet('/v3/users/app-install-link', { authType: 'Bearer' });
};

export const GetPaymentMethods = () => {
    return httpGet('/v3/users/card', { authType: 'Bearer' });
};

export const DeletePaymentMethod = ({ id }) => {
    return httpDelete(`/v3/users/card/${id}`, { authType: 'Bearer' });
};

export const LoginWithFacebook = (
    requestBody = {
        email,
        facebook_id,
        lastname,
        firstname,
        marketing_campaign,
    }
) => {
    return httpPost('/v3/users/register', requestBody, {
        attachRequestInfo: ['status'],
    });
};

export const LoginWithGoogle = (
    requestBody = { email, google_id, lastname, firstname, marketing_campaign }
) => {
    return httpPost('/v3/users/register', requestBody, {
        attachRequestInfo: ['status'],
    });
};

export const GetAddresses = smartMemoize('address', () => {
    return httpGet('/v4/users/address', { authType: 'Bearer' });
});

export const DeleteAddress = ({ id }) => {
    return httpDelete(`/v3/users/address/${id}`, { authType: 'Bearer' });
};

export const GetTimeslots = ({ date, mealTime }) => {
    return httpGet(`/v4/time-slots/${mealTime}?date=${date}`, {
        authType: 'Bearer',
    });
};

export const GetTimeslotsByItems = (
    requestBody = { date, menu_item, menu_type }
) => {
    requestBody.use_company = company;
    return httpPost(`/v5/time-slots/list`, requestBody, { authType: 'Bearer' });
};

export const Ping = () => {
    return httpGet('/oauth/ping', { authType: 'Bearer', isomorphic: true });
};

export const ResetPassword = (username) => {
    return httpGet(`/v3/users/reset-password/${username}/app`, {
        authType: 'Bearer',
    });
};

export const UpdatePassword = ({ token, requestBody = { id, password } }) => {
    return httpPost(`/v3/users/change-password/${token}`, requestBody, {
        authType: 'Bearer',
    });
};

export const GetInfo = once(() => {
    return httpGet('/v3/users/web/info', { authType: 'Bearer' });
});

export const CreateNewUser = (email, password, marketing_campaign) => {
    return httpPost(
        '/v3/users/create',
        {
            email: email,
            password: password,
            marketing_campaign: marketing_campaign,
        },
        { attachRequestInfo: ['statusCode'] }
    );
};

export const CheckUsername = (username) => {
    return httpGet(`/v4/users/username-available/${username}`);
};

export const StoreFreshChatId = (id) => {
    return httpPost(
        '/v3/users/update-freshchat-id',
        { freshchat_id: id },
        { authType: 'Bearer' }
    );
};

export const UpdatePartnerIntegration = (partner_integration) => {
    return httpPost(
        '/v3/users/update-partner-integration',
        { partner_integration },
        { authType: 'Bearer' }
    );
};

export const UpdateUser = (
    requestBody = { email, firstname, lastname, mobileno, password }
) => {
    return httpPut('/v3/users', requestBody, { authType: 'Bearer' });
};

export const UpdateUserName = (userId, name) => {
    return httpPut(`/v4/terminal/user/${userId}?alias=${name}`, null, {
        authType: 'Bearer',
    });
};

export const GetPastOrders = () => {
    return httpGet('/v4/deliveries/past', { authType: 'Bearer' });
};

export const GetUpcomingOrders = () => {
    return httpGet('/v4/deliveries/upcoming', { authType: 'Bearer' });
};

export const GetWalletStatus = () => {
    return httpGet('/wallet/status', { authType: 'Bearer' });
};

export const SetAutoTopup = (flag) => {
    return httpPost(`/wallet/auto-top-up/${flag}`, {}, { authType: 'Bearer' });
};

export const SetLanguage = (lang) => {
    return httpPost(
        `/v4/users/set-language/${lang}`,
        {},
        { authType: 'Bearer' }
    );
};

export const GetOtp = (requestBody = { phone_no }) => {
    return httpPost('/v4/password-less/phone/code/sms', requestBody);
};

export const GetNotificationSettings = () => {
    return httpGet('/v4/users/notification', { authType: 'Bearer' });
};

export const SetNotificationSettings = (platform, value) => {
    return httpPost(
        `/v4/users/notification/${platform}/${value}`,
        {},
        { authType: 'Bearer' }
    );
};

export const VerifyWithMobileNumber = (mobileNumber, otp, useToken = true) => {
    const request = {
        phone_no: mobileNumber,
        code: otp,
    };

    return httpPost(
        `/v4/password-less/verify${useToken === false ? '?token=false' : ''}`,
        request,
        { authType: 'Bearer' }
    );
};

export const AccessTokenStorage = {
    // undefined -> unknown, null -> user is not logged in, else logged in user
    token: undefined,

    setToken: (token) => {
        Cookies.set(`${services.getParam('city')}_Authorization`, token, {
            expires: 30,
        });
        AccessTokenStorage.token = token;
    },
    getToken: () => {
        if (AccessTokenStorage.token !== undefined) {
            return AccessTokenStorage.token;
        }
        const token = Cookies.get('Authorization');
        if (token) {
            services.api.AccessTokenStorage.setToken(token);
            Cookies.remove('Authorization');
            AccessTokenStorage.token = token;
            return token;
        }
        const result = Cookies.get(
            `${services.getParam('city')}_Authorization`
        );
        AccessTokenStorage.token = result;
        return result;
    },
    clearToken: () => {
        AccessTokenStorage.token = null;
        return Cookies.remove(`${services.getParam('city')}_Authorization`);
    },
    refreshToken: () => {
        const token = services.api.AccessTokenStorage.getToken();
        if (token) {
            services.api.AccessTokenStorage.setToken(token);
        }
        AccessTokenStorage.token = token;
    },
};
