import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { currency } from '../../services';
import menuHeaderStore from '../../store/menuHeaderStore';
import { when } from 'mobx';
import { autoAssignedCodeAmount } from '../../services/autoAssignDiscount';
import { referralMultiplier } from '../../services/referrals';

const base = 'promotion-bar';

@inject('rewardsStore', 'loginPopupStore', 'userStore')
@observer
export default class PromotionBar extends Component {
    state = {
        isBusy: false,
        show: false,
        reward: null,
    };

    componentDidMount() {
        const { rewardsStore } = this.props;
        rewardsStore.getRewards();

        window.addEventListener('resize', this.handleSetPromotionBarHeight);

        this.handlePromotionBarLogic();
        this.handleInputLogic();
    }

    componentWillUnmount() {
        menuHeaderStore.promotionBarHeight = null;
        window.removeEventListener('resize', this.handleSetPromotionBarHeight);
    }

    handleInputLogic = () => {
        const { loginPopupStore } = this.props;

        when(
            () => loginPopupStore.validNumber,
            () => {
                loginPopupStore.showVariantVerifyNumber();
            }
        );

        when(
            () => loginPopupStore.message,
            () => this.setState({ isBusy: false })
        );
    };

    handlePromotionBarLogic = () => {
        const { userStore, rewardsStore, loginPopupStore } = this.props;
        const referral = loginPopupStore.referral();

        when(
            () => userStore.user.data,
            () => {
                const userIsLoggedIn = userStore.isLoggedIn;

                if (userIsLoggedIn) {
                    when(
                        () =>
                            rewardsStore.userRewards.length > 0 &&
                            rewardsStore.rewardToApply(),
                        () => {
                            const reward = rewardsStore.userRewards.find(
                                (reward) =>
                                    reward.id === rewardsStore.rewardToApply()
                            );

                            this.setState(
                                {
                                    show: true,
                                    reward,
                                },
                                () => {
                                    this.handleSetPromotionBarHeight();
                                }
                            );
                        }
                    );
                } else if (referral || autoAssignedCodeAmount) {
                    let referralReward;
                    if (referral) {
                        if (referral.rewardIsPercentage) {
                            referralReward = referral.reward;
                        } else {
                            referralReward =
                                referral.reward /
                                (referral.rewardUsages || referralMultiplier);
                        }
                    }
                    this.setState(
                        {
                            show: true,
                            reward: referralReward || autoAssignedCodeAmount,
                        },
                        () => {
                            this.handleSetPromotionBarHeight();
                        }
                    );
                }
            }
        );
    };

    handleSetPromotionBarHeight = () => {
        if (document.getElementById('promotion-bar')) {
            menuHeaderStore.setPromotionBarHeight(
                document.getElementById('promotion-bar').offsetHeight
            );
        }
    };

    render() {
        const { hide, loginPopupStore } = this.props;
        const { show, reward } = this.state;
        const referral = loginPopupStore.referral();
        const referringUser =
            (referral && referral.referring_user) || 'your friend';
        const referringUserDisplayName = referringUser.includes('Your')
            ? 'your friend'
            : referringUser;

        let rewardTitle;
        // rewardsStore.rewardToApply() from rewardsStore.userRewards
        if (typeof reward === 'object' && reward !== null) {
            if (reward.referral_code) {
                rewardTitle = `Promo applied: ${reward.title}`;
            } else if (reward.reward_policy_name === 'cash_rm') {
                if (
                    reward.discount_type === 'PERCENTAGE' &&
                    reward.amount < 1
                ) {
                    rewardTitle = `You’ll save ${
                        reward.amount * 100
                    }% on your next order`;
                } else {
                    rewardTitle = `You’ll save ${currency.localCurrency(
                        reward.amount
                    )} on your next order`;
                }
            } else {
                rewardTitle = `Promo applied: ${reward.title}`;
            }
            // manually applied reward from exit popup
        } else {
            if (referral) {
                if (referral.rewardIsPercentage) {
                    rewardTitle = `Your ${
                        referral.rewardUsages || referralMultiplier
                    } x ${
                        reward * 100
                    }% voucher from ${referringUserDisplayName} is waiting for you! Complete sign up within 24 hours to redeem your voucher.`;
                } else {
                    rewardTitle = `Your ${
                        referral.rewardUsages || referralMultiplier
                    } x ${currency.localCurrency(
                        reward
                    )} voucher from ${referringUserDisplayName} is waiting for you! Complete sign up within 24 hours to redeem your voucher.`;
                }
            } else if (reward < 1) {
                rewardTitle = `You’ll save ${reward * 100}% on your next order`;
            } else {
                rewardTitle = `You’ll save ${currency.localCurrency(
                    reward
                )} on your next order`;
            }
        }

        return show && !hide ? (
            <div className={`${base}`} id={base}>
                <div className={`${base}__text-container`}>
                    <span className={`${base}__text two text--bolder`}>
                        {rewardTitle}
                    </span>
                </div>
            </div>
        ) : (
            <div />
        );
    }
}
