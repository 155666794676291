import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ResetPasswordRoot from '../components/ResetPasswordRoot';
import { isEmpty } from 'lodash';
import { services } from '../services/index';
import qs from 'query-string';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('resetPassword');

export default class extends Component {
    constructor(props) {
        super(props);
        this.params = null;
        this.state = {
            initDone: false,
        };
    }

    componentDidMount() {
        document.body.classList.add('resetPassword');
        this.params = qs.parse(this.props.location.search);
        if (isEmpty(this.params)) {
            this.setState({
                redirect: true,
            });
        } else {
            this.setState({
                initDone: true,
            });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/menu" />;
        }

        return (
            this.state.initDone && (
                <Fragment>
                    <Helmet>
                        <meta name="nosnippet" />
                        <title>{pageTitle}</title>
                        {localizationTags(`reset-password`).map((tag) => tag)}
                        {getCanonicalTag(`reset-password`)}
                    </Helmet>
                    <ResetPasswordRoot params={this.params} />
                </Fragment>
            )
        );
    }
}
