import dahmakanDev from './development/dahmakan';
import dahmakanStaging from './staging/dahmakan';
import dahmakanProd from './production/dahmakan';

import polpaDev from './development/polpa';
import polpaStaging from './staging/polpa';
import polpaProd from './production/polpa';

import { GetCity, cities } from '../services/city';

const parametersList = {
    dahmakan: {
        development: dahmakanDev,
        staging: dahmakanStaging,
        production: dahmakanProd,
    },
    polpa: {
        development: polpaDev,
        staging: polpaStaging,
        production: polpaProd,
    },
};

const city = GetCity();

let parameters;
if (process.env.REACT_APP_ENV) {
    parameters =
        city === cities.kl
            ? parametersList.dahmakan[process.env.REACT_APP_ENV]
            : parametersList.polpa[process.env.REACT_APP_ENV];
} else {
    parameters =
        city === cities.kl
            ? parametersList.dahmakan[process.env.NODE_ENV]
            : parametersList.polpa[process.env.NODE_ENV];
}

export default {
    ...parameters,
};
