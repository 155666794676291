import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { stripRepeatedCommas } from '../../../../services';
import { MAYBE } from '../../../../services/types';
import { AddressBusy } from '../../profilePage/Address';

const base = 'shopping-cart-address';

export const AddressItem = ({
    address,
    isSelected,
    onSelected,
    store,
    editable,
    deleteAddress,
}) => {
    const icon = isSelected
        ? '#icon-radio_button_checked'
        : '#icon-radio_button_unchecked';
    const name =
        address && address.address_type !== 'Else'
            ? address.address_type
            : address.location_type;

    const handleEditAddress = (id) => {
        store.editAddress(id);
    };

    const handleDeleteAddress = (address) => {
        const result = confirm('Are you sure you want to delete this address?'); //eslint-disable-line

        if (result) {
            deleteAddress(address);
        }
    };

    return (
        <div className={`${base}__item display--flex flex-align-items-center`}>
            <div className={`${base}__item-address flex-1-only mrs`}>
                <p className="text--dark mbxs">{name}</p>
                <p className="text--dark fs--small-medium lh--150">
                    {stripRepeatedCommas(
                        address.formatted_address || address.name
                    )}
                </p>
            </div>
            <div className="flex-none">
                {editable ? (
                    <div className="shopping-cart__edit-button-container display--flex">
                        <p
                            className={`shopping-cart__edit-button fs--small cursor-pointer`}
                            onClick={() => handleDeleteAddress(address)}
                        >
                            Delete
                        </p>
                        <p
                            className={`shopping-cart__edit-button fs--small cursor-pointer`}
                            onClick={() => handleEditAddress(address.id)}
                        >
                            Edit
                        </p>
                    </div>
                ) : (
                    <button
                        onClick={onSelected}
                        className="button button--nothing"
                    >
                        <svg className={`${base}__radio iconmoon-icon`}>
                            <use xlinkHref={icon} />
                        </svg>
                    </button>
                )}
            </div>
        </div>
    );
};

@inject(
    'deliveryAddressStore',
    'addressAutocompleteStore',
    'menuListStore',
    'menuCheckoutStore',
    'cartStore'
)
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editable: false,
        };
    }

    handleAddAddress = () => {
        const { deliveryAddressStore } = this.props;
        deliveryAddressStore.showPopup(deliveryAddressStore.POPUP_TYPING);
    };

    handleSelectAddress = (address) => {
        const { deliveryAddressStore, menuListStore, date } = this.props;
        deliveryAddressStore.setSelected({ date, address, checkCart: true });
        menuListStore.getMenuForEachDelivery({
            startDateString: date,
            endDateString: date,
            customAddress: address.id,
        });
        deliveryAddressStore.goToView(deliveryAddressStore.SHOW_VIEW)();
    };

    handleToggleEditable = () => {
        this.setState({ editable: !this.state.editable });
    };

    handleNavigateBack = () => {
        const { addressAutocompleteStore } = this.props;
        const store = this.props.deliveryAddressStore;

        if (store.showingPopup === store.POPUP_TYPING) {
            addressAutocompleteStore.getPlacePredictions(null);
            store.showMap = false;
        } else if (store.showingPopup === store.POPUP_ADJUSTING) {
            store.showingPopup = store.POPUP_TYPING;
        } else if (store.showingPopup === store.POPUP_NOTE) {
            store.showingPopup = store.POPUP_ADJUSTING;
        } else if (
            store.showingPopup === store.POPUP_OFFICE_EXPLAINED ||
            store.showingPopup === store.POPUP_HOUSE_EXPLAINED
        ) {
            store.showingPopup = store.POPUP_NOTE;
        }
    };

    render() {
        const { editable } = this.state;
        const store = this.props.deliveryAddressStore;
        const addresses = this.props.deliveryAddressStore.addresses.filter(
            (address) => !address.pickup_location
        );
        const selected = store.getSelected();

        return (
            <div>
                <div className="display--flex">
                    <p className="shopping-cart__section-title text--bold">
                        {intl.get('shoppingCart.deliveryAddress.header')}
                    </p>
                    <p
                        className={`shopping-cart__toggle-edit-button fs--small cursor-pointer ${
                            editable ? 'text--primary' : null
                        }`}
                        onClick={this.handleToggleEditable}
                    >
                        {editable ? 'Done' : 'Edit'}
                    </p>
                </div>
                {addresses.map((address) => {
                    if (
                        MAYBE.Some.is(
                            this.props.deliveryAddressStore.addressBeingDeleted
                        ) &&
                        this.props.deliveryAddressStore.addressBeingDeleted.data
                            .id === address.id
                    ) {
                        return <AddressBusy key={address.id} />;
                    }

                    return (
                        <AddressItem
                            key={address.id}
                            address={address}
                            isSelected={selected.id === address.id}
                            onSelected={this.handleSelectAddress.bind(
                                this,
                                address
                            )}
                            store={this.props.deliveryAddressStore}
                            editable={this.state.editable}
                            deleteAddress={
                                this.props.deliveryAddressStore.deleteAddress
                            }
                        />
                    );
                })}
                <div className="display--flex flex-align-items-center mts">
                    <div className="flex-1-only">
                        <button
                            className={`${base}__btn ${base}__btn--add-address button button--success`}
                            onClick={this.handleAddAddress}
                        >
                            {intl.get('shoppingCart.deliveryAddress.add')}
                        </button>
                    </div>
                    <div className="flex-1-only text--right">
                        <button
                            className={`${base}__btn ${base}__btn--cancel-add-address button`}
                            onClick={::store.goToView(store.SHOW_VIEW)}
                        >
                            {intl.get('shoppingCart.deliveryAddress.cancel')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
