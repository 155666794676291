import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import BasePage from './basePage';
import TerminalAddressRoot from '../components/TerminalAddressRoot';
import { getGTMScript } from '../components/core/ScriptTag';
import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('terminalAddress');

export default class extends Component {
    componentDidMount() {
        document.body.classList.add('terminal-address');
    }

    render() {
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: 'Terminal',
            lpType: '',
            lpVersion: '',
        });
        return (
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                    {localizationTags(`terminal-address`).map((tag) => tag)}
                    {getCanonicalTag(`terminal-address`)}
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <TerminalAddressRoot />
            </Fragment>
        );
    }
}
