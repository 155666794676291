import Validator from '../../services/validator';

export default new Validator()
    .add(
        'firstName',
        (model) => Boolean(model.firstName),
        'Please enter First Name'
    )
    .add(
        'lastName',
        (model) => Boolean(model.lastName),
        'Please enter Last Name'
    )
    .add(
        'verificationCode',
        (model) => Boolean(model.verificationCode),
        (model) =>
            `Please enter the verification code that we sent to ${model.countryCode} ${model.phoneNumber}`
    );

export const phoneValidator = new Validator()
    .add(
        'countryCode',
        (model) => Boolean(model.countryCode),
        'Please enter Country Code'
    )
    .add(
        'phoneNumber',
        (model) => Boolean(model.phoneNumber),
        'Please enter Phone Number'
    );
