import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Arrows from './Arrows';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

const base = 'careers__carousel';

export default class CarouselUI extends Component {
    state = {
        activeIndex: 0,
        changing: false,
    };

    onClickLeft = () => {
        const { name } = this.props;
        const scrollLength =
            document.getElementById(`${name}-box`).offsetWidth +
            (window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 15 : 25);
        const carousel = document.getElementById(name);
        if (this.state.activeIndex !== 0) {
            this.setState({ activeIndex: this.state.activeIndex - 1 });
        }

        carousel.scrollLeft = carousel.scrollLeft - scrollLength;
    };

    onClickRight = () => {
        const { name, content } = this.props;
        const scrollLength =
            document.getElementById(`${name}-box`).offsetWidth +
            (window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 15 : 25);
        const carousel = document.getElementById(name);
        if (this.state.activeIndex !== content.length - 1) {
            this.setState({
                activeIndex: this.state.activeIndex + 1,
                disableLeftArrow: false,
            });
        }

        carousel.scrollLeft = carousel.scrollLeft + scrollLength;
    };

    handleScroll = () => {
        const { name, getActiveIndex } = this.props;
        const carousel = document.getElementById(name);
        const scrollLength =
            document.getElementById(`${name}-box`).offsetWidth +
            (window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 15 : 25);
        const currentIndex = Math.round(carousel.scrollLeft / scrollLength);

        this.setState({ activeIndex: currentIndex });

        if (getActiveIndex) {
            getActiveIndex(currentIndex);
        }
    };

    render() {
        const {
            content,
            height,
            showStep,
            name,
            contentWidth,
            width,
            backgroundColor,
            imgWidth,
            imgHeight,
            marginBottom,
            leftAlignArrows,
            arrowLeftMargin,
            customContentClass,
            onLinkClick,
            index,
        } = this.props;
        const { disableLeftArrow, disableRightArrow } = this.state;

        return (
            <div
                className={`${base}`}
                style={{ marginBottom: marginBottom + 'px' }}
            >
                <div
                    className={`${base}__scroll-container`}
                    id={name}
                    onScroll={this.handleScroll}
                >
                    <div className={`${base}__content-container display--flex`}>
                        {content.map((c, i) => (
                            <div
                                className={`${base}__overflow-container`}
                                key={i}
                            >
                                <div
                                    className={`${base}__content-box`}
                                    id={`${name}-box`}
                                    style={{
                                        height: height + 'px',
                                        width: width + 'px',
                                        background: c.img
                                            ? `url(${c.img})`
                                            : '',
                                        backgroundSize: c.img ? 'cover' : '',
                                        backgroundColor: backgroundColor
                                            ? backgroundColor
                                            : 'white',
                                        boxShadow: backgroundColor
                                            ? ''
                                            : '0 2px 40px 0 rgba(0, 0, 0, 0.07)',
                                    }}
                                >
                                    <div
                                        className={`${base}__content ${customContentClass}`}
                                        style={{ width: contentWidth + 'px' }}
                                    >
                                        {showStep && (
                                            <div
                                                className={`${base}__step text--bolder ${
                                                    index === i ? 'active' : ''
                                                }`}
                                            >
                                                {intl.get('carousel.step')}{' '}
                                                {i + 1}
                                            </div>
                                        )}
                                        <div
                                            className={`${base}__header-container display--flex`}
                                        >
                                            {c.icon && (
                                                <div>
                                                    <svg
                                                        className={`${base}__header-icon iconmoon-icon ${c.icon}`}
                                                    >
                                                        <use
                                                            xlinkHref={`#${c.icon}`}
                                                        />
                                                    </svg>
                                                </div>
                                            )}
                                            {c.header && (
                                                <div
                                                    className={`${base}__header text--bolder`}
                                                >
                                                    {c.header}
                                                </div>
                                            )}
                                        </div>
                                        <div className={`${base}__text`}>
                                            {c.content && <p>{c.content}</p>}
                                            {c.linkText && (
                                                <p
                                                    className={` ${base}__link link--no-underline ${
                                                        c.linkColor
                                                            ? 'text--' +
                                                              c.linkColor
                                                            : 'text--primary'
                                                    } text--bolder cursor-pointer`}
                                                    onClick={() =>
                                                        onLinkClick(
                                                            c.url,
                                                            c.method
                                                        )
                                                    }
                                                >
                                                    {c.linkText}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    {c.contentImg && (
                                        <div
                                            className={`${base}__image`}
                                            style={{
                                                background: c.contentImg
                                                    ? `url(${c.contentImg})`
                                                    : '',
                                                width: imgWidth + 'px',
                                                height: imgHeight + 'px',
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ marginLeft: arrowLeftMargin + 'px' }}>
                    <Arrows
                        centerAlign={!leftAlignArrows}
                        onClickLeft={this.onClickLeft}
                        onClickRight={this.onClickRight}
                        disableLeftArrow={disableLeftArrow}
                        disableRightArrow={disableRightArrow}
                    />
                </div>
            </div>
        );
    }
}
