import React from 'react';
import { Link } from 'react-scroll';
import intl from 'react-intl-universal';
import SectionSavings from '../walletPage/SectionSavings';
import { getParam } from '../../../services';

const base = 'wallet';

export default class extends React.Component {
    render() {
        return (
            <div className={`${base}__section ${base}__section-hero container`}>
                <div className={`${base}__section-hero-gradient `}>
                    <div className={`${base}__section-hero-content `}>
                        <div>
                            <div className={`${base}__section-hero-text `}>
                                <h1
                                    className={`${base}__section-title ${base}__section-hero-title`}
                                >
                                    <p className={`${base}__header text--bold`}>
                                        {getParam('brandNameShort')}{' '}
                                        <span
                                            className={`${base}__header-alt text--bolder text--primary`}
                                        >
                                            Wallet
                                        </span>
                                    </p>
                                </h1>
                                <SectionSavings />
                                <Link
                                    activeClass="active"
                                    to={`${base}__section-packages`}
                                    smooth={true}
                                    duration={250}
                                    delay={0}
                                    ignoreCancelEvents={true}
                                    className="link--no-underline"
                                >
                                    <div
                                        className={`${base}__section-hero-cta text--center text--bolder`}
                                    >
                                        {intl.get('wallet.hero.cta')}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
