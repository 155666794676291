import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import AddressAutocomplete from '../../../core/AddressAutocomplete';
import PopupHeader from './PopupHeader';
import {
    parseLocation,
    parseEditLocation,
} from '../../../../services/geoparser';
import { SMALL_SCREEN_MEDIA } from '../../../../services/constants';
import FloatingCta from '../../../core/FloatingCta';
import { when } from 'mobx';

const { clevertap } = window;

const base = 'shopping-cart-address';

@inject('deliveryAddressStore', 'addressAutocompleteStore')
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);

        this.handleAddressSelected = this.handleAddressSelected.bind(this);
        this.handleAddressConfirmed = this.handleAddressConfirmed.bind(this);
        this.handleHideMap = this.handleHideMap.bind(this);

        this.state = {
            showMap: false,
            map: null,
            initialCenter: new window.google.maps.LatLng(
                3.1579513,
                101.7116233
            ),
        };
    }

    componentDidMount() {
        this.setState(
            {
                map: new window.google.maps.Map(
                    document.getElementById('address-map'),
                    {
                        center: this.state.initialCenter,
                        zoom: 16,
                        scrollwheel: false,
                    }
                ),
            },
            () => {
                if (this.props.deliveryAddressStore.editableAddress) {
                    this.handleEditAddress(
                        this.props.deliveryAddressStore.editableAddress
                    );
                    this.handleShowMap();
                }
            }
        );

        clevertap.event.push('Clicked on Add Delivery Address');
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.initialCenter !== this.state.initialCenter) {
            this.state.map.setCenter(this.state.initialCenter);
            this.state.map.addListener('center_changed', this.handleMoveMarker);
        }
    }

    handleMoveMarker = () => {
        const initialLocation = this.state.initialCenter;
        let distance =
            window.google.maps.geometry.spherical.computeDistanceBetween(
                this.state.initialCenter,
                this.state.map.getCenter()
            );
        let result;

        if (!this.state.proceed) {
            if (distance > 2000) {
                result = confirm(
                    'The location is far away from your address. Are you sure this is the correct entrance to your building?'
                ); //eslint-disable-line
            }
        }

        if (result === false) {
            this.setState({ proceed: false });
            this.state.map.panTo(initialLocation);
            this.state.map.setCenter(initialLocation);
            this.state.map.addListener('center_changed', this.handleMoveMarker);
        } else if (result === true) {
            this.setState({ proceed: true });
        }
    };

    componentWillUnmount() {
        this.setState({ map: null });
    }

    handleSubmitAddress = () => {
        const { deliveryAddressStore } = this.props;
        this.handleHideMap();
        if (deliveryAddressStore.editing) {
            deliveryAddressStore.setNewAddress(
                parseEditLocation(deliveryAddressStore.editableAddress)
            );
            deliveryAddressStore.editableAddress.latitude = this.state.map
                .getCenter()
                .lat();
            deliveryAddressStore.editableAddress.longitude = this.state.map
                .getCenter()
                .lng();
        } else {
            deliveryAddressStore.setNewAddress(
                parseLocation(deliveryAddressStore.place)
            );
            deliveryAddressStore.place.geometry.location =
                this.state.map.getCenter();
        }
        deliveryAddressStore.showPopup(deliveryAddressStore.POPUP_ADJUSTING);
        clevertap.event.push('Confirmed Location');
    };

    handleAddressConfirmed(place) {
        if (place) {
            const { deliveryAddressStore } = this.props;
            deliveryAddressStore.setPlace(place);
            this.handleShowMap();
        }
    }

    handleAddressSelected(prediction, cb) {
        const { deliveryAddressStore, addressAutocompleteStore } = this.props;
        addressAutocompleteStore.handleAddressSelected(prediction, cb);
        when(
            () => deliveryAddressStore.place,
            () => {
                const coords = addressAutocompleteStore.setAddressCoordinate(
                    deliveryAddressStore.place
                );
                this.setState({ initialCenter: coords });
                deliveryAddressStore.place.geometry = {};
                deliveryAddressStore.place.geometry.location = coords;
            }
        );
    }

    handleEditAddress(address) {
        const newPosition = new window.google.maps.LatLng(
            address.latitude,
            address.longitude
        );
        this.setState({ initialCenter: newPosition });
    }

    handleShowMap = () => {
        window.google.maps.event.trigger(this.state.map, 'resize');
        this.setState({ showMap: true });
        this.props.deliveryAddressStore.showMap = true;
    };

    handleHideMap = () => {
        window.google.maps.event.trigger(this.state.map, 'resize');
        this.setState({ showMap: false });
        this.props.deliveryAddressStore.showMap = false;
    };

    render() {
        const store = this.props.deliveryAddressStore;

        return (
            <div className={`${base}__popup-type-address`}>
                <div>
                    <PopupHeader
                        text={
                            store.showMap
                                ? intl.get(
                                      'shoppingCart.deliveryAddress.popup.dragPin'
                                  )
                                : intl.get(
                                      'shoppingCart.deliveryAddress.popup.searchTitle'
                                  )
                        }
                        customClasses={
                            store.showMap
                                ? 'shopping-cart-address__popup-header__extra-margin'
                                : ''
                        }
                    />
                </div>
                <div
                    className={
                        store.showMap && this.state.initialCenter
                            ? null
                            : 'hide'
                    }
                >
                    <div className={`${base}__map-container`}>
                        <div className={`${base}__map-pin`} />
                        <div id="address-map" className={`${base}__map`} />
                    </div>
                    <div className={`${base}__popup-footer`}>
                        {window.matchMedia(SMALL_SCREEN_MEDIA).matches ===
                        true ? (
                            <FloatingCta
                                centerText="Confirm location"
                                onClick={this.handleSubmitAddress}
                            />
                        ) : (
                            <button
                                className={`${base}__confirm button button--bigger-on-mobile button--bigger-on-mobile button--success`}
                                onClick={this.handleSubmitAddress}
                            >
                                Confirm location
                            </button>
                        )}
                    </div>
                </div>
                {!store.showMap && (
                    <Fragment>
                        <div
                            ref={(node) => {
                                this.placeNode = node;
                            }}
                        />
                        <div className={`${base}__popup text--left`}>
                            <p
                                className="text--bold mbm"
                                ref={(node) => {
                                    this.labelNode = node;
                                }}
                            >
                                {intl.get(
                                    'shoppingCart.deliveryAddress.popup.searchHeader'
                                )}
                            </p>
                            <div
                                className={`${base}__input-container__search display--flex flex-align width--100`}
                            >
                                <AddressAutocomplete
                                    onAddressSelected={
                                        this.handleAddressSelected
                                    }
                                    onAddressConfirmed={
                                        this.handleAddressConfirmed
                                    }
                                />
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}
