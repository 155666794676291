import React from 'react';
import { services } from '../services/index';
import { currency } from './index';
import intl from 'react-intl-universal';

const VISACARD = 'Visa Card';
const VISA = 'Visa';
const MASTERCARD = 'Mastercard';
const MASTERCARD_OMISE = 'MasterCard';
const AMEX = 'American Express';
const MAESTRO = 'Maestro';
const BILLPLZ = 'BillPlz';
const WIRE_TRANSFER = 'Wire_Transfer';
const ONLINE_BANKING = 'Online Banking';
const PAYPAL = 'Paypal';
const WALLET = 'Wallet';
const COD = 'Cash';
const VISACARD_ADYEN = 'visa';
const MASTERCARD_ADYEN = 'mc';
const COMPANY = 'Company';
const BOOST = 'Boost';
const TRUEMONEY = 'TrueMoney';

export const getCardIcon = (paymentMethod) => {
    switch (paymentMethod.card_type) {
        case VISACARD:
        case VISA:
        case VISACARD_ADYEN:
            return 'payment-provider payment-provider--visacard';
        case MASTERCARD:
        case MASTERCARD_OMISE:
        case MASTERCARD_ADYEN:
            return 'payment-provider payment-provider--mastercard';
        case AMEX:
            return 'payment-provider payment-provider--amex';
        case MAESTRO:
            return 'payment-provider payment-provider--maestro';
        case ONLINE_BANKING:
        case WIRE_TRANSFER:
        case BILLPLZ:
            return 'payment-provider payment-provider--onlinebanking';
        case PAYPAL:
            return 'payment-provider payment-provider--paypal';
        case WALLET:
            return 'payment-provider payment-provider--wallet';
        case COD:
            return 'payment-provider payment-provider--cash';
        case BOOST:
            return 'payment-provider payment-provider--boost';
        case TRUEMONEY:
            return 'payment-provider payment-provider--truemoney';
        default:
            return 'payment-provider payment-provider--unknown';
    }
};

export const getCardNumber = (paymentMethod) => {
    switch (paymentMethod.card_type) {
        case VISA:
        case VISACARD:
        case MASTERCARD:
        case AMEX:
        case VISACARD_ADYEN:
        case MASTERCARD_ADYEN:
        case MASTERCARD_OMISE:
        case MAESTRO:
            return '**** **** **** ' + paymentMethod.number;
        case BILLPLZ:
            return paymentMethod.description;
        case PAYPAL:
            return paymentMethod.email;
        case COD:
            return paymentMethod.description;
        case WALLET:
            return currency.localCurrency(paymentMethod.balance);
        case COMPANY:
            return '';
        default:
            return paymentMethod.number;
    }
};

export const getCardType = (paymentMethod) => {
    switch (paymentMethod.card_type) {
        case WALLET:
            return (
                <span>
                    {paymentMethod.title}
                    <b className="text--blue mlxs">
                        {paymentMethod.title_colored}
                    </b>
                </span>
            );
        case ONLINE_BANKING:
            return intl.get(
                `packageCheckout.payment.add.onlineBanking_${services.getParam(
                    'city'
                )}`
            );
        case COMPANY:
            return paymentMethod.title;
        default:
            return paymentMethod.card_type;
    }
};

export const getCardTypeText = (paymentMethod) => {
    if (paymentMethod.card_type === 'Mastercard') return 'MasterCard';
    if (paymentMethod.card_type === 'Visa') return 'Visa Card';
    return 'Visa Card';
};
