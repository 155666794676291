import React, { Component } from 'react';
import intl from 'react-intl-universal';

const base = 'halal-view';

export default class HalalView extends Component {
    render() {
        return (
            <div className={base}>
                <img
                    className={`${base}__img`}
                    src={'https://image.popcontent.link/halal_logo.svg'}
                    alt="halal_logo"
                />
                <p className="halal-text">
                    {intl.get('dish.halal_description')}
                </p>
            </div>
        );
    }
}
