import React from 'react';

const base = 'login-dropdown';

export default ({ children, isShowing, onClickOutside }) => {
    let baseClasses;
    if (isShowing) {
        baseClasses = 'position--relative show';
    } else if (!isShowing) {
        baseClasses = 'position--relative hide';
    }

    return (
        <div className={baseClasses}>
            <div className={`${base}__backdrop`} onClick={onClickOutside}></div>
            <div className={`${base}__triangle`}></div>
            <div className={base}>{children}</div>
        </div>
    );
};
