import React from 'react';
import { inject, observer } from 'mobx-react';

import intl from 'react-intl-universal';

const base = 'order-status-banner';

@inject('orderStatusStore')
@observer
export default class extends React.Component {
    state = {
        show: true,
    };

    componentDidMount() {
        const { orderStatusStore } = this.props;
        orderStatusStore.fetchDeliveryDetail();
    }

    handleCloseCard = () => {
        this.setState({ show: false });
    };

    render() {
        const { show } = this.state;
        const { orderStatusStore, onClick } = this.props;
        const delivery =
            orderStatusStore.deliveryInfoFetcher.data?.menu_item[0];
        const firstname = orderStatusStore.deliveryInfoFetcher.data?.firstname;

        if (show && delivery) {
            return (
                <div
                    className={`${base}__content ${base}__content--arrived ${base}__content--delivered`}
                >
                    <div
                        className={`${base}__image border--rounded`}
                        style={
                            delivery && {
                                backgroundImage: `url(${delivery.image})`,
                            }
                        }
                        onClick={onClick}
                    />
                    <div
                        className={`display--inline-block text--left text--black lh--200 fs--small-medium mlm`}
                        onClick={onClick}
                    >
                        <p className={`text--black text--bolder`}>
                            {`${intl.get(
                                'orderStatus.ratingPending.line1'
                            )} ${firstname}?`}
                        </p>
                        <p className={`fs--small lh--125`}>
                            {intl.get('orderStatus.ratingPending.line2')}
                        </p>
                        <p
                            className={`text--primary text--bold fs--small lh--125`}
                        >
                            {delivery && delivery.name}
                        </p>
                    </div>
                    <button
                        className={`${base}__close button--nothing`}
                        onClick={this.handleCloseCard}
                    >
                        <svg className={`modal__close-icon`}>
                            <use
                                xlinkHref="#svg--x-close"
                                style={{ fill: 'grey' }}
                            />
                        </svg>
                    </button>
                </div>
            );
        }

        return null;
    }
}
