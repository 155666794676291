import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { currency } from '../../../../services';

const base = 'menu-cart-confirm';

@observer
export default class extends Component {
    render() {
        const { quantity, name, price } = this.props;
        const displayPrice = currency.localCurrency(price * quantity);

        return (
            <div className="display--flex fs--small-medium">
                <div className={`${base}__quantity-col flex-none`}>
                    x{quantity}
                </div>
                <div className="flex-1-only">{name}</div>
                <div className="flex-none">{displayPrice}</div>
            </div>
        );
    }
}
