import React, { Component } from 'react';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import { getParam } from '../../../services';
import config from '../../../configs/parameters';
import CarouselUI from './CarouselUI';
import intl from 'react-intl-universal';
import { template } from 'lodash';

const base = 'careers__life-at-pop';

const facebookLink = getParam('facebookLink');
const instagramLink = getParam('instagramLink');
const linkedInLink = getParam('linkedInLink');

export default class LifeAtPop extends Component {
    renderPhotosLarge = () => {
        return (
            <div className={`${base}__photos-container`}>
                <div className={`${base}__photos`}>
                    <div className={`${base}__photos-group-1 display--flex`}>
                        <div className={`${base}__img team-pic-1`} />
                        <div className={`${base}__img team-pic-2`} />
                    </div>
                    <div className={`${base}__photos-group-2 display--flex`}>
                        <div className={`${base}__img team-pic-3`} />
                        <div>
                            <div className={`${base}__img team-pic-4`} />
                            <div
                                className={`${base}__social-sharing hide--if-small`}
                            >
                                <div
                                    className={`${base}__social-sharing--header`}
                                >
                                    <p className="text--bolder">
                                        {intl.get('careers.lifeAtPop.follow')}
                                    </p>
                                </div>
                                <div className="display--flex">
                                    <div
                                        className={`${base}__social-icon ${base}__facebook-icon cursor-pointer`}
                                        onClick={() =>
                                            this.handleClickSocialIcons(
                                                facebookLink
                                            )
                                        }
                                    />
                                    <div
                                        className={`${base}__social-icon ${base}__instagram-icon cursor-pointer`}
                                        onClick={() =>
                                            this.handleClickSocialIcons(
                                                instagramLink
                                            )
                                        }
                                    />
                                    <div
                                        className={`${base}__social-icon ${base}__linkedin-icon cursor-pointer`}
                                        onClick={() =>
                                            this.handleClickSocialIcons(
                                                linkedInLink
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderPhotosMobile = () => {
        const content = [
            {
                img: 'https://d3tjqo2jh935k9.cloudfront.net/images/static/team-pic-1.jpg',
            },
            {
                img: 'https://d3tjqo2jh935k9.cloudfront.net/images/static/team-pic-2.jpg',
            },
            {
                img: 'https://d3tjqo2jh935k9.cloudfront.net/images/static/team-pic-3.jpg',
            },
            {
                img: 'https://d3tjqo2jh935k9.cloudfront.net/images/static/team-pic-4.jpg',
            },
        ];
        return (
            <CarouselUI
                content={content}
                height={193}
                name="life-at-pop"
                marginBottom={
                    window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 65 : 90
                }
            />
        );
    };

    handleClickSocialIcons = (url) => {
        window.open(url, '_blank');
    };

    render() {
        const isMobile = window.matchMedia(SMALL_SCREEN_MEDIA).matches;
        const brandName = config.brandName;

        return (
            <div className={base}>
                <div className={`${base}__content`}>
                    <p className={`${base}__header text--bolder`}>
                        {template(intl.get('careers.lifeAtPop.header'))({
                            brandName,
                        })}
                    </p>
                    <div className={`${base}__social-sharing hide--if-large`}>
                        <div
                            className={`${base}__social-sharing--header hide--if-small`}
                        >
                            <p className="text--bolder">
                                {intl.get('careers.lifeAtPop.follow')}
                            </p>
                        </div>
                        <div className="display--flex">
                            <div
                                className={`${base}__social-icon ${base}__facebook-icon`}
                                onClick={() =>
                                    this.handleClickSocialIcons(facebookLink)
                                }
                            />
                            <div
                                className={`${base}__social-icon ${base}__instagram-icon`}
                                onClick={() =>
                                    this.handleClickSocialIcons(instagramLink)
                                }
                            />
                            <div
                                className={`${base}__social-icon ${base}__linkedin-icon`}
                                onClick={() =>
                                    this.handleClickSocialIcons(linkedInLink)
                                }
                            />
                        </div>
                    </div>
                    <p className={`${base}__sub-header`}>
                        {intl.get('careers.lifeAtPop.subHeader')}
                    </p>
                </div>
                {isMobile
                    ? this.renderPhotosMobile()
                    : this.renderPhotosLarge()}
            </div>
        );
    }
}
