import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import { when } from 'mobx';

const { clevertap } = window;

@inject('rewardsStore', 'rewardsPopupStore', 'menuCheckoutStore', 'userStore')
@observer
export default class extends React.Component {
    openRewardPopup = () => {
        const { rewardsPopupStore } = this.props;
        clevertap.event.push('Tapped on Add Promo in Cart');
        rewardsPopupStore.handleTogglePopup(true);
    };

    removeReward = () => {
        const { rewardsStore, menuCheckoutStore } = this.props;
        const code =
            menuCheckoutStore.rewardSummary &&
            menuCheckoutStore.rewardSummary.code;

        clevertap.event.push('Tapped on Use Later in Cart', {
            Code: code,
            Error: menuCheckoutStore.rewardSummary.error,
        });
        rewardsStore.removeReward();
    };

    componentDidMount() {
        const { menuCheckoutStore } = this.props;

        when(
            () =>
                menuCheckoutStore.rewardSummary &&
                menuCheckoutStore.rewardSummary.error,
            () => {
                const code =
                    menuCheckoutStore.rewardSummary &&
                    menuCheckoutStore.rewardSummary.code;

                clevertap.event.push('See Reward Error Message In Cart', {
                    Code: code,
                    Error: menuCheckoutStore.rewardSummary.error,
                });
            }
        );
    }

    render() {
        const base = 'discount-code-form';
        const { menuCheckoutStore, rewardsStore, userStore } = this.props;
        const hasReward =
            menuCheckoutStore.rewardSummary &&
            menuCheckoutStore.rewardSummary.title &&
            rewardsStore.rewardToApply();

        return userStore.isSelect ? (
            <div />
        ) : (
            <div
                className={`${base} ${hasReward ? '' : 'cursor-pointer'} ${
                    window.matchMedia(SMALL_SCREEN_MEDIA).matches ? 'well' : ''
                }`}
                onClick={() => (hasReward ? null : this.openRewardPopup())}
            >
                {hasReward ? (
                    <div style={{ marginBottom: '-8px' }}>
                        <div className={`display--flex`}>
                            <p style={{ fontSize: '14px' }}>
                                {menuCheckoutStore.rewardSummary.title}
                            </p>
                            <p
                                className={`${base}__use-later-button text--red margin--left text--bolder cursor-pointer`}
                                onClick={this.removeReward}
                            >
                                {intl.get('discountCode.removeCta1')}&nbsp;
                                {intl.get('discountCode.removeCta2')}
                            </p>
                        </div>
                        <div>
                            <p className={`${base}__error text--red`}>
                                {menuCheckoutStore.rewardSummary.error}
                            </p>
                        </div>
                    </div>
                ) : (
                    <p className="text--primary text--bolder cursor-pointer">
                        {intl.get('discountCode.addCta')}
                    </p>
                )}
            </div>
        );
    }
}
