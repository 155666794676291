import React from 'react';
import { Redirect } from 'react-router-dom';
import { GetLanguage } from './translation';
import { cities, SetCity } from './city';
import { getParam, services } from '../services';
import slugify from 'slugify';
import Cookies from 'js-cookie';
import qs from 'query-string';

let langComponent = {
    en: '/',
    th: '/th',
    ms: '/ms',
};

export const GetUrl = () => {
    return langComponent[GetLanguage()];
};

export const GetQueryParamFromUrl = (queryParam) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(queryParam);
};

export const CitySelectedCallback = (city) => {
    SetCity(city);

    if (city === cities.bangkok) {
        window.location.href = `${getParam(
            'baseUrlTh'
        )}${window.location.pathname.replace(`/${cities.bangkok}`, '')}${
            window.location.search
        }`;
    } else {
        window.location.href = `${getParam(
            'baseUrlMy'
        )}${window.location.pathname.replace(`/${cities.kl}`, '')}${
            window.location.search
        }`;
    }
};

export const HandleRedirect = () => {
    const token = services.api.AccessTokenStorage.getToken();

    if (window.location.hostname.includes('dahmakan')) {
        const baseURL = getParam('deploymentUrl');
        let redirectURL = baseURL;

        if (token) {
            redirectURL += `?auth=${token}`;
        }

        return window.location.replace(redirectURL);
    }

    if (window.location.hostname.includes('popmeals')) {
        const params = qs.parse(window.location.search);

        if (!token && params.auth) {
            Cookies.remove('has_seen_onboarding_version');
            services.api.AccessTokenStorage.setToken(params.auth);
            return (window.location.href = window.location.pathname);
        }
    }

    for (let city in cities) {
        if (window.location.pathname.includes(`/${cities[city]}`)) {
            return CitySelectedCallback(cities[city]);
        }
    }

    return <Redirect to={{ search: window.location.search }} />;
};

export const GenerateSlug = (string) => {
    if (string) {
        return slugify(string, {
            replacement: '-',
            remove: /[*+~.()'"!:@]/g,
            lower: true,
        });
    }
};

export const GetDishURL = (item) => {
    const urlSlug = GenerateSlug(item.title_bold || item.name);
    return `/menu-item/${item.id}/${urlSlug}`;
};
