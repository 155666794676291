import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import MenuItem from './MenuItem';
import { truncateDecimal } from '../../../services';
import OrderStatus from '../../core/orderStatus/OrderStatus';
import {
    autoAssignedCodeAmount,
    autoAssignedCodeType,
} from '../../../services/autoAssignDiscount';
import SliderBanner from '../../core/SliderBanner';
import PlateIcon from '../../../resources/images/svg/icon-plate.svg';

const base = 'menu-one-day';

@inject(
    'rewardsStore',
    'userStore',
    'calendarStore',
    'cartStore',
    'menuListStore',
    'menuHeaderStore'
)
@observer
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    handleAddItemToCart = (item) => {
        const { cartStore } = this.props;
        cartStore.addToCart(item);
    };

    handleGetDiscountedPrice = (item) => {
        const { userStore, cartStore, rewardsStore } = this.props;
        const userIsLoggedIn = userStore.isLoggedIn;

        const cartIsEmpty = !cartStore.numOfItems;

        // non-logged in reward
        const autoAssignedDiscount =
            autoAssignedCodeAmount && autoAssignedCodeType === 'percentage'
                ? truncateDecimal(
                      (1 - autoAssignedCodeAmount) *
                          (item.special_offer_price || item.price),
                      2,
                      false
                  )
                : autoAssignedCodeAmount
                ? truncateDecimal(
                      (item.special_offer_price || item.price) -
                          autoAssignedCodeAmount,
                      2,
                      false
                  )
                : null;

        // logged in reward
        const reward = rewardsStore.userRewards.find(
            (reward) => reward.id === rewardsStore.rewardToApply()
        );

        if (userIsLoggedIn) {
            // if user is logged in, and reward applied is percentage based, always display discounted_price
            if (
                item.discounted_price &&
                reward &&
                reward.discount_type === 'PERCENTAGE'
            ) {
                return item.discounted_price;
            }
        } else {
            // if user is not logged in, manually calculate discounted price when based on discount type
            if (autoAssignedDiscount && autoAssignedCodeType === 'percentage') {
                return autoAssignedDiscount;
            }
        }

        // when discount is absolute and not percentage based
        // if cart is empty, display discounted price if avauilable, else display special_offer_price
        if (cartIsEmpty) {
            const discountedPrice = userIsLoggedIn
                ? item.discounted_price
                : autoAssignedDiscount;
            return discountedPrice != null
                ? discountedPrice
                : item.special_offer_price;
            // else return special_offer_price
        } else {
            return item.special_offer_price;
        }
    };

    renderItemList() {
        const { menuListStore, isSmallScreen } = this.props;

        return (
            <Fragment>
                {!isSmallScreen && (
                    <Fragment>
                        <SliderBanner />
                        <OrderStatus />
                    </Fragment>
                )}
                {menuListStore.flattenedMenu.map((line, id) => {
                    // if (line.name.includes("BUY 1 FREE 1")) return <div /> // temporarily don't show buy 1 free 1 line
                    const lineName =
                        line.name &&
                        line.name.replace(/\s+/g, '-').toLowerCase();
                    const lineNameDisplay =
                        line.name && line.name.toUpperCase();
                    return (
                        <div
                            className={`${base}__line-container`}
                            id={lineName}
                            key={id}
                        >
                            <div className={`${base}__line-name`}>
                                {lineNameDisplay}
                            </div>
                            <div className={`${base}__item-container active`}>
                                {line.items &&
                                    line.items.length > 0 &&
                                    line.items.map((item, i) => {
                                        return (
                                            (item.quantity_left > 0 ||
                                                item.available_at) && (
                                                <MenuItem
                                                    key={i}
                                                    item={item}
                                                    onItemAddedToCart={() =>
                                                        this.handleAddItemToCart(
                                                            item
                                                        )
                                                    }
                                                    active={true}
                                                    discountedPrice={this.handleGetDiscountedPrice(
                                                        item
                                                    )}
                                                />
                                            )
                                        );
                                    })}
                            </div>
                        </div>
                    );
                })}
            </Fragment>
        );
    }

    handleGetNextDateMenu = () => {
        const { menuListStore } = this.props;
        menuListStore.getNextDateMenu();
        window.scrollTo(0, 0);
    };

    renderNextPageSection = () => {
        const { menuListStore } = this.props;

        if (!menuListStore.isLastDate) {
            return (
                <div
                    className={`${base}__see-tomorrow cursor-pointer`}
                    onClick={this.handleGetNextDateMenu}
                >
                    <p className={`${base}__see-tomorrow-header text--bolder`}>
                        <span>
                            That’s all for {menuListStore.activeDateDisplay}
                        </span>
                        <img
                            className={`${base}__see-tomorrow-header-icon`}
                            src={PlateIcon}
                        />
                    </p>
                    <p className={`text--bolder display--flex text--primary`}>
                        See {menuListStore.nextDateDisplay}
                        <svg className={`${base}__arrow-icon iconmoon-icon`}>
                            <use xlinkHref="#icon-chevron-thin-right" />
                        </svg>
                    </p>
                </div>
            );
        }
    };

    render() {
        const { menuObj } = this.props;
        const displayDate = menuObj && menuObj.menu && menuObj.menu.title;

        return (
            menuObj &&
            (window.innerWidth > 768 ? (
                <div className={`${base}`} id={base}>
                    {this.renderItemList()}
                    {this.renderNextPageSection()}
                </div>
            ) : (
                <div className={`${base}`} id={base}>
                    <div className={`${base}__header`}>
                        <div
                            className={`${base}__date-container`}
                            id={`date-${menuObj.date}`}
                        >
                            <p className={`${base}__date text--bolder`}>
                                {displayDate}
                            </p>
                        </div>
                    </div>
                    {this.renderItemList()}
                    {this.renderNextPageSection()}
                </div>
            ))
        );
    }
}
