import React, { Fragment } from 'react';
import { observer, Provider } from 'mobx-react';
import { services } from '../services/index';
import { Link } from '../services/types';
import BaseLayout from './core/baseLayout/Master_BaseLayout';
import ShoppingCartHeader from './core/headers/ShoppingCartHeader';
import Entry from './page/shoppingCartPage/Entry';

import rewardsStore from '../store/rewardsStore';
import PaymentStore from '../store/paymentStore';
import CreditCardStore from '../store/creditCardStore/Master_CreditCardStore';
import PaypalStore from '../store/paypalStore';
import HeaderStore from '../store/walletHeaderStore';
import loginPopupStore from '../store/loginPopupStore';
import CompleteSignUpStore from '../store/completeSignUpStore';
import menuHeaderStore from '../store/menuHeaderStore';
import menuCheckoutStore from '../store/menuCheckoutStore';
import setUpStoreInteractions from '../store/interactions/menuCheckoutInteractions';
import PromotionBar from './core/PromotionBar';
import { when } from 'mobx';

const { clevertap } = window;

const store = menuCheckoutStore;
const paymentStore = new PaymentStore();
const creditCardStore = new CreditCardStore();
const paypalStore = new PaypalStore();
const completeSignUpStore = new CompleteSignUpStore();

loginPopupStore.setBackLink(new Link('/menu', 'BACK TO MENU'));

const headerStore = new HeaderStore({ backLink: '/menu' });

setUpStoreInteractions(
    creditCardStore,
    paypalStore,
    store,
    rewardsStore,
    paymentStore,
    completeSignUpStore,
    headerStore
);

const stores = {
    paymentStore,
    creditCardStore,
    paypalStore,
    headerStore,
    store,
    completeSignUpStore,
    menuHeaderStore,
};

@observer
export default class extends React.Component {
    state = {
        hidePromotionBar: false,
    };

    componentDidMount() {
        clevertap.event.push('Cart opened');

        if (this.props.failedFpx) {
            services.bannerEvents.showError(
                'Payment not successful. Please try again.'
            );
            store.view = store.CHECKOUT_VIEW;
        }

        when(
            () =>
                store &&
                (store.view === 'ConfirmView' ||
                    store.view === 'PaymentInProgressView'),
            () => {
                this.setState({ hidePromotionBar: true });
            }
        );
    }

    renderHeader = (hide = true) => {
        return (
            <Fragment>
                <ShoppingCartHeader headerStore={headerStore} store={store} />
                <PromotionBar hide={hide} />
            </Fragment>
        );
    };

    render() {
        return (
            <BaseLayout.Page
                HeaderComponent={() =>
                    this.renderHeader(this.state.hidePromotionBar)
                }
            >
                <main className="container p0--if-small">
                    <Provider {...stores}>
                        <Entry />
                    </Provider>
                </main>
            </BaseLayout.Page>
        );
    }
}
