import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import menuHeaderStore from '../../store/menuHeaderStore';
import userStore from '../../store/userStore';
import intl from 'react-intl-universal';
import PopupUI from '../PopupUI';

class TerminalUserPopup extends Component {
    handleNavigate = () => {
        userStore.showTerminalUserPopup = false;
        this.props.history.push('/terminal');
    };

    logOutUser = () => {
        Cookies.remove('terminal_user');
        menuHeaderStore.logOut();
    };

    render() {
        return (
            <PopupUI
                titleLine1={intl.get('terminalPopup.titleLine1')}
                titleLine2={intl.get('terminalPopup.titleLine2')}
                onClickYes={this.handleNavigate}
                onClickNo={this.logOutUser}
                yesCta={intl.get('terminalPopup.cta1')}
                noCta={intl.get('terminalPopup.cta2')}
            />
        );
    }
}

export default withRouter(TerminalUserPopup);
