import React, { Component, Fragment } from 'react';
import ReferralCta from './ReferralCta';
import intl from 'react-intl-universal';
import template from 'lodash/template';
import { services } from '../../../services';
import { inject, observer } from 'mobx-react';

const base = 'section-how-it-works';

@inject('loginPopupStore')
@observer
export default class SectionHowItWorks extends Component {
    openLoginPopup = () => {
        this.props.loginPopupStore.isShowing = true;
    };

    render() {
        const { heroWidth } = this.props;
        const brandName = services.getParam('brandName');
        const howItWorks = [
            {
                title: intl.get('onboarding.sectionHowItWorks.point1.title'),
                subtitle: template(
                    intl.get(`onboarding.sectionHowItWorks.point1.subtitle`)
                )({ brandName }),
                subtitle2: intl.get(
                    'onboarding.sectionHowItWorks.point1.subtitle2'
                ),
            },
            {
                title: intl.get(`onboarding.sectionHowItWorks.point2.title`),
                subtitle: intl.get(
                    `onboarding.sectionHowItWorks.point2.subtitle`
                ),
                subtitle2: intl.get(
                    `onboarding.sectionHowItWorks.point2.subtitle2`
                ),
            },
            {
                title: intl.get(`onboarding.sectionHowItWorks.point3.title`),
                subtitle: intl.get(
                    'onboarding.sectionHowItWorks.point3.subtitle'
                ),
                subtitle2: intl.get(
                    `onboarding.sectionHowItWorks.point3.subtitle2`
                ),
            },
        ];

        return (
            <Fragment>
                <ReferralCta onClick={this.openLoginPopup} hideIfLarge={true} />
                <div
                    className={`${base} container`}
                    style={{ maxWidth: heroWidth + 120 + 'px' }}
                >
                    <div className={`${base}__title`}>
                        <h3 className="text--bolder">
                            {template(
                                intl.get(`onboarding.sectionHowItWorks.title`)
                            )({ brandName })}
                        </h3>
                    </div>
                    <div className={`${base}__content-container`}>
                        {howItWorks.map((content, i) => (
                            <div className={`${base}__content`} key={i}>
                                <p
                                    className={`${base}__content-header text--bolder`}
                                >
                                    {content.title}
                                </p>
                                <p className={`${base}__content-subheader`}>
                                    {content.subtitle}
                                </p>
                                <p className={`${base}__content-subheader`}>
                                    {content.subtitle2}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </Fragment>
        );
    }
}
