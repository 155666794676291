import React from 'react';
import { Provider, observer } from 'mobx-react';
import BillPlzHeader from './core/headers/BillPlzHeader';
import Entry from './page/billPlzProcessPage/Meal';
import BaseLayout from './core/baseLayout/Master_BaseLayout';

import WalletHeaderStore from '../store/walletHeaderStore';
import MenuBillPlzStore from '../store/menuBillPlzStore';
import setUpStoreInteractions from '../store/interactions/menuBillPlzInteraction';

@observer
export default class extends React.Component {
    constructor(props) {
        super(props);

        this.headerStore = new WalletHeaderStore({
            backLink: '/cart',
            nextLink: '/menu',
        });
        this.headerStore.setShowBackLink(false);
        this.billPlzStore = new MenuBillPlzStore(this.props.menuOrderId);

        setUpStoreInteractions(this.billPlzStore, this.headerStore);
    }

    render() {
        const Header = () => <BillPlzHeader headerStore={this.headerStore} />;

        return (
            <BaseLayout.Page HeaderComponent={Header}>
                <main className="container p0--if-small">
                    <Provider
                        headerStore={this.headerStore}
                        billPlzStore={this.billPlzStore}
                    >
                        <Entry />
                    </Provider>
                </main>
            </BaseLayout.Page>
        );
    }
}
