import React from 'react';

const base = 'addon-item';

export default function AddonItem({ item, onAddToCart, comboItem, itemsUIView }) {
    let itemPrice;

    if (comboItem) {
        if (
            item.special_offer_price &&
            item.special_offer_price > comboItem[itemsUIView == 'Addons' ? 'addons_base_price' : 'drinks_base_price']
        ) {
            itemPrice =
                '+' +
                parseFloat(
                    item.special_offer_price -
                        comboItem[itemsUIView == 'Addons' ? 'addons_base_price' : 'drinks_base_price']
                ).toFixed(2);
        } else if (
            !item.special_offer_price &&
            item.price > comboItem[itemsUIView == 'Addons' ? 'addons_base_price' : 'drinks_base_price']
        ) {
            itemPrice =
                '+' +
                parseFloat(
                    item.price - comboItem[itemsUIView == 'Addons' ? 'addons_base_price' : 'drinks_base_price']
                ).toFixed(2);
        } else {
            itemPrice = null;
        }
    }

    return (
        <div className={`${base}`} onClick={() => onAddToCart(item, true)}>
            <img className={`${base}__image`} src={item.image} alt="item image" />
            <div className={`${base}__content_card`}>
                <p className={`${base}__name`}>{item.title_bold}</p>
                <div className={`${base}__price-container`}>
                    {!comboItem ? (
                        <>
                            {item.special_offer_price ? (
                                <>
                                    <span className={`${base}__special-price`}>{item.special_offer_price}</span>
                                    <span
                                        style={{ marginLeft: '5px', textDecoration: 'line-through' }}
                                        className={`${base}__price`}
                                    >
                                        {item.price}
                                    </span>
                                </>
                            ) : (
                                <p className={`${base}__price`}>{item.price}</p>
                            )}
                        </>
                    ) : (
                        <p className={`${base}__price`}>{itemPrice}</p>
                    )}
                </div>
            </div>
        </div>
    );
}
