import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { GetQueryParamFromUrl } from '../../../services/url';
import { CircleLoader } from '../../core/Loading';

const base = 'terminal-qrcode';
@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    state = {
        backBtn: true,
        intervals: [],
        loading: false
    };

    setIntervals = (terminalMenuStore) => {
        let clearMenu = setInterval(() => {
            terminalMenuStore.cancelReaderAction();
            this.state.intervals.map((a) => {
                clearInterval(a);
            });
            let orderType = GetQueryParamFromUrl('orderType');
            terminalMenuStore.showSplashScreenActions(orderType);
        }, 180000);

        this.setState({
            intervals: [
                clearMenu
            ],
        });
    };
    componentDidMount = () => {
        const { terminalMenuStore } = this.props;
        console.log('Adding payment event listener ' + terminalMenuStore.orderNumber);
        window.addEventListener("cardPaymentResult", terminalMenuStore.handleCardPaymentResult);
        this.createCardPayment();
        this.setIntervals(terminalMenuStore);
    };

    componentWillUnmount() {
        console.log('Removing payment event listener');
        const { terminalMenuStore } = this.props;
        window.removeEventListener("cardPaymentResult", terminalMenuStore.handleCardPaymentResult);
        if (this.state.intervals) {
            this.state.intervals.map((a) => {
                clearInterval(a);
            });
            this.state.intervals = [];
        }
    }

    createCardPayment = () => {
        const { terminalMenuStore } = this.props;
        if (window.webkit && window.webkit.messageHandlers && terminalMenuStore.pendingAmount) {
            window.webkit.messageHandlers.iosListener.postMessage({
                action: 'createPaymentIntent',
                orderNumber: '' + terminalMenuStore.orderNumber,
                amount: '' + terminalMenuStore.pendingAmount
            });
        } else {
            // Return to payment selection
            terminalMenuStore.backBtn = true;
            terminalMenuStore.showingPopup = terminalMenuStore.POPUP_PAYMENT_OPTION;
        }
    };

    render() {
        const { terminalCartStore, terminalMenuStore } = this.props;
        return (
            <div className={base}>
                <div className={`${base}__title display--flex`}>
                    {this.state.backBtn ? (
                        <div
                            onClick={(e) => {
                                this.state.intervals.map((a) => {
                                    clearInterval(a);
                                });
                                terminalMenuStore.backBtn = true;
                                terminalMenuStore.cancelReaderAction();
                                terminalMenuStore.showingPopup =
                                    terminalMenuStore.POPUP_PAYMENT_OPTION;
                            }}
                        >
                            <img
                                style={{ marginLeft: 'auto' }}
                                src="/back-btn.png"
                            />
                        </div>
                    ) : null}

                    <div
                        style={{ justifyContent: 'center', width: '100%' }}
                        className="display--flex "
                    >
                        <p>{intl.get('terminal.qrPopup.tap_card_title')}</p>
                    </div>
                </div>
                <div className="display--flex">
                    <div
                        style={{ width: '50%' }}
                        className={`${base}__row-span`}
                    >
                        <div style={{ padding: '16px', height: '100%' }}>
                            <img
                                style={{ width: '100%', height: '100%' }}
                                src="/card-reader.png"
                            />
                        </div>
                    </div>
                    <div
                        style={{ width: '50%' }}
                        className={`${base}__row-span`}
                    >
                        <div style={{ padding: '16px', height: '100%', marginTop: '20%' }}>
                            <img
                                style={{ width: '100%', height: '45%' }}
                                src="/card-arrow.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
