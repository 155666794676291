import { observable, action, when } from 'mobx';
import { RESOURCE } from '../services/types';
import { services } from '../services/index';
import userStore from './userStore';
import intl from 'react-intl-universal';

const { clevertap } = window;

class WalletStatusStore {
    @observable walletStatus = RESOURCE.Initial;
    @observable smartReloadButtonBusy = false;

    @action getWalletStatus() {
        if (this.walletStatus === RESOURCE.Loading) {
            // wait for previous call to finish
            return;
        }

        this.walletStatus = RESOURCE.Loading;

        if (!userStore.hasToken) {
            this.walletStatus = RESOURCE.Success({});
            return;
        }

        services.api
            .GetWalletStatus()
            .then((status) => {
                this.walletStatus = RESOURCE.Success(status);
                if (status.migrated_from_prime) {
                    clevertap.event.push(
                        'Prime Balance Converted To Wallet Balance'
                    );
                }
            })
            .catch((response) => {
                this.walletStatus = RESOURCE.Failure(
                    response.message,
                    response
                );
            });
    }

    @action changeAutoTopup(enable) {
        this.smartReloadButtonBusy = true;

        services.api
            .SetAutoTopup(enable)
            .then((response) => {
                this.smartReloadButtonBusy = false;
                this.getWalletStatus();
                enable
                    ? clevertap.event.push('Easy Reload enabled')
                    : clevertap.event.push('Easy Reload disabled');
            })
            .catch((response) => {
                services.bannerEvents.showError(
                    intl.get('walletStatusInDropDown.smartReload.error')
                );
                this.smartReloadButtonBusy = false;
            });
    }
}

const store = new WalletStatusStore();
export default store;
