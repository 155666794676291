import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import EmptyCart from './EmptyCartCartPreview';

import MealRowCartPreview from './MealRowCartPreview';

import intl from 'react-intl-universal';

import MealRowCartSummary from './MealRowCartSummary';

const base = 'cart-preview';

@inject('cartStore', 'menuListStore')
@observer
class CartPreview extends React.Component {
    renderOrders() {
        const { cartStore } = this.props;

        const cart = cartStore.cart;
        return (
            cart &&
            cart.map((dateOrder) => (
                <MealRowCartPreview
                    key={dateOrder.date}
                    date={dateOrder.date}
                    items={dateOrder.menu_item}
                />
            ))
        );
    }

    render() {
        const { cartStore, onClick } = this.props;
        const isEmptyCart = !cartStore.cart.length;

        return (
            <div className={base}>
                {this.renderOrders()}
                {!isEmptyCart && <MealRowCartSummary />}
                {isEmptyCart ? (
                    <EmptyCart />
                ) : (
                    <a onClick={() => onClick()}>
                        <button
                            type="button"
                            className={`${base}__add-to-cart button button--success width--100`}
                            id="go-to-cart-btn"
                        >
                            {intl.get('cartPreview.goToCart')}
                        </button>
                    </a>
                )}
            </div>
        );
    }
}

export default withRouter(CartPreview);
