import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import PaymentMethodType from '../../core/PaymentMethodType';

@inject('profilePaymentMethodStore')
@inject('paypalStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.paypalStore.initPaypal();
    }

    componentWillUnmount() {
        this.props.paypalStore.reset();
    }

    renderPaypalButton() {
        const { paypalStore } = this.props;

        if (paypalStore.isBusy) {
            return (
                <li className="mts mbm">
                    <button className="button button--busy add-payment-method__button">
                        <span className="pts pbs">
                            {intl.get('profile.payment.paypalInit')}
                        </span>
                    </button>
                </li>
            );
        }

        return (
            <PaymentMethodType
                onClick={::paypalStore.doAddPaypal}
                text={intl.get('packageCheckout.payment.add.paypal')}
                paymentProviderIcon="payment-provider payment-provider--paypal"
            />
        );
    }

    render() {
        const { profilePaymentMethodStore } = this.props;

        return (
            <div>
                <div className="display--flex flex-align-items-center">
                    <div className="flex-1">
                        <p className="text--bold fs--small-medium">
                            {intl.get('packageCheckout.payment.add.title')}
                        </p>
                    </div>
                    <div>
                        <button
                            className="button button--small width--100px"
                            onClick={::profilePaymentMethodStore.setView(
                                profilePaymentMethodStore.SHOW_VIEW
                            )}
                        >
                            {intl.get('profile.payment.cancel')}
                        </button>
                    </div>
                </div>
                <ul>
                    <PaymentMethodType
                        onClick={::profilePaymentMethodStore.setView(
                            profilePaymentMethodStore.CREDIT_CARD_VIEW
                        )}
                        text={intl.get(
                            'packageCheckout.payment.add.creditcard'
                        )}
                        paymentProviderIcon="payment-provider payment-provider--unknown"
                    />
                    {this.renderPaypalButton()}
                </ul>
            </div>
        );
    }
}
