import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

const base = 'login-popup';

@inject('loginPopupStore')
@observer
export default class SubheaderUI extends Component {
    render() {
        const { text, customWidthPercentage, customMarginBottom } = this.props;
        const width = customWidthPercentage ? customWidthPercentage : '100%';
        const marginBottom = customMarginBottom ? customMarginBottom : 45;

        return (
            <div
                className={`${base}__subheader-container`}
                style={{ width, marginBottom: marginBottom + 'px' }}
            >
                <p className={`${base}__subheader`}>{text}</p>
            </div>
        );
    }
}
