import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { template } from 'lodash';
import { MAYBE } from '../../../services/types';
import config from '../../../configs/parameters';

const base = 'rating-popup';
const range = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

@inject('orderRatingStore')
@observer
export default class extends React.Component {
    render() {
        const { orderRatingStore } = this.props;
        const brandName = config.brandName;

        if (orderRatingStore.order === MAYBE.None) {
            return <div />;
        }

        return (
            <div className={base}>
                <div
                    className={`${base}__header text--bolder text--white text--center`}
                >
                    {intl.get('rating.order')}
                    {orderRatingStore.order.data.order_id}
                </div>
                <div className={`${base}__body ${base}__body--nps`}>
                    <p className={`${base}__title text--bolder mtl mbm`}>
                        {intl.get('rating.npsRating.title')}
                    </p>
                    <div className="separator separator--green"></div>
                    <p className="mtm mbl">
                        {template(intl.get('rating.npsRating.recommendation'))({
                            brandName,
                        })}
                    </p>
                    <div
                        className={`${base}__nps-container display--flex clearfix`}
                    >
                        {range.map((i) => {
                            return (
                                <span
                                    key={i}
                                    className={`${base}__nps-box flex-1-only`}
                                    onClick={orderRatingStore.setNPSRating.bind(
                                        orderRatingStore,
                                        i
                                    )}
                                >
                                    {i}
                                </span>
                            );
                        })}
                    </div>
                    <div className={`${base}__nps-container mts clearfix`}>
                        <span className="float-left text--gray fs--small">
                            {intl.get('rating.npsRating.notLikely')}
                        </span>
                        <span className="float-right text--gray fs--small">
                            {intl.get('rating.npsRating.extremelyLikely')}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
