import { observable, action, computed } from 'mobx';
import { reaction, when } from 'mobx';
import once from 'lodash/once';
import { RESOURCE } from '../services/types';
import { SMALL_SCREEN_MEDIA, MEDIUM_SCREEN_MEDIA } from '../services/constants';
import Cookies from 'js-cookie';

import { services } from '../services/index';

import { cities, GetCity } from '../services/city';
import { clearCompanyFromLocalStorage } from '../services/company';
import { clearAutoAssignDiscountCampaign } from '../services/autoAssignDiscount';
import { removeRewardToApply } from '../services/rewards';

const { clevertap } = window;

class MenuHeaderStore {
    @observable.ref user = RESOURCE.Initial;
    @observable showAppDownloadBanner = false;
    @observable calendarHeight = null;
    @observable headerHeight = null;
    @observable walletBannerHeight = null;
    @observable promotionBarHeight = null;
    @observable addressBarHeight = null;
    @observable dataToStore = {}; // careful: not reactive because keys have to be defined for that.
    @observable branchBannerHeight = null;
    @observable showHalal = false;

    @computed get marginTop() {
        return (
            this.walletBannerHeight +
            this.headerHeight +
            this.calendarHeight +
            this.promotionBarHeight +
            this.addressBarHeight
        );
    }

    constructor() {
        reaction(
            () => this.showAppDownloadBanner,
            (showAppDownloadBanner) => {
                this.branchBannerHeight = showAppDownloadBanner ? 100 : 0;
            }
        );
    }

    @action
    setUser = (user) => {
        this.user = user;
    };

    @action
    setCalendarHeight = (height) => {
        if (!this.calendarHeight) {
            this.calendarHeight = height;
        }
    };

    @action
    setAddressBarHeight = (height) => {
        if (!this.addressBarHeight) {
            this.addressBarHeight = height;
        }
    };

    @action
    setHeaderHeight = (height) => {
        if (!this.headerHeight) {
            this.headerHeight = height;
        }
    };

    @action
    setwalletBannerHeight = (height) => {
        if (!this.walletBannerHeight) {
            this.walletBannerHeight = height;
        }
    };

    @action
    setPromotionBarHeight = (height) => {
        if (!this.promotionBarHeight) {
            this.promotionBarHeight = height;
        }
    };

    @action setShowAppDownloadBanner(showAppDownloadBanner, setCookie = true) {
        this.showAppDownloadBanner = showAppDownloadBanner;
        const hasAutoSignIn = RESOURCE.Success.is(this.user);
        if (showAppDownloadBanner) {
            if (setCookie) {
                clevertap.event.push('See Custom App Banner', {
                    'Auto Sign In Available': hasAutoSignIn,
                });
            }
        } else {
            if (setCookie) {
                Cookies.set('hide_get_app_banner', true, { expires: 7 });
                Cookies.set('clicked_get_app_banner', true);
                clevertap.event.push('Clicked On Custom App Banner', {
                    'Auto Sign In Available': hasAutoSignIn,
                });
            }
        }
    }

    @action
    logOut(city) {
        if (!city) {
            services.api.AccessTokenStorage.clearToken();
        }
        services.api.UserCart.clear();
        services.api.UserAddressSettings.clear();

        clearCompanyFromLocalStorage();
        clearAutoAssignDiscountCampaign();
        removeRewardToApply();

        setTimeout(() => {
            if (window.location.href.includes('terminal')) {
                window.location.href = '/terminal-login';
            } else {
                window.location.href = '/';
            }
            clevertap.event.push('User logged out');
            clevertap.logout();
        }, 1000);
    }
}

const store = new MenuHeaderStore();
export default store;
