import { action, computed, observable, when } from 'mobx';
import get from 'lodash/get';
import Bacon from 'baconjs';
import Notifiable from './services/notifiable';
import branchBannerStore from '../store/branchBannerStore';

import { services } from '../services/index';
import userStore from './userStore';

import DeliveryInfoServices from './services/deliveryInfoServices';
import { DELIVERY_STATUS, RESOURCE } from '../services/types';
import { fromTimestamp } from '../services/dateTime';
import {
    DELIVERY_STATUS_ARRIVED,
    DELIVERY_STATUS_DELIVERED,
    DELIVERY_STATUS_INTRANSIT,
    DELIVERY_STATUS_PENDING,
} from '../services/constants';

const { clevertap } = window;
const INTERVAL = 15 * 1000;

class OrderStatusStore extends Notifiable {
    @observable message = '';
    @observable isShowingDetail = false;
    @observable order_id = null;
    @observable.ref deliveryStatus = DELIVERY_STATUS.Empty;
    @observable.ref statusFetcher = RESOURCE.Initial;
    @observable.ref deliveryInfoFetcher = RESOURCE.Initial;
    @observable.ref userFetcher = RESOURCE.Initial;
    @observable isShowingPopup = false;
    @observable cancellingOrder = RESOURCE.Initial;
    @observable isShowingChangeAddress = false;
    @observable isShowingChangeTimeframe = false;
    @observable addressesFetcher = RESOURCE.Initial;
    @observable timeframesFetcher = RESOURCE.Initial;

    onAddressOrTimeframeChanged$ = new Bacon.Bus();

    @observable showFreeMealBanner = false;

    @computed get deliveryId() {
        return get(this.deliveryStatus, ['data', 'id']);
    }
    @computed get orderId() {
        return get(this.deliveryStatus, ['data', 'order_id']);
    }
    @computed get menuType() {
        return get(
            this.deliveryStatus,
            ['data', 'menu_type'],
            ''
        ).toLowerCase();
    }
    @computed get timeSlotStartUTC() {
        return get(this.deliveryStatus, ['data', 'time_slot_start']);
    }
    @computed get timeSlotEndUTC() {
        return get(this.deliveryStatus, ['data', 'time_slot_end']);
    }
    @computed get orderStatus() {
        return get(this.deliveryStatus, ['data', 'status']);
    }
    @computed get eta() {
        return get(this.deliveryStatus, ['data', 'eta']);
    }
    @computed get tta() {
        return get(this.deliveryStatus, ['data', 'time_to_arrival_in_minutes']);
    }
    @computed get username() {
        return get(this.userFetcher, ['data', 'username'], '');
    }
    @computed get deliveryInstruction() {
        return get(
            this.deliveryInfoFetcher,
            ['data', 'address', 'delivery_instruction'],
            ''
        );
    }
    @computed get changeAllowed() {
        return get(this.deliveryInfoFetcher, ['data', 'change_allowed']);
    }
    @computed get riderName() {
        return get(this.deliveryInfoFetcher, ['data', 'rider_name']);
    }

    constructor() {
        super();
        this.setUpNotification();
    }

    @computed get deliveryInfoServices() {
        return new DeliveryInfoServices(this.deliveryInfoFetcher.data);
    }

    @action queryForStatus() {
        when(
            () => userStore.isLoggedIn,
            () => {
                if (!this.statusFetcher.data) {
                    this.statusFetcher = RESOURCE.Loading;
                }

                services.api
                    .GetDeliveryStatus()
                    .then((response) => {
                        const order = get(response, ['list', '0']);
                        if (order) {
                            const status = get(order, ['status']);
                            const delayed = get(order, ['delayed']);
                            branchBannerStore.orderStatusBannerOpen = true;

                            if (status === DELIVERY_STATUS_PENDING) {
                                this.deliveryStatus = delayed
                                    ? DELIVERY_STATUS.Delayed(order)
                                    : DELIVERY_STATUS.Pending(order);
                            } else if (status === DELIVERY_STATUS_INTRANSIT) {
                                this.deliveryStatus = delayed
                                    ? DELIVERY_STATUS.Delayed(order)
                                    : DELIVERY_STATUS.Delivering(order);
                            } else if (status === DELIVERY_STATUS_ARRIVED) {
                                this.deliveryStatus =
                                    DELIVERY_STATUS.Arrived(order);
                            } else if (status === DELIVERY_STATUS_DELIVERED) {
                                this.deliveryStatus =
                                    DELIVERY_STATUS.Delivered(order);
                                this.handleClearInterval();
                            }
                        }
                        // no order in progress, so we check if we should show the free meal banner. also reset the orderstatus
                        else {
                            this.deliveryStatus = DELIVERY_STATUS.Empty;
                            this.freeMealBanner();
                            this.handleClearInterval();
                            branchBannerStore.orderStatusBannerOpen = false;
                        }
                        this.statusFetcher = RESOURCE.Success(response);
                    })
                    .catch((response) => {
                        this.statusFetcher = RESOURCE.Failure(
                            response.message,
                            response
                        );
                        this.handleClearInterval();
                        branchBannerStore.orderStatusBannerOpen = false;
                    });
            }
        );
    }

    @action freeMealBanner() {
        if (
            services.getParam('brand') === 'dahmakan' ||
            services.getParam('brand') === 'Polpa'
        ) {
            services.api.GetUserWithMemoizer.run().then((user) => {
                this.userFetcher = RESOURCE.Success(user);
                if (this.userFetcher.data.marketing_campaign != null) {
                    services.api.GetCredits().then((response) => {
                        if (
                            response.total_rm >
                            services.getParam('freeMealMinimum')
                        ) {
                            this.showFreeMealBanner = true;
                        }
                    });
                }
            });
        } else {
            services.api.GetCredits().then((response) => {
                if (response.total_rm > services.getParam('freeMealMinimum')) {
                    this.showFreeMealBanner = true;
                }
            });
        }
    }

    @action fetchDeliveryDetail(id) {
        this.deliveryInfoFetcher = RESOURCE.Loading;
        const deliveryId = id || this.order_id || this.deliveryId;
        services.api
            .GetDeliveryDetail({ id: deliveryId })
            .then((response) => {
                this.deliveryInfoFetcher = RESOURCE.Success(response);
            })
            .catch(() => {
                this.deliveryInfoFetcher = RESOURCE.Initial;
            });

        services.api.GetUserWithMemoizer.run().then((user) => {
            this.userFetcher = RESOURCE.Success(user);
        });
    }

    @action setShowingDetail = (isShowingDetail) => {
        return () => {
            this.isShowingDetail = isShowingDetail;
            this.isShowingChangeAddress = false;
            this.isShowingChangeTimeframe = false;
        };
    };

    @action setShowPopup(setShowPopup) {
        return () => {
            this.isShowingPopup = setShowPopup;
        };
    }

    @action setShowChangeAddress(setShowAddress) {
        return () => {
            this.isShowingChangeAddress = setShowAddress;
        };
    }

    @action setShowChangeTimeframe(setShowTimeframe) {
        return () => {
            this.isShowingChangeTimeframe = setShowTimeframe;
        };
    }

    @action setOrderId(orderId) {
        return () => {
            this.order_id = orderId;
        };
    }

    @action updateAddress = (address_id) => {
        const requestBody = {
            id: this.order_id || this.deliveryId,
            address_id: address_id,
        };

        services.api
            .UpdateDelivery(requestBody)
            .then(() => {
                this.isShowingChangeAddress = false;
                this.fetchDeliveryDetail(this.order_id);
                this.onAddressOrTimeframeChanged$.push();
            })
            .catch((response) => {
                services.bannerEvents.showWarning(
                    response.message
                );
            });
    };

    @action updateTimeframe(timeframe) {
        const requestBody = {
            id: this.order_id || this.deliveryId,
            timeframe: timeframe.display_text,
        };

        services.api
            .UpdateDelivery(requestBody)
            .then(() => {
                this.isShowingChangeTimeframe = false;
                this.fetchDeliveryDetail(this.order_id);
                this.onAddressOrTimeframeChanged$.push();
            })
            .catch((response) => {
                services.bannerEvents.showWarning(
                    'Sorry, something went wrong. Please go back or try again.'
                );
            });
    }

    @action fetchAddresses() {
        this.addressesFetcher = RESOURCE.Loading;

        services.api.GetAddresses.runFresh()
            .then((addresses) => {
                this.addressesFetcher = RESOURCE.Success(addresses);
            })
            .catch((response) => {
                this.addressesFetcher = RESOURCE.Failure(
                    response.message,
                    response
                );
            });
    }

    @action fetchTimeframes() {
        this.timeframesFetcher = RESOURCE.Loading;
        const requestPayload = {
            date: fromTimestamp(
                this.deliveryInfoFetcher.data['time_slot_start']
            ).toString('dd-MM-yyyy'),
            menu_item: this.deliveryInfoFetcher.data.menu_item,
            menu_type: this.deliveryInfoFetcher.data.menu_type,
        };
        services.api
            .GetTimeslotsByItems(requestPayload)
            .then((response) => {
                this.timeframesFetcher = RESOURCE.Success(response.time_slots);
            })
            .catch((response) => {
                this.timeframesFetcher = RESOURCE.Failure(
                    response.message,
                    response
                );
            });
    }

    @action cancelOrder() {
        this.cancellingOrder = RESOURCE.Loading;

        const id = this.order_id || this.deliveryId;

        services.api
            .CancelOrder({ id: id })
            .then((response) => {
                this.cancellingOrder = RESOURCE.Success;
                this.message = response.message;
                clevertap.event.push('Cancel Order');
            })
            .catch((response) => {
                this.cancellingOrder = RESOURCE.Failure;
                this.message = response.message;
            })
            .then(() => {
                this.isShowingPopup = false;
                this.fetchDeliveryDetail(this.order_id);
            })
            .then(() => window.location.reload());
    }

    @action resetOrderStatusBanner() {
        this.isShowingDetail = false;
        this.deliveryStatus = DELIVERY_STATUS.Empty;
        this.statusFetcher = RESOURCE.Initial;
        this.deliveryInfoFetcher = RESOURCE.Initial;
    }

    @action startPolling = () => {
        if (!this.interval) {
            if (document.visibilityState === 'hidden') {
                this.handleClearInterval();
            } else {
                this.queryForStatus();
                this.interval = window.setInterval(
                    ::this.queryForStatus,
                    INTERVAL
                );
            }
        }
    };

    handleClearInterval = () => {
        window.clearInterval(this.interval);
        this.interval = null;
    };
}

const store = new OrderStatusStore();
export default store;
