import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import brandLogo from '../brandLogo/MasterBrandLogo';
import intl from 'react-intl-universal';

const base = 'shopping-cart-header';

@inject('headerStore')
@inject('cartViewStore')
@inject('store')
@observer
class ShoppingCartHeader extends Component {
    renderBackLink() {
        const { headerStore, store, cartViewStore } = this.props;
        const { backLink, showBackLink } = headerStore;

        if (
            store.view === store.CHECKOUT_VIEW &&
            cartViewStore.view === cartViewStore.STEP_2 &&
            showBackLink
        ) {
            return (
                <a
                    onClick={() => cartViewStore.setView(cartViewStore.STEP_1)}
                    className="link--no-underline text--black fs--small-medium mrl text--bold cursor-pointer"
                >
                    <svg
                        className={`${base}__back-link-icon iconmoon-icon iconmoon-icon--middle mrs`}
                    >
                        <use xlinkHref="#icon-chevron-thin-left" />
                    </svg>
                    <span className={`${base}__back-link-text`}>
                        {intl.get('headers.shoppingCartHeader.backToCart')}
                    </span>
                </a>
            );
        } else if (
            store.view === store.CONFIRM_VIEW &&
            cartViewStore.view === cartViewStore.STEP_2 &&
            showBackLink
        ) {
            return (
                <a
                    href={backLink}
                    className="link--no-underline text--black fs--small-medium mrl text--bold"
                >
                    <svg
                        className={`${base}__back-link-icon iconmoon-icon iconmoon-icon--middle mrs`}
                    >
                        <use xlinkHref="#icon-chevron-thin-left" />
                    </svg>
                    <span className={`${base}__back-link-text`}>
                        {intl.get('headers.shoppingCartHeader.backToMenu')}
                    </span>
                </a>
            );
        } else if (showBackLink) {
            return (
                <Link
                    to={backLink}
                    className="link--no-underline text--black fs--small-medium mrl text--bold"
                >
                    <svg
                        className={`${base}__back-link-icon iconmoon-icon iconmoon-icon--middle mrs`}
                    >
                        <use xlinkHref="#icon-chevron-thin-left" />
                    </svg>
                    <span className={`${base}__back-link-text`}>
                        {intl.get('headers.shoppingCartHeader.backToMenu')}
                    </span>
                </Link>
            );
        }

        return null;
    }

    render() {
        return (
            <div className={base}>
                <div className="display--flex flex-align-items-center container">
                    <div className="flex-1-only">{this.renderBackLink()}</div>
                    <div className="flex-none ma">
                        <a href="/menu">
                            <brandLogo.PrimaryLogo
                                className={`menu-header__onboarding-logo`}
                            />
                        </a>
                    </div>
                    <div className="flex-1-only" />
                </div>
            </div>
        );
    }
}

export default ShoppingCartHeader;
