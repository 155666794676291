// App Config for staging profile
export default {
    // the brand name we display
    brandName: 'Pop Meals',
    brandNameShort: 'Pop',

    // Hostnames
    hostnameMy: 'staging-web.popmeals.com.my',
    hostnameTh: 'staging-web.popmeals.co.th',

    // Base URL
    baseUrlMy: 'https://staging-web.popmeals.com.my',
    baseUrlTh: 'https://staging-web.popmeals.co.th',

    deploymentUrl: 'https://staging-web.popmeals.co.th',

    // Hostname for API endpoints
    services: 'https://staging-api.dahmakan.com',
    basicAuth: 'UkVGSVRVRkw6UVU0NlJrOVBSQQ==',

    // Current onBoarding version.
    // When the user visits onboarding page, they will be marked as "has seen onboarding version x"
    // The next time they visit, if the version matches, they will be redirected to order.rockchop.my
    // This feature can be turn off by setting willRedirectOnboarding to false
    willRedirectOnboarding: true,
    onboardingVersion: '1.0',

    // Default city
    defaultCity: 'Bangkok',

    //brand & city for http headers
    brand: 'popmeals',
    city: 'Bangkok',

    //country for place autocomplete suggestions
    country: 'th',
    currency: 'THB',
    currencyCode: 'THB',

    //Universal download link
    appDownloadPath: 'https://app.dahmakan.com/install-staging',

    //payment Provider
    paymentGateway: 'omise',

    omiseKey: 'pkey_test_5dwrvkd0ck98ezjwggs',

    // AppStore download link
    appStorePath: 'https://app.popmeals.com.my/download-popmeals-for-ios',

    // GooglePlay download link
    googlePlayPath: 'https://app.popmeals.com.my/download-popmeals-for-android',

    // Huawei App Gallery download link
    huaweiAppGalleryPath:
        'https://app.popmeals.com.my/download-popmeals-huawei-app-gallery',

    facebookLink: 'https://www.facebook.com/popmeals_th',
    facebookPixel: '793312697401487',
    GTMid: 'GTM-PLCJXV2',
    twitterLink: 'www.twitter.com/popmeals_th',
    instagramLink: 'https://instagram.com/popmeals_th',
    linkedInLink: 'https://www.linkedin.com/company/dah-makan',

    facebookAppId: '865803290168313',
    googleClientId:
        '743060368799-44tq1b72ha96ek6gg1fp4oh8mgku796l.apps.googleusercontent.com',
    googleMapKey: 'AIzaSyBNPySbOaJG-PxNJ1YwuPfsIL5wRLKqrAc',

    fpxRedirectUrlMenu: 'https://' + window.location.host + '/bangkok/purchase',
    fpxRedirectUrl:
        'https://' + window.location.host + '/bangkok/wallet/purchase',

    branchioKey: 'key_test_gbf0v6iRRBBcT61Trw1qChnbzulTvd7q',
    mixpanelKey: '60c113fb03808cab839d69314c0aaad7',

    ringCaptchaAppId: 'y3i6e8o4e2yhy6agyfif',
    ringCaptchaAPIKey: 'b2298beed2bc061ceacb720119a81460ae56d54a',

    freshDeskHc: 'https://help.popmeals.com.my',
    freshDeskSupportUrl:
        'https://us-central1-dahmakan-64232.cloudfunctions.net/createFreshdeskTicket',

    addonsLaunched: true,

    signupEventName: 'open',
    purchaseEventName: 'order_placed',

    freeMealMinimum: 219,
    lowestMealCost: 219,

    showGoogleMapsReviewPrompt: true,
    googleMapsReviewUrl:
        'https://search.google.com/local/writereview?placeid=ChIJq6o6rcOe4jARDCdb250j9WE',

    kitchenLocation: { lat: 13.7487136, lng: 100.5470629 },
    showChallenge: false,

    menuHeaderHeight: '149px',

    stripeAPIKey: 'pk_test_WC6qcitLHtpfZh1ZiEqzpWBc',
};
