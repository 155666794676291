import React from 'react';

const SummaryItem = (props) => {
    const bold = props.bold ? 'text--bolder' : null;
    const color = props.color === 'GREEN' ? 'text--primary' : null;
    return (
        <div className={`${bold} ${color}`}>
            <span>{props.title}</span>
            <span className={`${props.base}__invoices-col`}>{props.value}</span>
        </div>
    );
};

export default SummaryItem;
