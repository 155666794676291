import React from 'react';
import cx from 'classnames';
import intl from 'react-intl-universal';
import { inject, observer } from 'mobx-react';

import { RESOURCE } from '../../../services/types';

const base = 'profile';

@inject('profilePasswordStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.profilePasswordStore.fetchUserInfo();
    }

    renderPasswordFields() {
        const store = this.props.profilePasswordStore;
        const { validationResult, willShowOldPassword } = store;
        const updateModel = ::store.updateModel;

        const oldPasswordField = (
            <div className="col-lg-6 col-md-6 col-sm-6 mbm col-reduced-gutter--right normal-gutter--if-small">
                <label htmlFor="oldPassword">
                    {intl.get('profile.password.oldPassword')}
                </label>
                <input
                    type="password"
                    id="oldPassword"
                    name="oldPassword"
                    placeholder={intl.get('profile.password.oldPassword')}
                    value={store.oldPassword}
                    onChange={updateModel}
                    className={
                        'input width--100 ' +
                        validationResult.getInputErrorClassOf('oldPassword')
                    }
                />
            </div>
        );
        const newPasswordField = (
            <div>
                <label htmlFor="newPassword">
                    {intl.get('profile.password.newPassword')}
                </label>
                <input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    placeholder={intl.get('profile.password.newPassword')}
                    value={store.newPassword}
                    onChange={updateModel}
                    className={
                        'input width--100 ' +
                        validationResult.getInputErrorClassOf('newPassword')
                    }
                />
            </div>
        );
        if (willShowOldPassword) {
            return (
                <div>
                    {oldPasswordField}
                    <div className="col-lg-6 col-md-6 col-sm-6 mbm col-reduced-gutter--left normal-gutter--if-small">
                        {newPasswordField}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-lg-12 col-md-12 col-sm-12 mbm">
                    {newPasswordField}
                </div>
            );
        }
    }

    render() {
        const store = this.props.profilePasswordStore;
        const buttonClasses = cx(
            `button ${base}__button button--success width--100`,
            {
                'button--busy':
                    this.props.profilePasswordStore.passwordUpdater ===
                    RESOURCE.Loading,
            }
        );

        return (
            <div className={`${base}__box`}>
                <div className={`${base}__box-header`}>
                    {intl.get('profile.password.header')}
                </div>
                <div className={`${base}__box-body`}>
                    <center>
                        <form
                            onSubmit={::store.updatePassword}
                            style={{ textAlign: 'left' }}
                        >
                            <div className="row mbm">
                                {this.renderPasswordFields()}
                            </div>
                            <p className="text--red text--left fs--small-medium display--none-if-empty mbm">
                                {store.validationResult.getFirstError()}
                            </p>
                            <center>
                                <button className={buttonClasses}>
                                    {intl.get('profile.password.cta')}
                                </button>
                            </center>
                        </form>
                    </center>
                </div>
            </div>
        );
    }
}
