import React from 'react';
import { inject, observer } from 'mobx-react';
import _, { add } from 'lodash';
const base = 'terminal-menu-meals-selection';
const mediumItem = 'medium-item'
import { withRouter } from 'react-router-dom';
import Modal from '../../core/Modal';
import PopupResetKiosk from './PopupResetKiosk';
@inject('terminalMenuStore', 'terminalCartStore')
@observer
class MealSelection extends React.Component {
    state = {
        toggle: '',
        cartItem: [],
        addCart: false,
        totalSaving: '',
        totalPrice: '',
        extrasCart: [],
        drinksCart: [],
        edit: false,
        snacksCart: [],
        noCart: { extras: false, drinks: false, snacks: false },
        id: '',
        currentSection: 'extras'
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const menuStore = this.props.terminalMenuStore;

        const idIndex = urlParams.get('id');
        const edit = urlParams.get('edit');

        this.setState({ id: idIndex, edit: edit ? true : false, toggle: 'extras', totalPrice: edit ? menuStore.totalMenuList[idIndex].totalPrice : 0 })

        if (menuStore.totalMenuList[idIndex]) {
            let cartObject = {
                extras: menuStore.totalMenuList[idIndex].extras.length > 0 ? false : true,
                drinks: menuStore.totalMenuList[idIndex].drinks.length > 0 ? false : true,
                snacks: menuStore.totalMenuList[idIndex].snacks.length > 0 ? false : true,
            }

            this.calculateTotalPrice(menuStore.totalMenuList[idIndex].drinks, menuStore.totalMenuList[idIndex].extras, menuStore.totalMenuList[idIndex].snacks);
            this.setState({ extrasCart: menuStore.totalMenuList[idIndex].extras, drinksCart: menuStore.totalMenuList[idIndex].drinks, snacksCart: menuStore.totalMenuList[idIndex].snacks, noCart: cartObject, totalPrice: menuStore.totalMenuList[idIndex].totalPrice });

        }

        console.log("Adding card status listener");
        window.addEventListener("cardReaderStatusUpdate", menuStore.handleCardReaderStatusUpdate);
    }

    componentWillUnmount() {
        const { terminalMenuStore } = this.props;
        console.log("Removing card status listener");
        window.removeEventListener("cardReaderStatusUpdate", terminalMenuStore.handleCardReaderStatusUpdate);
    }

    handleClosePopup = () => {
        this.props.terminalMenuStore.setShowPopup('');
    };

    cancelItem = () => {
        const menuStore = this.props.terminalMenuStore.totalMenuList;
        const cartStore = this.props.terminalCartStore;

        delete menuStore[this.state.id];
        this.props.terminalMenuStore.totalMenuList = menuStore;

        this.props.terminalCartStore.itemsAdded = cartStore.itemsAdded.filter((o) => {
            return o.id != this.state.id

        })
        history.back();
    }

    addCart = () => {
        const cartStore = this.props.terminalCartStore;
        let addCart = true;
        this.setState({ addCart: addCart });

        const classList = document.getElementsByClassName("error-tag");

        for (var i = 0; i < classList.length; i++) {
            const className = classList[i];
            className.classList.toggle("box");
            setTimeout(() => {
                className.classList.remove('box');
            }, 1000);
        }

        if (!(this.state.snacksCart.length > 0 || this.state.noCart.snacks)) {
            this.selectNoMeal('snacks');
        }

        if ((this.state.drinksCart.length > 0 || this.state.noCart.drinks) && (this.state.extrasCart.length > 0 || this.state.noCart.extras)) {
            cartStore.addMealCart({ id: this.state.id, add: this.state.edit ? false : true });
            cartStore.getTotalPrice();
            cartStore.updateInCartOrder();
            history.back();
        }
    }

    calculateTotalPrice = (drinkCart, extraCart, snackCart) => {
        let extraSave = 0;
        let drinksSave = 0;
        let snacksSave = 0;

        drinkCart.map((o) => {
            if (o.value.value_meal_price) {
                drinksSave += (o.value.price - o.value.value_meal_price).toFixed(2) * o.quantity
            }
        })
        extraCart.map((o) => {
            if (o.value.value_meal_price) {
                extraSave += (o.value.price - o.value.value_meal_price).toFixed(2) * o.quantity
            }
        })

        snackCart.map((o) => {
            if (o.value.value_meal_price) {
                snacksSave += (o.value.price - o.value.value_meal_price).toFixed(2) * o.quantity
            }
        })

        let totalSavings = drinksSave + extraSave + snacksSave;
        this.setState({ totalSaving: parseFloat(totalSavings).toFixed(2) })
    }

    addItem = ({ item, type, operator, quantity }) => {
        let extrasObject = [], drinksObject = [], snacksObject = [];
        let extraCart = this.state.extrasCart;
        let drinkCart = this.state.drinksCart;
        let snackCart = this.state.snacksCart;

        if (type == "extras" && operator != null) {
            extrasObject = { value: item, name: item.title_bold, id: item.menu_item_id, quantity: operator == "add" ? quantity += 1 : quantity -= 1 }

            if (extraCart.length > 0) {
                let keyIndex;
                let cartCheck = extraCart.filter((o, key) => {
                    if (o.id == item.menu_item_id) {
                        keyIndex = key;
                        extraCart[key] = extrasObject;
                        return true;
                    }
                })

                if (cartCheck.length == 0) {
                    extraCart.push(extrasObject);
                } else if (cartCheck.length > 0 && extrasObject.quantity < 1) {
                    let cartnow = extraCart.filter((o) => {
                        return o.id != item.menu_item_id;
                    })
                    extraCart = cartnow
                }
            } else {
                extraCart.push(extrasObject);
            }
        } else if (type == "drinks" && operator != null) {
            drinksObject = { value: item, name: item.title_bold, id: item.menu_item_id, quantity: operator == "add" ? quantity += 1 : quantity -= 1 }

            if (drinkCart.length > 0) {
                let keyIndex;
                let cartCheck = drinkCart.filter((o, key) => {
                    if (o.id == item.menu_item_id) {
                        keyIndex = key;
                        drinkCart[key] = drinksObject;
                        return true;
                    }
                })

                if (cartCheck.length == 0) {
                    drinkCart.push(drinksObject);
                } else if (cartCheck.length > 0 && drinksObject.quantity < 1) {
                    let cartnow = drinkCart.filter((o) => {
                        return o.id != item.menu_item_id;
                    })
                    drinkCart = cartnow
                }
            } else {
                drinkCart.push(drinksObject);
            }
        }
        else if (type == "snacks" && operator != null) {
            snacksObject = { value: item, name: item.title_bold, id: item.menu_item_id, quantity: operator == "add" ? quantity += 1 : quantity -= 1 }

            if (snackCart.length > 0) {
                let keyIndex;
                let cartCheck = snackCart.filter((o, key) => {
                    if (o.id == item.menu_item_id) {
                        keyIndex = key;
                        snackCart[key] = snacksObject;
                        return true;
                    }
                })

                if (cartCheck.length == 0) {
                    snackCart.push(snacksObject);
                } else if (snackCart.length > 0 && snacksObject.quantity < 1) {
                    let cartnow = snackCart.filter((o) => {
                        return o.id != item.menu_item_id;
                    })
                    snackCart = cartnow
                }
            } else {
                snackCart.push(snacksObject);
            }

        }
        this.setState(prevState => ({ extrasCart: extraCart, drinksCart: drinkCart, snacksCart: snackCart, noCart: { extras: type == "extras" ? false : prevState.noCart.extras, snacks: type == "snacks" ? false : prevState.noCart.snacks, drinks: type == "drinks" ? false : prevState.noCart.drinks } }));

        let cartObject = {
            [this.state.id]: {
                extras: extraCart,
                drinks: drinkCart,
                snacks: snackCart,
            }
        }

        this.props.terminalMenuStore.totalMenuList = { ...this.props.terminalMenuStore.totalMenuList, ...cartObject }
        this.props.terminalCartStore.addMealCart({ id: this.state.id, add: true });

        this.calculateTotalPrice(drinkCart, extraCart, snackCart);
    }

    selectNoMeal = (type) => {
        if (type == "extras" || type == "drinks" || type == "snacks") {
            this.calculateTotalPrice(type == "drinks" ? [] : this.state.drinksCart, type == "snacks" ? [] : this.state.snacksCart, type == "extras" ? [] : this.state.extrasCart);
            let cartObject = {
                [this.state.id]: {
                    extras: type == "extras" ? [] : this.state.extrasCart,
                    drinks: type == "drinks" ? [] : this.state.drinksCart,
                    snacks: type == "snacks" ? [] : this.state.snacksCart,
                }
            }

            this.props.terminalMenuStore.totalMenuList = { ...this.props.terminalMenuStore.totalMenuList, ...cartObject }
            this.props.terminalCartStore.addMealCart({ id: this.state.id, add: true });

            this.setState(prevState => ({ extrasCart: type == "extras" ? [] : prevState.extrasCart, drinksCart: type == "drinks" ? [] : prevState.drinksCart, snacksCart: type == "snacks" ? [] : prevState.snacksCart, noCart: { extras: type == "extras" ? !prevState.noCart.extras : prevState.noCart.extras, drinks: type == "drinks" ? !prevState.noCart.drinks : prevState.noCart.drinks, snacks: type == "snacks" ? !prevState.noCart.snacks : prevState.noCart.snacks } }))
        }
    }

    changeSection = (isContinue) => {
        const {currentSection} = this.state;
        if (isContinue) {
            if (currentSection == 'extras') {
                if (this.state.extrasCart.length == 0) {
                    this.selectNoMeal("extras");
                }
                this.setState({ currentSection : 'drinks', toggle: 'drinks' })
            } else {
                if (this.state.drinksCart.length == 0) {
                    this.selectNoMeal("drinks");
                }
                this.setState({ currentSection : 'snacks', toggle: 'snacks' })
            }
        } else {
            if (currentSection == 'snacks') {
                this.setState({ currentSection : 'drinks', toggle: 'drinks' })
            } else {
                this.setState({ currentSection : 'extras', toggle: 'extras' })
            }
        }
    }

    goToSection = (nextSection) => {
        const {currentSection} = this.state;
        if (currentSection == 'extras') {
            if (nextSection == 'drinks') {
                if (this.state.extrasCart.length == 0 && !this.state.noCart.extras) {
                    this.selectNoMeal("extras");
                }
                this.setState({ currentSection : 'drinks', toggle: 'drinks' })
            } else if (nextSection == 'snacks') {
                if (this.state.extrasCart.length == 0 && !this.state.noCart.extras) {
                    this.selectNoMeal("extras");
                }
                if (this.state.drinksCart.length == 0 && !this.state.noCart.drinks) {
                    this.selectNoMeal("drinks");
                }
                this.setState({ currentSection : 'snacks', toggle: 'snacks' })
            } else {
                this.setState({ currentSection : nextSection, toggle: nextSection })
            }
        } else if (currentSection == 'drinks') {
            if (nextSection == 'snacks') {
                if (this.state.drinksCart.length == 0) {
                    this.selectNoMeal("drinks");
                }
                this.setState({ currentSection : 'snacks', toggle: 'snacks' })
            } else {
                this.setState({ currentSection : nextSection, toggle: nextSection })
            }
        } else {
            this.setState({ currentSection : nextSection, toggle: nextSection })
        }
    }

    render() {
        const itemWidth = '194px';
        const itemHeight = '322px';
        const itemBorderRadius = '8px';
        const xMargin = '0px';
        const yMargin = '24px';
        const store = this.props.terminalMenuStore;
        const cartStore = this.props.terminalCartStore;
        const { itemsFetcher, cartItem } = store;
        const { currentSection } = this.state;
        let extraAddOns = [];
        const items = itemsFetcher?.data?.filter((item) => item.quantity_left > 0)

        let quantityItems = cartStore.cartServices.getUniqueItemsAt().filter((o) => {
            return o.id == this.state.id
        })

        if (cartItem.extra_addon_item_ids.length > 0) {
            itemsFetcher?.data?.map((o) => {
                if (cartItem.extra_addon_item_ids.includes(o.menu_item_id)) {
                    extraAddOns.push(o)
                }
            })
        }

        if (extraAddOns.length > 0) {
            extraAddOns.sort((a, b) => {
                var textA = a.title_bold.toUpperCase();
                var priceA = a.value_meal_price;
                var priceB = b.value_meal_price;
                var textB = b.title_bold.toUpperCase();
                if (priceA != priceB) {
                    return priceA - priceB
                } else {
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }
            });
        } else {
            const addonItems = items?.filter((item) => item.addon_category == "Drinks" || item.addon_category == "Snacks" || item.addon_category == "Desserts").sort((a, b) => {
                return a.current_ranking - b.current_ranking;
            })

            let maxNumberOfExtras = addonItems.length;
            if (maxNumberOfExtras > 5) {
                maxNumberOfExtras = 5;
            }

            for (var i = 0; i < maxNumberOfExtras; i++) {
                extraAddOns.push(addonItems[i]);
            }
        }

        const drinkItems = items?.filter((item) => item.addon_category === 'Drinks').sort((a, b) => {
            var textA = a.title_bold.toUpperCase();
            var priceA = a.value_meal_price;
            var priceB = b.value_meal_price;
            var textB = b.title_bold.toUpperCase();
            if (priceA != priceB) {
                return priceA - priceB
            } else {
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }
        });

        const snackItems = items?.filter((item) => item.addon_category === 'Snacks' || item.addon_category === 'Desserts').sort((a, b) => {
            var textA = a.title_bold.toUpperCase();
            var priceA = a.value_meal_price;
            var priceB = b.value_meal_price;
            var textB = b.title_bold.toUpperCase();
            if (priceA != priceB) {
                return priceA - priceB
            } else {
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }
        });

        return (
            <>
                <Modal
                    showModal={Boolean(this.props.terminalMenuStore.showingPopup)}
                    showCloseIcon={false}
                    contentClasses="modal__content"
                    onBackdropClicked={this.handleClosePopup}
                    disableScrollingOnMobile={true}
                >
                    {this.props.terminalMenuStore.showingPopup === 'reset-kiosk' ? (
                        <PopupResetKiosk onClosePopup={this.handleClosePopup} />
                    ) : null}
                </Modal>
                <div style={{ height: '100vh' }} className={`${base} display--flex`}>
                    <div className={`${base}__item-1`}>
                        <div className={`${base}__menu-details`}>
                            <div
                                className={`${mediumItem}`}
                                style={{
                                    width: itemWidth,
                                    height: itemHeight,
                                    borderRadius: itemBorderRadius,
                                    margin: `${yMargin} ${xMargin}`,
                                    border: '0px',
                                    color:'white',
                                    backgroundImage : `url(${cartItem.image})`,
                                    backgroundSize: 'cover',
                                    flex: '0 0 50%'
                                }}
                            >

                                <div className={`${base}__ranking_name_container`}>
                                    <h4 className={`${mediumItem}__title_bold`} style={ cartItem.display_mode == "White" ? {color: 'black', marginTop: '0.5em', marginRight: '0.5em', fontSize: '20px'} : {color: 'white', marginTop: '0.5em', marginRight: '0.5em', fontSize: '20px'}}>{cartItem.title_bold}</h4>
                                </div>
                                <div className={`${mediumItem}__price_container`} style={cartItem.display_mode == "White" ? {color: 'black', flexDirection: 'row'} : {color: 'white', flexDirection: 'row'}}>
                                    <p className={`${mediumItem}__price`} style={cartItem.special_offer_price ? {textDecoration: 'line-through', fontSize: '18px'} : {fontSize: '18px'}}>{cartItem.price}</p>
                                    <p className={`${mediumItem}__special-price`} style={{marginLeft: '8px', fontWeight: '700', fontSize: '18px'}}>{cartItem.special_offer_price}</p>
                                </div>
                            </div>

                            <div className={`${base}__title-container`}>
                                <div id="extrasContainer" className={`${base}__title-sub-container`} style={this.state.extrasCart.length > 1 ? {paddingBottom: '0px'} : {}}>
                                    <div style={{ borderRight: (this.state.noCart.extras || this.state.extrasCart.length > 0) ? '5px solid #fec700' : '5px solid #a0a2a4' }} className="progress-line">
                                    </div>
                                    <div className={`display--flex flex-align-items-center `}>
                                        <div style={{ border: this.state.toggle == "extras" || this.state.extrasCart.length > 0 || this.state.noCart.extras || extraAddOns.length == 0 ? '5px solid #fec700' : '5px solid #a0a2a4', background: this.state.extrasCart.length > 0 || this.state.noCart.extras || extraAddOns.length == 0 ? '#fec700' : "#2b2b2b" }} className={`${base}__circle-icon`}>
                                            {this.state.extrasCart.length > 0 || this.state.noCart.extras || extraAddOns.length == 0 ? <img style={{ padding: '3px', objectFit: 'contain' }} width={'100%'} height={'100%'} src="/black-tick.png" />
                                                : null}
                                        </div>
                                        <div style={{ color: this.state.extrasCart.length > 0 || this.state.noCart.extras || extraAddOns.length == 0 || this.state.toggle == "extras" ? "#fec700" : "#a0a2a4" }} className={`${base}__icon-title`}
                                            onClick={e => {this.goToSection('extras')}}>
                                            EXTRAS
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '50px', color: 'white', fontWeight: 600 }}
                                        onClick={e => {this.goToSection('extras')}}>
                                        <div style={{marginLeft: '5px' }}>
                                            {this.state.extrasCart.length > 0 ? this.state.extrasCart.map((o, key) => {
                                                return (
                                                    <p key={key} style={this.state.extrasCart.length > 1 ? {paddingBottom: '8px', lineHeight: '1.5'} : {lineHeight: '1.5'}}>{o.quantity} x {o.name}</p>
                                                )
                                            }) : (
                                                    this.state.noCart.extras || extraAddOns.length == 0 ? (<p style={{lineHeight: '1.5'}}>No extras</p>)
                                                    : null)
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div id="drinksContainer" className={`${base}__title-sub-container`} style={this.state.drinksCart.length > 1 ? {paddingBottom: '0px'} : {}}>
                                    <div style={{ borderRight: ((currentSection == 'drinks' || currentSection == 'snacks') && (this.state.noCart.drinks || this.state.drinksCart.length > 0)) ? '5px solid #fec700' : '5px solid #a0a2a4' }} className="progress-line">
                                    </div>
                                    <div className={`display--flex flex-align-items-center`}>
                                        <div style={{ border: (currentSection == 'drinks' || currentSection == 'snacks') && (this.state.toggle == "drinks" || this.state.drinksCart.length > 0 || this.state.noCart.drinks || drinkItems.length == 0) ? '5px solid #fec700' : '5px solid #a0a2a4', background: (currentSection == 'drinks' || currentSection == 'snacks') && (this.state.drinksCart.length > 0 || this.state.noCart.drinks || drinkItems.length == 0) ? '#fec700' : "#2b2b2b" }} className={`${base}__circle-icon`}>
                                            {(currentSection == 'drinks' || currentSection == 'snacks') && (this.state.drinksCart.length > 0 || this.state.noCart.drinks || drinkItems.length == 0) ? <img style={{ padding: '3px', objectFit: 'contain' }} width={'100%'} height={'100%'} src="/black-tick.png" />
                                                : null}
                                        </div>
                                        <div style={{ color: (currentSection == 'drinks' || currentSection == 'snacks') && (this.state.drinksCart.length > 0 || this.state.noCart.drinks || drinkItems.length == 0 || this.state.toggle == "drinks") ? "#fec700" : "#a0a2a4" }} className={`${base}__icon-title`}
                                            onClick={e => {this.goToSection('drinks')}}>
                                            DRINKS
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '50px', color: 'white', fontWeight: 600 }}
                                        onClick={e => {this.goToSection('drinks')}}>
                                        <div style={{marginLeft: '5px' }}>
                                            {this.state.drinksCart.length > 0 ? this.state.drinksCart.map((o, key) => {
                                                return (
                                                    <p key={key} style={this.state.drinksCart.length > 1 ? {paddingBottom: '8px', lineHeight: '1.5'} : {lineHeight: '1.5'}}>{o.quantity} x {o.name}</p>
                                                )
                                            }) : (
                                                    this.state.noCart.drinks || drinkItems.length == 0 || drinkItems.length == 0 ? (<p style={{lineHeight: '1.5'}}>No drinks</p>)
                                                    : null)
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div id="snacksContainer" className={`${base}__title-sub-container`} style={this.state.snacksCart.length > 1 ? {paddingBottom: '0px'} : {}}>
                                    <div className={`display--flex flex-align-items-center`}>
                                        <div style={{ border: (currentSection == 'snacks') && (this.state.toggle == "snacks" || this.state.noCart.snacks || this.state.snacksCart.length > 0) ? '5px solid #fec700' : '5px solid #a0a2a4', background: currentSection == 'snacks' && (this.state.snacksCart.length > 0 || this.state.noCart.snacks || snackItems.length == 0) ? '#fec700' : "#2b2b2b" }} className={`${base}__circle-icon`}>
                                            {currentSection == 'snacks' && (this.state.snacksCart.length > 0 || this.state.noCart.snacks || snackItems.length == 0) ? <img style={{ padding: '3px', objectFit: 'contain' }} width={'100%'} height={'100%'} src="/black-tick.png" />
                                                : null}
                                        </div>
                                        <div style={{ color: currentSection == 'snacks' && (this.state.snacksCart.length > 0 || this.state.noCart.snacks || snackItems.length == 0 || this.state.toggle == "snacks") ? "#fec700" : "#a0a2a4" }} className={`${base}__icon-title`}
                                            onClick={e => {this.goToSection('snacks')}}>
                                            SNACKS & DESSERTS
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '50px', color: 'white', fontWeight: 600 }}
                                        onClick={e => {this.goToSection('snacks')}}>
                                        <div style={{marginLeft: '5px' }}>
                                            {this.state.snacksCart.length > 0 ? this.state.snacksCart.map((o, key) => {
                                                return (
                                                    <p key={key} style={this.state.snacksCart.length > 1 ? {paddingBottom: '8px', lineHeight: '1.5'} : {lineHeight: '1.5'}}>{o.quantity} x {o.name}</p>
                                                )
                                            }) : (
                                                    this.state.noCart.snacks || snackItems.length == 0 || snackItems.length == 0 ? (<p style={{lineHeight: '1.5'}}>No snacks & desserts</p>)
                                                    : null)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {quantityItems.length > 0 && (
                                <div style={{display: 'flex', marginTop: '5px', flex : '0 0 10%'}}>
                                    {this.state.totalSaving > 0 ? <div style={{ fontSize: '18px', color: 'rgba(189, 255, 0, 1)', fontWeight: 600 }}>
                                        TOTAL SAVINGS: {(this.state.totalSaving * quantityItems[0].quantity).toFixed(2)}
                                    </div> : null}
                                </div>
                            )}
                        </div>

                        <div className={`${base}__cta-container`}>
                            {currentSection == 'extras' ?
                                <div onClick={e => { this.cancelItem() }} style={{ fontSize: '18px', fontWeight: 600, color: 'rgba(244, 89, 79, 1)' }}>
                                    CANCEL ITEM
                                </div> : 
                                <div onClick={e => { this.changeSection(false) }} style={{ fontSize: '18px', fontWeight: 600, color: 'rgba(244, 89, 79, 1)' }}>
                                    GO BACK
                                </div>
                            }
                            <div onClick={e => {this.changeSection(true)}}
                            style={((this.state.drinksCart.length > 0 || this.state.noCart.drinks) && (this.state.extrasCart.length > 0 || this.state.noCart.extras) && currentSection == 'snacks') ? {display : 'none'} : {}} className={`${base}__cta-cart`}>
                                <span>
                                    Continue
                                </span>
                            </div>
                            <div onClick={e => {
                                e.preventDefault();
                                this.addCart();
                            }} style={((this.state.drinksCart.length > 0 || this.state.noCart.drinks) && (this.state.extrasCart.length > 0 || this.state.noCart.extras) && currentSection == 'snacks') ? {} : {display : 'none'}} className={`${base}__cta-cart`}>
                                <span>
                                    {this.state.edit ? 'Save changes' : 'Add to cart'}
                                </span>
                                    <span style={{ margin: '0px 10px' }}>
                                        |
                                    </span>
                                    <span>
                                    {cartStore.totalPrice ? cartStore.totalPrice : cartItem.special_offer_price ? cartItem.special_offer_price : cartItem.price}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={`${base}__item-2`}>
                        <div id="extras" style={currentSection == 'extras' ? {} : {display : 'none'}}>
                            <div className={`${base}__sticky-header display--flex flex-align-items-center`}>
                                <h1 style={{ color: 'white', fontWeight: 'bold' }}>SELECT EXTRAS</h1>
                                {this.state.addCart && this.state.extrasCart.length == 0 && !this.state.noCart.extras ? <span onClick={e => {
                                    const className = e.target.closest('.error-tag').classList;
                                    className.toggle("box"); setTimeout(() => {
                                        className.remove('box');
                                    }, 1000);
                                }} className='error-tag'> <img style={{ margin: '0px 7px' }} width="21px" height="21px" src="/red-alert.png" /><span style={{ paddingRight: '10px' }}>Please make a selection</span></span>
                                    : null}
                            </div>
                            <div className={`${base}__card-container`}>
                                {extraAddOns.length > 0 && extraAddOns.map((o, key) => {
                                    return (
                                        <div key={key} onClick={e => { if (this.state.extrasCart.filter((u) => { return u.id == o.menu_item_id }).length <= 0) { this.addItem({ item: o, type: "extras", operator: "add", quantity: 0 }) } }} className={`${base}__card-details`}>
                                            <div style={{ position: 'relative' }}>
                                                <img className={`${base}__card-img`} src={o.digital_menu_image} />
                                                {this.state.extrasCart.length > 0 && this.state.extrasCart.filter((u) => { return u.id == o.menu_item_id }).length > 0
                                                    ?
                                                    <div className={`${base}__main-btn-container`}>
                                                        <div className={`${base}__yellow-container`}>
                                                        </div>
                                                        <div className={`${base}__add-cart-container`}>
                                                            <div style={{ width: '80%' }} className={`${base} display--flex flex-align-items-center justify--space-between`}>
                                                                <div onClick={e => { e.preventDefault(); this.addItem({ item: o, type: "extras", operator: "minus", quantity: this.state.extrasCart.filter((u) => { return u.id == o.menu_item_id })[0].quantity }) }} className={`${base}__minus-btn`}>
                                                                    <img width="40px" height="40px" src="/minus-btn.png" />
                                                                </div>
                                                                <h2 className={`${base}__qty-number`}>
                                                                    {this.state.extrasCart.filter((u) => { return u.id == o.menu_item_id })[0].quantity}
                                                                </h2>
                                                                <div onClick={e => { e.preventDefault(); this.addItem({ item: o, type: "extras", operator: "add", quantity: this.state.extrasCart.filter((u) => { return u.id == o.menu_item_id })[0].quantity }) }} className={`${base}__plus-btn`}>
                                                                    <img width="40px" height="40px" src="/plus-btn.png" />

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                            <div className={`${base}__card-details-content`}>
                                                <p className={`${base}__card-details-name`}>{o.title_bold}</p>
                                                <div className={`${base}__card-details-price-container`}>
                                                    <div style={{ gap: '5px' }} className='display--flex flex-align-items-center'>
                                                        {o.value_meal_price ? <p className={`${base}__card-special-price`}>
                                                            {o.value_meal_price}
                                                        </p> : null}
                                                        <p style={{ textDecoration: o.value_meal_price ? 'line-through' : null, fontSize: o.value_meal_price ? '14px' : '18px' }} className={`${base}__card-price`}>
                                                            {o.price}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div id="drinks" style={currentSection == 'drinks' ? {} : {display : 'none'}}>
                            <div className={`${base}__sticky-header display--flex flex-align-items-center`}>
                                <h1 style={{ color: 'white', fontWeight: 'bold' }}>SELECT DRINKS</h1>
                                {this.state.addCart && this.state.drinksCart.length == 0 && !this.state.noCart.drinks ? <span onClick={e => {
                                    const className = e.target.closest('.error-tag').classList;
                                    className.toggle("box"); setTimeout(() => {
                                        className.remove('box');
                                    }, 1000);
                                }} className='error-tag'> <img width="21px" height="21px" style={{ margin: '0px 7px' }} src="/red-alert.png" /><span style={{ paddingRight: '10px' }}>Please make a selection</span></span>
                                    : null}
                            </div>
                            <div className={`${base}__card-container`}>
                                {drinkItems?.map((o, key) => {
                                    return (
                                        <div onClick={e => { e.preventDefault(); if (this.state.drinksCart.filter((u) => { return u.id == o.menu_item_id }).length <= 0) { this.addItem({ item: o, type: "drinks", operator: "add", quantity: 0 }) } }} key={key} className={`${base}__card-details`}>
                                            <div style={{ position: 'relative' }}>
                                                <img className={`${base}__card-img`} src={o.digital_menu_image} />

                                                {this.state.drinksCart.length > 0 && this.state.drinksCart.filter((u) => { return u.id == o.menu_item_id }).length > 0
                                                    ?
                                                    <div className={`${base}__main-btn-container`}>
                                                        <div className={`${base}__yellow-container`}>

                                                        </div>
                                                        <div className={`${base}__add-cart-container`}>
                                                            <div style={{ width: '80%' }} className={`${base} display--flex flex-align-items-center justify--space-between`}>
                                                                <div onClick={e => { this.addItem({ item: o, type: "drinks", operator: "minus", quantity: this.state.drinksCart.filter((u) => { return u.id == o.menu_item_id })[0].quantity }) }} className={`${base}__minus-btn`}>
                                                                    <img width="40px" height="40px" src="/minus-btn.png" />
                                                                </div>
                                                                <h2 className={`${base}__qty-number`}>
                                                                    {this.state.drinksCart.filter((u) => { return u.id == o.menu_item_id })[0].quantity}
                                                                </h2>
                                                                <div onClick={e => { this.addItem({ item: o, type: "drinks", operator: "add", quantity: this.state.drinksCart.filter((u) => { return u.id == o.menu_item_id })[0].quantity }) }} className={`${base}__plus-btn`}>
                                                                    <img width="40px" height="40px" src="/plus-btn.png" />

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                            <div className={`${base}__card-details-content`}>
                                                <p className={`${base}__card-details-name`}>{o.title_bold}</p>
                                                <div className={`${base}__card-details-price-container`}>
                                                    <div style={{ gap: '5px' }} className='display--flex flex-align-items-center'>
                                                        {o.value_meal_price ? <p className={`${base}__card-special-price`}>
                                                            {o.value_meal_price}
                                                        </p> : null}
                                                        <p style={{ textDecoration: o.value_meal_price ? 'line-through' : null, fontSize: o.value_meal_price ? '14px' : '18px' }} className={`${base}__card-price`}>
                                                            {o.price}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div id="snacks" style={currentSection == 'snacks' ? {} : {display : 'none'}}>
                            <div className={`${base}__sticky-header display--flex flex-align-items-center`}>
                                <h1 style={{ color: 'white', fontWeight: 'bold' }}>SELECT SNACKS & DESSERTS</h1>
                                {this.state.addCart && this.state.snacksCart.length == 0 && !this.state.noCart.snacks ? <span onClick={e => {
                                    const className = e.target.closest('.error-tag').classList;
                                    className.toggle("box"); setTimeout(() => {
                                        className.remove('box');
                                    }, 1000);
                                }} className='error-tag'> <img style={{ margin: '0px 7px' }} width="21px" height="21px" src="/red-alert.png" /><span style={{ paddingRight: '10px' }}>Please make a selection</span></span>
                                    : null}
                            </div>
                            <div className={`${base}__card-container`}>
                                {snackItems?.map((o, key) => {
                                    return (
                                        <div onClick={e => { e.preventDefault(); if (this.state.snacksCart.filter((u) => { return u.id == o.menu_item_id }).length <= 0) { this.addItem({ item: o, type: "snacks", operator: "add", quantity: 0 }) } }} key={key} className={`${base}__card-details`}>
                                            <div style={{ position: 'relative' }}>
                                                <img className={`${base}__card-img`} src={o.digital_menu_image} />
                                                {this.state.snacksCart.length > 0 && this.state.snacksCart.filter((u) => { return u.id == o.menu_item_id }).length > 0
                                                    ?
                                                    <div className={`${base}__main-btn-container`}>
                                                        <div className={`${base}__yellow-container`}>
                                                        </div>
                                                        <div className={`${base}__add-cart-container`}>
                                                            <div style={{ width: '80%' }} className={`${base} display--flex flex-align-items-center justify--space-between`}>
                                                                <div onClick={e => { this.addItem({ item: o, type: "snacks", operator: "minus", quantity: this.state.snacksCart.filter((u) => { return u.id == o.menu_item_id })[0].quantity }) }} className={`${base}__minus-btn`}>
                                                                    <img width="40px" height="40px" src="/minus-btn.png" />
                                                                </div>
                                                                <h2 className={`${base}__qty-number`}>
                                                                    {this.state.snacksCart.filter((u) => { return u.id == o.menu_item_id })[0].quantity}
                                                                </h2>
                                                                <div onClick={e => { this.addItem({ item: o, type: "snacks", operator: "add", quantity: this.state.snacksCart.filter((u) => { return u.id == o.menu_item_id })[0].quantity }) }} className={`${base}__plus-btn`}>
                                                                    <img width="40px" height="40px" src="/plus-btn.png" />

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                            <div className={`${base}__card-details-content`}>
                                                <p className={`${base}__card-details-name`}>{o.title_bold}</p>
                                                <div className={`${base}__card-details-price-container`}>
                                                    <div style={{ gap: '5px' }} className='display--flex flex-align-items-center'>
                                                        {o.value_meal_price ? <p className={`${base}__card-special-price`}>
                                                            {o.value_meal_price}
                                                        </p> : null}
                                                        <p style={{ textDecoration: o.value_meal_price ? 'line-through' : null, fontSize: o.value_meal_price ? '14px' : '18px' }} className={`${base}__card-price`}>
                                                            {o.price}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(MealSelection)