import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import Cookies from 'js-cookie';
import config from '../../configs/parameters';

@inject('loginPopupStore', 'menuHeaderStore', 'userStore')
@observer
class AppDownloadBanner extends Component {
    handleHideBanner = () => {
        const { menuHeaderStore } = this.props;
        menuHeaderStore.setShowAppDownloadBanner(false);
    };

    render() {
        const { loginPopupStore, menuHeaderStore, userStore } = this.props;
        const bannerCta = Cookies.get('clicked_get_app_banner')
            ? 'OPEN'
            : 'GET';
        const display =
            menuHeaderStore.showAppDownloadBanner && !loginPopupStore.isShowing
                ? ''
                : 'collapse';

        return (
            <div id="app-banner-iframe" className={display}>
                <div id="app-banner">
                    <div className="app-banner-content">
                        <div className="app-banner-right">
                            <a
                                href={userStore.appDownloadLink}
                                className="app-banner-button"
                                onClick={this.handleHideBanner}
                            >
                                {bannerCta}
                            </a>
                        </div>
                        <div className="app-banner-left">
                            <div
                                className="app-banner-close close"
                                onClick={this.handleHideBanner}
                            >
                                ×
                            </div>
                            <div className="app-banner-icon">
                                <img src="/android-chrome-192x192.png" />
                            </div>
                            <div className="app-banner-details">
                                <div className="app-banner-title">
                                    {config.brandName} | food delivery
                                </div>
                                <div className="app-banner-description">
                                    Popular meals at even more popular prices.
                                </div>
                                <div className="app-banner-stars">★★★★★</div>
                                <div className="app-banner-reviews">
                                    (44.8K)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AppDownloadBanner;
