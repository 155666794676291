import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import CartItem from './CartItem';
import Modal from '../../../core/Modal';
import PopupOrdering from '../PopupOrdering';
import PopupPhoneNumber from '../PopupPhoneNumber';
// import PopupTableNumber from '../PopupTableNumber';
import intl from 'react-intl-universal';
import SwitchButton from 'lyef-switch-button';
import terminalCartStore from '../../../../store/terminalCartStore';

const base = 'terminal-mobile-cart-screen';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    state = {
        showBrowseMenuPopup: false,
    };
    handleCloseClick = () => {
        const {
            terminalMenuStore: { mobileScreens, setShowMobileScreen },
        } = this.props;
        setShowMobileScreen(mobileScreens.ITEM);
    };

    handleConfirmBtnClick = () => {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.setShowPopup(terminalMenuStore.POPUP_PHONE_NUMBER);
    };

    handleClosePopup = () => {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.setShowPopup('');
    };

    removeMealCallback = () => {
        const { terminalCartStore } = this.props;
        const itemsInCart = terminalCartStore.cartServices.getUniqueItemsAt();
        if (!itemsInCart || itemsInCart.length === 0) {
            this.setState({ showBrowseMenuPopup: true });
        }
    };

    render() {
        const {
            terminalMenuStore: { orderType },
            terminalMenuStore: store,
            terminalCartStore,
        } = this.props;
        const numberOfMainMeal = terminalCartStore.cartServices.getMainMeals().length;
        const showCutlerySwitcher = orderType === 'TAKE_AWAY' && numberOfMainMeal > 0;
        const { showBrowseMenuPopup } = this.state;
        const itemsInCart = terminalCartStore.cartServices.getUniqueItemsAt();
        const cutleryPrice = terminalCartStore.isCutleryProvided
            ? terminalCartStore.getCutleryPrice() * numberOfMainMeal
            : 0;
        const subTotal = terminalCartStore.totalPrice - cutleryPrice;
        const totalPrice = terminalCartStore.totalPrice;

        const screenHeight = window.screen.height;
        //it's a hack to solve the nature of placement(styling) of CTA button if we've more items in cart. These numbers on right correspond to height of elements present on cart screen
        const isBigScreen =
            screenHeight - (76 + 24 + 14 + 32 + 26 + 32 + itemsInCart.length * 68.5 + 24 + 6 + 24 + 47) > 0;

        return (
            <Fragment>
                <div className={`${base}`}>
                    <svg
                        className={'back_btn'}
                        onClick={this.handleCloseClick}
                        width="12"
                        height="19"
                        viewBox="0 0 12 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.5561 2.14306L9.9528 0.539795L0.985413 9.50719L9.9528 18.4746L11.5561 16.8713L4.19193 9.50719L11.5561 2.14306Z"
                            fill="black"
                        />
                    </svg>

                    <h4 className={`${base}__review_text`}>Review your order</h4>
                    <div className={`${base}__cart_items`}>
                        {itemsInCart &&
                            itemsInCart.map((item) => (
                                <CartItem item={item} removeMealCallback={this.removeMealCallback} />
                            ))}
                    </div>
                    {showCutlerySwitcher && (
                        <div>
                            <div className="separator"></div>
                            <div className="display--flex flex-align-items-center justify--space-between">
                                <div>
                                    <p className={`${base}__heading`}>Cutlery</p>
                                    {terminalCartStore.isCutleryProvided && (
                                        <p className={`${base}__sub-heading`}>
                                            {`${intl.get(
                                                'cutlerySelection.enabled'
                                            )} RM${terminalCartStore.getCutleryPrice()} each.`}
                                        </p>
                                    )}
                                    {!terminalCartStore.isCutleryProvided && (
                                        <p className={`${base}__sub-heading`}>
                                            {`${intl.get('cutlerySelection.disabled')}`}
                                        </p>
                                    )}
                                </div>
                                <div className={`${base}__switch-button`}>
                                    <SwitchButton
                                        id={`switchTerminalCutlery`}
                                        isChecked={terminalCartStore.isCutleryProvided}
                                        action={this.handleToggleCutlerySwitch}
                                    />
                                </div>
                            </div>
                            {!terminalCartStore.isCutleryProvided && (
                                <p className={`fs--small ${base}__thank-you-text`}>
                                    <img
                                        src={'https://image.popcontent.link/leaf.svg'}
                                        alt={'leaf_icon'}
                                        style={{ marginRight: '8px' }}
                                    />
                                    {intl.get('cutlerySelection.thankyou')}
                                </p>
                            )}
                        </div>
                    )}
                    <div className="separator"></div>

                    <div className={`${base}__price_container`}>
                        <div className={`${base}__price_row`}>
                            <p className="subtotal_row">Subtotal</p>
                            <p className="subtotal_row">RM {subTotal}</p>
                        </div>
                        {showCutlerySwitcher && terminalCartStore.isCutleryProvided && (
                            <div className={`${base}__price_row`}>
                                <p className="subtotal_row">Cutlery</p>
                                <p className="subtotal_row">RM {cutleryPrice}</p>
                            </div>
                        )}
                        <div className={`${base}__price_row`}>
                            <p className="total_row">Total</p>
                            <p className="total_row">RM {totalPrice}</p>
                        </div>
                    </div>
                    <div className={`${base}__cta_container ${isBigScreen ? 'position-absolute' : 'position-below'}`}>
                        <button
                            disabled={!itemsInCart || itemsInCart.length === 0}
                            onClick={this.handleConfirmBtnClick}
                            className={`${base}__cta_btn`}
                        >
                            Confirm Order
                        </button>
                    </div>
                </div>
                {Boolean(store.showingPopup) && (
                    <Modal
                        showModal={true}
                        showCloseIcon={false}
                        onBackdropClicked={this.handleClosePopup}
                        disableScrollingOnMobile={false}
                        showBackdropOnMobile={true}
                        contentClasses={'rounded'}
                        manualStyles={{ width: '295px' }}
                    >
                        {store.showingPopup === store.POPUP_PHONE_NUMBER ? (
                            <PopupPhoneNumber onKeyPress={this.handleKeyPress} showOnMobile={true} />
                        ) : null}
                        {/* {store.showingPopup === store.POPUP_TABLE_NUMBER ? (
                            <PopupTableNumber onKeyPress={this.handleKeyPress} showOnMobile={true} />
                        ) : null} */}
                        {store.showingPopup === store.POPUP_ORDERING ? <PopupOrdering checkIn={store.checkIn} /> : null}
                    </Modal>
                )}

                {showBrowseMenuPopup && (
                    <Modal
                        showModal={true}
                        showCloseIcon={false}
                        onBackdropClicked={this.handleClosePopup}
                        disableScrollingOnMobile={false}
                        showBackdropOnMobile={true}
                        contentClasses={'rounded'}
                        manualStyles={{ width: '295px' }}
                    >
                        <div className={`${base}__menu_modal`}>
                            <p className={`${base}__modal_title`}>Your cart is now empty</p>
                            <button onClick={this.handleCloseClick} className={`${base}__modal_cta_btn`}>
                                Browse Menu
                            </button>
                        </div>
                    </Modal>
                )}
            </Fragment>
        );
    }

    handleToggleCutlerySwitch = () => {
        {
            terminalCartStore.toggleCutlerySwitch();
            terminalCartStore.getTotalPrice();
        }
    };
}
