import React from 'react';

import ArrowUp from '../../../core/SVGs/ArrowUp';
import ArrowDown from '../../../core/SVGs/ArrowDown';
import ArrowSide from '../../../core/SVGs/ArrowSide';

const base = 'medium-item';

export default function MediumItem({
    item,
    comboPrice,
    width,
    height,
    useCurrencySymbol,
    comboItem,
    borderRadius,
    itemWithBlackBorder,
    itemXMargin,
    itemYMargin,
    onAddToCart,
}) {
    const item_ranking = item.current_ranking < 10 ? `0${item.current_ranking}` : item.current_ranking;
    const movement_status = item.movement === 0 ? 'constant' : item.movement > 0 ? 'up' : 'down';
    let comboPriceToShow;
    if (item.item_type === 'Combo') {
        comboPriceToShow = null;
    } else {
        comboPriceToShow = comboPrice ? ((item.special_offer_price || item.price) + comboPrice).toFixed(2) : null;
    }
    const itemWidth = width ? `${width}px` : `470px`;
    const itemHeight = height ? `${height}px` : `525px`;
    const itemBorderRadius = borderRadius ? borderRadius : '1rem';
    const border = itemWithBlackBorder === false ? `0px` : `5px solid black`;
    const xMargin = itemXMargin ? `${itemXMargin}px` : `0px`;
    const yMargin = itemYMargin ? `${itemYMargin}px` : `0px`;

    const setPrice = () => {
        if (item.special_offer_price && item.special_offer_price > comboItem.meal_base_price) {
            return '+' + parseFloat(item.special_offer_price - comboItem.meal_base_price).toFixed(2);
        } else if (!item.special_offer_price && item.price > comboItem.meal_base_price) {
            return '+' + parseFloat(item.price - comboItem.meal_base_price).toFixed(2);
        }
    };
    return (
        <div
            className={`${base}`}
            style={{
                width: itemWidth,
                height: itemHeight,
                borderRadius: itemBorderRadius,
                border,
                margin: `${yMargin} ${xMargin}`,
            }}
            onClick={() => onAddToCart(item, false)}
        >
            <div className={`${base}__ranking_name_container`}>
                <div className={`${base}__ranking`}>
                    <p className={`${base}__current_ranking`}>{item_ranking}</p>
                    <div className={`${base}__ranking_change`}>
                        {(movement_status === 'constant' || Math.abs(item.movement) == 0) ? (
                            <ArrowSide width={11} height={11} />
                        ) : movement_status === 'up' ? (
                            <>
                                <ArrowUp width={11} height={11} />
                                <p className={`${base}__ranking_change_num`}>{item.movement}</p>
                            </>
                        ) : (
                            <>
                                <ArrowDown width={11} height={11} />
                                <p className={`${base}__ranking_change_num`}>{Math.abs(item.movement)}</p>
                            </>
                        )}
                    </div>
                </div>
                <h4 className={`${base}__title_bold`}>{item.title_bold}</h4>
            </div>
            <img
                loading="lazy"
                src={
                    item.digital_menu_image
                        ? item.digital_menu_image
                        : 'https://image.popcontent.link/DIGITAL_MENU_IMAGE_placeholder.png'
                }
                className={`${base}__meal_img`}
            />
            <div className={`${base}__price_container`}>
                {useCurrencySymbol ? <p className={`${base}__currency`}>RM</p> : null}
                {!comboItem ? (
                    <>
                        {item.special_offer_price ? (
                            <>
                                <p className={`${base}__special-price`}>{item.special_offer_price}</p>

                                <p
                                    style={{ textDecoration: 'line-through', fontSize: '2.2em' }}
                                    className={`${base}__price`}
                                >
                                    {item.price}
                                </p>
                            </>
                        ) : (
                            <>
                                <p className={`${base}__price`}>{item.price}</p>
                            </>
                        )}
                    </>
                ) : (
                    <p className={`${base}__price`}>{setPrice()}</p>
                )}
            </div>
            <div className={`${base}__yellow_pastel_section`}></div>
        </div>
    );
}
