import React, { Component, Fragment } from 'react';
import intl from 'react-intl-universal';
import { template } from 'lodash';
import { cities, GetCity } from '../../../services/city';
import config from '../../../configs/parameters';

const base = 'section-about';
const isKL = GetCity() === cities.kl;
const isBKK = GetCity() === cities.bangkok;

export default class SectionAbout extends Component {
    render() {
        const pathString = `${GetCity()}.onboarding.sectionAbout`;
        const brandName = config.brandName;

        return (
            <div className={`${base} container`}>
                <h3 className="text--bolder">
                    {intl.get(`${pathString}.title`)}
                </h3>
                <p>
                    {template(intl.get(`${pathString}.paragraph1`))({
                        brandName,
                    })}
                </p>
                <p>{intl.get(`${pathString}.paragraph2`)}</p>
                <p class="text--bolder">
                    {intl.get(`${pathString}.paragraph3`)}
                </p>
                <ul>
                    <li>✓ {intl.get(`${pathString}.additionalInfo.point1`)}</li>
                    <li>✓ {intl.get(`${pathString}.additionalInfo.point2`)}</li>
                    <li>✓ {intl.get(`${pathString}.additionalInfo.point3`)}</li>
                </ul>
                <p>
                    {template(intl.get(`${pathString}.paragraph4`))({
                        brandName,
                    })}
                </p>
                <p>
                    {template(intl.get(`${pathString}.paragraph5`))({
                        brandName,
                    })}
                </p>
                <p class="text--bolder">
                    {template(intl.get(`${pathString}.subtitle1`))({
                        brandName,
                    })}
                </p>
                <ul>
                    <li>✓ {intl.get(`${pathString}.dishes.dish1`)}</li>
                    <li>✓ {intl.get(`${pathString}.dishes.dish2`)}</li>
                    <li>✓ {intl.get(`${pathString}.dishes.dish3`)}</li>
                    {isBKK && (
                        <li>✓ {intl.get(`${pathString}.dishes.dish4`)}</li>
                    )}
                </ul>
                {isKL && (
                    <Fragment>
                        <p class="text--bolder">
                            {intl.get(`${pathString}.subtitle2`)}
                        </p>
                        <ul>
                            <li>✓ {intl.get(`${pathString}.dishes.dish4`)}</li>
                            <li>✓ {intl.get(`${pathString}.dishes.dish5`)}</li>
                        </ul>
                    </Fragment>
                )}
                <p>
                    {template(intl.get(`${pathString}.paragraph6`))({
                        brandName,
                    })}
                </p>
                <p>{intl.get(`${pathString}.paragraph7`)}</p>
                <p>
                    {template(intl.get(`${pathString}.paragraph8`))({
                        brandName,
                    })}
                </p>
            </div>
        );
    }
}
