import React from 'react';

const SummaryItem = (props) => {
    const bold = props.bold ? 'text--bolder' : null;
    const color = props.color === 'GREEN' ? 'text--primary' : null;
    return (
        <div className="clearfix mtm mbm">
            <span className={`float-left ${bold} ${color}`}>{props.title}</span>
            <span className={`float-right ${bold} ${color}`}>
                {props.value}
            </span>
        </div>
    );
};

export default SummaryItem;
