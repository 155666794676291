import dahmakan from './dahmakan';
import polpa from './polpa';
import { GetCity, cities } from '../city';

const city = GetCity();
const pageTitles = city === cities.kl ? dahmakan : polpa;

export default {
    ...pageTitles,
};
