import React, { Fragment } from 'react';
import Bacon from 'baconjs';
import ReactDOM from 'react-dom';
import { services } from '../../services/index';
import {
    MEDIUM_SCREEN_MEDIA,
    SMALL_SCREEN_MEDIA,
} from '../../services/constants';
import brandLogo from './brandLogo/MasterBrandLogo';

export default class extends React.Component {
    targetElement = null;

    handleEscEvent = (e) => {
        if (e.keyCode === 27) {
            if (this.props.onCloseClicked) {
                this.props.onCloseClicked();
            }
        }
    };

    componentDidMount() {
        const thisDOM = ReactDOM.findDOMNode(this);
        this.targetElement = document.querySelector('#modal-backdrop');

        document.addEventListener('keydown', this.handleEscEvent, false);

        this.disposer = Bacon.fromEvent(thisDOM, 'keyup')
            .filter(() => this.props.showModal)
            .map((evt) => evt.code)
            .filter((code) => code === 'Escape')
            .onValue(this.props.onCloseClicked);
    }

    componentWillReceiveProps() {
        const overflow =
            window.matchMedia(MEDIUM_SCREEN_MEDIA).matches ||
            window.matchMedia(SMALL_SCREEN_MEDIA).matches
                ? 'no-overflow-mobile'
                : 'no-overflow';

        window.requestAnimationFrame(() => {
            if (document.querySelectorAll('.modal--open').length) {
                // at least one opening modal
                document.body.classList.add(overflow);
            } else {
                document.body.classList.remove('no-overflow');
                document.body.classList.remove('no-overflow-mobile');
            }
        });
    }

    componentWillUnmount() {
        document.body.classList.remove('no-overflow');
        document.body.classList.remove('no-overflow-mobile');
        document.removeEventListener('keydown', this.handleEscEvent, false);

        this.disposer();
    }

    render() {
        const {
            children,
            className,
            contentClasses,
            closeButtonClasses,
            closeButtonMargin,
            showLogo,
            showModal,
            onBackdropClicked,
            onCloseClicked,
            onBackClicked,
            backButtonText,
            backButtonMargin,
            showCloseIcon,
            showBackIcon,
            isFullScreen,
            manualStyles,
            doNotHideBanner,
            logoMargin,
            showBackdropOnMobile,
            contentId,
            backgroundUrl,
            showBackgroundGradient,
            whiteIcons,
        } = this.props;

        const rootClass =
            isFullScreen && showModal
                ? className + ' modal-fullscreen modal'
                : showModal
                ? className + ' modal--open modal'
                : className + ' modal';
        const contentClass = 'modal__content ' + contentClasses;
        const closeIconClass = 'modal__close-icon ' + closeButtonClasses;
        const bottomMargin = showBackIcon ? 'modal--margin-bottom' : '';
        const showBackButtonText = backButtonText ? backButtonText : 'BACK';

        return (
            <div className={rootClass}>
                <div
                    className={`modal__backdrop ${
                        showBackdropOnMobile && 'show'
                    }`}
                    onClick={onBackdropClicked}
                    id="modal-backdrop"
                />
                <div
                    className={contentClass}
                    id={contentId}
                    style={{
                        ...manualStyles,
                        background: backgroundUrl
                            ? `${
                                  showBackgroundGradient
                                      ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), '
                                      : ''
                              }url(${backgroundUrl})`
                            : '',
                        backgroundSize: 'cover',
                    }}
                >
                    <div className={bottomMargin}>
                        <div className={`modal__close ${closeButtonMargin}`}>
                            {showCloseIcon ? (
                                <button
                                    className="button--nothing"
                                    onClick={
                                        onCloseClicked || onBackdropClicked
                                    }
                                >
                                    <svg className={closeIconClass}>
                                        <use
                                            xlinkHref="#svg--x-close"
                                            style={{
                                                fill: whiteIcons
                                                    ? 'white'
                                                    : 'black',
                                            }}
                                        />
                                    </svg>
                                </button>
                            ) : null}
                        </div>
                        <div className={`modal__logo ${logoMargin}`}>
                            {showLogo ? (
                                <div style={{ marginTop: '1px' }}>
                                    <brandLogo.PrimaryLogo />
                                </div>
                            ) : null}
                        </div>
                        <div
                            className={`modal__back display--flex ${backButtonMargin}`}
                        >
                            {showBackIcon ? (
                                <Fragment>
                                    <button
                                        className="modal__button button--nothing cursor-pointer"
                                        onClick={onBackClicked}
                                    >
                                        <svg
                                            className={`modal__back-icon iconmoon-icon mrs text--primary`}
                                        >
                                            <use xlinkHref="#icon-chevron-thin-up" />
                                        </svg>
                                    </button>
                                    <p
                                        className="modal__back-text cursor-pointer fs--small-medium"
                                        onClick={onBackClicked}
                                    >
                                        {showBackButtonText}
                                    </p>
                                </Fragment>
                            ) : null}
                        </div>
                    </div>
                    {showModal && !doNotHideBanner ? (
                        <style
                            dangerouslySetInnerHTML={{
                                __html: `.${services.getParam(
                                    'city'
                                )} .banner--show { display: none }`,
                            }}
                        />
                    ) : null}
                    {showModal ? children : null}
                </div>
            </div>
        );
    }
}
