import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import template from 'lodash/template';
import intl from 'react-intl-universal';

import { CircleLoader } from '../../core/Loading';
import { RESOURCE } from '../../../services/types';
import { services } from '../../../services/index';
import { cities, GetCity } from '../../../services/city';
import Cookies from 'js-cookie';
import Modal from '../Modal';
import qs from 'query-string';

const base = 'hamburger-menu';

@inject('menuHeaderStore')
@inject('loginPopupStore')
@inject('userStore')
@inject('rewardsStore')
@observer
export default class extends React.Component {
    handleSignInClicked() {
        this.props.onHidingMenu();
        this.props.loginPopupStore.setShowing(true);
        this.props.loginPopupStore.showLogin();
        this.props.loginPopupStore.clearPassword();
    }

    handleSignOutClicked() {
        this.props.menuHeaderStore.logOut();
    }

    handleHalalClicked() {
        this.props.onHidingMenu();
        this.props.userStore.setShowHalalPopup();
    }

    renderUserGreeting() {
        const { userStore } = this.props;
        const { user } = userStore;

        if (user === RESOURCE.Loading) {
            return (
                <li className={`${base}__item ${base}__item--underlined`}>
                    <CircleLoader />
                </li>
            );
        }

        if (RESOURCE.Success.is(user) === false) {
            return null;
        }

        return (
            <li className={`${base}__item ${base}__item--underlined`}>
                {intl.get('sidebars.hamburgerMenu.hi')}
                {user.data.firstname}
            </li>
        );
    }

    renderLogoutLink() {
        const { userStore } = this.props;
        const { user } = userStore;
        const path = window.location.pathname.replace('/' + GetCity(), '');

        if (RESOURCE.Success.is(user) === false) {
            return (
                <li className={`${base}__item ${base}__item--underlined`}>
                    <a
                        className="dmk-link--primary-hover cursor-pointer"
                        onClick={::this.handleSignInClicked}
                        id="select-login-mobile"
                    >
                        {intl.get('sidebars.hamburgerMenu.signIn')}
                    </a>
                </li>
            );
        }

        const menuItems = [
            <li key="profile" className={`${base}__item`}>
                <Link
                    className="dmk-link--primary-hover cursor-pointer"
                    to="/me"
                    id="select-profile-mobile"
                >
                    {intl.get('sidebars.hamburgerMenu.profile')}
                </Link>
            </li>,
            <li key="myorders" className={`${base}__item`}>
                <Link
                    className="dmk-link--primary-hover cursor-pointer"
                    to="/history"
                    id="select-orders-mobile"
                >
                    {intl.get('sidebars.hamburgerMenu.myOrders')}
                </Link>
            </li>,
            <li key="rewards" className={`${base}__item`}>
                <Link
                    className="dmk-link--primary-hover cursor-pointer"
                    to="/rewards"
                    id="select-rewards-mobile"
                >
                    {intl.get('rewards.rewards')}
                </Link>
                <svg
                    className={`login-cta__notification-dot login-cta__notification-dot-hamburger`}
                    focusable="false"
                >
                    <circle cx="3" cy="3" r="3"></circle>
                </svg>
            </li>,
        ];

        menuItems.push(
            <li
                key="logout"
                className={`${base}__item ${base}__item--underlined`}
            >
                <a
                    className="dmk-link--primary-hover cursor-pointer"
                    onClick={::this.handleSignOutClicked}
                    id="select-signout-mobile"
                >
                    {intl.get('sidebars.hamburgerMenu.logOut')}
                </a>
            </li>
        );

        return menuItems;
    }

    componentWillUnmount() {
        const params = qs.parse(window.location.search);
    }

    handleRewardDisplay = (reward) => {
        const currency = services.getParam('currency');
        return currency + reward;
    };

    renderRulesDetail() {
        const { rewardsStore } = this.props;
        const referringAmount =
            rewardsStore.rules === RESOURCE.Loading ||
            rewardsStore.rules === RESOURCE.Initial ||
            !rewardsStore.user
                ? 'Rewards'
                : this.handleRewardDisplay(
                      rewardsStore.rules.data.referring_user_amount
                  );
        return (
            <Link
                className="dmk-link--primary-hover"
                to="/rewards"
                id="select-referral-rewards"
            >
                {template(intl.get('headers.menuHeader.getReferralRewards'))({
                    referringAmount,
                })}
            </Link>
        );
    }

    render() {
        const { isOpening, menuHeaderStore } = this.props;
        const baseClasses = isOpening
            ? `${base}__content ${base}__content--show`
            : `${base}__content`;
        const path = window.location.pathname.replace('/' + GetCity(), '');
        const rewardTextElement = this.renderRulesDetail();
        const showHalal = menuHeaderStore.showHalal;

        return (
            <aside className={baseClasses}>
                <ul>
                    {this.renderUserGreeting()}
                    <li className={`${base}__item ${base}__item--underlined`}>
                        <a
                            className="text--primary text--no-underlined"
                            href="/menu"
                            id="select-menu-mobile"
                        >
                            {intl.get('sidebars.hamburgerMenu.menu')}
                        </a>
                    </li>
                    {this.renderLogoutLink()}
                    <li className={`${base}__item`}>
                        <Link
                            className="dmk-link--primary-hover"
                            to={{ pathname: '/wallet', state: { from: path } }}
                            id="select-wallet-mobile"
                        >
                            {intl.get('sidebars.hamburgerMenu.wallet')}
                        </Link>
                    </li>
                    <li className={`${base}__item`}>
                        <Link
                            className="dmk-link--primary-hover"
                            to="/delivery-area"
                            id="select-delivery-area-mobile"
                        >
                            {intl.get('sidebars.hamburgerMenu.deliveryArea')}
                        </Link>
                    </li>
                    <li className={`${base}__item`}>
                        <Link
                            className="dmk-link--primary-hover"
                            to="/careers"
                            id="select-delivery-area-mobile"
                        >
                            {intl.get('sidebars.hamburgerMenu.careers')}
                        </Link>
                    </li>
                    {GetCity() === cities.kl && (
                        <li className={`${base}__item`}>{rewardTextElement}</li>
                    )}
                    {showHalal && (
                        <li
                            className={`${base}__item`}
                            onClick={::this.handleHalalClicked}
                        >
                            {intl.get('sidebars.hamburgerMenu.halal')}
                        </li>
                    )}
                    <li key="promotions" className={`${base}__item`}>
                        <Link
                            className="dmk-link--primary-hover"
                            to="/promotions"
                            id="select-promotions-mobile"
                        >
                            {intl.get('sidebars.hamburgerMenu.promotions')}
                        </Link>
                    </li>
                    <li className={`${base}__item`}>
                        <Link
                            className="dmk-link--primary-hover"
                            to="/about"
                            id="select-about-mobile"
                        >
                            {intl.get('sidebars.hamburgerMenu.learnMore')}
                        </Link>
                    </li>
                    {services.getParam('freshDeskHc') && (
                        <li className={`${base}__item`}>
                            <a
                                className="dmk-link--primary-hover"
                                href={services.getParam('freshDeskHc')}
                                id="select-support-mobile"
                            >
                                {intl.get('sidebars.hamburgerMenu.faq')}
                            </a>
                        </li>
                    )}
                </ul>
            </aside>
        );
    }
}
