import React from 'react';

import intl from 'react-intl-universal';
const base = 'nutritional-view';

const Attribute = ({ item }) => {
    return (
        <div className={`${base}__item`}>
            <center>
                <img
                    alt="nutritional item"
                    className={`${base}__image`}
                    src={item.image}
                />
                <p className={`${base}__name`}>{item.name}</p>
            </center>
        </div>
    );
};

export default ({ dish }) => {
    const attributes = dish.attributes || [];

    if (attributes.length === 0) {
        return <div />;
    }

    return (
        <div className={base}>
            <div className="lh--150">
                {intl.get('dish.nutritionalNotesIntro')}
                <div className={`${base}__list`}>
                    {attributes.map((item, index) => (
                        <Attribute key={index} item={item} />
                    ))}
                </div>
            </div>
        </div>
    );
};
