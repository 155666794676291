/* eslint-disable no-undef */
import { httpGet, httpPost, httpPut } from '../http';
import { getCompanyFromLocalStorage } from '../company';
import once from 'lodash/once';

const company = getCompanyFromLocalStorage();

export const GetMenuTimeSettings = once(({ city }) =>
    httpGet(`/v3/menus/setting/${city}?useCompany=${company}`)
);

export const GetMenuForDateRange = ({ city, startDateString, endDateString }) =>
    httpGet(
        `/v4/menus/list/${city}?startDate=${startDateString}&endDate=${endDateString}&useCompany=${company}&imageDimension=Small`,
        { authType: 'Bearer' }
    );

export const GetMenuDates = () =>
    httpGet(`/v7/menus/calendar`, { authType: 'Bearer' });

export const GetMenu = ({
    startDateString,
    endDateString,
    customTimeslotId,
    customAddress,
    lat,
    lng,
}) => {
    return httpGet(
        `/v8/menus/stream?startDate=${startDateString}&endDate=${endDateString}&use_company=${company}&imageDimension=Small`,
        {
            authType: 'Bearer',
            customTimeslotId: customTimeslotId || null,
            customAddress: customAddress || null,
            latitude: lat || null,
            longitude: lng || null,
        }
    );
};

export const InvalidateInCartOrder = () => httpPut(`/v2/pos/in-cart/order`, null, { authType: 'Bearer'})

export const GetTerminalMenuForDateRange = ({
    startDateString,
    endDateString,
    customAddress,
    bearerToken,
    orderMode,
}) =>
    httpGet(
        `/v11/menus/stream?startDate=${startDateString}&endDate=${endDateString}&t=${Date.now()}&useCompany=${company}&imageDimension=Medium`,
        {
            authType: 'Bearer',
            ...(orderMode && {
                additionalHeaders: { 'X-Order-Mode': `${orderMode}` },
            }),
            noTimeslotId: true,
            customAddress: customAddress || null,
            bearerToken,
        }
    );

    export const GetAddonsForUpsell = ({
        type,
        subType,
        bearerToken,
    }) =>
        httpGet(
            `/general-setting/upsell-addons?type=${type}&subType=${subType}`,
            {
                authType: 'Bearer',
                bearerToken,
            }
        );
    
export const GetTerminalComboItemsForDateRange = ({
    city,
    startDateString,
    endDateString,
    customAddress,
    lat,
    lng,
    bearerToken,
    orderMode,
}) => {
    return httpGet(
        `/v9/menus/value-meal?startDate=${startDateString}&endDate=${endDateString}&useCompany=${company}&imageDimension=Medium`,
        {
            authType: 'Bearer',
            ...(orderMode && {
                additionalHeaders: { 'X-Order-Mode': `${orderMode}` },
            }),
            city: city || null,
            noTimeslotId: true,
            customAddress: customAddress || null,
            latitude: lat || null,
            longitude: lng || null,
            bearerToken,
        }
    );
};

export const GetAddOnForDate = ({ startDateString, endDateString }) =>
    httpGet(
        `/v9/menus/stream?startDate=${startDateString}&endDate=${endDateString}&useCompany=${company}&imageDimension=Small`,
        {
            authType: 'Bearer',
            additionalHeaders: {
                'X-Order-Mode': 'DINE_IN',
                'X-Time-Slot-Id': null,
            },
        }
    );

export const GetReviewsForDish = ({ id }) =>
    httpGet(`/v5/rating/menu-item/${id}`, { authType: 'Bearer' });

export const GetDish = ({ id, customTimeslot }) =>
    httpGet(`/v6/menus/item/${id}?use_company=${company}`, {
        authType: 'Bearer',
        customTimeslot: customTimeslot ? customTimeslot : null,
    });

export const GetTerminalDish = ({ id, customTimeslot }) =>
    httpGet(`/v4/menus/item/${id}?useCompany=${company}`, {
        authType: 'Bearer',
        customTimeslot: customTimeslot ? customTimeslot : null,
    });

export const CheckCart = (requestBody) => {
    return httpPost('/v6/menus/check-cart', requestBody, {
        authType: 'Bearer',
    });
};

export const GetContentCards = () => {
    return httpGet('/v2/cms/content-cards', { authType: 'Bearer' });
};
