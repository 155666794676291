import React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { stripRepeatedCommas } from '../../../../services';
import ChangeButton from '../ChangeButton';

const base = 'shopping-cart-address';

@inject('deliveryAddressStore', 'addressAutocompleteStore', 'cartStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const store = this.props.deliveryAddressStore;
        const address = store.getSelected();

        setTimeout(() => {
            const popupOpen = document.getElementsByClassName(
                'shopping-cart-address__popup-type-address'
            );
            if (!address && popupOpen && popupOpen.length === 0) {
                store.showingPopup = store.POPUP_TYPING;
            }
        }, 100);

        var ua = navigator.userAgent,
            iOS = /iPad|iPhone|iPod/.test(ua);
        if (iOS) {
            if (store.showingPopup !== '') {
                document.body.classList.add('modal-ios-fix');
            }
            reaction(
                () => store.showingPopup,
                () => {
                    store.showingPopup === ''
                        ? document.body.classList.remove('modal-ios-fix')
                        : document.body.classList.add('modal-ios-fix');
                }
            );
        }
    }

    handleNavigateBack = () => {
        const { addressAutocompleteStore } = this.props;
        const store = this.props.deliveryAddressStore;

        if (store.showingPopup === store.POPUP_TYPING) {
            addressAutocompleteStore.getPlacePredictions(null);
            store.showMap = false;
        } else if (store.showingPopup === store.POPUP_ADJUSTING) {
            store.showingPopup = store.POPUP_TYPING;
        } else if (store.showingPopup === store.POPUP_NOTE) {
            store.showingPopup = store.POPUP_ADJUSTING;
        } else if (
            store.showingPopup === store.POPUP_OFFICE_EXPLAINED ||
            store.showingPopup === store.POPUP_HOUSE_EXPLAINED
        ) {
            store.showingPopup = store.POPUP_NOTE;
        }
    };

    render() {
        const { deliveryAddressStore, cartStore, date } = this.props;

        const dateOrder = cartStore.cart.find(
            (dateOrder) => dateOrder.date === date
        );
        const addressId = dateOrder ? dateOrder.address_id : '';
        const addressInStore = deliveryAddressStore.addresses.find(
            (address) => address.id === addressId
        );
        const address = addressInStore
            ? addressInStore
            : deliveryAddressStore.selectedAddress;

        if (address) {
            return (
                <div>
                    <p className="shopping-cart__section-title text--bolder">
                        {intl.get('shoppingCart.deliveryAddress.header')}
                    </p>
                    <div
                        className={`${base}__item--show-view display--flex flex-align-items-center`}
                    >
                        <div className="flex-1-only mrm">
                            <p className="lh--150">
                                {stripRepeatedCommas(
                                    address.formatted_address || address.name
                                )}
                            </p>
                        </div>
                        <div className="flex-none">
                            <ChangeButton
                                onClick={::deliveryAddressStore.goToView(
                                    deliveryAddressStore.LIST_VIEW
                                )}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <p className="text--bolder mbl shopping-cart__section-title">
                    {intl.get('shoppingCart.deliveryAddress.header')}
                </p>
                <button
                    className={`${base}__btn--first-add-address button button--bigger-on-mobile button--warning`}
                    onClick={() =>
                        deliveryAddressStore.showPopup(
                            deliveryAddressStore.POPUP_TYPING
                        )
                    }
                >
                    {intl.get('shoppingCart.deliveryAddress.cta')}
                </button>
                {/*<AddressPopup />*/}
            </div>
        );
    }
}
