import { observable, action, computed } from 'mobx';
import { uniqBy } from 'lodash';
import { getCountryCode, parsePhoneNumber } from '../services/phoneParser';
import { ValidationResult } from '../services/validator';
import profileAccountDetailValidator from './validators/profileAccountDetailValidator';
import { RESOURCE, VERIFICATION_STATUS } from '../services/types';
import PhoneVerificationStore from './phoneVerificationStore';
import { services } from '../services/index';
import { COUNTRY_CODES } from '../services/constants';

export default class extends PhoneVerificationStore {
    @observable message = '';

    @observable firstName = '';
    @observable lastName = '';
    @observable email = '';
    @observable countryCode = COUNTRY_CODES[services.getParam('country')];
    @observable phoneNumber = '';
    @observable verificationCode = '';
    @observable username = '';
    @observable notificationSettings = [];

    @observable.ref notificationTypes = ['promotion', 'sms', 'email'];
    @observable.ref validationResult = new ValidationResult();
    @observable.ref userFetcher = RESOURCE.Initial;
    @observable.ref userUpdater = RESOURCE.Initial;

    @computed get mobileNumber() {
        return this.countryCode + this.phoneNumber;
    }

    @computed get alias() {
        if (RESOURCE.Success.is(this.userFetcher)) {
            const user = this.userFetcher.data;

            return (user.firstname || '') + ' ' + (user.lastname || '');
        }

        return '';
    }

    @action updateModel(e) {
        const field = e.currentTarget.name;
        const value = e.currentTarget.value;

        this[field] = value;

        if (field === 'phoneNumber' || field === 'countryCode') {
            this.verifier = VERIFICATION_STATUS.Initial;
            this.allowResendCode();
            this.validationResult = new ValidationResult();
        }

        if (field === 'phoneNumber') {
            this.removeCountryCodeFromPhoneNumber();
        }
    }

    removeCountryCodeFromPhoneNumber = () => {
        const parsedPhoneNumber = parsePhoneNumber(
            this.phoneNumber,
            this.countryCode
        );
        if (parsedPhoneNumber) {
            this.phoneNumber = parsedPhoneNumber.phoneNumber;
            this.countryCode = '+' + parsedPhoneNumber.countryCode;
        }
    };

    @action fetchUserInfo() {
        this.userFetcher = RESOURCE.Loading;

        services.api.GetUserWithMemoizer.run()
            .then((user) => {
                this.username = user['username'];
                this.email = user['email'];
                this.firstName = user['firstname'];
                this.lastName = user['lastname'];

                this.countryCode = getCountryCode(
                    services.getParam('country') || 'my'
                );
                this.phoneNumber = user['mobileno'];
                this.removeCountryCodeFromPhoneNumber();
                this.userFetcher = RESOURCE.Success(user);
            })
            .catch((response) => {
                this.userFetcher = RESOURCE.Failure(response.message, response);
            });
    }

    @action updateUser() {
        this.userUpdater = RESOURCE.Loading;

        services.api
            .UpdateUser({
                email: this.email,
                firstname: this.firstName,
                lastname: this.lastName,
                mobileno: this.countryCode + this.phoneNumber,
            })
            .then((user) => {
                this.userFetcher = RESOURCE.Success(user);
                services.bannerEvents.showSuccess('Updated successfully');
            })
            .catch(::this.handlePhoneUpdate)
            .then(() => {
                this.userUpdater = RESOURCE.Initial;
            });
    }

    @action verifyPhone = (e) => {
        e.preventDefault();

        this.validationResult = profileAccountDetailValidator
            .validate(this)
            .toSingleErrorMode();
        if (this.validationResult.pass()) {
            services.api
                .VerifyWithMobileNumber(
                    this.mobileNumber,
                    this.verificationCode,
                    false
                )
                .then(::this.updateUser)
                .catch(() => {
                    services.bannerEvents.showError(
                        'Invalid Verification Code or Session has expired'
                    );
                });
        }
    };

    @action
    getNotificationSettings = () => {
        services.api
            .GetNotificationSettings()
            .then((res) => {
                this.notificationSettings = RESOURCE.Success(res.settings);
            })
            .catch((err) => RESOURCE.Failure(err.message, err));
    };

    @action
    setNotificationSettings = (notificationType, value) => {
        services.api
            .SetNotificationSettings(notificationType, value)
            .then((res) => {
                const updatedNotificationSettings = uniqBy(
                    [...res.settings, ...this.notificationSettings.data],
                    'notification_type'
                );
                this.notificationSettings = RESOURCE.Success(
                    updatedNotificationSettings
                );
            })
            .catch((err) => RESOURCE.Failure(err.message, err));
    };
}
