import { reaction, when } from 'mobx';

export default (billPlzStore, headerStore) => {
    /*
     * Every time the payment status change:
     * ----- If status is COMPLETE, then loadUser to display Confirm screen, then clear the cart
     * ----- If status is CANCELLED or FAILED, show the back link and show error
     */
    reaction(
        () => billPlzStore.status,
        (status) => {
            const transactionStatus = status['transaction_status'];

            if (transactionStatus === billPlzStore.COMPLETE) {
                billPlzStore.loadUser();
                billPlzStore.clearCart();
            }

            if (
                transactionStatus === billPlzStore.CANCELLED ||
                transactionStatus === billPlzStore.FAILED
            ) {
                headerStore.setShowBackLink(true);
                billPlzStore.notifyError();
            }
        },
        { fireImmediately: true }
    );

    /*
     * Only once when payment status is pending, we
     * ----- Hide both links on header so that user can go nowhere
     * ----- Begin polling until status becomes either COMPLETE, CANCELLED or FAILED
     */
    when(
        () =>
            billPlzStore.status['transaction_status'] === billPlzStore.PENDING,
        () => {
            headerStore.setShowBackLink(false);
            headerStore.setShowNextLink(false);
            billPlzStore.startPolling();
        },
        { fireImmediately: true }
    );
};
