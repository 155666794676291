import config from '../../configs/parameters';

export const InitFacebook = () => {
    if (typeof FB !== 'undefined') {
        window.FB.init({
            appId: config.facebookAppId,
            cookie: true,
            xfbml: true,
            version: 'v2.7',
            status: true,
        });
    }
};

export const HasLoggedInFacebook = () => {
    return new Promise((resolve, reject) => {
        window.FB.getLoginStatus((response) => {
            return response.status === 'connected' ? resolve() : reject();
        });
    });
};

export const LogInFacebook = () => {
    return new Promise((resolve) => {
        window.FB.login(resolve, { scope: 'email' });
    });
};

export const LogOutFacebook = () => {
    return new Promise((resolve) => {
        window.FB.logout(resolve);
    });
};

export const GetFacebookProfile = () => {
    return new Promise((resolve, reject) => {
        window.FB.api('/me?fields=email,first_name,last_name', (profile) => {
            return profile.email ? resolve(profile) : reject();
        });
    });
};
