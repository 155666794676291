import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import SwitchButton from 'lyef-switch-button';
import BusyBox from './BusyBox';
import { RESOURCE } from '../../../services/types';

const base = 'profile';

@inject('profileAccountDetailStore')
@observer
export default class extends React.Component {
    componentDidMount = () => {
        const { profileAccountDetailStore } = this.props;
        profileAccountDetailStore.getNotificationSettings();
    };

    handleToggleSwitch = (e, notificationType) => {
        const {
            profileAccountDetailStore: { setNotificationSettings },
        } = this.props;
        setNotificationSettings(notificationType, e.target.checked);
    };

    render() {
        const {
            profileAccountDetailStore: {
                notificationSettings,
                notificationTypes,
            },
        } = this.props;

        if (RESOURCE.Success.is(notificationSettings) === false) {
            return (
                <BusyBox
                    headerText={intl.get('profile.userPermissions.header')}
                />
            );
        }

        const isEnabled = (notificationType) => {
            return notificationSettings.data.find(
                (type) =>
                    type.notification_type.toLowerCase() === notificationType
            ).enabled;
        };

        return (
            <div className={`${base}__box`}>
                <div className={`${base}__box-header`}>
                    {intl.get('profile.userPermissions.header')}
                </div>
                <div className={`${base}__box-body p0 display--none-if-empty`}>
                    <p className={`${base}__box-subheader text--bold lh--125`}>
                        {intl.get(`profile.userPermissions.subheader`)}
                    </p>
                    {notificationTypes.map((notificationType) => (
                        <div
                            className={`${base}__notification-item display--flex flex-align-items-center justify--space-between`}
                        >
                            <p>
                                {intl.get(
                                    `profile.userPermissions.${notificationType}`
                                )}
                            </p>
                            <SwitchButton
                                id={`switch-${notificationType}-notifications`}
                                isChecked={isEnabled(notificationType)}
                                action={(e) =>
                                    this.handleToggleSwitch(e, notificationType)
                                }
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
