import request from 'superagent';
import pick from 'lodash/pick';
// import { getCurrentRequestContext } from 'react-server';
import prefixRequest from 'superagent-prefix';
import parameters from '../configs/parameters'; //eslint-disable-line import/no-unresolved
import { services } from '../services/index';
import { UserAddressSettings } from './api/userAddressServices';
import { getActiveTimeslotObj } from './timeslot';
import { rewardToApply } from './rewards';

const prefix = prefixRequest(parameters['services']);

export const handleResponse =
    (resolver, rejecter, attachRequestInfo) => (error, response) => {
        if (!response) {
            // Usually network exceptions (404, 403...)
            if (typeof window !== 'undefined') {
                // On the client, client will redirect to a page asking user to reload previous page
                document.dispatchEvent(
                    new CustomEvent('serverNotCommunicating')
                );
                rejecter(error);
            } else {
                // On the server, AJAX is only used on RootElement.
                // In this case, just reject will cause the page to render blank page, which is enough
                rejecter(error);
            }
        } else {
            // Business exceptions
            if (error || response.ok === false) {
                if (attachRequestInfo) {
                    const responseBody = Object.assign({}, response.body, {
                        $$xhr: pick(response, attachRequestInfo),
                    });

                    rejecter(responseBody);
                }
                rejecter(response.body || response.error);
            } else {
                // Some request may needs more than just response body. For example: status code, network error...
                // attachRequestInfo is an array containing which fields will be included in the body
                // the response body will have an extra $$xhr property containing those fields
                if (attachRequestInfo) {
                    const responseBody = Object.assign({}, response.body, {
                        $$xhr: pick(response, attachRequestInfo),
                    });

                    resolver(responseBody);
                } else {
                    resolver(response.body);
                }
            }
        }
    };

const getCookie = (cookieName, isomorphic) => {
    // if (isomorphic) {
    //     if (typeof window === 'undefined') {
    //         const currentRequest = getCurrentRequestContext().getServerStash().req;
    //         return currentRequest.cookies[cookieName];
    //     }
    //     else {
    //         return services.api.AccessTokenStorage.getToken();
    //     }
    // }

    return services.api.AccessTokenStorage.getToken();
};

const getAuthorizationHeader = ({
    authType,
    accessToken,
    isomorphic,
    bearerToken,
}) => {
    if (bearerToken) {
        return `Bearer ${bearerToken}`;
    }
    if (authType === 'Basic') {
        return `Basic ${parameters['basicAuth']}`;
    } else if (authType === 'Token' && accessToken) {
        return `Bearer ${accessToken}`;
    } else {
        // Bearer
        const cookie = getCookie('Authorization', isomorphic);

        if (cookie) {
            return `Bearer ${cookie}`;
        }

        return `Basic ${parameters['basicAuth']}`;
    }
};

const getBrandHeader = () => {
    return services.getParam('brand');
};

const getCityHeader = () => {
    return services.getParam('city');
};

const getLanguageHeader = () => {
    return localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
};

const getDeviceIdHeader = () => {
    return localStorage.getItem('device_id');
};

const getAddressIdHeader = (authHeader) => {
    const currentAddress = UserAddressSettings.getCurrentAddress();
    return authHeader.includes('Bearer') && currentAddress && currentAddress.id;
};

const getTimeslotIdHeader = () => {
    return getActiveTimeslotObj() ? getActiveTimeslotObj().id : null;
};

const getLatitudeHeader = (authHeader, latitude) => {
    if (latitude) return latitude;

    const currentAddress = UserAddressSettings.getCurrentAddress();
    return (
        authHeader.includes('Basic') &&
        currentAddress &&
        currentAddress.latitude
    );
};

const getLongitudeHeader = (authHeader, longitude) => {
    if (longitude) return longitude;

    const currentAddress = UserAddressSettings.getCurrentAddress();
    return (
        authHeader.includes('Basic') &&
        currentAddress &&
        currentAddress.longitude
    );
};

const getRewardIdHeader = () => {
    return rewardToApply();
};

const addHeaders = ({
    requestObject,
    bearerToken,
    url,
    authType,
    contentType,
    accessToken,
    isomorphic,
    json,
    additionalHeaders,
    customTimeslot,
    customTimeslotId,
    noTimeslotId,
    customAddress,
    longitude,
    latitude,
}) => {
    if (prefix(requestObject).url.includes(services.getParam('services'))) {
        const authHeader = getAuthorizationHeader({
            authType,
            accessToken,
            isomorphic,
            bearerToken,
        });
        const brandHeader = getBrandHeader();
        const cityHeader = getCityHeader();
        const languageHeader = getLanguageHeader();
        const deviceIdHeader = getDeviceIdHeader();
        const addressIdHeader = getAddressIdHeader(authHeader);
        const timeSlotIdHeader = getTimeslotIdHeader();
        const latitudeHeader = getLatitudeHeader(authHeader, latitude);
        const longitudeHeader = getLongitudeHeader(authHeader, longitude);
        const rewardIdHeader = getRewardIdHeader();

        if (authHeader) {
            requestObject.set('Authorization', authHeader);
        }

        if (brandHeader) {
            requestObject.set('Brand', brandHeader);
        }

        if (cityHeader) {
            requestObject.set('City', cityHeader);
        }

        if (languageHeader) {
            requestObject.set('Accept-Language', languageHeader);
        }

        if (deviceIdHeader) {
            requestObject.set('X-Device-Id', deviceIdHeader);
        }

        if (customAddress) {
            requestObject.set('X-Address-Id', customAddress);
        } else {
            if (addressIdHeader) {
                requestObject.set('X-Address-Id', addressIdHeader);
            }
        }

        if (customTimeslotId) {
            requestObject.set('X-Time-Slot-Id', customTimeslotId);
        } else {
            if (!noTimeslotId) {
                if (timeSlotIdHeader) {
                    requestObject.set('X-Time-Slot-Id', timeSlotIdHeader);
                }
            }
        }

        if (latitudeHeader) {
            requestObject.set('X-Latitude', latitudeHeader);
        }

        if (longitudeHeader) {
            requestObject.set('X-Longitude', longitudeHeader);
        }

        if (rewardIdHeader) {
            requestObject.set('X-Reward-Id', rewardIdHeader);
        }

        if (json) {
            requestObject.set('Content-Type', 'application/json;charset=UTF-8');
        }

        if (additionalHeaders) {
            Object.keys(additionalHeaders).forEach((key) => {
                requestObject.set(key, additionalHeaders[key]);
            });
        }
    }
};

export const httpGet = (
    url,
    {
        authType = 'Basic',
        accessToken = '',
        isomorphic = false,
        attachRequestInfo = false,
        additionalHeaders = {},
        customTimeslotId = null,
        json = true,
        noTimeslotId = false,
        customAddress = null,
        latitude = null,
        longitude = null,
        bearerToken = null,
    } = {}
) =>
    new Promise((resolve, reject) => {
        const requestObject = request.get(url).use(prefix);

        addHeaders({
            requestObject,
            url,
            authType,
            accessToken,
            isomorphic,
            json,
            additionalHeaders,
            customTimeslotId,
            noTimeslotId,
            customAddress,
            ...(longitude && latitude && { longitude, latitude }),
            bearerToken,
        });

        requestObject.end(handleResponse(resolve, reject, attachRequestInfo));
    });

export const httpPost = async (
    url,
    body,
    {
        authType = 'Basic',
        accessToken = '',
        isomorphic = false,
        attachRequestInfo = false,
        json = false,
        additionalHeaders = {},
        bearerToken = null,
    } = {}
) =>
    await new Promise((resolve, reject) => {
        const requestObject = request.post(url).use(prefix).send(body);

        addHeaders({
            requestObject,
            url,
            authType,
            accessToken,
            isomorphic,
            json,
            additionalHeaders,
            bearerToken,
        });

        requestObject.end(handleResponse(resolve, reject, attachRequestInfo));
    });

export const httpSubmit = (
    url,
    body,
    {
        authType = 'Basic',
        accessToken = '',
        isomorphic = false,
        attachRequestInfo = false,
    } = {}
) =>
    new Promise((resolve, reject) => {
        const requestObject = request
            .post(url)
            .type('form')
            .use(prefix)
            .send(body);

        addHeaders({ requestObject, url, authType, accessToken, isomorphic });

        requestObject.end(handleResponse(resolve, reject, attachRequestInfo));
    });

export const httpPut = (
    url,
    body,
    {
        authType = 'Basic',
        accessToken = '',
        isomorphic = false,
        attachRequestInfo = false,
    } = {}
) =>
    new Promise((resolve, reject) => {
        const requestObject = request.put(url).use(prefix).send(body);

        addHeaders({ requestObject, url, authType, accessToken, isomorphic });

        requestObject.end(handleResponse(resolve, reject, attachRequestInfo));
    });

export const httpDelete = (
    url,
    {
        authType = 'Basic',
        accessToken = '',
        isomorphic = false,
        attachRequestInfo = false,
    } = {}
) =>
    new Promise((resolve, reject) => {
        const requestObject = request.delete(url).use(prefix);

        addHeaders({ requestObject, url, authType, accessToken, isomorphic });

        requestObject.end(handleResponse(resolve, reject, attachRequestInfo));
    });

export const httpPatch = (
    url,
    body,
    {
        authType = 'Basic',
        accessToken = '',
        isomorphic = false,
        attachRequestInfo = false,
    } = {}
) =>
    new Promise((resolve, reject) => {
        const requestObject = request.patch(url).use(prefix).send(body);

        addHeaders({ requestObject, url, authType, accessToken, isomorphic });

        requestObject.end(handleResponse(resolve, reject, attachRequestInfo));
    });
