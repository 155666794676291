import React from 'react';
import { inject, observer } from 'mobx-react';
import LoginPopup from '../../core/LoginPopup';
import ShoppingCartView from './ShoppingCartView';
import CheckoutView from './CheckoutView';

import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import { when, reaction } from 'mobx';
import { autoAssignedCode } from '../../../services/autoAssignDiscount';
import BoostPopup from '../../core/BoostPopup';
import SelectPopup from '../../core/selectPopup/SelectPopup';
import HalalPopup from '../../core/HalalPopup';

@inject(
    'selectPopupStore',
    'menuListStore',
    'headerStore',
    'rewardsStore',
    'userStore',
    'store',
    'loginPopupStore',
    'cartViewStore',
    'cartStore',
    'deliveryAddressStore'
)
@observer
export default class extends React.Component {
    componentDidMount() {
        const {
            userStore,
            rewardsStore,
            menuListStore,
            loginPopupStore,
            cartStore,
            deliveryAddressStore,
        } = this.props;

        loginPopupStore.checkUser();
        this.isSmallScreen =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches === true;

        when(
            () => userStore.user.data && deliveryAddressStore.selectedAddress,
            () => {
                menuListStore.getMenuDates({ fromCheckout: true });

                cartStore.cart.forEach((delivery) => {
                    menuListStore.getMenuForEachDelivery({
                        startDateString: delivery.date,
                        endDateString: delivery.date,
                        customTimeslotId: delivery.time_slot_id,
                        customAddress: delivery.address_id,
                    });
                });
            }
        );

        when(
            () => userStore.isLoggedIn,
            () => {
                if (autoAssignedCode) {
                    rewardsStore.code = autoAssignedCode;
                }
            }
        );

        reaction(
            () => rewardsStore.code,
            (code, reaction) => {
                if (autoAssignedCode) {
                    rewardsStore.redeemCode();
                }
                reaction.dispose();
            }
        );

        if (autoAssignedCode) {
            when(
                () => rewardsStore.result,
                () => cartStore.updatePreliminaryOrderSummary()
            );
        } else {
            cartStore.updatePreliminaryOrderSummary();
        }

        cartStore.sendCartDataToDataLayer();
    }

    render() {
        const {
            cartViewStore,
            store,
            headerStore,
            selectPopupStore,
            userStore,
        } = this.props;
        const view = cartViewStore.getView();
        const { showHalalPopup, setHideHalalPopup } = userStore;
        return (
            <div>
                {store.openBoostPopup && <BoostPopup />}
                {selectPopupStore.show && <SelectPopup />}
                {this.props.loginPopupStore.isShowing ? (
                    <LoginPopup />
                ) : view === cartViewStore.STEP_1 ? (
                    <ShoppingCartView />
                ) : (
                    <CheckoutView />
                )}
                {showHalalPopup && this.isSmallScreen && (
                    <HalalPopup
                        showModal={showHalalPopup}
                        handleClose={setHideHalalPopup}
                    />
                )}
            </div>
        );
    }
}
