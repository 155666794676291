import { Maybe } from 'monet';

/**
 * Represent the result of a validation. This object contains a dictionary of error and a boolean indicate whether
 * there is an error or not. The dictionary is of map<string, list<string>> type
 * A ValidationResult is usually the return value of Validator.validate() method
 */
export class ValidationResult {
    /**
     * Construct a ValidationResult object.
     * @constructor
     * @param {String} errorMap The dictionary of error
     * @param {Boolean} hasError Whether or not an error has occured
     */
    constructor(errorMap = {}, hasError = false) {
        this.errorMap = errorMap;
        this.hasError = hasError;
    }

    /**
     * Intentionally add an error, for display purpose
     * @param {String} field The name of the field
     * @param {String} message The error message
     * @returns {ValidationResult} Self
     */
    addError(field, message) {
        this.errorMap[field] = []
            .concat(this.errorMap[field] || [])
            .concat(message);

        return this;
    }

    /**
     * Get any first error of the validation result.
     * @returns {String} First error message from whichever fields appears first in the dictionary
     */
    getFirstError() {
        return Maybe.fromNull(Object.keys(this.errorMap)[0])
            .map((firstField) => this.errorMap[firstField])
            .map((errorList) => errorList[0])
            .orSome('');
    }

    /**
     * Get first error of a specific field
     * @param {String} field The name of the form field
     * @returns {String} First error message of a field
     */
    getFirstErrorOf(field) {
        return Maybe.fromNull(this.errorMap[field])
            .map((errorList) => errorList[0])
            .orSome('');
    }

    /**
     * A helper function that will return "input--error" in case the field as an error, and empty otherwise
     * @param {String} field The name of the form field
     * @returns {String} First error message of a field
     */
    getInputErrorClassOf(field) {
        const hasError = Boolean(this.getFirstErrorOf(field));

        if (hasError) {
            return 'input--error';
        }

        return '';
    }

    /**
     * Get all error messages from all fields
     * @returns {String[]} A list of all error messages.
     */
    getAllErrorsAsList() {
        return Object.keys(this.errorMap).reduce((list, field) => {
            return list.concat(this.errorMap[field]);
        }, []);
    }

    /**
     * Indicate whether or not we have passed validation
     * @returns {Boolean} True if has error, False if otherwise
     */
    pass() {
        return this.hasError === false;
    }

    /**
     * In single error mode, only keeps the first error :)
     * @returns {ValidationResult} new ValidationResult with the only first error
     */
    toSingleErrorMode() {
        const fields = Object.keys(this.errorMap);

        if (fields[0]) {
            const newErrorMap = {
                [fields[0]]: this.errorMap[fields[0]],
            };

            return new ValidationResult(newErrorMap, this.hasError);
        }

        return new ValidationResult();
    }
}

/**
 * A validator contains a dictionary of rules. The key of the dictionary is form field name, and the value
 * is the list of predicate functions which has to return either True or False
 */
export default class Validator {
    /**
     * Construct a validator object. Initialize this.validators property as an empty map.
     * @constructor
     */
    constructor() {
        this.validators = {};
    }

    /**
     * Add a validation function on a field, and associate an error message in case the function on
     * that field returns false
     * @param {String} field The name of the form field
     * @param {Function} validateFn Any function that can accept any arguments but have to return true/false.
     *                              validateFn has a this object that is bound to the form model
     * @param {String} errorMessage An error message in case validateFn returns falsy
     * @returns {Validator} Return the same instance of Validator so that you can chain multiple adds
     */
    add(field, validateFn, errorMessage) {
        if (this.validators.hasOwnProperty(field) === false) {
            // eslint-disable-line no-prototype-builtins
            this.validators[field] = [];
        }

        this.validators[field].push({ validateFn, errorMessage });

        return this;
    }

    /**
     * Validate an object against this.validators. The algorithm is simple: loop through each fields,
     * for each field, loop through each predicate function, and check against the objectToValidate.
     * @param {Object} objectToValidate The model representing a form. It is actually an object with
     *                                  keys being form field name, and value being form field value
     * @returns {ValidationResult} Returns a ValidationResult that contains the error list
     */
    validate(objectToValidate) {
        let hasError = false;
        const errorMap = {};
        const fields = Object.keys(this.validators);

        fields.forEach((field) => {
            const validatorsForField = this.validators[field];

            validatorsForField.forEach((validator) => {
                const result = validator.validateFn.call(
                    objectToValidate,
                    objectToValidate
                );
                if (result === false) {
                    let errorMessage = validator.errorMessage;
                    if (typeof validator.errorMessage === 'function') {
                        errorMessage = validator.errorMessage.call(
                            objectToValidate,
                            objectToValidate
                        );
                    }

                    errorMap[field] = (errorMap[field] || []).concat(
                        errorMessage
                    );
                    hasError = true;
                }
            });
        });

        return new ValidationResult(errorMap, hasError);
    }
}
