import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { services } from '../../../services/index';
import { RESOURCE } from '../../../services/types';
import template from 'lodash/template';
import HalalPreview from './HalalPreview';
import brandLogo from '../brandLogo/MasterBrandLogo';
import CartIcon from '../CartIcon';
import LoginCTA from '../LoginCTA';
import HamburgerIcon from '../sidebars/HamburgerIcon';
import WalletButton from '../WalletButton';
import { GetCity, cities } from '../../../services/city';
import intl from 'react-intl-universal';
import {
    MEDIUM_SCREEN_MEDIA,
    SMALL_SCREEN_MEDIA,
} from '../../../services/constants';
import Cookies from 'js-cookie';
import qs from 'query-string';

@inject('menuHeaderStore', 'loginPopupStore', 'rewardsStore', 'userStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const { hideBranchBanner, rewardsStore } = this.props;
        const params = qs.parse(window.location.search);
        rewardsStore.getRules();
        this.handleSetHeaderHeight();
        window.addEventListener('resize', this.handleSetHeaderHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleSetHeaderHeight);
    }

    handleSetHeaderHeight = () => {
        const { menuHeaderStore } = this.props;
        const headerHeight =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches ||
            window.matchMedia(MEDIUM_SCREEN_MEDIA).matches
                ? document.getElementById('menuHeader').offsetHeight
                : 90;

        menuHeaderStore.setHeaderHeight(headerHeight);
    };

    handleRewardDisplay = (reward) => {
        const { userStore } = this.props;
        const currency = services.getParam('currency');
        return currency + reward;
    };

    renderRulesDetail() {
        const { rewardsStore, whiteIcons } = this.props;
        const base = 'menu-header';
        const linkHoverColor = whiteIcons
            ? 'dmk-link--secondary-hover'
            : 'dmk-link--primary-hover';

        const referringAmount =
            rewardsStore.rules === RESOURCE.Loading ||
            rewardsStore.rules === RESOURCE.Initial ||
            !rewardsStore.user
                ? 'Rewards'
                : this.handleRewardDisplay(
                      rewardsStore.rules.data.referring_user_amount
                  );
        return (
            <Link
                className={`${base}__desktop-link ${linkHoverColor} link--no-underline mrl hide--if-small`}
                to="/rewards"
                id="select-referral-rewards"
            >
                {template(intl.get('headers.menuHeader.getReferralRewards'))({
                    referringAmount,
                })}
            </Link>
        );
    }

    render() {
        const base = 'menu-header';
        const city = GetCity();
        const { menuHeaderStore, customClasses, whiteIcons, hideBranchBanner } =
            this.props;
        const rewardTextElement = this.renderRulesDetail();
        const linkHoverColor = whiteIcons
            ? 'dmk-link--secondary-hover'
            : 'dmk-link--primary-hover';
        const showHalal = menuHeaderStore.showHalal;

        return (
            <div
                className={`${base} ${customClasses}`}
                id="menuHeader"
                style={{ marginTop: menuHeaderStore.branchBannerHeight + 'px' }}
            >
                <div
                    className={`${base}__container display--flex flex-align-items-center container p0--if-small`}
                >
                    <div className={`${base}__hamburger-container`}>
                        <HamburgerIcon
                            whiteIcons={whiteIcons}
                            hideBranchBanner={hideBranchBanner}
                        />
                    </div>
                    <Fragment>
                        <div className={`${base}__logo`}>
                            <a href="/menu">
                                <div className={`${base}__onboarding-logo`}>
                                    <img style={{ objectFit: 'contain' }} src="/pop-icon-logo.png" width="70px" height="60px" />
                                </div>


                            </a>
                        </div>
                        <a style={{textDecoration: "none"}} href="https://wa.me/601154193392?text=Hi%2C%20I%20would%20like%20to%20place%20an%20order" target="_blank" >
                            <div className={`${base}__whatsapp-order-button`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none">
                                    <path d="M19.05 4.91C18.1332 3.98392 17.0412 3.24967 15.8377 2.75005C14.6341 2.25043 13.3432 1.99546 12.04 2C6.58005 2 2.13005 6.45 2.13005 11.91C2.13005 13.66 2.59005 15.36 3.45005 16.86L2.05005 22L7.30005 20.62C8.75005 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.9501 17.38 21.9501 11.92C21.9501 9.27 20.92 6.78 19.05 4.91ZM12.04 20.15C10.56 20.15 9.11005 19.75 7.84005 19L7.54005 18.82L4.42005 19.64L5.25005 16.6L5.05005 16.29C4.2278 14.977 3.79119 13.4592 3.79005 11.91C3.79005 7.37 7.49005 3.67 12.03 3.67C14.23 3.67 16.3 4.53 17.85 6.09C18.6175 6.85396 19.2257 7.76266 19.6394 8.76342C20.0531 9.76419 20.2641 10.8371 20.26 11.92C20.2801 16.46 16.58 20.15 12.04 20.15ZM16.56 13.99C16.31 13.87 15.09 13.27 14.87 13.18C14.64 13.1 14.48 13.06 14.31 13.3C14.14 13.55 13.67 14.11 13.53 14.27C13.39 14.44 13.24 14.46 12.99 14.33C12.74 14.21 11.94 13.94 11 13.1C10.26 12.44 9.77005 11.63 9.62005 11.38C9.48005 11.13 9.60005 11 9.73005 10.87C9.84005 10.76 9.98005 10.58 10.1 10.44C10.22 10.3 10.27 10.19 10.35 10.03C10.43 9.86 10.39 9.72 10.33 9.6C10.27 9.48 9.77005 8.26 9.57005 7.76C9.37005 7.28 9.16005 7.34 9.01005 7.33H8.53005C8.36005 7.33 8.10005 7.39 7.87005 7.64C7.65005 7.89 7.01005 8.49 7.01005 9.71C7.01005 10.93 7.90005 12.11 8.02005 12.27C8.14005 12.44 9.77005 14.94 12.25 16.01C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.69 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.03 14.27C16.96 14.16 16.81 14.11 16.56 13.99Z" fill="white"/>
                                </svg>
                                <p style={{display: "inline"}}>WhatsApp</p>
                                <p> Order Pickup & Delivery</p>
                            </div>
                        </a>
                        <div
                            className={`${base}__right-container display--flex text--right`}
                            id="menuHeaderRight"
                        >
                            <a
                                className={`${base}__desktop-link ${linkHoverColor} link--no-underline mrl hide--if-small`}
                                href="/menu"
                                id="select-menu"
                            >
                                {intl.get('headers.menuHeader.menu')}
                            </a>
                            <Link
                                className={`${base}__desktop-link ${linkHoverColor} link--no-underline mrl hide--if-small`}
                                to="/delivery-area"
                                id="select-delivery-area"
                            >
                                {intl.get('headers.menuHeader.deliveryArea')}
                            </Link>
                            <Link
                                className={`${base}__desktop-link ${linkHoverColor} link--no-underline mrl hide--if-small`}
                                to="/about"
                                id="select-about"
                            >
                                {intl.get('headers.menuHeader.about')}
                            </Link>
                            <Link
                                className={`${base}__desktop-link ${linkHoverColor} link--no-underline mrl hide--if-small`}
                                to="/careers"
                                id="select-careers"
                            >
                                {intl.get('headers.menuHeader.careers')}
                            </Link>
                            {city === cities.kl && rewardTextElement}
                            <Link
                                className={`${base}__desktop-link ${linkHoverColor} link--no-underline mrl hide--if-small`}
                                to="/promotions"
                                id="select-promotions"
                            >
                                {intl.get('headers.menuHeader.promotions')}
                            </Link>
                            {showHalal && (
                                <span
                                    className={`${base}__desktop-link ${linkHoverColor} link--no-underline mrl hide--if-small`}
                                >
                                    <HalalPreview />
                                </span>
                            )}
                            <span className={`hide--if-small`}>
                                <WalletButton />
                            </span>
                            <span
                                className={`${base}__desktop-link ${linkHoverColor} link--no-underline mrl hide--if-small`}
                            >
                                <LoginCTA />
                            </span>
                            <span
                                className={`${base}__cart-icon-container hide--if-small mll`}
                            >
                                <CartIcon whiteIcons={whiteIcons} />
                            </span>
                        </div>
                    </Fragment>
                </div>
                {window.matchMedia(SMALL_SCREEN_MEDIA).matches && <CartIcon />}
            </div>
        );
    }
}
