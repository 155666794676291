import React from 'react';
import request from 'superagent';

export default class Embed extends React.Component {
    static defaultProps = {
        onComplete: () => {},
    };

    constructor(props) {
        super(props);

        this.state = { embedded: '' };
    }

    componentDidMount() {
        request.get(this.props.resource).then((res) => {
            this.setState({ embedded: res.text }, this.props.onComplete);
        });
    }

    render() {
        return (
            <div
                style={{ display: 'none' }}
                dangerouslySetInnerHTML={{ __html: this.state.embedded }}
            />
        );
    }
}
