import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import { services } from '../../../services/index';
import intl from 'react-intl-universal';
import OrSeparator from './OrSeparator';
import { PHONE, SMALL_SCREEN_MEDIA } from '../../../services/constants';
import SubheaderUI from './SubheaderUI';
import ButtonUI from './ButtonUI';
import Cookies from 'js-cookie';
import qs from 'query-string';
import UserAvatar from '../UserAvatar';
import InputUI from '../InputUI';
import { VERIFICATION_STATUS } from '../../../services/types';
import { when } from 'mobx';
import { referralMultiplier } from '../../../services/referrals';
import { currency } from '../../../services';
import { template } from 'lodash';

const base = 'login-popup';

@inject('loginPopupStore', 'phoneVerificationStore')
@observer
export default class extends React.Component {
    state = {
        showFbButton: false,
        showGoogleButton: false,
        showOnlyFacebook: false,
    };

    componentDidMount() {
        const { loginPopupStore } = this.props;
        const params = qs.parse(window.location.search);

        document.addEventListener('keydown', this.onKeyPress);

        const waitForFb = setInterval(() => {
            if (typeof FB !== 'undefined') {
                services.api.InitFacebook();
                clearInterval(waitForFb);
                this.setState({ showFbButton: true });
            }
        }, 100);

        if (params.platform === 'boost' || Cookies.get('boost_user')) {
            this.setState({ showOnlyFacebook: true });
        } else {
            const waitForGoogle = setInterval(() => {
                if (typeof window.google !== 'undefined') {
                    services.api.InitGoogle(
                        'google-button',
                        ::this.handleGoogleClicked
                    );
                    clearInterval(waitForGoogle);
                    this.setState({ showGoogleButton: true });
                }
            }, 100);
        }

        when(
            () => loginPopupStore.validNumber,
            () => this.handleLoginWithMobileNumber()
        );

        document.addEventListener('keydown', this.onKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPress);
        this.props.phoneVerificationStore.validNumber = false;
        this.props.loginPopupStore.validNumber = false;
    }

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleVerifyNumber();
        }
    };

    handleLoginWithMobileNumber = () => {
        const { loginPopupStore } = this.props;
        loginPopupStore.email = '';
        loginPopupStore.showVerifyNumber();
    };

    handleFacebookClicked() {
        const { loginPopupStore } = this.props;

        // We have to explicitly handle FB dialog here because browser would no open Facebook dialog
        // when we use Promise
        window.FB.getLoginStatus((statusResponse) => {
            const { status } = statusResponse;
            if (status === 'connected') {
                window.FB.api(
                    '/me?fields=email,first_name,last_name',
                    (profile) => {
                        return profile.email
                            ? loginPopupStore.handleFacebookSuccess(profile)
                            : loginPopupStore.handleFacebookFailure();
                    }
                );
            } else {
                window.FB.login(
                    (response) => {
                        if (response.authResponse) {
                            window.FB.api(
                                '/me?fields=email,first_name,last_name',
                                (profile) => {
                                    return profile.email
                                        ? loginPopupStore.handleFacebookSuccess(
                                              profile
                                          )
                                        : loginPopupStore.handleFacebookFailure();
                                }
                            );
                        } else {
                            loginPopupStore.handleFacebookFailure();
                        }
                    },
                    { scope: 'email' }
                );
            }
        });
    }

    handleGoogleClicked(profile) {
        const { loginPopupStore } = this.props;

        loginPopupStore.showBusy();
        loginPopupStore.doGoogleSignIn(profile);
    }

    handleChange = (e) => {
        const { loginPopupStore } = this.props;

        loginPopupStore.handleChange(e);
    };

    handleVerifyNumber = () => {
        const { loginPopupStore } = this.props;
        loginPopupStore.checkUsername(PHONE);
    };

    renderMobileSignIn = () => {
        const { loginPopupStore } = this.props;

        return (
            <div className={`${base}__phone-number-container display--flex`}>
                <InputUI
                    name="phoneNumber"
                    mobileNumberInput={true}
                    autoFocus="true"
                    autoComplete="tel"
                    countryCode={loginPopupStore.countryCode}
                    value={loginPopupStore.phoneNumber}
                    containerClass={`${base}__phone-number-input-container`}
                    inputClass={`${base}__input`}
                    placeholder="Phone number"
                    type="tel"
                    required="true"
                    onChange={this.handleChange}
                    errorMessage={loginPopupStore.message}
                />
                <ButtonUI
                    icon={
                        <svg
                            className={`${base}__arrow-icon iconmoon-icon cursor-pointer`}
                        >
                            <use xlinkHref="#icon-arrow" />
                        </svg>
                    }
                    customClasses={`${base}__select-login-cta button--success`}
                    loading={
                        loginPopupStore.verifier ===
                        VERIFICATION_STATUS.CodeRequesting
                    }
                    onClick={this.handleVerifyNumber}
                />
            </div>
        );
    };

    renderFacebookButton() {
        const text = window.matchMedia(SMALL_SCREEN_MEDIA).matches
            ? 'Facebook'
            : intl.get('loginPopup.usernameView.facebookCta');

        return (
            <ButtonUI
                onClick={::this.handleFacebookClicked}
                icon={
                    <svg
                        className={`${base}__social-media-icon iconmoon-icon cursor-pointer`}
                    >
                        <use xlinkHref="#icon-facebook" />
                    </svg>
                }
                text={text}
                customClasses="button--facebook"
                id="fb-button"
            />
        );
    }

    renderGoogleButton() {
        const text = window.matchMedia(SMALL_SCREEN_MEDIA).matches
            ? 'Google'
            : intl.get('loginPopup.usernameView.googleCta');

        return (
            <ButtonUI
                id="google-button"
                customClasses="button--google"
                marginTop="15px"
                marginText="15px"
                icon={
                    <svg
                        className={`${base}__social-media-icon iconmoon-icon cursor-pointer`}
                    >
                        <use xlinkHref="#icon-google" />
                    </svg>
                }
                text={text}
            />
        );
    }

    renderEmailButton = () => {
        const { loginPopupStore } = this.props;
        return (
            <div
                className={`${base}__email-button-container`}
                onClick={loginPopupStore.showEmail}
                id="email-btn"
            >
                <p className="text--bolder cursor-pointer">
                    Continue with email
                </p>
            </div>
        );
    };

    renderHeader = () => {
        const { loginPopupStore } = this.props;
        const referral = loginPopupStore.referral();

        if (referral) {
            const referralDiscountString = referral.rewardIsPercentage
                ? template(
                      intl.get('loginPopup.usernameView.percentageAmount')
                  )({
                      amount: `${
                          referral.rewardUsages || referralMultiplier
                      } x ${referral.reward * 100}`,
                  })
                : template(intl.get('loginPopup.usernameView.creditAmount'))({
                      amount: currency.localCurrency(referral.reward),
                      brandName: services.getParam('brandName'),
                  });

            return (
                <h3 className={`${base}__title`}>
                    {`${template(
                        intl.get('loginPopup.usernameView.referralHeader')
                    )({
                        name: referral.referring_user,
                    })} ${referralDiscountString}`}
                </h3>
            );
        } else if (loginPopupStore.autoApplyDiscount) {
            const referralDiscountString = referral.rewardIsPercentage
                ? `${referral.reward * 100}`
                : `${services.getParam('currency')} ${
                      referral.reward /
                      (referral.rewardUsages || referralMultiplier)
                  }`;
            return (
                <h3 className={`${base}__title`}>
                    Sign up now to get {referralDiscountString} off
                </h3>
            );
        } else if (loginPopupStore.isOfflineCampaign) {
            return (
                <Fragment>
                    <h3 className={`${base}__title`}>
                        Enter your phone number to receive your credits
                    </h3>
                    <p
                        style={{
                            fontSize: '10px',
                            marginBottom: '10px',
                            textAlign: 'left',
                        }}
                    >
                        *By signing up, you agree to the Terms and Conditions
                    </p>
                </Fragment>
            );
        } else if (loginPopupStore.redirectToSelectPage) {
            return (
                <h3 className={`${base}__title`}>
                    {intl.get('loginPopup.selectLoginTypeView.selectCta')}
                </h3>
            );
        } else {
            return (
                <h3 className={`${base}__title`}>
                    {intl.get('loginPopup.selectLoginTypeView.cta')}
                </h3>
            );
        }
    };

    renderSubheader = () => {
        const { loginPopupStore } = this.props;
        const referral = loginPopupStore.referral();

        if (referral) {
            return (
                <SubheaderUI
                    text={`${intl.get('loginPopup.usernameView.signUpNow')}`}
                    customMarginBottom={30}
                />
            );
        }
    };

    render() {
        const { loginPopupStore } = this.props;
        const { showFbButton, showGoogleButton, showOnlyFacebook } = this.state;

        return (
            <div className={base}>
                {/*{this.renderBackLink()}*/}
                <UserAvatar marginBottom={21} />
                {this.renderHeader()}
                {this.renderSubheader()}
                {this.renderMobileSignIn()}
                {!loginPopupStore.isOfflineCampaign && (
                    <Fragment>
                        <OrSeparator />
                        <div
                            className={`${base}__social-buttons-container ${
                                showOnlyFacebook ? 'one-item-grid' : ''
                            }`}
                        >
                            {showFbButton && this.renderFacebookButton()}
                            {showGoogleButton && this.renderGoogleButton()}
                        </div>
                        {this.renderEmailButton()}
                    </Fragment>
                )}
                <div className="hide--if-large show--if-small mbl" />
            </div>
        );
    }
}
