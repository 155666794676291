import { observable, action } from 'mobx';
import get from 'lodash/get';

import { MAYBE } from '../services/types';
import { ValidationResult } from '../services/validator';
import terminalAddressValidator from './validators/terminalAddressValidator';
import { services } from '../services/index';

export default class {
    STEP_INPUT_ADDRESS = 'StepInputAddress';
    STEP_ADJUST_ADDRESS = 'StepAdjustAddress';

    place = null;

    @observable currentStep = 'StepInputAddress';
    @observable newAddress = {
        buildingName: '',
        floor: '',
        homeNumber: '',
        street: '',
        district: '',
        postalCode: '',
        city: '',
        contactPerson: '',
        contactPhoneNumber: '',
        deliveryInstruction: '',
    };
    @observable.ref validationResult = new ValidationResult();
    @observable.ref terminalInfo = MAYBE.None;

    get addressPreview() {
        return get(this.place, ['suggested_address'], null);
    }

    @action fetchTerminalInfo() {
        services.api
            .GetTerminalInfo()
            .then((info) => {
                this.terminalInfo = MAYBE.Some(info);
            })
            .catch((response) => {
                services.bannerEvents.showError(response.message);
            });
    }

    @action updateForm(e) {
        this.newAddress[e.currentTarget.name] = e.currentTarget.value;
    }

    @action confirmAddress() {
        this.validationResult = terminalAddressValidator
            .validate(this.newAddress)
            .toSingleErrorMode();
        if (this.validationResult.pass()) {
            services.api
                .UpdateTerminalInfo(this.getRequestPayload())
                .then(() => {
                    services.bannerEvents.showSuccess('Update successfully');
                })
                .catch((response) => {
                    services.bannerEvents.showError(
                        `Update error: ${response.message}`
                    );
                });
        }
    }

    @action setNewAddress(address) {
        this.newAddress.buildingName = address.buildingName;
        this.newAddress.floor = address.floor;

        this.newAddress.homeNumber = address.homeNumber;
        this.newAddress.street = address.street;

        this.newAddress.postalCode = address.postalCode;
        this.newAddress.district = address.district;
        this.newAddress.city = address.city;
    }

    @action resetNewAddress() {
        for (const key in this.newAddress) {
            this.newAddress[key] = '';
        }
    }

    @action setStep(newStep) {
        this.currentStep = newStep;
    }

    getRequestPayload() {
        const newAddress = this.newAddress;
        const lat = this.place.geometry.location.lat();
        const lng = this.place.geometry.location.lng();
        const street = [newAddress.homeNumber, newAddress.street]
            .filter((s) => s)
            .join(', ');
        const addressObj = {
            additional_info: null,
            address_type: null,
            building_name: newAddress.buildingName,
            city: newAddress.city,
            delivery_instruction: newAddress.deliveryInstruction,
            district: newAddress.district,
            floor: newAddress.floor,
            latitude: lat,
            longitude: lng,
            postal_code_new: newAddress.postalCode,
            state: null,
            street: street,
        };

        return {
            address: addressObj,
            contact_person_name: newAddress.contactPerson,
            contact_person_phone_no: newAddress.contactPhoneNumber,
        };
    }

    setPlace(place) {
        this.place = place;
    }
}
