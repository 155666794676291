import Bacon from 'baconjs';
import { parseUA } from '../index';

export default () => {
    const parseResult = parseUA(window.navigator.userAgent);
    const { device } = parseResult;

    if (device.type === 'tablet' || device.type === 'mobile') {
        return Bacon.never();
    }

    const initialStream$ = Bacon.once({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    return Bacon.fromEvent(window, 'resize')
        .filter(window.pageYOffset < 800)
        .merge(initialStream$)
        .map(() => ({ width: window.innerWidth, height: window.innerHeight }));
};
