import { observable, action } from 'mobx';
import { RESOURCE, MAYBE } from '../services/types';
import { services } from '../services/index';

export default class {
    SHOW_VIEW = 'ShowView';
    ADD_VIEW = 'AddView';
    CREDIT_CARD_VIEW = 'CreditCardView';

    @observable view = 'ShowView';
    @observable.ref pmFetcher = RESOURCE.Initial;
    @observable.ref pmDeleter = RESOURCE.Initial;
    @observable.ref pmBeingDeleted = MAYBE.None;

    @action fetchPaymentMethods() {
        this.pmFetcher = RESOURCE.Loading;

        services.api
            .GetPaymentMethods()
            .then((paymentMethods) => {
                this.pmFetcher = RESOURCE.Success(paymentMethods);
            })
            .catch((response) => {
                this.pmFetcher = RESOURCE.Failure(response.message, response);
            });
    }

    @action deletePaymentMethod(paymentMethod) {
        this.pmDeleter = RESOURCE.Loading;
        this.pmBeingDeleted = MAYBE.Some(paymentMethod);

        services.api
            .DeletePaymentMethod({ id: paymentMethod.id })
            .then((response) => {
                this.pmDeleter = RESOURCE.Success(response);
            })
            .then(::this.fetchPaymentMethods)
            .catch((response) => {
                this.pmDeleter = RESOURCE.Failure(response.message, response);
            })
            .then(() => {
                this.pmBeingDeleted = MAYBE.None;
            });
    }

    @action setView(view) {
        return () => {
            this.view = view;
        };
    }
}
