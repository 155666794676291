import { observable } from 'mobx';

class CartViewStore {
    STEP_1 = 'Step1';
    STEP_2 = 'Step2';

    @observable view = 'Step1';

    setView(view) {
        this.view = view;
    }
    getView() {
        return this.view;
    }
}

const store = new CartViewStore();
export default store;
