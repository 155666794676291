import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('terminalMenuStore')
@inject('terminalCartStore')
@observer
export default class extends React.Component {
    render() {
        const { terminalCartStore, terminalMenuStore } = this.props;
        const itemsQuantityAt =
            terminalCartStore.cartServices.getUniqueItemsAt();

        return (
            <div>
                {itemsQuantityAt.map((item, index) => {
                    if (index === 0) {
                        return <div key={index} style={{ height: 225 }} />;
                    }

                    return <div key={index} style={{ height: 30 }} />;
                })}
            </div>
        );
    }
}
