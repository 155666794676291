import React from 'react';
import { inject, observer } from 'mobx-react';
import { services } from '../../../services/index';

import DiscountCode from '../walletCheckoutPage/DiscountCode';
import PaymentManagement from './PaymentManagement';
import PaymentInProgress from '../../core/PaymentInProgress';
import OrderSummary from './OrderSummary';

import PlaceOrderButton from './PlaceOrderButton';
import CompleteSignUp from '../../core/completeSignUp/Widget';
import EmptyCart from './EmptyCart';
import ProductList from './ProductList';
import ConfirmPayment from './ConfirmPayment';
import OrderInfo from './OrderInfo';
import BankSelector from '../../page/walletCheckoutPage/BankSelector';
import { when } from 'mobx';
import RewardsPopup from './RewardsPopup';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';

const { clevertap } = window;
const base = 'menu-checkout';

@inject('store', 'rewardsPopupStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        const { store } = this.props;

        clevertap.event.push('Checkout opened');

        store.clickedGoToCheckout = false;

        services.api.GetUser().then((user) => this.props.store.setUser(user));

        when(
            () => store.paymentMethod && store.orderId,
            () => {
                if (store.paymentMethod.id === 'boost') {
                    store.startPolling();
                }
            }
        );
    }

    renderBankSelector() {
        return services.getParam('city') === 'Bangkok' && <BankSelector />;
    }

    render() {
        const { store, rewardsPopupStore } = this.props;

        if (store.view === store.CHECKOUT_VIEW) {
            return (
                <div className={`${base}__container`}>
                    {rewardsPopupStore.showPopup && <RewardsPopup />}
                    <div
                        className={`col-lg-7 col-md-7 col-sm-7 ${base}__user-info`}
                    >
                        <OrderInfo />
                        <CompleteSignUp />
                        <PaymentManagement />
                        {this.renderBankSelector()}
                        <div
                            className={`${base}__summary-section hide--if-large`}
                        >
                            <OrderSummary />
                        </div>
                        {window.matchMedia(SMALL_SCREEN_MEDIA).matches && (
                            <DiscountCode />
                        )}
                        <div className={`${base}__button-wrapper text--center`}>
                            <PlaceOrderButton />
                        </div>
                    </div>
                    <div
                        className={`col-lg-5 col-md-5 col-sm-5 ${base}__cart-info`}
                    >
                        <ProductList />
                        {!window.matchMedia(SMALL_SCREEN_MEDIA).matches && (
                            <DiscountCode />
                        )}
                    </div>
                </div>
            );
        }

        if (store.view === store.EMPTY_VIEW) {
            return (
                <div className="row">
                    <div className="col-lg-12">
                        <EmptyCart />
                    </div>
                </div>
            );
        }

        if (store.view === store.PAYMENT_IN_PROGRESS_VIEW) {
            return <PaymentInProgress color="Green" />;
        }

        if (store.view === store.CONFIRM_VIEW) {
            return <ConfirmPayment />;
        }

        return <div />;
    }
}
