import React, { Component } from 'react';
import intl from 'react-intl-universal';
import ButtonUI from '../loginPopup/ButtonUI';
import { inject, observer } from 'mobx-react';

const base = 'select-popup';

@inject('selectPopupStore')
@observer
export default class SelectThankYouPage extends Component {
    render() {
        const { selectPopupStore } = this.props;

        return (
            <div className={`${base}__thank-you-container`}>
                <div className={`${base}__thank-you-header-container`} />
                <div className={`${base}__thank-you-body`}>
                    <div className={`${base}__thank-you-body-header`}>
                        <p className="text--bolder">
                            {intl.get('selectPopup.thankYou.header')}
                        </p>
                    </div>
                    <div className={`${base}__thank-you-body-subheader`}>
                        <p>{intl.get('selectPopup.thankYou.subheader')}</p>
                    </div>
                    <div className={`${base}__thank-you-cta-container`}>
                        <ButtonUI
                            onClick={selectPopupStore.handleHidePopup}
                            customClasses={`${base}__cta button--select select`}
                            text={intl.get('selectPopup.thankYou.cta')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
