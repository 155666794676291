import React from 'react';
const base = 'add-payment-method';

export default ({ onClick, text, paymentProviderIcon }) => {
    return (
        <li className="mtm mbm">
            <button
                type="button"
                className={`button button--outline ${base}__button`}
                onClick={onClick}
            >
                <div className="display--flex flex-align-items-center width--100">
                    <div className={`${base}__icon ${paymentProviderIcon}`} />
                    <span className={`${base}__button-text mls`}>{text}</span>
                </div>
            </button>
        </li>
    );
};
