import React, { Fragment } from 'react';
import { Provider } from 'mobx-react';

import { Link } from '../services/types';
import BaseLayout from './core/baseLayout/Master_BaseLayout';
import MenuHeader from './core/headers/MenuHeader';
import MenuFooter from './core/MenuFooter';
import Entry from './page/profilePage/Entry';

import menuHeaderStore from '../store/menuHeaderStore';
import loginPopupStore from '../store/loginPopupStore';
import PaypalStore from '../store/paypalStore';
import CreditCardStore from '../store/creditCardStore/Master_CreditCardStore';
import ProfileAccountDetailStore from '../store/profileAccountDetailStore';
import ProfilePaymentMethodStore from '../store/profilePaymentMethodStore';
import ProfileAddressStore from '../store/profileAddressStore';
import ProfilePasswordStore from '../store/profilePasswordStore';
import setUpStoreInteractions from '../store/interactions/profileInteractions';
import PromotionBar from './core/PromotionBar';

const profileAccountDetailStore = new ProfileAccountDetailStore();
const profilePaymentMethodStore = new ProfilePaymentMethodStore();
const paypalStore = new PaypalStore();
const creditCardStore = new CreditCardStore();
const profileAddressStore = new ProfileAddressStore();
const profilePasswordStore = new ProfilePasswordStore();

loginPopupStore.setBackLink(new Link('/menu', 'BACK TO MENU'));

const Header = () => (
    <Provider menuHeaderStore={menuHeaderStore}>
        <Fragment>
            <MenuHeader />
            <PromotionBar />
        </Fragment>
    </Provider>
);

const stores = {
    profileAccountDetailStore,
    profilePaymentMethodStore,
    profileAddressStore,
    profilePasswordStore,
    paypalStore,
    creditCardStore,
};

setUpStoreInteractions(creditCardStore, paypalStore, profilePaymentMethodStore);

export default class extends React.Component {
    render() {
        return (
            <BaseLayout.Page HeaderComponent={Header}>
                <main>
                    <Provider {...stores}>
                        <Entry />
                    </Provider>
                </main>
                <Provider menuHeaderStore={menuHeaderStore}>
                    <MenuFooter />
                </Provider>
            </BaseLayout.Page>
        );
    }
}
