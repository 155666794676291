import React, { Component } from 'react';
import SectionHero from './SectionHero';
import Why from './Why';
import AboutCompanyCarousel from './AboutCompanyCarousel';
import Mission from './Mission';
import TeamsCarousel from './TeamsCarousel';
import Testimonials from './Testimonials';
import LifeAtPop from './LifeAtPop';
import HowWeHire from './HowWeHire';
import ViewJobs from './ViewJobs';

const base = 'careers';

export default class extends Component {
    render() {
        return (
            <div className={base}>
                <SectionHero />
                <Why />
                <AboutCompanyCarousel />
                <Mission />
                <TeamsCarousel />
                <Testimonials />
                <LifeAtPop />
                <HowWeHire />
                <ViewJobs />
            </div>
        );
    }
}
