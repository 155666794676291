import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { services } from '../../../services/index';
import intl from 'react-intl-universal';
import { MAYBE, RESOURCE } from '../../../services/types';
import {
    RATING_TYPE_DELIVERY,
    RATING_TYPE_MEAL,
} from '../../../services/constants';
import { CircleLoader } from '../../core/Loading';
import Rating from './Rating';
const { clevertap } = window;

const base = 'rating-popup';

@inject('orderRatingStore', 'orderStatusStore')
@observer
export default class extends React.Component {
    handleSubmitComment() {
        const { orderRatingStore } = this.props;

        orderRatingStore.submitComment();
        orderRatingStore.setShowingPopup(orderRatingStore.POPUP_NPS_RATING)();
    }

    renderSubmit() {
        const { orderRatingStore } = this.props;
        const disabled = !orderRatingStore.hasRatedAll;

        if (orderRatingStore.hasPutStarRating) {
            return (
                <div
                    className={`${base}__body ${base}__body-cta ${base}__body--border-top pbm`}
                >
                    <style
                        dangerouslySetInnerHTML={{
                            __html: `.${services.getParam(
                                'city'
                            )} #launcher { display: none }`,
                        }}
                    />
                    <button
                        className={`${base}__cta button button--larger button--bigger-on-mobile button--success ${
                            disabled && 'button--disabled'
                        }`}
                        onClick={::this.handleSubmitComment}
                    >
                        {intl.get('rating.starRating.cta')}
                    </button>
                </div>
            );
        }

        return null;
    }

    onClickMissingItemLink = () => {
        const { orderStatusStore } = this.props;

        clevertap.event.push('Reported Delivery Not Received', {
            Rider: orderStatusStore.riderName,
        });

        if (typeof window.fcWidget !== 'undefined') {
            window.fcWidget.open();
        }
    };

    render() {
        const { orderRatingStore } = this.props;

        if (orderRatingStore.order === MAYBE.None) {
            return <div />;
        }

        const { ratingInfoFetcher } = orderRatingStore;
        if (RESOURCE.Failure.is(ratingInfoFetcher)) {
            return (
                <div className="text--red">
                    {intl.get('rating.starRating.tryAgain')}
                </div>
            );
        }

        if (ratingInfoFetcher === RESOURCE.Loading) {
            return (
                <div className={base}>
                    <div
                        className={`${base}__header text--bolder text--white text--center`}
                    >
                        {intl.get('rating.order')}
                        {orderRatingStore.order.data.order_id}
                    </div>
                    <div className={`${base}__body ptm`}>
                        <CircleLoader />
                    </div>
                </div>
            );
        }

        const { ratings } = orderRatingStore;
        const deliveryRating = ratings.find(
            (rating) => rating.type === RATING_TYPE_DELIVERY
        );
        return (
            <Fragment>
                <div className={base}>
                    <div className={`${base}__header text--bolder`}>
                        <span>{intl.get('rating.order')}</span>
                        <span
                            className={`${base}__header-alt text--thin`}
                        >{`#${orderRatingStore.order.data.order_id}`}</span>
                    </div>
                    <div className={`${base}__body`}>
                        <p className={`${base}__title text--left text--bolder`}>
                            {intl.get('rating.starRating.title')}
                        </p>
                        {ratings
                            .filter(
                                (rating) => rating.type !== RATING_TYPE_DELIVERY
                            )
                            .map((rating, index) => (
                                <Rating key={index} rating={rating} />
                            ))}
                    </div>
                    {deliveryRating && (
                        <div
                            className={`${base}__body delivery ${base}__body--border-top`}
                        >
                            <Rating
                                rating={deliveryRating}
                                isDelivery={true}
                                title={intl.get('rating.deliveryRating.title')}
                            />
                        </div>
                    )}
                </div>
                {this.renderSubmit()}
            </Fragment>
        );
    }
}
