import React from 'react';
// import cx from 'classnames';
import defaultsDeep from 'lodash/defaultsDeep';

const hideTimeout = 10000;

const defaultData = {
    text: '',
    extra: {
        showCloseIcon: true,
    },
};

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowingBanner: false,
            data: defaultData,
            type: 'error',
        };
        this.handleReceiveData = ::this.handleReceiveData;
        this.handleHideBanner = ::this.handleHideBanner;
        this.handlePauseTimer = ::this.handlePauseTimer;
        this.handleHideBannerWithTimer = ::this.handleHideBannerWithTimer;

        if (typeof window !== 'undefined') {
            window.document.addEventListener(
                'showBanner',
                this.handleReceiveData
            );
        }
    }

    handlePauseTimer() {
        window.clearTimeout(this.timerHandler);
    }

    handleReceiveData(e) {
        this.setState(
            {
                isShowingBanner: true,
                data: defaultsDeep(e.detail.data, defaultData),
                type: e.detail.type,
            },
            this.handleHideBannerWithTimer
        );
    }

    handleHideBannerWithTimer() {
        this.handlePauseTimer();
        this.timerHandler = setTimeout(() => {
            this.setState({
                isShowingBanner: false,
                data: defaultData,
            });
        }, hideTimeout);
    }

    handleHideBanner() {
        this.setState({
            isShowingBanner: false,
            data: defaultData,
        });
    }

    render() {
        const base = 'banner';
        const { type, data, isShowingBanner } = this.state;
        const classNames =
            isShowingBanner && Boolean(data.text) === true
                ? `${base} ${base}--${type} ${base}--show`
                : `${base} ${base}--${type}`;

        return (
            <div className={classNames}>
                <div
                    className={`${base}__content`}
                    onMouseEnter={this.handlePauseTimer}
                    onMouseLeave={this.handleHideBannerWithTimer}
                >
                    {data.text}
                </div>
                {data.extra.showCloseIcon && isShowingBanner ? (
                    <button
                        className={`${base}__close-button`}
                        onClick={this.handleHideBanner}
                    >
                        <svg className={`${base}__cross-icon`}>
                            <use xlinkHref="#icon-cross" />
                        </svg>
                    </button>
                ) : null}
            </div>
        );
    }
}
