import React from 'react';
import { inject, observer } from 'mobx-react';
import EmptyCart from './EmptyCart';
import MealRow from './MealRow';
import OneMoreStepButton from './OneMoreStepButton';
import MealTotal from './MealTotal';
import { CircleLoader } from '../../core/Loading';

const base = 'shopping-cart-meal';

@inject('cartStore', 'menuListStore', 'userStore')
@observer
export default class extends React.Component {
    renderOrders() {
        const { cartStore } = this.props;

        return cartStore.cart.map((dateOrder, i) => {
            const isLastRow = cartStore.cart.length - 1 === i;
            return (
                <MealRow
                    id={i + 1}
                    key={dateOrder.date}
                    date={dateOrder.date}
                    items={dateOrder.menu_item}
                    isLastRow={isLastRow}
                    lastRow={
                        isLastRow && <MealTotal showMoneyBack={true} key={i} />
                    }
                />
            );
        });
    }

    render() {
        const { cartStore, menuListStore, userStore } = this.props;
        const isEmptyCart = cartStore.cart.length === 0;
        if (menuListStore.isLoading && userStore.user.data) {
            return (
                <div className="menu__list--loading text--center">
                    <div className="display--inline-block text--center">
                        <CircleLoader className="m0a" />
                        <p className="mtm">Loading cart...</p>
                    </div>
                </div>
            );
        }
        return (
            <div className={`${base}__container`}>
                {this.renderOrders()}
                {isEmptyCart ? <EmptyCart /> : null}
                <div className={`${base}__button-container`}>
                    <OneMoreStepButton />
                </div>
            </div>
        );
    }
}
