import { observable, action, computed } from 'mobx';
import { VERIFICATION_STATUS } from '../services/types';
import { COUNTRY_CODES } from '../services/constants';
import intl from 'react-intl-universal';
import { services } from '../services/index';
import { ValidationResult } from '../services/validator';
import { parsePhoneNumber } from '../services/phoneParser';

const { clevertap } = window;

export default class {
    @observable.ref verifier = VERIFICATION_STATUS.Initial;
    @observable timerRunning = false;
    @observable.ref validationResult = new ValidationResult();
    @observable verified = false;
    @observable phoneNumber = '';
    @observable verificationCode = '';
    @observable countryCode = COUNTRY_CODES[services.getParam('country')];
    @observable errorMessage = '';
    @observable validNumber = '';
    @observable loginOtpRetryTime = null;

    @computed get verifyButtonText() {
        if (this.timerRunning === true) {
            return intl.get('phoneVerification.resend');
        }

        return intl.get('phoneVerification.send');
    }

    @computed get retryTime() {
        if (this.verifier.data && this.verifier.data['retry_in']) {
            return parseInt(this.verifier.data['retry_in'], 10) || 30;
        }

        return 30;
    }

    @action checkCountryCodeInNumber = (countryCode, phoneNumber) => {
        if (phoneNumber.length > 0) {
            if (countryCode.includes(phoneNumber.slice(0, 2))) {
                services.bannerEvents.showError(
                    intl.get('phoneVerification.duplicateCode')
                );
                this.errorMessage = intl.get('phoneVerification.duplicateCode');
                return true;
            } else {
                return false;
            }
        } else {
            services.bannerEvents.showError(
                intl.get('phoneVerification.emptyNo')
            );
            this.errorMessage = intl.get('phoneVerification.emptyNo');
        }
    };

    removeCountryCodeFromPhoneNumber = () => {
        const parsedPhoneNumber = parsePhoneNumber(
            this.phoneNumber,
            this.countryCode
        );

        if (parsedPhoneNumber) {
            this.phoneNumber = parsedPhoneNumber.phoneNumber;
            this.countryCode = '+' + parsedPhoneNumber.countryCode;
        }
    };

    @action handleChange = (evt) => {
        const field = evt.currentTarget.name;
        this[field] = evt.currentTarget.value;

        if (field === 'phoneNumber' || field === 'countryCode') {
            this.verifier = VERIFICATION_STATUS.Initial;
            this.allowResendCode();
            this.validationResult = new ValidationResult();
        }

        this.removeCountryCodeFromPhoneNumber();
    };

    @action requestVerificationCode = () => {
        this.verifier = VERIFICATION_STATUS.CodeRequesting;
        services.api
            .GetOtp({ phone_no: this.mobileNumber })
            .then((res) => {
                if (res.status === 'ERROR') {
                    this.message = res.message;
                    this.verifier = VERIFICATION_STATUS.Initial;
                } else {
                    clevertap.event.push('Confirmed Phone Number');

                    this.verifier = VERIFICATION_STATUS.CodeRequestSuccess(res);
                    this.validNumber = true;
                    this.loginOtpRetryTime = res.retry_in;
                    this.timerRunning = true;
                }
            })
            .catch((e) => {
                this.message = e.message;
                this.verifier = VERIFICATION_STATUS.Initial;
            });
    };

    @action verifyPhone = () => {
        services.api
            .VerifyWithMobileNumber(this.mobileNumber, this.verificationCode)
            .then(() => {
                this.verified = true;
            })
            .catch(() => {
                this.errorMessage = 'Invalid verification code';
                services.bannerEvents.showError('Invalid verification code');
            });
    };

    @action allowResendCode() {
        this.timerRunning = false;
    }

    handlePhoneUpdate(response) {
        switch (response.status) {
            case 409:
                services.bannerEvents.showError(
                    intl.get('user.duplicatePhone')
                );
                break;
            case 400:
                services.bannerEvents.showError(intl.get('user.phoneInvalid'));
                break;
            default:
                services.bannerEvents.showError(response.message);
        }
    }
}
