import React, { Component } from 'react';
import BasePage from './basePage';
import { Helmet } from 'react-helmet';
import AboutRoot from '../components/AboutRoot';
import { getGTMScript } from '../components/core/ScriptTag';

import { services } from '../services/index';
import { localizationTags } from '../services/localizationTags';
import { getCanonicalTag } from '../services/canonicalTags';

const pageTitle = services.getTitle('about');

export default class extends Component {
    componentDidMount() {
        document.body.classList.add('about');
    }

    render() {
        const GTM = getGTMScript({
            pageTitle: pageTitle,
            pageType: 'About',
            lpType: '',
            lpVersion: '',
        });

        return (
            <div>
                <Helmet>
                    <title>{pageTitle}</title>
                    {localizationTags('about').map((tag) => tag)}
                    {getCanonicalTag('about')}
                    <script type="text/javascript">{GTM}</script>
                </Helmet>
                <BasePage />
                <AboutRoot />
            </div>
        );
    }
}
