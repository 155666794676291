import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';

import intl from 'react-intl-universal';

import { RESOURCE } from '../../../services/types';
import { stripRepeatedCommas } from '../../../services';
import { DELIVERY_STATUS_DELIVERED } from '../../../services/constants';
import { CircleLoader } from '../../core/Loading';
import RatingWidget from './RatingWidget';
import ChangeAddress from '../../core/changeOrder/ChangeAddress';
import ChangeTimeframe from '../../core/changeOrder/ChangeTimeframe';

const base = 'order-status-popup';

@inject('orderRatingStore')
@inject('orderStatusStore')
@observer
export default class extends React.Component {
    componentDidMount() {
        this.props.orderStatusStore.fetchDeliveryDetail();
    }

    handleOpenCancel = (cb) => {
        document.getElementById('order-status-popup').scrollTop = 0;
        cb();
    };

    renderHeader() {
        const { orderStatusStore, orderRatingStore } = this.props;
        const { deliveryInfoServices } = orderStatusStore;
        if (
            deliveryInfoServices.status === DELIVERY_STATUS_DELIVERED &&
            !orderStatusStore.deliveryInfoFetcher.data.rated &&
            !orderStatusStore.deliveryInfoFetcher.data.rated
        ) {
            return (
                <div
                    className={`${base}__header--inverse text--bolder text--white text--center`}
                >
                    <p className="text--bold fs--medium-large mbl">
                        {intl.get('orderStatus.popupDeliveryDetail.title')}
                        {deliveryInfoServices.orderNumber}
                    </p>
                    <RatingWidget />
                </div>
            );
        }

        return (
            <div
                className={`${base}__header text--bolder text--white text--center`}
            >
                {intl.get('orderStatus.popupDeliveryDetail.title')}
                {deliveryInfoServices.orderNumber}
            </div>
        );
    }

    renderCancel() {
        const { orderStatusStore } = this.props;
        const { deliveryInfoServices } = orderStatusStore;

        if (
            deliveryInfoServices.status === DELIVERY_STATUS_DELIVERED ||
            !deliveryInfoServices.cancelled_allowed
        ) {
            return null;
        } else {
            return (
                <div className="mts">
                    <hr className={`${base}__sep-line`} />
                    <div className="text--right mtm">
                        <div>
                            <span
                                className={`${base}__final-line cursor-pointer text--orange`}
                                onClick={() =>
                                    this.handleOpenCancel(
                                        orderStatusStore.setShowPopup(true)
                                    )
                                }
                            >
                                {intl.get('orderHistory.cancel.cancel')}
                            </span>
                        </div>
                        <div>
                            <span className={`${base}__cutoff-text text--gray`}>
                                {intl.get('orderHistory.cancel.possible')}{' '}
                                {deliveryInfoServices.cutofftime}
                            </span>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        const { orderStatusStore } = this.props;

        if (orderStatusStore.deliveryInfoFetcher === RESOURCE.Loading) {
            return (
                <div className={base}>
                    <div
                        className={`${base}__header text--bolder text--white text--center`}
                    >
                        {intl.get('orderStatus.popupDeliveryDetail.loading')}
                    </div>
                    <div className={`${base}__body`}>
                        <center>
                            <CircleLoader />
                        </center>
                    </div>
                </div>
            );
        }

        if (orderStatusStore.deliveryInfoFetcher === RESOURCE.Initial) {
            return <div />;
        }

        const { deliveryInfoServices } = orderStatusStore;

        if (orderStatusStore.isShowingChangeAddress) {
            return <ChangeAddress />;
        }

        if (orderStatusStore.isShowingChangeTimeframe) {
            return <ChangeTimeframe />;
        }

        const popupBodyClasses = cx(`${base}__body fs--small-medium`, {
            [`${base}__body--with-rating`]:
                deliveryInfoServices.status === DELIVERY_STATUS_DELIVERED,
        });

        let deliveryInstruction = null;
        if (orderStatusStore.deliveryInstruction) {
            deliveryInstruction = (
                <div className="flex-1 width--50">
                    <p className="text--bolder mbxs">
                        {intl.get(
                            'orderStatus.popupDeliveryDetail.deliveryInstruction'
                        )}
                    </p>
                    <p>{orderStatusStore.deliveryInstruction} </p>
                </div>
            );
        }

        return (
            <div className={base}>
                {this.renderHeader()}
                <div className={popupBodyClasses}>
                    <div className="display--flex mbm">
                        <div className="flex-1 width--50">
                            <p className="text--bolder mbxs">
                                {intl.get(
                                    'orderStatus.popupDeliveryDetail.orderPlacedOn'
                                )}
                            </p>
                            <p>{deliveryInfoServices.orderCreatedDate}</p>
                        </div>
                        <div className="flex-1 width--50">
                            <p className="text--bolder mbxs">
                                {deliveryInfoServices.deliveryLabel}:
                            </p>
                            <p>{deliveryInfoServices.deliveryDate}</p>
                        </div>
                    </div>
                    <div className="display--flex mbm">
                        <div className="flex-1 width--50">
                            <p className="text--bolder mbxs">
                                {intl.get(
                                    'orderStatus.popupDeliveryDetail.yourDetails'
                                )}
                            </p>
                            <p>{deliveryInfoServices.name}</p>
                            <p>{orderStatusStore.username}</p>
                        </div>
                        {deliveryInstruction}
                    </div>
                    <div className={`${base}__meals mtm mbm pm`}>
                        <div className="display--flex flex-wrap text--bolder">
                            <div className="flex-1 width--50">
                                <span>
                                    {deliveryInfoServices.shortOrderDate.toUpperCase()}
                                </span>
                            </div>
                            <div className="flex-1 width--33 text--right text--bolder">
                                <span className="text--primary">
                                    {deliveryInfoServices.timeframe}
                                </span>
                            </div>
                            {orderStatusStore.changeAllowed ? (
                                <div className="flex-1 text--right text--thin">
                                    <span
                                        className="mls cursor-pointer text--orange text--right"
                                        onClick={
                                            ::orderStatusStore.setShowChangeTimeframe(
                                            true
                                        )}
                                    >
                                        {intl.get(
                                            'orderStatus.popupDeliveryDetail.change'
                                        )}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                        <div className="mtm">
                            <p className="text--bolder mbxs">
                                {intl.get(
                                    'orderStatus.popupDeliveryDetail.address'
                                )}
                            </p>
                            <p>
                                {stripRepeatedCommas(
                                    deliveryInfoServices.address
                                )}
                            </p>
                        </div>
                        {orderStatusStore.changeAllowed ? (
                            <div className="mtxs">
                                <span
                                    onClick={
                                        ::orderStatusStore.setShowChangeAddress(
                                        true
                                    )}
                                    className="cursor-pointer text--orange mbxs"
                                >
                                    {intl.get(
                                        'orderStatus.popupDeliveryDetail.change'
                                    )}
                                </span>
                            </div>
                        ) : null}
                        <div className="mtm">
                            <p className="text--bolder mbxs">
                                {intl.get(
                                    'orderStatus.popupDeliveryDetail.orderItems'
                                )}
                            </p>
                            <ul>
                                {deliveryInfoServices.itemLines.map(
                                    (itemLine) => {
                                        return (
                                            <li
                                                key={itemLine.id}
                                                className="display--flex mbxs"
                                            >
                                                <span
                                                    className={`${base}__item-line-quantity`}
                                                >
                                                    {itemLine.quantity}
                                                </span>
                                                <span className="flex-1-only">
                                                    {itemLine.name}
                                                </span>
                                                <span className="flex-none">
                                                    {itemLine.price}
                                                </span>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="text--right">
                        {deliveryInfoServices.orderSummaryLines.map(
                            (line, id) => {
                                let color = '';
                                if (line.color === 'GREEN') {
                                    color = 'text--primary';
                                } else if (line.color === 'BLUE') {
                                    color = 'text--blue';
                                }
                                return (
                                    <div
                                        className={
                                            line.bold ? 'text--sofiapro' : ''
                                        }
                                        key={id}
                                    >
                                        <span className={color}>
                                            {line.name}
                                        </span>
                                        <span
                                            className={`${base}__final-line mbxs ${color}`}
                                        >
                                            {' '}
                                            {line.value}
                                        </span>
                                    </div>
                                );
                            }
                        )}
                    </div>
                    <hr className={`${base}__sep-line`} />
                    <div className="text--right">
                        <div>
                            <span>
                                {intl.get(
                                    'orderStatus.popupDeliveryDetail.paymentMethod'
                                )}
                            </span>
                            <span className={`${base}__final-line`}>
                                {deliveryInfoServices.paymentMethod}
                            </span>
                        </div>
                    </div>
                    {this.props.showHistoryLink ? (
                        <div className="text--right mtm">
                            <Link
                                className="cursor-pointer dmk-link--primary-hover"
                                to="/history"
                            >
                                {intl.get(
                                    'orderStatus.popupDeliveryDetail.seeAll'
                                )}
                            </Link>
                        </div>
                    ) : null}
                    {this.renderCancel()}
                </div>
            </div>
        );
    }
}
